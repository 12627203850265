import { Chip } from 'primereact/chip'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLanguageContext } from '../../LanguageContext';
import { HeaderInfo } from './type';
import { fetchData as ballotGetData } from '../../../services/Ballot/apiservice';

const CommentCommitteeHeader = (props: HeaderInfo) => {
    const { headerInfo } = props;
    const [t, i18n] = useTranslation("ballot");
    const { selectedLanguage } = useLanguageContext();
    const [respondedCount, setRespondedCount] = useState(0);
    const performAction = () => {
        i18n.changeLanguage(selectedLanguage);
    };
    React.useEffect(() => {
        performAction();
    }, [selectedLanguage]);
    const fetchRespondedCount = async () => {
        const promises: any = headerInfo?.BallotComments.map(async (comment) => {
            const response = await ballotGetData(`BallotCommentResponses/GetBallotCommentResponsesByCommentId?ballotcommentId=${comment.Id}`);
            return response.Collection.length > 0 ? 1 : 0;
        });

        const results = await Promise.all(promises);
        const count = results.reduce((acc: number, value: number) => acc + value, 0);
        setRespondedCount(count);
    };
    useEffect(() => {
        fetchRespondedCount();
    }, [headerInfo]);
    // Calculate the dynamic values
    const resolvedCount = headerInfo?.BallotComments.filter(comment => comment.CommentStatusName === "Resolved").length;
    const disapprovedWOCommentCount = headerInfo?.BallotComments.filter(comment => comment.CommentApprovalTypeName === "Disapproved without comment").length;
    const unrelatedCount = headerInfo?.BallotComments.filter(comment => comment.CommentStatusName === "Unrelated to proposal").length;
    return (
        <div className="flex flex-wrap align-items-center px-4 py-3 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h4 className="text-title text-base font-bold text-capitalize m-0">
                {headerInfo?.CommitteeName}
            </h4>
            <div className="flex flex-wrap align-items-center gap-2 ml-auto">
                <span>{t("ballot.comments")}</span>
                <Chip className="text-sm bg-white border-1 custom-chip" label={`${resolvedCount}/${headerInfo?.BallotComments.length} ${t('ballot.resolved')}`}
                />
                <Chip className="text-sm bg-white border-1 custom-chip" label={`${respondedCount}/${headerInfo?.BallotComments.length} ${t('ballot.responded')}`} />
                <Chip className="text-sm bg-white border-1 custom-chip" label={`${disapprovedWOCommentCount} ${t('ballot.disaaprovedWOComment')}`} />
                <Chip className="text-sm bg-white border-1 custom-chip" label={`${unrelatedCount} ${t('ballot.unrelated')}`} />
            </div>
        </div>
    )
}

export default CommentCommitteeHeader
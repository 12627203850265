import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectRecords from "../common/SelectRecords";
import BallotDetails from "../common/SelectBallotDetails";
import CreateBallotHeader from "../common/BallotHeader";
import { fetchData } from "../../../services/Ballot/apiservice";
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";
import { ballotDetails } from "../../../slices/ballotDetails";
import SelectCommittee from "../common/SelectCommittee";
import Preview from "../common/Preview";
import { ballotCommittees } from "../../../slices/ballotCommittees";
import { fetchData as getData } from "../../../services/apiService";
import {
  BallotRecordStatus,
  BallotRecordStatusResponse,
  BallotStatus,
  BallotStatusResponse,
  Committee,
  CommitteeResponse,
} from "../common/type";

const EditBallot = () => {
  const { stepCount } = useSelector((state: any) => state.ballotSelectRecords);
  const [activeStep, setActiveSteps] = useState<number>(stepCount);
  const stepController = (step: number) => {
    setActiveSteps(step);
  };

  const dispatch = useDispatch();
  let ballotId = sessionStorage.getItem("ballotTableDetails");
  const {
    primaryCommitteOptions,
    ballotTypeOptions,
    ballotSubTypeOptions,
    ballotLevelOptions,
    recordTypeOptions,
    recordSubTypeOptions,
    positionAppliedOptions,
  } = useSelector((state: any) => state.ballotSelectRecords);
  const ballotCommitteesData = useSelector(
    (state: any) => state.ballotCommittee.ballotCommitteeData
  );
  const {
    primaryCommitteSelect,
    ballotTypeSelect,
    ballotSubTypeSelect,
    ballotLevelSelect,
    recordTypeSelect,
    recordSubTypeSelect,
    positionAppliedSelect,
  } = useSelector(
    (state: any) => state.ballotSelectRecords.selectedRecordValues
  );
  const { isEdit } = useSelector((state: any) => state.ballotSelectRecords);
  const { ballotStatusList, ballotDetailsInfo } = useSelector(
    (state: any) => state.ballotDetails
  );
  const fetchParentCommittee = async () => {
    try {
      const primaryCommitteeResponse: CommitteeResponse =
        await getData(`Committee/GetAll`);
      const primaryCommittee = primaryCommitteeResponse.Collection.filter(
        (committee: Committee) => committee.Status.Name === "Created"
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: primaryCommittee,
          label: "primarycommittee",
        })
      );
    } catch (err) {
      console.log(err, "err");
    }
  };
  const handleDropDownOptions = async () => {
    try {
      const userProfileId = window.localStorage.userProfileID;
      const [
        accreditationBody,
        ballotType,
        ballotSubType,
        ballotLevel,
        recordType,
        recordSubType,
        membershipPosition,
      ] = await Promise.all([
        getData(`AccreditationBody/GetAll`),
        fetchData("BallotType/GetAll"),
        fetchData("BallotSubType/GetAll"),
        fetchData("BallotLevel/GetAll"),
        getData("RecordType/GetAll"),
        fetchData("RecordSubType/GetAll"),
        getData("MembershipPosition/GetAll"),
      ]);
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: accreditationBody,
          label: "accreditationType",
        })
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: ballotType,
          label: "ballottype",
        })
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: ballotSubType,
          label: "ballotsubtype",
        })
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: ballotLevel,
          label: "ballotlevel",
        })
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: recordType,
          label: "recordtype",
        })
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: recordSubType,
          label: "recordsubtype",
        })
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: membershipPosition,
          label: "positions",
        })
      );
      // setSelectedRecords(tablerecordSelected)
    } catch (err) {
      console.log(err, "err");
    }
  };
  const getAllBallotStatus = async () => {
    try {
      const ballotStatusResponse: BallotStatusResponse =
        await fetchData(`BallotStatus/GetAll`);
      const allBallotStatusFormatted = ballotStatusResponse.Collection.map(
        (ballot: BallotStatus) => {
          return {
            id: ballot.Id,
            name: ballot.Name,
          };
        }
      );
      dispatch(
        ballotDetails.addBallotStatusList({ value: allBallotStatusFormatted })
      );
    } catch (err) {
      console.log(err, "err");
    }
  };
  const getAllBallotRecordStatus = async () => {
    try {
      const ballotRecordStatusResponse: BallotRecordStatusResponse =
        await fetchData(`BallotRecordStatus/GetAll`);
      const allBallotRecordStatusFormatted =
        ballotRecordStatusResponse.Collection.map(
          (ballot: BallotRecordStatus) => {
            return {
              id: ballot.Id,
              name: ballot.Name,
            };
          }
        );
      dispatch(
        ballotDetails.addBallotRecordStatusList({
          value: allBallotRecordStatusFormatted,
        })
      );
    } catch (err) {
      console.log(err, "err");
    }
  };
  const getBallotDetailsHandler = async () => {
    try {
      const allBallotDetails = await fetchData(`Ballot/${ballotId}`);
      console.log(allBallotDetails, "allBallotDetails");
      onEditBallotHandler(allBallotDetails);
    } catch (err) {
      console.log(err, "errr");
    }
  };
  const onEditBallotHandler = (ballotDetailsData: any) => {
    // ballot record section 1
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.AccreditationBody?.Name,
        label: "accreditationBodyValue",
      })
    );
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.Committee?.Name,
        label: "primaryCommitteeValue",
      })
    );
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.BallotType?.Name,
        label: "ballotTypeValue",
      })
    );
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.BallotSubType?.Name,
        label: "ballotSubTypeValue",
      })
    );
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.BallotLevel?.Name,
        label: "ballotLevelValue",
      })
    );
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.RecordType?.TypeName,
        label: "recordTypeValue",
      })
    );
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.RecordSubType?.Type,
        label: "recordSubTypeValue",
      })
    );
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.MembershipPosition?.Description,
        label: "positionAppliedValue",
      })
    );
    // ballot Details section 2
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.Id,
        label: "ballotid",
      })
    );
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.OpenDate,
        label: "startdate",
      })
    );
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.CloseDate,
        label: "enddate",
      })
    );
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.Description,
        label: "description",
      })
    );
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.Explaination,
        label: "explaination",
      })
    );
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.Note,
        label: "staffnote",
      })
    );
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.BallotNumber,
        label: "ballotnumber",
      })
    );
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.BallotStatus.Id,
        label: "ballotStatusId",
      })
    );
  };

  const fetchAllBallotCommittees = async () => {
    try {
      const allBallotCommittee = await fetchData(
        `BallotCommittees/GetBallotInvitedComitteeListByBallotId/${ballotId}`
      );
      // const response = await allBallotCommittee.json()
      // setEditBallotCommitteeData(allBallotCommittee)
      dispatch(
        ballotCommittees.initialBallotCommitteeValues({
          value: allBallotCommittee,
          statusId: ballotStatusList["Out for Ballot"]?.id,
        })
      );
      if (!ballotDetailsInfo.description) {
        // setActiveSteps(1)
        dispatch(ballotSelectRecords.addStepCountHandler({ value: 1 }));
      }
      if (ballotDetailsInfo?.description) {
        // setActiveSteps(2)
        dispatch(ballotSelectRecords.addStepCountHandler({ value: 2 }));
      }
      if (allBallotCommittee.length >= 1) {
        // setActiveSteps(3)
        dispatch(ballotSelectRecords.addStepCountHandler({ value: 3 }));
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  useEffect(() => {
    if (ballotId) {
      dispatch(ballotSelectRecords.isEditHandler({ value: true }));
      getBallotDetailsHandler();
    }
  }, [ballotId]);
  useEffect(() => {
    fetchAllBallotCommittees();
  }, []);

  useEffect(() => {
    setActiveSteps(stepCount);
  }, [stepCount]);

  useEffect(() => {
    fetchParentCommittee();
    handleDropDownOptions();
    getAllBallotStatus();
    getAllBallotRecordStatus();
  }, []);
  return (
    <>
      <CreateBallotHeader activeStep={activeStep} />
      {activeStep === 0 && <SelectRecords />}
      {activeStep === 1 && <BallotDetails />}
      {activeStep === 2 && <SelectCommittee />}
      {activeStep === 3 && <Preview />}
    </>
  );
};

export default EditBallot;

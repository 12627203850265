import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { TabPanel, TabView } from "primereact/tabview";
import { Badge } from "primereact/badge";
import { Chart } from "primereact/chart";
import { fetchData } from "../../services/Ballot/apiservice";
import { useLanguageContext } from "../LanguageContext";
import { BallotDetails, BallotRecord, InvitedCommittee } from "./types/vote";
import useUserData from "../../hooks/useUserData";
import withLoader from "../common/LoaderIntercepter";
import { DropdownType } from "../../CommonTypes/utils";

interface SummaryType {
  AbstainCount: number;
  AbstainMember: string;
  ApproveCount: number;
  BallotId: string;
  BallotNumber: string;
  CommitteeId: string;
  CommitteesName: string;
  DisapproveCount: number;
  NotReturnCount: number;
  RecordId: string;
  RecordNumber: string;
  approvedMember: string;
  disapproveMember: string;
  noCommentCount: number;
  noCommentMember: string;
  notCommentCount: number;
  noReturnedCount: number;
  nonsubstaintiveCount: number;
  nonsubstaintiveMember: string;
  notReturnedMember: string;
  substaintiveCount: number;
  substaintiveMember: string;
}

const BallotTabVoteSummary: React.FC = () => {
  const { id } = useParams();
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [selectedRecordId, setSelectedRecordId] = useState<string>("");
  const [recordLists, setRecordList] = useState<BallotRecord[] | [] | any>([]);
  const [intialInvitedCommittee, setIntialInvitedCommitteess] = useState<
    InvitedCommittee[]
  >([]);
  const [invitedCommittees, setInvitedCommitteess] = useState<
    { label: string; value: string }[] | []
  >([]);
  const [activeRecordId, setActiveRecordId] = useState<string | null>(null);
  const [selectedRecordApplicantName, setSelectedRecordApplicantName] =
    useState<string>("");
  const [ballotDetails, setBallotDetails] = useState<BallotDetails | null>(
    null
  );
  const { userRole } = useUserData();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [voteSummaries, setVoteSummaries] = useState<any>({});
  const navigate = useNavigate();
  useEffect(() => {
    if (recordLists[activeIndex]?.record?.recordId) {
      setActiveRecordId(recordLists[activeIndex]?.record?.recordId);
    }
  }, [activeIndex, recordLists]);

  const getBallotDetailsHandler = async () => {
    let ballotId = sessionStorage.getItem("ballotTableDetails");
    try {
      const ballotDetailsData = await fetchData(`Ballot/${ballotId}`);
      setBallotDetails(ballotDetailsData);
    } catch (err) {
      console.error("Error fetching ballot details", err);
    }
  };

  useEffect(() => {
    getBallotDetailsHandler();
  }, [id]);

  useEffect(() => {
    const getBallotDetailsHandler = async () => {
      try {
        const ballotCommitteessInvited = await fetchData(
          `BallotCommittees/GetBallotInvitedComitteeListByBallotId/${id}`
        );
        setIntialInvitedCommitteess(ballotCommitteessInvited);
        const forCommentCommittee: DropdownType[] = [];
        const forVoteCommittee: DropdownType[] = [];
        const sortedData = ballotCommitteessInvited.map(
          (item: InvitedCommittee) => ({
            label: item.CommitteeName,
            value: item.CommitteeId,
          })
        );
        ballotCommitteessInvited.forEach((item: any) => {
          const label = item.CommitteeName ?? "Unknown Committee";
          const value = item.CommitteeId ?? "Unknown Id";
          if (item.ForReviewAndComment) {
            forCommentCommittee.push({ label, value });
          } else {
            forVoteCommittee.push({ label, value });
          }
        });
        setInvitedCommitteess(sortedData);
        setInvitedCommitteess(sortedData);
      } catch (err) {
        console.error(err, "Error fetching ballot committee invitations");
      }
    };
    if (id) {
      getBallotDetailsHandler();
    }
  }, [id, userRole]);

  useEffect(() => {
    const fetchDataMemberRecord = async () => {
      try {
        const responseData = await fetchData(
          `BallotRecord/GetListByParentId?parentId=${id}`
        );
        setRecordList(responseData.collection);
      } catch (error) {
        console.error("Error fetching Category of Interest:", error);
      }
    };
    if (id) {
      fetchDataMemberRecord();
    }
  }, [id]);

  useEffect(() => {
    if (recordLists.length > 0 && activeRecordId === null) {
      const firstRecord = recordLists[0];
      setSelectedRecordApplicantName(
        firstRecord.record.userProfile?.firstname +
          " " +
          firstRecord.record.userProfile?.lastname
      );
      setSelectedRecordId(firstRecord.record.id);
      callFunctionForRecord(firstRecord);
      setActiveRecordId(firstRecord.record.id);
    }
  }, [recordLists, invitedCommittees]);

  useEffect(() => {
    if (recordLists.length > 0) {
      const selectedRecord = recordLists[activeIndex];
      if (selectedRecord !== activeRecordId) {
        callFunctionForRecord(selectedRecord);
        setActiveRecordId(selectedRecord.record.id);
      }
    }
  }, [activeIndex]);

  const handleRecordRediretion = (recordId: any) => {
    sessionStorage.setItem("recordId", recordId);
    navigate("/record/record-preview");
  };

  const callFunctionForRecord = async (record: any) => {
    if (invitedCommittees.length > 0) {
      for (const committee of invitedCommittees) {
        try {
          const summary = await fetchVoteSumarray(
            record.record.id,
            committee.value,
            id
          );
          updateVoteSummaries(
            record.record.id,
            committee.value,
            summary,
            record.record.recordNumber
          );
        } catch (error) {
          console.error(
            `Failed to fetch summary for committee: ${committee.label}`,
            error
          );
        }
      }
    }
  };

  // Updating vote summaries for each committee and record
  const updateVoteSummaries = (
    recordId: any,
    committeeID: any,
    summary: any,
    recordNumber: string
  ) => {
    setVoteSummaries((prevSummaries: any) => {
      const existingCommitteeSummary =
        prevSummaries[recordId]?.[committeeID] || [];
      if (!summary || summary.length === 0) {
        // Create a default summary if no data is returned
        const committeeName =
          invitedCommittees.find(
            (committee: any) => committee.value === committeeID
          )?.label || "";
        const defaultSummary = [
          {
            BallotId: ballotDetails?.Id,
            BallotNumber: ballotDetails?.BallotNumber,
            CommitteeId: committeeID,
            CommitteesName: committeeName,
            RecordId: recordId,
            RecordNumber: recordNumber,
            ApproveCount: 0,
            DisapproveCount: 0,
            AbstainCount: 0,
            NotReturnCount: 0,
            approvedMember: "",
            disapproveMember: "",
            AbstainMember: "",
            notReturnedMember: "",
            substaintiveMember: "",
            nonsubstaintiveMember: "",
            noCommentMember: "",
            substaintiveCount: 0,
            nonSubstaintiveCount: 0,
            noCommentCount: 0,
            noReturnedCount: 0,
          },
        ];
        return {
          ...prevSummaries,
          [recordId]: {
            ...prevSummaries[recordId],
            [committeeID]: defaultSummary,
          },
        };
      }

      // Check if the new summary needs to be merged or updated
      const needsUpdate = summary.some((item: any) => {
        const existingItem = existingCommitteeSummary.find(
          (existing: any) =>
            existing.BallotId === item.BallotId &&
            existing.CommitteeId === item.CommitteeId
        );
        return !existingItem;
      });
      if (!needsUpdate) {
        // If no update is needed, return previous summaries
        return prevSummaries;
      }

      // Merge the new summary data
      const mergedSummary = summary.reduce((acc: any[], current: any) => {
        const existingItemIndex = acc.findIndex(
          (item) =>
            item.BallotId === current.BallotId &&
            item.CommitteeId === current.CommitteeId
        );
        if (existingItemIndex > -1) {
          const existingItem = acc[existingItemIndex];
          // Update the existing item
          acc[existingItemIndex] = {
            ...existingItem,
            ApproveCount:
              existingItem.ApproveCount +
              (current.VoteName === "Approved" ? current.Count : 0),
            DisapproveCount:
              existingItem.DisapproveCount +
              (current.VoteName === "Disapproved" ? current.Count : 0),
            AbstainCount:
              existingItem.AbstainCount +
              (current.VoteName === "Abstain" ? current.Count : 0),
            NotReturnCount:
              existingItem.NotReturnCount +
              (current.VoteName === "No Returned" ? current.Count : 0),
            substentiveCount:
              existingItem.substentiveCount +
              (current.VoteName === "Substaintive" ? `${current.Count}` : 0),
            nonsubstaintiveCount:
              current.nonsubstaintiveCount === "Non Substaintive"
                ? current.Count
                : 0,
            noCommentCount:
              existingItem.noCommentCount +
              (current.VoteName === "No Comment" ? `${current.Count}` : 0),
            approvedMember:
              existingItem.approvedMember +
              (current.VoteName === "Approved" ? `${current.MemberName}` : ""),
            disapproveMember:
              existingItem.disapproveMember +
              (current.VoteName === "Disapproved"
                ? `${current.MemberName}`
                : ""),
            AbstainMember:
              existingItem.AbstainMember +
              (current.VoteName === "Abstain" ? `${current.MemberName}` : ""),
            notReturnedMember:
              existingItem.notReturnedMember +
              (current.VoteName === "Not Returned"
                ? `${current.MemberName}`
                : ""),
            noCommentMember:
              existingItem.noCommentMember +
              (current.VoteName === "No Comment"
                ? `${current.MemberName}`
                : ""),
            substentiveMember:
              existingItem.substentiveMember +
              (current.VoteName === "Substaintive"
                ? `${current.MemberName}`
                : ""),
            nonsubstentiveMember:
              existingItem.nonsubstentiveMember +
              (current.VoteName === "Non Substaintive"
                ? `${current.MemberName}`
                : ""),
          };
        } else {
          // Add new entry if it's not present
          acc.push({
            BallotId: current.BallotId,
            BallotNumber: current.BallotNumber,
            CommitteeId: current.CommitteeId,
            CommitteesName: current.CommitteesName,
            RecordId: current.RecordId,
            RecordNumber: current.RecordNumber,
            ApproveCount: current.VoteName === "Approved" ? current.Count : 0,
            DisapproveCount:
              current.VoteName === "Disapproved" ? current.Count : 0,
            AbstainCount: current.VoteName === "Abstain" ? current.Count : 0,
            NotReturnCount:
              current.VoteName === "No Returned" ? current.Count : 0,
            noCommentCount:
              current.VoteName === "No Comment" ? current.Count : 0,
            nonsubstaintiveCount:
              current.VoteName === "Non Substaintive" ? current.Count : 0,
            substaintiveCount:
              current.VoteName === "Substaintive" ? current.Count : 0,
            noReturnedCount:
              current.VoteName === "Not Returned" ? current.Count : 0,
            approvedMember:
              current.VoteName === "Approved" ? current.MemberName : "",
            disapproveMember:
              current.VoteName === "Disapproved" ? current.MemberName : "",
            AbstainMember:
              current.VoteName === "Abstain" ? current.MemberName : "",
            notReturnedMember:
              current.VoteName === "No Returned" ? current.MemberName : "",
            substaintiveMember:
              current.VoteName === "Substaintive" ? current.MemberName : "",
            nonsubstaintiveMember:
              current.VoteName === "Non-Substaintive" ? current.MemberName : "",
            noCommentMember:
              current.VoteName === "No Comment" ? current.MemberName : "",
          });
        }
        return acc;
      }, existingCommitteeSummary);

      return {
        ...prevSummaries,
        [recordId]: {
          ...prevSummaries[recordId],
          [committeeID]: mergedSummary,
        },
      };
    });
  };

  const fetchVoteSumarray = async (
    recordId: any,
    committeeID: any,
    ballotId: any
  ) => {
    try {
      const ballotTypesData = await fetchData(
        `BallotRecordVote/BallotRecordVoteSummary?Filters[0].Key=ballotid&Filters[0].Value=${ballotId}&Filters[1].Key=recordids&Filters[1].Value=${recordId}&Filters[2].Key=committeeid&Filters[2].Value=${committeeID}`
      );
      const cleanedData = ballotTypesData.Collection;
      return cleanedData;
    } catch (error) {
      console.error("Error fetching data:");
    }
  };

  return (
    <>
      <div className="flex flex-column md:flex-row gap-4 mb-5">
        <div className="p-0 bg-white flex flex-column gap-0 md:gap-4 shadow-md w-full md:w-10rem flex-shrink-0">
          <h2 className="m-0 p-3 text-title display-xs">
            {t("ballot.records")}
          </h2>
          <div className="tabs-wrap">
            <TabView
              scrollable
              className="md:vertical-tabs"
              activeIndex={activeIndex}
              onTabChange={(e) => {
                setActiveIndex(e.index);
                const selectedRecord = recordLists[e.index];
                setSelectedRecordApplicantName(
                  selectedRecord.record.userProfile.firstname +
                    " " +
                    selectedRecord.record.userProfile.lastname
                );
                setSelectedRecordId(selectedRecord.record.id); // Update the active record ID
                callFunctionForRecord(selectedRecord); // Call the function for the selected record
              }}
            >
              {recordLists.map((recordData: any, index: any) => (
                <TabPanel
                  key={index}
                  header={
                    <>
                      {recordData?.record?.recordNumber} <br /> Paityn Lubin
                    </>
                  }
                />
              ))}
            </TabView>
          </div>
        </div>
        <div className="flex flex-column w-full gap-4">
          <div className="bg-white flex flex-wrap align-items-center justify-content-between px-4 py-3 gap-3">
            <div className="flex flex-column gap-1">
              <p className="text-base font-normal text-capitalize m-0">
                {t("ballot.applicantName")}
              </p>
              <h3 className="text-title text-base font-bold text-capitalize m-0">
                {selectedRecordApplicantName}
              </h3>
            </div>
            <Button
              label={t("ballot.viewRecord")}
              onClick={() => handleRecordRediretion(selectedRecordId)}
              className="button-md"
              severity="secondary"
            />
          </div>
          {Object.keys(voteSummaries).length === 0 ||
          !activeRecordId ||
          !voteSummaries[activeRecordId] ? (
            <div className="bg-white text-center py-8">No data available</div>
          ) : (
            Object.keys(voteSummaries).map((recordId: any) => {
              if (recordId === activeRecordId) {
                return (
                  <div key={recordId}>
                    {Object.keys(voteSummaries[recordId]).map((committeeId) => (
                      <div key={committeeId}>
                        {voteSummaries[recordId][committeeId].map(
                          (summary: SummaryType, index: number) => {
                            const documentStyle = getComputedStyle(
                              document.documentElement
                            );
                            const isReviewAndComment =
                              intialInvitedCommittee?.find(
                                (committee: any) =>
                                  committee.CommitteeId ===
                                    summary.CommitteeId &&
                                  committee.ForReviewAndComment
                              );
                            const data = {
                              labels: isReviewAndComment
                                ? [
                                    "Substentive",
                                    "Non-Substantive",
                                    "No Comment",
                                  ]
                                : [
                                    "Approved",
                                    "Disapproved",
                                    "Abstained",
                                    "Not Return",
                                  ],
                              datasets: [
                                {
                                  data: isReviewAndComment
                                    ? [
                                        summary.nonsubstaintiveCount,
                                        summary.substaintiveCount,
                                        summary.noCommentCount,
                                      ]
                                    : [
                                        summary.ApproveCount,
                                        summary.DisapproveCount,
                                        summary.AbstainCount,
                                        summary.NotReturnCount,
                                      ],
                                  backgroundColor: isReviewAndComment
                                    ? [
                                        documentStyle.getPropertyValue(
                                          "--approved"
                                        ),
                                        documentStyle.getPropertyValue(
                                          "--abstained"
                                        ),
                                        documentStyle.getPropertyValue(
                                          "--not-return"
                                        ),
                                      ]
                                    : [
                                        documentStyle.getPropertyValue(
                                          "--approved"
                                        ),
                                        documentStyle.getPropertyValue(
                                          "--disapproved"
                                        ),
                                        documentStyle.getPropertyValue(
                                          "--abstained"
                                        ),
                                        documentStyle.getPropertyValue(
                                          "--not-return"
                                        ),
                                      ],
                                  hoverBackgroundColor: isReviewAndComment
                                    ? [
                                        documentStyle.getPropertyValue(
                                          "--approved"
                                        ),
                                        documentStyle.getPropertyValue(
                                          "--abstained"
                                        ),
                                        documentStyle.getPropertyValue(
                                          "--not-return"
                                        ),
                                      ]
                                    : [
                                        documentStyle.getPropertyValue(
                                          "--approved"
                                        ),
                                        documentStyle.getPropertyValue(
                                          "--disapproved"
                                        ),
                                        documentStyle.getPropertyValue(
                                          "--abstained"
                                        ),
                                        documentStyle.getPropertyValue(
                                          "--not-return"
                                        ),
                                      ],
                                },
                              ],
                            };
                            const options = {
                              plugins: {
                                legend: {
                                  display: false,
                                  labels: {
                                    usePointStyle: true,
                                  },
                                },
                              },
                            };
                            return (
                              <>
                                <div
                                  className="card bg-white w-full shadow-md mb-4"
                                  key={index}
                                >
                                  <div className="flex flex-wrap align-items-center px-4 py-3 border-bottom-1 border-gray-200 gap-3 cardHeader">
                                    <h4 className="text-title text-base font-bold text-capitalize m-0">
                                      {summary.CommitteesName}
                                    </h4>
                                    <div className="flex flex-wrap align-items-center gap-2 ml-auto">
                                      <span>{t("ballot.comments")}</span>
                                      <Chip
                                        className="text-sm bg-white border-1 custom-chip"
                                        label={`2/4 ${t("ballot.resolved")}`}
                                      />
                                      <Chip
                                        className="text-sm bg-white border-1 custom-chip"
                                        label={`2/4 ${t("ballot.responded")}`}
                                      />
                                    </div>
                                  </div>
                                  <div className="p-4 cardBody flex flex-column gap-5 lg:flex-row justify-content-between">
                                    <div className="flex flex-column gap-5">
                                      {intialInvitedCommittee?.find(
                                        (committee: any) =>
                                          committee.CommitteeId ===
                                            summary.CommitteeId &&
                                          committee.ForReviewAndComment
                                      ) ? (
                                        <>
                                          <div className="flex flex-column gap-2">
                                            <div>
                                              <Badge
                                                value={
                                                  summary.nonsubstaintiveCount >
                                                  0
                                                    ? `${summary.nonsubstaintiveCount} Non Substaintive`
                                                    : `Non Substaintive`
                                                }
                                                className="approved"
                                              />
                                            </div>
                                            <div>
                                              <span>
                                                {summary.nonsubstaintiveMember &&
                                                summary.nonsubstaintiveMember
                                                  .length > 0
                                                  ? summary.nonsubstaintiveMember
                                                  : "None"}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="flex flex-column gap-2">
                                            <div>
                                              <Badge
                                                value={
                                                  summary.substaintiveCount > 0
                                                    ? `${summary.substaintiveCount} Substaintive`
                                                    : `Substaintive`
                                                }
                                                className="abstained"
                                              />
                                            </div>
                                            <div>
                                              <span>
                                                {summary.substaintiveMember &&
                                                summary.substaintiveMember
                                                  .length > 0
                                                  ? summary.substaintiveMember
                                                  : "None"}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="flex flex-column gap-2">
                                            <div>
                                              <Badge
                                                value={
                                                  summary.noCommentCount > 0
                                                    ? `${summary.noCommentCount} No Comment`
                                                    : `No Comment`
                                                }
                                                className="not-return"
                                              />
                                            </div>
                                            <div>
                                              <span>
                                                {summary.noCommentMember &&
                                                summary.noCommentMember.length >
                                                  0
                                                  ? summary.noCommentMember
                                                  : "None"}
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="flex flex-column gap-2">
                                            <div>
                                              <Badge
                                                value={
                                                  summary.ApproveCount > 0
                                                    ? `${summary.ApproveCount} ${t("ballot.approved")}`
                                                    : `${t("ballot.approved")}`
                                                }
                                                className="approved"
                                              />
                                            </div>
                                            <div>
                                              <span>
                                                {summary.approvedMember &&
                                                summary.approvedMember.length >
                                                  0
                                                  ? summary.approvedMember
                                                  : "None"}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="flex flex-column gap-2">
                                            <div>
                                              <Badge
                                                value={
                                                  summary.DisapproveCount > 0
                                                    ? `${summary.DisapproveCount} ${t(
                                                        "ballot.disapproved"
                                                      )}`
                                                    : `${t("ballot.disapproved")}`
                                                }
                                                className="disapproved"
                                              ></Badge>
                                            </div>
                                            <div>
                                              <span>
                                                {summary.disapproveMember &&
                                                summary.disapproveMember
                                                  .length > 0
                                                  ? summary.disapproveMember
                                                  : "None"}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="flex flex-column gap-2">
                                            <div>
                                              <Badge
                                                value={
                                                  summary.AbstainCount > 0
                                                    ? `${summary.AbstainCount} Abstain`
                                                    : "Abstain"
                                                }
                                                className="abstained"
                                              ></Badge>
                                            </div>
                                            <div>
                                              <span>
                                                {summary.AbstainMember &&
                                                summary.AbstainMember.length > 0
                                                  ? summary.AbstainMember
                                                  : "None"}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="flex flex-column gap-2">
                                            <div>
                                              <Badge
                                                value={
                                                  summary.NotReturnCount > 0
                                                    ? `${summary.NotReturnCount} No Return`
                                                    : "No Return"
                                                }
                                                className="not-return"
                                              ></Badge>
                                            </div>
                                            <div>
                                              <span>
                                                {summary.notReturnedMember &&
                                                summary.notReturnedMember
                                                  .length > 0
                                                  ? summary.notReturnedMember
                                                  : "None"}
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div className="flex px-3 py-3 xl:px-8 xl:py-6 justify-content-center align-items-center">
                                      <Chart
                                        type="pie"
                                        data={data}
                                        options={options}
                                        className="w-auto md:w-16rem"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    ))}
                  </div>
                );
              }
            })
          )}
        </div>
      </div>
    </>
  );
};

export default withLoader(BallotTabVoteSummary);

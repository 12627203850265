import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectRecords from "../common/SelectRecords";
import BallotDetails from "../common/SelectBallotDetails";
import CreateBallotHeader from "../common/BallotHeader";
import SelectCommittee from "../common/SelectCommittee";
import { ballotDetails } from "../../../slices/ballotDetails";
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";
import Preview from "../common/Preview";
import { fetchData } from "../../../services/Ballot/apiservice";
import { fetchData as getData } from "../../../services/apiService";
import {
  BallotRecordStatus,
  BallotRecordStatusResponse,
  BallotStatus,
  BallotStatusResponse,
  Committee,
  CommitteeResponse,
} from "../common/type";
import { clearAllSessionStorage } from "../../../utils/storage";

const CreateNewBallot = () => {
  clearAllSessionStorage();
  const { stepCount } = useSelector((state: any) => state.ballotSelectRecords);
  const [activeStep, setActiveSteps] = useState<number>(stepCount);
  const stepController = (step: number) => {
    setActiveSteps(step);
  };
  const dispatch = useDispatch();
  const fetchParentCommittee = async () => {
    try {
      const primaryCommitteeResponse: CommitteeResponse =
        await getData(`Committee/GetAll`);
      const primaryCommittee = primaryCommitteeResponse.Collection.filter(
        (committee: Committee) => committee.Status.Name === "Created"
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: primaryCommittee,
          label: "primarycommittee",
        })
      );
    } catch (err) {
      console.log(err, "err");
    }
  };

  const handleDropDownOptions = async () => {
    try {
      const userProfileId = window.localStorage.userProfileID;
      const [
        accreditationBody,
        ballotType,
        ballotSubType,
        ballotLevel,
        recordType,
        recordSubType,
        membershipPosition,
      ] = await Promise.all([
        getData(`AccreditationBody/GetAll`),
        fetchData("BallotType/GetAll"),
        fetchData("BallotSubType/GetAll"),
        fetchData("BallotLevel/GetAll"),
        getData("RecordType/GetAll"),
        fetchData("RecordSubType/GetAll"),
        getData("MembershipPosition/GetAll"),
      ]);
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: accreditationBody,
          label: "accreditationType",
        })
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: ballotType,
          label: "ballottype",
        })
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: ballotSubType,
          label: "ballotsubtype",
        })
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: ballotLevel,
          label: "ballotlevel",
        })
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: recordType,
          label: "recordtype",
        })
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: recordSubType,
          label: "recordsubtype",
        })
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: membershipPosition,
          label: "positions",
        })
      );
    } catch (err) {
      console.log(err, "err");
    }
  };
  const getAllBallotStatus = async () => {
    try {
      const ballotStatusResponse: BallotStatusResponse =
        await fetchData(`BallotStatus/GetAll`);
      const allBallotStatusFormatted = ballotStatusResponse.Collection.map(
        (ballot: BallotStatus) => {
          return {
            id: ballot.Id,
            name: ballot.Name,
          };
        }
      );
      dispatch(
        ballotDetails.addBallotStatusList({ value: allBallotStatusFormatted })
      );
    } catch (err) {
      console.log(err, "err");
    }
  };
  const getAllBallotRecordStatus = async () => {
    try {
      const ballotRecordStatusResponse: BallotRecordStatusResponse =
        await fetchData(`BallotRecordStatus/GetAll`);
      const allBallotRecordStatusFormatted =
        ballotRecordStatusResponse.Collection.map(
          (ballot: BallotRecordStatus) => {
            return {
              id: ballot.Id,
              name: ballot.Name,
            };
          }
        );
      dispatch(
        ballotDetails.addBallotRecordStatusList({
          value: allBallotRecordStatusFormatted,
        })
      );
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getAllBallotStatus();
    getAllBallotRecordStatus();
    dispatch(ballotDetails.reset());
    dispatch(ballotSelectRecords.reset());
  }, []);
  useEffect(() => {
    setActiveSteps(stepCount);
  }, [stepCount]);
  useEffect(() => {
    handleDropDownOptions();
    fetchParentCommittee();
  }, []);
  return (
    <>
      <CreateBallotHeader activeStep={activeStep} />
      {activeStep === 0 && <SelectRecords />}
      {activeStep === 1 && <BallotDetails />}
      {activeStep === 2 && <SelectCommittee />}
      {activeStep === 3 && <Preview />}
    </>
  );
};

export default CreateNewBallot;

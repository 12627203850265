import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ballotCommittee {
    ballotCommitteeData: Record<string, any>;
    updatedCommitteeData: Record<string, any>;
}
const initialState: ballotCommittee = {
    ballotCommitteeData: [],
    updatedCommitteeData: [],
}
const selectBallotCommittee = createSlice({
    name: 'ballotCommittees',
    initialState,
    reducers: {
        initialBallotCommitteeValues: (state,
            action: PayloadAction<Record<string, any>>) => {
            state.ballotCommitteeData = action.payload.value;
            const formattedObj = action.payload.value.map((committee: any) => {
                return {
                    isDirty: true,
                    isNew: false,
                    ballotId: committee.BallotId,
                    committeeId: committee.BallotCommitteeId,
                    ballotRecordStatusId: action.payload.statusId,
                    isRevivewCommentCommittee: committee.ForReviewAndComment,
                    ballotCommitteeId: committee.BallotCommitteeId,
                    isActive: true,
                    isDeleted: false
                }
            })
            state.updatedCommitteeData = formattedObj;
        },
        updatedCommitteeData: (state,
            action: PayloadAction<Record<string, any>>) => {
            // const filterObj = state.updatedCommitteeData.filter((committee: any) => committee.Committee.Id !== action.payload.value)
            const { value, label, isReview } = action.payload;

            switch (label) {
                case ('delete'):
                    state.updatedCommitteeData = state.updatedCommitteeData.map((committee: any) =>
                        committee.committeeId === value
                            ? {
                                ...committee, isDirty: true, isRevivewCommentCommittee: null,
                                isNew: false, isActive: false, isDeleted: true
                            }
                            : committee
                    );
                    break;
                case ('update'):
                    state.updatedCommitteeData = state.updatedCommitteeData.map((committee: any) =>
                        committee.committeeId === value
                            ? {
                                ...committee, isDirty: true, isRevivewCommentCommittee: isReview,
                                isNew: false, isActive: true, isDeleted: false
                            }
                            : committee
                    );
                    break;
                default:
                    break;
            }
        },
        reset: () => initialState,
    }
});

export const ballotCommittees = selectBallotCommittee.actions
export default selectBallotCommittee.reducer
import React, { useState, useEffect } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { useNavigate } from "react-router-dom";
import { deleteData, fetchData } from "../../services/apiService";
import { confirmDialog } from "primereact/confirmdialog";
import pdfIcon from "../../assets/icons/fileIcon.svg";

interface DocumentUpload {
  containerName: string;
  sasToken: string;
  storageAccountName: string;
}

type CallbackFunction = (arg1: any, arg2: any) => void;

interface Document {
  Id: string;
  DocumentName: string;
  BlobURL: string;
}

interface Props {
  documentType: string;
  handleDocumentDetailsAvailable: CallbackFunction;
  refreshList?: string;
  userProfileID: string;
  isDelete?: boolean;
  isDownload?: boolean;
  isDocumentType?: boolean;
}

const ListDocuments: React.FC<Props> = ({
  documentType,
  handleDocumentDetailsAvailable,
  refreshList,
  userProfileID,
  isDelete,
  isDownload,
  isDocumentType
}) => {
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const navigate = useNavigate();
  const [userDocumentTypeId, setUserDocumentTypeId] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const [documentDetails, setDocumentDetails] = useState<Document[] | null>(
    null
  );
  const [documentUploadData, setDocumentUploadData] = useState<
    DocumentUpload | undefined
  >();

  const getAll = async () => {
    const fetchAllDocuments = await fetchData("DocumentType/GetAll");
    const documents = fetchAllDocuments.Collection;

    for (const document of documents) {
      if (document.TypeName === documentType) {
        setUserDocumentTypeId(document.Id);
        break;
      }
    }
  };

  const handleGetAllDocumentUpload = async () => {
    if (!userDocumentTypeId) return;
    const response = await fetchData(
      `UserDocument/Search?Filters[0].Key=DocumentTypeId&Filters[0].Value=${userDocumentTypeId}&Filters[1].Key=UserProfileId&Filters[1].Value=${userProfileID}&PageIndex=-1`
    );

    const documentData: Document[] = response.Collection;
    setDocumentDetails(updateDocumentNames(documentData));
    if (documentData.length > 0) {
      handleDocumentDetailsAvailable(true, documentType);
    } else {
      handleDocumentDetailsAvailable(false, documentType);
    }
  };

  const updateDocumentNames = (documents: Document[]): Document[] => {
    const nameCount: { [key: string]: number } = {};
    const updatedDocuments = documents.map((doc) => {
      if (nameCount[doc.DocumentName]) {
        nameCount[doc.DocumentName]++;
      } else {
        nameCount[doc.DocumentName] = 1;
      }

      if (nameCount[doc.DocumentName] > 1) {
        doc.DocumentName += `_(${nameCount[doc.DocumentName]})`;
      }

      return doc;
    });

    return updatedDocuments;
  };

  const handleFetchUserDocument = async () => {
    try {
      const documentResponse = await fetchData(
        `UserDocument/GetUserDocumentValetKey?userProfileId=${userProfileID}&userDocumentType=${documentType}`
      );
      setDocumentUploadData(documentResponse);
      return documentResponse;
    } catch (error) { }
  };

  const downloadDocument = async (blobUrl: string) => {
    try {
      const documentData = await handleFetchUserDocument();
      const sasToken = documentData?.sasToken;
      if (sasToken) {
        const urlWithToken = `${blobUrl}?${sasToken}`;
        window.open(urlWithToken, "_blank");
      } else {
        console.error(
          "SAS token is not available. Cannot download the document."
        );
      }
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  };

  const confirmDeleteDocument = (documentId: string) => {
    confirmDialog({
      header: t("profile.confirmation"),
      message: t("profile.areYouSureYouWantToDelete"),
      icon: "",
      acceptLabel: t("profile.yes"),
      rejectLabel: t("profile.no"),
      defaultFocus: "accept",
      accept: () => handleDeleteDocument(documentId),
    });
  };

  const handleDeleteDocument = async (documentId: string) => {
    try {
      if (documentId) {
        await deleteData("UserDocument", documentId);
        await handleGetAllDocumentUpload();
      } else {
        console.error("No id found in parameters.");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (userDocumentTypeId) {
      handleGetAllDocumentUpload();
    }
  }, [userDocumentTypeId]);

  const getDocumentTypeName = (documentType: string) => {
    switch (documentType) {
      case "UserProfile_Resume":
        return t("profile.resume");
      case "UserProfile_AdditionalDocument":
        return t("profile.additionalDocuments");
      case "UserProfile_Certification":
        return t("profile.certifications");
      default:
        return t("profile.document");
    }
  };

  return (
    <div className="mb-3">
      {isDocumentType && (
        <h3 className="m-0 font-bold text-base mb-3">
          {getDocumentTypeName(documentType)}
        </h3>
      )}
    
      {documentDetails && documentDetails.length > 0 ? (
        documentDetails.map((document: Document, index: number) => (
          <div key={index} className="flex align-items-center gap-2 mb-2">
            <span
              className="cursor-pointer flex align-items-center underline text-link"
              onClick={() => downloadDocument(document.BlobURL)}
            >
              <img src={pdfIcon} alt="PDF" className="mr-2" />
              <span className="font-bold break-all">
                {document.DocumentName}
              </span>
            </span>
            {isDelete && ( // Conditionally render the delete button
              <Button
              text
              className="p-button-plain text-delete flex-shrink-0"
              onClick={() => confirmDeleteDocument(document.Id)}
              aria-label={t("profile.delete")}
            >
              <FeatherIcon name="trash-2" size={20} onClick={undefined} />
            </Button>
            )}
            {
              isDownload && (
                <Button
                  text
                  className="p-button-plain text-link flex-shrink-0"
                  onClick={() => downloadDocument(document.BlobURL)}
                  aria-label={t("profile.download")}
                >
                  <FeatherIcon name="download" size={20} onClick={undefined} />
                </Button>
              )
            }
          </div>
        ))
      ) : (
        <p className="m-0">{t("profile.noDataAvailable")}</p>
      )}
    </div>
  );
};

export default ListDocuments;

import React, { useEffect, useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import CommitteeSteps from "./CommitteeSteps";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "../common/Table";
import { MenuItem } from "primereact/menuitem";
import pdfIcon from "../../assets/icons/pdf.svg";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getCommitteeData } from "../../utils/utils";
import { createCommitteeRequest } from "../../slices/createCommitteeSlice";
import { usePermission } from "../auth/Authorization";
import { fetchData } from "../../services/apiService";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import PreviewItem from "../common/PreviewItem";
interface Option {
  name: string;
  code: string;
}

interface CharterHistoryList {
  [x: string]: string;
  charter: string;
  recordId: string;
  charterApproval: string;
  updatedOn: string;
}

interface CommitteeHistoryList {
  [x: string]: string;
  fieldName: string;
  actionBy: string;
  oldValue: string;
  newValue: string;
  actionDate: string;
}
const CommitteeTabDetails: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const [auditHistoryList, setAuditHistorList] = useState<any>(null);
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [checked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };
  const [charterHistory, setCharterHistory] = useState<CharterHistoryList[]>(
    []
  );
  const [committeeHistory, setCommitteeHistory] = useState<
    CommitteeHistoryList[]
  >([]);
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const dispatch = useDispatch();
  const createCommitteeData = useSelector(
    (state: RootState) => state.createCommittee
  );
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const [categoryOfInteresetData, setCategoryOfInteresetData] = useState<any>();
  const percent = 70; // Set your percentage here

  // Define the style object as a regular JavaScript object
  const circleStyle = {
    "--percent": `${percent}`, // Set the percentage as a custom CSS variable
  };
  const { id } = useParams();
  const [dateActivation, setDateActivation] = useState(null);
  const [value, setValue] = useState("");
  const [tableTotalFooter, setTableTotalFooter] = useState<any>();
  const [COIandMemberData, setCOIandMemberData] = useState([]);
  const [balanceBasedOnANSI, setBalanceBasedOnANSI] = useState([]);
  const [balanceBasedOnCOI, setBalanceBasedOnCOI] = useState([]);
  const [balanceBasedOnRBC, setBalanceBasedOnRBC] = useState([]);

  const navigate = useNavigate();
  const handleEdit = () => {
    sessionStorage.setItem("committeeEdit", "On");
    navigate("/committee/create");
  };

  const fetchCommitteeCharterHistory = async () => {
    let committeeID;
    if (id) {
      committeeID = id;
    } else {
      committeeID = sessionStorage.getItem("committeeId");
    }
    try {
      let postResponseData = await fetchData(
        `CommitteeCharter/GetCommitteeCharterHistoryByCommiteeId?CommitteeId=${committeeID}`
      );
      const getCharterStatusById = async (Id: any) => {
        if (Id) {
          const charterStatusResponse = await fetchData(`CharterStatus/${Id}`);
          return charterStatusResponse.StatusName;
        }
        return "";
      };
      const formattedCharterHistory = await Promise.all(
        postResponseData.collection.map(async (item: any) => {
          const charterApproval = await getCharterStatusById(
            item.charterStatusId
          );

          return {
            charter: item.charter,
            recordId: item.charterApprovedRecordNumber,
            charterApproval: charterApproval,
            updatedOn: item.modifiedDate,
          };
        })
      );
      setCharterHistory(formattedCharterHistory);
      return postResponseData;
    } catch (error) {
      console.error("Error fetching committee history:", error);
    }
  };

  const fetchCommitteeCOIandMember = async () => {
    let committeeID;
    if (id) {
      committeeID = id;
    } else {
      committeeID = sessionStorage.getItem("committeeId");
    }
    try {
      const getCOIandMember = await fetchData(
        `Committee/GetListOfCOIWithMembercount?commiteeId=${committeeID}`
      );
      const formatCOIandMember = getCOIandMember?.map((data: any) => {
        return {
          categoryOfInterest: data.CategoryOfInterestName,
          memberCount: data.CountOfMembers,
          totalMemberCount: data.totalMemberCount,
        };
      });
      setCOIandMemberData(formatCOIandMember);

      const footerGroup = (
        <ColumnGroup>
          <Row>
            <Column footer={t("committee.totalMembersInCommittee")} />
            <Column footer={getCOIandMember[0].totalMemberCount} />
          </Row>
        </ColumnGroup>
      );

      setTableTotalFooter(footerGroup);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchBalanceBasedOnANSI = async () => {
    let committeeID;
    if (id) {
      committeeID = id;
    } else {
      committeeID = sessionStorage.getItem("committeeId");
    }
    try {
      const getBalanceBasedOnANSI = await fetchData(
        `Committee/GetBalanceBasedOnAnsi?committeeId=${committeeID}`
      );
      const formatBalanceBasedOnANSI = getBalanceBasedOnANSI?.map(
        (data: any) => {
          return {
            categoryOfInterest: data.CategoryOfInterestName,
            memberCount: data.CountOfMembers,
            max: data.MaxMember ? data.MaxMember : "NA",
            min: data.MinMember ? data.MinMember : "NA",
            COIPercentage: data.CategoryOfInterestPercentage + "%",
            threshold: data.Threshold ? data.Threshold + "%" : "NA",
          };
        }
      );
      setBalanceBasedOnANSI(formatBalanceBasedOnANSI);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchBalanceBasedOnCOI = async () => {
    let committeeID;
    if (id) {
      committeeID = id;
    } else {
      committeeID = sessionStorage.getItem("committeeId");
    }
    try {
      const getBalanceBasedOnCOI = await fetchData(
        `Committee/GetBalanceBasedOnCOI?commiteeId=${committeeID}`
      );
      const formatBalanceBasedOnCOI = getBalanceBasedOnCOI?.map((data: any) => {
        return {
          categoryOfInterest: data.CategoryOfInterestName,
          memberCount: data.CountOfMembers,
          COIPercentage: data.CategoryOfInterestPercentage + "%",
          maxMember: data.MaxMember ? data.MaxMember : "NA",
          minMember: data.MinMember ? data.MinMember : "NA",
          threshold: data.Threshold ? data.Threshold + "%" : "NA",
        };
      });
      setBalanceBasedOnCOI(formatBalanceBasedOnCOI);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchBalanceBasedOnRBC = async () => {
    let committeeID;
    if (id) {
      committeeID = id;
    } else {
      committeeID = sessionStorage.getItem("committeeId");
    }
    try {
      const getBalanceBasedOnRBC = await fetchData(
        `Committee/GetBalanceBasedOnOrganization?commiteeId=${committeeID}`
      );
      const formatBalanceBasedOnRBC = getBalanceBasedOnRBC?.map((data: any) => {
        return {
          company: data.CompanyName,
          currentCount: data.CurrentCount,
          organizationPercentage: data.OrganizationPercentage + "%",
          max: data.OrganisationMaxMember ? data.OrganisationMaxMember : "NA",
          min: data.MinMember ? data.MinMember : "NA",
          threshold: data.OrganisationMemberThreshold
            ? data.OrganisationMemberThreshold + "%"
            : "NA",
        };
      });
      setBalanceBasedOnRBC(formatBalanceBasedOnRBC);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCommitteeHistory = async () => {
    let committeeID: any;
    if (id) {
      committeeID = id;
    } else {
      committeeID = sessionStorage.getItem("committeeId");
    }
    try {
      let postResponseData = await fetchData(
        `Committee/GetCommitteeHistoryByCommitteeId?CommitteeId=${committeeID}`
      );
      const fetchAuditHistory = async () => {
        try {
          const response = await fetchData(`Audit/${committeeID}/Committee`);
          setAuditHistorList(response);
        } catch (error) {
          console.error("Error fetching committees:", error);
        }
      };
      fetchAuditHistory();

      const formattedCommitteeHistory = postResponseData.collection.map(
        (item: any) => ({
          fieldName: item?.status?.name,
          actionBy: [
            item?.staff?.firstname,
            item?.staff?.middleName,
            item?.staff?.lastname,
          ]
            .filter(Boolean)
            .join(" "),
          actionDate:
            item?.status?.name === "Draft"
              ? item?.createdDate
                ? new Date(item.createdDate).toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })
                : ""
              : item?.modifiedDate
                ? new Date(item.modifiedDate).toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })
                : "",
        })
      );

      setCommitteeHistory(formattedCommitteeHistory);
      return postResponseData;
    } catch (error) {
      console.error("Error fetching committee history:", error);
    }
  };

  useEffect(() => {
    fetchCommitteeCharterHistory();
    fetchCommitteeHistory();
    fetchCommitteeCOIandMember();
    fetchBalanceBasedOnANSI();
    fetchBalanceBasedOnCOI();
    fetchBalanceBasedOnRBC();
    if (!isEmpty(createCommitteeData.categoryOfIntereset)) {
      const categoryOfInteresetArray = Object.values(
        createCommitteeData.categoryOfIntereset
      )
        .map((val) => val)
        ?.map((details: any) => {
          return {
            categoryOfInterest: details.categoryOfInterestValue?.name,
            min: details?.min ? `${details?.min} Members` : "",
            threshold: details?.threshold ? `${details?.threshold}%` : "",
            max: details?.max ? `${details?.max} Members` : "",
          };
        });

      setCategoryOfInteresetData(categoryOfInteresetArray);
    }
    function isEmpty(obj: any) {
      return Object.keys(obj).length === 0;
    }
  }, [createCommitteeData.categoryOfIntereset]);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  return (
    <>
      <div className="">
        {createCommitteeData?.createCommittee && (
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
              <h2 className="text-title text-lg font-bold text-capitalize m-0">
                {t("committee.committeeDetails")}
              </h2>
              {userRole !== "Member" && (
                <Button
                  disabled={
                    userRole === "Admin" || userRole === "Staff" ? false : true
                  }
                  text
                  className="p-button-plain gap-2 underline"
                  onClick={() => handleEdit()}
                >
                  <FeatherIcon name="edit-2" size={20} />
                  <span>{t("committee.edit")}</span>
                </Button>
              )}
            </div>

            <div className="p-5 cardBody">
              <div className="flex flex-column gap-4">
                <PreviewItem
                  label={t("committee.committeeName")}
                  value={createCommitteeData.createCommittee?.committeeName}
                />
                <PreviewItem
                  label={t("committee.committeeAbbreviation")}
                  value={
                    createCommitteeData.createCommittee?.committeeAbbreviation
                  }
                />
                <PreviewItem
                  label={t("committee.parentCommittee")}
                  value={
                    createCommitteeData.createCommittee.parentCommittee?.name
                  }
                />
                <PreviewItem
                  label={t("committee.committeeType")}
                  value={
                    createCommitteeData.createCommittee?.hierarchyLevel?.name
                  }
                />
                <PreviewItem
                  label={t("committee.committeeCreatedOn")}
                  value={
                    createCommitteeData.configureCommittee?.createdDate &&
                    new Date(
                      createCommitteeData.configureCommittee?.createdDate
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })
                  }
                />
                <PreviewItem
                  label={t("committee.dateOfActivation")}
                  value={createCommitteeData.createCommittee?.activationDate?.toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                />
                <PreviewItem
                  label={t("committee.committeeSubType")}
                  value={
                    createCommitteeData.createCommittee?.committeeType?.name
                  }
                />
                <PreviewItem
                  label={t("committee.staffContact")}
                  value={createCommitteeData.configureCommittee?.staffName}
                />
              </div>
            </div>
          </div>
        )}

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.committeeCharterandHistory")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("committee.committeeCharter")}
                value={createCommitteeData.createCommittee?.charterName}
              />
              <PreviewItem
                label={t("committee.charterApproval")}
                value={createCommitteeData.createCommittee?.charterStatus?.name}
              />
              <PreviewItem
                label={t("committee.recordIdForTheApprovedCharter")}
                value={createCommitteeData.createCommittee?.recordId}
              />
              <div className="flex flex-column gap-3">
                <h3 className="m-0 font-bold text-capitalize text-lg text-title w-full">
                  {t("committee.charterHistory")}
                </h3>
                <Table
                  loading={false}
                  products={charterHistory}
                  columns={[
                    {
                      field: "charter",
                      header: t("committee.committeeCharter"),
                    },
                    {
                      field: "recordId",
                      header: t("committee.recordIdForTheApprovedCharter"),
                    },
                    {
                      field: "charterApproval",
                      header: t("committee.charterApproval"),
                    },
                    {
                      field: "updatedOn",
                      header: t("committee.updatedOn"),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.categoryInterestCommitteeBalance")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={COIandMemberData}
              footerGroup={tableTotalFooter}
              columns={[
                {
                  field: "categoryOfInterest",
                  header: t("committee.categoryOfInterest"),
                },
                { field: "memberCount", header: t("committee.members") },
              ]}
            />
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.BalanceBasedonANSICompliance")}
              <span className="font-normal">
                {balanceBasedOnANSI.length > 0
                  ? ": Committee Out of Balance"
                  : ": Committee In Balance"}
              </span>
            </h2>
          </div>

          {balanceBasedOnANSI.length > 0 && (
            <div className="p-5 cardBody">
              <Table
                loading={false}
                products={balanceBasedOnANSI}
                columns={[
                  {
                    field: "categoryOfInterest",
                    header: t("committee.categoryOfInterest"),
                  },
                  { field: "memberCount", header: t("committee.member") },
                  { field: "min", header: t("committee.minimum") },
                  { field: "max", header: t("committee.maximum") },
                  {
                    field: "COIPercentage",
                    header: t("committee.COIPercentage"),
                  },
                  {
                    field: "threshold",
                    header: t("committee.thresholdPercentage"),
                  },
                ]}
              />
            </div>
          )}
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.BalanceBasedonCategoryOfInterest")}
              <span className="font-normal">
                {balanceBasedOnCOI.length > 0
                  ? ": Committee Out of Balance"
                  : ": Committee In Balance"}
              </span>
            </h2>
          </div>

          {balanceBasedOnCOI.length > 0 && (
            <div className="p-5 cardBody">
              <Table
                loading={false}
                products={balanceBasedOnCOI}
                columns={[
                  {
                    field: "categoryOfInterest",
                    header: t("committee.categoryOfInterest"),
                  },
                  { field: "memberCount", header: t("committee.member") },
                  {
                    field: "minMember",
                    header: t("committee.minimum"),
                  },
                  {
                    field: "maxMember",
                    header: t("committee.maximum"),
                  },
                  {
                    field: "COIPercentage",
                    header: t("committee.COIPercentage"),
                  },
                  {
                    field: "threshold",
                    header: t("committee.thresholdPercentage"),
                  },
                ]}
              />
            </div>
          )}
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.BalanceBasedonRepersentationByCompany")}
              <span className="font-normal">
                {balanceBasedOnRBC.length > 0
                  ? ": Committee Out of Balance"
                  : ": Committee In Balance"}
              </span>
            </h2>
          </div>

          {balanceBasedOnRBC.length > 0 && (
            <div className="p-5 cardBody">
              <Table
                loading={false}
                products={balanceBasedOnRBC}
                columns={[
                  {
                    field: "company",
                    header: t("committee.company"),
                  },
                  {
                    field: "currentCount",
                    header: t("committee.member"),
                  },
                  { field: "min", header: t("committee.minimum") },
                  { field: "max", header: t("committee.maximum") },
                  {
                    field: "organizationPercentage",
                    header: t("committee.organizationPercentage"),
                  },

                  { field: "threshold", header: t("committee.balancePercent") },
                ]}
              />
            </div>
          )}
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.committeeActionHistory")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={committeeHistory}
              columns={[
                {
                  field: "fieldName",
                  header: t("committee.action"),
                },
                {
                  field: "actionBy",
                  header: t("committee.actionBy"),
                },
                {
                  field: "actionDate",
                  header: t("committee.actionDate"),
                },
              ]}
            />
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.auditHistory")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={auditHistoryList}
              columns={[
                {
                  field: "oldValues.CommitteeNumber",
                  header: t("committee.committeeId"),
                },
                {
                  field: "oldValues.Name",
                  header: t("committee.committeeName"),
                },
                {
                  field: "auditDate",
                  header: t("committee.action"),
                },
                {
                  field: "actionBy",
                  header: t("committee.actionBy"),
                },
                {
                  field: "actionDate",
                  header: t("committee.actionDate"),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommitteeTabDetails;

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { IDENTITY_URL } from "../../config";
import FeatherIcon from "../common/FeatherIconComponent";
import { getTenantNameFromDomain } from "../../utils/utils";

const ResetPassword: React.FC = () => {
  const [t, i18n] = useTranslation("auth");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showComfirmPassword, setShowComfirmPassword] = useState(false);
  const token = searchParams.get("t");
  const toast = useRef<Toast>(null);

  const handleToasterMessage = (msg: any) => {
    toast.current?.show({
      severity: "success",
      summary: "Success",
      detail: msg,
      life: 3000,
    });
  };

  const handlePasswordChange = (e: any) => {
    setError("");
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: any) => {
    setError("");
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@#$!%*?&]{8,}$/;
    // Check if passwords match
    if (password !== confirmPassword) {
      // Handle password mismatch error
      setError("Passwords do not match");
      return;
    } else if (password.length < 8) {
      setError("Passwords should contain at least 8 characters");
      return;
    } else if (!passwordRegex.test(password)) {
      setError(
        "Passwords should contain at least one numerical character and one uppercase character."
      );
      return;
    }
    let modifiedToken = token?.replaceAll(" ", "+");
    const ResetPasswordRequestBody = {
      password: password,
      confirmPassword: confirmPassword,
      token: modifiedToken,
      TenantDomain: getTenantNameFromDomain(),
    };

    const ResetPasswordRequest = await fetch(
      `${IDENTITY_URL}/Account/ResetPasswordWithToken`,
      {
        body: JSON.stringify(ResetPasswordRequestBody),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!ResetPasswordRequest.ok) {
      setError("Token is Expired");
    } else {
      handleToasterMessage("Password Updated Successfully");
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
    }
    // Optionally, you can reset the form state here
    setPassword("");
    setConfirmPassword("");
  };

  useEffect(() => {
    let modifiedToken = token?.replaceAll(" ", "+");
    let verifyToken = {
      token: modifiedToken,
      TenantDomain: getTenantNameFromDomain(),
    };

    const ResetPasswordRequest = fetch(
      `${IDENTITY_URL}/Account/ForgetPasswordTokenVerify`,
      {
        body: JSON.stringify(verifyToken),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowComfirmPassword(!showComfirmPassword);
  };

  return (
    <div className="flex align-items-center justify-content-center align-self-center h-full p-3">
      <Toast ref={toast} />
      <div className="surface-card p-fluid sm:px-4 md:px-8 shadow-2 border-round w-full loginCards">
        <div className="flex flex-column gap-6 md:p-8 p-5">
          <div className="flex flex-column gap-1">
            <h1 className="text-title display-sm font-bold m-0">
              {t("auth.resetPassword")}
            </h1>
            <p className="text-help text-base font-normal m-0">
              {t("auth.enterNewPasswordBelow")}
            </p>
          </div>
          <div className="flex flex-column gap-4">
            <div className="flex flex-column gap-2">
              <label
                htmlFor="password"
                className={`block font-bold text-capitalize text-input-label`}
              >
                {t("auth.newPassword")}*
              </label>
              <div className="flex align-content-center relative">
                <InputText
                  type={showPassword ? "text" : "password"}
                  id="password"
                  aria-describedby="passwordError"
                  value={password}
                  name="password"
                  onChange={(e) => handlePasswordChange(e)}
                  placeholder={t("auth.newPassword")}
                  className={`${error ? "p-invalid" : ""}  `}
                />
                <Button
                  text
                  className="p-2 absolute right-0 w-auto h-full"
                  aria-label={t("auth.togglePasswordVisibility")}
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <FeatherIcon
                      name="eye-off"
                      size={20}
                      color="var(--gray-600)"
                    />
                  ) : (
                    <FeatherIcon name="eye" size={20} color="var(--gray-600)" />
                  )}
                </Button>
              </div>
              <span
                id="passwordError"
                className={`p-error font-bold text-capitalize ${error ? "" : "error-hidden"}`}
              >
                {error}
              </span>
            </div>
            <div className="flex flex-column gap-2">
              <label
                htmlFor="confirmPassword"
                className={`block font-bold text-capitalize ${error ? " p-error" : "text-input-label"
                  }`}
              >
                {t("auth.confirmPassword")}*
              </label>
              <div className="flex align-content-center relative">
                <InputText
                  type={showComfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  aria-describedby="confirmPasswordError"
                  value={confirmPassword}
                  name="Password"
                  onChange={(e) => handleConfirmPasswordChange(e)}
                  placeholder={t("auth.confirmPassword")}
                  className={`${error ? "p-invalid" : ""}  `}
                />
                <Button
                  text
                  className="p-2 absolute right-0 w-auto h-full"
                  aria-label={t("auth.togglePasswordVisibility")}
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {showComfirmPassword ? (
                    <FeatherIcon
                      name="eye-off"
                      size={20}
                      color="var(--gray-600)"
                    />
                  ) : (
                    <FeatherIcon name="eye" size={20} color="var(--gray-600)" />
                  )}
                </Button>
              </div>
              <span
                id="confirmPasswordError"
                className={`p-error font-bold text-capitalize ${error ? "" : "error-hidden"}`}
              >
                {error}
              </span>
            </div>
          </div>
          <div className="flex flex-column gap-4">
            <Button
              label={t("auth.resetPassword")}
              className="w-full button-lg px-3"
              onClick={handleSubmit}
            />
            <div>
              <span>{t("auth.goBackTo")}</span>
              <Link
                to="/"
                className="underline font-bold ml-2 text-link"
              >
              {t("auth.logInText")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React, { useState, useRef, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import StandardTable from "./StandardTable";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "../common/FeatherIconComponent";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";

interface Option {
  name: string;
  code: string;
}

const NewStandard: React.FC = () => {
  const [t, i18n] = useTranslation("request");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();


  const { control, formState: { errors }, handleSubmit } = useForm();

  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const [onBehalfOf, setonBehalfOf] = useState<string>('');
  const [value, setValue] = useState<string>('');

  return (
    <>
      <div>
        <Button
          text
          className="p-button-plain gap-2 mb-3 pl-0 underline"
          onClick={() => navigate("/request/standard")}
        >
          <FeatherIcon name="arrow-left" size={20} />
          <span>{t("request.back")}</span>
        </Button>
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">
            {t("request.standardRequest")}
          </h1>
          <p className="text-base font-normal m-0">
            One line supporting text will appear here
          </p>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("request.newStandardRequestDetails")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl">
                <div className="flex flex-column gap-3">
                  <fieldset className="border-none p-0">
                    <legend className="block font-bold text-input-label p-0 mb-3">
                      {t("request.onBehalfOf")}
                    </legend>
                    <div className="flex flex-wrap gap-3">
                      <div className="flex align-items-center">
                        <RadioButton inputId="onBehalfOf1" name="onBehalfOf" value="Committee" onChange={(e: RadioButtonChangeEvent) => setonBehalfOf(e.value)} checked={onBehalfOf === 'Committee'} />
                        <label htmlFor="onBehalfOf1" className="ml-2">{t("request.onBehalfOf")}</label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton inputId="onBehalfOf2" name="onBehalfOf" value="Member" onChange={(e: RadioButtonChangeEvent) => setonBehalfOf(e.value)} checked={onBehalfOf === 'Member'} />
                        <label htmlFor="onBehalfOf2" className="ml-2">{t("request.member")}</label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton inputId="onBehalfOf3" name="onBehalfOf" value="None" onChange={(e: RadioButtonChangeEvent) => setonBehalfOf(e.value)} checked={onBehalfOf === 'None'} />
                        <label htmlFor="onBehalfOf3" className="ml-2">{t("request.none")}</label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="memberName"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("request.memberName")}
                  </label>
                  <Controller
                    name="memberName"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="memberName"
                        {...field}
                        placeholder={t("request.memberName")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>

                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="memberId"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("request.memberId")}
                  </label>
                  <Controller
                    name="memberId"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="memberId"
                        {...field}
                        placeholder={t("request.memberId")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
              </div>



              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="developedByExistingCommittee"
                    className={`block font-bold text-capitalize ${errors.developedByExistingCommittee ? " p-error" : "text-input-label"}`}
                  >
                    {t("request.developedByExistingCommittee")}*
                  </label>
                  <Controller
                    name="developedByExistingCommittee"
                    control={control}
                    rules={{ required: `${t("request.developedByExistingCommittee")} ${t("request.isRequired")}` }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="developedByExistingCommittee"
                        aria-describedby="developedByExistingCommitteeError"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("request.selectAnOption")}
                        className={`w-full ${errors.developedByExistingCommittee ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                  <span
                    id="developedByExistingCommitteeError"
                    className={`p-error font-bold text-capitalize ${errors.developedByExistingCommittee ? "" : "error-hidden"}`}
                  >
                    {`${errors.developedByExistingCommittee?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="titleProposedStandard"
                    className={`block font-bold text-capitalize ${errors.titleProposedStandard
                      ? "p-error"
                      : "text-input-label"
                      }`}
                  >
                    {t("request.titleProposedStandard")}*
                  </label>
                  <Controller
                    name="titleProposedStandard"
                    control={control}
                    rules={{ required: `${t("request.titleProposedStandard")} ${t("request.isRequired")}` }}
                    render={({ field }) => (
                      <InputText
                        id="titleProposedStandard"
                        aria-describedby="titleProposedStandardError"
                        {...field}
                        placeholder={t("request.titleProposedStandard")}
                        className={`w-full ${errors.titleProposedStandard ? "p-invalid" : ""
                          }`}
                      />
                    )}
                  />
                  <span
                    id="titleProposedStandardError"
                    className={`p-error font-bold text-capitalize ${errors.titleProposedStandard ? "" : "error-hidden"
                      }`}
                  >
                    {`${errors.titleProposedStandard?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="standardAbstract"
                    className={`block font-bold text-capitalize ${errors.standardAbstract ? " p-error" : "text-input-label"}`}
                  >
                    {t("request.standardAbstract")}*
                  </label>
                  <Controller
                    name="standardAbstract"
                    control={control}
                    rules={{ required: `${t("request.standardAbstract")} ${t("request.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="standardAbstract"
                        aria-describedby="standardAbstractError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("request.standardAbstract")}
                        className={`w-full ${errors.standardAbstract ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="standardAbstractError"
                    className={`p-error font-bold text-capitalize ${errors.standardAbstract ? "" : "error-hidden"}`}
                  >
                    {`${errors.standardAbstract?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="problemStatement"
                    className={`block font-bold text-capitalize ${errors.problemStatement ? " p-error" : "text-input-label"}`}
                  >
                    {t("request.problemStatement")}*
                  </label>
                  <Controller
                    name="problemStatement"
                    control={control}
                    rules={{ required: `${t("request.problemStatement")} ${t("request.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="problemStatement"
                        aria-describedby="problemStatementError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("request.problemStatement")}
                        className={`w-full ${errors.problemStatement ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="problemStatementError"
                    className={`p-error font-bold text-capitalize ${errors.problemStatement ? "" : "error-hidden"}`}
                  >
                    {`${errors.problemStatement?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="industryNeeds"
                    className={`block font-bold text-capitalize ${errors.industryNeeds ? " p-error" : "text-input-label"}`}
                  >
                    {t("request.industryNeeds")}*
                  </label>
                  <Controller
                    name="industryNeeds"
                    control={control}
                    rules={{ required: `${t("request.industryNeeds")} ${t("request.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="industryNeeds"
                        aria-describedby="industryNeedsError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("request.industryNeeds")}
                        className={`w-full ${errors.industryNeeds ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="industryNeedsError"
                    className={`p-error font-bold text-capitalize ${errors.industryNeeds ? "" : "error-hidden"}`}
                  >
                    {`${errors.industryNeeds?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="proposedBenefitStandard"
                    className={`block font-bold text-capitalize ${errors.proposedBenefitStandard ? " p-error" : "text-input-label"}`}
                  >
                    {t("request.proposedBenefitStandard")}*
                  </label>
                  <Controller
                    name="proposedBenefitStandard"
                    control={control}
                    rules={{ required: `${t("request.proposedBenefitStandard")} ${t("request.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="proposedBenefitStandard"
                        aria-describedby="proposedBenefitStandardError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("request.proposedBenefitStandard")}
                        className={`w-full ${errors.proposedBenefitStandard ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="proposedBenefitStandardError"
                    className={`p-error font-bold text-capitalize ${errors.proposedBenefitStandard ? "" : "error-hidden"}`}
                  >
                    {`${errors.proposedBenefitStandard?.message}`}
                  </span>
                </div>
              </div>



            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("request.relevance")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="standardDevelopedOtherOrganisation"
                    className={`block font-bold text-capitalize ${errors.standardDevelopedOtherOrganisation ? " p-error" : "text-input-label"}`}
                  >
                    {t("request.standardDevelopedOtherOrganisation")}*
                  </label>
                  <Controller
                    name="standardDevelopedOtherOrganisation"
                    control={control}
                    rules={{ required: `${t("request.standardDevelopedOtherOrganisation")} ${t("request.isRequired")}` }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="standardDevelopedOtherOrganisation"
                        aria-describedby="standardDevelopedOtherOrganisationError"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("request.selectAnOption")}
                        className={`w-full ${errors.standardDevelopedOtherOrganisation ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                  <span
                    id="standardDevelopedOtherOrganisationError"
                    className={`p-error font-bold text-capitalize ${errors.standardDevelopedOtherOrganisation ? "" : "error-hidden"}`}
                  >
                    {`${errors.standardDevelopedOtherOrganisation?.message}`}
                  </span>
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="standardForPatentedItems"
                    className={`block font-bold text-capitalize ${errors.standardForPatentedItems ? " p-error" : "text-input-label"}`}
                  >
                    {t("request.standardForPatentedItems")}*
                  </label>
                  <Controller
                    name="standardForPatentedItems"
                    control={control}
                    rules={{ required: `${t("request.standardForPatentedItems")} ${t("request.isRequired")}` }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="standardForPatentedItems"
                        aria-describedby="standardForPatentedItemsError"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("request.selectAnOption")}
                        className={`w-full ${errors.standardForPatentedItems ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                  <span
                    id="standardForPatentedItemsError"
                    className={`p-error font-bold text-capitalize ${errors.standardForPatentedItems ? "" : "error-hidden"}`}
                  >
                    {`${errors.standardForPatentedItems?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="potentiallyConflictingStandard"
                    className={`block font-bold text-capitalize ${errors.potentiallyConflictingStandard ? " p-error" : "text-input-label"}`}
                  >
                    {t("request.potentiallyConflictingStandard")}*
                  </label>
                  <Controller
                    name="potentiallyConflictingStandard"
                    control={control}
                    rules={{ required: `${t("request.potentiallyConflictingStandard")} ${t("request.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="potentiallyConflictingStandard"
                        aria-describedby="potentiallyConflictingStandardError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("request.potentiallyConflictingStandard")}
                        className={`w-full ${errors.potentiallyConflictingStandard ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="potentiallyConflictingStandardError"
                    className={`p-error font-bold text-capitalize ${errors.potentiallyConflictingStandard ? "" : "error-hidden"}`}
                  >
                    {`${errors.potentiallyConflictingStandard?.message}`}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("request.stakeholder")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="affectedStakeholders"
                    className={`block font-bold text-capitalize ${errors.affectedStakeholders ? " p-error" : "text-input-label"}`}
                  >
                    {t("request.affectedStakeholders")}*
                  </label>
                  <Controller
                    name="affectedStakeholders"
                    control={control}
                    rules={{ required: `${t("request.affectedStakeholders")} ${t("request.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="affectedStakeholders"
                        aria-describedby="affectedStakeholdersError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("request.affectedStakeholders")}
                        className={`w-full ${errors.affectedStakeholders ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="affectedStakeholdersError"
                    className={`p-error font-bold text-capitalize ${errors.affectedStakeholders ? "" : "error-hidden"}`}
                  >
                    {`${errors.affectedStakeholders?.message}`}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("request.standardProposalDocument")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <div className="flex flex-column gap-2">
                <h3 className="text-base font-bold m-0">
                  {t("request.proposalDocument")}
                </h3>
                <div>
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("request.uploadDocument")}
                    </span>
                  </Button>
                </div>
              </div>
              <div className="flex flex-column gap-2">
                <h3 className="text-base font-bold m-0">
                  {t("request.referenceDocument")}
                </h3>
                <div>
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("request.uploadDocument")}
                    </span>
                  </Button>
                </div>
              </div>
              <div className="flex flex-column gap-2">
                <h3 className="text-base font-bold m-0">
                  {t("request.additionalDocument")}
                </h3>
                <div>
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("request.uploadDocument")}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("request.cancel")}
            className="button-md"
            severity="secondary"
            type="button"
          // onClick={() => navigate("/request/")}
          />
          <Button
            label={t("request.submitRequest")}
            className="button-md"
            type="button"
          // onClick={() => navigate("/request/")}
          />
        </div>
      </div>
    </>
  );
};

export default NewStandard;

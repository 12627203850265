import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DocumentState {
  selectedDocumentId: string | null;
}

const initialState: DocumentState = {
  selectedDocumentId: null,
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setSelectedDocumentId(state, action: PayloadAction<string | null>) {
      state.selectedDocumentId = action.payload;
    },
  },
});

export const { setSelectedDocumentId } = documentSlice.actions;
export default documentSlice.reducer;

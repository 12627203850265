import React, { useState, useRef, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import StandardTable from "./StandardTable";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "../common/FeatherIconComponent";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
import PreviewItem from "../common/PreviewItem";

interface Option {
  name: string;
  code: string;
}

const StabilisedStandard: React.FC = () => {
  const [t, i18n] = useTranslation("request");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();


  const { control, formState: { errors }, handleSubmit } = useForm();

  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const [accredited, setaccredited] = useState<string>('');
  const [standardJointlyDeveloped, setstandardJointlyDeveloped] = useState<string>('');

  const [value, setValue] = useState<string>('');

  return (
    <>
      <div>
        <Button
          text
          className="p-button-plain gap-2 mb-3 pl-0 underline"
          onClick={() => navigate("/request/standard")}
        >
          <FeatherIcon name="arrow-left" size={20} />
          <span>{t("request.back")}</span>
        </Button>
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">
            {t("request.requestToStabilizeAStandard")}
          </h1>
          <p className="text-base font-normal m-0">
            One line supporting text will appear here
          </p>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("request.requesterInformation")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("request.requesterName")}
                value="Ajeng Cinta Purwanti "
              />
              <PreviewItem
                label={t("request.committee")}
                value={`Committee name 1 (Category of interest)`}
              />
              <PreviewItem
                label={t("request.requestedOn")}
                value="Sep 20, 2021"
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("request.standardDetails")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="standardAffected"
                    className={`block font-bold text-capitalize ${errors.standardAffected ? " p-error" : "text-input-label"}`}
                  >
                    {t("request.standardAffected")}*
                  </label>
                  <Controller
                    name="standardAffected"
                    control={control}
                    rules={{ required: `${t("request.standardAffected")} ${t("request.isRequired")}` }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="standardAffected"
                        aria-describedby="standardAffectedError"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("request.selectAnOption")}
                        className={`w-full ${errors.standardAffected ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                  <span
                    id="standardAffectedError"
                    className={`p-error font-bold text-capitalize ${errors.standardAffected ? "" : "error-hidden"}`}
                  >
                    {`${errors.standardAffected?.message}`}
                  </span>
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="standardPublishedYear"
                    className={`block font-bold text-capitalize ${errors.standardPublishedYear ? " p-error" : "text-input-label"}`}
                  >
                    {t("request.standardPublishedYear")}*
                  </label>
                  <Controller
                    name="standardPublishedYear"
                    control={control}
                    rules={{ required: `${t("request.standardPublishedYear")} ${t("request.isRequired")}` }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="standardPublishedYear"
                        aria-describedby="standardPublishedYearError"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("request.selectAnOption")}
                        className={`w-full ${errors.standardPublishedYear ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                  <span
                    id="standardPublishedYearError"
                    className={`p-error font-bold text-capitalize ${errors.standardPublishedYear ? "" : "error-hidden"}`}
                  >
                    {`${errors.standardPublishedYear?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-3">
                  <fieldset className="border-none p-0">
                    <legend className="block font-bold text-input-label p-0 mb-3">
                      {t("request.accredited")}?
                    </legend>
                    <div className="flex flex-wrap gap-3">
                      <div className="flex align-items-center">
                        <RadioButton inputId="accredited1" name="accredited" value="Yes" onChange={(e: RadioButtonChangeEvent) => setaccredited(e.value)} checked={accredited === 'Yes'} />
                        <label htmlFor="accredited1" className="ml-2">{t("request.yes")}</label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton inputId="accredited2" name="accredited" value="No" onChange={(e: RadioButtonChangeEvent) => setaccredited(e.value)} checked={accredited === 'No'} />
                        <label htmlFor="accredited2" className="ml-2">{t("request.no")}</label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="applicableAccreditationBody"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("request.applicableAccreditationBody")}
                  </label>
                  <Controller
                    name="applicableAccreditationBody"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="applicableAccreditationBody"
                        {...field}
                        placeholder={t("request.applicableAccreditationBody")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-3">
                  <fieldset className="border-none p-0">
                    <legend className="block font-bold text-input-label p-0 mb-3">
                      {t("request.standardJointlyDeveloped")}?
                    </legend>
                    <div className="flex flex-wrap gap-3">
                      <div className="flex align-items-center">
                        <RadioButton inputId="standardJointlyDeveloped1" name="standardJointlyDeveloped" value="Yes" onChange={(e: RadioButtonChangeEvent) => setstandardJointlyDeveloped(e.value)} checked={standardJointlyDeveloped === 'Yes'} />
                        <label htmlFor="standardJointlyDeveloped1" className="ml-2">{t("request.yes")}</label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton inputId="standardJointlyDeveloped2" name="standardJointlyDeveloped" value="No" onChange={(e: RadioButtonChangeEvent) => setstandardJointlyDeveloped(e.value)} checked={standardJointlyDeveloped === 'No'} />
                        <label htmlFor="standardJointlyDeveloped2" className="ml-2">{t("request.no")}</label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="developedInternalCommittees"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("request.developedInternalCommittees")}
                  </label>
                  <Controller
                    name="developedInternalCommittees"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="developedInternalCommittees"
                        {...field}
                        placeholder={t("request.developedInternalCommittees")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="developedExternalCommittees"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("request.developedExternalCommittees")}
                  </label>
                  <Controller
                    name="developedExternalCommittees"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="developedExternalCommittees"
                        {...field}
                        placeholder={t("request.developedExternalCommittees")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="primaryCommitteeResponsible"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("request.primaryCommitteeResponsible")}
                  </label>
                  <Controller
                    name="primaryCommitteeResponsible"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="primaryCommitteeResponsible"
                        {...field}
                        placeholder={t("request.primaryCommitteeResponsible")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="titleProposedRevision"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("request.titleProposedRevision")}
                  </label>
                  <Controller
                    name="titleProposedRevision"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="titleProposedRevision"
                        {...field}
                        placeholder={t("request.titleProposedRevision")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="explanationRationalProposal"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("request.explanationRationalProposal")}
                  </label>
                  <Controller
                    name="explanationRationalProposal"
                    control={control}
                    render={({ field }) => (
                      <InputTextarea
                        id="explanationRationalProposal"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("request.explanationRationalProposal")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="abstract"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("request.abstract")}
                  </label>
                  <Controller
                    name="abstract"
                    control={control}
                    render={({ field }) => (
                      <InputTextarea
                        id="abstract"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("request.abstract")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="sectionAffected"
                    className={`block font-bold text-capitalize ${errors.sectionAffected ? " p-error" : "text-input-label"}`}
                  >
                    {t("request.sectionAffected")}*
                  </label>
                  <Controller
                    name="sectionAffected"
                    control={control}
                    rules={{ required: `${t("request.sectionAffected")} ${t("request.isRequired")}` }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="sectionAffected"
                        aria-describedby="sectionAffectedError"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("request.selectAnOption")}
                        className={`w-full ${errors.sectionAffected ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                  <span
                    id="sectionAffectedError"
                    className={`p-error font-bold text-capitalize ${errors.sectionAffected ? "" : "error-hidden"}`}
                  >
                    {`${errors.sectionAffected?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="standardAffectedRevision"
                    className={`block font-bold text-capitalize text-input-label"}`}
                  >
                    {t("request.standardAffectedRevision")}
                  </label>
                  <Controller
                    name="standardAffectedRevision"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        inputId="standardAffectedRevision"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("request.selectAnOption")}
                        className={`w-full`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                </div>
              </div>


              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="additionalRemarkReferences"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("request.additionalRemarkReferences")}
                  </label>
                  <Controller
                    name="additionalRemarkReferences"
                    control={control}
                    render={({ field }) => (
                      <InputTextarea
                        id="additionalRemarkReferences"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("request.additionalRemarkReferences")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("request.addDocument")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <div className="flex flex-column gap-2">
                <h3 className="text-base font-bold m-0">
                  {t("request.proposalDocument")}*
                </h3>
                <div>
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("request.uploadDocument")}
                    </span>
                  </Button>
                </div>
              </div>
              <div className="flex flex-column gap-2">
                <h3 className="text-base font-bold m-0">
                  {t("request.referenceDocument")}
                </h3>
                <div>
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("request.uploadDocument")}
                    </span>
                  </Button>
                </div>
              </div>
              <div className="flex flex-column gap-2">
                <h3 className="text-base font-bold m-0">
                  {t("request.additionalDocument")}
                </h3>
                <div>
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("request.uploadDocument")}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("request.staffNotes")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 col-12">
                  <label
                    htmlFor="staffNotes"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("request.staffNotes")}
                  </label>
                  <Controller
                    name="staffNotes"
                    control={control}
                    render={({ field }) => (
                      <InputTextarea
                        id="staffNotes"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("request.staffNotes")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
                <div className="col-12">
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("request.uploadDocument")}
                    </span>
                  </Button>
                </div>
              </div>
              <div>
                <Button
                  text
                  className="p-button-plain gap-2 pl-0 underline"
                >
                  <span className="font-bold text-capitalize">
                    {t("request.addNewNote")}
                  </span>
                </Button>
              </div>

            </div>
          </div>
        </div>

        <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("request.cancel")}
            className="button-md"
            severity="secondary"
            type="button"
          // onClick={() => navigate("/request/")}
          />
          <Button
            label={t("request.createRequest")}
            className="button-md"
            type="button"
          // onClick={() => navigate("/request/")}
          />
        </div>
      </div>
    </>
  );
};

export default StabilisedStandard;

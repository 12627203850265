import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import Table from "../../common/Table";
import pdfIcon from "../../../assets/icons/pdf.svg";
import { useLanguageContext } from "../../LanguageContext";
import { fetchData } from "../../../services/Ballot/apiservice";
import PreviewItem from "../../common/PreviewItem";
import { formatDate } from "../../../utils/utils";
import { InvitedCommittee } from "../types/vote";
import withLoader from "../../common/LoaderIntercepter";

const BallotTabDetails: React.FC = () => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const [isNormalBallot, setIsNormalBallot] = useState<boolean>(false);
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [ballotDetails, setBallotDetails] = useState<any>([]);
  const [invitedCommittees, setInvitedCommitteess] = useState<
    InvitedCommittee[] | []
  >([]);
  const [reviewCommentCommitteess, setReviewCommentCommitteess] = useState<
    InvitedCommittee[] | []
  >([]);
  const getBallotDetailsHandler = async () => {
    let ballotId = sessionStorage.getItem("ballotTableDetails");
    try {
      const ballotDetailsData = await fetchData(`Ballot/${ballotId}`);
      const ballotCommitteessInvited = await fetchData(
        `BallotCommittees/GetBallotInvitedComitteeListByBallotId/${ballotId}`
      );
      setInvitedCommitteess(ballotCommitteessInvited);
      const reviewCommentCommittees = ballotCommitteessInvited.filter(
        (committee: InvitedCommittee) => committee.ForReviewAndComment
      );
      setReviewCommentCommitteess(reviewCommentCommittees);
      setBallotDetails(ballotDetailsData);
      if (ballotDetails?.BallotType?.Name === "Normal Ballot") {
        setIsNormalBallot(true);
      }
    } catch (err) {
      console.log(err, "errr");
    }
  };

  useEffect(() => {
    getBallotDetailsHandler();
  }, []);
  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.ballotDetails")}
            </h2>
            <Button
              label={t("ballot.open")}
              className="button-md"
              severity="secondary"
            />
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("ballot.ballotType")}
                value={ballotDetails?.BallotType?.Name}
              />
              <PreviewItem
                label={t("ballot.primaryCommitteeResponsible")}
                value={ballotDetails?.Committee?.Name}
              />
              <PreviewItem
                label={t("ballot.ballotLevel")}
                value={ballotDetails?.BallotLevel?.Name}
              />
              <PreviewItem
                label={t("ballot.recordType")}
                value={ballotDetails?.RecordType?.TypeName}
              />
              <PreviewItem
                label={t("ballot.recordSubType")}
                value={ballotDetails?.RecordSubType?.Type}
              />
              <PreviewItem label={t("ballot.recordSubSubType")} value="Value" />
              <PreviewItem
                label={t("ballot.ballotStartDate")}
                value={formatDate(ballotDetails?.OpenDate)}
              />
              <PreviewItem
                label={t("ballot.ballotEndDate")}
                value={formatDate(ballotDetails?.CloseDate)}
              />
              <PreviewItem
                label={t("ballot.confidentiality")}
                value={ballotDetails?.IsSecretBallot ? "Yes" : "No"}
              />
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                  {t("ballot.daysToIssue")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {ballotDetails?.DaysToIssue}
                </p>
              </div>
              <PreviewItem
                label={t("ballot.ballotDescription")}
                value={ballotDetails?.Description}
              />
              <PreviewItem
                label={t("ballot.ballotExplanation")}
                value={ballotDetails?.Explaination}
              />
              <PreviewItem
                label={t("ballot.notes")}
                value={ballotDetails?.Note}
              />
              <PreviewItem label={t("ballot.ballotClosureDate")} value="-" />
              <PreviewItem label={t("ballot.ballotClosureRemark")} value="-" />
            </div>
          </div>
        </div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.supportingDocument")}
            </h2>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <a className="m-0 flex align-items-center gap-2 underline">
                <img src={pdfIcon} alt="PDF" />
                <span className="text-link font-bold text-capitalize">
                  {"Random document B"}
                </span>
              </a>
              <a className="m-0 flex align-items-center gap-2 underline">
                <img src={pdfIcon} alt="PDF" />
                <span className="text-link font-bold text-capitalize">
                  {"Random document B"}
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.committeeInvitedForBalloting")}
            </h2>
          </div>
          <div className="p-5 cardBody">
            <Table
              products={invitedCommittees}
              loading={false}
              columns={[
                {
                  field: "CommitteeName",
                  header: "Committees for Balloting",
                },
                { field: "CommitteeMembershCount", header: "Members" },
              ]}
            />
          </div>
        </div>
        {isNormalBallot ? (
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
              <h2 className="text-title text-lg font-bold text-capitalize m-0">
                {t("ballot.committeeInvitedForReviewingAndCommenting")}
              </h2>
            </div>
            <div className="p-5 cardBody">
              <Table
                products={reviewCommentCommitteess}
                loading={false}
                columns={[
                  {
                    field: "CommitteeName",
                    header: "Committees for Balloting",
                  },
                  { field: "CommitteeMembershCount", header: "Members" },
                ]}
              />
            </div>
          </div>
        ) : null}
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.ballotActionHistory")}
            </h2>
          </div>
          <div className="p-5 cardBody">
            <Table
              products={[]}
              loading={false}
              columns={[
                {
                  field: "actionDescription",
                  header: t("ballot.actionDescription"),
                },
                { field: "dateOfAction", header: t("ballot.dateOfAction") },
                { field: "actionTakenBy", header: t("ballot.actionTakenBy") },
              ]}
            />
          </div>
        </div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.auditHistory")}
            </h2>
          </div>
          <div className="p-5 cardBody">
            <Table
              products={[]}
              loading={false}
              columns={[
                {
                  field: "date",
                  header: t("ballot.date"),
                },
                { field: "updatedBy", header: t("ballot.updatedBy") },
                { field: "changesMade", header: t("ballot.changesMade") },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withLoader(BallotTabDetails);

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";

interface Option {
  name: string;
  code: string;
}

const BasicDetails: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  const { control, formState: { errors }, handleSubmit } = useForm();

  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const [onBehalfOf, setonBehalfOf] = useState<string>('');
  const [accredited, setaccredited] = useState<string>('');

  const [value, setValue] = useState<string>('');


  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.newStandardRecordDetails")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl">
                <div className="flex flex-column gap-3">
                  <fieldset className="border-none p-0">
                    <legend className="block font-bold text-input-label p-0 mb-3">
                      {t("record.onBehalfOf")}
                    </legend>
                    <div className="flex flex-wrap gap-3">
                      <div className="flex align-items-center">
                        <RadioButton inputId="onBehalfOf1" name="onBehalfOf" value="Committee" onChange={(e: RadioButtonChangeEvent) => setonBehalfOf(e.value)} checked={onBehalfOf === 'Committee'} />
                        <label htmlFor="onBehalfOf1" className="ml-2">{t("record.committee")}</label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton inputId="onBehalfOf2" name="onBehalfOf" value="Member" onChange={(e: RadioButtonChangeEvent) => setonBehalfOf(e.value)} checked={onBehalfOf === 'Member'} />
                        <label htmlFor="onBehalfOf2" className="ml-2">{t("record.member")}</label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton inputId="onBehalfOf3" name="onBehalfOf" value="None" onChange={(e: RadioButtonChangeEvent) => setonBehalfOf(e.value)} checked={onBehalfOf === 'None'} />
                        <label htmlFor="onBehalfOf3" className="ml-2">{t("record.none")}</label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="memberName"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("record.memberName")}
                  </label>
                  <Controller
                    name="memberName"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="memberName"
                        {...field}
                        placeholder={t("record.memberName")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>

                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="memberId"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("record.memberId")}
                  </label>
                  <Controller
                    name="memberId"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="memberId"
                        {...field}
                        placeholder={t("record.memberId")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="remark"
                    className={`block font-bold text-capitalize ${errors.remark ? " p-error" : "text-input-label"}`}
                  >
                    {t("record.remark")}*
                  </label>
                  <Controller
                    name="remark"
                    control={control}
                    rules={{ required: `${t("record.remark")} ${t("record.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="remark"
                        aria-describedby="remarkError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("record.remark")}
                        className={`w-full ${errors.remark ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="remarkError"
                    className={`p-error font-bold text-capitalize ${errors.remark ? "" : "error-hidden"}`}
                  >
                    {`${errors.remark?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="publishingYear"
                    className={`block font-bold text-capitalize text-input-label"}`}
                  >
                    {t("record.publishingYear")}
                  </label>
                  <Controller
                    name="publishingYear"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        inputId="publishingYear"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("record.selectAnOption")}
                        className={`w-full`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-3">
                  <fieldset className="border-none p-0">
                    <legend className="block font-bold text-input-label p-0 mb-3">
                      {t("record.accredited")}?
                    </legend>
                    <div className="flex flex-wrap gap-3">
                      <div className="flex align-items-center">
                        <RadioButton inputId="accredited1" name="accredited" value="Yes" onChange={(e: RadioButtonChangeEvent) => setaccredited(e.value)} checked={accredited === 'Yes'} />
                        <label htmlFor="accredited1" className="ml-2">{t("record.yes")}</label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton inputId="accredited2" name="accredited" value="No" onChange={(e: RadioButtonChangeEvent) => setaccredited(e.value)} checked={accredited === 'No'} />
                        <label htmlFor="accredited2" className="ml-2">{t("record.no")}</label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="applicableAccreditationBody"
                    className={`block font-bold text-capitalize ${errors.applicableAccreditationBody ? " p-error" : "text-input-label"}`}
                  >
                    {t("record.applicableAccreditationBody")}*
                  </label>
                  <Controller
                    name="applicableAccreditationBody"
                    control={control}
                    rules={{ required: `${t("record.applicableAccreditationBody")} ${t("record.isRequired")}` }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="applicableAccreditationBody"
                        aria-describedby="applicableAccreditationBodyError"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("record.selectAnOption")}
                        className={`w-full ${errors.applicableAccreditationBody ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                  <span
                    id="applicableAccreditationBodyError"
                    className={`p-error font-bold text-capitalize ${errors.applicableAccreditationBody ? "" : "error-hidden"}`}
                  >
                    {`${errors.applicableAccreditationBody?.message}`}
                  </span>
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="canDevelopedByExistingCommittee"
                    className={`block font-bold text-capitalize ${errors.canDevelopedByExistingCommittee ? " p-error" : "text-input-label"}`}
                  >
                    {t("record.canDevelopedByExistingCommittee")}*
                  </label>
                  <Controller
                    name="canDevelopedByExistingCommittee"
                    control={control}
                    rules={{ required: `${t("record.canDevelopedByExistingCommittee")} ${t("record.isRequired")}` }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="canDevelopedByExistingCommittee"
                        aria-describedby="canDevelopedByExistingCommitteeError"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("record.selectAnOption")}
                        className={`w-full ${errors.canDevelopedByExistingCommittee ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                  <span
                    id="canDevelopedByExistingCommitteeError"
                    className={`p-error font-bold text-capitalize ${errors.canDevelopedByExistingCommittee ? "" : "error-hidden"}`}
                  >
                    {`${errors.canDevelopedByExistingCommittee?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="titleProposedStandard"
                    className={`block font-bold text-capitalize ${errors.titleProposedStandard
                      ? "p-error"
                      : "text-input-label"
                      }`}
                  >
                    {t("record.titleProposedStandard")}*
                  </label>
                  <Controller
                    name="titleProposedStandard"
                    control={control}
                    rules={{ required: `${t("record.titleProposedStandard")} ${t("record.isRequired")}` }}
                    render={({ field }) => (
                      <InputText
                        id="titleProposedStandard"
                        aria-describedby="titleProposedStandardError"
                        {...field}
                        placeholder={t("record.titleProposedStandard")}
                        className={`w-full ${errors.titleProposedStandard ? "p-invalid" : ""
                          }`}
                      />
                    )}
                  />
                  <span
                    id="titleProposedStandardError"
                    className={`p-error font-bold text-capitalize ${errors.titleProposedStandard ? "" : "error-hidden"
                      }`}
                  >
                    {`${errors.titleProposedStandard?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="productAbstract"
                    className={`block font-bold text-capitalize ${errors.productAbstract ? " p-error" : "text-input-label"}`}
                  >
                    {t("record.productAbstract")}*
                  </label>
                  <Controller
                    name="productAbstract"
                    control={control}
                    rules={{ required: `${t("record.productAbstract")} ${t("record.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="productAbstract"
                        aria-describedby="productAbstractError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("record.productAbstract")}
                        className={`w-full ${errors.productAbstract ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="productAbstractError"
                    className={`p-error font-bold text-capitalize ${errors.productAbstract ? "" : "error-hidden"}`}
                  >
                    {`${errors.productAbstract?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="problemStatement"
                    className={`block font-bold text-capitalize ${errors.problemStatement ? " p-error" : "text-input-label"}`}
                  >
                    {t("record.problemStatement")}*
                  </label>
                  <Controller
                    name="problemStatement"
                    control={control}
                    rules={{ required: `${t("record.problemStatement")} ${t("record.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="problemStatement"
                        aria-describedby="problemStatementError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("record.problemStatement")}
                        className={`w-full ${errors.problemStatement ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="problemStatementError"
                    className={`p-error font-bold text-capitalize ${errors.problemStatement ? "" : "error-hidden"}`}
                  >
                    {`${errors.problemStatement?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="industryNeeds"
                    className={`block font-bold text-capitalize ${errors.industryNeeds ? " p-error" : "text-input-label"}`}
                  >
                    {t("record.industryNeeds")}*
                  </label>
                  <Controller
                    name="industryNeeds"
                    control={control}
                    rules={{ required: `${t("record.industryNeeds")} ${t("record.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="industryNeeds"
                        aria-describedby="industryNeedsError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("record.industryNeeds")}
                        className={`w-full ${errors.industryNeeds ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="industryNeedsError"
                    className={`p-error font-bold text-capitalize ${errors.industryNeeds ? "" : "error-hidden"}`}
                  >
                    {`${errors.industryNeeds?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="proposedBenefitProduct"
                    className={`block font-bold text-capitalize ${errors.proposedBenefitProduct ? " p-error" : "text-input-label"}`}
                  >
                    {t("record.proposedBenefitProduct")}*
                  </label>
                  <Controller
                    name="proposedBenefitProduct"
                    control={control}
                    rules={{ required: `${t("record.proposedBenefitProduct")} ${t("record.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="proposedBenefitProduct"
                        aria-describedby="proposedBenefitProductError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("record.proposedBenefitProduct")}
                        className={`w-full ${errors.proposedBenefitProduct ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="proposedBenefitProductError"
                    className={`p-error font-bold text-capitalize ${errors.proposedBenefitProduct ? "" : "error-hidden"}`}
                  >
                    {`${errors.proposedBenefitProduct?.message}`}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.relevance")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="standardDevelopedOtherOrganisation"
                    className={`block font-bold text-capitalize ${errors.standardDevelopedOtherOrganisation ? " p-error" : "text-input-label"}`}
                  >
                    {t("record.standardDevelopedOtherOrganisation")}*
                  </label>
                  <Controller
                    name="standardDevelopedOtherOrganisation"
                    control={control}
                    rules={{ required: `${t("record.standardDevelopedOtherOrganisation")} ${t("record.isRequired")}` }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="standardDevelopedOtherOrganisation"
                        aria-describedby="standardDevelopedOtherOrganisationError"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("record.selectAnOption")}
                        className={`w-full ${errors.standardDevelopedOtherOrganisation ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                  <span
                    id="standardDevelopedOtherOrganisationError"
                    className={`p-error font-bold text-capitalize ${errors.standardDevelopedOtherOrganisation ? "" : "error-hidden"}`}
                  >
                    {`${errors.standardDevelopedOtherOrganisation?.message}`}
                  </span>
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="standardPatentedItems"
                    className={`block font-bold text-capitalize ${errors.standardPatentedItems ? " p-error" : "text-input-label"}`}
                  >
                    {t("record.standardPatentedItems")}*
                  </label>
                  <Controller
                    name="standardPatentedItems"
                    control={control}
                    rules={{ required: `${t("record.standardPatentedItems")} ${t("record.isRequired")}` }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="standardPatentedItems"
                        aria-describedby="standardPatentedItemsError"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("record.selectAnOption")}
                        className={`w-full ${errors.standardPatentedItems ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                  <span
                    id="standardPatentedItemsError"
                    className={`p-error font-bold text-capitalize ${errors.standardPatentedItems ? "" : "error-hidden"}`}
                  >
                    {`${errors.standardPatentedItems?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="potentiallyConflictingStandard"
                    className={`block font-bold text-capitalize ${errors.potentiallyConflictingStandard ? " p-error" : "text-input-label"}`}
                  >
                    {t("record.potentiallyConflictingStandard")}*
                  </label>
                  <Controller
                    name="potentiallyConflictingStandard"
                    control={control}
                    rules={{ required: `${t("record.potentiallyConflictingStandard")} ${t("record.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="potentiallyConflictingStandard"
                        aria-describedby="potentiallyConflictingStandardError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("record.potentiallyConflictingStandard")}
                        className={`w-full ${errors.potentiallyConflictingStandard ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="potentiallyConflictingStandardError"
                    className={`p-error font-bold text-capitalize ${errors.potentiallyConflictingStandard ? "" : "error-hidden"}`}
                  >
                    {`${errors.potentiallyConflictingStandard?.message}`}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.stakeholder")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="affectedStakeholders"
                    className={`block font-bold text-capitalize ${errors.affectedStakeholders ? " p-error" : "text-input-label"}`}
                  >
                    {t("record.affectedStakeholders")}*
                  </label>
                  <Controller
                    name="affectedStakeholders"
                    control={control}
                    rules={{ required: `${t("record.affectedStakeholders")} ${t("record.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="affectedStakeholders"
                        aria-describedby="affectedStakeholdersError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("record.affectedStakeholders")}
                        className={`w-full ${errors.affectedStakeholders ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="affectedStakeholdersError"
                    className={`p-error font-bold text-capitalize ${errors.affectedStakeholders ? "" : "error-hidden"}`}
                  >
                    {`${errors.affectedStakeholders?.message}`}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.addDocument")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <div className="flex flex-column gap-2">
                <h3 className="text-base font-bold m-0">
                  {t("record.proposalDocument")}
                </h3>
                <div>
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("record.uploadDocument")}
                    </span>
                  </Button>
                </div>
              </div>
              <div className="flex flex-column gap-2">
                <h3 className="text-base font-bold m-0">
                  {t("record.referenceDocuments")}
                </h3>
                <div>
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("record.uploadDocument")}
                    </span>
                  </Button>
                </div>
              </div>
              <div className="flex flex-column gap-2">
                <h3 className="text-base font-bold m-0">
                  {t("record.additionalDocument")}
                </h3>
                <div>
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("record.uploadDocument")}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.staffNotes")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 col-12">
                  <label
                    htmlFor="staffNotes"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("record.staffNotes")}
                  </label>
                  <Controller
                    name="staffNotes"
                    control={control}
                    render={({ field }) => (
                      <InputTextarea
                        id="staffNotes"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("record.staffNotes")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
                <div className="col-12">
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("record.uploadDocument")}
                    </span>
                  </Button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicDetails;

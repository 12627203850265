import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import FeatherIcon from "../../common/FeatherIconComponent";
import { useLanguageContext } from '../../LanguageContext';
interface CommentSectionProps {
  index: number;
  votingData: any;
  commentTypeOptions: any[];
  handleCommentChange: (index: number, field: string, value: any) => void;
}

const VotingCommentSection: React.FC<CommentSectionProps> = ({
    index,
    votingData,
    commentTypeOptions,
    handleCommentChange,
  }) => {
    const [t, i18n] = useTranslation("ballot");
    const { selectedLanguage } = useLanguageContext();
    const performAction = () => {
        i18n.changeLanguage(selectedLanguage);
      };
     
      React.useEffect(() => {
        performAction();
      }, [selectedLanguage]);
    return (
      <div className="flex flex-column gap-3">
        <fieldset className="border-none p-0">
          <legend className="block font-bold text-input-label p-0 mb-3">
            {t("ballot.comments")}
          </legend>
          <div className="p-3 lg:p-5 cardBody flex flex-column gap-4 surface-100">
            <div className="flex flex-column gap-2">
              <label htmlFor={`paragraphSection${index}`} className="block font-bold text-capitalize text-input-label">
                {t("ballot.paragraphSection")}*
              </label>
              <InputTextarea
                id={`paragraphSection${index}`}
                name="paragraphSection"
                placeholder={t("ballot.paragraphSection")}
                value={votingData[index]?.paragraphSection || ''}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  handleCommentChange(index, 'paragraphSection', e.target.value)
                }
                rows={3}
                cols={50}
              />
            </div>
            <div className="flex flex-column gap-2">
              <label
                htmlFor={`typeOfComment${index}`}
                className="block font-bold text-capitalize text-input-label"
              >
                {t("ballot.typeOfComment")}*
              </label>
              <Dropdown
                inputId={`typeOfComment${index}`}
                name="typeOfComment"
                value={votingData[index]?.commentType || ''}
                onChange={(e) => handleCommentChange(index, 'commentType', e.value)}
                options={commentTypeOptions}
                optionLabel="label"
                placeholder="Select an Option"
              />
            </div>
            <div className="flex flex-column gap-2">
              <label
                htmlFor={`comment${index}`}
                className="block font-bold text-capitalize text-input-label"
              >
                {t("ballot.comment")}
              </label>
              <InputTextarea
                id={`comment${index}`}
                name="charterName"
                placeholder={t("ballot.comment")}
                value={votingData[index]?.comment || ''}
                onChange={(e) => handleCommentChange(index, 'comment', e.target.value)}
                rows={3}
                cols={50}
              />
            </div>
            <div className="flex flex-column gap-2">
              <label
                htmlFor={`proposedAction${index}`}
                className="block font-bold text-capitalize text-input-label"
              >
                {t("ballot.proposedAction")}
              </label>
              <InputTextarea
                id={`proposedAction${index}`}
                name="charterName"
                placeholder={t("ballot.proposedAction")}
                value={votingData[index]?.proposedAction || ''}
                onChange={(e) => handleCommentChange(index, 'proposedAction', e.target.value)}
                rows={3}
                cols={50}
              />
            </div>
            <div>
              <Button
                text
                className="p-button-plain gap-2 pl-0 underline"
              >
                <FeatherIcon name="plus" size={20} color="inherit" />
                <span className="font-bold text-capitalize">
                  {t("ballot.uploadDocument")}
                </span>
              </Button>
            </div>
          </div>
        </fieldset>
      </div>
    );
  };
  
  export default VotingCommentSection;
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
import { useLanguageContext } from "../LanguageContext";
import FeatherIcon from "../common/FeatherIconComponent";

interface Option {
  name: string;
  code: string;
}

const OtherConfiguration: React.FC = () => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  const { control, formState: { errors }, handleSubmit } = useForm();

  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const [accredited, setaccredited] = useState<string>('');
  const [isStandardJointlyDeveloped, setisStandardJointlyDeveloped] = useState<string>('');

  const [value, setValue] = useState<string>('');


  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("standard.otherConfiguration")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl">
                <div className="flex flex-column gap-3">
                  <fieldset className="border-none p-0">
                    <legend className="block font-bold text-input-label p-0 mb-3">
                      {t("standard.accredited")}?
                    </legend>
                    <div className="flex flex-wrap gap-3">
                      <div className="flex align-items-center">
                        <RadioButton inputId="accredited1" name="accredited" value="Yes" onChange={(e: RadioButtonChangeEvent) => setaccredited(e.value)} checked={accredited === 'Yes'} />
                        <label htmlFor="accredited1" className="ml-2">{t("standard.yes")}</label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton inputId="accredited2" name="accredited" value="No" onChange={(e: RadioButtonChangeEvent) => setaccredited(e.value)} checked={accredited === 'No'} />
                        <label htmlFor="accredited2" className="ml-2">{t("standard.no")}</label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="applicableAccreditationBody"
                    className={`block font-bold text-capitalize ${errors.applicableAccreditationBody ? " p-error" : "text-input-label"}`}
                  >
                    {t("standard.applicableAccreditationBody")}*
                  </label>
                  <Controller
                    name="applicableAccreditationBody"
                    control={control}
                    rules={{ required: `${t("standard.applicableAccreditationBody")} ${t("standard.isRequired")}` }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="applicableAccreditationBody"
                        aria-describedby="applicableAccreditationBodyError"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("standard.selectAnOption")}
                        className={`w-full ${errors.applicableAccreditationBody ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                  <span
                    id="applicableAccreditationBodyError"
                    className={`p-error font-bold text-capitalize ${errors.applicableAccreditationBody ? "" : "error-hidden"}`}
                  >
                    {`${errors.applicableAccreditationBody?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-3">
                  <fieldset className="border-none p-0">
                    <legend className="block font-bold text-input-label p-0 mb-3">
                      {t("standard.isStandardJointlyDeveloped")}?
                    </legend>
                    <div className="flex flex-wrap gap-3">
                      <div className="flex align-items-center">
                        <RadioButton inputId="isStandardJointlyDeveloped1" name="isStandardJointlyDeveloped" value="Yes" onChange={(e: RadioButtonChangeEvent) => setisStandardJointlyDeveloped(e.value)} checked={isStandardJointlyDeveloped === 'Yes'} />
                        <label htmlFor="isStandardJointlyDeveloped1" className="ml-2">{t("standard.yes")}</label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton inputId="isStandardJointlyDeveloped2" name="isStandardJointlyDeveloped" value="No" onChange={(e: RadioButtonChangeEvent) => setisStandardJointlyDeveloped(e.value)} checked={isStandardJointlyDeveloped === 'No'} />
                        <label htmlFor="isStandardJointlyDeveloped2" className="ml-2">{t("standard.no")}</label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="withInternalCommittees"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("standard.withInternalCommittees")}
                  </label>
                  <Controller
                    name="withInternalCommittees"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="withInternalCommittees"
                        {...field}
                        placeholder={t("standard.withInternalCommittees")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="withExternalCommittees"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("standard.withExternalCommittees")}
                  </label>
                  <Controller
                    name="withExternalCommittees"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="withExternalCommittees"
                        {...field}
                        placeholder={t("standard.withExternalCommittees")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>


        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("standard.responsibleCommittees")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="board"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("standard.board")}
                  </label>
                  <Controller
                    name="board"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="board"
                        {...field}
                        placeholder={t("standard.board")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
                <div className="flex col-12 py-0">
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("standard.addBoard")}
                    </span>
                  </Button>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="standardCommittee"
                    className={`block font-bold text-capitalize text-input-label"}`}
                  >
                    {t("standard.standardCommittee")}
                  </label>
                  <Controller
                    name="standardCommittee"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        inputId="standardCommittee"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("standard.selectAnOption")}
                        className={`w-full`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="flex col-12 py-0">
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("standard.addStandard")}
                    </span>
                  </Button>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="subTier"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("standard.subTier")}
                  </label>
                  <Controller
                    name="subTier"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="subTier"
                        {...field}
                        placeholder={t("standard.subTier")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
                <div className="flex col-12 py-0">
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("standard.addSubTier")}
                    </span>
                  </Button>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="primaryResponsibleCommittee"
                    className={`block font-bold text-capitalize text-input-label"}`}
                  >
                    {t("standard.primaryResponsibleCommittee")}
                  </label>
                  <Controller
                    name="primaryResponsibleCommittee"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        inputId="primaryResponsibleCommittee"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("standard.selectAnOption")}
                        className={`w-full`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default OtherConfiguration;

import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { MenuItem } from "primereact/menuitem";
import {
  fetchData,
  putData,
  postData,
} from "../../../services/Ballot/apiservice";
import { fetchData as getData } from "../../../services/apiService";
import Table from "../../common/Table";
import FeatherIcon from "../../common/FeatherIconComponent";
import { useLanguageContext } from "../../LanguageContext";
import {
  BallotType,
  BallotLevel,
  AccreditationBody,
  RecordType,
  RecordSubType,
  BallotSubType,
  initialState,
  BallotConfigurationResponse,
} from "../ballotConfiguration/BallotConfigurationInterface";
import { Toast } from "primereact/toast";

const BallotConfigurationTab: React.FC = () => {
  const [t, i18n] = useTranslation("configuration");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const [ballotConfigurationData, setBallotConfigurationData] = useState<any>({
    enableVotingPostBallotClosed: false,
    enableVotingBeforeBallotClosed: Boolean,
    enableVotingAfterBallotEndDate: Boolean,
    accreditationBodyId: "",
    ballotLevelId: "",
    ballotTypeId: "",
    ballotSubTypeId: "",
    recordTypeId: "",
    recordSubTypeId: "",
    votingFirstRemindersInDays: Number,
    votingSecondRemindersInDays: Number,
    votingThirdRemindersInDays: Number,
    allowUsersToAddRemoveRecords: Boolean,
    allowUsersToAddRemoveCommittees: Boolean,
    durationToAllowWithdrawDisapproval: 0,
    isDirty: Boolean,
    isNew: true,
  });
  const [errors, setErrors] = useState<any>({});
  const toast = useRef<any>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [value, setValue] = useState<string>("");

  const [isBallotConfVisible, setIsBallotConfVisible] = useState(false);
  const handleBallotConfHideShow = () => {
    setIsBallotConfVisible(!isBallotConfVisible);
    fetchDropdownOptions();
  };
  const [ballotLevels, setBallotLevels] = useState<BallotLevel[]>([]);
  const [ballotTypes, setBallotTypes] = useState<BallotType[]>([]);
  const [recordTypes, setRecordTypes] = useState<RecordType[]>([]);
  const [recordSubTypes, setRecordSubTypes] = useState<RecordSubType[]>([]);
  const [ballotSubTypes, setBallotSubTypes] = useState<BallotSubType[]>([]);
  const [accreditationBody, setAccreditationBody] = useState<
    AccreditationBody[]
  >([]);
  const [isWeightedBallot, setIsWeightedBallot] = useState<boolean>(false);
  const [isRecirculation, setIsRecirculation] = useState<boolean>(false);
  const [ballotConfigurationListing, setBallotConfigurationListing] =
    useState<any>([]);
  const [editConfigurationId, setEditConfigurationId] = useState<string>("");
  useEffect(() => {
    fetchDataballotConfiguration();
    fetchDropdownOptions();
  }, []);

  const fetchDropdownOptions = async () => {
    try {
      const [
        accreditationBody,
        ballotType,
        ballotSubType,
        ballotLevel,
        recordType,
        recordSubType,
      ] = await Promise.all([
        getData("AccreditationBody/GetAll"),
        fetchData("BallotType/GetAll"),
        fetchData("BallotSubType/GetAll"),
        fetchData("BallotLevel/GetAll"),
        getData("RecordType/GetAll"),
        fetchData("RecordSubType/GetAll"),
      ]);
      setAccreditationBody(
        accreditationBody.Collection.map((item: AccreditationBody) => ({
          Id: item.Id,
          Name: item.Name,
        }))
      );
      setBallotTypes(
        ballotType.Collection.map((item: BallotType) => ({
          Id: item.Id,
          Name: item.Name,
        }))
      );
      setBallotSubTypes(
        ballotSubType.Collection.map((item: BallotSubType) => ({
          Id: item.Id,
          Name: item.Name,
        }))
      );
      setBallotLevels(
        ballotLevel.Collection.map((item: BallotLevel) => ({
          Id: item.Id,
          Name: item.Name,
        }))
      );
      setRecordTypes(
        recordType.Collection.map((item: RecordType) => ({
          Id: item.Id,
          Name: item.TypeName,
        }))
      );
      setRecordSubTypes(
        recordSubType.Collection.map((item: RecordSubType) => ({
          Id: item.Id,
          Name: item.Type,
        }))
      );
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const formatBallotConfigurationData = (collection: any[]) => {
    return collection.map((item: any) => ({
      ballotLevel: item.BallotLevel.Name,
      ballotType: item.BallotType.Name,
      ballotSubType: item.BallotSubType.Name,
      recordType: item.RecordType.TypeName,
      recordSubType: item.RecordSubType.Type,
      editVoteAfterClosed: item.EnableVotingPostBallotClosed,
      editVoteBeforeClosed: item.EnableVotingBeforeBallotClosed,
      firstNotification: item.VotingFirstRemindersInDays,
      secondNotification: item.VotingSecondRemindersInDays,
      thirdNotification: item.VotingThirdRemindersInDays,
      Id: item.Id,
      action: "Edit",
      sessionKey: "formattedTableData",
    }));
  };

  const actionItems: MenuItem[] = [
    {
      label: t("configuration.viewEdit"),
      command: async () => {
        const formattedTableData = sessionStorage.getItem("formattedTableData");
        if (formattedTableData) {
          setIsBallotConfVisible(!isBallotConfVisible);
          setEditConfigurationId(formattedTableData);
          setIsEditMode(true);
        } else {
          // Set edit mode to false if ID is not present
          setIsEditMode(false);
        }
      },
    },
  ];

  const fetchConfigurationBYID = async (ConfigurationId: string) => {
    try {
      const response = await fetchData(
        `BallotConfiguration/${ConfigurationId}`
      );
      return response;
    } catch (error) {
      console.error("Error fetching configuration by ID:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchConfiguration = async () => {
      if (isEditMode && editConfigurationId) {
        const details = await fetchConfigurationBYID(editConfigurationId);
        setBallotConfigurationData({
          ...ballotConfigurationData,
          accreditationBodyId: details.AccreditationBody.Id,
          ballotLevelId: details.BallotLevel.Id,
          ballotTypeId: details.BallotType.Id,
          ballotSubTypeId: details.BallotSubType.Id,
          recordTypeId: details.RecordType.Id,
          recordSubTypeId: details.RecordSubType.Id,
          enableVotingPostBallotClosed: details.EnableVotingPostBallotClosed,
          enableVotingBeforeBallotClosed:
            details.EnableVotingBeforeBallotClosed,
          enableVotingAfterBallotEndDate:
            details.EnableVotingAfterBallotEndDate,
          votingFirstRemindersInDays: details.VotingFirstRemindersInDays,
          votingSecondRemindersInDays: details.VotingSecondRemindersInDays,
          votingThirdRemindersInDays: details.VotingThirdRemindersInDays,
          allowUsersToAddRemoveRecords: details.AllowUsersToAddRemoveRecords,
          allowUsersToAddRemoveCommittees:
            details.AllowUsersToAddRemoveCommittees,
          durationToAllowWithdrawDisapproval: 0,
          isDirty: true,
          isNew: false,
        });
      }
    };
    fetchConfiguration();
  }, [isEditMode, editConfigurationId]);

  const fetchDataballotConfiguration = async () => {
    try {
      const responseData = await fetchData("BallotConfiguration/GetAll");
      const configationList = formatBallotConfigurationData(
        responseData.Collection
      );
      setBallotConfigurationListing(configationList);
    } catch (error) {
      console.error("Error fetching Ballot Configuration:", error, 404);
    }
  };

  const handleDropdownField = (
    event: DropdownChangeEvent,
    field: keyof typeof ballotConfigurationData
  ) => {
    setBallotConfigurationData((prevState: any) => ({
      ...prevState,
      [field]: event.value,
    }));
    const selectedBallot = ballotTypes.find((bt) => bt.Id === event.value);
    setIsWeightedBallot(selectedBallot?.Name === "Weighted Ballot");
    const isRecirculation =
      ballotSubTypes.find((subType) => subType.Id === event.value)?.Name ===
      "Recirculation";
    setIsRecirculation(isRecirculation);
  };

  const handleRadioButtonChange = (
    e: RadioButtonChangeEvent,
    field: keyof typeof ballotConfigurationData
  ) => {
    setBallotConfigurationData((prevState: any) => ({
      ...prevState,
      [field]: e.value === "Yes",
    }));
  };

  const handleRecordsRecirculatingBallots = (e: RadioButtonChangeEvent) => {
    setBallotConfigurationData((prevConfig: any) => ({
      ...prevConfig,
      allowUsersToAddRemoveRecords: e.value === "Yes",
    }));
  };

  const handleCommitteeRecirculatingBallots = (e: RadioButtonChangeEvent) => {
    setBallotConfigurationData((prevConfig: any) => ({
      ...prevConfig,
      allowUsersToAddRemoveCommittees: e.value === "Yes",
    }));
  };

  const validateBallotConfiguration = () => {
    let isValid = true;
    let validationErrors: any = {};
    if (ballotConfigurationData.accreditationBodyId === "") {
      validationErrors.accreditationBodyId = "Accreditation Body is required.";
      isValid = false;
    }

    if (ballotConfigurationData.ballotLevelId === "") {
      validationErrors.ballotLevelId = "Ballot Level is required.";
      isValid = false;
    }

    if (ballotConfigurationData.ballotTypeId === "") {
      validationErrors.ballotTypeId = "Ballot Type is required.";
    }

    if (ballotConfigurationData.ballotSubTypeId === "") {
      validationErrors.ballotSubTypeId = "Ballot Sub Type is required.";
    }

    if (ballotConfigurationData.recordTypeId === "") {
      validationErrors.recordTypeId = "Record Type is required.";
    }

    if (ballotConfigurationData.recordSubTypeId === "") {
      validationErrors.recordSubTypeId = "Record Sub Type is required.";
    }

    setErrors(validationErrors);
    // Return true if no errors
    return isValid;
  };

  const showToast = (message: string, type: string) => {
    toast.current.show({
      severity: type,
      summary: "",
      detail: message,
      life: 2000,
    });
  };

  const resetForm = () => {
    const initialBallotConfigurationData = {
      enableVotingPostBallotClosed: false,
      enableVotingBeforeBallotClosed: false,
      enableVotingAfterBallotEndDate: false,
      accreditationBodyId: "",
      ballotLevelId: "",
      ballotTypeId: "",
      ballotSubTypeId: "",
      recordTypeId: "",
      recordSubTypeId: "",
      votingFirstRemindersInDays: 0,
      votingSecondRemindersInDays: 0,
      votingThirdRemindersInDays: 0,
      allowUsersToAddRemoveRecords: false,
      allowUsersToAddRemoveCommittees: false,
      durationToAllowWithdrawDisapproval: 0,
      isDirty: false,
      isNew: true,
    };
    setBallotConfigurationData(initialBallotConfigurationData);
  };

  const createConfiguration = async () => {
    try {
      const payload = createPayload();
      await postData("BallotConfiguration", payload);
      handleBallotConfHideShow();
      fetchDataballotConfiguration();
    } catch (error) {
      console.error("Error creating configuration:", error);
    }
  };
  const createPayload = (isNew: boolean = false) => {
    return {
      isDirty: !isNew,
      isNew: isNew,
      enableVotingPostBallotClosed:
        ballotConfigurationData.enableVotingPostBallotClosed ?? isNew,
      enableVotingBeforeBallotClosed:
        ballotConfigurationData.enableVotingBeforeBallotClosed ?? isNew,
      enableVotingAfterBallotEndDate:
        ballotConfigurationData.enableVotingAfterBallotEndDate ?? isNew,
      votingFirstRemindersInDays:
        parseInt(ballotConfigurationData.votingFirstRemindersInDays, 10) ?? 0,
      votingSecondRemindersInDays:
        parseInt(ballotConfigurationData.votingSecondRemindersInDays, 10) ?? 0,
      votingThirdRemindersInDays:
        parseInt(ballotConfigurationData.votingThirdRemindersInDays, 10) ?? 0,
      ballotLevelId: ballotConfigurationData.ballotLevelId ?? "",
      ballotTypeId: ballotConfigurationData.ballotTypeId ?? "",
      ballotSubTypeId: ballotConfigurationData.ballotSubTypeId ?? "",
      recordTypeId: ballotConfigurationData.recordTypeId ?? "",
      recordSubTypeId: ballotConfigurationData.recordSubTypeId ?? "",
      accreditationBodyId: ballotConfigurationData.accreditationBodyId ?? "",
      allowUsersToAddRemoveRecords:
        ballotConfigurationData.allowUsersToAddRemoveRecords ?? isNew,
      allowUsersToAddRemoveCommittees:
        ballotConfigurationData.allowUsersToAddRemoveCommittees ?? isNew,
      durationToAllowWithdrawDisapproval:
        ballotConfigurationData.durationToAllowWithdrawDisapproval,
    };
  };
  const editConfiguration = async (ConfigurationId: string) => {
    try {
      const payload = createPayload(true);
      await putData("BallotConfiguration", ConfigurationId, payload);
      resetForm();
      showToast("Updated Successfully", "success");
      handleBallotConfHideShow();
      fetchDataballotConfiguration();
    } catch (error) {
      console.error("Error updating configuration:", error);
    }
  };
  const saveConfiguration = async () => {
    const isValid = validateBallotConfiguration();
    if (!isValid) {
      // If validation fails, do not proceed
      console.error("Validation failed. Please correct the errors.");
      return;
    }
    if (isEditMode) {
      await editConfiguration(editConfigurationId);
    } else {
      await createConfiguration();
      showToast("Created Successfully", "success");
      resetForm();
    }

    handleBallotConfHideShow();
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="">
        {!isBallotConfVisible && (
          <div>
            <div className="flex justify-content-end py-3 gap-3 w-full">
              <Button
                className="button-md gap-1 justify-content-center w-full md:w-auto"
                onClick={handleBallotConfHideShow}
              >
                <FeatherIcon name="plus" size={20} color="inherit" />
                <span className="font-bold text-capitalize">
                  {t("configuration.addNewConfiguration")}
                </span>
              </Button>
            </div>
            <Table
              loading={false}
              products={ballotConfigurationListing}
              columns={[
                {
                  field: "ballotLevel",
                  header: t("configuration.ballotLevel"),
                },
                {
                  field: "ballotType",
                  header: t("configuration.ballotType"),
                },
                {
                  field: "ballotSubType",
                  header: t("configuration.ballotSubType"),
                },
                {
                  field: "recordType",
                  header: t("configuration.recordType"),
                },
                {
                  field: "recordSubType",
                  header: t("configuration.recordSubType"),
                },

                {
                  field: "editVoteAfterClosed",
                  header: t("configuration.editVoteAfterClosed"),
                },
                {
                  field: "editVoteBeforeClosed",
                  header: t("configuration.editVoteBeforeClosed"),
                },
                {
                  field: "firstNotification",
                  header: t("configuration.firstNotification"),
                },
                {
                  field: "secondNotification",
                  header: t("configuration.secondNotification"),
                },
                {
                  field: "thirdNotification",
                  header: t("configuration.thirdNotification"),
                },
                { field: "action", header: t("configuration.action") },
              ]}
              showCheckbox={false}
              actionItems={actionItems}
            />
          </div>
        )}

        {isBallotConfVisible && (
          <>
            <div className="card bg-white w-full mb-5 shadow-md">
              <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
                <Button
                  text
                  className="p-button-plain p-0"
                  onClick={handleBallotConfHideShow}
                  aria-label={t("configuration.back")}
                >
                  <FeatherIcon name="arrow-left" size={32} />
                </Button>
                <h2 className="text-title text-lg font-bold m-0">
                  {t("configuration.ballotConfiguration")}
                </h2>
              </div>

              <div className="p-5 cardBody">
                <div className="flex flex-column gap-3">
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="applicableAccreditationBody"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.applicableAccreditationBody")}
                      </label>

                      <Dropdown
                        inputId="applicableAccreditationBody"
                        name="applicableAccreditationBody"
                        value={ballotConfigurationData.accreditationBodyId}
                        options={accreditationBody}
                        onChange={(e) =>
                          handleDropdownField(e, "accreditationBodyId")
                        }
                        optionLabel="Name"
                        optionValue="Id"
                        placeholder={t("configuration.selectAnOption")}
                      />
                      {errors?.accreditationBodyId && (
                        <small className="p-error">
                          {errors?.accreditationBodyId}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="ballotLevel"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.ballotLevel")}
                      </label>
                      <Dropdown
                        inputId="ballotLevel"
                        value={ballotConfigurationData.ballotLevelId}
                        options={ballotLevels}
                        onChange={(e) =>
                          handleDropdownField(e, "ballotLevelId")
                        }
                        optionLabel="Name"
                        optionValue="Id"
                        defaultValue={""}
                        placeholder={t("configuration.selectAnOption")}
                      />
                      {errors?.ballotLevelId && (
                        <small className="p-error">
                          {errors?.ballotLevelId}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="ballotType"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.ballotType")}
                      </label>
                      <Dropdown
                        inputId="ballotType"
                        value={ballotConfigurationData.ballotTypeId}
                        options={ballotTypes}
                        onChange={(e) => handleDropdownField(e, "ballotTypeId")}
                        optionLabel="Name"
                        optionValue="Id"
                        placeholder={t("configuration.selectAnOption")}
                      />
                      {errors?.ballotTypeId && (
                        <small className="p-error">
                          {errors?.ballotTypeId}
                        </small>
                      )}
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="ballotSubType"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.ballotSubType")}
                      </label>
                      <Dropdown
                        inputId="ballotSubType"
                        name="ballotSubType"
                        value={ballotConfigurationData.ballotSubTypeId}
                        options={ballotSubTypes}
                        onChange={(e) =>
                          handleDropdownField(e, "ballotSubTypeId")
                        }
                        optionLabel="Name"
                        optionValue="Id"
                        placeholder={t("configuration.selectAnOption")}
                      />
                      {errors?.ballotSubTypeId && (
                        <small className="p-error">
                          {errors?.ballotSubTypeId}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="recordType"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.recordType")}
                      </label>
                      <Dropdown
                        inputId="recordType"
                        value={ballotConfigurationData.recordTypeId}
                        options={recordTypes}
                        onChange={(e) => handleDropdownField(e, "recordTypeId")}
                        optionLabel="Name"
                        optionValue="Id"
                        placeholder={t("configuration.selectAnOption")}
                      />
                      {errors?.recordTypeId && (
                        <small className="p-error">
                          {errors?.recordTypeId}
                        </small>
                      )}
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="recordSubType"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.recordSubType")}
                      </label>
                      <Dropdown
                        inputId="recordSubType"
                        value={ballotConfigurationData.recordSubTypeId}
                        options={recordSubTypes}
                        onChange={(e) =>
                          handleDropdownField(e, "recordSubTypeId")
                        }
                        optionLabel="Name"
                        optionValue="Id"
                        placeholder={t("configuration.selectAnOption")}
                      />
                      {errors?.recordSubTypeId && (
                        <small className="p-error">
                          {errors?.recordSubTypeId}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-3">
                      <fieldset className="border-none p-0">
                        <legend className="block font-bold text-input-label p-0 mb-3">
                          {t("configuration.allowEditVoteAfterClosed")}
                        </legend>
                        <div className="flex flex-wrap gap-3">
                          <div
                            className="flex align-items-center"
                            style={{ cursor: "not-allowed" }}
                          >
                            <RadioButton
                              inputId="allowEditVoteAfterClosed1"
                              name="allowEditVoteAfterClosed"
                              value="Yes"
                              onChange={(e) =>
                                handleRadioButtonChange(
                                  e,
                                  "enableVotingPostBallotClosed"
                                )
                              }
                              checked={
                                ballotConfigurationData.enableVotingPostBallotClosed ===
                                true
                              }
                              disabled={true}
                            />
                            <label
                              htmlFor="allowEditVoteAfterClosed1"
                              className="ml-2"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="flex align-items-center">
                            <RadioButton
                              inputId="allowEditVoteAfterClosed2"
                              name="allowEditVoteAfterClosed"
                              value="No"
                              onChange={(e) =>
                                handleRadioButtonChange(
                                  e,
                                  "enableVotingPostBallotClosed"
                                )
                              }
                              checked={
                                ballotConfigurationData.enableVotingPostBallotClosed ===
                                false
                              }
                              disabled={true}
                            />
                            <label
                              htmlFor="allowEditVoteAfterClosed2"
                              className="ml-2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-3">
                      <fieldset className="border-none p-0">
                        <legend className="block font-bold text-input-label p-0 mb-3">
                          {t("configuration.allowEditVoteBeforeClosed")}
                        </legend>
                        <div className="flex flex-wrap gap-3">
                          <div className="flex align-items-center">
                            <RadioButton
                              inputId="allowEditVoteBeforeClosed1"
                              name="allowEditVoteBeforeClosed"
                              value="Yes"
                              onChange={(e) =>
                                handleRadioButtonChange(
                                  e,
                                  "enableVotingBeforeBallotClosed"
                                )
                              }
                              checked={
                                ballotConfigurationData.enableVotingBeforeBallotClosed ===
                                true
                              }
                            />
                            <label
                              htmlFor="allowEditVoteBeforeClosed1"
                              className="ml-2"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="flex align-items-center">
                            <RadioButton
                              inputId="allowEditVoteBeforeClosed2"
                              name="allowEditVoteBeforeClosed"
                              value="No"
                              onChange={(e) =>
                                handleRadioButtonChange(
                                  e,
                                  "enableVotingBeforeBallotClosed"
                                )
                              }
                              checked={
                                ballotConfigurationData.enableVotingBeforeBallotClosed ===
                                false
                              }
                            />
                            <label
                              htmlFor="allowEditVoteBeforeClosed2"
                              className="ml-2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-3">
                      <fieldset className="border-none p-0">
                        <legend className="block font-bold text-input-label p-0 mb-3">
                          {t("configuration.allowVotingPostBallotEndDate")}
                        </legend>
                        <div className="flex flex-wrap gap-3">
                          <div className="flex align-items-center">
                            <RadioButton
                              inputId="allowVotingPostBallotEndDate1"
                              name="allowVotingPostBallotEndDate"
                              value="Yes"
                              onChange={(e) =>
                                handleRadioButtonChange(
                                  e,
                                  "enableVotingAfterBallotEndDate"
                                )
                              }
                              checked={
                                ballotConfigurationData.enableVotingAfterBallotEndDate ===
                                true
                              }
                            />
                            <label
                              htmlFor="allowVotingPostBallotEndDate1"
                              className="ml-2"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="flex align-items-center">
                            <RadioButton
                              inputId="allowVotingPostBallotEndDate2"
                              name="allowVotingPostBallotEndDate"
                              value="No"
                              onChange={(e) =>
                                handleRadioButtonChange(
                                  e,
                                  "enableVotingAfterBallotEndDate"
                                )
                              }
                              checked={
                                ballotConfigurationData.enableVotingAfterBallotEndDate ===
                                false
                              }
                            />
                            <label
                              htmlFor="allowVotingPostBallotEndDate2"
                              className="ml-2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div className="grid grid-xl align-items-end">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="firstVotingNotificationDays"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.firstVotingNotificationDays")}
                      </label>
                      <InputText
                        id="firstVotingNotificationDays"
                        name="firstVotingNotificationDays"
                        value={
                          ballotConfigurationData.votingFirstRemindersInDays ||
                          ""
                        }
                        onChange={(e) =>
                          setBallotConfigurationData((prevConfig: any) => ({
                            ...prevConfig,
                            votingFirstRemindersInDays: e.target.value,
                          }))
                        }
                        type="number"
                        placeholder={t(
                          "configuration.firstVotingNotificationDays"
                        )}
                        className="w-full"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="secondVotingNotificationDays"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.secondVotingNotificationDays")}
                      </label>
                      <InputText
                        id="secondVotingNotificationDays"
                        name="secondVotingNotificationDays"
                        value={
                          ballotConfigurationData.votingSecondRemindersInDays ||
                          ""
                        }
                        onChange={(e) =>
                          setBallotConfigurationData((prevConfig: any) => ({
                            ...prevConfig,
                            votingSecondRemindersInDays: e.target.value,
                          }))
                        }
                        type="number"
                        placeholder={t(
                          "configuration.secondVotingNotificationDays"
                        )}
                        className="w-full"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="thirdVotingNotificationDays"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.thirdVotingNotificationDays")}
                      </label>
                      <InputText
                        id="thirdVotingNotificationDays"
                        name="thirdVotingNotificationDays"
                        value={
                          ballotConfigurationData.votingThirdRemindersInDays ||
                          ""
                        }
                        onChange={(e) =>
                          setBallotConfigurationData((prevConfig: any) => ({
                            ...prevConfig,
                            votingThirdRemindersInDays: e.target.value,
                          }))
                        }
                        type="number"
                        placeholder={t(
                          "configuration.thirdVotingNotificationDays"
                        )}
                        className="w-full"
                      />
                    </div>
                  </div>

                  <div>
                    {isWeightedBallot && (
                      <div>
                        <h3 className="font-bold text-lg m-0">
                          {t("configuration.rankingPoints")}
                        </h3>
                        <div className="flex flex-column gap-2 w-full">
                          <div className="flex flex-column gap-2">
                            <div className="grid grid-xl mt-0">
                              <span
                                className="flex xl:col-3 lg:col-4 md:col-5 col-5 py-1 font-bold text-capitalize"
                                id="rank"
                              >
                                {t("configuration.rank")}
                              </span>
                              <span
                                className="flex xl:col-3 lg:col-3 md:col-5 col-5 py-1 font-bold text-capitalize"
                                id="points"
                              >
                                {t("configuration.points")}
                              </span>
                            </div>
                            <div className="flex flex-column w-full">
                              <div className="grid grid-xl align-items-center idx">
                                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                                  <Dropdown
                                    inputId="rank-0"
                                    name="rank-0"
                                    optionLabel="name"
                                    placeholder={t(
                                      "configuration.selectAnOption"
                                    )}
                                    aria-labelledby="rank"
                                  />
                                </div>
                                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                                  <InputText
                                    id="points-0"
                                    name="points-0"
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    type="number"
                                    placeholder=""
                                    className="w-full"
                                  />
                                </div>
                                <div className="w-auto p-0">
                                  <Button
                                    text
                                    className="p-button-plain p-2 text-delete"
                                    aria-label={t("configuration.delete")}
                                  >
                                    <FeatherIcon name="trash-2" size={20} />
                                  </Button>
                                </div>
                              </div>
                              <div className="grid grid-xl align-items-center idx">
                                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                                  <Dropdown
                                    inputId="rank-1"
                                    name="rank-1"
                                    optionLabel="name"
                                    placeholder={t(
                                      "configuration.selectAnOption"
                                    )}
                                    aria-labelledby="rank"
                                  />
                                </div>
                                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                                  <InputText
                                    id="points-1"
                                    name="points-1"
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    type="number"
                                    placeholder=""
                                    className="w-full"
                                  />
                                </div>
                                <div className="w-auto p-0">
                                  <Button
                                    text
                                    className="p-button-plain p-2 text-delete"
                                    aria-label={t("configuration.delete")}
                                  >
                                    <FeatherIcon name="trash-2" size={20} />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Button
                              text
                              className="p-button-plain gap-1 pl-0 underline"
                            >
                              <FeatherIcon name="plus" size={20} />
                              <span className="font-bold text-capitalize">
                                {t("configuration.addRank")}
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="grid grid-xl">
                    {isRecirculation && (
                      <div className="flex flex-column gap-3 xl:col-3 lg:col-4 md:col-6 col-12">
                        <fieldset className="border-none p-0">
                          <legend className="block font-bold text-input-label p-0 mb-3">
                            {t(
                              "configuration.newRemoveExistingRecordsRecirculatingBallots"
                            )}
                          </legend>
                          <div className="flex flex-wrap gap-3">
                            <div className="flex align-items-center">
                              <RadioButton
                                inputId="newRemoveExistingRecordsRecirculatingBallots1"
                                name="newRemoveExistingRecordsRecirculatingBallots"
                                value="Yes"
                                onChange={handleRecordsRecirculatingBallots}
                                checked={
                                  ballotConfigurationData.allowUsersToAddRemoveRecords ===
                                  true
                                }
                              />
                              <label
                                htmlFor="newRemoveExistingRecordsRecirculatingBallots2"
                                className="ml-2"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="flex align-items-center">
                              <RadioButton
                                inputId="newRemoveExistingRecordsRecirculatingBallots2"
                                name="newRemoveExistingRecordsRecirculatingBallots"
                                value="No"
                                onChange={handleRecordsRecirculatingBallots}
                                checked={
                                  ballotConfigurationData.allowUsersToAddRemoveRecords ===
                                  false
                                }
                              />
                              <label
                                htmlFor="newRemoveExistingRecordsRecirculatingBallots2"
                                className="ml-2"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    )}
                  </div>
                  {isRecirculation && (
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-3 xl:col-3 lg:col-4 md:col-6 col-12">
                        <fieldset className="border-none p-0">
                          <legend className="block font-bold text-input-label p-0 mb-3">
                            {t(
                              "configuration.newRemoveExistingCommitteeRecirculatingBallots"
                            )}
                          </legend>
                          <div className="flex flex-wrap gap-3">
                            <div className="flex align-items-center">
                              <RadioButton
                                inputId="newRemoveExistingCommitteeRecirculatingBallots1"
                                name="newRemoveExistingCommitteeRecirculatingBallots"
                                value="Yes"
                                onChange={handleCommitteeRecirculatingBallots}
                                checked={
                                  ballotConfigurationData.allowUsersToAddRemoveCommittees ===
                                  true
                                }
                              />
                              <label
                                htmlFor="newRemoveExistingCommitteeRecirculatingBallots1"
                                className="ml-2"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="flex align-items-center">
                              <RadioButton
                                inputId="newRemoveExistingCommitteeRecirculatingBallots2"
                                name="newRemoveExistingCommitteeRecirculatingBallots"
                                value="No"
                                onChange={handleCommitteeRecirculatingBallots}
                                checked={
                                  ballotConfigurationData.allowUsersToAddRemoveCommittees ===
                                  false
                                }
                              />
                              <label
                                htmlFor="newRemoveExistingCommitteeRecirculatingBallots2"
                                className="ml-2"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
              <Button
                label={t("configuration.cancel")}
                className="button-md"
                severity="secondary"
                type="button"
                onClick={handleBallotConfHideShow}
              />

              <Button
                label={t("configuration.save")}
                className="button-md"
                type="button"
                onClick={() => {
                  saveConfiguration();
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default BallotConfigurationTab;

import React, { useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { InputComponent } from "../common/inputComponent";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
const EmptyState = () => {
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="p-5 cardBody">
            <div className="text-center">
              <p>{t("profile.emptyStateExperience")}</p>
              <p>{t("profile.emptyStateExperienceSub")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmptyState;

import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import PreviewItem from "../../common/PreviewItem";
import Table from "../../common/Table";

const DetailTab: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.recordDetails")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("record.onBehalfOf")}
                value="Value"
              />
              <PreviewItem
                label={t("record.memberCommittee")}
                value="Value"
              />
              <PreviewItem
                label={t("record.requestedOn")}
                value="Value"
              />
              <PreviewItem
                label={t("record.recordType")}
                value="Value"
              />
              <PreviewItem
                label={t("record.recordSubtype")}
                value="Value"
              />
              <PreviewItem
                label={t("record.remark")}
                value="Value"
              />
              <PreviewItem
                label={t("record.readyForBalloting")}
                value="Value"
              />
              <PreviewItem
                label={t("record.productAbstract")}
                value="Value"
              />
              <PreviewItem
                label={t("record.productType")}
                value="Value"
              />
              <PreviewItem
                label={t("record.problemStatement")}
                value="Value"
              />
              <PreviewItem
                label={t("record.existingStandardCommittee")}
                value="Value"
              />
              <PreviewItem
                label={t("record.industryNeeds")}
                value="Value"
              />
              <PreviewItem
                label={t("record.titleOfProposedProduct")}
                value="Value"
              />
              <PreviewItem
                label={t("record.projectTechnicalManager")}
                value="Value"
              />
              <PreviewItem
                label={t("record.proposedBenefitsProduct")}
                value="Value"
              />
              <PreviewItem
                label={t("record.recordStatus")}
                value="Value"
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.relevance")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("record.standardAffected")}
                value="Value"
              />
              <PreviewItem
                label={t("record.standardForPatentedItem")}
                value="Value"
              />
              <PreviewItem
                label={t("record.similarProductDevelopedByOtherOrg")}
                value="Value"
              />
              <PreviewItem
                label={t("record.specifyPotentiallyConflictingStandard")}
                value="Value"
              />
              <PreviewItem
                label={t("record.publishingCycle")}
                value="Value"
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.stakeholders")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("record.affectedStakeholder")}
                value="Value"
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.responsibleCommittee")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("record.workingCommittee")}
                value="Value"
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.staffNotes")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="plus" size={20} />
              <span>{t("record.addNew")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "description", header: t("record.description") },
                { field: "fileType", header: t("record.fileType") },
                { field: "attachments", header: t("record.attachments") },
              ]}
            />
          </div>
        </div>

      </div>
    </>
  );
};

export default DetailTab;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface selectRecordsData {
    ballotTableData: Record<string, any>;
}
const initialState: selectRecordsData = {
    ballotTableData: [],
}
const ballotListings = createSlice({
    name: 'ballotTableData',
    initialState,
    reducers: {
        addAllBallotDetails: (state,
            action: PayloadAction<Record<string, any>>) => {
            state.ballotTableData = action.payload.value
        },
    }
});

export const allBallotListings = ballotListings.actions
export default ballotListings.reducer;
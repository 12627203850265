import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Table from "../common/Table";
import { fetchData } from "../../services/apiService";
import { Button } from "primereact/button";

interface Product {
  memberName: string;
  email: string;
  position: string;
  categoryOfInterest: string;
}

interface PositionList {
  Id: string;
  MemberName: string;
  Email: string;
  CategoryOfInterest: string;
  Position: string;
}

const Positioncount: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const [positionCountList, setPositionCountList] = useState<PositionList[]>(
    []
  );
  const navigate = useNavigate();
  const location = useLocation();
  const rowData = location.state?.rowData as any | undefined;

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  React.useEffect(() => {
    const Id = rowData?.OpenPositionId;
    fetchPositionFilled(Id);
  }, [rowData]);

  const fetchPositionFilled = async (Id: any) => {
    try {
      const response = await fetchData(
        `OpenPosition/GetFilledPositions?openPositionId=${Id}`
      );
      const positionFilledCount = response.Collection;
      setPositionCountList(positionFilledCount);
    } catch (error) {
      console.error("Error fetching open positions:", error);
    }
  };
  const products: Product[] = positionCountList.map((item: PositionList) => ({
    memberName: item.MemberName,
    email: item.Email,
    position: item.Position,
    categoryOfInterest: item.CategoryOfInterest,
  }));
  const handleCancel = () => {
    navigate(`/positions`);
  };

  return (
    <>
      <div className="">
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">
            {t("committee.memberDetails")}
          </h1>
          <p className="text-base font-normal m-0">
            {t("committee.positionText")}
          </p>
        </div>

        <Table
          products={products}
          columns={[
            {
              field: "memberName",
              header: t("committee.memberName"),
            },
            { field: "email", header: t("committee.email") },
            { field: "position", header: t("committee.position") },
            {
              field: "categoryOfInterest",
              header: t("committee.categoryOfInterest"),
            },
          ]}
          loading={false}
        />
        <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("committee.cancel")}
            className="button-md w-full sm:w-auto"
            severity="secondary"
            onClick={handleCancel}
          />
        </div>
      </div>
    </>
  );
};

export default Positioncount;

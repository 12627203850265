import React, { useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import Input from "../../utils/Input";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import {
  fetchData,
  getAllCountries,
  getListByParentId,
  postData,
  putData,
} from "../../services/apiService";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { focusOnErrorField } from "../../utils/focusError";
import { focusOnField } from "../../utils/focusField";
interface Experience {
  isNew: boolean;
  userProfileId: string;
  designation: string;
  department: string;
  companyName: string;
  startDate: any;
  endDate: any;
  websiteURL: any;
  isActive: true;
  isDeleted: false;
  UserProfileId?: string;
  TenantId?: string;
  IsDirty?: boolean;
  userExperienceId: string;
}

const AddExperience = () => {
  const { id } = useParams();
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const userProfileID = localStorage.getItem("userProfileID") || "";
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState<
    Array<Partial<Experience>>
  >([]);
  const [experiences, setExperiences] = useState<Experience[]>([
    {
      isNew: true,
      userProfileId: userProfileID,
      designation: "",
      department: "",
      companyName: "",
      startDate: "",
      endDate: "",
      websiteURL: "",
      isActive: true,
      isDeleted: false,
      IsDirty: true,
      //TenantId: TENANT_ID,
      userExperienceId: userProfileID,
    },
  ]);

  const [validationError, setValidationError] = useState<string | null>(null);

  const handleAddExperience = () => {
    const newExperience: Experience = {
      isNew: true,
      userProfileId: userProfileID,
      designation: "",
      department: "",
      companyName: "",
      startDate: "",
      endDate: "",
      websiteURL: "",
      isActive: true,
      isDeleted: false,
      IsDirty: true,
      userExperienceId: userProfileID,
    };
    setExperiences([...experiences, newExperience]);

    // To focus on newly added row first element "designation"
    setTimeout(() => {
      focusOnField(`designation-${experiences.length}`);
    }, 0); // Delay of 0 milliseconds
  };

  const createProfileLabelForValidation = [
    { name: "designation", label: t("profile.designation") },
    { name: "companyName", label: t("profile.companyName") },
    { name: "department", label: t("profile.department") },
    { name: "websiteURL", label: t("profile.companyWebsite") },
    { name: "startDate", label: t("profile.startDate") },
    { name: "endDate", label: t("profile.endDate") },
  ];

  const urlRegexPattern = /^www\.[a-zA-Z0-9\-&'’]+\.[a-zA-Z]{2,}(\/\S*)?$/;

  // Function to check if a URL is valid
  const isValidUrl = (url: string) => {
    return urlRegexPattern.test(url);
  };
  const handleInputChange = (
    index: number,
    key: keyof Experience,
    value: string
  ) => {
    if (key === "websiteURL") {
      // Check if the input value matches the URL regex pattern
      const isValid = isValidUrl(value);

      // Update validation errors based on URL validity
      const newValidationErrors = [...validationErrors];
      newValidationErrors[index] = {
        ...newValidationErrors[index],
        [key]: isValid ? undefined : t("profile.invalidUrlFormat"),
      };
      setValidationErrors(newValidationErrors);
    } else {
      const newValidationErrors = [...validationErrors];
      newValidationErrors[index] = {
        ...newValidationErrors[index],
        [key]: undefined,
      };
      setValidationErrors(newValidationErrors);
    }

    const newExperiences = [...experiences];
    newExperiences[index][key] = value as never;
    setExperiences(newExperiences);
  };

  const handleDateChange = (
    index: number,
    key: keyof Experience,
    value: Date
  ) => {
    const updatedExperiences = [...experiences];
    updatedExperiences[index][key] = value as never;

    const newValidationErrors = [...validationErrors];
    newValidationErrors[index] = {
      ...newValidationErrors[index],
      [key]: undefined,
    };
    setValidationErrors(newValidationErrors);

    setExperiences(updatedExperiences);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setTimeout(() => {
      focusOnErrorField(".p-invalid");
    }, 100);
    const errors: Array<Partial<Experience>> = [];

    const requiredFields = [
      "startDate",
      "endDate",
      "designation",
      "companyName",
      "department",
      "websiteURL",
    ];

    experiences.forEach((experience, index) => {
      const error: Partial<Experience> = {};

      requiredFields.forEach((field) => {
        const fieldValue = experience[field as keyof Experience];
        if (
          !fieldValue ||
          (typeof fieldValue === "string" && fieldValue.trim() === "")
        ) {
          const label =
            createProfileLabelForValidation.find((item) => item.name === field)
              ?.label || field;
          error[field as keyof Experience] =
            `${label} ${t("profile.isRequired")}`;
        }
      });

      errors[index] = error;
    });

    setValidationErrors(errors);

    if (errors.some((error) => Object.keys(error).length !== 0)) {
      return;
    }

    try {
      const postDataResponse = await postData(
        "UserExperience/BulkUpload",
        experiences
      );
      navigate("/profile/");
      return postDataResponse;
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  // Function to check if all required fields are filled for a given experience
  const isExperienceFilled = (experience: Experience): boolean => {
    return (
      experience.designation.trim() !== "" &&
      experience.companyName.trim() !== "" &&
      experience.department.trim() !== "" &&
      experience.websiteURL.trim() !== "" &&
      experience.startDate &&
      experience.endDate
    );
  };

  // Function to check if all experiences have required fields filled
  const areAllExperiencesFilled = (): boolean => {
    return experiences.every(isExperienceFilled);
  };

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              onClick={() => navigate("/profile/")}
              aria-label={t("profile.back")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("profile.addExperienceDetails")}
              </h1>
              <p className="text-base font-normal m-0 text-help">
                {t("profile.addExperienceDetails")}
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="p-5 cardBody">
              {experiences.map((experience, index) => (
                <div
                  className={`flex flex-column gap-3 mb-3 ${
                    index > 0 ? "border-top-1 border-gray-200 pt-5" : ""
                  }`}
                  key={index}
                >
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`designation-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.designation ? "p-error" : ""
                        }`}
                      >
                        {t("profile.jobTitle")}
                      </label>
                      <InputText
                        id={`designation-${index}`}
                        aria-describedby={`designationError-${index}`}
                        value={experience.designation}
                        name={`jobTitle_${index}`}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "designation",
                            e.target.value
                          )
                        }
                        placeholder={t("profile.jobTitle")}
                        className={
                          validationErrors[index]?.designation
                            ? "p-invalid"
                            : ""
                        }
                      />
                      <span
                        id={`designationError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.designation ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.designation}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`website-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.websiteURL ? "p-error" : ""
                        }`}
                      >
                        {t("profile.website")}
                      </label>
                      <InputText
                        id={`website-${index}`}
                        aria-describedby={`websiteError-${index}`}
                        value={experience.websiteURL}
                        name={`website_${index}`}
                        onChange={(e) =>
                          handleInputChange(index, "websiteURL", e.target.value)
                        }
                        placeholder={t("profile.website")}
                        className={
                          validationErrors[index]?.websiteURL ? "p-invalid" : ""
                        }
                      />
                      <span
                        id={`websiteError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.websiteURL ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.websiteURL}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`companyName-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.companyName ? "p-error" : ""
                        }`}
                      >
                        {t("profile.company")}
                      </label>
                      <InputText
                        id={`companyName-${index}`}
                        aria-describedby={`companyNameError-${index}`}
                        value={experience.companyName}
                        name={`jobTitle_${index}`}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "companyName",
                            e.target.value
                          )
                        }
                        placeholder={t("profile.company")}
                        className={
                          validationErrors[index]?.companyName
                            ? "p-invalid"
                            : ""
                        }
                      />
                      <span
                        id={`companyNameError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.companyName ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.companyName}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`department-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.companyName ? "p-error" : ""
                        }`}
                      >
                        {t("profile.department")}
                      </label>
                      <InputText
                        id={`department-${index}`}
                        aria-describedby={`departmentError-${index}`}
                        value={experience.department}
                        name={`department_${index}`}
                        onChange={(e) =>
                          handleInputChange(index, "department", e.target.value)
                        }
                        placeholder={t("profile.department")}
                        className={
                          validationErrors[index]?.department ? "p-invalid" : ""
                        }
                      />
                      <span
                        id={`departmentError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.department ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.department}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`yearfrom-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.startDate ? "p-error" : ""
                        }`}
                      >
                        {t("profile.from")}
                      </label>
                      <Calendar
                        appendTo="self"
                        maxDate={experience.endDate}
                        placeholder={t("profile.startDate")}
                        value={experience.startDate}
                        onChange={(e) =>
                          handleDateChange(index, "startDate", e.value as Date)
                        }
                        // onSelect={() => focusOnField(`yearfrom-${index}`)}
                        inputId={`yearfrom-${index}`}
                        aria-describedby={`yearfromError-${index}`}
                        className={`custom-datepicker ${
                          validationErrors[index]?.startDate ? "p-invalid" : ""
                        }`}
                      />
                      <span
                        id={`yearfromError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.startDate ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.startDate}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`yearTo-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.endDate ? "p-error" : ""
                        }`}
                      >
                        {t("profile.to")}
                      </label>
                      <Calendar
                        appendTo="self"
                        placeholder={t("profile.endDate")}
                        minDate={experience.startDate}
                        value={experience.endDate}
                        onChange={(e) =>
                          handleDateChange(index, "endDate", e.value as Date)
                        }
                        // onSelect={() => focusOnField(`yearTo-${index}`)}
                        inputId={`yearTo-${index}`}
                        aria-describedby={`yearToError-${index}`}
                        className={`custom-datepicker ${
                          validationErrors[index]?.endDate ? "p-invalid" : ""
                        }`}
                      />
                      <span
                        id={`yearToError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.endDate ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.endDate}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              <div
                className={`p-error font-bold text-capitalize ${validationError ? "" : "error-hidden"}`}
              >
                {validationError}
              </div>
              <div className="block w-full">
                <Button
                  text
                  className="p-button-plain gap-2 pl-0 underline"
                  onClick={handleAddExperience}
                  disabled={!areAllExperiencesFilled()}
                >
                  <FeatherIcon name="plus" size={20} />
                  <span>{t("profile.addAnotherExperience")}</span>
                </Button>
              </div>
            </div>

            <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-3 justify-content-end cardFooter">
              <div className="flex align-items-center gap-4">
                <Button
                  label={t("profile.cancel")}
                  className="button-md"
                  severity="secondary"
                  onClick={() => navigate("/profile/")}
                />
                <Button label={t("profile.save")} className="button-md" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddExperience;

import React, { useState, useRef } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OverlayPanel } from "primereact/overlaypanel"; // Import OverlayPanel
import Table from "../common/Table";
import { Calendar } from "primereact/calendar";
import { MenuItem } from "primereact/menuitem";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
interface Option {
  name: string;
  code: string;
}

const CommitteeMeetingTabActionItems: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: t("committee.dashboard"), url: "/dashboard" },
    { label: t("committee.committees") },
  ];

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const navigate = useNavigate();

  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  const [checked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };
  const [dateActivation, setDateActivation] = useState(null);
  const actionItems: MenuItem[] = [
    {
      label: "Opt 1",
      // command: () => {
      //   navigate("/member/request-view");
      // },
    },
    // {
    //   label: "Terminate member",
    // },
    // {
    //   label: "Reappoint member",
    // },
  ];
  return (
    <>
      <div className="">
        <div className="flex align-items-center py-3 gap-3 justify-content-end w-full">
          <Button
            className="button-md gap-1"
            onClick={() => navigate("/committee/add-action")}
          >
            <FeatherIcon name="plus" size={20} color="inherit" />
            <span className="font-bold text-capitalize">
              {t("committee.addAction")}
            </span>
          </Button>
        </div>

        <Table
          loading={false}
          products={[
            {
              task: "Task details will go here",
              assignTo: "John Doe",
              assignBy: "Ian vetrovik",
              completionDate: "04/02/22",
              status: "In-progress",
              action: t("committee.action"),
            },
          ]}
          columns={[
            { field: "task", header: t("committee.task") },
            { field: "assignTo", header: t("committee.assignTo") },
            { field: "assignBy", header: t("committee.assignBy") },
            { field: "completionDate", header: t("committee.completionDate") },
            { field: "status", header: t("committee.status") },
            { field: "action", header: t("committee.action"), sortable: false },
          ]}
          actionItems={actionItems}
        />
      </div>
    </>
  );
};

export default CommitteeMeetingTabActionItems;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";
import FeatherIcon from "../../common/FeatherIconComponent";
import { postData } from "../../../services/Ballot/apiservice";
import { RootState } from "../../../store/store";
import { useLanguageContext } from "../../LanguageContext";
import HierarchyTableData from "../HierarchyTableData";

const SelectCommittee: any = () => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isEdit } = useSelector((state: any) => state.ballotSelectRecords);
  const { ballotCommitteeData } = useSelector(
    (state: any) => state.ballotCommittee
  );
  const createCommitteeData = useSelector(
    (state: RootState) => state.createCommittee
  );
  const { updatedCommitteeData } = useSelector(
    (state: any) => state.ballotCommittee
  );
  const [payload, setPayload] = useState<any>(updatedCommitteeData);

  const onPayloadHandler = (formattedData: any) => {
    const isDuplicate = payload.some(
      (item: any) => item.committeeId === formattedData.committeeId
    );
    if (!isDuplicate) {
      setPayload((prevPayload: any) => [...prevPayload, formattedData]);
    }
  };

  const { ballotDetailsInfo } = useSelector(
    (state: any) => state.ballotDetails
  );
  let ballotId = ballotDetailsInfo.id;

  const createBallotCommitteeHandler = async (e: any) => {
    e.preventDefault();
    try {
      if (ballotId && isEdit) {
        dispatch(ballotSelectRecords.addStepCountHandler({ value: 3 }));
        const createBallotCommitte = await postData(
          `BallotCommittees/BulkUpload`,
          payload
        );
      } else {
        dispatch(ballotSelectRecords.addStepCountHandler({ value: 3 }));
        const createBallotCommitte = await postData(
          `BallotCommittees/BulkUpload`,
          payload
        );
      }
    } catch (err) {
      console.log(err, "errr");
    }
  };
  return (
    <>
      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="p-5 cardBody">
              <HierarchyTableData
                key={ballotCommitteeData}
                onUpdatePayload={onPayloadHandler}
              />
            </div>
          </div>
          <div className="bg-white hidden md:flex align-items-stretch px-5 py-3 gap-3 lg:gap-4 fixed-footer w-full left-0 shadow">
            <Button
              label={t("ballot.cancel")}
              className="button-md flex-shrink-0"
              severity="secondary"
              onClick={() => navigate(-1)}
            />
            <Button
              text
              className="p-button-plain underline"
              label={t("ballot.discardChanges")}
              onClick={() => navigate(-1)}
            ></Button>
            <Button
              text
              className="p-button-plain underline ml-auto"
              label={t("ballot.saveAsDraft")}
              onClick={(e) => {
                createBallotCommitteeHandler(e);
                navigate(-1);
              }}
            ></Button>
            <Button
              className="button-md gap-1 flex-shrink-0"
              severity="secondary"
              onClick={() =>
                dispatch(ballotSelectRecords.addStepCountHandler({ value: 1 }))
              }
            >
              <FeatherIcon name="chevron-left" size={20} color="inherit" />
              <span className="font-bold text-capitalize">
                {t("ballot.back")}
              </span>
            </Button>
            <Button
              className="button-md gap-1"
              onClick={(e) => createBallotCommitteeHandler(e)}
            >
              <span className="font-bold text-capitalize">
                {t("ballot.savePreview")}
              </span>
              <FeatherIcon name="chevron-right" size={20} color="inherit" />
            </Button>
          </div>

          {/* Mobile responsive buttions */}
          <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
            <Button
              className="button-md gap-1 w-full justify-content-center"
              onClick={(e) => createBallotCommitteeHandler(e)}
            >
              <span className="font-bold text-capitalize">
                {t("ballot.savePreview")}
              </span>
              <FeatherIcon name="chevron-right" size={20} color="inherit" />
            </Button>
            <div className="flex align-items-center gap-3 w-full">
              <Button
                className="button-md gap-1"
                severity="secondary"
                onClick={() =>
                  dispatch(
                    ballotSelectRecords.addStepCountHandler({ value: 1 })
                  )
                }
                aria-label={t("ballot.back")}
              >
                <FeatherIcon name="chevron-left" size={20} color="inherit" />
              </Button>
              <Button
                label={t("ballot.cancel")}
                className="button-md w-full"
                severity="secondary"
                onClick={() => navigate(-1)}
              />
            </div>
            <div className="flex flex-column sm:flex-row align-items-center gap-3 w-full">
              <Button
                text
                className="p-button-plain underline  w-full"
                label={t("ballot.saveAsDraft")}
                onClick={(e) => {
                  createBallotCommitteeHandler(e);
                  navigate(-1);
                }}
              ></Button>
              <Button
                text
                className="p-button-plain underline  w-full"
                label={t("ballot.discardChanges")}
                onClick={() => navigate(-1)}
              ></Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SelectCommittee;

import React from "react";

const SkipLink: React.FC = () => {
  const handleClick = () => {
    const mainContent = document.getElementById("main-content");
    if (mainContent) {
      mainContent.setAttribute("tabindex", "-1"); // Make main content focusable
      mainContent.focus(); // Set focus to main content
      mainContent.removeAttribute("tabindex"); // Remove tabindex to restore natural tab order
      mainContent.scrollIntoView({ behavior: "smooth" }); // Scroll to main content
    }
  };

  return (
    <button className="skip-link" onClick={handleClick}>
      Skip to main content (Press Enter)
    </button>
  );
};

export default SkipLink;

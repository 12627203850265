import { IDENTITY_URL } from "../config";

const BASE_URL = IDENTITY_URL;

interface LoginResponse {
  token: string;
}

async function fetchSignUpData<T>(formData?: any): Promise<T> {
  const url = `${BASE_URL}/User/AddUser`;
  try {
    let options: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };

    const response = await fetch(url, options);
    if (!response.ok) {
      const responseBody = await response.json();
      const errorMessage = responseBody.errors?.RequestModel?.[0];
      
      throw new Error(
        `Failed to fetch data. Response status: ${response.status}. Error message: ${errorMessage}`,
      );
    }
    return response.json() as Promise<T>;
  } catch (error) {
    console.error("Error fetching data:", error);

    throw error;
  }
}

export async function login(email: string, password: string): Promise<string> {
  const url = `${BASE_URL}/Account`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(responseBody.message || "Login failed");
    }

    const responseData: LoginResponse = await response.json();
    return responseData.token;
  } catch (error) {
    throw new Error("Login failed. Please try again later.");
  }
}

export default fetchSignUpData;

import React, { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { FilterMatchMode } from "primereact/api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import FeatherIcon from "../common/FeatherIconComponent";
import { fetchData } from "../../services/apiService";
import { useLanguageContext } from "../LanguageContext";
import { createMemberRequest } from "../../slices/createMemberRequestSlice";
import { Column } from "primereact/column";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import MemberListsSteps from "./MemberListsSteps";
import { staffConfigureProfile } from "../../slices/createProfileSlice";
import { createRecordRequest } from "../../slices/createRecordSlice";
import { clearAllSessionStorage } from "../../utils/storage";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
interface Option {
  name: string;
  code: string;
}

const Volunteers: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const menuRight = useRef<Menu>(null);
  const [createRecord, setCreateRecord] = useState<any>({
    userProfileId: "",
  });
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [selectedVolunteer, setSelectedVolunteer] = useState<any>(null);
  const [rowClick, setRowClick] = useState<any>(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [t, i18n] = useTranslation("member");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [committees, setCommitteess] = useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    clearAllSessionStorage();
    const fetchAllMembers = async () => {
      try {
        const responseData = await fetchData(
          "UserProfile/SearchVolenteerMembers"
        );
        const modifiedData = responseData.map((item: any) => ({
          ...item,
          fullName: item.Firstname + " " + item.Lastname,
        }));
        setCommitteess(modifiedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching members:", error);
        setLoading(false);
      }
    };
    fetchAllMembers();
  }, []);

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const navigate = useNavigate();
  const handleActionClick = (rowData: any, actionType: string) => {
    dispatch(staffConfigureProfile({ userProfileId: rowData.Id }));
    sessionStorage.setItem("staffUpdatingMember", rowData.Id);
    if (actionType === "Edit") {
      navigate("/profile");
    }
  };

  const toggleMenu = (event: any, rowData?: any) => {
    if (rowData.UserProfileId) {
      //localStorage.setItem('userProfileID',rowData.UserProfileId)
      // console.log(rowData)
    }
    if (rowData.Id) {
      dispatch(staffConfigureProfile({ userProfileId: rowData.Id }));
      sessionStorage.setItem("staffUpdatingMember", rowData.Id);
    }
    // sessionStorage.setItem('membershipRequestid', JSON.stringify({membershipRequestId: rowDataId}))
    if (menuRight.current) {
      menuRight.current.toggle(event);
    }
  };

  const handleViewMember = () => {
    return () => {
      navigate("/profile/");
    };
  };

  const actionItems: MenuItem[] = [
    {
      label: t("member.editProfile"),
      command: handleViewMember(),
      // command: () => {
      //   navigate("/member/request-view");
      // },
    },
  ];

  const actionBodyTemplate = (rowData: any) => {
    return (
      <>
        <Menu
          model={actionItems}
          popup
          ref={menuRight}
          id="popup_menu_right"
          popupAlignment="right"
        />
        <Button
          text
          className="text-body gap-1"
          aria-haspopup="true"
          aria-label={t("member.action")}
          onClick={(e) => toggleMenu(e, rowData)}
        >
          <FeatherIcon name="settings" size={20} />
          <span className="font-normal">{rowData.action}</span>
        </Button>
      </>
    );
  };

  const createMemberRecord = async () => {
    dispatch(createRecordRequest(createRecord));
    navigate("/record/create");
  };

  const handleSelectionChange = (e: any) => {
    setSelectedVolunteer(e.value);
    setCreateRecord((prevData: any) => ({
      ...prevData,
      userProfileId: e.Id,
    }));
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search"> </InputIcon>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder={t("member.keywordSearch")}
            aria-label={t("member.keywordSearch")}
          />
        </IconField>
      </div>
    );
  };
  const header = renderHeader();
  return (
    <>
      <div className="">
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("member.allMembers")}
          </h1>
          <p className="text-base font-normal m-0">
            {t("member.allMembersHelp")}
          </p>
        </div>
        {/* <MemberListsSteps  activeStep={1} /> */}
        {/* <div className="flex align-items-center py-3 gap-3 justify-content-between w-full">
          <div className="p-input-icon-left w-full md:w-16rem"></div>
          <div className="flex align-items-center gap-3">
            <div className="relative"></div>
            <Button
              className="button-md gap-1"
              onClick={createMemberRecord}
              disabled={selectedVolunteer === null}
            >
              <FeatherIcon name="plus" size={20} color="inherit" />
              <span className="font-bold">Create New Record</span>
            </Button>
          </div>
        </div> */}
        <DataTable
          sortField="createdDate"
          sortOrder={1}
          stripedRows
          rowsPerPageOptions={[10, 25, 50, 100]}
          showGridlines
          value={committees}
          paginator
          rows={10}
          dataKey="Id"
          filters={filters}
          loading={loading}
          globalFilterFields={["Remark", "fullName", "Lastname", "Email"]}
          header={header}
          emptyMessage={t("member.noCustomersFound")}
          tableStyle={{ minWidth: "50rem" }}
          selectionMode={rowClick ? null : "radiobutton"}
          onSelectionChange={(e: any) => handleSelectionChange(e.value)}
          selection={selectedVolunteer}
        >
          {/* <Column
            selectionMode="single"
            headerStyle={{ width: "3rem" }}
          ></Column> */}
          <Column
            key="fullName"
            field="fullName"
            header={t("member.fullName")}
          />
          {/* <Column key="Email" field="Email" header={t("member.email")} /> */}
          <Column
            key="action"
            field="Action"
            header={t("member.action")}
            body={actionBodyTemplate}
          />
        </DataTable>
      </div>
    </>
  );
};

export default Volunteers;

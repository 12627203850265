import React, { useState, useEffect, ChangeEvent } from "react";
import { Button } from "primereact/button";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { fetchData } from "../../services/apiService";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { CSVLink } from "react-csv";
import { usePermission } from "../auth/Authorization";
import { Calendar } from "primereact/calendar";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import Table from "../common/Table";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { RequestReportList } from "./types/RequestReport";

const RequestReport: React.FC = () => {
  const [t, i18n] = useTranslation("report");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [searchText, setSearchText] = useState<string>("");
  const [dates, setDates] = useState<Date[]>([]);
  const [userProfileId, setUserProfileId] = useState<string>("");
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [committeeList, setCommitteeList] = useState<
    { label: string; value: string }[]
  >([]);
  const [committeeValue, setCommitteeValue] = useState<string[] | null>(null);
  const [memberValue, setMembersValue] = useState<string[] | null>(null);
  const [memberList, setMemberList] = useState<
    { label: string; value: string }[]
  >([]);
  const [userRole, setUserRole] = useState<string>("");
  const [requestList, setRequestLists] = useState<RequestReportList[] | []>([]);

  const breadcumbItems = [
    { label: t("report.dashboard"), url: "/dashboard" },
    { label: t("report.reports") },
  ];

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const responseData = await fetchData(
          "UserProfile/GetCurrentUserProfile"
        );
        setUserProfileId(responseData.Id);
      } catch (error) {
        console.error("Error fetching current user profile:", error);
      }
    };
    fetchCurrentUser();
  }, []);

  const exportData = () => {
    if (!requestList) {
      return null;
    }
    const sortedData = requestList.map((item: RequestReportList) => ({
      "Request number": item.RequestNumber,
      "Member Name": item.MemberName,
      "Committee Applied for": item.CommitteeAppliedFor,
      "Category of Interest": item.CategoryOfInterest,
      "Staff contact": item.StaffContact,
      "Date of request": item.CreatedDate,
    }));
    const csvData = [...sortedData];
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const filename = `Membership_Requests_Report_${day}-${month}-${year}_${hours}${minutes}${seconds}.csv`;
    return (
      <CSVLink data={csvData} filename={filename}>
        <Button className="button-md gap-1 justify-content-center w-full md:w-auto">
          <span className="font-bold text-capitalize">
            {t("report.export")}
          </span>
        </Button>
      </CSVLink>
    );
  };

  const handleChangeCommittee = (e: MultiSelectChangeEvent) => {
    const selectedCommittee = e.value;
    setCommitteeValue(selectedCommittee);
  };

  const handleChangeMembers = (e: MultiSelectChangeEvent) => {
    const selectedMembers = e.value;
    setMembersValue(selectedMembers);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const buildUrl = (
    dates: any,
    memberValue: null | string | string[],
    committeeValue: null | string | string[],
    userProfileId: string
  ) => {
    let url = "MembershipRequest/GetMembershipRequestReport?";
    const filters: {
      Key: string;
      Value: string | number | string[] | null | number[];
    }[] = [];
    if (dates && dates.length > 0) {
      const formattedStartDate = formatDate(dates[0]);
      const formattedEndDate = formatDate(dates[dates.length - 1]);
      filters.push({ Key: "StartDate", Value: formattedStartDate });
      filters.push({ Key: "EndDate", Value: formattedEndDate });
    }
    if (committeeValue && committeeValue.length > 0) {
      filters.push({ Key: "CommitteeId", Value: committeeValue });
    }
    if (memberValue && memberValue.length > 0) {
      filters.push({ Key: "UserProfileId", Value: memberValue });
    }
    if (userProfileId && userRole !== "Admin") {
      filters.push({ Key: "StaffId", Value: userProfileId });
    }
    url += filters
      .map(
        (filter: {
          Key: string;
          Value: string | number | string[] | null | number[];
        }) =>
          `Filters[${filters.indexOf(filter)}].Key=${filter.Key}&Filters[${filters.indexOf(filter)}].Value=${filter.Value}`
      )
      .join("&");
    url += `&PageIndex=-1`;
    return url;
  };

  const fetchDataBasedOnFilters = async (
    url: string,
    setRequestLists: React.Dispatch<React.SetStateAction<any[]>>,
    setMemberList: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    try {
      const responseData = await fetchData(url);
      const sortedData = responseData.Collection.map(
        (item: RequestReportList) => ({
          ...item,
          CreatedDate: item.CreatedDate
            ? new Date(item.CreatedDate).toLocaleDateString()
            : null,
        })
      );
      setRequestLists(sortedData);
      if (!committeeValue && !memberValue) {
        const uniqueUserIds = new Set();
        const memberListData = responseData.Collection.reduce(
          (
            accumulator: { label: string; value: string }[],
            item: RequestReportList
          ) => {
            if (!uniqueUserIds.has(item.UserProfileId)) {
              accumulator.push({
                label: item.MemberName,
                value: item.UserProfileId,
              });
              uniqueUserIds.add(item.UserProfileId);
            }
            return accumulator;
          },
          []
        );
        const CommitteeListData = responseData.Collection.reduce(
          (
            accumulator: { label: string; value: string }[],
            item: RequestReportList
          ) => {
            if (!uniqueUserIds.has(item.CommitteeId)) {
              accumulator.push({
                label: item.CommitteeAppliedFor,
                value: item.CommitteeId,
              });
              uniqueUserIds.add(item.CommitteeId);
            }
            return accumulator;
          },
          []
        );
        setMemberList(memberListData);
        setCommitteeList(CommitteeListData);
      }
    } catch (error) {
      console.error("Error fetching current user profile:", error);
    }
  };

  useEffect(() => {
    if (!userProfileId) return;
    const url = buildUrl(dates, memberValue, committeeValue, userProfileId);
    if (url) {
      fetchDataBasedOnFilters(url, setRequestLists, setMemberList);
    }
  }, [dates, memberValue, committeeValue, userProfileId]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    const filteredList = requestList?.filter((item: RequestReportList) => {
      const RequestNumber = item.RequestNumber || "";
      const MemberName = item.MemberName || "";
      const CommitteeAppliedFor = item.CommitteeAppliedFor || "";
      return (
        searchText === "" ||
        CommitteeAppliedFor.toLowerCase().includes(searchText) ||
        MemberName.toLowerCase().includes(searchText) ||
        RequestNumber.toLowerCase().includes(searchText)
      );
    });
    setRequestLists(filteredList);
  };

  return (
    <>
      <div className="">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-wrap md:flex-nowrap md:align-items-center gap-3 w-full justify-content-between">
          <h1 className="text-title display-xs font-bold m-0">
            {t("report.membershipRequestsReport")}
          </h1>
          <Calendar
            className="custom-datepicker w-full md:w-18rem lg:w:20rem"
            value={dates}
            ariaLabel={t("report.selectDateRange")}
            placeholder={t("report.selectDateRange")}
            onChange={(e: any) => setDates(e.value)}
            selectionMode="range"
          />
        </div>
        <div>
          <div className="flex flex-wrap md:align-items-center py-3 gap-3 w-full justify-content-between">
            <IconField iconPosition="left" className="w-full md:w-16rem">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                id="searchCommittee"
                className="w-full"
                placeholder={t("report.search")}
                aria-label={t("report.search")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </IconField>
            <div className="flex flex-wrap gap-3 w-full md:w-auto align-items-center">
              <MultiSelect
                value={committeeValue}
                onChange={handleChangeCommittee}
                options={committeeList}
                optionLabel="label"
                display="chip"
                filter
                aria-label={t("report.selectCommittees")}
                placeholder={t("report.selectCommittees")}
                maxSelectedLabels={3}
                className="w-full md:w-18rem lg:w:20rem"
              />
              <MultiSelect
                value={memberValue}
                onChange={handleChangeMembers}
                options={memberList}
                optionLabel="label"
                display="chip"
                filter
                aria-label={t("report.selectMembers")}
                placeholder={t("report.selectMembers")}
                maxSelectedLabels={3}
                className="w-full md:w-18rem lg:w:20rem"
              />
              {exportData()}
            </div>
          </div>
          <Table
            products={requestList?.filter((item: any) => {
              const RequestNumber = "" || item.RequestNumber;
              const MemberName = "" || item.MemberName;
              const CommitteeAppliedFor = "" || item.CommitteeAppliedFor;
              return (
                searchText === "" ||
                CommitteeAppliedFor.toLowerCase().includes(
                  searchText.toLowerCase()
                ) ||
                MemberName.toLowerCase().includes(searchText.toLowerCase()) ||
                RequestNumber.toLowerCase().includes(searchText.toLowerCase())
              );
            })}
            columns={[
              { field: "RequestNumber", header: t("report.requestNumber") },
              { field: "MemberName", header: t("report.memberName") },
              {
                field: "CommitteeAppliedFor",
                header: t("report.committeeAppliedFor"),
              },
              { field: "CreatedDate", header: t("report.dateOfRequest") },
              {
                field: "CategoryOfInterest",
                header: t("report.categoryOfInterest"),
              },
              { field: "StaffContact", header: t("report.staffContact") },
            ]}
            loading={false}
          />
        </div>
      </div>
    </>
  );
};

export default RequestReport;

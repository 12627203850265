import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IDENTITY_URL } from "../../config";

const EmailSent = () => {
  const location = useLocation();
  const formData = location.state?.formData;
  const tokenResponse = location.state?.token;
  const [resetSent, setResetSent] = useState(false);
  const [countdown, setCountdown] = useState(30);

  const handleSendEmailAgain = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    setResetSent(true);
    try {
      const response = await fetch(`${IDENTITY_URL}/User/SendEmailAgain`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenResponse}`,
        },
        body: JSON.stringify(formData),
      });
      const responseData = await response.json();
    } catch (error) {}
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (resetSent && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (resetSent && countdown === 0) {
      setResetSent(false);
      setCountdown(30);
    }

    return () => clearTimeout(timer);
  }, [resetSent, countdown]);

  return (
    <>
      <div className="flex align-items-center justify-content-center align-self-center h-full p-3">
        <div className="surface-card p-fluid sm:px-4 md:px-8 shadow-2 border-round w-full loginCards">
          <div className="flex flex-column gap-6 md:p-8 p-5">
            <div className="flex flex-column gap-2">
              <h1 className="text-title display-sm font-bold m-0 mb-3">
                Email sent
              </h1>
              <p className="m-0">
                We have sent a verification link to{" "}
                <strong>{formData?.emailAddress} </strong>
              </p>
              <p className="m-0">
                Follow the instructions on the email to verify your email id.
              </p>
            </div>
            <div>
              <span>Haven’t received an email?</span>
              {resetSent ? (
                <span>
                  Resend in
                  <span className="font-bold text-link">
                    {countdown} seconds
                  </span>
                </span>
              ) : (
                <>
                  <span
                    className="font-bold underline ml-2 text-link cursor-pointer"
                    onClick={handleSendEmailAgain}
                  >
                    Send the Reset Link again
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailSent;

import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import Table from "../../common/Table";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { OverlayPanel } from "primereact/overlaypanel";

const AssignProjectTeam: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const { control, formState: { errors }, handleSubmit } = useForm();

  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex flex-column gap-2 px-5 py-4 border-bottom-1 border-gray-200 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.selectProjectTeam")} ({t("record.optional")})
            </h2>
            <p className="text-base font-normal m-0">
              {t("record.requiredUnlessOptional")}
            </p>
          </div>

          <div className="p-5 cardBody">
            <div className="flex justify-content-between align-items-center pb-3 gap-3 w-full">
              <IconField iconPosition="left" className="w-full md:w-16rem">
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText
                  id="searchMember"
                  placeholder={t("record.searchMember")}
                  className="w-full"
                  aria-label={t("record.searchMember")}
                />
              </IconField>

              <div className="relative">
                <Button
                  className="button-md gap-1"
                  severity="secondary"
                  onClick={toggleFilterPanel}
                  aria-label={t("record.filter")}
                >
                  <FeatherIcon name="filter" size={20} color="inherit" />
                  <span className="font-bold text-capitalize hidden md:flex">
                    {t("record.filter")}
                  </span>
                </Button>
                {/* OverlayPanel for Filter */}
                <OverlayPanel
                  ref={overlayPanelRef}
                  onHide={() => setShowFilterPanel(false)}
                  appendTo={"self"}
                  dismissable
                  className="p-overlaypanel-filter left-auto right-0 top-100"
                  aria-labelledby="filter-heading"
                  role="dialog"
                >
                  <div className="flex flex-column gap-1" tabIndex={0}>
                    <p id="filter-heading" className="text-lg font-bold m-0 p-3">
                      {t("record.filter")}
                    </p>
                    <div className="filter-wrap">
                      {/* Add filter fields here */}
                    </div>
                    <Button
                      className="button-md w-full justify-content-center"
                    >
                      {t("record.applyFilter")}
                    </Button>
                  </div>
                </OverlayPanel>
              </div>
            </div>

            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "memberName", header: t("record.memberName") },
                { field: "committeeName", header: t("record.committeeName") },
                { field: "position", header: t("record.position") },
                { field: "endDate", header: t("record.endDate") },
                { field: "status", header: t("record.status") },
                { field: "action", header: t("record.action") },
              ]}
              // actionItems={actionItems}
              showCheckbox={true}
            />
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.notes")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 col-12">
                  <label
                    htmlFor="projectTeamNotes"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("record.projectTeamNotes")}
                  </label>
                  <Controller
                    name="projectTeamNotes"
                    control={control}
                    render={({ field }) => (
                      <InputTextarea
                        id="projectTeamNotes"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("record.projectTeamNotes")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
                <div className="col-12">
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("record.uploadDocument")}
                    </span>
                  </Button>
                </div>
              </div>
              <div>
                <Button
                  text
                  className="p-button-plain gap-2 pl-0 underline"
                >
                  <span className="font-bold text-capitalize">
                    {t("record.addNewNote")}
                  </span>
                </Button>
              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default AssignProjectTeam;

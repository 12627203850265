import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";
import { buildQueryParams, formatDate } from "../../../utils/utils";
import { fetchData as getData } from "../../../services/apiService";
import { fetchData, postData } from "../../../services/Ballot/apiservice";
import { ballotDetails } from "../../../slices/ballotDetails";

type HandleChangeType = (value: string, label: string) => void;
type OnSubmitType = (data: { [key: string]: any }) => void;

const SearchRecordForm = () => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const toast = useRef<Toast>(null);
  const errorMessageToast = (msg: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };
  const dispatch = useDispatch();
  const {
    accreditationBodyOptions,
    primaryCommitteOptions,
    ballotTypeOptions,
    ballotSubTypeOptions,
    ballotLevelOptions,
    recordTypeOptions,
    recordSubTypeOptions,
    positionAppliedOptions,
    isEdit,
  } = useSelector((state: any) => state.ballotSelectRecords);
  const {
    accreditationBodySelect,
    primaryCommitteSelect,
    ballotTypeSelect,
    ballotSubTypeSelect,
    ballotLevelSelect,
    recordTypeSelect,
    recordSubTypeSelect,
    positionAppliedSelect,
  } = useSelector(
    (state: any) => state.ballotSelectRecords.selectedRecordValues
  );
  const { ballotStatusList, ballotDetailsInfo } = useSelector(
    (state: any) => state.ballotDetails
  );
  let ballotId = ballotDetailsInfo.id;
  const [ballotCreated, setBallotCreated] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      accreditationBodyValue: accreditationBodySelect?.value,
      primaryCommitteeValue: primaryCommitteSelect?.value,
      ballotTypeValue: ballotTypeSelect?.value,
      ballotSubTypeValue: ballotSubTypeSelect?.value,
      ballotLevelValue: ballotLevelSelect?.value,
      recordTypeValue: recordTypeSelect?.value,
      recordSubTypeValue: recordSubTypeSelect?.value,
      positionAppliedValue: positionAppliedSelect?.value,
    },
  });
  const handleChange: HandleChangeType = (value, label) => {
    dispatch(
      ballotSelectRecords.selectedRecordValues({ target: value, label })
    );
  };
  const onSubmit: OnSubmitType = (data) => {
    for (const [key, value] of Object.entries(data)) {
      handleChange(value as string, key);
    }
    setBallotCreated(true);
  };
  // Search Query Params
  const paramsFilters = [
    { key: "CommitteeId", value: primaryCommitteSelect },
    { key: "BallotTypeSelect", value: ballotTypeSelect },
    { key: "BallotSubTypeSelect", value: ballotSubTypeSelect },
    { key: "BallotLevelSelect", value: ballotLevelSelect },
    { key: "RecordTypeId", value: recordTypeSelect },
    { key: "MembershipPositionId", value: positionAppliedSelect },
  ];
  let idx = 0;
  const params = paramsFilters.reduce((acc: any, filter: any) => {
    if (filter?.value?.id) {
      acc[`Filters[${idx}].Key`] = filter.key;
      acc[`Filters[${idx}].Value`] = filter.value.id;
      idx++;
    }
    return acc;
  }, {});
  const queryString = buildQueryParams(params);
  const searchRecordhandler = async () => {
    try {
      const apiUrl = `Search?${queryString}&PageIndex=1&PageSize=10000`;
      const searchRecordData = await getData("Record", apiUrl);
      const tableProducts = searchRecordData.Collection.map((record: any) => {
        return {
          id: record.Id,
          recordNumber: record.RecordNumber,
          applicantName: record.UserProfile.Firstname,
          positionAppliedFor: record.MembershipPosition.PositionName,
          categoryOfInterest: record.CategoryOfInterest.CategoryName,
          requestedOn: formatDate(record.CreatedDate),
          statusName: record.RecordStatus.StatusName,
          termYear: 10,
          createdDate: record.CreatedDate,
          ModifiedDate: record.ModifiedDate,
        };
      });
      const filteredData = tableProducts.filter(
        (item: any) => item.statusName === "Open"
      );
      dispatch(
        ballotSelectRecords.addRecordTableData({ rowTableData: filteredData })
      );
      if (!ballotId) {
        createBallotHandler();
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const ballotPayload = {
    accreditationBodyId: accreditationBodySelect?.id,
    ballotLevelId: ballotLevelSelect?.id,
    ballotTypeId: ballotTypeSelect?.id,
    ballotSubTypeId: ballotSubTypeSelect?.id,
    ballotStatusId:
      ballotDetailsInfo?.ballotStatusId ?? ballotStatusList?.Draft?.id,
    recordSubTypeId: recordSubTypeSelect?.id,
    recordTypeId: recordTypeSelect?.id,
    committeeId: primaryCommitteSelect?.id,
    membershipPositionId: positionAppliedSelect?.id,
    note: "",
    noOfRecords: 0,
    explaination: "",
    description: "",
    daysToIssue: 0,
    openDate: null,
    closeDate: null,
    ballotNumber: "string",
    isSecretBallot: false,
  };
  useEffect(() => {
    if (ballotCreated) {
      searchRecordhandler();
    }
  }, [queryString]);
  const createBallotHandler = async () => {
    let payload = {
      isDirty: ballotId ? true : false,
      isNew: ballotId ? false : true,
      ...ballotPayload,
    };
    try {
      const createBallot = await postData(`Ballot`, payload);
      const formattedBallotDetails = JSON.parse(createBallot.content);
      dispatch(
        ballotDetails.addBallotDetails({
          target: formattedBallotDetails.Id,
          label: "ballotid",
        })
      );
      dispatch(
        ballotDetails.addBallotDetails({
          target: formattedBallotDetails.BallotNumber,
          label: "ballotnumber",
        })
      );
    } catch (err: any) {
      if (
        err.response?.data?.errors?.requestModel[0]?.includes(
          "limit has reached"
        )
      ) {
        errorMessageToast("Allowed No. of Ballot limit has reached");
      }
    }
  };
  const getBallotDetailsHandler = async () => {
    try {
      const allBallotDetails = await fetchData(`Ballot/${ballotId}`);
      onEditBallotHandler(allBallotDetails);
    } catch (err) {
      console.log(err, "errr");
    }
  };
  const onEditBallotHandler = (ballotDetailsData: any) => {
    // ballot record section 1
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.AccreditationBody?.Name,
        label: "accreditationBodyValue",
      })
    );
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.Committee?.Name,
        label: "primaryCommitteeValue",
      })
    );
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.BallotType?.Name,
        label: "ballotTypeValue",
      })
    );
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.BallotSubType?.Name,
        label: "ballotSubTypeValue",
      })
    );
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.BallotLevel?.Name,
        label: "ballotLevelValue",
      })
    );
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.RecordType?.TypeName,
        label: "recordTypeValue",
      })
    );
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.RecordSubType?.Type,
        label: "recordSubTypeValue",
      })
    );
    dispatch(
      ballotSelectRecords.selectedRecordValues({
        target: ballotDetailsData?.MembershipPosition?.Description,
        label: "positionAppliedValue",
      })
    );
    // ballot Details section 2
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.Id,
        label: "ballotid",
      })
    );
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.OpenDate,
        label: "opendate",
      })
    );
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.CloseDate,
        label: "enddate",
      })
    );
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.Description,
        label: "description",
      })
    );
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.Explaination,
        label: "explaination",
      })
    );
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.Note,
        label: "staffnote",
      })
    );
    dispatch(
      ballotDetails.addBallotDetails({
        target: ballotDetailsData?.BallotNumber,
        label: "ballotnumber",
      })
    );
  };
  useEffect(() => {
    if (isEdit) {
      getBallotDetailsHandler();
    }
  }, [isEdit]);
  return (
    <>
      <Toast ref={toast} />
      <div className="card bg-white w-full mb-5 shadow-md">
        <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
          <div className="flex flex-column gap-1">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.enterTheBasicBallotDetailsAndSearchRecords")}
            </h2>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="accreditationBodyValue"
                    className={`block font-bold text-capitalize ${
                      errors.accreditationBodyValue
                        ? " p-error"
                        : "text-input-label"
                    }`}
                  >
                    {t("ballot.accreditationBody")}*
                  </label>
                  <Controller
                    name="accreditationBodyValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.accreditationBody")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="accreditationBodyValue"
                        aria-describedby="accreditationBodyValue"
                        {...field}
                        value={field.value || accreditationBodySelect?.value}
                        options={accreditationBodyOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.accreditationBodyValue ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  {errors.accreditationBodyValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.accreditationBodyValue.message} `}
                    </span>
                  )}
                </div>
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="primaryCommitteeValue"
                    className={`block font-bold text-capitalize ${
                      errors.primaryCommitteeValue
                        ? " p-error"
                        : "text-input-label"
                    }`}
                  >
                    {t("ballot.primaryCommitteeResponsible")}*
                  </label>
                  <Controller
                    name="primaryCommitteeValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.primaryCommitteeResponsible")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        filter
                        inputId="primaryCommitteeValue"
                        aria-describedby="primaryCommitteeValue"
                        {...field}
                        value={field.value || primaryCommitteSelect?.value}
                        options={primaryCommitteOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.primaryCommitteeValue ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  {errors.primaryCommitteeValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.primaryCommitteeValue.message} `}
                    </span>
                  )}
                </div>
              </div>
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="ballotTypeValue"
                    className={`block font-bold text-capitalize ${
                      errors.ballotTypeValue ? " p-error" : "text-input-label"
                    }`}
                  >
                    {t("ballot.ballotType")}*
                  </label>
                  <Controller
                    name="ballotTypeValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.ballotType")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="ballotTypeValue"
                        aria-describedby="ballotTypeValue"
                        {...field}
                        value={field.value || ballotTypeSelect?.value}
                        options={ballotTypeOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.ballotTypeValue ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  {errors.ballotTypeValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.ballotTypeValue.message} `}
                    </span>
                  )}
                </div>
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="ballotSubTypeValue"
                    className={`block font-bold text-capitalize ${
                      errors.ballotSubTypeValue
                        ? " p-error"
                        : "text-input-label"
                    }`}
                  >
                    {t("ballot.ballotSubType")}*
                  </label>
                  <Controller
                    name="ballotSubTypeValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.ballotSubType")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="ballotSubTypeValue"
                        aria-describedby="ballotSubTypeValue"
                        {...field}
                        value={field.value || ballotSubTypeSelect?.value}
                        options={ballotSubTypeOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.ballotTypeValue ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  {errors.ballotSubTypeValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.ballotSubTypeValue.message} `}
                    </span>
                  )}
                </div>
              </div>
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="ballotLevelValue"
                    className={`block font-bold text-capitalize ${
                      errors.ballotLevelValue ? " p-error" : "text-input-label"
                    }`}
                  >
                    {t("ballot.ballotLevel")}*
                  </label>
                  <Controller
                    name="ballotLevelValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.ballotLevel")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="ballotLevelValue"
                        aria-describedby="ballotLevelValue"
                        {...field}
                        value={field.value || ballotLevelSelect?.value}
                        options={ballotLevelOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.ballotLevelValue ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  {errors.ballotLevelValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.ballotLevelValue.message} `}
                    </span>
                  )}
                </div>
              </div>
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="recordTypeValue"
                    className={`block font-bold text-capitalize ${
                      errors.recordTypeValue ? " p-error" : "text-input-label"
                    }`}
                  >
                    {t("ballot.recordType")}*
                  </label>
                  <Controller
                    name="recordTypeValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.recordType")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="recordTypeValue"
                        aria-describedby="recordTypeValue"
                        {...field}
                        value={field.value || recordTypeSelect?.value}
                        options={recordTypeOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.recordTypeValue ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  {errors.recordTypeValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.recordTypeValue.message} `}
                    </span>
                  )}
                </div>
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="recordSubTypeValue"
                    className={`block font-bold text-capitalize ${
                      errors.recordSubTypeValue
                        ? " p-error"
                        : "text-input-label"
                    }`}
                  >
                    {t("ballot.recordSubType")}*
                  </label>
                  <Controller
                    name="recordSubTypeValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.recordSubType")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="recordSubTypeValue"
                        aria-describedby="recordSubTypeValue"
                        {...field}
                        value={field.value || recordSubTypeSelect?.value}
                        options={recordSubTypeOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.recordSubTypeValue ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  {errors.recordSubTypeValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.recordSubTypeValue.message} `}
                    </span>
                  )}
                </div>
              </div>
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="positionAppliedValue"
                    className={`block font-bold text-capitalize ${
                      errors.positionAppliedValue
                        ? " p-error"
                        : "text-input-label"
                    }`}
                  >
                    {t("ballot.positionAppliedFor")}*
                  </label>
                  <Controller
                    name="positionAppliedValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.positionAppliedFor")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="positionAppliedValue"
                        aria-describedby="positionAppliedValue"
                        {...field}
                        value={field.value || positionAppliedSelect?.value}
                        options={positionAppliedOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.positionAppliedValue ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  {errors.positionAppliedValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.positionAppliedValue.message} `}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="p-5 cardBody">
            <Button
              className="button-md gap-2"
              severity="secondary"
              type="submit"
              disabled={!isValid}
            >
              <FeatherIcon name="search" size={20} color="inherit" />
              <span className="font-bold text-capitalize">
                {t("ballot.searchRecords")}
              </span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SearchRecordForm;

import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { RootState } from "../../store/store";
import FeatherIcon from "../common/FeatherIconComponent";
import MemberRequestSteps from "./MemberRequestSteps";
import {
  deleteData,
  fetchData,
  getListByParentId,
  putData,
} from "../../services/apiService";
import pdfIcon from "../../assets/icons/pdf.svg";
import { useLanguageContext } from "../LanguageContext";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { resetMemberRequest } from "../../slices/createMemberRequestSlice";
import RequestListDocument from "../common/RequestListDocument";
import RecordListDocument from "../common/RecordListDocument";
import { clearAllSessionStorage } from "../../utils/storage";

const RequestPreview: React.FC = () => {
  //const { setLoading } = useLoadingContext();
  const toast = useRef<any>(null);
  const dispatch = useDispatch();
  const [memberShipData, setMemberShipData] = useState<any>(null);
  const [memberShipStatusDraft, setMemberShipStatusDraft] =
    useState<String>("");
  const [memberShipStatusSubmitted, setMemberShipStatusSubmitted] =
    useState<String>("");
  const [userProfileId, setUserProfileId] = useState<string>("");
  const [membershipRequestId, setMembershipRequestId] = useState<any>(null);
  const [membershipRecordId, setMembershipRecordId] = useState<any>(null);
  const [userDocumentTypeId, setUserDocumentTypeId] = useState<
    string | undefined
  >(undefined);
  const [isprofessionalLicenseDetails, setIsprofessionalLicenseDetails] =
    useState<boolean>(true);
  const [professionalLicenseDetails, setProfessionalLicenseDetails] =
    useState<any>(null);
  const [alternetMemberUserProfileId, setAlternetMemberUserProfileId] =
    useState<any>(null);
  const [alternetMemberName, setAlternetMemberName] = useState<any>(null);
  const [t, i18n] = useTranslation("member");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const breadcumbItems = [
    { label: t("member.dashboard"), url: "/dashboard" },
    { label: t("member.membershipRequest") },
  ];
  const createCommitteeData = useSelector(
    (state: RootState) => state.createMemberRequest
  );
  const storedData = sessionStorage.getItem("committeeId");
  const parsedData = storedData ? storedData : ""; // Parse the JSON string from sessionStorage
  const committeeId = parsedData || createCommitteeData.data?.committeeId;
  const [termsConditionsCheked, setTermsConditionsCheked] =
    useState<boolean>(false);
  const [confidentialityAgreement, setConfidentialityAgreement] =
    useState<boolean>(false);
  const [saveAsDraftClicked, setSaveAsDraftClicked] = useState(false);
  const [documentDetails, setDocumentDetails] = useState<any>(null);
  const handleAgreementboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setConfidentialityAgreement(e.checked);
    }
  };
  const handleTermsboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setTermsConditionsCheked(e.checked);
    }
  };

  const navigate = useNavigate();
  const handleMemberRequestStatus = async () => {
    const committeeResponse = await fetchData("MembershipStatus/GetAll");
    const draftRecord = committeeResponse.Collection.find(
      (item: any) => item.StatusName === "Draft"
    );
    const submittedRecord = committeeResponse.Collection.find(
      (item: any) => item.StatusName === "Submitted"
    );
    setMemberShipStatusSubmitted(submittedRecord.Id);
    setMemberShipStatusDraft(draftRecord.Id);
  };

  const getAll = async () => {
    const fetchAllDocuments = await fetchData("DocumentType/GetAll");
    const documents = fetchAllDocuments.Collection;

    let requestDocumentId = null;
    let evidenceDocumentId = null;

    for (const document of documents) {
      if (document.TypeName === "RequestDocument") {
        requestDocumentId = document.Id;
      } else if (document.TypeName === "EvidenceforAlternateMembership") {
        evidenceDocumentId = document.Id;
      }

      // Break the loop if both IDs are found
      if (requestDocumentId && evidenceDocumentId) {
        break;
      } else if (document.TypeName === "EvidenceforAlternateMembership") {
        setUserDocumentTypeId(document.Id);
        break;
      }
    }

    if (requestDocumentId) {
      setUserDocumentTypeId(requestDocumentId);
    }

    if (evidenceDocumentId) {
      setUserDocumentTypeId(evidenceDocumentId);
    }
  };

  React.useEffect(() => {
    getAll();
  }, []);

  React.useEffect(() => {
    handleMemberRequestStatus();
    const fetchCurrentUser = async () => {
      try {
        const responseData = await fetchData(
          "UserProfile/GetCurrentUserProfile"
        );
        setUserProfileId(responseData.Id);
      } catch (error) {
        console.error("Error fetching current user profile:", error);
      }
    };
    fetchCurrentUser();
    let memberId: string | null | undefined;
    if (
      createCommitteeData.data &&
      createCommitteeData.data.membershipRequestid
    ) {
      memberId = createCommitteeData.data.membershipRequestid;
      setMembershipRequestId(memberId);
    } else {
      const storedData = sessionStorage.getItem("membershipRequestid");

      if (storedData) {
        const parsedData = JSON.parse(storedData);
        memberId = parsedData.membershipRequestId;
        setMembershipRequestId(memberId);
      }
    }
    if (memberId) {
      const fetchDataMemberRequest = async () => {
        try {
          const responseData = await fetchData("MembershipRequest", memberId);
          setMembershipRecordId(responseData.RecordId);
          setAlternetMemberUserProfileId(
            responseData.AlternateMemberUserProfileId
          );
          setConfidentialityAgreement(
            responseData.IsAgreeToConfidentialityAgreement
          );
          setTermsConditionsCheked(responseData.IsAgreeToTermandCondition);
          setMemberShipData(responseData);
        } catch (error) {
          console.error("Error fetching Category of Interest:", error);
        }
      };
      const fetchAlternetMemberDetails = async () => {
        try {
          const responseData = await fetchData(
            "UserProfile",
            alternetMemberUserProfileId
          );
          setAlternetMemberName(
            responseData.Firstname + " " + responseData.Lastname
          );
        } catch (error) {}
      };
      fetchDataMemberRequest();
      fetchAlternetMemberDetails();
    }
    fetchUploadMemberRequest();
  }, [createCommitteeData, membershipRequestId, alternetMemberUserProfileId]);

  React.useEffect(() => {
    if (userProfileId) {
      const fetchDataAsync = async () => {
        try {
          const [licenseData] = await Promise.all([
            getListByParentId("UserProfessionalLicense", userProfileId),
          ]);
          setProfessionalLicenseDetails(licenseData?.Collection);
          if (licenseData?.Collection) {
            setIsprofessionalLicenseDetails(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchDataAsync();
    }
  }, [userProfileId]);

  const [visible, setVisible] = useState<boolean>(false);
  const footerContent = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("member.goToAllRequests")}
        onClick={() => {
          setVisible(false);
          navigate("/member");
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("member.goToDashboard")}
        onClick={() => {
          setVisible(false);
          navigate("/dashboard");
        }}
        className="button-md"
        autoFocus
      />
    </div>
  );

  const handlePostData = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (saveAsDraftClicked || termsConditionsCheked) {
        if (saveAsDraftClicked) {
          memberShipData.MembershipStatusId = memberShipStatusDraft;
        } else {
          memberShipData.MembershipStatusId = memberShipStatusSubmitted;

          setVisible(true);
          dispatch(resetMemberRequest());
          clearAllSessionStorage();
        }
        memberShipData.IsAgreeToTermandCondition = termsConditionsCheked;
        memberShipData.IsAgreeToConfidentialityAgreement =
          confidentialityAgreement;
        await putData("MembershipRequest", memberShipData.Id, memberShipData);

        if (toast.current && saveAsDraftClicked) {
          toast.current.show({
            severity: "success",
            summary: t("member.success"),
            detail: t("member.dataSavedSuccessfullyAsDraft"),
          });
        }
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const fetchUploadMemberRequest = async () => {
    if (membershipRequestId) {
      try {
        const response = await fetchData(
          `MembershipRequestDocument/Search?Filters[0].Key=DocumentTypeId&Filters[0].Value=${userDocumentTypeId}&Filters[1].Key=membershipRequestId&Filters[1].Value=${membershipRequestId}&PageIndex=-1`
        );
        const documentData = response.Collection;
        setDocumentDetails(documentData);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const isValidMembershipRecordId = (id: string) => {
    const invalidId = "00000000-0000-0000-0000-000000000000";
    return id && id !== invalidId;
  };
  const handleFetchUserDocument = async () => {
    try {
      const documentResponse = await fetchData(
        `MembershipRequestDocument/GetRequestDocumentValetKey?membershipRequestId=${membershipRequestId}&userDocumentType=RequestDocument`
      );
      //setDocumentUploadData(documentResponse);
      return documentResponse;
    } catch (error) {}
  };

  React.useEffect(() => {}, []);

  return (
    <>
      <Toast ref={toast} />
      <div className="">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("member.membershipRequest")}
          </h1>
          <p className="text-base font-normal m-0">
            {t("member.membershipRequestSubText")}
          </p>
        </div>

        <MemberRequestSteps activeStep={2} />
        <form onSubmit={handlePostData}>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("member.committeeDetails")}
              </h2>
              <div className="flex text-sm">
                <Button
                  text
                  className="p-button-plain gap-2 underline"
                  onClick={() => {
                    navigate("/member/create");
                  }}
                >
                  <FeatherIcon name="edit-2" size={20} />
                  <span>{t("member.edit")}</span>
                </Button>
              </div>
            </div>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-4">
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("member.committeeName")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {memberShipData?.Committee?.Name}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("member.staffSecretary")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {memberShipData?.Committee?.Staff?.Firstname}{" "}
                    {memberShipData?.Committee?.Staff?.Lastname}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("member.membershipDetail")}
              </h2>
              <div className="flex text-sm">
                <Button
                  text
                  className="p-button-plain gap-2 underline"
                  onClick={() => {
                    navigate("/member/details");
                  }}
                >
                  <FeatherIcon name="edit-2" size={20} />
                  <span>{t("member.edit")}</span>
                </Button>
              </div>
            </div>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-4">
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("member.committeePosition")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {memberShipData?.MembershipPosition?.PositionName}
                  </p>
                </div>
                {memberShipData?.MembershipPosition?.PositionName ===
                  "Alternate Member" && (
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                      {t("member.alternetMemberFor")}
                    </span>
                    {alternetMemberName && (
                      <p className="m-0 font-normal text-base text-title m-0 w-full">
                        {alternetMemberName}
                      </p>
                    )}
                  </div>
                )}
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("member.categoryOfInterest")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {memberShipData?.CategoryOfInterest?.CategoryName}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("member.remark")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {memberShipData?.Remark}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("member.professionalDetails")}
              </h2>
            </div>
            {professionalLicenseDetails &&
            professionalLicenseDetails.length > 0 ? (
              <div className="p-5 cardBody">
                <div className="flex flex-column gap-5">
                  {professionalLicenseDetails.map(
                    (license: any, index: number) => (
                      <div className="flex" key={index}>
                        <div className="experienceDetails flex flex-column gap-1">
                          <h3 className="font-bold text-base text-title m-0">
                            {t("member.licenseName")} - {license.LicenseName}
                          </h3>
                          <p className="m-0 font-normal">
                            {t("member.licenseNumber")} -{" "}
                            {license.LicenseNumber}
                          </p>
                          <p className="m-0 font-normal">
                            {t("member.validUpto")} - {license.LicenseYear}
                          </p>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : (
              <div className="p-5 font-normal">
                {t("member.professionalDetailsNotAvailable")}
              </div>
            )}
          </div>

          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("member.documents")}
              </h2>
            </div>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                <div className="flex flex-column gap-3">
                  <h3 className="m-0 font-bold text-base">
                    {t("member.requestDoumentsLabel")}
                  </h3>
                  <p className="m-0 flex align-items-center gap-2">
                    <RequestListDocument
                      documentType="RequestDocument"
                      membershipRequestID={membershipRequestId}
                      showNoDataMessage={true}
                    />
                  </p>
                </div>
                {memberShipData?.MembershipPosition?.PositionName ===
                  "Alternate Member" && (
                  <div className="flex flex-column gap-3">
                    <h3 className="m-0 font-bold text-base">
                      {t("member.requestEvidenceDoumentsLabel")}
                    </h3>
                    <p className="m-0 flex align-items-center gap-2">
                      <RequestListDocument
                        documentType="EvidenceforAlternateMembership"
                        membershipRequestID={membershipRequestId}
                        showNoDataMessage={true}
                      />
                    </p>
                  </div>
                )}
              </div>
              {isValidMembershipRecordId(membershipRecordId) && (
                <div className="flex flex-column gap-5">
                  <div className="flex flex-column gap-3">
                    <h3 className="m-0 font-bold text-base">
                      {t("member.recordDoumentsLabel")}
                    </h3>
                    <p className="m-0 flex align-items-center gap-2">
                      <RecordListDocument
                        documentType="RecordDocument"
                        membershipRecordID={membershipRecordId}
                      />
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("member.termsConditions")}
              </h2>
            </div>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                <div className="border-1 border-gray-200 p-4 p-shadow-4">
                  <div className="section">
                    <h3>1. Acceptance of Terms</h3>
                    <p>
                      By accessing or using the Site or Services, you agree to
                      be bound by these Terms and our Privacy Policy. If you do
                      not agree to these Terms or the Privacy Policy, you may
                      not access or use the Site or Services.
                    </p>
                  </div>

                  <div className="section">
                    <h3>2. Use of the Site and Services</h3>
                    <div className="list">
                      <p>
                        a. License: Subject to these Terms, [Your Company Name]
                        grants you a non-transferable, non-exclusive, revocable
                        license to use the Site and Services.
                      </p>
                      <p>b. Restrictions:</p>
                      <ul>
                        <li>
                          Use the Site or Services for any illegal purpose or in
                          violation of any local, state, national, or
                          international law;
                        </li>
                        <li>
                          Modify, adapt, translate, or reverse engineer any
                          portion of the Site or Services;
                        </li>
                        <li>
                          Attempt to gain unauthorized access to the Site or
                          Services or to any accounts or computer systems
                          connected to the Site or Services;
                        </li>
                        <li>
                          Interfere with the operation of the Site or Services
                          or any user’s enjoyment of them, including by
                          uploading or otherwise disseminating viruses, adware,
                          spyware, worms, or other malicious code;
                        </li>
                        <li>
                          Use any robot, spider, scraper, or other automated
                          means to access the Site or Services for any purpose
                          without our express written permission.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="section">
                    <h3>3. Intellectual Property</h3>
                    <div className="list">
                      <p>
                        a. Ownership: The Site and Services are owned and
                        operated by [Your Company Name]. The visual interfaces,
                        graphics, design, compilation, information, data,
                        computer code (including source code or object code),
                        products, software, services, and all other elements of
                        the Site and Services provided by [Your Company Name]
                        are protected by intellectual property and other laws.
                      </p>
                      <p>
                        b. Trademarks: [Your Company Name]’s trademarks, service
                        marks, and logos used and displayed on the Site and
                        Services are registered and unregistered trademarks or
                        service marks of [Your Company Name]. Other company,
                        product, and service names located on the Site or
                        Services may be trademarks or service marks owned by
                        others (the "Third-Party Trademarks," and, collectively
                        with [Your Company Name]’s trademarks, the
                        "Trademarks"). Nothing on the Site or Services should be
                        construed as granting, by implication, estoppel, or
                        otherwise, any license or right to use the Trademarks,
                        without our prior written permission specific for each
                        such use.
                      </p>
                    </div>
                  </div>

                  <div className="section">
                    <h3>4. Privacy</h3>
                    <p>
                      Please review our Privacy Policy, which explains how we
                      collect, use, and disclose information about you. By using
                      the Site or Services, you consent to the collection, use,
                      and disclosure of your information as described in our
                      Privacy Policy.
                    </p>
                  </div>

                  <div className="section">
                    <h3>5. Disclaimer of Warranties</h3>
                    <p>
                      THE SITE AND SERVICES ARE PROVIDED "AS IS" AND WITHOUT
                      WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE
                      FULLEST EXTENT PERMITTED BY APPLICABLE LAW, [YOUR COMPANY
                      NAME] DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
                      INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                      NON-INFRINGEMENT.
                    </p>
                  </div>

                  <div className="section">
                    <h3>6. Limitation of Liability</h3>
                    <p>
                      TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                      EVENT SHALL [YOUR COMPANY NAME] BE LIABLE FOR ANY
                      INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE
                      DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER
                      INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE,
                      GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF YOUR
                      ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SITE
                      OR SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT
                      (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF
                      [YOUR COMPANY NAME] HAS BEEN ADVISED OF THE POSSIBILITY OF
                      SUCH DAMAGES.
                    </p>
                  </div>

                  <div className="section">
                    <h3>7. Governing Law and Dispute Resolution</h3>
                    <p>
                      These Terms are governed by and construed in accordance
                      with the laws of [Your Jurisdiction], without regard to
                      its conflict of law principles. Any dispute arising out of
                      or relating to these Terms shall be exclusively resolved
                      by the state and federal courts located in [Your
                      Jurisdiction].
                    </p>
                  </div>

                  <div className="section">
                    <h3>8. Changes to Terms</h3>
                    <p>
                      We may modify these Terms at any time. If we make changes
                      to these Terms, we will post the revised Terms on the Site
                      and update the "Last Updated" date at the top of these
                      Terms. By continuing to access or use the Site or Services
                      after we have posted revised Terms, you agree to be bound
                      by the revised Terms.
                    </p>
                  </div>

                  <div className="section">
                    <h3>9. Contact Us</h3>
                    <p>
                      If you have any questions about these Terms, please
                      contact us at [Your Contact Information].
                    </p>
                  </div>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="termsconditions"
                    onChange={handleTermsboxChange}
                    checked={termsConditionsCheked}
                    className="mr-2"
                  />
                  <label
                    htmlFor="termsconditions"
                    className="text-base font-normal"
                  >
                    {t("member.iAgreeToTermsAndConditions")}
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("member.confidentialityAgreement")}
              </h2>
            </div>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                <p className="m-0 font-normal text-base">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <p className="m-0 font-normal text-base">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="confidentiality"
                    onChange={handleAgreementboxChange}
                    checked={confidentialityAgreement}
                    className="mr-2"
                  />
                  <label
                    htmlFor="confidentiality"
                    className="text-base font-normal"
                  >
                    {t("member.iAgreeToConfidentialityAgreement")}
                  </label>
                </div>
              </div>
            </div>
          </div> */}
          <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 fixed-footer w-full left-0 shadow">
            <Button
              label={t("member.cancel")}
              className="button-md"
              severity="secondary"
              onClick={() => {
                navigate("/member");
              }}
            />
            <Button
              text
              className="p-button-plain underline gap-1 flex align-self-center ml-auto"
              label={t("member.saveasdraft")}
              onClick={() => {
                setSaveAsDraftClicked(true);
              }}
            ></Button>
            <Button
              onClick={() => {
                navigate("/member/details");
              }}
              className="button-md"
              severity="secondary"
            >
              <FeatherIcon name="chevron-left" size={20} color="inherit" />
              <span className="font-bold text-capitalize">
                {t("member.back")}
              </span>
            </Button>
            <Button
              onClick={() => {
                setSaveAsDraftClicked(false);
              }}
              label={t("member.submit")}
              disabled={!termsConditionsCheked}
              className="button-md"
            />
          </div>
          {/* Mobile responsive buttions */}
          <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 fixed-footer w-full left-0 shadow">
            `
            <Button
              onClick={() => {
                setSaveAsDraftClicked(false);
              }}
              label={t("member.submit")}
              disabled={!termsConditionsCheked}
              className="button-md w-full"
            />
            <div className="flex align-items-center gap-3 w-full">
              <Button
                onClick={() => {
                  navigate("/member/details");
                }}
                className="button-md"
                severity="secondary"
                aria-label={t("member.back")}
              >
                <FeatherIcon name="chevron-left" size={20} color="inherit" />
              </Button>
              <Button
                label={t("member.cancel")}
                className="button-md w-full"
                severity="secondary"
                onClick={() => {
                  navigate("/member");
                }}
              />
            </div>
            <Button
              text
              className="p-button-plain underline gap-1 w-full"
              label={t("member.saveasdraft")}
              onClick={() => {
                setSaveAsDraftClicked(true);
              }}
            ></Button>
          </div>
        </form>
      </div>
      <Dialog
        header={
          <div className="border-circle bg-green-300 p-2 inline-block w-3rem h-3rem flex align-items-center justify-content-center border-8 border-green-100">
            <FeatherIcon
              name="check-circle"
              size={24}
              color="var(--icon-white)"
            />
          </div>
        }
        visible={visible}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
        className="custom-dialog hide-close"
        ariaCloseIconLabel="Close"
      >
        <div className="flex flex-column gap-3">
          <h2 className="text-lg font-bold m-0">
            {t("member.membershipRequestSubmitted")}
          </h2>
          <p className="text-base font-normal m-0">
            {t("member.requestSubmittedSub")}
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default RequestPreview;

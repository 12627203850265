import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import ToastMessage from "../common/ToastMessage";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { useLoadingContext } from "../Layout";

const TestPage: React.FC = () => {
  const toast = useRef<Toast>(null);

  const showSuccess = () => {
    toast.current?.show({
      severity: "success",
      summary: "Success",
      detail: "Message Content",
      life: 3000,
    });
  };

  const showInfo = () => {
    toast.current?.show({
      severity: "info",
      summary: "Info",
      detail: "Message Content",
      life: 3000,
    });
  };

  const showWarn = () => {
    toast.current?.show({
      severity: "warn",
      summary: "Warning",
      detail: "Message Content",
      life: 3000,
    });
  };

  const showError = () => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: "Message Content",
      life: 3000,
    });
  };

  // For Confirm Dialog
  const accept = () => {
    alert("Yes");
  };
  const reject = () => {
    alert("No");
  };
  const confirmDelete = () => {
    confirmDialog({
      message: "Are you sure you want to delete?",
      header: "Confirmation",
      icon: "",
      defaultFocus: "accept",
      accept,
      reject,
    });
  };
  // For Confirm Dialog

  // for loading
  const { setLoading } = useLoadingContext();
  useEffect(() => {
    setLoading(true);
  }, []);
  return (
    <>
      <div className="mb-7">
        <div className="flex flex-wrap gap-2">
          <ToastMessage
            severity="success"
            summary="Success 11"
            detail="Message Content success"
          >
            Success
          </ToastMessage>
          <ToastMessage
            severity="info"
            summary="Info"
            detail="Message Content info"
          >
            <span>Info</span>
          </ToastMessage>
          <ToastMessage
            severity="warn"
            summary="Warning"
            detail="Message Content warning"
          >
            <div>Warning</div>
          </ToastMessage>
          <ToastMessage
            severity="error"
            summary="Error"
            detail="Message Content error"
          >
            <button
              onClick={(e) => e.preventDefault()}
              className="text-link underline bg-transparent border-none p-0 cursor-pointer"
            >
              Error
            </button>
          </ToastMessage>
        </div>

        <Toast ref={toast} />
        <div className="flex flex-wrap gap-2">
          <Button
            label="Success"
            className="p-button-success"
            onClick={showSuccess}
          />
          <Button label="Info" className="p-button-info" onClick={showInfo} />
          <Button
            label="Warn"
            className="p-button-warning"
            onClick={showWarn}
          />
          <Button
            label="Error"
            className="p-button-danger"
            onClick={showError}
          />
        </div>
      </div>

      <div className="mb-7">
        {/* For Confirm Dialog */}
        <ConfirmDialog className="custom-dialog hide-close" ariaCloseIconLabel="Close" />
        <Button
          onClick={confirmDelete}
          label="Confirm"
          className="mr-2"
        ></Button>
        {/* For Confirm Dialog */}
      </div>
    </>
  );
};

export default TestPage;

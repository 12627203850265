import React, { useState, useRef } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OverlayPanel } from "primereact/overlaypanel"; // Import OverlayPanel
import Table from "../common/Table";
import { Calendar } from "primereact/calendar";
import CommitteeTabDetails from "./CommitteeTabDetails";
import CommitteeTabConfiguration from "./CommitteeTabConfiguration";
import { Badge } from "primereact/badge";
import CommitteeTabRoster from "./CommitteeTabRoster";
import CommitteeTabRequests from "./CommitteeTabRequests";
import CommitteeTabRecords from "./CommitteeTabRecords";
import CommitteeTabBallots from "./CommitteeTabBallots";
import CommitteeTabOpenPositions from "./CommitteeTabOpenPositions";
import CommitteeTabCommitteeMeeting from "./CommitteeTabCommitteeMeeting";
import CommitteeTabStandardAssociated from "./CommitteeTabStandardAssociated";
import CommitteeMeetingTabActionItems from "./CommitteeMeetingTabActionItems";
import CommitteeMeetingTabAttendees from "./CommitteeMeetingTabAttendees";
import CommitteeMeetingTabDocuments from "./CommitteeMeetingTabDocuments";
import CommitteeMeetingTabDetails from "./CommitteeMeetingTabDetails";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
interface Option {
  name: string;
  code: string;
}

const CommitteeMeetingTabs: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const navigate = useNavigate();

  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  const [checked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };
  const [dateActivation, setDateActivation] = useState(null);

  return (
    <>
      <div className="">
        <Button text className="p-button-plain gap-2 mb-3 pl-0 underline">
          <FeatherIcon name="arrow-left" size={20} />
          <span className="">{t("committee.back")}</span>
        </Button>
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">{t("committee.meetingName")}</h1>
          {/* <p className="text-base font-normal m-0">
            Any supporting text for request listing page can go here
          </p> */}
        </div>

        <div className="tabs-wrap">
          <TabView scrollable>
            <TabPanel header={t("committee.details")}>
              <CommitteeMeetingTabDetails />
            </TabPanel>
            <TabPanel header={t("committee.attendees")}>
              <CommitteeMeetingTabAttendees />
            </TabPanel>
            <TabPanel header={t("committee.documents")}>
              <CommitteeMeetingTabDocuments />
            </TabPanel>
            <TabPanel header={t("committee.actionItems")}>
              <CommitteeMeetingTabActionItems />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default CommitteeMeetingTabs;

import React from "react";
import DetailTab from "./DetailTab";
import OtherConfigurationTab from "./OtherConfigurationTab";
import ContributingCommitteeTab from "./ContributingCommitteeTab";

const Preview: React.FC = () => {
  return (
    <>
      <div>
        <DetailTab showEditButton={true} />
        <OtherConfigurationTab showEditButton={true} />
        <ContributingCommitteeTab showEditButton={true} />
      </div>
    </>
  );
};

export default Preview;

import React, { useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
//mport { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import Input from "../../utils/Input";
import { useNavigate } from "react-router-dom";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { focusOnField } from "../../utils/focusField";
interface FormValues {
  memberShipId: string;
  type: string;
  expiryDate: any;
}

const OtherMembershipDetails = () => {
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [formValues, setFormValues] = useState<FormValues>({
    memberShipId: "",
    type: "",
    expiryDate: "",
  });
  const navigate = useNavigate();
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    if (name === "expiryDate") {
      setFormValues({
        ...formValues,
        expiryDate: value ? new Date(value) : null, // Convert string to Date object or null
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              onClick={() => navigate("/profile/")}
              aria-label={t("profile.back")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("profile.editMembershipDetails")}
              </h1>
              <p className="text-base font-normal m-0 text-help">
                {t("profile.allFieldsRequired")}
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-3">
                <div className="grid grid-xl">
                  <div className="xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="memberId"
                      className="block font-bold text-input-label capitalize"
                    >
                      {t("profile.membershipId")}
                    </label>
                    {/* <Input type="text" label={t("profile.firstname")} value={formValues.memberShipId} name="firstname"  
  placeholder="Firstname"
                      /> */}
                  </div>
                </div>

                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="membershipType"
                      className="block font-bold text-input-label capitalize"
                    >
                      {t("profile.membershipType")}
                    </label>

                    <Dropdown
                      inputId="membershipType"
                      value={formValues.type}
                      optionLabel="label"
                      placeholder={t("profile.selectAnOption")}
                      className="w-full"
                      aria-labelledby="membershipType"
                    />
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="expiry"
                      className="block font-bold text-input-label capitalize"
                    >
                      {t("profile.expiry")}
                    </label>
                    <Calendar
                      appendTo="self"
                      inputId="expiry"
                      value={formValues.expiryDate}
                      dateFormat="mm/dd/yy"
                      placeholder={t("profile.expiry")}
                      onChange={(value) =>
                        setFormValues({ ...formValues, expiryDate: value })
                      }
                      // onSelect={() => focusOnField("expiry")}
                      className="custom-datepicker"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-3 justify-content-end cardFooter">
            <div className="flex align-items-center gap-4">
              <Button
                label={t("profile.cancel")}
                className="button-md"
                severity="secondary"
              />
              <Button label={t("profile.save")} className="button-md" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherMembershipDetails;

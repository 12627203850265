import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import FeatherIcon from "feather-icons-react";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import Table from "../common/Table";
import { MenuItem } from "primereact/menuitem";
import { fetchData } from "../../services/apiService";
import { usePermission } from "../auth/Authorization";
import { sortByStartDate } from "../../utils/utils";

interface RecordList {
  [x: string]: string;
  Committee: any;
  id: string;
  name: string;
  committeeApplied: string;
  recordSubType: string;
  subSubType: string;
  position: string;
  categoryOfInterest: string;
  createdOn: string;
  status: string;
  action: string;
}

const RecordResignation: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const [committees, setRequestsList] = useState([]);
  const [recordList, setRecordList] = useState<RecordList[]>([]);
  const [resignedRecords, setResignedRecords] = useState([]);
  const [search, setSearch] = useState("");
  const [userRole, setUserRole] = useState<string>("");
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();

  const { selectedLanguage } = useLanguageContext();

  const [loading, setLoading] = useState<boolean>(true);
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const handleViewMember = () => {
    return () => {
      if (sessionStorage.getItem("statusName") === "Draft") {
        navigate("/member/create");
      } else {
        navigate("/member/request-view");
      }
    };
  };

  const actionItems: MenuItem[] = [
    {
      label: t("record.viewEditRequest"),
      command: handleViewMember(),
    },
  ];

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  const breadcumbItems = [
    { label: t("member.dashboard"), url: "/dashboard" },
    { label: t("member.membershipRequests") },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDataByRole = async () => {
      try {
        let responseData;
        switch (userRole) {
          case "Admin":
            responseData = await fetchData(`Record/Search?&PageIndex=-1`);
            break;

          default:
            break;
        }

        const modifiedData = responseData.Collection.sort((a: any, b: any) => {
          const dateA: any = new Date(a.CreatedDate as any);
          const dateB: any = new Date(b.CreatedDate as any);
          return dateB - dateA;
        });

        const responseAllData = await fetchData(`Record/Search?&PageIndex=-1`);
        const modifiedAllData = responseAllData.Collection.sort(
          (a: any, b: any) => {
            const dateA: any = new Date(a.CreatedDate as any);
            const dateB: any = new Date(b.CreatedDate as any);
            return dateB - dateA;
          }
        );

        const formattedRecordList = modifiedData.map((record: any) => ({
          ...record,
          //new
          recordId:
            record?.RecordNumber === "null" || !record?.RecordNumber
              ? ""
              : record?.RecordNumber, //new
          name: `${record?.UserProfile?.Firstname} ${record?.UserProfile?.MiddleName} ${record?.UserProfile?.Lastname}`,
          sessionKey: "recordId",
          statusName: record?.RecordStatus.StatusName,
          sessionKeyStatus: "statusName",
        }));
        const filteredRecordList = formattedRecordList.filter(
          (record: { IsResignRecord: boolean }) =>
            record.IsResignRecord === true
        );
        setRecordList(sortByStartDate(filteredRecordList));
        const terminated = modifiedData.filter(
          (record: { RecordStatus: { StatusName: string } }) =>
            record.RecordStatus.StatusName === "Rejected"
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDataByRole();
  }, [userRole]);

  return (
    <>
      <IconField iconPosition="left" className="w-full md:w-16rem mb-4">
        <InputIcon className="pi pi-search"> </InputIcon>
        <InputText
          id="searchCommittee"
          className="w-full"
          placeholder={"Search"}
          aria-label={t("record.searchCommittee")}
          onChange={(e) => setSearch(e.target.value)}
        />
      </IconField>
      <Table
        loading={loading}
        products={recordList?.filter((item: any) => {
          const committeeName = item.name || "";
          const Id = item.recordId || "";
          return (
            search === "" ||
            Id.toLowerCase().includes(search.toLowerCase()) ||
            committeeName.toLowerCase().includes(search.toLowerCase())
          );
        })}
        columns={[
          {
            field: "recordId",
            header: t("record.recordId"),
            link: "/record/record-preview",
          },
          {
            field: "name",
            header: t("record.name"),
          },
          {
            field: "Committee.Name",
            header: t("record.committeeApplied"),
          },
          {
            field: "RecordType.TypeName",
            header: t("record.recordSubType"),
          },
          {
            field: "MembershipPosition.PositionName",
            header: t("record.position"),
          },
          {
            field: "CategoryOfInterest.CategoryName",
            header: t("record.categoryOfInterest"),
          },
          { field: "CreatedDate", header: t("record.createdOn") },
          {
            field: "statusName",
            header: t("record.status"),
          },
          {
            field: "action",
            header: t("record.action"),
            sortable: false,
          },
        ]}
        actionItems={actionItems}
        showCheckbox={true}
      />
    </>
  );
};

export default RecordResignation;

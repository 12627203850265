import { useEffect, useState, useCallback } from 'react';
import { usePermission } from "../components/auth/Authorization";
import { fetchData as getData } from '../services/apiService';

const useUserData = () => {
  const { userPermissions, fetchUserPermissions, hasPermission } = usePermission();
  const [userProfileId, setUserProfileId] = useState<string>('');
  const [userFirstName, setuserFirstName] = useState<string>('');
  const [userRole, setUserRole] = useState('');

  const fetchCurrentUser = useCallback(async () => {
    try {
        const responseData = await getData('UserProfile/GetCurrentUserProfile');
        setUserProfileId(responseData.Id);
        setuserFirstName(responseData.Firstname);
      } catch (error) {
        console.error('Error fetching current user profile:', error);
      }
  }, []); // Memoize the fetch function

  useEffect(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser]); // Dependency array includes memoized fetch function

  useEffect(() => {
    if (userPermissions) {
      setUserRole(hasPermission());
    } else {
      fetchUserPermissions();
    }
  }, [userPermissions, hasPermission]); // Include hasPermission as well

  return { userFirstName, userProfileId, userRole, fetchUserPermissions };
};

export default useUserData;
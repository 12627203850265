import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLanguageContext } from '../../LanguageContext';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InputTextarea } from 'primereact/inputtextarea';
import { CommentPayload } from './type';

interface RemarkPopoverProps {
    isVisible: boolean;
    payloadData: CommentPayload;
    onSubmitHandler: (payload: CommentPayload) => void;
    onHideRemarks: () => void;
    isDisapprove: boolean;
}
interface FormValues {
    remark: string;
}

const RemarkPopover = ({ isVisible, payloadData, onSubmitHandler, onHideRemarks, isDisapprove }: RemarkPopoverProps) => {
    const [t, i18n] = useTranslation("ballot");
    const { selectedLanguage } = useLanguageContext();
    const { register, handleSubmit, reset, formState: { errors } } = useForm<FormValues>();

    const performAction = () => {
        i18n.changeLanguage(selectedLanguage);
    };
    React.useEffect(() => {
        performAction();
    }, [selectedLanguage]);
    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        const userID = localStorage.getItem("userProfileID");
        const updateCommentPayload = { ...payloadData }
        updateCommentPayload.remark = data.remark;
        updateCommentPayload.remarkAddedDate = new Date().toISOString();
        updateCommentPayload.remarkAddedById = userID;
        await onSubmitHandler(updateCommentPayload);
        reset();
        onHideRemarks()
    };
    const footerContentHold = (
        <div className="mt-4 flex justify-content-end">
            <Button
                label={t("ballot.cancel")}
                onClick={() => {
                    onHideRemarks();
                }}
                className="button-md"
                severity="secondary"
            />
            <Button
                label={t("ballot.submit")}
                onClick={handleSubmit(onSubmit)}
                className="button-md"
            />
        </div>
    );
    return (
        <Dialog
            id="visiblePositionHold"
            header={isDisapprove ? t("ballot.addRemarkAndDisapproveComment") : t("ballot.addRemarkAndUnrelatedComment")}
            visible={isVisible}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
            onHide={() => onHideRemarks()}
            footer={footerContentHold}
            className="custom-dialog hide-close"
            ariaCloseIconLabel="Close"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3 id="filter-heading" className="text-lg font-normal m-0 p-3">
                    {t("ballot.pleaseEnterTheRemark")}
                </h3>
                <InputTextarea
                    {...register("remark", { required: t("ballot.remarkRequired") })}
                    rows={5}
                    cols={50}
                    placeholder={t("ballot.enterTheRemarkHere")}
                    autoResize
                />
                {errors.remark && <p className="error">{errors.remark.message}</p>}
            </form>
        </Dialog>
    )
}

export default RemarkPopover
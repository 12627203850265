import React, { useState, useRef } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { InputComponent } from "../common/inputComponent";
import { Checkbox } from "primereact/checkbox";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Input from "../../utils/Input";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";

interface FundingSource {
  name: string;
}

const AddFundingSources = () => {
  const [t, i18n] = useTranslation("profile");
  const [fundingSources, setFundingSources] = useState<FundingSource[]>();
  const [newSourceName, setNewSourceName] = useState<string>("");
  const navigate = useNavigate();
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const handleAddFundingSource = () => {
    // if (newSourceName.trim() !== '') {
    //   const newFundingSource: FundingSource = {
    //     name: newSourceName.trim(),
    //   };
    //   setFundingSources([...fundingSources, newFundingSource]);
    //   setNewSourceName(''); // Clear input after adding
    // }
    // setNewSourceName();
  };

  const [formErrors, setFormErrors] = useState({});

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              onClick={() => navigate("/profile/")}
              aria-label={t("profile.back")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("profile.addfundingdetail")}
              </h1>
              <p className="text-base font-normal m-0 text-help">
                {t("profile.allFieldsRequired")}
              </p>
            </div>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl">
                <div className="lg:col-6 md:col-8 col-12">
                  <Input
                    type="text"
                    label={t("profile.sourceFunding")}
                    value={newSourceName}
                    name="funcingSource"
                    onChange={handleAddFundingSource}
                    placeholder={t("profile.sorceplacehoder")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-4 justify-content-end cardFooter">
            <Button
              label={t("profile.cancel")}
              className="button-md"
              severity="secondary"
            />
            <Button label={t("profile.save")} className="button-md" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFundingSources;

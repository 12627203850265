import { useState, useEffect } from "react";
import { fetchData } from "../../services/apiService";

const useCommitteesData = (committeeId?: string) => {
  const [committeeData, setCommitteeData] = useState<any>(null);

  useEffect(() => {
    const fetchCommitteeData = async () => {
      try {
        if (committeeId) {
          const responseData = await fetchData("Committee", committeeId);
          const managerName =
            responseData?.Staff?.Firstname +
            " " +
            responseData?.Staff?.Lastname;
          setCommitteeData(managerName);
        }
      } catch (error) {
        console.error("Error fetching Committee data:", error);
      }
    };
    fetchCommitteeData();
  }, [committeeId]);

  return { committeeData };
};

export default useCommitteesData;

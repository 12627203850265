import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../LanguageContext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import FeatherIcon from "../../common/FeatherIconComponent";
import Table from "../../common/Table";
import PreviewItem from "../../common/PreviewItem";
import pdfIcon from "../../../assets/icons/pdf.svg";
import { Link } from "react-router-dom";

const Preview: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.basicDetails")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("record.onBehalfOf")}
                value="Value"
              />
              <PreviewItem
                label={t("record.memberCommittee")}
                value="Value"
              />
              <PreviewItem
                label={t("record.memberId")}
                value="Value"
              />
              <PreviewItem
                label={t("record.publishingYear")}
                value="Value"
              />
              <PreviewItem
                label={`${t("record.accredited")}?`}
                value="Value"
              />
              <PreviewItem
                label={t("record.applicableAccreditationBody")}
                value="Value"
              />
              <PreviewItem
                label={t("record.canDevelopedByExistingCommittee")}
                value="Value"
              />
              <PreviewItem
                label={t("record.titleProposedStandard")}
                value="Value"
              />
              <PreviewItem
                label={t("record.productAbstract")}
                value="Value"
              />
              <PreviewItem
                label={t("record.problemStatement")}
                value="Value"
              />
              <PreviewItem
                label={t("record.industryNeeds")}
                value="Value"
              />
              <PreviewItem
                label={t("record.proposedBenefitsProduct")}
                value="Value"
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.relevance")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("record.standardDevelopedOtherOrganisation")}
                value="Value"
              />
              <PreviewItem
                label={`${t("record.standardPatentedItems")}?`}
                value="Value"
              />
              <PreviewItem
                label={t("record.potentiallyConflictingStandard")}
                value="Value"
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.stakeholders")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("record.affectedStakeholder")}
                value="Value"
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.workingCommittee")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("record.committeeName")}
                value="Value"
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.documents")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <div className="flex flex-column gap-3">
                <h3 className="m-0 font-bold text-lg">{t("record.resume")}</h3>
                <p className="m-0 flex align-items-center gap-2">
                  <img src={pdfIcon} alt="PDF" />
                  <span className="text-base font-normal">
                    {"Random document B"}
                  </span>
                </p>
              </div>
              <div className="flex flex-column gap-3">
                <h3 className="m-0 font-bold text-lg">
                  {t("record.otherDocument")}
                </h3>
                <p className="m-0 flex align-items-center gap-2">
                  <img src={pdfIcon} alt="PDF" />
                  <span className="text-base font-normal">
                    {"Random document B"}
                  </span>
                </p>
                <p className="m-0 flex align-items-center gap-2">
                  <img src={pdfIcon} alt="PDF" />
                  <span className="text-base font-normal">
                    {"Random document B"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.staffNotes")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("record.note")}
                value="Value"
              />
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                  {t("record.attachments")}
                </span>
                <p className="m-0 font-normal text-base text-title w-full flex align-items-center gap-2">
                  <img src={pdfIcon} alt="PDF" />
                  <span className="text-base font-normal">{"Random document B"}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.mandatorytandardCommitteeReview")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "committeeName", header: t("record.committeeName") },
                { field: "primaryStaff", header: t("record.primaryStaff") },
                { field: "committeeLevel", header: t("record.committeeLevel") },
              ]}
            />
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.reviewAndCommentCommittee")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "committeeName", header: t("record.committeeName") },
                { field: "committeeLevel", header: t("record.committeeLevel") },
              ]}
            />
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.selectProjectTeam")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "memberName", header: t("record.memberName") },
                { field: "committeeName", header: t("record.committeeName") },
                { field: "position", header: t("record.position") },
                { field: "endDate", header: t("record.endDate") },
                { field: "status", header: t("record.status") },
                { field: "action", header: t("record.action") },
              ]}
            // actionItems={actionItems}
            />
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.teamNotes")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("record.note")}
                value="Value"
              />
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                  {t("record.attachments")}
                </span>
                <p className="m-0 font-normal text-base text-title w-full flex align-items-center gap-2">
                  <img src={pdfIcon} alt="PDF" />
                  <span className="text-base font-normal">{"Random document B"}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.selectLinkedRelatedRecords")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "recordId", header: t("record.recordId") },
                { field: "recordSubType", header: t("record.recordSubType") },
                { field: "status", header: t("record.status") },
                { field: "subject", header: t("record.subject") },
              ]}
            />
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.selectLockedRelatedRecords")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("record.edit")}</span>
            </Button>
          </div>

          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "recordId", header: t("record.recordId") },
                { field: "recordSubType", header: t("record.recordSubType") },
                { field: "subject", header: t("record.subject") },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Preview;

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { setCookie, getCookie, deleteCookie } from './cookieUtils';

const CookieBanner: React.FC = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const cookiesAccepted = getCookie('cookiesAccepted');
    if (!cookiesAccepted) {
      setVisible(true);
    }
  }, []);

  const acceptAllCookies = () => {
    setCookie('cookiesAccepted', 'true', 365);
    setVisible(false);
  };

  const rejectAllCookies = () => {
    setCookie('cookiesAccepted', 'false', 365);
    setVisible(false);
    deleteNonEssentialCookies();
  };

  const deleteNonEssentialCookies = () => {
    deleteCookie('analyticsCookie');
    deleteCookie('marketingCookie');
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="flex flex-column md:flex-row align-items-center gap-3 p-4 fixed w-full bottom-0 shadow-md z-5 text-white cookie-banner">
      <div className="text-left">
        <p className="font-bold display-xs m-0 mb-3">We value your privacy</p>
        {/* <p>We use cookies to ensure you get the best experience on our website.</p> */}
        <p className="font-normal m-0 .line-height-3">We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.</p>
      </div>
      <div className="flex flex-row md:flex-column flex-shrink-0 gap-3">
        <Button label="Accept All" icon="pi pi-check" onClick={acceptAllCookies} />
        <Button label="Reject All" icon="pi pi-times" severity="secondary" onClick={rejectAllCookies} />
      </div>
    </div>
  );
};

export default CookieBanner;

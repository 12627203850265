import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { FC, useEffect, useState } from "react";
import ForgotPassword from "./components/auth/ForgotPassword";
import AddBasicDetails from "./components/profile/AddBasicDetails";
import EditBasicDetails from "./components/profile/EditBasicDetails";
import UserProfile from "./components/profile/UserProfile";
import AddAddress from "./components/profile/AddAddress";
import AddExperience from "./components/profile/AddExperience";
import EditAddress from "./components/profile/EditAddress";
import EditExperience from "./components/profile/EditExperience";
import AddFundingSources from "./components/profile/AddFundingSources";
import AddProfessionalLicenses from "./components/profile/AddProfessionalLicenses";
import AddDocuments from "./components/profile/AddDocument";
import AddEducation from "./components/profile/AddEducation";
import EmptyState from "./components/profile/EmptyState";
import MemberRequestCommitteeSelection from "./components/member/MemberRequestCommitteeSelection";
import MemberRequestSubCommitteeStandardSelection from "./components/member/MemberRequestSubCommitteeStandardSelection";
import CreateCommittee from "./components/committee/CreateCommittee";
import CommitteeConfiguration from "./components/committee/CommitteeConfiguration";
import EditProfessionalLicenses from "./components/profile/EditProfessionalLicensed";
import AddMembershipDetails from "./components/profile/AddMembershipDetails";
import AllRequests from "./components/member/AllRequests";
import OtherMembershipDetails from "./components/profile/OtherMembershipDetails";
import CommitteeListing from "./components/committee/CommitteeListing";
import CommitteeMembers from "./components/committee/CommitteeMembers";
import CommitteePreview from "./components/committee/CommitteePreview";
import CommitteeTabs from "./components/committee/CommitteeTabs";
import DashboardPage from "./components/dashboard/DashboardPage";
import CommitteeAddMeeting from "./components/committee/CommitteeAddMeeting";
import CommitteeMeetingTabs from "./components/committee/CommitteeMeetingTabs";
import CommitteeSelection from "./components/member/CommitteeSelection";
import MembershipDetails from "./components/member/MembershipDetails";
import CommitteeDetails from "./components/member/CommitteeDetails";
import RequestPreview from "./components/member/RequestPreview";
import ViewRequest from "./components/member/ViewRequest";
import ErrorPage from "./components/error/ErrorPage";
import ErrorPage404 from "./components/error/ErrorPage404";
import EditEducation from "./components/profile/EditEducation";
import Volunteers from "./components/MemberList/Volunteers";
import TestPage from "./components/error/TestPage";
import RecordListing from "./components/record/RecordListing";
import CreateRecordApplicant from "./components/record/CreateRecordApplicant";
import CreateRecordCommitteePosition from "./components/record/CreateRecordCommitteePosition";
import CreateRecord from "./components/record/CreateRecord";
import RecordCommitteeSelection from "./components/record/RecordCommitteeSeletion";
import RecordDetails from "./components/record/RecordDetails";
import CreateRecordPreview from "./components/record/CreateRecordPreview";
import RecordPreview from "./components/record/RecordPreview";
import Preview from "./components/record/Preview";
import AllMembership from "./components/membership/AllMembership";
import VolunteerSelection from "./components/record/VolunteerSelection";
import MasterData from "./components/configuration/MasterData";
import PrimaryLayout from "./components/common/PrimaryLayout";
import ApproveMembership from "./components/record/ApproveMembership";
import Configuration from "./components/configuration/Configuration";
import RecordCommitteeDetails from "./components/record/RecordCommitteeDetails";
import RecordSubCommittee from "./components/record/RecordSubCommittee";
import { fetchData, getListByParentId } from "./services/apiService";
import withLoader from "./components/common/LoaderIntercepter";
import { usePermission } from "./components/auth/Authorization";
import MemberProfile from "./components/profile/MemberProfile";
import BallotListing from "./components/ballot/listing/BallotListing";
import CreateSelectRecords from "./components/ballot/common/SelectRecords";
import CreateBallotDetails from "./components/ballot/common/SelectBallotDetails";
import CreateSelectCommittee from "./components/ballot/common/SelectCommittee";
import CreatePreview from "./components/ballot/common/Preview";
import RequestReport from "./components/reports/RequestReport";
import RequestDetails from "./components/member/RequestDetails";
import BallotTabs from "./components/ballot/BallotTabs";
import RecordViewDetails from "./components/record/RecordViewDetails";
import PandingReappointment from "./components/reports/PandingReappointment";
import MembershipExpiration from "./components/reports/MembershipExpiration";
import CommitteeReport from "./components/reports/CommitteeReport";
import RecordStatus from "./components/reports/RecordStatus";
import BalanceOfInterest from "./components/reports/BalanceOfInterest";
import SignIn from "./components/auth/SignIn";
import SignInOidc from "./components/auth/SignInOidc";
import SignUp from "./components/auth/SignUp";
import EmailSent from "./components/auth/EmailSent";
import ResetPasswordEmailSent from "./components/auth/ResetPasswordEmailSent";
import ResetPassword from "./components/auth/ResetPassword";
import EmailVerified from "./components/auth/EmailVerified";
import OpenPositionsListing from "./components/position/OpenPositionsListing";
import AddOpenPosition from "./components/position/AddOpenPosition";
import CreateNewBallot from "./components/ballot/create/CreateNewBallot";
import EditBallot from "./components/ballot/edit/EditBallot";
import EditOpenPosition from "./components/position/EditOpenPosition";
import PowerBIReportIframe from "./components/reports/PowerBIReportIframe";
import CloseBallot from "./components/ballot/CloseBallot";
import PositionCount from "./components/position/PositionCount";
import StandardBallots from "./components/ballot/listing/StandardBallots";
import NonStandardDocuments from "./components/ballot/listing/NonStandardDocuments";
import Administrative from "./components/ballot/listing/Administrative";
import Membership from "./components/ballot/listing/Membership";
import CommitteeMeetingAddAction from "./components/committee/CommitteeMeetingAddAction";
import StandardRequests from "./components/request/StandardRequests";
import NewStandard from "./components/request/NewStandard";
import TechnicalRevision from "./components/request/TechnicalRevision";
import EditorialRevision from "./components/request/EditorialRevision";
import ErrataRevision from "./components/request/ErrataRevision";
import WithdrawalStandard from "./components/request/WithdrawalStandard";
import ReaffirmStandard from "./components/request/ReaffirmStandard";
import StabilisedStandard from "./components/request/StabilisedStandard";
import CreateStandardRecord from "./components/record/standard/CreateStandardRecord";
import StandardRecordDetailTabs from "./components/record/standard/StandardRecordDetailTabs";
import StandardRecordListing from "./components/record/standard/StandardRecordListing";
import AdministrativeRecord from "./components/record/AdministrativeRecord";
import AdministrativeRequest from "./components/request/AdministrativeRequest";
import RenameStandard from "./components/request/RenameStandard";
import MergeStandard from "./components/request/MergeStandard";
import SplitStandard from "./components/request/SplitStandard";
import StandardManagementListing from "./components/standard/StandardManagementListing";
import CreateNewStandard from "./components/standard/CreateNewStandard";
import StandardDetailTabs from "./components/standard/StandardDetailTabs";
import RemarkBallot from "./components/ballot/RemarkBallot";
import BallotConfigurationTab from "./components/configuration/BallotConfigurationTab";

export const ProtectedRoute: FC<any> = () => {
  const [addressDetails, setAddressDetails] = useState<[]>([]);
  const [userProfileID, setUserProfileId] = useState<string>(
    localStorage.getItem("userProfileID") ?? ""
  );

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  // const { userPermissions, fetchUserPermissions, hasPermission } =
  //   usePermission();
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchUserPermissions = async () => {
      try {
        const data = await fetchData("UserProfile/GetCurrentUserProfile");
        setUserRole(data.UserProfileUserType[0].UserType.TypeName);
      } catch (error) {
        console.error("Error fetching user permissions:", error);
      }
    };
    fetchUserPermissions();
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      if (
        location.pathname !== "/" &&
        location.pathname !== "/signin-oidc" &&
        location.pathname !== "/sign-up" &&
        location.pathname !== "/email-sent" &&
        location.pathname !== "/email-verified" &&
        location.pathname !== "/forgot-password" &&
        location.pathname !== "/reset-password-email-sent"
      ) {
        sessionStorage.setItem("fromPath", location.pathname);
      }
      switch (location.pathname) {
        case "/email-sent":
          navigate("/email-sent", { state: location.state });
          break;
        case "/sign-up":
          navigate("/sign-up");
          break;
        case "/email-verified":
          navigate(`/email-verified${location.search}`);
          break;
        case "/resetpassword":
          navigate(`/resetpassword${location.search}`);
          break;
        case "/forgot-password":
          navigate(`/forgot-password`);
          break;
        case "/reset-password-email-sent":
          navigate(`/reset-password-email-sent`, { state: location.state });
          break;
        case "/signin-oidc":
          navigate(`/signin-oidc${location.search}`);
          break;
        default:
          navigate("/");
          break;
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchAddressDetails = async () => {
      try {
        const response = await getListByParentId("UserAddress", userProfileID);
        setAddressDetails(response.Collection);
      } catch (error) {
        console.error("Error fetching address details:", error);
      }
    };
    fetchAddressDetails();
  }, [userProfileID]);

  const [userRole, setUserRole] = useState<string>("");

  const CreateCommitteeWithLoader = withLoader(CreateCommittee);
  const CreateRequestWithLoader = withLoader(MembershipDetails);
  const CreateRequestPreviewWithLoader = withLoader(RequestPreview);
  const RequestLisitingWithLoader = withLoader(AllRequests);
  const CreateRequestCommitteeSelectionWithLoader =
    withLoader(CommitteeSelection);
  const CreateRecordDetailsWithLoader = withLoader(RecordDetails);
  const CreateRecordPreviewWithLoader = withLoader(RecordPreview);
  const CreatePreviewWithLoader = withLoader(Preview);
  const AllMembershipWithLoader = withLoader(AllMembership);
  const RequestReportWithLoader = withLoader(RequestReport);
  const RecordStatusReportWithLoader = withLoader(RecordStatus);
  const MemberShipExpirationReportsWithLoader =
    withLoader(MembershipExpiration);
  const PendingRepointmentsReportWithLoader = withLoader(PandingReappointment);
  const CommitteeReportWithLoader = withLoader(CommitteeReport);
  const DashboardPageWithLoader = withLoader(DashboardPage);

  // Use useLocation hook to update page title on route change
  const location = useLocation();

  // Page Title
  const TITLE_SUFFIX = " - Stanza by InfoBeans";
  const titleMap = {
    member: {
      "/dashboard": "Dashboard",
      "/profile": "Profile",
      "/all-membership": "My Memberships",
      "/member": "My Requests",
      "/request": "My Requests",
      "/volunteers": "Volunteers",
      "/committee": "My Committees",
      "/standard": "Standards",
      "/positions": "Positions",
      "/record": "Records",
      "/report": "Reports",
      "/ballot": "My Ballots",
      "/configuration": "Configuration",
    },
    default: {
      "/dashboard": "Dashboard",
      "/profile": "Profile",
      "/all-membership": "Memberships",
      "/member": "Requests",
      "/request": "Requests",
      "/volunteers": "Volunteers",
      "/committee": "Committees",
      "/standard": "Standards",
      "/positions": "Positions",
      "/record": "Records",
      "/report": "Reports",
      "/ballot": "Ballots",
      "/configuration": "Configuration",
    },
  };

  const getTitleForPath = (pathname: string, userRolecheck: string) => {
    const role = userRolecheck === "Member" ? "member" : "default";
    const selectedTitleMap = titleMap[role];

    for (const [key, title] of Object.entries(selectedTitleMap)) {
      if (pathname === key || pathname.startsWith(key + "/")) {
        return title + TITLE_SUFFIX;
      }
    }
    return "Stanza by InfoBeans"; // Default title
  };

  useEffect(() => {
    if (userRole) {
      document.title = getTitleForPath(location.pathname, userRole);
    }
  }, [location.pathname, userRole]);

  {
    return isAuthenticated ? (
      <>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signin-oidc" element={<SignInOidc />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/404" element={<div>No page found</div>} />

          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="profile" element={<PrimaryLayout />}>
            <Route path="" element={<UserProfile />} />
            <Route path="add-details" element={<AddBasicDetails />} />
            <Route path="edit-details" element={<EditBasicDetails />} />
            <Route path="add-address" element={<AddAddress />} />
            <Route path="member-profile" element={<MemberProfile />} />

            {/* <Route path="edit-address" element={<EditAddress />} />
            <Route path="edit-address/:id" element={<EditAddress />} /> */}
            <Route
              path="edit-address"
              element={<EditAddress addressData={addressDetails} />}
            />
            <Route path="add-experience" element={<AddExperience />} />

            <Route path="edit-experience" element={<EditExperience />} />
            <Route path="add-funding" element={<AddFundingSources />} />
            <Route path="add-funding/:id" element={<AddFundingSources />} />

            <Route path="add-licenses" element={<AddProfessionalLicenses />} />
            <Route
              path="edit-licenses"
              element={<EditProfessionalLicenses />}
            />
            <Route path="add-document" element={<AddDocuments />} />
            <Route path="add-document/:id" element={<AddDocuments />} />

            <Route path="add-education" element={<AddEducation />} />

            <Route path="edit-education" element={<EditEducation />} />
            <Route path="add-membership" element={<AddMembershipDetails />} />
            <Route
              path="add-membership/:id"
              element={<AddMembershipDetails />}
            />
            <Route path="other" element={<OtherMembershipDetails />} />
            <Route path="*" element={<EmptyState />} />
          </Route>
          <Route path="all-membership" element={<PrimaryLayout />}>
            <Route path="" element={<AllMembershipWithLoader />} />
            <Route path=":id" element={<AllMembershipWithLoader />} />
          </Route>

          {/* Request Membership Menu */}
          <Route path="member" element={<PrimaryLayout />}>
            <Route path="" element={<RequestLisitingWithLoader />} />
            <Route path=":id" element={<RequestLisitingWithLoader />} />
            <Route
              path="create"
              element={<CreateRequestCommitteeSelectionWithLoader />}
            />
            <Route path="committee" element={<CommitteeDetails />} />
            <Route
              path="sub-committee"
              element={<MemberRequestSubCommitteeStandardSelection />}
            />
            <Route path="details" element={<CreateRequestWithLoader />} />
            <Route
              path="preview"
              element={<CreateRequestPreviewWithLoader />}
            />
            <Route
              path="direct-committee"
              element={<MemberRequestCommitteeSelection />}
            />
            {/* <Route path="requests" element={<AllRequests />} /> */}
            <Route path="request-view" element={<ViewRequest />} />
            <Route path="request-details/:id" element={<RequestDetails />} />
          </Route>
          {/* Request Menu */}
          <Route path="request" element={<PrimaryLayout />}>
            <Route path="standard" element={<StandardRequests />} />
            <Route path="standard/new" element={<NewStandard />} />
            <Route
              path="standard/technical-revision"
              element={<TechnicalRevision />}
            />
            <Route
              path="standard/editorial-revision"
              element={<EditorialRevision />}
            />
            <Route
              path="standard/errata-revision"
              element={<ErrataRevision />}
            />
            <Route
              path="standard/withdrawal"
              element={<WithdrawalStandard />}
            />
            <Route path="standard/reaffirm" element={<ReaffirmStandard />} />
            <Route
              path="standard/stabilised"
              element={<StabilisedStandard />}
            />
            <Route path="standard/split" element={<SplitStandard />} />
            <Route path="standard/merge" element={<MergeStandard />} />
            <Route path="standard/rename" element={<RenameStandard />} />

            <Route path="administrative" element={<AdministrativeRequest />} />
          </Route>

          <Route path="volunteers" element={<PrimaryLayout />}>
            <Route
              path=""
              element={
                userRole === "Member" ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <Volunteers />
                )
              }
            />
          </Route>
          <Route path="committee" element={<PrimaryLayout />}>
            <Route path="" element={<CommitteeListing />} />
            <Route
              path="create"
              element={
                userRole === "Member" ? (
                  <Navigate to="/committee" replace />
                ) : (
                  <CreateCommitteeWithLoader />
                )
              }
            />

            <Route
              path="configuration"
              element={
                userRole === "Member" ? (
                  <Navigate to="/committee" replace />
                ) : (
                  <CommitteeConfiguration />
                )
              }
            />

            <Route
              path="members"
              element={
                userRole === "Member" ? (
                  <Navigate to="/committee" replace />
                ) : (
                  <CommitteeMembers />
                )
              }
            />
            <Route path="preview" element={<CommitteePreview />} />

            <Route path="details/:id" element={<CommitteeTabs />} />

            <Route path="add-meeting" element={<CommitteeAddMeeting />} />
            <Route path="meeting" element={<CommitteeMeetingTabs />} />
            <Route path="add-action" element={<CommitteeMeetingAddAction />} />
          </Route>

          <Route path="positions" element={<PrimaryLayout />}>
            <Route path="" element={<OpenPositionsListing />} />
            <Route path="add-position" element={<AddOpenPosition />} />
            <Route path="edit-position" element={<EditOpenPosition />} />
            <Route path="count" element={<PositionCount />} />
          </Route>

          <Route path="record" element={<PrimaryLayout />}>
            <Route
              path=""
              element={
                userRole === "Member" ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <RecordListing />
                )
              }
            />
            <Route
              path="volunteer"
              element={
                userRole === "Member" ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <VolunteerSelection />
                )
              }
            />
            <Route
              path="create"
              element={
                userRole === "Member" ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <RecordCommitteeSelection />
                )
              }
            />
            <Route
              path="committee-details"
              element={
                userRole === "Member" ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <RecordCommitteeDetails />
                )
              }
            />
            <Route
              path="sub-committee"
              element={
                userRole === "Member" ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <RecordSubCommittee />
                )
              }
            />
            <Route
              path="details"
              element={
                userRole === "Member" ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <CreateRecordDetailsWithLoader />
                )
              }
            />
            <Route
              path="preview"
              element={
                userRole === "Member" ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <CreateRecordPreviewWithLoader />
                )
              }
            />
            <Route
              path="record-preview"
              // element={
              //   userRole === "Member" ? (
              //     <Navigate to="/dashboard" replace />
              //   ) : (
              //     <Preview />
              //   )
              // }
              element={<CreatePreviewWithLoader />}
            />
            <Route path="record-view/:id" element={<RecordViewDetails />} />
            <Route
              path="view"
              element={
                userRole === "Member" ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <CreateRecordPreview />
                )
              }
            />
            <Route
              path="approve-membership"
              element={
                userRole === "Member" ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <ApproveMembership />
                )
              }
            />
            <Route path="standard" element={<StandardRecordListing />} />
            <Route path="standard/create" element={<CreateStandardRecord />} />
            <Route
              path="standard/details"
              element={<StandardRecordDetailTabs />}
            />
            <Route path="administrative" element={<AdministrativeRecord />} />
          </Route>
          <Route path="report" element={<PrimaryLayout />}>
            <Route path="bi-report" element={<PowerBIReportIframe />} />
            <Route
              path="request-report"
              element={<RequestReportWithLoader />}
            />
            <Route
              path="pending-reappointments"
              element={<PendingRepointmentsReportWithLoader />}
            />
            <Route
              path="member-expiration"
              element={<MemberShipExpirationReportsWithLoader />}
            />
            <Route
              path="committee-report"
              element={<CommitteeReportWithLoader />}
            />
            <Route
              path="record-status"
              element={<RecordStatusReportWithLoader />}
            />
            <Route path="balance-interest" element={<BalanceOfInterest />} />
          </Route>
          <Route path="standard" element={<PrimaryLayout />}>
            <Route path="" element={<StandardManagementListing />} />
            <Route path="create" element={<CreateNewStandard />} />
            <Route path="details" element={<StandardDetailTabs />} />
          </Route>
          <Route path="dashboard" element={<PrimaryLayout />}>
            <Route path="" element={<DashboardPageWithLoader />} />
          </Route>
          <Route path="configuration" element={<PrimaryLayout />}>
            <Route
              path=""
              element={
                userRole === "Member" ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <Configuration />
                )
              }
            />
            <Route
              path=""
              element={
                userRole === "Member" ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <BallotConfigurationTab />
                )
              }
            />
          </Route>
          <Route path="ballot" element={<PrimaryLayout />}>
            {/* <Route path="" element={<BallotListing />} /> */}
            <Route path="standard" element={<StandardBallots />} />
            <Route
              path="non-standard-documents"
              element={<NonStandardDocuments />}
            />
            <Route path="administrative" element={<Administrative />} />
            <Route path="membership" element={<Membership />} />
            <Route path="create" element={<CreateNewBallot />} />
            <Route path=":id" element={<BallotTabs />} />
            <Route path="edit/:id" element={<EditBallot />} />
            {/* <Route path="ballot-details" element={<CreateBallotDetails />} />
            <Route path="committee" element={<CreateSelectCommittee />} />
            <Route path="preview" element={<CreatePreview />} />
            <Route path="details" element={<BallotTabs />} /> */}
            <Route path="close" element={<CloseBallot />} />
            <Route path="remarks" element={<RemarkBallot />} />
          </Route>

          <Route path="errorpage" element={<PrimaryLayout />}>
            <Route path="" element={<ErrorPage />} />
          </Route>

          <Route path="errorpage404" element={<PrimaryLayout />}>
            <Route path="" element={<ErrorPage404 />} />
          </Route>

          <Route path="*" element={<PrimaryLayout />}>
            <Route path="test" element={<TestPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
          {/* <Route path='' element={<ELayout/>}> */}
        </Routes>
      </>
    ) : (
      <Routes>
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/email-sent" element={<EmailSent />} />
        <Route path="/email-verified/" element={<EmailVerified />} />
        <Route path="/resetpassword/" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/reset-password-email-sent"
          element={<ResetPasswordEmailSent />}
        />
      </Routes>
    );
  }
};

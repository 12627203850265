import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";

const AccessibilityOptions = () => {
  // Retrieve font size from local storage or default to "font-normal"
  const [fontSize, setFontSize] = useState(() => {
    return localStorage.getItem("fontSize") || "font-normal";
  });

  const fontSizes = [
    { name: "aA", value: "font-normal", class: "text-base" },
    { name: "aA", value: "font-medium", class: "display-xs" },
    { name: "aA", value: "font-large", class: "display-sm" },
  ];

  const handleFontSizeChange = (newFontSize: React.SetStateAction<string>) => {
    setFontSize(newFontSize);
  };

  useEffect(() => {
    // Update local storage with the new font size
    localStorage.setItem("fontSize", fontSize);

    // Remove old font size classes dynamically
    fontSizes.forEach(size => document.body.classList.remove(size.value));

    // Add new font size class to body element
    document.body.classList.add(fontSize);
  }, [fontSize]);

  return (
    <div className="flex gap-2 align-items-end">
      {fontSizes.map((size) => (
        <Button
          text
          key={size.value}
          className={`text-body font-normal p-2 ${size.class} ${size.value === fontSize ? "text-link underline" : ""
            }`}
          onClick={() => handleFontSizeChange(size.value)}
        >
          {size.name}
        </Button>
      ))}
    </div>
  );
};

export default AccessibilityOptions;

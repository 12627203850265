import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { Link, useNavigate } from "react-router-dom";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import {
  formatMembershipResponse,
  formatMembershipHistoryResponse,
  formatStaffNoteResponse,
} from "../../utils/utils";
import { useLoadingContext } from "../Layout";
import {
  postData,
  fetchData,
  getAllCountries,
  getListByParentId,
  putData,
} from "../../services/apiService";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { Toast } from "primereact/toast";
import { usePermission } from "../auth/Authorization";
interface OutletWrapperProps {
  selectedLanguage: string;
}

interface MemberList {
  id: string;
  Committee: string;
  MembershipPosition: string;
  CategoryOfInterest: string;
  NumberofBallotsMissed: string;
  NumberofMeetingMissed: string;
  StartDate: string;
  EndDate: string;
  Status: string;
}

interface StaffNotesList {
  id: string;
  StaffName: string;
  UserProfileStaffNotes: string;
  Date: string;
  CommitteeName: string;
}

const MemberProfile = () => {
  const { setLoading } = useLoadingContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("profile");
  const [userProfileID, setUserProfileId] = useState<string>("");
  const { selectedLanguage } = useLanguageContext();
  const [fullName, setFullName] = useState<string>("");
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  
  const breadcumbItems = [
    { label: t("profile.dashboard"), url: "/dashboard" },
    { label: t("profile.profileText") },
  ];
  const [basicDetails, setBasicDetails] = useState<any>(null);
  const [contactDetails, setContactDetails] = useState<any>(null);
  const [addressDetails, setAddressDetails] = useState<any>(null);
  const [experienceDetails, setExperienceDetails] = useState<any>(null);
  const [professionalLicenseDetails, setProfessionalLicenseDetails] =
    useState<any>(null);
  const [educationDetails, setEducationDetails] = useState<any>(null);
  const [profileLoading, setProfileLoading] = useState<boolean>(true);
  const createProfile = useSelector((state: RootState) => state.createProfile);
  const [memberHistory, setMemberHistory] = useState<MemberList[]>([]);
  const [activeMember, setActiveMember] = useState<MemberList[]>([]);
  const [visibleResign, setVisibleResign] = useState<boolean>(false);
  const [visibleEndMembership, setVisibleEndMembership] =
    useState<boolean>(false);
  const [visibleTerminate, setVisibleTerminate] = useState<boolean>(false);
  const [memberShipId, setMemberShipId] = useState("");
  const [memberRemarks, setMemberRemarks] = useState<StaffNotesList[]>([]);
  const [committees, setCommittees] = useState<any>([]);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  const fetchCommittees = async () => {
    try {
      const response = await fetchData("Committee/GetAll");
      const committeesData = response.Collection;
      setCommittees(committeesData);
    } catch (error) {
      console.error("Error fetching committees:", error);
    }
  };

  const fetchCountryByID = async (id: string) => {
    try {
      const responseData = fetchData("Country", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const fetchStateByID = async (id: string) => {
    try {
      const responseData = fetchData("State", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const fetchUniversityByID = async (id: string) => {
    try {
      const responseData = fetchData("University", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const fetchDegreeByID = async (id: string) => {
    try {
      const responseData = fetchData("Degree", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const getUserProfileIdByMembership = async () => {
    const committeeMembershipId = sessionStorage.getItem(
      "committeeMembershipId"
    );
    const membershipData = await fetchData(
      `CommitteeMembership/${committeeMembershipId}`
    );
    const userProfileId = membershipData.UserProfileId;
    if (userProfileId) {
      setUserProfileId(userProfileId);
    }
  };

  useEffect(() => {
    getUserProfileIdByMembership();
  }, [createProfile]);

  const fetchStaffNotes = async () => {
    if (userProfileID) {
      try {
        const postResponseData = await fetchData(
          `UserProfileStaffNote/GetUserProfileStaffNoteByUserProfileID?UserProfileId=${userProfileID}`
        );
        const HistoryData = formatStaffNoteResponse(
          postResponseData.collection
        );

        setMemberRemarks(HistoryData);
        return postResponseData;
      } catch (error) {
        console.error("Error while fetching membership history", error);
      }
    }
  };

  useEffect(() => {
    fetchStaffNotes();
  }, []);

  useEffect(() => {
    const fetchDataAsync = async () => {
      if (userProfileID) {
        try {
          const [
            userProfile,
            phoneData,
            experienceData,
            addressData,
            licenseData,
            educationData,
          ] = await Promise.all([
            fetchData("UserProfile", userProfileID),
            getListByParentId("UserPhone", userProfileID),
            getListByParentId("UserExperience", userProfileID),
            getListByParentId("UserAddress", userProfileID),
            getListByParentId("UserProfessionalLicense", userProfileID),
            getListByParentId("UserEducationDetail", userProfileID),
            fetchCommittees(),
          ]);
          setBasicDetails(userProfile);
          setFullName(userProfile.Firstname + " " + userProfile.Lastname);
          setContactDetails(phoneData?.Collection);
          setExperienceDetails(experienceData?.Collection);
          setAddressDetails(addressData?.Collection);
          setProfessionalLicenseDetails(licenseData?.Collection);
          const updatedLicenseDetails = await Promise.all(
            licenseData?.Collection.map(async (license: any) => {
              try {
                const countryData = await fetchCountryByID(license.CountryId);
                const stateData = await fetchStateByID(license.StateId);
                return {
                  ...license,
                  countryName: countryData?.Name,
                  stateName: stateData?.Name,
                };
              } catch (error) {
                console.error("Error fetching education details:", error);
                return license;
              }
            })
          );
          setProfessionalLicenseDetails(updatedLicenseDetails);
          const updatedAddressDetails = await Promise.all(
            addressData?.Collection.map(async (address: any) => {
              try {
                const countryData = await fetchCountryByID(address.CountryId);
                const stateData = await fetchStateByID(address.StateId);
                return {
                  ...address,
                  countryName: countryData?.Name,
                  stateName: stateData?.Name,
                };
              } catch (error) {
                console.error("Error fetching education details:", error);
                return address;
              }
            })
          );
          setAddressDetails(updatedAddressDetails);
          const updatedEducationDetails = await Promise.all(
            educationData?.Collection.map(async (education: any) => {
              try {
                const countryData = await fetchCountryByID(education.CountryId);
                const stateData = await fetchStateByID(education.StateId);
                const universityData = await fetchUniversityByID(
                  education.UniversityId
                );
                const degreeData = await fetchDegreeByID(education.DegreeId);
                return {
                  ...education,
                  countryName: countryData?.Name,
                  stateName: stateData?.Name,
                  universityName: universityData?.Name,
                  degreeName: degreeData?.Name,
                };
              } catch (error) {
                console.error("Error fetching education details:", error);
                return education;
              }
            })
          );
          setEducationDetails(updatedEducationDetails);
          setProfileLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchDataAsync();
    fetchMembershipHistory();
    fetchActiveMembership();
    fetchStaffNotes(); //newremarks
  }, [userProfileID]);

  useEffect(() => {
    fetchStaffNotes();
  }, []);

  const fetchMembershipHistory = async () => {
    if (userProfileID) {
      try {
        const postResponseData = await fetchData(
          `CommitteeMembership/GetCommitteeMembershipHistoryByUserProfileID?UserProfileId=${userProfileID}`
        );
        const HistoryData = formatMembershipHistoryResponse(
          postResponseData.Collection
        );
        setMemberHistory(HistoryData);
        return postResponseData;
      } catch (error) {
        console.error("Error while fetching membership history", error);
      }
    }
  };

  const fetchActiveMembership = async () => {
    if (userProfileID) {
      try {
        const postResponseData = await fetchData(
          `CommitteeMembership/GetCommitteeMembershipByUserProfileID?UserProfileId=${userProfileID}`
        );
        const MembershipData = formatMembershipResponse(
          postResponseData.Collection
        );

        setActiveMember(MembershipData);
        return postResponseData;
      } catch (error) {
        console.error("Error while fetching membership history", error);
      }
    }
  };

  return (
    <>
      <div className="">
        <Toast ref={toast} />
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">{fullName}</h1>
          <p className="text-sm font-normal m-0">
            {" "}
            {t("profile.subTextProfile")}{" "}
          </p>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
            <h2 className="text-title text-lg font-bold m-0">
              {" "}
              {t("profile.basicDetails")}{" "}
            </h2>
            <div className="flex text-sm">
              <div>
                {profileLoading ? (
                  <div className="flex flex-column gap-3">
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="4"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div>
            {profileLoading ? (
              <div className="flex flex-column gap-3">
                <ProgressSpinner
                  style={{ width: "50px", height: "50px" }}
                  strokeWidth="4"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                />
              </div>
            ) : basicDetails ? (
              <div className="p-5 cardBody">
                <div className="flex flex-column gap-1">
                  <h3 className="font-bold text-base text-title m-0">
                    {basicDetails.Prefix} <span>.</span>{" "}
                    {basicDetails.Firstname} {basicDetails.MiddleName}{" "}
                    {basicDetails.Lastname}
                  </h3>
                  <p className="m-0 font-normal">{basicDetails.Email}</p>

                  {contactDetails.map(
                    (
                      phone: { Type: string; PhoneNumber: any },
                      index: React.Key | null | undefined
                    ) => (
                      <p className="m-0 font-normal" key={index}>
                        {phone.Type === "work" &&
                          `${t("Work")} - ${phone.PhoneNumber}`}
                        {phone.Type === "home" &&
                          `${t("Home")} - ${phone.PhoneNumber}`}
                      </p>
                    )
                  )}
                </div>
              </div>
            ) : (
              <div className="p-5 cardBody">
                <div className="text-center">
                  <p>{t("profile.noDataAvailable")}</p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
            <h2 className="text-title text-lg font-bold m-0">
              {t("profile.addressText")}
            </h2>
            <div className="flex text-sm">
              <div>
                {profileLoading ? (
                  <div className="flex flex-column gap-3">
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="4"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {profileLoading ? (
            <div className="flex flex-column gap-3">
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="4"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            </div>
          ) : addressDetails && addressDetails.length > 0 ? (
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                {addressDetails.map((address: any, index: number) => (
                  <div className="flex" key={index}>
                    <div className="experienceDetails flex flex-column gap-1">
                      {address.AddressLine1 && (
                        <p className="m-0 font-normal">
                          {address.AddressLine1}
                        </p>
                      )}
                      <p className="m-0 font-normal">
                        {address.AddressLine2}, {address.AddressLine3}
                      </p>
                      {address.countryName && (
                        <p className="m-0 font-normal">
                          {address.stateName}, {address.countryName}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
            <h2 className="text-title text-lg font-bold m-0">
              {t("profile.experienceText")}
            </h2>
            <div className="flex text-sm">
              <div>
                {profileLoading ? (
                  <div className="flex flex-column gap-3">
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="4"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {profileLoading ? (
            <div className="flex flex-column gap-3">
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="4"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            </div>
          ) : experienceDetails && experienceDetails.length > 0 ? (
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                {experienceDetails.map((experience: any, index: number) => (
                  <div className="flex" key={index}>
                    <div className="experienceDetails flex flex-column gap-1">
                      <h3 className="font-bold text-base text-title m-0">
                        {experience.Designation} ({experience.Department})
                      </h3>
                      <p className="m-0 font-normal">
                        {experience.CompanyName}
                      </p>
                      <p className="m-0 font-normal">
                        {new Date(experience.StartDate).toLocaleDateString(
                          "en-US",
                          { month: "long", year: "numeric" }
                        )}{" "}
                        -{" "}
                        {new Date(experience.EndDate).toLocaleDateString(
                          "en-US",
                          { month: "long", year: "numeric" }
                        )}
                      </p>
                      <p className="m-0 font-normal">{experience.WebsiteURL}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
            <h2 className="text-title text-lg font-bold m-0">
              {" "}
              {t("profile.professionalLicesesText")}
            </h2>
            <div className="flex text-sm">
              <div>
                {profileLoading ? (
                  <div className="flex flex-column gap-3">
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="4"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {profileLoading ? (
            <div className="flex flex-column gap-3">
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="4"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            </div>
          ) : professionalLicenseDetails &&
            professionalLicenseDetails.length > 0 ? (
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                {professionalLicenseDetails.map(
                  (license: any, index: number) => (
                    <div className="flex" key={index}>
                      <div className="experienceDetails flex flex-column gap-1">
                        <h3 className="font-normal text-base text-title mt-1 mb-1">
                          {t("profile.licenseDetails")}
                        </h3>
                        <h3 className="font-bold text-base text-title m-0">
                          {license.LicenseNumber}, {license.LicenseYear}
                        </h3>
                        <p className="m-0 font-normal">{license.LicenseName}</p>
                        <p className="m-0 font-normal">
                          {license.stateName}, {license.countryName}
                        </p>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default MemberProfile;

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import Table from "../../common/Table";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { OverlayPanel } from "primereact/overlaypanel";

const SelectRelatedRecords: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="px-5 py-4 border-bottom-1 border-gray-200 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.selectLinkedRelatedRecords")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex justify-content-between align-items-center pb-3 gap-3 w-full">
              <IconField iconPosition="left" className="w-full md:w-16rem">
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText
                  id="searchMemberLinked"
                  placeholder={t("record.searchMember")}
                  className="w-full"
                  aria-label={t("record.searchMember")}
                />
              </IconField>

              <div className="relative">
                <Button
                  className="button-md gap-1"
                  severity="secondary"
                  onClick={toggleFilterPanel}
                  aria-label={t("record.filter")}
                >
                  <FeatherIcon name="filter" size={20} color="inherit" />
                  <span className="font-bold text-capitalize hidden md:flex">
                    {t("record.filter")}
                  </span>
                </Button>
                {/* OverlayPanel for Filter */}
                <OverlayPanel
                  ref={overlayPanelRef}
                  onHide={() => setShowFilterPanel(false)}
                  appendTo={"self"}
                  dismissable
                  className="p-overlaypanel-filter left-auto right-0 top-100"
                  aria-labelledby="filter-heading"
                  role="dialog"
                >
                  <div className="flex flex-column gap-1" tabIndex={0}>
                    <p id="filter-heading" className="text-lg font-bold m-0 p-3">
                      {t("record.filter")}
                    </p>
                    <div className="filter-wrap">
                      {/* Add filter fields here */}
                    </div>
                    <Button
                      className="button-md w-full justify-content-center"
                    >
                      {t("record.applyFilter")}
                    </Button>
                  </div>
                </OverlayPanel>
              </div>
            </div>

            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "recordId", header: t("record.recordId") },
                { field: "recordSubType", header: t("record.recordSubType") },
                { field: "status", header: t("record.status") },
                { field: "subject", header: t("record.subject") },
              ]}
              showCheckbox={true}
            />
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="px-5 py-4 border-bottom-1 border-gray-200 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.selectLockedRelatedRecords")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex justify-content-between align-items-center pb-3 gap-3 w-full">
              <IconField iconPosition="left" className="w-full md:w-16rem">
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText
                  id="searchMemberLocked"
                  placeholder={t("record.searchMember")}
                  className="w-full"
                  aria-label={t("record.searchMember")}
                />
              </IconField>

              <div className="relative">
                <Button
                  className="button-md gap-1"
                  severity="secondary"
                  onClick={toggleFilterPanel}
                  aria-label={t("record.filter")}
                >
                  <FeatherIcon name="filter" size={20} color="inherit" />
                  <span className="font-bold text-capitalize hidden md:flex">
                    {t("record.filter")}
                  </span>
                </Button>
                {/* OverlayPanel for Filter */}
                <OverlayPanel
                  ref={overlayPanelRef}
                  onHide={() => setShowFilterPanel(false)}
                  appendTo={"self"}
                  dismissable
                  className="p-overlaypanel-filter left-auto right-0 top-100"
                  aria-labelledby="filter-heading"
                  role="dialog"
                >
                  <div className="flex flex-column gap-1" tabIndex={0}>
                    <p id="filter-heading" className="text-lg font-bold m-0 p-3">
                      {t("record.filter")}
                    </p>
                    <div className="filter-wrap">
                      {/* Add filter fields here */}
                    </div>
                    <Button
                      className="button-md w-full justify-content-center"
                    >
                      {t("record.applyFilter")}
                    </Button>
                  </div>
                </OverlayPanel>
              </div>
            </div>

            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "recordId", header: t("record.recordId") },
                { field: "recordSubType", header: t("record.recordSubType") },
                { field: "subject", header: t("record.subject") },
              ]}
              showCheckbox={true}
            />
          </div>
        </div>

      </div>
    </>
  );
};

export default SelectRelatedRecords;

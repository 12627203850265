import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import PreviewItem from "../common/PreviewItem";
import pdfIcon from "../../assets/icons/pdf.svg";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";

interface DetailTabProps {
  showEditButton?: boolean;
}

const DetailTab: React.FC<DetailTabProps> = ({ showEditButton }) => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("standard.basicDetails")}
            </h2>
            {showEditButton && (
              <Button
                text
                className="p-button-plain gap-2 underline"
              >
                <FeatherIcon name="edit-2" size={20} />
                <span>{t("standard.edit")}</span>
              </Button>
            )}
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("standard.standardCreationBasedOn")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.committee")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.meetingId")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.recordId")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.ballotId")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.standardName")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.standardShortName")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.maintenanceCycle")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.publishingCycle")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.latestPublishedYear")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.nextPublishingYear")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.subsequentPublishingYears")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.previousPublishingYear")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.standardAbstract")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.industryNeeds")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.proposedBenefitOfStandards")}
                value="Value"
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("standard.publishedStandardDocuments")}
            </h2>
            {showEditButton && (
              <Button
                text
                className="p-button-plain gap-2 underline"
              >
                <FeatherIcon name="edit-2" size={20} />
                <span>{t("standard.edit")}</span>
              </Button>
            )}
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <a className="m-0 flex align-items-center gap-2 underline cursor-pointer">
                <img src={pdfIcon} alt="PDF" />
                <span className="text-link font-bold text-capitalize">
                  {"Random document B"}
                </span>
              </a>
              <a className="m-0 flex align-items-center gap-2 underline cursor-pointer">
                <img src={pdfIcon} alt="PDF" />
                <span className="text-link font-bold text-capitalize">
                  {"Random document B"}
                </span>
              </a>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default DetailTab;

import { Button } from 'primereact/button'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import { Avatar } from 'primereact/avatar'
import { useLanguageContext } from '../../LanguageContext'
import { CommentNewReplyProps, CommentPayload, FormValues, ReplyCommentPayload } from './type'
import { postData, putData } from "../../../services/Ballot/apiservice";

const CommentNewReply: React.FC<CommentNewReplyProps> = ({ ballotCommentId, onSubmitHandler, parentHierarchyLevel, parentResponseId,
    hasChildComment, commentPayload, allCommentStatus, selectedComment }) => {
    const [t, i18n] = useTranslation("ballot");
    const { selectedLanguage } = useLanguageContext();
    const performAction = () => {
        i18n.changeLanguage(selectedLanguage);
    };
    React.useEffect(() => {
        performAction();
    }, [selectedLanguage]);
    const toast = useRef<any>(null);
    const { control, handleSubmit, reset } = useForm<FormValues>({
        defaultValues: {
            paragraphSection: ''
        }
    });
    const updateCommentStatusHandler = async (payload: CommentPayload) => {
        try {
            const updateStatus = await putData(`BallotComments`, `${selectedComment?.Id}`, payload);
            toast.current.show({
                severity: 'info',
                message: `Status Updated Successfully`,
                life: 3000,
                content: (props: any) => (<>
                    <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
                        <div className="font-medium text-lg my-3 text-900">{`${props.message.message}`}</div>
                    </div >
                </>
                )
            });
            setTimeout(() => {
                onSubmitHandler?.(null, true);
            }, 1500);
        } catch (err) {
            console.log(err)
        }
    }
    const postReplyComment = async (payload: ReplyCommentPayload) => {
        try {
            const postComment = await postData(`BallotCommentResponses`, payload);
            await toast.current.show({
                severity: 'info',
                message: `Comment Posted Successfully`,
                life: 3000,
                content: (props: any) => (<>
                    <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
                        <div className="font-medium text-lg my-3 text-900">{`${props.message.message}`}</div>
                    </div >
                </>
                )
            });
            setTimeout(() => {
                onSubmitHandler?.(null, true);
            }, 1500);
            if (!hasChildComment) {
                const updateCommentPayload = { ...commentPayload }
                updateCommentPayload.commentStatusId = allCommentStatus && allCommentStatus['UnResolved'].id;
                await updateCommentStatusHandler(updateCommentPayload)
            }

        } catch (err) {
            console.log(err, 'err')
        }
    }
    const onSubmit = (data: FormValues) => {
        const { paragraphSection } = data;
        const parentHierarchyLevelPayload = {
            isDirty: true,
            isNew: true,
            ballotCommentId: ballotCommentId,
            responseText: paragraphSection
        }
        const childHierarchyLevelPayload = {
            isDirty: true,
            isNew: true,
            ballotCommentId: ballotCommentId,
            responseText: paragraphSection,
            parentResponseId: parentResponseId
        }
        if (parentHierarchyLevel) {
            postReplyComment(parentHierarchyLevelPayload);
        } else {
            postReplyComment(childHierarchyLevelPayload);
        }
    }
    return (<>
        <Toast ref={toast} />
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-4 flex flex-row gap-3 surface-100">
                <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" size="large" shape="circle" className="flex-shrink-0" />
                <div className="flex flex-column gap-2 w-full">
                    <Controller
                        name="paragraphSection"
                        control={control}
                        render={({ field }) => (
                            <InputTextarea
                                id="paragraphSection"
                                placeholder={t("ballot.paragraphSection")}
                                rows={3}
                                cols={50}
                                aria-label={t("ballot.paragraphSection")}
                                {...field}
                            />
                        )}
                    />
                    <div className="flex flex-wrap align-items-center py-2 gap-3 md:gap-4 justify-content-between cardFooter">
                        <div>
                        </div>
                        <div className="flex gap-3 md:gap-4">
                            <Button
                                label={t("ballot.cancel")}
                                type="button"
                                className="button-md ml-auto w-auto"
                                severity="secondary"
                                onClick={() => {
                                    onSubmitHandler?.(null, false);
                                    reset();
                                }}
                            />
                            <Button
                                label={t("ballot.submit")}
                                type="submit"
                                className="button-md w-auto"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>
    )
};
export default CommentNewReply
import React from 'react';

const PowerBIReportIframe: React.FC = () => {
  return (
    <div className="bi-power">
      <iframe title="Stanza New Report" width="100%" height="800px" src="https://app.powerbi.com/view?r=eyJrIjoiNzU2NmVjOGUtNjU4Yy00OWFlLWIxMjItMzE2ZjA4NDNhMzk3IiwidCI6IjNhNGJjNWJmLTVlZTEtNGQxMC1iNzZiLTY2MjM0OTU4OGNkMSIsImMiOjEwfQ%3D%3D"></iframe>
    </div>
  );
};

export default PowerBIReportIframe;

// focusUtils.ts
export const focusOnErrorField = (selector: string) => {
  const errorFields = document.querySelectorAll(selector);
  if (errorFields.length > 0) {
    const firstErrorField = errorFields[0] as HTMLElement;
    const inputElement = firstErrorField.querySelector('input');
    const dropdownElement = firstErrorField.querySelector('.p-dropdown');
    
    if (inputElement) {
      (inputElement as HTMLInputElement).focus();
    } else if (dropdownElement) {
      // If using PrimeReact Dropdown, you might need to trigger its focus method
      const dropdownInstance = (dropdownElement as any).getElementsByClassName('p-dropdown-trigger')[0];
      if (dropdownInstance && dropdownInstance.focus) {
        dropdownInstance.focus();
      }
    } else {
      // If no input or dropdown found, try focusing on the container element itself
      firstErrorField.focus();
    }
  }
};


import React, { useState, useEffect } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { useNavigate } from "react-router-dom";
import { deleteData, fetchData } from "../../services/apiService";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import pdfIcon from "../../assets/icons/fileIcon.svg";

interface DocumentUpload {
  containerName: string;
  sasToken: string;
  storageAccountName: string;
}

interface Document {
  Id: string;
  DocumentName: string;
  BlobURL: string;
}

interface Props {
  documentType: string;
  membershipRecordID: any;
  showNoDataMessage?: boolean; // Optional prop to indicate whether to show "No Data Available" message or not
}

const ListDocuments: React.FC<Props> = ({
  documentType,
  membershipRecordID,
  showNoDataMessage = true, // Default value is true
}) => {
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const navigate = useNavigate();
  const [userDocumentTypeId, setUserDocumentTypeId] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const [documentDetails, setDocumentDetails] = useState<Document[] | null>(
    null
  );
  const [documentUploadData, setDocumentUploadData] = useState<
    DocumentUpload | undefined
  >();

  const getAll = async () => {
    const fetchAllDocuments = await fetchData("DocumentType/GetAll");
    const documents = fetchAllDocuments.Collection;

    for (const document of documents) {
      if (document.TypeName === documentType) {
        setUserDocumentTypeId(document.Id);
        break;
      }
    }
  };

  const handleGetAllDocumentUpload = async () => {
    if (!userDocumentTypeId) return;
    const response = await fetchData(
      `RecordDocument/Search?Filters[0].Key=DocumentTypeId&Filters[0].Value=${userDocumentTypeId}&Filters[1].Key=RecordId&Filters[1].Value=${membershipRecordID}&PageIndex=-1`
    );

    const documentData: Document[] = response.Collection;
    setDocumentDetails(updateDocumentNames(documentData));
  };

  const updateDocumentNames = (documents: Document[]): Document[] => {
    const nameCount: { [key: string]: number } = {};
    const updatedDocuments = documents.map((doc) => {
      if (nameCount[doc.DocumentName]) {
        nameCount[doc.DocumentName]++;
      } else {
        nameCount[doc.DocumentName] = 1;
      }

      if (nameCount[doc.DocumentName] > 1) {
        doc.DocumentName += ` (${nameCount[doc.DocumentName]})`;
      }

      return doc;
    });

    return updatedDocuments;
  };

  const handleFetchUserDocument = async () => {
    try {
      const documentResponse = await fetchData(
        `RecordDocument/GetRecordDocumentValetKey?RecordId=${membershipRecordID}&userDocumentType=RecordDocument`
      );
      setDocumentUploadData(documentResponse);
      return documentResponse;
    } catch (error) {
      console.error("Error fetching user document:", error);
    }
  };

  const downloadDocument = async (blobUrl: string) => {
    try {
      const documentData = await handleFetchUserDocument();
      const sasToken = documentData?.sasToken;
      if (sasToken) {
        const urlWithToken = `${blobUrl}?${sasToken}`;
        window.open(urlWithToken, "_blank");
      } else {
        console.error(
          "SAS token is not available. Cannot download the document."
        );
      }
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  };

  const confirmDeleteDocument = (documentId: string) => {
    confirmDialog({
      header: t("profile.confirmation"),
      message: t("profile.areYouSureYouWantToDelete"),
      icon: "",
      acceptLabel: t("profile.yes"),
      rejectLabel: t("profile.no"),
      defaultFocus: "accept",
      accept: () => handleDeleteDocument(documentId),
    });
  };

  const handleDeleteDocument = async (documentId: string) => {
    try {
      if (documentId) {
        await deleteData("RecordDocument", documentId);
        await handleGetAllDocumentUpload();
      } else {
        console.error("No id found in parameters.");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (userDocumentTypeId) {
      handleGetAllDocumentUpload();
    }
  }, [userDocumentTypeId]);

  return (
    <>
      <div className="">
        {documentDetails && documentDetails.length > 0
          ? documentDetails.map((document: Document, index: number) => (
              <div key={index} className="flex flex-column gap-5">
                <div className="flex flex-column gap-3">
                  <p className="m-0 flex align-items-center gap-2">
                    <span
                      className="cursor-pointer flex align-items-center"
                      onClick={() => downloadDocument(document.BlobURL)}
                    >
                      <img src={pdfIcon} alt="PDF" className="mr-2" />
                      <span className="underline text-charcoal">
                        {document.DocumentName}
                      </span>
                    </span>
                    <Button
                      type="button"
                      text
                      className="p-button-plain gap-2 text-delete underline"
                      onClick={() => confirmDeleteDocument(document.Id)}
                      aria-label="Delete"
                    >
                      <FeatherIcon
                        name="trash-2"
                        size={20}
                        onClick={undefined}
                      />
                    </Button>
                  </p>
                </div>
              </div>
            ))
          : showNoDataMessage && <p className="m-0">No Data Available</p>}
      </div>
    </>
  );
};

export default ListDocuments;

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { RootState } from "../../store/store";
import FeatherIcon from "../common/FeatherIconComponent";
import { fetchData, putData } from "../../services/apiService";
import { useLanguageContext } from "../LanguageContext";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { fetchRecordRequest } from "./recordUtils";
import { Menu } from "primereact/menu";
import { InputTextarea } from "primereact/inputtextarea";
import { usePermission } from "../auth/Authorization";
import { Badge } from "primereact/badge";
import RecordListDocument from "../common/RecordListDocument";
import Table from "../common/Table";
import RequestListDocument from "../common/RequestListDocument";
import { Panel } from "primereact/panel";
import { ScrollPanel } from "primereact/scrollpanel";

interface MenuItem {
  label: string;
  command?: () => void;
}

interface ActiveMembership {
  isDirty?: boolean;
  isNew: boolean;
  isActive: boolean;
  isDelete: boolean;
  userProfileId: string;
  committeeId: string;
  membershipPositionId: string;
  startDate: any;
  endDate: any;
  categoryOfInterestId: string;
  tenureLength: any;
  membershipStatusId: string;
  committeeMembershipId: string;
}
const defaultActiveMembership: ActiveMembership = {
  isNew: true,
  isActive: true,
  isDelete: false,
  userProfileId: "",
  committeeId: "",
  membershipPositionId: "",
  startDate: null,
  endDate: null,
  categoryOfInterestId: "",
  tenureLength: 0,
  membershipStatusId: "",
  committeeMembershipId: "",
};
const Preview: React.FC = () => {
  const toast = useRef<any>(null);
  const [recordDetails, setRecordDetails] = useState<any>(null);
  const [showActionItem, setShowActionItem] = useState<any>([]);
  const [recordStatus, setRecordStatus] = useState<string | null | undefined>(
    ""
  );
  const [t, i18n] = useTranslation("record");
  const [membershipRecordId, setMembershipRecordId] = useState<any>(null);
  const [membershipRequestId, setMembershipRequestId] = useState<any>(null);
  const { selectedLanguage } = useLanguageContext();
  const [membershipTerminationReason, setMembershipTerminationReason] =
    useState<string>("");
  const [auditHistoryList, setAuditHistorList] = useState<any>(null);
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const breadcumbItems = [
    { label: t("record.dashboard"), url: "/dashboard" },
    { label: t("record.record"), url: "/record" },
    { label: t("record.preview") },
  ];
  const createRecordDetails = useSelector(
    (state: RootState) => state.createRecord
  );
  const [termsConditionsCheked, setTermsConditionsCheked] =
    useState<boolean>(true);
  const [confidentialityAgreement, setConfidentialityAgreement] =
    useState<boolean>(true);
  const [activeMembership, setActiveMembership] = useState<ActiveMembership[]>([
    defaultActiveMembership,
  ]);
  const [visibleMembership, setVisibleMembership] = useState<boolean>(false);
  const [closeValue, setCloseValue] = useState("");
  const [rejectValue, setRejectValue] = useState("");
  const [validationCloseError, setCloseValidationError] = useState("");
  const [validationRejectError, setRejectValidationError] = useState("");
  const [visibleClose, setVisibleClose] = useState<boolean>(false);
  const [visibleReject, setVisibleReject] = useState<boolean>(false);
  const [validationError, setValidationError] = useState("");
  const [membershipValue, setMembershipValue] = useState("");
  const handleAgreementboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setConfidentialityAgreement(e.checked);
    }
  };
  const [educationDetails, setEducationDetails] = useState<any>(null);
  const handleTermsboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setTermsConditionsCheked(e.checked);
    }
  };

  const navigate = useNavigate();
  const fetchCountryByID = async (id: string) => {
    try {
      const responseData = fetchData("Country", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const fetchStateByID = async (id: string) => {
    try {
      const responseData = fetchData("State", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const fetchUniversityByID = async (id: string) => {
    try {
      const responseData = fetchData("University", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const fetchDegreeByID = async (id: string) => {
    try {
      const responseData = fetchData("Degree", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const getTerminationNameByID = async () => {
    try {
      const responseData = await fetchData(
        `MembershipTerminateReason/${recordDetails.MembershipTerminateReason.Id}`
      );
      setMembershipTerminationReason(responseData.Reason);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (recordDetails?.MembershipTerminateReason?.Id) {
      getTerminationNameByID();
    }
  }, [recordDetails?.MembershipTerminateReason?.Id]);

  React.useEffect(() => {
    let recordId: string | null | undefined;
    if (
      createRecordDetails.configureRecord &&
      createRecordDetails.configureRecord.recordId
    ) {
      recordId = createRecordDetails.configureRecord.recordId;
      setMembershipRecordId(recordId);
    } else {
      const storedData = sessionStorage.recordId;
      setMembershipRecordId(storedData);
      if (storedData) {
        recordId = storedData;
      }
    }
    if (recordId) {
      const fetchRecordData = async () => {
        try {
          const labelValueArray = await fetchRecordRequest(recordId);
          const fetchAuditHistory = async () => {
            try {
              const response = await fetchData(
                `Audit/${labelValueArray?.Id}/Record`
              );
              setAuditHistorList(response);
            } catch (error) {
              console.error("Error fetching committees:", error);
            }
          };
          fetchAuditHistory();
          setMembershipRequestId(labelValueArray?.MembershipRequestId);
          setRecordDetails(labelValueArray);
          setRecordStatus(labelValueArray?.RecordStatus?.StatusName);
          const educationData =
            labelValueArray?.UserProfile?.UserEducationDetails;
          const updatedEducationDetails = await Promise.all(
            educationData.map(async (education: any) => {
              try {
                const countryData = await fetchCountryByID(education.CountryId);
                const stateData = await fetchStateByID(education.StateId);
                const universityData = await fetchUniversityByID(
                  education.UniversityId
                );
                const degreeData = await fetchDegreeByID(education.DegreeId);
                return {
                  ...education,
                  countryName: countryData?.Name,
                  stateName: stateData?.Name,
                  universityName: universityData?.Name,
                  degreeName: degreeData?.Name,
                };
              } catch (error) {
                console.error("Error fetching education details:", error);
                return education;
              }
            })
          );
          setEducationDetails(updatedEducationDetails);
        } catch (error) {
          console.error("Error fetching Category of Interest:", error);
        }
      };
      fetchRecordData();
    }
  }, [createRecordDetails]);
  const menuBallot = useRef<Menu>(null);
  const toggleBallotMenu = (event: any) => {
    if (menuBallot.current) {
      menuBallot.current.toggle(event);
    }
  };

  const approveMembership = async () => {
    if (!membershipValue.trim()) {
      setValidationError(`${t("record.remarkForApprove")}`);
      return;
    }
    const approveForMembership = {
      approveForMembership: membershipValue,
    };
    try {
      if (recordDetails) {
        const approveMemberhsipResponse = await putData(
          `Record/ApproveMembership/${recordDetails.Id}`,
          undefined,
          approveForMembership
        );
        toast.current?.show({
          severity: "success",
          summary: t("record.success"),
          detail: `${t("record.membershipGrantedTo")} ${recordDetails.UserProfile?.Firstname} ${recordDetails.UserProfile?.MiddleName} ${recordDetails.UserProfile?.Lastname}`,
          life: 3000,
        });
        setTimeout(() => {
          setVisibleMembership(false);
          navigate("/record");
        }, 1000);
        return approveMemberhsipResponse;
      }
    } catch (error) {
      console.error("Error posting data", error);
    }
  };

  useEffect(() => {
    if (recordStatus === "Open") {
      const items = [
        {
          label: t("record.approveMembership"),
          command: () => {
            navigate("/record/approve-membership");
          },
        },
        {
          label: t("record.reject"),
          command: handleRejectCondition,
        },
        {
          label: t("record.close"),
          command: handleCloseDialog,
        },
      ];

      const terminateItems = [
        {
          label: t("record.approve"),
          command: () => {
            approveTermination();
          },
        },
        {
          label: t("record.reject"),
          command: () => {
            rejectTermination();
          },
        },
      ];
      if (recordDetails?.IsTerminateRecord == true) {
        setShowActionItem(terminateItems);
      } else {
        setShowActionItem(items);
      }
    }
  }, [recordStatus]);

  const approveTermination = async () => {
    try {
      if (
        !recordDetails.Id ||
        !recordDetails.CommitteeId ||
        !recordDetails.UserProfileId
      ) {
        console.error("Missing required data for termination.");
        return;
      }
      const terminationData = {
        recordId: recordDetails.Id,
        committeeId: recordDetails.CommitteeId,
        userProfileId: recordDetails.UserProfileId,
      };

      const response = await putData(
        `Record/ApproveTermination`,
        undefined,
        terminationData
      );

      if (response) {
        toast.current.show({
          severity: "success",
          summary: t("record.success"),
          detail: t("record.membershipTerminated"),
          life: 3000,
        });
        setTimeout(() => {
          navigate("/record");
        }, 3000);
      }
    } catch (error) {
      console.error("Error approving termination:", error);
    }
  };

  const rejectTermination = async () => {
    try {
      const response = await putData(
        `Record/RejectMembershipRecord/${recordDetails.Id}`,
        undefined,
        undefined
      );

      if (response) {
        toast.current.show({
          severity: "success",
          summary: t("record.success"),
          detail: t("record.membershipRejected"),
          life: 3000,
        });
        setTimeout(() => {
          navigate("/record");
        }, 3000);
      }
    } catch (error) {
      console.error("Error approving termination:", error);
    }
  };

  const handleRejectCondition = async () => {
    setVisibleReject(true);
  };

  const handleCloseDialog = () => {
    setVisibleClose(true);
  };

  const handleCloseRecord = async () => {
    if (!closeValue.trim()) {
      setCloseValidationError(`${t("record.reasonCloseRecord")}`);
      return;
    }
    let reason = {
      reasonForCloseMembershipRecord: closeValue,
    };
    const recordId = sessionStorage.getItem("recordId");
    if (recordId) {
      try {
        const closeRecord = await putData(
          `Record/CloseMembershipRecord/${recordId}`,
          undefined,
          reason
        );
        setVisibleClose(false);
        if (closeRecord) {
          toast.current.show({
            severity: "danger",
            summary: t("record.success"),
            detail: t("record.recordHasClosed"),
          });
          setTimeout(() => {
            navigate("/record");
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleRejectRecord = async () => {
    if (!rejectValue.trim()) {
      setRejectValidationError(`${t("record.reasonRejectRecord")}`);
      return;
    }
    let reason = {
      reasonForCloseMembershipRecord: rejectValue,
    };
    const recordId = sessionStorage.getItem("recordId");
    if (recordId) {
      try {
        const rejectRecord = await putData(
          `Record/RejectMembershipRecord/${recordId}`,
          undefined,
          reason
        );
        setVisibleReject(false);
        if (rejectRecord) {
          toast.current.show({
            severity: "success",
            summary: t("record.success"),
            detail: t("record.recordHasRejected"),
          });
          setTimeout(() => {
            navigate("/record");
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const footerContentMembership = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("record.cancel")}
        onClick={() => {
          setVisibleMembership(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("record.approve")}
        onClick={approveMembership}
        className="button-md"
      />
    </div>
  );

  const footerContentClose = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("record.cancel")}
        onClick={() => {
          setVisibleClose(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("record.close")}
        onClick={handleCloseRecord}
        className="button-md"
      />
    </div>
  );

  const footerContentReject = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("record.cancel")}
        onClick={() => {
          setVisibleReject(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("record.reject")}
        onClick={handleRejectRecord}
        className="button-md"
      />
    </div>
  );

  const [checked, setChecked] = useState<boolean>(true);
  const handleCheckboxChange = (e: any) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-row gap-2">
          <div className="flex flex-column gap-2">
            <h1 className="text-title display-xs font-bold m-0">
              {t("record.recordId")}: {recordDetails?.RecordNumber} &nbsp;
              {recordDetails?.RecordStatus?.StatusName === "Rejected" ? (
                <Badge
                  value={recordDetails?.RecordStatus?.StatusName}
                  className="h-auto text-sm rejected"
                />
              ) : recordDetails?.RecordStatus?.StatusName === "Draft" ? (
                <Badge
                  value={recordDetails?.RecordStatus?.StatusName}
                  className="h-auto text-sm draft"
                />
              ) : recordDetails?.RecordStatus?.StatusName === "Open" ? (
                <Badge
                  value={recordDetails?.RecordStatus?.StatusName}
                  className="h-auto text-sm open"
                />
              ) : recordDetails?.RecordStatus?.StatusName === "Approved" ? (
                <Badge
                  value={recordDetails?.RecordStatus?.StatusName}
                  className="h-auto text-sm active"
                />
              ) : recordDetails?.RecordStatus?.StatusName === "Closed" ? (
                <Badge
                  value={recordDetails?.RecordStatus?.StatusName}
                  className="h-auto text-sm closed"
                />
              ) : null}
            </h1>
          </div>
          {recordDetails?.RecordStatus?.StatusName === "Open" && (
            <div className="flex flex-row align-items-center gap-2 left-auto">
              <Button
                className="button-md gap-1"
                severity="secondary"
                onClick={toggleBallotMenu}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    toggleBallotMenu(e);
                  }
                }}
              >
                <span className="font-bold">{t("record.action")}</span>
                <FeatherIcon name="chevron-down" size={20} color="inherit" />
              </Button>
              <Menu
                model={showActionItem}
                popup
                ref={menuBallot}
                id="profileMenu"
                popupAlignment="right"
                role="listbox"
              />
            </div>
          )}
        </div>
        <form>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.recordDetails")}
              </h2>
            </div>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-4">
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("record.staffSecretary")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {recordDetails?.Committee?.Staff?.Firstname}{" "}
                    {recordDetails?.Committee?.Staff?.Lastname}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("record.committeePosition")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {recordDetails?.MembershipPosition?.PositionName}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("record.categoryOfInterest")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {recordDetails?.CategoryOfInterest?.CategoryName}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("record.remark")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {recordDetails?.Remark}
                  </p>
                </div>
                {membershipTerminationReason && (
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                      {t("record.reasonForTermination")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {membershipTerminationReason}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.basicDetails")}
              </h2>
            </div>
            {recordDetails?.UserProfile ? (
              <div className="p-5 cardBody">
                <div className="flex flex-column gap-2">
                  <h3 className="font-bold text-base text-title m-0">
                    {recordDetails?.UserProfile?.Prefix}{" "}
                    {recordDetails?.UserProfile?.Firstname}{" "}
                    {recordDetails?.UserProfile?.MiddleName}{" "}
                    {recordDetails?.UserProfile?.Lastname}
                  </h3>
                  <p className="m-0 font-normal">
                    {recordDetails?.UserProfile?.Gender}
                  </p>
                  <p className="m-0 font-normal">
                    {recordDetails?.UserProfile?.Email}
                  </p>
                </div>
              </div>
            ) : (
              <p>{t("record.userProfileNotFound")}</p>
            )}
          </div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.address")}
              </h2>
            </div>
            {recordDetails?.UserProfile ? (
              <div className="p-5 cardBody">
                {recordDetails?.UserProfile?.UserAddress &&
                recordDetails.UserProfile.UserAddress.length > 0 ? (
                  <div className="flex flex-column gap-3">
                    {recordDetails.UserProfile.UserAddress.map(
                      (address: any, index: number) => (
                        <div key={index} className="flex flex-column gap-2">
                          <h3 className="font-bold text-base text-title m-0">
                            {t("record.addressType")} - {address.AddressType}
                          </h3>
                          <p className="m-0 font-normal">
                            {t("record.address")} - {address.AddressLine1}
                          </p>
                          <p className="m-0 font-normal">
                            {address.AddressLine2}
                          </p>
                          <p className="m-0 font-normal">
                            {address.AddressLine3}
                          </p>
                          <p className="m-0 font-normal">{address.Pincode}</p>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <p>{t("record.noDataAvailable")}</p>
                )}
              </div>
            ) : (
              <p>{t("record.userProfileNotFound")}</p>
            )}
          </div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.experience")}
              </h2>
            </div>
            {recordDetails?.UserProfile ? (
              <div className="p-5 cardBody">
                {recordDetails?.UserProfile?.UserExperiences &&
                recordDetails.UserProfile.UserExperiences.length > 0 ? (
                  <div className="flex flex-column gap-3">
                    {recordDetails.UserProfile.UserExperiences.map(
                      (experience: any, index: number) => (
                        <div key={index} className="flex flex-column gap-2">
                          <h3 className="font-bold text-base text-title m-0">
                            {experience.Designation}
                          </h3>
                          <p className="m-0 font-normal">
                            {experience.Designation}
                          </p>
                          <p className="m-0 font-normal">
                            {experience.Department}
                          </p>
                          <p className="m-0 font-normal">
                            {experience.CompanyName}
                          </p>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <p>{t("record.noDataAvailable")}</p>
                )}
              </div>
            ) : (
              <p>{t("record.userProfileNotFound")}</p>
            )}
          </div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.professionalDetails")}
              </h2>
            </div>
            {recordDetails?.UserProfile ? (
              <div className="p-5 cardBody">
                {recordDetails?.UserProfile?.UserProfessionalLicenses &&
                recordDetails.UserProfile.UserProfessionalLicenses.length >
                  0 ? (
                  <div className="flex flex-column gap-3">
                    {recordDetails.UserProfile.UserProfessionalLicenses.map(
                      (license: any, index: number) => (
                        <div key={index} className="flex flex-column gap-2">
                          <h3 className="font-bold text-base text-title m-0">
                            {t("record.licenseName")} - {license.LicenseName}
                          </h3>
                          <p className="m-0 font-normal">
                            {t("record.licenseNumber")} -{" "}
                            {license.LicenseNumber}
                          </p>
                          <p className="m-0 font-normal">
                            {t("record.validUpto")} - {license.LicenseYear}
                          </p>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <p>{t("record.noDataAvailable")}</p>
                )}
              </div>
            ) : (
              <p>{t("record.userProfileNotFound")}</p>
            )}
          </div>

          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.documents")}
              </h2>
            </div>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                <div className="flex flex-column gap-3">
                  <h3 className="m-0 font-bold text-base">
                    {t("record.recordDoumentsLabel")}
                  </h3>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    <RecordListDocument
                      documentType="RecordDocument"
                      membershipRecordID={membershipRecordId}
                      showNoDataMessage={true}
                    />
                  </p>
                </div>
                {membershipRequestId && (
                  <div className="flex flex-column gap-3">
                    <h3 className="m-0 font-bold text-base">
                      {t("record.requestDoumentsLabel")}
                    </h3>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      <RequestListDocument
                        documentType="RequestDocument"
                        membershipRequestID={membershipRequestId}
                      />
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.education")}
              </h2>
            </div>
            {recordDetails?.UserProfile ? (
              <div className="p-5 cardBody">
                {educationDetails && educationDetails.length > 0 ? (
                  <div className="flex flex-column gap-3">
                    {educationDetails.map((education: any, index: number) => (
                      <div key={index} className="flex flex-column gap-2">
                        <h3 className="font-bold text-base text-title">
                          {education.degreeName}
                        </h3>
                        <p className="m-0 font-normal">
                          {education.FromYear.substring(0, 10)} -{" "}
                          {education.ToYear.substring(0, 10)}
                        </p>
                        <p className="m-0 font-normal">
                          {education.universityName}{" "}
                        </p>
                        <p className="m-0 font-normal">
                          {education.stateName}, {education.countryName}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>{t("record.noDataAvailable")}</p>
                )}
              </div>
            ) : (
              <p>{t("record.userProfileNotFound")}</p>
            )}
          </div>

          {/* <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.reviewAndProfile")}
              </h2>
            </div>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-4">
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("record.stanzaCommitteeMembership")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {t("record.na")}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("record.membershipOtherTechnical")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {t("record.na")}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("record.majorContribution")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {t("record.na")}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("record.experienceQualifications")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {t("record.na")}
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.termsConditions")}
              </h2>
            </div>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                <div className="border-1 border-gray-200 p-4 p-shadow-4">
                  <div className="section">
                    <h3>1. Acceptance of Terms</h3>
                    <p>
                      By accessing or using the Site or Services, you agree to
                      be bound by these Terms and our Privacy Policy. If you do
                      not agree to these Terms or the Privacy Policy, you may
                      not access or use the Site or Services.
                    </p>
                  </div>

                  <div className="section">
                    <h3>2. Use of the Site and Services</h3>
                    <div className="list">
                      <p>
                        a. License: Subject to these Terms, [Your Company Name]
                        grants you a non-transferable, non-exclusive, revocable
                        license to use the Site and Services.
                      </p>
                      <p>b. Restrictions:</p>
                      <ul>
                        <li>
                          Use the Site or Services for any illegal purpose or in
                          violation of any local, state, national, or
                          international law;
                        </li>
                        <li>
                          Modify, adapt, translate, or reverse engineer any
                          portion of the Site or Services;
                        </li>
                        <li>
                          Attempt to gain unauthorized access to the Site or
                          Services or to any accounts or computer systems
                          connected to the Site or Services;
                        </li>
                        <li>
                          Interfere with the operation of the Site or Services
                          or any user’s enjoyment of them, including by
                          uploading or otherwise disseminating viruses, adware,
                          spyware, worms, or other malicious code;
                        </li>
                        <li>
                          Use any robot, spider, scraper, or other automated
                          means to access the Site or Services for any purpose
                          without our express written permission.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="section">
                    <h3>3. Intellectual Property</h3>
                    <div className="list">
                      <p>
                        a. Ownership: The Site and Services are owned and
                        operated by [Your Company Name]. The visual interfaces,
                        graphics, design, compilation, information, data,
                        computer code (including source code or object code),
                        products, software, services, and all other elements of
                        the Site and Services provided by [Your Company Name]
                        are protected by intellectual property and other laws.
                      </p>
                      <p>
                        b. Trademarks: [Your Company Name]’s trademarks, service
                        marks, and logos used and displayed on the Site and
                        Services are registered and unregistered trademarks or
                        service marks of [Your Company Name]. Other company,
                        product, and service names located on the Site or
                        Services may be trademarks or service marks owned by
                        others (the "Third-Party Trademarks," and, collectively
                        with [Your Company Name]’s trademarks, the
                        "Trademarks"). Nothing on the Site or Services should be
                        construed as granting, by implication, estoppel, or
                        otherwise, any license or right to use the Trademarks,
                        without our prior written permission specific for each
                        such use.
                      </p>
                    </div>
                  </div>

                  <div className="section">
                    <h3>4. Privacy</h3>
                    <p>
                      Please review our Privacy Policy, which explains how we
                      collect, use, and disclose information about you. By using
                      the Site or Services, you consent to the collection, use,
                      and disclosure of your information as described in our
                      Privacy Policy.
                    </p>
                  </div>

                  <div className="section">
                    <h3>5. Disclaimer of Warranties</h3>
                    <p>
                      THE SITE AND SERVICES ARE PROVIDED "AS IS" AND WITHOUT
                      WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE
                      FULLEST EXTENT PERMITTED BY APPLICABLE LAW, [YOUR COMPANY
                      NAME] DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
                      INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                      NON-INFRINGEMENT.
                    </p>
                  </div>

                  <div className="section">
                    <h3>6. Limitation of Liability</h3>
                    <p>
                      TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                      EVENT SHALL [YOUR COMPANY NAME] BE LIABLE FOR ANY
                      INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE
                      DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER
                      INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE,
                      GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF YOUR
                      ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SITE
                      OR SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT
                      (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF
                      [YOUR COMPANY NAME] HAS BEEN ADVISED OF THE POSSIBILITY OF
                      SUCH DAMAGES.
                    </p>
                  </div>

                  <div className="section">
                    <h3>7. Governing Law and Dispute Resolution</h3>
                    <p>
                      These Terms are governed by and construed in accordance
                      with the laws of [Your Jurisdiction], without regard to
                      its conflict of law principles. Any dispute arising out of
                      or relating to these Terms shall be exclusively resolved
                      by the state and federal courts located in [Your
                      Jurisdiction].
                    </p>
                  </div>

                  <div className="section">
                    <h3>8. Changes to Terms</h3>
                    <p>
                      We may modify these Terms at any time. If we make changes
                      to these Terms, we will post the revised Terms on the Site
                      and update the "Last Updated" date at the top of these
                      Terms. By continuing to access or use the Site or Services
                      after we have posted revised Terms, you agree to be bound
                      by the revised Terms.
                    </p>
                  </div>

                  <div className="section">
                    <h3>9. Contact Us</h3>
                    <p>
                      If you have any questions about these Terms, please
                      contact us at [Your Contact Information].
                    </p>
                  </div>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="termsconditions"
                    onChange={handleCheckboxChange}
                    checked={checked}
                    className="mr-2"
                    disabled
                  />
                  <label
                    htmlFor="termsconditions"
                    className="text-base font-normal"
                  >
                    {t("record.iAgreeToTermsAndConditions")}
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.confidentialityAgreement")}
              </h2>
            </div>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                <p className="m-0 font-normal text-base">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <p className="m-0 font-normal text-base">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="confidentiality"
                    onChange={handleAgreementboxChange}
                    checked={confidentialityAgreement}
                    className="mr-2"
                    disabled
                  />
                  <label
                    htmlFor="confidentiality"
                    className="text-base font-normal"
                  >
                    {t("record.iAgreeToConfidentialityAgreement")}
                  </label>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                Audit History 
              </h2>
              <div className="flex text-sm"></div>
            </div>
            <div>
              <div className="p-5 cardBody">
                <div className="flex flex-column gap-2">
                  <Table
                    products={auditHistoryList}
                    columns={[
                      {
                        field: "entityName",
                        header: "Name",
                      },
                      {
                        field: "positionName",
                        header:"New Change",
                      },
                      {
                        field: "categoryName",
                        header: "Old change",
                      }
                    ]}
                    showCheckbox={false}
                  />
                </div>
              </div>
            </div>
        </div> */}

          <Dialog
            id="visibleWithdrawId"
            header={t("record.remark")}
            visible={visibleMembership}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
            onHide={() => setVisibleMembership(false)}
            footer={footerContentMembership}
            className="custom-dialog hide-close"
            ariaCloseIconLabel="Close"
          >
            <div className="flex flex-column gap-2 w-full">
              {/* <label
                htmlFor="remarks"
                className="block font-light text-input-label capitalize"
              >
                {" "}
                {t("record.remarks")}
              </label> */}
              <InputTextarea
                id="remarks"
                value={membershipValue}
                onChange={(e) => setMembershipValue(e.target.value)}
                rows={5}
                cols={30}
                aria-label={t("record.remarks")}
              />
              {validationError && (
                <span className="p-error font-bold">{validationError}</span>
              )}
            </div>
          </Dialog>
          <Dialog
            id="visibleRejectId"
            header={t("record.closeRecord")}
            visible={visibleClose}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
            onHide={() => setVisibleClose(false)}
            footer={footerContentClose}
            className="custom-dialog hide-close"
            ariaCloseIconLabel="Close"
          >
            <div className="flex flex-column gap-2 w-full">
              <label
                htmlFor="requestClose"
                className={`block font-bold text-capitalize mb-2 ${
                  validationCloseError ? "p-error" : ""
                }`}
              >
                {t("record.reasonForClose")}
              </label>
              <InputTextarea
                id="requestClose"
                onChange={(e) => setCloseValue(e.target.value)}
                rows={5}
                cols={30}
                className={validationCloseError ? "p-invalid" : ""}
              />
              <span
                className={`p-error font-bold text-capitalize ${validationCloseError ? "" : "error-hidden"}`}
              >
                {validationCloseError}
              </span>
            </div>
          </Dialog>
          <Dialog
            id="visibleRejectId"
            header={t("record.rejectRecord")}
            visible={visibleReject}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
            onHide={() => setVisibleReject(false)}
            footer={footerContentReject}
            className="custom-dialog hide-close"
            ariaCloseIconLabel="Close"
          >
            <div className="flex flex-column gap-2 w-full">
              <label
                htmlFor="requestClose"
                className={`block font-bold text-capitalize mb-2 ${
                  validationRejectError ? "p-error" : ""
                }`}
              >
                {t("record.reasonForReject")}
              </label>
              <InputTextarea
                id="requestClose"
                onChange={(e) => setRejectValue(e.target.value)}
                rows={5}
                cols={30}
                className={validationRejectError ? "p-invalid" : ""}
              />
              <span
                className={`p-error font-bold text-capitalize ${validationRejectError ? "" : "error-hidden"}`}
              >
                {validationRejectError}
              </span>
            </div>
          </Dialog>
        </form>
      </div>
    </>
  );
};

export default Preview;

import React from "react";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { useLanguageContext } from "../../LanguageContext";
import Table from "../../common/Table";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

const ContributingCommittee: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex flex-column gap-2 px-5 py-4 border-bottom-1 border-gray-200 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.mandatorytandardCommitteeReview")}
            </h2>
            <p className="text-base font-normal m-0">
              Any supporting text for request listing page can go here
            </p>
          </div>

          <div className="p-5 cardBody">
            <div className="pb-3">
              <IconField iconPosition="left" className="w-full md:w-16rem">
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText
                  id="searchCommittee"
                  placeholder={t("record.searchCommittee")}
                  className="w-full"
                  aria-label={t("record.searchCommittee")}
                />
              </IconField>
            </div>

            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "committeeName", header: t("record.committeeName") },
                { field: "primaryStaff", header: t("record.primaryStaff") },
                { field: "committeeLevel", header: t("record.committeeLevel") },
              ]}
            />
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex flex-column gap-2 px-5 py-4 border-bottom-1 border-gray-200 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.reviewAndCommentCommittee")}
            </h2>
            <p className="text-base font-normal m-0">
              Any supporting text for request listing page can go here
            </p>
          </div>

          <div className="p-5 cardBody">
            <div className="pb-3">
              <IconField iconPosition="left" className="w-full md:w-16rem">
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText
                  id="searchCommittee"
                  placeholder={t("record.searchCommittee")}
                  className="w-full"
                  aria-label={t("record.searchCommittee")}
                />
              </IconField>
            </div>

            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "committeeName", header: t("record.committeeName") },
                { field: "committeeLevel", header: t("record.committeeLevel") },
              ]}
            />
          </div>
        </div>

      </div>
    </>
  );
};

export default ContributingCommittee;

import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { Button } from "primereact/button";
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import { postData, putData } from "../../../services/Ballot/apiservice";
import { fetchData } from "../../../services/Ballot/apiservice";
import { formatDate } from "../../../utils/utils";
import { BALLOT_RECORD_OUT_FOR_BALLOT } from "../common/constants";

const BallotRecordTable = () => {
  const { id } = useParams();
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const navigate = useNavigate();
  const { postBallotRecord, isEdit } = useSelector(
    (state: any) => state.ballotSelectRecords
  );

  const { ballotRecordStatusList, ballotDetailsInfo } = useSelector(
    (state: any) => state.ballotDetails
  );
  let ballotId = ballotDetailsInfo.id;

  const tableRecordData: any = useSelector(
    (state: any) => state.ballotSelectRecords.recordTableData
  );
  const tablerecordSelected: any = useSelector(
    (state: any) => state.ballotSelectRecords.selectedTableRecords
  );
  const [filteredData, setFilteredData] = useState(tableRecordData);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const createBallotRecord = tablerecordSelected.map((tableRecord: any) => {
    return {
      isDirty: false,
      isNew: true,
      ballotId: ballotId,
      recordId: tableRecord.id,
      ballotRecordStatusId:
        ballotRecordStatusList[BALLOT_RECORD_OUT_FOR_BALLOT]?.id,
      term: 10,
      isActive: true,
      isDeleted: false,
      ballotRecordId: "00000000-0000-0000-0000-000000000000",
    };
  });

  const onRowSelectHandler = (data: any) => {
    dispatch(
      ballotSelectRecords.tableRecordSelectionHandler({ rowData: data })
    );
  };
  const onRowUnSelectHandler = (data: any) => {
    dispatch(
      ballotSelectRecords.tableRecordSelectionHandler({ rowData: data })
    );
  };
  const editBallotRecord = tableRecordData.map((tableRecord: any) => {
    const isDeleted = !tablerecordSelected.find(
      (updatedRecord: any) => updatedRecord.id === tableRecord.id
    );
    return {
      isDirty: true,
      isNew: false,
      ballotId: ballotId,
      recordId: tableRecord.ballotRecordId,
      ballotRecordStatusId:
        ballotRecordStatusList[BALLOT_RECORD_OUT_FOR_BALLOT]?.id,
      term: tableRecord.termYear,
      isActive: !isDeleted,
      isDeleted: isDeleted,
      ballotRecordId: tableRecord.id,
    };
  });
  const updateballotRecordHandler = async () => {
    try {
      if ((ballotId && isEdit) || (postBallotRecord && ballotId && !isEdit)) {
        const updateRecords = await postData(
          `BallotRecord/BulkUpload`,
          editBallotRecord
        );
        dispatch(ballotSelectRecords.addStepCountHandler({ value: 1 }));
        editRecordhandler();
      }
      if (!postBallotRecord && ballotId && !isEdit) {
        const updateRecords = await postData(
          `BallotRecord/BulkUpload`,
          createBallotRecord
        );
        dispatch(ballotSelectRecords.addStepCountHandler({ value: 1 }));
        dispatch(ballotSelectRecords.postBallotRecord({ value: true }));
        editRecordhandler();
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const editRecordhandler = async () => {
    try {
      const selectedRecordsData = await fetchData(
        `BallotRecord/GetListByParentId?parentId=${ballotId}`
      );
      const tableProducts = selectedRecordsData.collection.map(
        (record: any) => {
          return {
            id: record?.id,
            recordNumber: record.record?.recordNumber,
            ballotRecordId: record.record?.id,
            applicantName: record.record?.userProfile?.firstname,
            positionAppliedFor: record.record?.membershipPosition.positionName,
            categoryOfInterest: record.record?.categoryOfInterest.categoryName,
            requestedOn: formatDate(record.record?.createdDate),
            statusName: record.ballotRecordStatus?.name,
            termYear: record.term,
            createdDate: record.createdDate,
            ModifiedDate: record.modifiedDate,
          };
        }
      );
      const filteredData = tableProducts.filter(
        (item: any) => item.statusName === null
      );
      dispatch(
        ballotSelectRecords.addRecordTableData({ rowTableData: tableProducts })
      );
      updateRecordSelectionHandler(tableProducts);
    } catch (err) {
      console.log(err, "err");
    }
  };
  const updateRecordSelectionHandler = (tableRecordData: any) => {
    tableRecordData.forEach((data: any) => {
      dispatch(
        ballotSelectRecords.tableRecordSelectionHandler({ rowData: data })
      );
    });
  };
  useEffect(() => {
    if (isEdit && ballotId) {
      editRecordhandler();
    }
  }, [isEdit]);
  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = tableRecordData.filter(
      (record: any) =>
        record.recordNumber.toLowerCase().includes(lowercasedSearch) ||
        record.applicantName.toLowerCase().includes(lowercasedSearch) ||
        record.positionAppliedFor.toLowerCase().includes(lowercasedSearch)
    );
    setFilteredData(filtered);
  }, [search, tableRecordData]);

  const getBallotDetailsHandler = async () => {
    try {
      const ballotDetailsData = await fetchData(
        `BallotRecordVote/BallotReports/${id}`
      );
      console.log(ballotDetailsData);
    } catch (err) {
      console.error("Error fetching ballot details", err);
    }
  };

  useEffect(() => {
    getBallotDetailsHandler();
  }, [id]);

  return (
    <>
      <div className="toggleSearchRecords">
        <div className="flex flex-wrap align-items-center md:flex-nowrap md:justify-content-between py-3 gap-3 w-ful">
          <IconField iconPosition="left" className="w-full md:w-16rem">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              id="searchBallotRecord"
              placeholder={t("ballot.searchBallotRecord")}
              className="w-full"
              aria-label={t("ballot.searchBallotRecord")}
              onChange={(e) => setSearch(e.target.value)}
            />
          </IconField>
          <p className="text-base font-bold m-0 text-disabled text-link">
            {tablerecordSelected.length} {t("ballot.recordsSelected")}
          </p>
          <div className="flex gap-3 w-full w-auto align-items-center ml-auto">
            <div className="relative"></div>
          </div>
        </div>
        <DataTable
          key={filteredData}
          sortOrder={1}
          stripedRows
          showGridlines
          value={filteredData}
          paginator
          rows={10}
          emptyMessage="No data found."
          selectionMode={"checkbox"}
          selection={tablerecordSelected}
          onSelectionChange={(e) => {}}
          dataKey="id"
          onRowSelect={(e: any) => {
            onRowSelectHandler(e.data);
          }}
          onRowUnselect={(e: any) => {
            onRowUnSelectHandler(e.data);
          }}
        >
          <Column
            key="IsChecked"
            selectionMode="multiple"
            field="IsChecked"
            headerStyle={{ width: "3rem", visibility: "hidden" }}
            body={(rowData) => {
              return (
                <Checkbox
                  id={`checkbox-${rowData.id}`}
                  checked={rowData.IsChecked}
                  className="mr-2"
                />
              );
            }}
          />
          <Column
            key="recordNumber"
            field={"recordNumber"}
            header={t("ballot.recordId")}
            style={{ cursor: "pointer" }}
            body={(rowData) => (
              <a
                onClick={() => {
                  sessionStorage.setItem("recordId", rowData.id);
                  navigate("/record/record-preview");
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    sessionStorage.setItem("recordId", rowData.id);
                    navigate("/record/record-preview");
                  }
                }}
                className="font-bold table-text-link underline"
                tabIndex={0}
              >
                {rowData.recordNumber}
              </a>
            )}
          />
          <Column
            key="applicantName"
            field={"applicantName"}
            header={t("ballot.applicantName")}
          />
          <Column
            key="positionAppliedFor"
            field={"positionAppliedFor"}
            header={t("ballot.positionAppliedFor")}
          />
          <Column
            key="categoryOfInterest"
            field={"categoryOfInterest"}
            header={t("ballot.categoryOfInterest")}
          />
          <Column
            key="requestedOn"
            field={"requestedOn"}
            header={t("ballot.requestedOn")}
          />
          <Column
            key="statusName"
            field={"statusName"}
            header={t("ballot.status")}
          />
        </DataTable>
      </div>
      <div className="bg-white flex items-center px-5 py-3 gap-4 fixed-footer w-full left-0 shadow justify-content-end">
        <Button
          className="button-md gap-1"
          onClick={() => updateballotRecordHandler()}
          disabled={tablerecordSelected.length <= 0 ? true : false}
        >
          <span className="font-bold text-capitalize">
            {t("ballot.continue")}
          </span>
          <FeatherIcon name="chevron-right" size={20} color="inherit" />
        </Button>
      </div>
    </>
  );
};

export default BallotRecordTable;

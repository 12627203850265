import React, { useState, useEffect } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { useNavigate } from "react-router-dom";
import { deleteData, fetchData } from "../../services/apiService";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import pdfIcon from "../../assets/icons/fileIcon.svg";
import { useDispatch } from "react-redux";
import { setSelectedDocumentId } from "../../slices/documentSlice";

interface DocumentUpload {
  containerName: string;
  sasToken: string;
  storageAccountName: string;
}

interface Document {
  Id: string;
  DocumentName: string;
  BlobURL: string;
}

interface Props {
  documentType: string;
  membershipRequestID: any;
  showNoDataMessage?: boolean; // Optional prop to indicate whether to show "No Data Available" message or not
}

const ListDocuments: React.FC<Props> = ({
  documentType,
  membershipRequestID,
  showNoDataMessage = true, // Default value is true
}) => {
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userDocumentTypeId, setUserDocumentTypeId] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const [documentDetails, setDocumentDetails] = useState<Document[] | null>(
    null
  );
  const [documentUploadData, setDocumentUploadData] = useState<
    DocumentUpload | undefined
  >();
  const [requestListDataAvailable, setRequestListDataAvailable] =
    useState<boolean>(false);
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const [documentToDelete, setDocumentToDelete] = useState<string | null>(null);
  const getAll = async () => {
    const fetchAllDocuments = await fetchData("DocumentType/GetAll");
    const documents = fetchAllDocuments.Collection;

    for (const document of documents) {
      if (document.TypeName === documentType) {
        setUserDocumentTypeId(document.Id);
        break;
      }
    }
  };

  const handleGetAllDocumentUpload = async () => {
    if (!userDocumentTypeId) return;
    if (membershipRequestID) {
      const response = await fetchData(
        `MembershipRequestDocument/Search?Filters[0].Key=DocumentTypeId&Filters[0].Value=${userDocumentTypeId}&Filters[1].Key=membershipRequestId&Filters[1].Value=${membershipRequestID}&PageIndex=-1`
      );
      const documentData: Document[] = response.Collection;
      const updatedDocuments = updateDocumentNames(documentData);
      setDocumentDetails(updatedDocuments);
      setRequestListDataAvailable(updatedDocuments.length > 0);
    }
  };

  const updateDocumentNames = (documents: Document[]): Document[] => {
    const nameCount: { [key: string]: number } = {};
    const updatedDocuments = documents.map((doc) => {
      if (nameCount[doc.DocumentName]) {
        nameCount[doc.DocumentName]++;
      } else {
        nameCount[doc.DocumentName] = 1;
      }

      if (nameCount[doc.DocumentName] > 1) {
        doc.DocumentName += ` (${nameCount[doc.DocumentName]})`;
      }

      return doc;
    });

    return updatedDocuments;
  };

  const handleFetchUserDocument = async () => {
    try {
      const documentResponse = await fetchData(
        `MembershipRequestDocument/GetRequestDocumentValetKey?membershipRequestId=${membershipRequestID}&userDocumentType=${documentType}`
      );
      setDocumentUploadData(documentResponse);
      return documentResponse;
    } catch (error) {
      console.error("Error fetching user document:", error);
    }
  };

  const downloadDocument = async (blobUrl: string, documentId: string) => {
    try {
      const documentData = await handleFetchUserDocument();
      const sasToken = documentData?.sasToken;
      if (sasToken) {
        const urlWithToken = `${blobUrl}?${sasToken}`;
        window.open(urlWithToken, "_blank");
        dispatch(setSelectedDocumentId(documentId));
      } else {
        console.error(
          "SAS token is not available. Cannot download the document."
        );
      }
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  };

  const confirmDeleteDocument = (documentId: string) => {
    setDocumentToDelete(documentId);
    setVisibleDialog(true);
  };

  const handleDeleteDocument = async () => {
    try {
      if (documentToDelete) {
        await deleteData("MembershipRequestDocument", documentToDelete);
        await handleGetAllDocumentUpload();
      }
      setVisibleDialog(false);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleRejectDelete = () => {
    setDocumentToDelete(null);
    setVisibleDialog(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (userDocumentTypeId) {
      handleGetAllDocumentUpload();
    }
  }, [userDocumentTypeId]);

  return (
    <>
      {/* <ConfirmDialog className="custom-dialog hide-close" ariaCloseIconLabel="Close" /> */}
      <ConfirmDialog
        visible={visibleDialog}
        onHide={handleRejectDelete}
        header={t("profile.confirmation")}
        message={t("profile.areYouSureYouWantToDelete")}
        icon=""
        acceptLabel={t("profile.yes")}
        rejectLabel={t("profile.no")}
        accept={handleDeleteDocument}
        reject={handleRejectDelete}
        ariaCloseIconLabel="Close"
      />
      <div className="mb-3">
        {documentDetails && documentDetails.length > 0
          ? documentDetails.map((document: Document, index: number) => (
              <div key={index} className="flex flex-column gap-5">
                <div className="flex flex-column gap-3">
                  <p className="m-0 flex align-items-center gap-2">
                    <span
                      className="cursor-pointer flex align-items-center"
                      onClick={() =>
                        downloadDocument(document.BlobURL, document.Id)
                      }
                    >
                      <img src={pdfIcon} alt="PDF" className="mr-2" />
                      <span className="underline text-charcoal">
                        {document.DocumentName}
                      </span>
                    </span>
                    <Button
                      text
                      type="button"
                      className="p-button-plain gap-2 text-delete underline"
                      onClick={() => confirmDeleteDocument(document.Id)}
                    >
                      <FeatherIcon
                        name="trash-2"
                        size={20}
                        onClick={undefined}
                      />
                    </Button>
                  </p>
                </div>
              </div>
            ))
          : showNoDataMessage && <p className="m-0">No Data Available</p>}
      </div>
    </>
  );
};

export default ListDocuments;

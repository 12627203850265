import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
import { useLanguageContext } from "../LanguageContext";
import FeatherIcon from "../common/FeatherIconComponent";

interface Option {
  name: string;
  code: string;
}

const BasicDetails: React.FC = () => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  const { control, formState: { errors }, handleSubmit } = useForm();

  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const [standardCreationBasedOn, setstandardCreationBasedOn] = useState<string>('');
  const [accredited, setaccredited] = useState<string>('');

  const [value, setValue] = useState<string>('');


  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("standard.basicStandardDetails")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl">
                <div className="flex flex-column gap-3">
                  <fieldset className="border-none p-0">
                    <legend className="block font-bold text-input-label p-0 mb-3">
                      {t("standard.standardCreationBasedOn")}
                    </legend>
                    <div className="flex flex-wrap gap-3">
                      <div className="flex align-items-center">
                        <RadioButton inputId="standardCreationBasedOn1" name="standardCreationBasedOn" value="Committee" onChange={(e: RadioButtonChangeEvent) => setstandardCreationBasedOn(e.value)} checked={standardCreationBasedOn === 'Committee'} />
                        <label htmlFor="standardCreationBasedOn1" className="ml-2">{t("standard.committee")}</label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton inputId="standardCreationBasedOn2" name="standardCreationBasedOn" value="Direct" onChange={(e: RadioButtonChangeEvent) => setstandardCreationBasedOn(e.value)} checked={standardCreationBasedOn === 'Direct'} />
                        <label htmlFor="standardCreationBasedOn2" className="ml-2">{t("standard.direct")}</label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="committeeName"
                    className={`block font-bold text-capitalize ${errors.committeeName
                      ? "p-error"
                      : "text-input-label"
                      }`}
                  >
                    {t("standard.committeeName")}*
                  </label>
                  <Controller
                    name="committeeName"
                    control={control}
                    rules={{ required: `${t("standard.committeeName")} ${t("standard.isRequired")}` }}
                    render={({ field }) => (
                      <InputText
                        id="committeeName"
                        aria-describedby="committeeNameError"
                        {...field}
                        placeholder={t("standard.committeeName")}
                        className={`w-full ${errors.committeeName ? "p-invalid" : ""
                          }`}
                      />
                    )}
                  />
                  <span
                    id="committeeNameError"
                    className={`p-error font-bold text-capitalize ${errors.committeeName ? "" : "error-hidden"
                      }`}
                  >
                    {`${errors.committeeName?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="meetingId"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("standard.meetingId")}
                  </label>
                  <Controller
                    name="meetingId"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="meetingId"
                        {...field}
                        placeholder={t("standard.meetingId")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>

                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="recordId"
                    className={`block font-bold text-capitalize text-input-label`}
                  >
                    {t("standard.recordId")}
                  </label>
                  <Controller
                    name="recordId"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        id="recordId"
                        {...field}
                        placeholder={t("standard.recordId")}
                        className={`w-full`}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="ballotId"
                    className={`block font-bold text-capitalize ${errors.ballotId
                      ? "p-error"
                      : "text-input-label"
                      }`}
                  >
                    {t("standard.ballotId")}*
                  </label>
                  <Controller
                    name="ballotId"
                    control={control}
                    rules={{ required: `${t("standard.ballotId")} ${t("standard.isRequired")}` }}
                    render={({ field }) => (
                      <InputText
                        id="ballotId"
                        aria-describedby="ballotIdError"
                        {...field}
                        placeholder={t("standard.ballotId")}
                        className={`w-full ${errors.ballotId ? "p-invalid" : ""
                          }`}
                      />
                    )}
                  />
                  <span
                    id="ballotIdError"
                    className={`p-error font-bold text-capitalize ${errors.ballotId ? "" : "error-hidden"
                      }`}
                  >
                    {`${errors.ballotId?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="standardName"
                    className={`block font-bold text-capitalize ${errors.standardName
                      ? "p-error"
                      : "text-input-label"
                      }`}
                  >
                    {t("standard.standardName")}*
                  </label>
                  <Controller
                    name="standardName"
                    control={control}
                    rules={{ required: `${t("standard.standardName")} ${t("standard.isRequired")}` }}
                    render={({ field }) => (
                      <InputText
                        id="standardName"
                        aria-describedby="standardNameError"
                        {...field}
                        placeholder={t("standard.standardName")}
                        className={`w-full ${errors.standardName ? "p-invalid" : ""
                          }`}
                      />
                    )}
                  />
                  <span
                    id="standardNameError"
                    className={`p-error font-bold text-capitalize ${errors.standardName ? "" : "error-hidden"
                      }`}
                  >
                    {`${errors.standardName?.message}`}
                  </span>
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="standardShortName"
                    className={`block font-bold text-capitalize ${errors.standardShortName
                      ? "p-error"
                      : "text-input-label"
                      }`}
                  >
                    {t("standard.standardShortName")}*
                  </label>
                  <Controller
                    name="standardShortName"
                    control={control}
                    rules={{ required: `${t("standard.standardShortName")} ${t("standard.isRequired")}` }}
                    render={({ field }) => (
                      <InputText
                        id="standardShortName"
                        aria-describedby="standardShortNameError"
                        {...field}
                        placeholder={t("standard.standardShortName")}
                        className={`w-full ${errors.standardShortName ? "p-invalid" : ""
                          }`}
                      />
                    )}
                  />
                  <span
                    id="standardShortNameError"
                    className={`p-error font-bold text-capitalize ${errors.standardShortName ? "" : "error-hidden"
                      }`}
                  >
                    {`${errors.standardShortName?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="maintenanceCycle"
                    className={`block font-bold text-capitalize ${errors.maintenanceCycle ? " p-error" : "text-input-label"}`}
                  >
                    {t("standard.maintenanceCycle")}*
                  </label>
                  <Controller
                    name="maintenanceCycle"
                    control={control}
                    rules={{ required: `${t("standard.maintenanceCycle")} ${t("standard.isRequired")}` }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="maintenanceCycle"
                        aria-describedby="maintenanceCycleError"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("standard.selectAnOption")}
                        className={`w-full ${errors.maintenanceCycle ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                  <span
                    id="maintenanceCycleError"
                    className={`p-error font-bold text-capitalize ${errors.maintenanceCycle ? "" : "error-hidden"}`}
                  >
                    {`${errors.maintenanceCycle?.message}`}
                  </span>
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="publishingCycle"
                    className={`block font-bold text-capitalize ${errors.publishingCycle ? " p-error" : "text-input-label"}`}
                  >
                    {t("standard.publishingCycle")}*
                  </label>
                  <Controller
                    name="publishingCycle"
                    control={control}
                    rules={{ required: `${t("standard.publishingCycle")} ${t("standard.isRequired")}` }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="publishingCycle"
                        aria-describedby="publishingCycleError"
                        {...field}
                        value={field.value || selectedOption}
                        options={options}
                        optionLabel="name"
                        placeholder={t("standard.selectAnOption")}
                        className={`w-full ${errors.publishingCycle ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setSelectedOption(e.value);
                        }}
                      />
                    )}
                  />
                  <span
                    id="publishingCycleError"
                    className={`p-error font-bold text-capitalize ${errors.publishingCycle ? "" : "error-hidden"}`}
                  >
                    {`${errors.publishingCycle?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="latestPublishedYear"
                    className={`block font-bold text-capitalize ${errors.latestPublishedYear
                      ? "p-error"
                      : "text-input-label"
                      }`}
                  >
                    {t("standard.latestPublishedYear")}*
                  </label>
                  <Controller
                    name="latestPublishedYear"
                    control={control}
                    rules={{ required: `${t("standard.latestPublishedYear")} ${t("standard.isRequired")}` }}
                    render={({ field }) => (
                      <InputText
                        id="latestPublishedYear"
                        aria-describedby="latestPublishedYearError"
                        {...field}
                        placeholder={t("standard.latestPublishedYear")}
                        className={`w-full ${errors.latestPublishedYear ? "p-invalid" : ""
                          }`}
                      />
                    )}
                  />
                  <span
                    id="latestPublishedYearError"
                    className={`p-error font-bold text-capitalize ${errors.latestPublishedYear ? "" : "error-hidden"
                      }`}
                  >
                    {`${errors.latestPublishedYear?.message}`}
                  </span>
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="nextPublishingYear"
                    className={`block font-bold text-capitalize ${errors.nextPublishingYear
                      ? "p-error"
                      : "text-input-label"
                      }`}
                  >
                    {t("standard.nextPublishingYear")}*
                  </label>
                  <Controller
                    name="nextPublishingYear"
                    control={control}
                    rules={{ required: `${t("standard.nextPublishingYear")} ${t("standard.isRequired")}` }}
                    render={({ field }) => (
                      <InputText
                        id="nextPublishingYear"
                        aria-describedby="nextPublishingYearError"
                        {...field}
                        placeholder={t("standard.nextPublishingYear")}
                        className={`w-full ${errors.nextPublishingYear ? "p-invalid" : ""
                          }`}
                      />
                    )}
                  />
                  <span
                    id="nextPublishingYearError"
                    className={`p-error font-bold text-capitalize ${errors.nextPublishingYear ? "" : "error-hidden"
                      }`}
                  >
                    {`${errors.nextPublishingYear?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="subsequentPublishingYears"
                    className={`block font-bold text-capitalize ${errors.subsequentPublishingYears
                      ? "p-error"
                      : "text-input-label"
                      }`}
                  >
                    {t("standard.subsequentPublishingYears")}*
                  </label>
                  <Controller
                    name="subsequentPublishingYears"
                    control={control}
                    rules={{ required: `${t("standard.subsequentPublishingYears")} ${t("standard.isRequired")}` }}
                    render={({ field }) => (
                      <InputText
                        id="subsequentPublishingYears"
                        aria-describedby="subsequentPublishingYearsError"
                        {...field}
                        placeholder={t("standard.subsequentPublishingYears")}
                        className={`w-full ${errors.subsequentPublishingYears ? "p-invalid" : ""
                          }`}
                      />
                    )}
                  />
                  <span
                    id="subsequentPublishingYearsError"
                    className={`p-error font-bold text-capitalize ${errors.subsequentPublishingYears ? "" : "error-hidden"
                      }`}
                  >
                    {`${errors.subsequentPublishingYears?.message}`}
                  </span>
                </div>
                <div className="flex col-12 py-0">
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("standard.addMoreSubsequentYears")}
                    </span>
                  </Button>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-9 col-12">
                  <label
                    htmlFor="standardAbstract"
                    className={`block font-bold text-capitalize ${errors.standardAbstract ? " p-error" : "text-input-label"}`}
                  >
                    {t("standard.standardAbstract")}*
                  </label>
                  <Controller
                    name="standardAbstract"
                    control={control}
                    rules={{ required: `${t("standard.standardAbstract")} ${t("standard.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="standardAbstract"
                        aria-describedby="standardAbstractError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("standard.standardAbstract")}
                        className={`w-full ${errors.standardAbstract ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="standardAbstractError"
                    className={`p-error font-bold text-capitalize ${errors.standardAbstract ? "" : "error-hidden"}`}
                  >
                    {`${errors.standardAbstract?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-9 col-12">
                  <label
                    htmlFor="industryNeeds"
                    className={`block font-bold text-capitalize ${errors.industryNeeds ? " p-error" : "text-input-label"}`}
                  >
                    {t("standard.industryNeeds")}*
                  </label>
                  <Controller
                    name="industryNeeds"
                    control={control}
                    rules={{ required: `${t("standard.industryNeeds")} ${t("standard.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="industryNeeds"
                        aria-describedby="industryNeedsError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("standard.industryNeeds")}
                        className={`w-full ${errors.industryNeeds ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="industryNeedsError"
                    className={`p-error font-bold text-capitalize ${errors.industryNeeds ? "" : "error-hidden"}`}
                  >
                    {`${errors.industryNeeds?.message}`}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl align-items-end">
                <div className="flex flex-column gap-2 xl:col-9 col-12">
                  <label
                    htmlFor="proposedBenefit"
                    className={`block font-bold text-capitalize ${errors.proposedBenefit ? " p-error" : "text-input-label"}`}
                  >
                    {t("standard.proposedBenefit")}*
                  </label>
                  <Controller
                    name="proposedBenefit"
                    control={control}
                    rules={{ required: `${t("standard.proposedBenefit")} ${t("standard.isRequired")}` }}
                    render={({ field }) => (
                      <InputTextarea
                        id="proposedBenefit"
                        aria-describedby="proposedBenefitError"
                        {...field}
                        rows={5}
                        cols={30}
                        placeholder={t("standard.proposedBenefit")}
                        className={`w-full ${errors.proposedBenefit ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  <span
                    id="proposedBenefitError"
                    className={`p-error font-bold text-capitalize ${errors.proposedBenefit ? "" : "error-hidden"}`}
                  >
                    {`${errors.proposedBenefit?.message}`}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>


        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("standard.document")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <div className="flex flex-column gap-2">
                <h3 className="text-base font-bold m-0">
                  {t("standard.publishedStandardDocument")}
                </h3>
                <div>
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0 underline"
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("standard.uploadDocument")}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default BasicDetails;

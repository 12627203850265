import React, { useState, useRef, useEffect } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { useNavigate } from "react-router-dom";
import pdfIcon from "../../assets/icons/fileIcon.svg";
import { fetchData, postData } from "../../services/apiService";
import { AnonymousCredential, BlobServiceClient } from "@azure/storage-blob";
import { Toast } from "primereact/toast";
import { useLoadingContext } from "../Layout";

interface DocumentUpload {
  containerName: string;
  sasToken: string;
  storageAccountName: string;
}

interface Props {
  heading: string;
  startFileUpload: boolean;
  setStartFileUpload: (value: boolean) => void;
  documentType: string;
  onFileUploadChange: (hasFile: boolean) => void;
}

const DocumentUpload: React.FC<Props> = ({
  heading,
  startFileUpload,
  setStartFileUpload,
  documentType,
  onFileUploadChange,
}) => {
  const { setLoading } = useLoadingContext();
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const navigate = useNavigate();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const toast = useRef<Toast>(null);
  const [file, setFile] = useState<any | undefined>(undefined);
  const [blobUrl, setBlobUrl] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [fileError, setFileError] = useState<string | null>(null);
  const [blobUrlState, setBlobUrlState] = useState<string | null>(null);
  const [userDocumentTypeId, setUserDocumentTypeId] = useState<
    string | undefined
  >(undefined);

  const [documentUploadData, setDocumentUploadData] =
    useState<DocumentUpload>();
  const [blobService, setBlobService] = useState<BlobServiceClient | null>(
    null
  );
  const userProfileID = localStorage.getItem("userProfileID");

  const initializeBlobService = async (documentResponse: any) => {
    const connectionString = `https://${documentResponse?.storageAccountName}.blob.core.windows.net?${documentResponse?.sasToken}`;

    const blobServiceClient = new BlobServiceClient(connectionString);
    setBlobService(blobServiceClient);
  };

  const handleFetchUserDocument = async () => {
    try {
      const documentResponse = await fetchData(
        `UserDocument/GetUserDocumentValetKey?userProfileId=${userProfileID}&userDocumentType=${documentType}`
      );
      setDocumentUploadData(documentResponse);
      initializeBlobService(documentResponse);
    } catch (error) {}
  };

  const getAll = async () => {
    const fetchAllDocuments = await fetchData("DocumentType/GetAll");
    const documents = fetchAllDocuments.Collection;
    let userDocumentTypeId;

    for (const document of documents) {
      if (document.TypeName === documentType) {
        setUserDocumentTypeId(document.Id);
        break;
      }
    }
  };

  React.useEffect(() => {
    getAll();
    handleFetchUserDocument();
  }, []);

  const showSuccessFileUpload = () => {
    toast.current?.show({
      severity: "success",
      //summary: "File Upload Successful",
      detail: "The file has been uploaded successfully.",
      life: 5000,
    });
  };

  const handleDocumentUpload = async (files: any[]) => {
    try {
      setLoading(true);
      if (documentUploadData && blobService) {
        for (let i = 0; i < files.length; i++) {
          const fileName = files[i];
          // Create a Blob from the file name
          const fileBlob = new Blob([fileName], {
            type: "application/octet-stream",
          });

          const containerName = documentUploadData.containerName;
          let sub = containerName.substring(0, containerName.length - 1);
          const containerClient = blobService.getContainerClient(sub);
          const currentTime = Date.now();
          const blobName = `${currentTime}_${fileName.name}`;
          const blockBlobClient = containerClient.getBlockBlobClient(blobName);
          const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
            fileBlob,
            {
              blockSize: 4 * 1024 * 1024,
              concurrency: 20,
            }
          );
          const url = uploadBlobResponse._response.request.url;
          const parts = url.split("?");
          const modifiedBlobUrl = parts[0];
          setBlobUrl(modifiedBlobUrl);
          showSuccessFileUpload();
          if (modifiedBlobUrl) {
            const userDocumentData = {
              isDirty: true,
              isNew: true,
              isActive: true,
              isDeleted: false,
              userProfileId: userProfileID,
              documentName: fileName.name,
              documentTypeId: userDocumentTypeId,
              blobURL: modifiedBlobUrl,
              userDocumentId: "00000000-0000-0000-0000-000000000000",
            };
            const addUploadResponse = await postData(
              "UserDocument",
              userDocumentData
            );
          }
        }
        setLoading(false);
        navigate("/profile");
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validExtensions = [
      ".xlsx",
      ".xls",
      ".doc",
      ".docx",
      ".ppt",
      ".pptx",
      ".txt",
      ".pdf",
      ".csv",
    ];

    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFileError("");
      let totalSize = 0;
      const filesArray: any[] = [];
      let allFilesValid = true; // Flag to check if all files have valid extensions

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const fileExtension = file.name
          .slice(file.name.lastIndexOf("."))
          .toLowerCase();

        if (!validExtensions.includes(fileExtension)) {
          setErrorMessage(`${t("profile.invalidFileFormat")}: ${file.name}`);
          allFilesValid = false;
          break;
        }
        totalSize += file.size;
        filesArray.push(file);
      }

      if (allFilesValid) {
        const totalSizeInMB = totalSize / (1024 * 1024);
        if (totalSizeInMB <= 500) {
          //setFile(filesArray.join(", "));
          setFile(filesArray);
          setErrorMessage(undefined);
          onFileUploadChange(true); // Notify parent that a file has been selected
        } else {
          setErrorMessage(`${t("profile.totalFileSizeExceed500")}`);
          setFile(undefined);
          onFileUploadChange(false); // Notify parent that no valid file is selected
        }
      } else {
        setFile(undefined);
        onFileUploadChange(false); // Notify parent that no valid file is selected
      }
    } else {
      setFile(undefined);
      setErrorMessage(undefined);
      onFileUploadChange(false); // Notify parent that no file is selected
    }
  };

  useEffect(() => {
    if (startFileUpload) {
      if (file) {
        const filesArray = file;
        if (filesArray.length > 0) {
          handleDocumentUpload(filesArray);
        }
      }
      setStartFileUpload(false);
    }
  }, [startFileUpload]);

  const handleDelete = (index: number) => {
    if (file) {
      const updatedFiles = [...file];
      updatedFiles.splice(index, 1);
      setFile(updatedFiles);
    }
  };
  return (
    <>
      <div className="">
        <Toast ref={toast} />
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-7">
              <div className="flex flex-column gap-3">
                <h2 className="m-0 font-bold text-base">{heading}</h2>
                <div className="relative md:w-16rem custom-file-upload">
                  <input
                    type="file"
                    id={`fileInput1-${heading}`}
                    accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.csv"
                    multiple
                    onChange={handleChange}
                  />
                  <label
                    htmlFor={`fileInput1-${heading}`}
                    className="flex flex-column align-items-center justify-content-center gap-2 p-3"
                  >
                    <span className="bg-brand-500 border-circle flex align-items-center justify-content-center">
                      <FeatherIcon
                        name="plus"
                        size={32}
                        color="var(--icon-white)"
                        onClick={undefined}
                      />
                    </span>
                    <span className="text-sm font-normal">
                      {t("profile.browseDosPlacehoder")} <br />
                      {t("profile.maxFileSize")}
                    </span>
                  </label>
                </div>
                {fileError && (
                  <div key="fileError" className="p-error font-bold">
                    {fileError}
                  </div>
                )}
                {errorMessage && (
                  <div key="errorMessage" className="p-error font-bold">
                    {errorMessage}
                  </div>
                )}
                {file && (
                  <div className="flex flex-column gap-3">
                    {file.map((fileName: any, index: number) => (
                      <div
                        key={index}
                        className="flex align-items-center gap-3"
                      >
                        <a className="m-0 flex align-items-center gap-2 underline text-charcoal">
                          <img src={pdfIcon} alt="PDF" />
                          <span>{fileName.name}</span>
                        </a>
                        <Button
                          text
                          className="p-button-plain gap-2 text-delete underline"
                          onClick={() => handleDelete(index)}
                          aria-label={t("profile.delete")}
                        >
                          <FeatherIcon
                            name="trash-2"
                            size={20}
                            onClick={undefined}
                          />
                          {/* <span>{t("profile.delete")}</span> */}
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-4 justify-content-end cardFooter">
            <Button
              label={t("profile.save")}
              className="button-md"
              onClick={handleSaveButtonClick}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default DocumentUpload;

import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel";
import { useDispatch } from "react-redux";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import FeatherIcon from "../common/FeatherIconComponent";
import { fetchData } from "../../services/apiService";
import { useLanguageContext } from "../LanguageContext";
import { resetMemberRequest } from "../../slices/createMemberRequestSlice";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import Table from "../common/Table";
import { MenuItem } from "primereact/menuitem";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import { usePermission } from "../auth/Authorization";
import { BASE_URL } from "../../config";
import axiosInstance from "../../services/axiosInstance";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { focusOnField } from "../../utils/focusField";
import { clearAllSessionStorage } from "../../utils/storage";
import ResignMembers from "./ResignMembers";

const AllRequests: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [toDateError, setToDateError] = useState("");
  const [userProfileId, setUserProfileId] = useState<string>("");
  const [search, setSearch] = useState("");
  const [committees, setRequestsList] = useState([]);
  const [reappointmentList, setReappointmentList] = useState([]);
  const [mycommittees, setMyCommittees] = useState<any>([]);
  const [memberStatus, setMemberStatus] = useState([]);
  const [memberPosition, setMemberPosition] = useState([]);
  const [coiData, setCoiData] = useState([]);
  const [allRequests, setAllRequests] = useState<boolean>(false);
  const [selectedCOIs, setSelectedCOIs] = useState<any[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<any[]>([]);
  const [selectedCommittees, setSelectedCommittees] = useState<any[]>([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [selectedMemberStatuses, setSelectedMemberStatuses] = useState<any[]>(
    []
  );
  const [dateCheckboxChecked, setDateCheckboxChecked] =
    useState<boolean>(false);
  const [dateActivationFrom, setDateActivationFrom] = useState<Date | null>(
    null
  );
  const [committeeCheckboxChecked, setCommitteeCheckboxChecked] =
    useState<boolean>(false);
  const [coiCheckboxChecked, setCoiCheckboxChecked] = useState<boolean>(false);
  const [positionCheckboxChecked, setPositionCheckboxChecked] =
    useState<boolean>(false);
  const [rejectionDateCheckboxChecked, setRejectionDateCheckboxChecked] =
    useState<boolean>(false);
  const [dateActivationTo, setDateActivationTo] = useState<Date | null>(null);
  const [rejectionDate, SetRejectionDate] = useState<Date | null>(null);
  const [memberStatusCheckboxChecked, setMemberStatusCheckboxChecked] =
    useState<boolean>(false);
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const [t, i18n] = useTranslation("member");
  const { selectedLanguage } = useLanguageContext();
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
  const [defaultFilter, setDefaultFilter] = useState<boolean>(false);
  const { id: submittedRequestId } = useParams();

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const dispatch = useDispatch();

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel);
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  useEffect(() => {
    if (submittedRequestId) {
      setMemberStatusCheckboxChecked(true);
      setDefaultFilter(true);
    } else {
      setMemberStatusCheckboxChecked(false);
    }
  }, []);

  useEffect(() => {
    if (memberStatusCheckboxChecked && defaultFilter) {
      fetchFilteredData();
    }
  }, [memberStatusCheckboxChecked, defaultFilter, userRole, userProfileId]);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  const fetchDataAndSetState = async (
    endpoint: string,
    setStateCallback: any,
    transformData = (data: any) => data
  ) => {
    try {
      const response = await fetchData(endpoint);
      const data = transformData(response.Collection);

      setStateCallback(data);
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}:`, error);
    }
  };

  const fetchAndFilterMembershipStatus = async () => {
    await fetchDataAndSetState(
      "MembershipStatus/GetAll",
      setMemberStatus,
      (data) => {
        const removedStatusNames = [
          "Retired",
          "Current",
          "Terminate",
          "Resign",
        ];
        const filteredData = data
          .filter(
            (status: any) => !removedStatusNames.includes(status.StatusName)
          )
          .map((status: any) =>
            status.StatusName === "Convert to Record"
              ? { ...status, StatusName: "Converted to Record" }
              : status
          );
        const newArray = filteredData.map((item: any) => ({
          name: item.StatusName,
          code: item.Id,
        }));
        return newArray;
      }
    );
  };

  useEffect(() => {
    fetchDataAndSetState("Committee/GetAll", setMyCommittees);
    fetchDataAndSetState("CategoryOfInterest/GetAll", setCoiData);
    fetchDataAndSetState("MembershipPosition/GetAll", setMemberPosition);
    fetchAndFilterMembershipStatus();
  }, []);

  const handleDateSelect = (date: Date, control: string) => {
    focusOnField(control);
    if (control === "dateActivationFrom") {
      if (dateActivationTo && date > dateActivationTo) {
        setToDateError(`${t("member.fromDateBeforeToDate")}`);
        setDateActivationFrom(null);
      } else {
        setToDateError("");
        setDateActivationFrom(date);
      }
    } else if (control === "dateActivationTo") {
      if (dateActivationFrom && date < dateActivationFrom) {
        setToDateError(`${t("member.toDateAfterFromDate")}`);
        setDateActivationTo(null);
      } else {
        setToDateError("");
        setDateActivationTo(date);
      }
    } else if (control === "rejectionDate") {
      SetRejectionDate(date);
    }
  };

  const handleCheckboxChange =
    (checkbox: string) => async (e: CheckboxChangeEvent) => {
      const isChecked = e.checked !== undefined ? e.checked : false;
      switch (checkbox) {
        case "committee":
          setCommitteeCheckboxChecked(isChecked);
          break;
        case "coi":
          setCoiCheckboxChecked(isChecked);
          break;
        case "position":
          setPositionCheckboxChecked(isChecked);
          break;
        case "memberStatus":
          setMemberStatusCheckboxChecked(isChecked);
          break;
        case "date":
          setDateCheckboxChecked(isChecked);
          break;
        case "rejectiondate":
          setRejectionDateCheckboxChecked(isChecked);
          break;
        case "allRequests":
          setAllRequests(isChecked);
          setLoading(true);
          if (isChecked) {
            try {
              const allCommitteeResponse = await fetchData(
                "MembershipRequest/Search?PageIndex=-1"
              );
              const result = formatResponseData(allCommitteeResponse);
              setRequestsList(result);
            } catch (err) {
              console.error(err);
            } finally {
              setLoading(false);
            }
          } else {
            if (userRole == "Staff") {
              const responseData = await fetchData(
                `MembershipRequest/Search?Filters[0].Key=StaffId&Filters[0].Value=${userProfileId}&PageIndex=-1`
              );
              const result = formatResponseData(responseData);
              setRequestsList(result);
              setLoading(false);
            } else if (userRole == "Member") {
              const responseData = await fetchData(
                `MembershipRequest/Search?Filters[0].Key=UserProfileId&Filters[0].Value=${userProfileId}&PageIndex=-1`
              );
              const result = formatResponseData(responseData);
              setRequestsList(result);
              setLoading(false);
            }
          }
          break;
        default:
          break;
      }
    };

  const formatResponseData = (responseData: any) => {
    const collection = Array.isArray(responseData?.Collection)
      ? responseData.Collection
      : [];

    let modifiedData = collection.sort((a: any, b: any) => {
      const dateA: any = new Date(a.CreatedDate as any);
      const dateB: any = new Date(b.CreatedDate as any);
      return dateB - dateA;
    });

    // Filter out 'Draft' records for 'Staff' and 'Admin' roles
    if (userRole === "Staff" || userRole === "Admin") {
      modifiedData = modifiedData.filter(
        (item: any) => item.MembershipStatus?.StatusName !== "Draft"
      );
    }

    const sortedData = modifiedData.map((item: any) => ({
      ...item,
      MemberPositionName: item.MembershipPosition
        ? item.MembershipPosition.PositionName
        : null,
      CommitteeName: item.Committee ? item.Committee.Name : null,
      CategoryName: item.CategoryOfInterest
        ? item.CategoryOfInterest.CategoryName
        : null,
      CreatedDate: item.CreatedDate
        ? new Date(item.CreatedDate).toLocaleDateString()
        : null,
      RequestStatus: item.MembershipStatus
        ? item.MembershipStatus.StatusName
        : null,
      sessionKey: "memberShipId",
      statusName: item.MembershipStatus
        ? item.MembershipStatus.StatusName
        : null,
      sessionKeyStatus: "statusName",
      MemberName:
        item?.UserProfile?.Firstname + " " + item?.UserProfile?.Lastname,
    }));

    return sortedData;
  };

  const fetchFilteredData = async (event?: any) => {
    const queryParams: { [key: string]: any } = {};
    const filters: { Key: string; Value: string }[] = [];
    if (userRole !== "" && userProfileId !== "") {
      if (committeeCheckboxChecked) {
        const committeeIds = selectedCommittees.map(
          (committee) => committee.Id
        );
        filters.push({ Key: "CommitteeId", Value: committeeIds.join(",") });
      }
      if (coiCheckboxChecked) {
        const coiIds = selectedCOIs.map((coi) => coi.Id);
        filters.push({ Key: "CategoryOfInterestId", Value: coiIds.join(",") });
      }
      if (positionCheckboxChecked) {
        const positionIds = selectedPositions.map((position) => position.Id);
        filters.push({
          Key: "MembershipPositionId",
          Value: positionIds.join(","),
        });
      }
      if (memberStatusCheckboxChecked) {
        if (defaultFilter) {
          if (memberStatus && memberStatus.length) {
            const storedValue = submittedRequestId;
            const filteredStatus: any = memberStatus.find(
              (item: { name: string; code: string }) =>
                item.code === storedValue
            );
            if (filteredStatus) {
              setSelectedMemberStatuses([
                { name: filteredStatus.name, code: filteredStatus.code },
              ]);
              const memberStatusIds = filteredStatus.code;
              filters.push({
                Key: "MembershipStatusId",
                Value: memberStatusIds,
              });
            }
          }
        } else {
          const memberStatusIds = selectedMemberStatuses.map(
            (status) => status.value
          );
          filters.push({
            Key: "MembershipStatusId",
            Value: memberStatusIds.join(","),
          });
          setDefaultFilter(false);
        }
      }
      if (dateCheckboxChecked) {
        const fromDateStr = formatDate(dateActivationFrom);
        const toDateStr = formatDate(dateActivationTo);
        filters.push({
          Key: "DateFilter",
          Value: `${fromDateStr}#${toDateStr}`,
        });
      }
      if (rejectionDateCheckboxChecked) {
        const rejectionDateStr = formatDate(rejectionDate);
        filters.push({ Key: "RejectionDate", Value: `${rejectionDateStr}` });
      }
      switch (userRole) {
        case "Staff":
          filters.push({ Key: "StaffId", Value: userProfileId });
          break;
        case "Member":
          filters.push({ Key: "UserProfileId", Value: userProfileId });
          break;
        default:
          break;
      }
      filters.forEach((filter, index) => {
        queryParams[`Filters[${index}].Key`] = filter.Key;
        queryParams[`Filters[${index}].Value`] = filter.Value;
      });
      queryParams["PageIndex"] = -1;
      queryParams["SearchText"] = "";
      queryParams["SortBy"] = "";
      queryParams["SortDir"] = "asc";
      const apiUrl = `${BASE_URL}/MembershipRequest/Search?${new URLSearchParams(queryParams).toString()}`;
      try {
        const response = await axiosInstance.get(apiUrl);
        if (response.status === 200) {
          const filteredData = response.data;
          const sortedData = formatResponseData(filteredData);
          setFilteredMembers(sortedData);
          setIsFilterApplied(true);
        } else {
          console.error("Failed to fetch filtered data");
        }
      } catch (error) {
        console.error("Error fetching filtered data:", error);
      } finally {
        setLoading(false);
      }
      if (event) {
        overlayPanelRef.current?.toggle(event);
        setMemberStatusCheckboxChecked(false);
        setSelectedMemberStatuses([]);
      }
      if (defaultFilter) {
        localStorage.removeItem("pendingRequest");
      }

      setCommitteeCheckboxChecked(false);
      setCoiCheckboxChecked(false);
      setPositionCheckboxChecked(false);
      setDateCheckboxChecked(false);
      setRejectionDateCheckboxChecked(false);
      setSelectedCommittees([]);
      setSelectedCOIs([]);
      setSelectedPositions([]);
      setDateActivationFrom(null);
      setDateActivationTo(null);
      SetRejectionDate(null);
    }
  };

  const formatDate = (date: Date | null) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const responseData = await fetchData(
          "UserProfile/GetCurrentUserProfile"
        );
        setUserProfileId(responseData.Id);
      } catch (error) {
        console.error("Error fetching current user profile:", error);
      }
    };
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    if (!userProfileId) return;
    const fetchDataByRole = async () => {
      try {
        let responseData;
        switch (userRole) {
          case "Staff":
            responseData = await fetchData(
              `MembershipRequest/Search?Filters[0].Key=StaffId&Filters[0].Value=${userProfileId}&PageIndex=-1`
            );
            break;
          case "Admin":
            responseData = await fetchData(
              `MembershipRequest/Search?&PageIndex=-1`
            );
            break;
          case "Member":
            responseData = await fetchData(
              `MembershipRequest/Search?Filters[0].Key=UserProfileId&Filters[0].Value=${userProfileId}&PageIndex=-1`
            );
            break;
          default:
            break;
        }
        const sortedData = formatResponseData(responseData);
        const filteredRequestList = sortedData?.filter(
          (record: { IsResignRequest: boolean }) =>
            record.IsResignRequest === false
        );
        setRequestsList(filteredRequestList);

        // for reappointments

        const filteredReappoinmentList = sortedData?.filter(
          (record: { isMemberReappoinmentRequest: boolean }) =>
            record.isMemberReappoinmentRequest === true
        );
        setReappointmentList(filteredReappoinmentList);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDataByRole();
  }, [userRole, userProfileId]);

  React.useEffect(() => {
    dispatch(resetMemberRequest());
    clearAllSessionStorage();
  }, []);

  const handleViewMember = () => {
    return () => {
      if (sessionStorage.getItem("statusName") === "Draft") {
        navigate("/member/create");
      } else {
        navigate("/member/request-view");
      }
    };
  };

  const actionItems: MenuItem[] = [
    {
      label: t("member.viewEditRequest"),
      command: handleViewMember(),
    },
  ];
  const breadcumbItems = [
    { label: t("member.dashboard"), url: "/dashboard" },
    { label: t("member.membershipRequests") },
  ];
  const navigate = useNavigate();
  return (
    <>
      <div className="">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("member.membershipRequests")}
          </h1>
          <p className="text-base font-normal m-0">
            {t("member.myrequestsListingHelp")}
          </p>
        </div>
        <div className="tabs-wrap">
          <TabView scrollable>
            <TabPanel header={t("member.new")}>
              <div className="flex flex-wrap md:flex-nowrap md:align-items-center py-3 gap-3 w-full">
                <IconField iconPosition="left" className="w-full md:w-16rem">
                  <InputIcon className="pi pi-search"> </InputIcon>
                  <InputText
                    id="searchCommittee"
                    className="w-full"
                    placeholder={"Search"}
                    aria-label={t("record.searchCommittee")}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </IconField>

                <div className="flex gap-3 w-full md:w-auto align-items-center ml-auto">
                  {userRole === "Staff" ? (
                    <label
                      htmlFor="allRequestsFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer flex align-items-center"
                    >
                      <Checkbox
                        inputId="allRequestsFilter"
                        onChange={handleCheckboxChange("allRequests")}
                        checked={allRequests}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("member.allRequests")}
                    </label>
                  ) : null}
                  <div className="relative">
                    <Button
                      className="button-md gap-1"
                      severity="secondary"
                      onClick={toggleFilterPanel}
                      aria-label={t("member.filter")}
                    >
                      <FeatherIcon name="filter" size={20} color="inherit" />
                      <span className="font-bold text-capitalize hidden md:flex">
                        {t("member.filter")}
                      </span>
                    </Button>
                    {/* OverlayPanel for Filter */}
                    <OverlayPanel
                      ref={overlayPanelRef}
                      onHide={() => setShowFilterPanel(false)}
                      appendTo={"self"}
                      dismissable
                      className="p-overlaypanel-filter left-auto md:right-0 top-100"
                      aria-labelledby="filter-heading"
                      role="dialog"
                    >
                      <div className="flex flex-column gap-1" tabIndex={0}>
                        <p
                          id="filter-heading"
                          className="text-lg font-bold m-0 p-3"
                        >
                          {t("member.filter")}
                        </p>
                        <div className="filter-wrap">
                          <div className="">
                            <label
                              htmlFor="committeeNameFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="committeeNameFilter"
                                onChange={handleCheckboxChange("committee")}
                                checked={committeeCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("member.committeeName")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full relative">
                              <MultiSelect
                                // appendTo="self"
                                value={selectedCommittees}
                                onChange={(e) => setSelectedCommittees(e.value)}
                                options={mycommittees}
                                optionLabel="Name"
                                filter
                                placeholder={t("member.committeeName")}
                                maxSelectedLabels={3}
                                className="w-full"
                                aria-label={t("member.committeeName")}
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="memberPositionFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="memberPositionFilter"
                                onChange={handleCheckboxChange("position")}
                                checked={positionCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("member.memberPosition")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full relative">
                              <MultiSelect
                                // appendTo="self"
                                value={selectedPositions}
                                onChange={(e) => setSelectedPositions(e.value)}
                                options={memberPosition}
                                optionLabel="PositionName"
                                filter
                                placeholder={t("member.memberPosition")}
                                maxSelectedLabels={3}
                                className="w-full"
                                aria-label={t("member.memberPosition")}
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="categoryOfInterestFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="categoryOfInterestFilter"
                                onChange={handleCheckboxChange("coi")}
                                checked={coiCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("member.categoryOfInterest")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full relative">
                              <MultiSelect
                                // appendTo="self"
                                value={selectedCOIs}
                                onChange={(e) => setSelectedCOIs(e.value)}
                                options={coiData}
                                optionLabel="CategoryName"
                                filter
                                placeholder={t("member.categoryOfInterest")}
                                maxSelectedLabels={3}
                                className="w-full"
                                aria-label={t("member.categoryOfInterest")}
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="memberStatusFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="memberStatusFilter"
                                onChange={handleCheckboxChange("memberStatus")}
                                checked={memberStatusCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("member.memberStatus")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full relative">
                              <MultiSelect
                                // appendTo="self"
                                value={selectedMemberStatuses}
                                onChange={(e) =>
                                  setSelectedMemberStatuses(e.value)
                                }
                                options={memberStatus}
                                optionLabel="name"
                                filter
                                placeholder={t("member.memberStatus")}
                                maxSelectedLabels={3}
                                className="w-full"
                                aria-label={t("member.memberStatus")}
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="requestDateFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="requestDateFilter"
                                onChange={handleCheckboxChange("date")}
                                checked={dateCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("member.requestDate")}
                            </label>
                            <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                              <Calendar
                                className="custom-datepicker"
                                inputId="dateActivationFrom"
                                onChange={(e) =>
                                  handleDateSelect(
                                    e.value as Date,
                                    "dateActivationFrom"
                                  )
                                }
                                value={dateActivationFrom}
                                dateFormat="mm/dd/yy"
                                tabIndex={0}
                                ariaLabel={t("member.fromDate")}
                                placeholder={t("member.fromDate")}
                              />
                              <Calendar
                                className="custom-datepicker"
                                inputId="dateActivationTo"
                                onChange={(e) =>
                                  handleDateSelect(
                                    e.value as Date,
                                    "dateActivationTo"
                                  )
                                }
                                value={dateActivationTo}
                                dateFormat="mm/dd/yy"
                                tabIndex={0}
                                ariaLabel={t("member.toDate")}
                                placeholder={t("member.toDate")}
                              />
                              {toDateError && (
                                <div className="text-red-500">
                                  {toDateError}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="rejectionDateFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="rejectionDateFilter"
                                onChange={handleCheckboxChange("rejectiondate")}
                                checked={rejectionDateCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("member.rejectionDate")}
                            </label>
                            <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                              <Calendar
                                className="custom-datepicker"
                                inputId="rejectionDate"
                                onChange={(e) =>
                                  handleDateSelect(
                                    e.value as Date,
                                    "rejectionDate"
                                  )
                                }
                                value={rejectionDate}
                                dateFormat="mm/dd/yy"
                                tabIndex={0}
                                ariaLabel={t("member.rejectionDate")}
                                placeholder={t("member.rejectionDate")}
                              />
                            </div>
                          </div>
                        </div>
                        <Button
                          className="button-md w-full justify-content-center"
                          onClick={(e: any) => fetchFilteredData(e)}
                        >
                          {t("member.applyFilter")}
                        </Button>
                      </div>
                    </OverlayPanel>
                  </div>
                  {userRole === "Member" && (
                    <Button
                      className="button-md gap-1 justify-content-center w-full md:w-auto"
                      onClick={() => navigate("/member/create")}
                    >
                      <FeatherIcon name="plus" size={20} color="inherit" />
                      <span className="font-bold">
                        {t("member.newMembershipRequest")}
                      </span>
                    </Button>
                  )}
                </div>
              </div>
              <Table
                loading={loading}
                products={
                  isFilterApplied
                    ? filteredMembers?.filter((item: any) => {
                        const memberName = "" || item.MemberName;
                        const committeeName = "" || item.CommitteeName;
                        const MembershipRequestNumber =
                          "" || item.MembershipRequestNumber;
                        return (
                          search === "" ||
                          committeeName
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          memberName
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          MembershipRequestNumber.toLowerCase().includes(
                            search.toLowerCase()
                          )
                        );
                      })
                    : committees?.filter((item: any) => {
                        const memberName = "" || item.MemberName;
                        const committeeName = "" || item.CommitteeName;
                        const MembershipRequestNumber =
                          "" || item.MembershipRequestNumber;
                        return (
                          search === "" ||
                          committeeName
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          memberName
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          MembershipRequestNumber.toLowerCase().includes(
                            search.toLowerCase()
                          )
                        );
                      })
                }
                columns={[
                  {
                    field: "MembershipRequestNumber",
                    header: t("member.request") + "#",
                    link: "/member/request-view",
                  },
                  { field: "MemberName", header: t("member.memberName") },
                  {
                    field: "CommitteeName",
                    header: t("member.committeeApplied"),
                  },
                  {
                    field: "MemberPositionName",
                    header: t("member.positionApplied"),
                  },
                  {
                    field: "CategoryName",
                    header: t("member.categoryofIntrest"),
                  },
                  {
                    field: "CreatedDate",
                    header: t("member.requestDate"),
                    sortable: true,
                  },
                  { field: "statusName", header: t("member.status") },
                  {
                    field: "action",
                    header: t("member.action"),
                    sortable: false,
                  },
                ]}
                actionItems={actionItems}
              />
            </TabPanel>
            <TabPanel header={t("member.reappointments")}>
              <div className="flex flex-wrap md:flex-nowrap md:align-items-center py-3 gap-3 w-full">
                <IconField iconPosition="left" className="w-full md:w-16rem">
                  <InputIcon className="pi pi-search"> </InputIcon>
                  <InputText
                    id="searchCommittee"
                    className="w-full"
                    placeholder={"Search"}
                    aria-label={t("record.searchCommittee")}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </IconField>
              </div>
              <Table
                loading={loading}
                products={reappointmentList?.filter((item: any) => {
                  const memberName = "" || item.MemberName;
                  const committeeName = "" || item.CommitteeName;
                  const MembershipRequestNumber =
                    "" || item.MembershipRequestNumber;
                  return (
                    search === "" ||
                    committeeName
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    memberName.toLowerCase().includes(search.toLowerCase()) ||
                    MembershipRequestNumber.toLowerCase().includes(
                      search.toLowerCase()
                    )
                  );
                })}
                columns={[
                  {
                    field: "MembershipRequestNumber",
                    header: t("member.request") + "#",
                    link: "/member/request-view",
                  },
                  { field: "MemberName", header: t("member.memberName") },
                  {
                    field: "CommitteeName",
                    header: t("member.committeeApplied"),
                  },
                  {
                    field: "MemberPositionName",
                    header: t("member.positionApplied"),
                  },
                  {
                    field: "CategoryName",
                    header: t("member.categoryofIntrest"),
                  },
                  {
                    field: "CreatedDate",
                    header: t("member.requestDate"),
                    sortable: true,
                  },
                  { field: "statusName", header: t("member.status") },
                  {
                    field: "action",
                    header: t("member.action"),
                    sortable: false,
                  },
                ]}
                actionItems={actionItems}
              />
            </TabPanel>
            <TabPanel header={t("member.termination")}>
              {t("member.termination")}
            </TabPanel>
            <TabPanel header={t("member.resignation")}>
              <ResignMembers />
            </TabPanel>
            <TabPanel header={t("member.all")}>{t("member.all")}</TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default AllRequests;

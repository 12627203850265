import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Nullable } from "primereact/ts-helpers";
import FeatherIcon from "../common/FeatherIconComponent";
import { useLanguageContext } from "../LanguageContext";
interface Option {
  name: string;
  code: string;
}
const CommitteeAddMeeting: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const [dateActivation, setDateActivation] = useState(null);
  const [value, setValue] = useState("");
  const [time, setTime] = useState<Nullable<Date>>(null);
  const navigate = useNavigate();

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain"
              aria-label={t("committee.back")}
              onClick={() => navigate("/committee/details")}
            >
              <FeatherIcon name="arrow-left" size={20} />
            </Button>
            <h1 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.addMeeting")}
            </h1>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="meetingName"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.meetingName")}
                  </label>
                  <InputText
                    id="meetingName"
                    type="text"
                    placeholder={t("committee.meetingName")}
                    className="w-full"
                    value=""
                  />
                </div>
              </div>
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="meetingType"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    {t("committee.meetingType")}
                  </label>
                  <Dropdown
                    inputId="meetingType"
                    name="meetingType"
                    value={selectedOption}
                    onChange={(e: DropdownChangeEvent) =>
                      setSelectedOption(e.value)
                    }
                    options={options}
                    optionLabel="name"
                    placeholder={t("committee.selectAnOption")}
                  />
                </div>
              </div>

              {/* Start day 1 */}
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="startDate"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.startDate")}
                  </label>
                  <Calendar
                    appendTo="self"
                    inputId="startDate"
                    value={dateActivation}
                    dateFormat="mm/dd/yy"
                    className="custom-datepicker"
                    placeholder={t("committee.startDate")}
                  />
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="startTime"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.startTime")}
                  </label>
                  <Calendar
                    id="startTime"
                    placeholder={t("committee.startTime")}
                    value={time}
                    onChange={(e) => setTime(e.value)}
                    timeOnly
                  />
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="meetingLocation"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.meetingLocation")}
                  </label>
                  <InputText
                    id="meetingLocation"
                    type="text"
                    placeholder={t("committee.meetingLocation")}
                    className="w-full"
                    value=""
                  />
                  <span className="text-sm">
                    {t("committee.meetingLocationHelp")}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="meetingAgenda"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.meetingAgenda")}
                  </label>
                  <InputTextarea
                    id="meetingAgenda"
                    value={value}
                    placeholder={t("committee.meetingAgenda")}
                    onChange={(e) => setValue(e.target.value)}
                    rows={5}
                    cols={30}
                  />
                </div>
                <div className="pt-0 col-12">
                  <Button text className="p-button-plain gap-2 pl-0 underline">
                    <FeatherIcon name="paperclip" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("committee.addAttachments")}
                    </span>
                  </Button>
                </div>
              </div>

              <div className="flex flex-column gap-2 w-full mb-5">
                <div className="flex flex-column gap-2">
                  <div className="grid grid-xl mt-0">
                    <span
                      className={`flex xl:col-3 lg:col-4 md:col-5 col-5 py-1 font-bold text-capitalize`}
                      id="selectCommittee"
                    >
                      {t("committee.selectCommittee")}
                    </span>
                    <span
                      className={`flex xl:col-3 lg:col-3 md:col-5 col-5 py-1 font-bold text-capitalize`}
                      id="members"
                    >
                      {t("committee.members")}
                    </span>
                  </div>
                  <div className="flex flex-column w-full">
                    <div className={`grid grid-xl align-items-center idx`}>
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                        <Dropdown
                          inputId="selectCommittee-0"
                          name="selectCommittee-0"
                          value={selectedOption}
                          onChange={(e: DropdownChangeEvent) =>
                            setSelectedOption(e.value)
                          }
                          options={options}
                          optionLabel="name"
                          placeholder={t("committee.selectAnOption")}
                          aria-labelledby="selectCommittee"
                        />
                      </div>
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                        <Dropdown
                          inputId="members-0"
                          name="members-0"
                          value={selectedOption}
                          onChange={(e: DropdownChangeEvent) =>
                            setSelectedOption(e.value)
                          }
                          options={options}
                          optionLabel="name"
                          placeholder={t("committee.selectAnOption")}
                          aria-labelledby="members"
                        />
                      </div>
                      <div className="w-auto p-0">
                        <Button
                          text
                          className="p-button-plain p-2 text-delete"
                          aria-label={t("committee.delete")}
                        >
                          <FeatherIcon name="trash-2" size={20} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button text className="p-button-plain gap-1 pl-0 underline">
                    <FeatherIcon name="plus" size={20} />
                    <span className="font-bold text-capitalize">
                      {t("committee.addCommittee")}
                    </span>
                  </Button>
                </div>
              </div>

              {/* Day 2 */}
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="startDate-2"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.day")} 2
                  </label>
                  <Calendar
                    appendTo="self"
                    inputId="startDate-2"
                    value={dateActivation}
                    dateFormat="mm/dd/yy"
                    className="custom-datepicker"
                    placeholder={t("committee.startDate")}
                  />
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="startTime-2"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.startTime")}
                  </label>
                  <Calendar
                    inputId="startTime-2"
                    placeholder={t("committee.startTime")}
                    value={time}
                    onChange={(e) => setTime(e.value)}
                    timeOnly
                  />
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="meetingLocation-2"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.meetingLocation")}
                  </label>
                  <InputText
                    id="meetingLocation-2"
                    type="text"
                    placeholder={t("committee.meetingLocation")}
                    className="w-full"
                    value=""
                  />
                  <span className="text-sm">
                    {t("committee.meetingLocationHelp")}
                  </span>
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="meetingAgenda-2"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.meetingAgenda")}
                  </label>
                  <InputTextarea
                    id="meetingAgenda-2"
                    value={value}
                    placeholder={t("committee.meetingAgenda")}
                    onChange={(e) => setValue(e.target.value)}
                    rows={5}
                    cols={30}
                  />
                </div>
                <div className="pt-0 col-12">
                  <Button text className="p-button-plain gap-2 pl-0 underline">
                    <FeatherIcon name="paperclip" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("committee.addAttachments")}
                    </span>
                  </Button>
                </div>
              </div>

              <div className="flex flex-column gap-2 w-full mb-5">
                <div className="flex flex-column gap-2">
                  <div className="grid grid-xl mt-0">
                    <span
                      className={`flex xl:col-3 lg:col-4 md:col-5 col-5 py-1 font-bold text-capitalize`}
                      id="selectCommittee-2"
                    >
                      {t("committee.selectCommittee")}
                    </span>
                    <span
                      className={`flex xl:col-3 lg:col-3 md:col-5 col-5 py-1 font-bold text-capitalize`}
                      id="members-2"
                    >
                      {t("committee.members")}
                    </span>
                  </div>
                  <div className="flex flex-column w-full">
                    <div className={`grid grid-xl align-items-center idx`}>
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                        <Dropdown
                          inputId="selectCommittee-2-0"
                          name="selectCommittee-2-0"
                          value={selectedOption}
                          onChange={(e: DropdownChangeEvent) =>
                            setSelectedOption(e.value)
                          }
                          options={options}
                          optionLabel="name"
                          placeholder={t("committee.selectAnOption")}
                          aria-labelledby="selectCommittee-2"
                        />
                      </div>
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                        <Dropdown
                          inputId="members-2-0"
                          name="members-2-0"
                          value={selectedOption}
                          onChange={(e: DropdownChangeEvent) =>
                            setSelectedOption(e.value)
                          }
                          options={options}
                          optionLabel="name"
                          placeholder={t("committee.selectAnOption")}
                          aria-labelledby="members-2"
                        />
                      </div>
                      <div className="w-auto p-0">
                        <Button
                          text
                          className="p-button-plain p-2 text-delete"
                          aria-label={t("committee.delete")}
                        >
                          <FeatherIcon name="trash-2" size={20} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button text className="p-button-plain gap-1 pl-0 underline">
                    <FeatherIcon name="plus" size={20} />
                    <span className="font-bold text-capitalize">
                      {t("committee.addCommittee")}
                    </span>
                  </Button>
                </div>
              </div>

              {/* Add day button */}
              <div>
                <Button text className="p-button-plain gap-1 pl-0 underline">
                  <FeatherIcon name="plus" size={20} />
                  <span className="font-bold text-capitalize">
                    {t("committee.addDay")}
                  </span>
                </Button>
              </div>
            </div>
          </div>
          <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-4 justify-content-end cardFooter">
            <Button
              label={t("committee.cancel")}
              className="button-md"
              severity="secondary"
              type="button"
              onClick={() => navigate("/committee/details")}
            />
            <Button
              label={t("committee.addMeeting")}
              className="button-md"
              type="button"
              onClick={() => navigate("/committee/meeting")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommitteeAddMeeting;

import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import axoisInstanceIdentity from "../../services/axiosInstanceIdentity";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import {
  AllFormPermissionDetails,
  AllFormPermissionResponse,
  FormPermission,
  FormPermissionDetails,
  Role,
  RoleFormPermission,
} from "./member-role";
import { FilterMatchMode } from "primereact/api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  addAllFormPermissions,
  addRoleFormPermissions,
} from "../../slices/userRolePermissions";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
const MemberRole = () => {
  const [t, i18n] = useTranslation("configuration");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [selectedPermissions, setSelectedPermissions] = useState<any>(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedForm, setSelectedForm] = useState(null);
  const [allForms, setAllForms] = useState<any>([]);
  const [rolePermissionData, setRolePermissionData] = useState<any>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [loading, setLoading] = useState(true);
  const toast = useRef<any>(null);
  const dispatch = useDispatch();
  const fetchAllRoles = async () => {
    try {
      const allRoles: any = await axoisInstanceIdentity.get(`Role/GetAll`);
      const roleOptions = allRoles.data.Collection.map((role: any) => {
        return {
          ...role,
          label: role.RoleName,
          value: role.RoleName,
        };
      });
      setRoles(roleOptions);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const tableData: any = useSelector(
    (state: RootState) => state.addUserRolePermission
  );

  const fetchAllFormData = async () => {
    try {
      const allFormPermission: AllFormPermissionResponse =
        await axoisInstanceIdentity.get(`/FormPermission/GetAll`);
      dispatch(addAllFormPermissions(allFormPermission?.data));
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  const fetchAllRoleFormData = async () => {
    try {
      const rolePermissions: AllFormPermissionDetails =
        await axoisInstanceIdentity.get(
          `/RoleFormPermission/GetListByParentId/${selectedRole}`
        );
      dispatch(addRoleFormPermissions(rolePermissions.data.Collection));
    } catch (err) {
      console.error("Error fetching data:", err);
      dispatch(addRoleFormPermissions([]));
    }
  };

  useEffect(() => {
    fetchAllRoles();
    fetchAllFormData();
  }, []);

  const renderTableData = () => {
    const allForms = tableData.allFormPermissions?.map(
      (data: FormPermission) => {
        return {
          id: Math.random(),
          name: data.Form.Name,
        };
      }
    );

    const filteredObj = tableData.allFormPermissions?.map(
      (data: FormPermission) => {
        const roleData = tableData.allRoleFormPermissions?.find(
          (roleData: FormPermissionDetails) =>
            roleData.FormPermissionId === data.Id
        );
        const isChecked = Boolean(roleData);
        return {
          uid: Math.random(),
          Id: isChecked ? roleData?.Id : data.Id,
          RoleId: isChecked ? roleData?.RoleId : selectedRole,
          FormPermissionId: isChecked ? roleData?.FormPermissionId : data.Id,
          FormName: data.Form.Name,
          PermissionName: data.Permission.Name,
          IsChecked: isChecked,
        };
      }
    );
    const enabledPermission = filteredObj.filter(
      (ele: any) => ele.IsChecked === true
    );

    setSelectedPermissions(enabledPermission);
    setRolePermissionData(filteredObj);
    setAllForms(allForms);
    setLoading(false);
  };

  const handleRoleDropdownChange = async (selectedItem: any) => {
    setSelectedValue(selectedItem.value);
    const selectedRoleItem = roles.find(
      (obj: any) => obj.RoleName === selectedItem.value
    );
    setSelectedRole(selectedRoleItem ? selectedRoleItem.Id : "");
  };

  useEffect(() => {
    fetchAllRoleFormData();
  }, [selectedRole]);
  useEffect(() => {
    renderTableData();
  }, [tableData]);
  const selectedFormTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const formOptionTemplate = (option: any) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };
  const onRowSelect = async (payload: RoleFormPermission) => {
    try {
      const bulkUpload = await axoisInstanceIdentity.post(
        `/RoleFormPermission`,
        payload
      );
      if (bulkUpload.status === 201) {
        toast.current.show({
          severity: "info",
          summary: t("configuration.productSelected"),
          message: t("configuration.permissionEnabled"),
          life: 3000,
          content: (props: any) => (
            <>
              <div
                className="flex flex-column align-items-left"
                style={{ flex: "1" }}
              >
                <div className="font-medium text-lg my-3 text-900">
                  {props.message.message}
                </div>
              </div>
            </>
          ),
        });
        fetchAllRoleFormData();
      }
    } catch (err) {
      console.error("Error data:", err);
    }
  };

  const onRowUnselect = async (payload: string) => {
    try {
      const bulkUpload = await axoisInstanceIdentity.delete(
        `/RoleFormPermission/${payload}`
      );
      if (bulkUpload.status === 204) {
        toast.current.show({
          severity: "info",
          summary: t("configuration.productSelected"),
          message: t("configuration.permissionDisabled"),
          life: 3000,
          content: (props: any) => (
            <>
              <div
                className="flex flex-column align-items-left"
                style={{ flex: "1" }}
              >
                <div className="font-medium text-lg my-3 text-900">
                  {props.message.message}
                </div>
              </div>
            </>
          ),
        });
        fetchAllRoleFormData();
      }
    } catch (err) {
      console.error("Error data:", err);
    }
  };
  return (
    <div className="card bg-white w-full mb-5 shadow-md">
      <div className="flex flex-row gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
        <Dropdown
          inputId="MasterType"
          value={selectedValue}
          options={roles}
          optionLabel="label"
          onChange={handleRoleDropdownChange}
          aria-label={t("configuration.selectUserRole")}
          placeholder={t("configuration.selectUserRole")}
          className="w-full"
        />
        {selectedValue && (
          <Dropdown
            inputId="MasterType"
            value={selectedForm}
            options={allForms}
            optionLabel="name"
            onChange={(e) => {
              setSelectedForm(e.value);
              const value = e.value.name;
              let _filters = { ...filters };
              _filters["global"].value = value;
              setFilters(_filters);
            }}
            filter
            valueTemplate={selectedFormTemplate}
            itemTemplate={formOptionTemplate}
            aria-label={t("configuration.selectForm")}
            placeholder={t("configuration.selectForm")}
            className="w-full"
          />
        )}
      </div>
      <div>
        <Toast ref={toast} />
        <DataTable
          key={rolePermissionData}
          sortOrder={1}
          stripedRows
          showGridlines
          value={selectedRole ? rolePermissionData : []}
          paginator
          rows={10}
          filters={filters}
          loading={loading}
          emptyMessage={t("configuration.noDataFound")}
          selectionMode={"checkbox"}
          selection={selectedPermissions}
          onSelectionChange={(e) => {
            setSelectedPermissions(e.value);
          }}
          dataKey="uid"
          onRowSelect={(e) => {
            const payload = {
              roleId: e.data.RoleId,
              formPermissionId: e.data.FormPermissionId,
            };
            onRowSelect(payload);
          }}
          onRowUnselect={(e) => {
            onRowUnselect(e.data.Id);
          }}
        >
          <Column
            key="IsChecked"
            selectionMode="multiple"
            field="IsChecked"
            headerStyle={{ width: "3rem", visibility: "hidden" }}
            body={(rowData) => {
              return (
                <Checkbox
                  id={`checkbox-${rowData.id}`}
                  checked={rowData.IsChecked}
                  className="mr-2"
                />
              );
            }}
          />
          <Column
            key="FormName"
            field={"FormName"}
            header={t("configuration.formName")}
          />

          <Column
            key="PermissionName"
            field={"PermissionName"}
            body={(rowData) => {
              return rowData.PermissionName;
            }}
            header={t("configuration.permissionName")}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default MemberRole;

import React, { useState, useEffect, ComponentType } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useLoadingContext } from "../Layout";
import axoisInstance from "../../services/axiosInstance";

interface WithLoaderProps {
  // You can define any specific props needed here
}

const withLoader = <P extends object>(
  WrappedComponent: ComponentType<P & WithLoaderProps>
) => {
  return (props: P) => {
    const { setLoading } = useLoadingContext();
    const [activeRequests, setActiveRequests] = useState(0);

    useEffect(() => {
      const requestInterceptor = axoisInstance.interceptors.request.use(
        (config: any) => {
          setActiveRequests((prevCount) => prevCount + 1);
          return config;
        },
        (error) => {
          setActiveRequests((prevCount) => prevCount - 1);
          return Promise.reject(error);
        }
      );

      const responseInterceptor = axoisInstance.interceptors.response.use(
        (response: AxiosResponse) => {
          setActiveRequests((prevCount) => prevCount - 1);
          return response;
        },
        (error) => {
          setActiveRequests((prevCount) => prevCount - 1);
          return Promise.reject(error);
        }
      );

      return () => {
        axoisInstance.interceptors.request.eject(requestInterceptor);
        axoisInstance.interceptors.response.eject(responseInterceptor);
      };
    }, []);

    useEffect(() => {
      setLoading(activeRequests > 0);
    }, [activeRequests, setLoading]);

    return <WrappedComponent {...(props as P)} />;
  };
};

export default withLoader;

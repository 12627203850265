// Ballot Status
export const BALLOT_CLOSE = "Closed";
export const BALLOT_OPEN = "Open";
export const BALLOT_OUT_FOR_BALLOT = "Out for Ballot";
export const BALLOT_DRAFT = "Draft";
export const BALLOT_CANCELLED = "Cancelled";
// Ballot Record Status
export const BALLOT_RECORD_CLOSE = "Close";
export const BALLOT_RECORD_OPEN = "Open";
export const BALLOT_RECORD_OUT_FOR_BALLOT = "Out for ballot";
export const BALLOT_RECORD_DRAFT = "Draft";

export const MEMBERSHIP_PROFILE_KEY = "membershipprofilesid";
export const BALLOT_STATUS_KEY = "ballotstatusid";

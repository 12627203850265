import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { fetchData, putData } from "../../services/apiService";
import { useNavigate, useLocation } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { usePermission } from "../auth/Authorization";
import { focusOnErrorField } from "../../utils/focusError";
import { focusOnField } from "../../utils/focusField";
import { Nullable } from "primereact/ts-helpers";
import { InputTextarea } from "primereact/inputtextarea";

interface Option {
  name: string;
  code: string;
}

interface MembershipPosition {
  Id: string;
  PositionName: string;
}

interface CategoryOfInterest {
  Id: string;
  CategoryName: string;
}

interface Committee {
  Id: string;
  Name: string;
  Status: { Name: string };
}

interface RowData {
  CommitteeName: string;
  StaffName: string;
  CategoryOfInterest: string;
  OpenPositions: string;
  PositionOpenfrom: string;
  NumberOfPositions: number;
  statusName: string;
  StaffId: string;
  OpenPositionId: string;
  CommitteeId: string;
  CategoryOfInterestId: string;
  PositionFilled: number;
}

const EditOpenPosition = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const [committees, setCommittees] = useState([]);
  const [selectedCommittee, setSelectedCommittee] = useState<Committee>();
  const toast = useRef<Toast>(null);
  const [comment, setComment] = useState<string>("");
  const [selectedPosition, setSelectedPosition] =
    useState<MembershipPosition>();
  const [selectedCoi, setSelectedCoi] = useState<CategoryOfInterest>();
  const [openPositionStatusId, setOpenPositionStatusId] = useState<string>("");
  const [matchedCommentId, setMatchedCommentId] = useState(null);
  const [noOfPositions, setNoOfPositions] = useState<number>(0);
  const [coiData, setCoiData] = useState([]);
  const [memberPosition, setMemberPosition] = useState([]);
  const location = useLocation();
  const rowData = location.state?.rowData;
  const openPositionId = rowData.OpenPositionId;

  const fetchComments = async () => {
    try {
      const response = await fetchData("OpenPositionComment/GetAll");
      const commentData = response.Collection;
      const matchedComment = commentData.find(
        (item: any) => item.OpenPositionId === rowData.OpenPositionId
      );
      if (matchedComment) {
        const matchedCommentId = matchedComment.Id;
        const fetchCommentById = await fetchData(
          "OpenPositionComment",
          matchedCommentId
        );
        setComment(fetchCommentById.Comment);
        setMatchedCommentId(matchedCommentId);
      } else {
        console.log("No matching comment found");
      }
    } catch (error) {
      console.error("Error fetching comment Data", error);
    }
  };

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  const [date, setDate] = useState<Nullable<Date>>(null);

  const handleCancel = () => {
    navigate("/positions");
  };

  const handleToasterMessage = (msg: any) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (rowData.statusName === "Open" || rowData.statusName === "Draft") {
      if (noOfPositions < rowData.PositionFilled) {
        handleToasterMessage(
          "No of Positions should be greater than the Positions Filled"
        );
        return;
      }
      if (rowData.date && rowData.date < new Date()) {
        handleToasterMessage("Date should be a future Date");
        return;
      }
    }
    const OpenPositionStatus = await fetchData("OpenPositionStatus/GetAll");
    const statusResponse = OpenPositionStatus.Collection;

    const matchedStatus = statusResponse.find(
      (status: any) => status.Name === rowData.statusName
    );
    const openPositionStatusId = matchedStatus.Id;
    const data = {
      committeeId: selectedCommittee?.Id,
      membershipPositionId: selectedPosition?.Id,
      numberOfPositions: noOfPositions,
      categoryOfInterestId: selectedCoi?.Id,
      positionOpenFrom: date ? date.toISOString() : null,
      openPositionStatusId: openPositionStatusId,
    };
    const response = await putData("OpenPosition", openPositionId, data);
    setTimeout(() => {
      navigate("/positions/");
    }, 3000);
    const commentData = {
      comment: comment,
      openPositionId: openPositionId,
      openPositionStatusId: openPositionStatusId,
    };
    if (matchedCommentId) {
      const commentResponse = await putData(
        "OpenPositionComment",
        matchedCommentId,
        commentData
      );
    } else {
    }
    setTimeout(() => {
      navigate("/positions/");
    }, 3000);
  };

  const handleCommitteeChange = (e: DropdownChangeEvent) => {
    const selectedCommittee = e.value;
    setSelectedCommittee(selectedCommittee);
  };

  useEffect(() => {
    fetchCommittees();
    fetchMembershipPosition();
    fetchComments();
    fetchCategoryOfInterest();
  }, []);

  const fetchCommittees = async () => {
    try {
      const response = await fetchData("Committee/GetAll");
      const committeesData = response.Collection;
      const filteredCommittees = committeesData.filter(
        (committee: any) => committee.Status.Name !== "Draft"
      );

      setCommittees(filteredCommittees);
    } catch (error) {
      console.error("Error fetching committees:", error);
    }
  };
  const fetchMembershipPosition = async () => {
    try {
      const response = await fetchData("MembershipPosition/GetAll");
      const membershipPositionData = response.Collection;
      setMemberPosition(membershipPositionData);
    } catch (error) {
      console.error("Error fetching Membership Position:", error);
    }
  };
  const fetchCategoryOfInterest = async () => {
    try {
      const response = await fetchData("CategoryOfInterest/GetAll");
      const categoryOfInterestData = response.Collection;
      setCoiData(categoryOfInterestData);
    } catch (error) {
      console.error("Error fetching Category Of Interest:", error);
    }
  };
  useEffect(() => {
    if (rowData) {
      const {
        CommitteeName,
        OpenPositions,
        CategoryOfInterest,
        NumberOfPositions,
        PositionOpenfrom,
      } = rowData;

      const selectedCommittee = committees.find(
        (committee: any) => committee.Name === CommitteeName
      );
      setSelectedCommittee(selectedCommittee);

      const selectedPosition = memberPosition.find(
        (position: any) => position.PositionName === OpenPositions
      );
      setSelectedPosition(selectedPosition);

      const selectedCoi = coiData.find(
        (coi: any) => coi.CategoryName === CategoryOfInterest
      );
      setSelectedCoi(selectedCoi);

      setNoOfPositions(NumberOfPositions);
      setDate(new Date(PositionOpenfrom));
    }
  }, [rowData, committees, memberPosition, coiData]);

  return (
    <>
      <div className="">
        <Toast ref={toast} />
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              aria-label={t("committee.back")}
              onClick={() => navigate("/positions/")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("committee.openPositions")}
              </h1>
            </div>
          </div>
          <form onSubmit={handleSave}>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-3 w-full">
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-6 lg:col-8 md:col-12 col-12">
                    <label
                      htmlFor="selectCommittee"
                      className={`block font-bold text-capitalize text-input-label}`}
                    >
                      {t("committee.selectCommittee")}*
                    </label>

                    <Dropdown
                      inputId="selectCommittee"
                      name="selectCommittee"
                      value={selectedCommittee}
                      onChange={handleCommitteeChange}
                      options={committees}
                      optionLabel="Name"
                      placeholder="Select an Option"
                      filter
                      filterBy="Name"
                      disabled={rowData ? true : false}
                    />
                  </div>
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="selectPosition"
                      className={`block font-bold text-capitalize text-input-label}`}
                    >
                      {t("committee.selectPosition")}*
                    </label>

                    <Dropdown
                      inputId="selectPosition"
                      name="selectPosition"
                      value={selectedPosition}
                      onChange={(e: DropdownChangeEvent) =>
                        setSelectedPosition(e.value)
                      }
                      options={memberPosition}
                      optionLabel="PositionName"
                      placeholder="Select an Option"
                      disabled={rowData ? true : false}
                    />
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="noOfPositions"
                      className={`block font-bold text-capitalize text-input-label}`}
                    >
                      {t("committee.noOfPositions")}*
                    </label>
                    <InputText
                      id="noOfPositions"
                      name="noOfPositions"
                      value={noOfPositions.toString()}
                      onChange={(e) => {
                        setNoOfPositions(
                          e.target.value ? parseInt(e.target.value, 10) : 0
                        );
                      }}
                      type="number"
                      placeholder={t("committee.noOfPositions")}
                      className="w-full"
                      disabled={
                        rowData.statusName !== "Open" &&
                        rowData.statusName !== "Draft"
                      }
                      min="0"
                    />
                  </div>
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="categoryOfInterest"
                      className={`block font-bold text-capitalize text-input-label}`}
                    >
                      {t("committee.categoryOfInterest")}*
                    </label>

                    <Dropdown
                      inputId="categoryOfInterest"
                      name="categoryOfInterest"
                      value={selectedCoi}
                      onChange={(e: DropdownChangeEvent) =>
                        setSelectedCoi(e.value)
                      }
                      options={coiData}
                      optionLabel="CategoryName"
                      placeholder="Select an Option"
                      disabled={rowData ? true : false}
                    />
                  </div>
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="positionOpenFrom"
                      className="block font-bold text-capitalize text-input-label"
                    >
                      {t("committee.positionOpenFrom")}*
                    </label>
                    <Calendar
                      appendTo="self"
                      inputId="positionOpenFrom"
                      aria-describedby="startDateError"
                      name="positionOpenFrom"
                      value={date}
                      onChange={(e) => setDate(e.value)}
                      placeholder={t("committee.positionOpenFrom")}
                      dateFormat="mm/dd/yy"
                      className="custom-datepicker"
                      minDate={new Date(rowData.PositionOpenfrom)}
                      disabled={
                        (rowData.statusName !== "Open" &&
                          rowData.statusName !== "Draft") ||
                        (rowData.PositionOpenfrom &&
                          new Date(rowData.PositionOpenfrom) <
                            new Date(new Date().setHours(0, 0, 0, 0)))
                      }
                    />
                  </div>
                </div>

                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-6 lg:col-8 md:col-12 col-12">
                    <label
                      htmlFor="comments"
                      className="block font-bold text-capitalize text-input-label"
                    >
                      {t("committee.comments")}
                    </label>
                    <InputTextarea
                      id="comments"
                      name="charterName"
                      placeholder={t("committee.comments")}
                      value={comment}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setComment(e.target.value)
                      }
                      rows={3}
                      cols={50}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap align-items-center px-5 py-3 border-top-1 border-gray-200 gap-3 md:gap-4 justify-content-between cardFooter">
              <Button
                label={t("committee.cancel")}
                className="button-md ml-auto w-auto"
                severity="secondary"
                onClick={handleCancel}
              />
              <Button
                label={t("committee.save")}
                className="button-md w-auto"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditOpenPosition;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CreateRecordApiData {
  createRecord: Record<string, any>;
  configureRecord: Record<string, any>;
  addCommitteeInRecordRequest: Record<string, any>;
  loading: boolean;
}

const initialState: CreateRecordApiData = {
  createRecord: {},
  configureRecord: {},
  addCommitteeInRecordRequest: {},
  loading: false,
};

const createRecordSlice = createSlice({
  name: "Record",
  initialState,
  reducers: {
    createRecordRequest: (
      state,
      action: PayloadAction<Record<string, any>>,
    ) => {
      state.createRecord = { ...state.createRecord, ...action.payload };
      state.loading = false;
    },
    configureRecordRequest: (
      state,
      action: PayloadAction<Record<string, any>>,
    ) => {
      state.configureRecord = { ...state.configureRecord, ...action.payload };
      state.loading = false;
    },
    addCommitteeInRecordRequest: (
      state,
      action: PayloadAction<Record<string, any>>,
    ) => {
      state.addCommitteeInRecordRequest = {
        ...state.addCommitteeInRecordRequest,
        ...action.payload,
      };
      state.loading = false;
    },
    reset: () => initialState,
  },
});

export const {
  createRecordRequest,
  configureRecordRequest,
  addCommitteeInRecordRequest,
  reset,
} = createRecordSlice.actions;
export default createRecordSlice.reducer;

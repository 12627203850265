import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomStepWizard from "../../common/CustomStepWizard";
import { useLanguageContext } from "../../LanguageContext";

interface CreateBallotHeaderProps {
  activeStep: number;
}

const BallotHeader: React.FC<CreateBallotHeaderProps> = ({
  activeStep,
}) => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const stepItems = [
    {
      icon: "cached",
      label: t("ballot.selectRecords"),
    },
    {
      icon: "cached",
      label: t("ballot.ballotsDetails"),
    },
    {
      icon: "cached",
      label: t("ballot.committee"),
    },
    {
      icon: "cached",
      label: t("ballot.preview"),
    },
  ];
  return (
    <>
      <div className="mb-5">

        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("ballot.createNewBallot")}
          </h1>
          <p className="text-base font-normal m-0">
            Any supporting text for request listing page can go here
          </p>
        </div>
        <CustomStepWizard stepItems={stepItems} activeIndex={activeStep} />
      </div>
    </>
  );
};

export default BallotHeader;

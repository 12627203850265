import React, { useState } from "react";
import { Button } from "primereact/button";
import Input from "../../utils/Input";
import { IDENTITY_URL } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import { getTenantNameFromDomain } from "../../utils/utils";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const [t, i18n] = useTranslation("auth");
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors: Record<string, string> = {};

    // Validation
    if (!email.trim()) {
      errors.email = t("auth.email") + " " + t("auth.isRequired");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = t("auth.invalidEmailFormat");
    } else {
      await handleEmailSentAPI();
    }

    if (Object.keys(errors).length === 0) {
    } else {
      setFormErrors(errors);
    }
  };

  const handleEmailChange = (value: string) => {
    setFormErrors({});
    setEmail(value);
  };

  const handleEmailSentAPI = async () => {
    let requestBody: any = {
      email: email,
      TenantDomain: getTenantNameFromDomain(),
    };
    try {
      let emailVerifier = await fetch(
        `${IDENTITY_URL}/Account/ForgetPasswordEmailVerify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      navigate("/reset-password-email-sent", {
        state: { email: email },
      });
    } catch (err) {
      navigate("/reset-password-email-sent", {
        state: { email: email },
      });
    }
  };

  return (
    <div className="flex align-items-center justify-content-center align-self-center h-full p-3">
      <div className="surface-card p-fluid sm:px-4 md:px-8 shadow-2 border-round w-full loginCards">
        <div className="flex flex-column gap-6 md:p-8 p-5">
          <div className="flex flex-column gap-1">
            <h1 className="text-title display-sm font-bold m-0">
              {t("auth.forgotPassword")}
            </h1>
            <p className="text-help text-base font-normal m-0">
              {t("auth.dontWorryItHappens")}
            </p>
          </div>
          <div className="flex flex-column gap-4">
            <form onSubmit={handleSubmit} className="flex flex-column gap-6">
              <Input
                type="text"
                label={`${t("auth.emailAddress")}*`}
                value={email}
                name="email"
                onChange={handleEmailChange}
                placeholder={t("auth.enterEmailAddress")}
                errorMessage={formErrors.email}
              />
              <div className="flex gap-4">
                <Button
                  label={t("auth.backToLogin")}
                  className="w-full button-lg px-3"
                  severity="secondary"
                  onClick={() => navigate("/")}
                />
                <Button
                  onClick={(e) => handleSubmit}
                  type="submit"
                  label={t("auth.sendMeLink")}
                  className="w-full button-lg px-3"
                />
              </div>
            </form>

            <div>
              <span>{t("auth.dontHaveAccount")}?</span>
              <Link
  to="/sign-up"
  className="underline font-bold ml-2 text-link"
>
  {t("auth.createAccount")}
</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

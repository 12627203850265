import { Avatar } from 'primereact/avatar'
import { RemarksProps } from './type'

const RemarksSection = ({ comments }: RemarksProps) => {
    return (
        <div className="p-4 pl-4 md:pl-7 flex flex-column gap-3 surface-100">
            <div className="flex flex-wrap align-items-center justify-content-between py-3 gap-3">
                <div className="flex flex-row gap-2 align-items-center">
                    <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" size="large" shape="circle" className="flex-shrink-0" />
                    <div className="flex flex-column gap-1">
                        <h3 className="text-title text-base font-bold text-capitalize m-0">
                            {comments?.RemarkAddedByName}
                        </h3>
                    </div>
                </div>
            </div>
            <div>
                <p className="p-0">
                    {comments?.Remark}
                </p>
            </div>
        </div>
    )
}

export default RemarksSection
import React, { useEffect, useState } from "react";
import MemberConfigurationTab from "./MemberConfigurationTab";
import MemberConfigurationTabView from "./MemberConfigurationTabView";
import { fetchData } from "../../services/apiService";

interface MemberConfigurationToggleProps {
  setActiveIndex: (index: number) => void; // Define setActiveIndex prop
}

const MemberConfigurationToggle: React.FC<MemberConfigurationToggleProps> = ({
  setActiveIndex,
}) => {
  const [committeeData, setCommitteeData] = useState<any[]>([]);
  const [editClicked, setEditClicked] = useState<boolean>(false);

  //   const fetchCommitteeConfig = async () => {
  //     try {
  //       const responseData = await fetchData("CommitteeConfiguration/GetAll");
  //       const CommitteeConfigData = responseData?.Collection;
  //       setCommitteeData(CommitteeConfigData);
  //     } catch (error) {
  //       console.error("Error fetching committee configuration", error);
  //     }
  //   };

  //   useEffect(() => {
  //     fetchCommitteeConfig();
  //   }, []);

  const handleEditButtonClick = () => {
    setActiveIndex(1);
    setEditClicked(true);
  };

  return (
    <div>
      {/* {committeeData.length === 0 || editClicked ? ( */}
      {editClicked ? (
        <MemberConfigurationTab />
      ) : (
        // <MemberConfigurationTabView
        //   setActiveIndex={setActiveIndex}
        //   onEditButtonClick={handleEditButtonClick}
        // />
        // <MemberConfigurationTab />
        <MemberConfigurationTabView
          setActiveIndex={setActiveIndex}
          onEditButtonClick={handleEditButtonClick}
        />
      )}
    </div>
  );
};

export default MemberConfigurationToggle;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useNavigate } from "react-router-dom";
import { RadioButton } from "primereact/radiobutton";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import CommitteeSteps from "./CommitteeSteps";
import { RootState } from "../../store/store";
import { useLanguageContext } from "../LanguageContext";
import {
  addMembersInCommitteeRequest,
  categoryOfInteresetRequest,
  categoryOfInteresetReset,
  configureCommitteeRequest,
  createCommitteeRequest,
} from "../../slices/createCommitteeSlice";
import "./Committee.scss";
import {
  fetchData,
  getListByParentId,
  postData,
  putData,
} from "../../services/apiService";
import {
  formatCategoryOfInterest,
  setOptionsFromResponse,
  formatCongigurationCommitteeData,
  ConfigureCommitteeResponseFormter,
  getCommitteeData,
  setCategoryOfInterestData,
  deepClone,
} from "../../utils/utils";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { focusOnErrorField } from "../../utils/focusError";
import { focusOnField } from "../../utils/focusField";
import FeatherIcon from "../common/FeatherIconComponent";
import TooltipButton from "../common/TooltipButton";

interface CommitteeConfigurationObject {
  defaultParent: boolean;
  isNatureofCommitteeRestricted: boolean;
  isBalanceofInterestRequiredtoMaintained: boolean;
  acceptApplicationWithoutOpenPositions: boolean;
  isAllowMemberOtherCommitteAccess: boolean;
  balanceofInterest: string;
  committeeMinMember: string;
  committeeMaxMember: string;
  organisationMinMember?: string;
  organisationMaxMember?: string;
  organisationMemberThreshold?: string;
  msElig_SubTierCommitte_Months: string;
  msElig_SameCommitte_Officer_Months: string;
  msExpire_Officer_Tenure_Years: string;
  msExpire_Member_Tenure_Years: string;
  sendNotificationforMembershipExpiration: boolean;
  notifyStaffExpirationofMembership: boolean;
  notifyStaffExpirationofMembershipDays_First: string;
  notifyStaffExpirationofMembershipDays_Second: string;
  notifyStaffExpirationofMembershipDays_Third: string;
  notifyMemberExpirationofMembership: boolean;
  notifyMemberExpirationofMembershipDays_First: string;
  notifyMemberExpirationofMembershipDays_Second: string;
  notifyMemberExpirationofMembershipDays_Third: string;
  officerMaximumTermYears: string;
  applicationAutoWithdrawalMonths: string;
  notifyMemberLackofParticipation: boolean;
  numberofMeetingMissed: string;
  numberofBallotsMissed: string;
  totalMeetingMisssedPercentage: string;
  totalBallotMisssedPercentage: string;
  categoryOfInterestValue?: string;
  min?: string;
  max?: string;
  threshold?: string;
  configureCommitteeFlag?: boolean;
  membershipExpiresOn_All: string;
  minOverallThreshold?: string;
  maxOverallThreshold?: string;
  thresholdPercentage?: string;
  IsBalanceBasedOnCategoryofInterests: boolean;
  IsBalanceBasedOnOverAllThreshold: boolean;
  IsBalanceBasedOnRepresentationByOrg: boolean;
}
interface CategoryOfInterestObject {
  categoryOfInterestValue: any;
  min: string;
  max: string;
  threshold: string;
  CategoryOfInterestId?: string;
}

interface Option {
  id: any;
  name: string;
  CategoryOfInterestId?: string;
}
const CommitteeConfiguration: React.FC = () => {
  const initialConfiguration = {
    defaultParent: false,
    isNatureofCommitteeRestricted: false,
    isBalanceofInterestRequiredtoMaintained: false,
    acceptApplicationWithoutOpenPositions: false,
    isAllowMemberOtherCommitteAccess: false,
    balanceofInterest: "",
    committeeMinMember: "",
    committeeMaxMember: "",
    organisationMinMember: "",
    organisationMaxMember: "",
    organisationMemberThreshold: "",
    msElig_SubTierCommitte_Months: "",
    msElig_SameCommitte_Officer_Months: "",
    msExpire_Officer_Tenure_Years: "",
    msExpire_Member_Tenure_Years: "",
    sendNotificationforMembershipExpiration: true,
    notifyStaffExpirationofMembership: false,
    notifyStaffExpirationofMembershipDays_First: "",
    notifyStaffExpirationofMembershipDays_Second: "",
    notifyStaffExpirationofMembershipDays_Third: "",
    notifyMemberExpirationofMembership: false,
    notifyMemberExpirationofMembershipDays_First: "",
    notifyMemberExpirationofMembershipDays_Second: "",
    notifyMemberExpirationofMembershipDays_Third: "",
    officerMaximumTermYears: "",
    applicationAutoWithdrawalMonths: "",
    notifyMemberLackofParticipation: false,
    numberofMeetingMissed: "",
    numberofBallotsMissed: "",
    totalMeetingMisssedPercentage: "",
    totalBallotMisssedPercentage: "",
    membershipExpiresOn_All: "",
    minOverallThreshold: "",
    maxOverallThreshold: "",
    thresholdPercentage: "",
    IsBalanceBasedOnCategoryofInterests: false,
    IsBalanceBasedOnOverAllThreshold: false,
    IsBalanceBasedOnRepresentationByOrg: false,
  };
  const [committeeConfigurationObject, setcommitteeConfigurationObject] =
    useState<CommitteeConfigurationObject>(initialConfiguration);
  const dispatch = useDispatch();
  const createCommitteeData = useSelector(
    (state: RootState) => state.createCommittee
  );

  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: t("committee.dashboard"), url: "/dashboard" },
    { label: t("committee.committee"), url: "/committee" },
    { label: t("committee.createNewCommittee") },
  ];

  const [
    checkedBalanceBasedOnOverallThreshold,
    setCheckedBalanceBasedOnOverallThreshold,
  ] = useState<boolean | undefined>(false);
  const [
    checkedBalanceRepresentationByOrg,
    setCheckedBalanceRepresentationByOrg,
  ] = useState<boolean | undefined>(false);
  const [checkedBalanceBasedOnIndCoi, setCheckedBalanceBasedOnIndCoi] =
    useState<boolean | undefined>(false);

  const initialCategoryOfInterest = {
    categoryOfInterestValue: "",
    min: "",
    max: "",
    threshold: "",
  };

  const [categoryOfInterest, setCategoryOfInterest] = useState<
    CategoryOfInterestObject[]
  >([initialCategoryOfInterest]);

  const [categoryOfInterestError, setCategoryOfInterestError] = useState<
    Partial<CommitteeConfigurationObject>
  >({});
  const [editModeForConfigCommitte, setEditModeForConfigCommitte] =
    useState(false);
  const [categoryOfInterestOption, setCategoryOfInterestOption] = useState<
    Option[] | undefined
  >(undefined);
  const [selectedCategory, setSelectedCategory] = useState<
    Option[] | undefined
  >(undefined);

  const percent = 70; // Set your percentage here

  // Define the style object as a regular JavaScript object
  const circleStyle = {
    "--percent": `${percent}`, // Set the percentage as a custom CSS variable
  };
  const [dateActivation, setDateActivation] = useState(null);
  const [multiselectCOIError, setMultiselectCOIError] = useState<string | null>(
    null
  );
  const [value, setValue] = useState("");
  const [criteria, setCriteria] = useState<string>("");
  const navigate = useNavigate();
  const [errorBalanceRepresentationByOrg, setErrorBalanceRepresentationByOrg] =
    useState<Partial<CommitteeConfigurationObject>>({});
  const [
    errorBalanceBasedOnOverallThreshold,
    setErrorBalanceBasedOnOverallThreshold,
  ] = useState<Partial<CommitteeConfigurationObject>>({});
  const [error, setError] = useState<Partial<CommitteeConfigurationObject>>({});
  const [defaultParentCheck, setDefaultParentCheck] = useState<boolean>(true);

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [adminConfigFlag, setAdminConfigFlag] = useState<boolean>(false);

  const [
    defaultConfigurationFromParentCommitteeCheckbox,
    setDefaultConfigurationFromParentCommitteeCheckbox,
  ] = useState<boolean>(false);

  const [natureOfCommitteeCheckbox, setNatureOfCommitteeCheckbox] =
    useState<boolean>(false);

  const [
    acceptApplicationsWithoutOpenPositionCheckbox,
    setAcceptApplicationsWithoutOpenPositionCheckbox,
  ] = useState<boolean>(false);

  const [
    defaultApplicationConfigurationFromParentCommitteeCheckbox,
    setDefaultApplicationConfigurationFromParentCommitteeCheckbox,
  ] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isDirty) {
        const confirmationMessage = t("committee.unsavedChangeMsg");
        event.returnValue = confirmationMessage; // For older browsers
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);

  // useEffect(() => {
  //   if (committeeConfigurationObject.minOverallThreshold) {
  //     setCheckedBalanceBasedOnOverallThreshold(true);
  //   }
  //   if (committeeConfigurationObject.organisationMinMember) {
  //     setCheckedBalanceRepresentationByOrg(true);
  //   }
  //   if (categoryOfInterest[0]?.min) {
  //     setCheckedBalanceBasedOnIndCoi(true);
  //   }
  // }, [
  //   committeeConfigurationObject.Is,
  //   committeeConfigurationObject.organisationMinMember,
  //   categoryOfInterest,
  // ]);

  useEffect(() => {
    if (!committeeConfigurationObject.IsBalanceBasedOnOverAllThreshold) {
      setcommitteeConfigurationObject((prevData) => ({
        ...prevData,
        ["minOverallThreshold"]: "",
        ["maxOverallThreshold"]: "",
        ["thresholdPercentage"]: "",
      }));
    }
    if (!committeeConfigurationObject.IsBalanceBasedOnRepresentationByOrg) {
      setcommitteeConfigurationObject((prevData) => ({
        ...prevData,
        ["organisationMinMember"]: "",
        ["organisationMaxMember"]: "",
        ["organisationMemberThreshold"]: "",
      }));
    }
    // if (!checkedBalanceBasedOnIndCoi) {
    //   setCategoryOfInterestData([initialCategoryOfInterest]);
    // }
  }, [
    committeeConfigurationObject.IsBalanceBasedOnCategoryofInterests,
    committeeConfigurationObject.IsBalanceBasedOnOverAllThreshold,
    committeeConfigurationObject.IsBalanceBasedOnRepresentationByOrg,
  ]);

  const handleInputChange = (e: any) => {
    setError({});
    setErrorBalanceRepresentationByOrg({});
    setErrorBalanceBasedOnOverallThreshold({});
    let { name, value } = e.target;
    if (e.target.max) {
      if (parseInt(value) > parseInt(e.target.max)) {
        value = e.target.max;
      }
      if (parseInt(value) < parseInt(e.target.min)) {
        value = e.target.min;
      }
    }
    setcommitteeConfigurationObject((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsDirty(true);
  };
  const handleDefaultCommitte = async (Id: string) => {
    const parentCommitteeResponse = await fetchData(`Committee/${Id}`);

    const parentAutoFillData = ConfigureCommitteeResponseFormter(
      parentCommitteeResponse
    );

    setcommitteeConfigurationObject(parentAutoFillData);
    setcommitteeConfigurationObject((prev) => ({
      ...prev,
      defaultParent: true,
    }));
    if (
      setCategoryOfInterestData(
        parentCommitteeResponse.CommitteCategoryofInterests
      ).length < 1
    ) {
      setCategoryOfInterest([initialCategoryOfInterest]);
    } else {
      setCategoryOfInterest(
        setCategoryOfInterestData(
          parentCommitteeResponse.CommitteCategoryofInterests,
          true
        )
      );
      let parentCOIResponse = setCategoryOfInterestData(
        parentCommitteeResponse.CommitteCategoryofInterests,
        true
      );
      setCategoryOfInterest(separateCOIDataFromResponse(parentCOIResponse));

      setSelectedCategory(
        parentCOIResponse.map((item: any) => {
          return {
            ...item.categoryOfInterestValue,
            CategoryOfInterestId: item.categoryOfInterestValue.id,
          };
        })
      );
    }
  };

  const handleCheckboxChange = (e: any) => {
    setError({});
    const { name, checked } = e.target;
    setcommitteeConfigurationObject((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleRadioButton = (e: any) => {
    setError({});
    const { name, value } = e.target;
    setcommitteeConfigurationObject((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCategoryOfInterest = (e: any, idx: number) => {
    setCategoryOfInterestError({});
    let { name, value } = e.target;
    if (name !== "categoryOfInterestValue") {
      value = parseInt(value);
    }
    let updatedCategoryOfInterset = categoryOfInterest.map((data, i) =>
      i === idx ? { ...data, [name]: value } : data
    );

    setCategoryOfInterest(updatedCategoryOfInterset);
    dispatch(categoryOfInteresetRequest(updatedCategoryOfInterset));
  };

  const handleAddCategoryOfInterest = () => {
    setCategoryOfInterest([
      ...categoryOfInterest,
      {
        categoryOfInterestValue: "",
        min: "",
        max: "",
        threshold: "",
      },
    ]);

    // To focus on newly added row first element "categoryInterest"
    setTimeout(() => {
      focusOnField(`categoryInterest-${categoryOfInterest.length}`);
    }, 0); // Delay of 0 milliseconds
  };

  const AddCOIIdInOption = (COIOptions: any, COIWithID: any) => {
    let idToCategory: any = {};
    COIOptions.forEach((item: any) => {
      if (item.CategoryOfInterestId) {
        idToCategory[item.id] = item.CategoryOfInterestId;
      }
    });

    // Update getData with CategoryOfInterestId where ids match
    let updatedCOIData = COIWithID.map((item: any) => {
      let newItem = { ...item }; // Create a shallow copy of item
      if (item.id in idToCategory) {
        newItem.CategoryOfInterestId = idToCategory[item.id];
      }
      return newItem;
    });
    return updatedCOIData;
  };

  const handleConfigurationDropdownOptions = async (category?: any) => {
    const [CategoryOfInterestResponse] = await Promise.all([
      fetchData("CategoryOfInterest/GetAll"),
    ]);
    const CategoryOfInterestObject: Option[] = [
      ...setOptionsFromResponse(CategoryOfInterestResponse, "CategoryName"),
    ];
    if (category) {
      let updatedCOI = AddCOIIdInOption(category, CategoryOfInterestObject);
      setCategoryOfInterestOption(updatedCOI);
    } else {
      setCategoryOfInterestOption(CategoryOfInterestObject);
    }
  };
  const IntitialConfigurationCommitteeLableForValidation = [
    { name: "committeeMinMember", lable: "Committee Min Member" },
    { name: "committeeMaxMember", lable: "Committee Max Member" },
    //{ name: "msElig_SubTierCommitte_Months", lable: "SubTier Committe Months" },
    // {
    //   name: "msElig_SameCommitte_Officer_Months",
    //   lable: "Same Committee Officer Months",
    // },
    // {
    //   name: "msExpire_Officer_Tenure_Years",
    //   lable: "Expire Officer Tenure Years",
    // },
    // {
    //   name: "msExpire_Member_Tenure_Years",
    //   lable: "Expire Member Tenure Years",
    // },
    //{ name: "sendNotificationforMembershipExpiration", lable: "Notification" },

    { name: "officerMaximumTermYears", lable: "Officer Maximum Term Years" },
    {
      name: "applicationAutoWithdrawalMonths",
      lable: "Application Auto Withdrawal Months",
    },
    // {
    //   name: "membershipExpiresOn_All",
    //   lable: "Mebership Expires On ",
    // },
  ];

  const [
    configurationCommitteeLableForValidation,
    setConfigurationCommitteeLableForValidation,
  ] = useState(IntitialConfigurationCommitteeLableForValidation);

  const initialMemberNotification = [
    {
      name: "notifyMemberExpirationofMembershipDays_First",
      lable: "First Notification",
    },
    {
      name: "notifyMemberExpirationofMembershipDays_Second",
      lable: "Second Notification",
    },
    {
      name: "notifyMemberExpirationofMembershipDays_Third",
      lable: "Third Notification",
    },
  ];

  const initialStaffNotification = [
    {
      name: "notifyStaffExpirationofMembershipDays_First",
      lable: "First Notification",
    },
    {
      name: "notifyStaffExpirationofMembershipDays_Second",
      lable: "Second Notification",
    },
    {
      name: "notifyStaffExpirationofMembershipDays_Third",
      lable: "Third Notification",
    },
  ];

  const initialNotificationMemberLackofParticipation = [
    { name: "numberofMeetingMissed", lable: "Number of Meeting Missed" },
    { name: "numberofBallotsMissed", lable: "Number of Ballots Missed" },
    { name: "totalMeetingMisssedPercentage", lable: "Total Meeting Misssed" },
    { name: "totalBallotMisssedPercentage", lable: "Total Ballot Misssed" },
  ];

  const handleNotificationValidation = () => {
    const memberFlag: boolean =
      committeeConfigurationObject.notifyMemberExpirationofMembership;
    const staffFlag: boolean =
      committeeConfigurationObject.notifyStaffExpirationofMembership;
    const participationFlag: boolean =
      committeeConfigurationObject.notifyMemberLackofParticipation;

    memberFlag && staffFlag && participationFlag
      ? setConfigurationCommitteeLableForValidation([
          ...IntitialConfigurationCommitteeLableForValidation,
          ...initialMemberNotification,
          ...initialStaffNotification,
          ...initialNotificationMemberLackofParticipation,
        ])
      : memberFlag && staffFlag
        ? setConfigurationCommitteeLableForValidation([
            ...IntitialConfigurationCommitteeLableForValidation,
            ...initialMemberNotification,
            ...initialStaffNotification,
          ])
        : staffFlag && participationFlag
          ? setConfigurationCommitteeLableForValidation([
              ...IntitialConfigurationCommitteeLableForValidation,
              ...initialStaffNotification,
              ...initialNotificationMemberLackofParticipation,
            ])
          : memberFlag && participationFlag
            ? setConfigurationCommitteeLableForValidation([
                ...IntitialConfigurationCommitteeLableForValidation,
                ...initialMemberNotification,
                ...initialNotificationMemberLackofParticipation,
              ])
            : memberFlag
              ? setConfigurationCommitteeLableForValidation([
                  ...IntitialConfigurationCommitteeLableForValidation,
                  ...initialMemberNotification,
                ])
              : staffFlag
                ? setConfigurationCommitteeLableForValidation([
                    ...IntitialConfigurationCommitteeLableForValidation,
                    ...initialStaffNotification,
                  ])
                : participationFlag
                  ? setConfigurationCommitteeLableForValidation([
                      ...IntitialConfigurationCommitteeLableForValidation,
                      ...initialNotificationMemberLackofParticipation,
                    ])
                  : setConfigurationCommitteeLableForValidation([
                      ...IntitialConfigurationCommitteeLableForValidation,
                    ]);
  };

  useEffect(() => {
    handleNotificationValidation();
    if (!committeeConfigurationObject.notifyMemberExpirationofMembership) {
      setcommitteeConfigurationObject((prevData) => ({
        ...prevData,
        ["notifyMemberExpirationofMembershipDays_First"]: "",
        ["notifyMemberExpirationofMembershipDays_Second"]: "",
        ["notifyMemberExpirationofMembershipDays_Third"]: "",
      }));
    }
    if (!committeeConfigurationObject.notifyStaffExpirationofMembership) {
      setcommitteeConfigurationObject((prevData) => ({
        ...prevData,
        ["notifyStaffExpirationofMembershipDays_First"]: "",
        ["notifyStaffExpirationofMembershipDays_Second"]: "",
        ["notifyStaffExpirationofMembershipDays_Third"]: "",
      }));
    }
    if (!committeeConfigurationObject.notifyMemberLackofParticipation) {
      setcommitteeConfigurationObject((prevData) => ({
        ...prevData,
        ["numberofMeetingMissed"]: "",
        ["numberofBallotsMissed"]: "",
        ["totalMeetingMisssedPercentage"]: "",
        ["totalBallotMisssedPercentage"]: "",
      }));
    }
  }, [
    committeeConfigurationObject.notifyMemberExpirationofMembership,
    committeeConfigurationObject.notifyStaffExpirationofMembership,
    committeeConfigurationObject.notifyMemberLackofParticipation,
  ]);

  const categoryOfInterestLableForValidation = [
    { name: "categoryOfInterestValue", lable: "Category" },
    { name: "min", lable: "Min" },
    { name: "max", lable: "Max" },
    //{ name: "threshold", lable: "Threshold" },
  ];

  const balanceBasedOnOverallThresholdValidation = [
    { name: "minOverallThreshold", lable: "Min" },
    { name: "maxOverallThreshold", lable: "Max" },
    { name: "thresholdPercentage", lable: "Threshold" },
  ];

  const balanceRepresentationByOrgValidation = [
    { name: "organisationMaxMember", lable: "Organisation Max Member" },
    //{ name: "organisationMinMember", lable: "Organisation Min Member" },
    // {
    //   name: "organisationMemberThreshold",
    //   lable: "Organisation Member Threshold",
    // },
  ];

  const validationForBalanceRepresentationByOrg = (
    data: any,
    name: string,
    label: string
  ) => {
    let valid = true;
    const newError: any = {};
    if (
      data[name] === "" &&
      committeeConfigurationObject.IsBalanceBasedOnRepresentationByOrg
    ) {
      newError[name] = `${label} ${t("committee.isRequired")}`;
      valid = false;
    }
    setErrorBalanceRepresentationByOrg((prev) => ({
      ...prev,
      ...newError,
    }));
    return valid;
  };
  const validationForbalanceBasedOnOverallThreshold = (
    data: any,
    name: string,
    label: string
  ) => {
    let valid = true;
    const newError: any = {};
    if (
      data[name] === "" &&
      committeeConfigurationObject.IsBalanceBasedOnOverAllThreshold
    ) {
      newError[name] = `${label} ${t("committee.isRequired")}`;
      valid = false;
    }
    setErrorBalanceBasedOnOverallThreshold((prev) => ({
      ...prev,
      ...newError,
    }));
    return valid;
  };

  const fileterCOIDataWRTMultiSelect = (
    selectedCategory: any,
    categoryOfInterest: any
  ) => {
    let coiList: any = [...selectedCategory];
    let postData = [...categoryOfInterest];
    let coiMap = new Map(coiList.map((item: any) => [item.id, item]));

    let updatedPostData: any = [];
    postData.forEach((item: any) => {
      let coiItem: any = coiMap.get(item.categoryOfInterestValue.id);
      if (coiItem) {
        //  item.categoryOfInterestValue.name = coiItem.name;
        updatedPostData.push(item);
      } else {
        if (item.categoryOfInterestValue.name) {
          updatedPostData.push({
            min: 0,
            max: 0,
            threshold: 0,
            categoryOfInterestValue: {
              name: item.categoryOfInterestValue.name,
              id: item.categoryOfInterestValue.id,
            },
          });
        }
      }
    });

    coiList.forEach((coiItem: any) => {
      let found = updatedPostData.some(
        (item: any) => item.categoryOfInterestValue.id === coiItem.id
      );
      if (!found) {
        if (coiItem.name) {
          updatedPostData.push({
            min: 0,
            max: 0,
            threshold: 0,
            CategoryOfInterestId: coiItem?.CategoryOfInterestId
              ? coiItem?.CategoryOfInterestId
              : "",
            categoryOfInterestValue: {
              name: coiItem.name,
              id: coiItem.id,
            },
          });
        }
      }
    });

    return updatedPostData;
  };

  function separateCOIDataFromResponse(postData: any) {
    let coi: any = [];
    postData.forEach((item: any) => {
      let mutableObj = deepClone(item);
      if (item.CategoryOfInterestId) {
        mutableObj.categoryOfInterestValue.CategoryOfInterestId =
          item.CategoryOfInterestId;
      }
      coi.push(mutableObj);
    });
    return coi;
  }

  const multiselectCOIValidationLabel = [
    { name: "selectedCategory", lable: t("committee.categoryOfInterest") },
  ];

  const validationForMultiselectCOI = (
    data: any,
    name: string,
    label: string
  ) => {
    let valid = true;
    let newError = null;
    if (!data || !data[0]?.name) {
      newError = `${label} ${t("committee.isRequired")}`;
      valid = false;
    }
    setMultiselectCOIError(newError);
    return valid;
  };

  useEffect(() => {
    handleConfigurationDropdownOptions(selectedCategory);
  }, [selectedCategory]);

  const handleSaveAndContinue = async (
    e: any,
    saveAsDraft: string = "false"
  ) => {
    e.preventDefault();
    let isValid = configurationCommitteeLableForValidation
      .map((data) =>
        validationForCommitteeCreation(
          committeeConfigurationObject,
          data.name,
          data.lable
        )
      )
      .every((val) => val == true);
    let categoryValid = categoryOfInterestLableForValidation
      .map((data) =>
        validationForCatogoryOfInterest(
          categoryOfInterest,
          data.name,
          data.lable
        )
      )
      .every((val) => val == true);
    let balanceBasedOnOverallThresholdValid =
      balanceBasedOnOverallThresholdValidation
        .map((data) =>
          validationForbalanceBasedOnOverallThreshold(
            committeeConfigurationObject,
            data.name,
            data.lable
          )
        )
        .every((val) => val == true);
    let balanceRepresentationByOrgValid = balanceRepresentationByOrgValidation
      .map((data) =>
        validationForBalanceRepresentationByOrg(
          committeeConfigurationObject,
          data.name,
          data.lable
        )
      )
      .every((val) => val == true);
    let multiselectCOIValid = multiselectCOIValidationLabel
      .map((data) =>
        validationForMultiselectCOI(selectedCategory, data.name, data.lable)
      )
      .every((val) => val === true);
    if (
      isValid &&
      categoryValid &&
      balanceBasedOnOverallThresholdValid &&
      balanceRepresentationByOrgValid &&
      multiselectCOIValid
    ) {
      dispatch(configureCommitteeRequest(committeeConfigurationObject));
      let status = false;
      if (createCommitteeData.createCommittee.status === "Created") {
        status = true;
      }
      if (
        createCommitteeData.createCommittee.status === "Draft" ||
        saveAsDraft === "draft"
      ) {
        status = true;
      }
      const configurationCommitteeRequest =
        await formatCongigurationCommitteeData(
          committeeConfigurationObject,
          createCommitteeData.createCommittee,
          status,
          saveAsDraft
        );

      let multiselectCOI = fileterCOIDataWRTMultiSelect(
        selectedCategory,
        categoryOfInterest
      );

      const categoryOfInterestRequest = formatCategoryOfInterest(
        multiselectCOI,
        createCommitteeData.createCommittee.committeeId
      );

      const configurationCommitteeResponse = await putData(
        "Committee",
        createCommitteeData.createCommittee.committeeId,
        configurationCommitteeRequest
      );
      const categoryOfInterestResponse = await postData(
        "CommitteeCategoryOfInterest/BulkUpload",
        categoryOfInterestRequest
      );
      handleRefershCommitteMember();
      setIsDirty(false);
      if (editModeForConfigCommitte) {
        sessionStorage.setItem("redirectionPath", "config");
        sessionStorage.removeItem("editConfigCommittee");
        let committeeId = sessionStorage.getItem("committeeId");
        navigate(`/committee/details/${committeeId}`);
      } else if (saveAsDraft === "draft") {
        navigate("/committee");
      } else {
        navigate("/committee/members");
      }
    } else {
      setTimeout(() => {
        focusOnErrorField(".p-invalid");
      }, 100);
      console.log(error);
    }
  };
  const handleDeleteCategoryOfInterest = async (idx: number) => {
    if (categoryOfInterest.length > 1) {
      const updateCommitteMembers = [...categoryOfInterest];

      if (updateCommitteMembers[idx].CategoryOfInterestId) {
        const categoryOfInterestRequest = formatCategoryOfInterest(
          [updateCommitteMembers[idx]],
          createCommitteeData.createCommittee.committeeId,
          true
        );
        const categoryOfInterestResponse = await postData(
          "CommitteeCategoryOfInterest/BulkUpload",
          categoryOfInterestRequest
        );
      }
      updateCommitteMembers.splice(idx, 1);

      setCategoryOfInterest(updateCommitteMembers);
      dispatch(categoryOfInteresetReset());
      dispatch(categoryOfInteresetRequest(updateCommitteMembers));
    } else {
      console.log("atleat one");
    }
  };

  const notificationValidationCheck = (data: any, name: string) => {
    let isValid = true;

    if (
      name === "notifyStaffExpirationofMembershipDays_Third" ||
      name === "notifyMemberExpirationofMembershipDays_Third"
    ) {
      isValid = false;
    }
    if (
      name === "notifyStaffExpirationofMembershipDays_Second" ||
      name === "notifyMemberExpirationofMembershipDays_Second"
    ) {
      if (
        data["notifyStaffExpirationofMembershipDays_Third"] != "" ||
        data["notifyMemberExpirationofMembershipDays_Third"] != ""
      ) {
        isValid = true;
      } else {
        isValid = false;
      }
    }

    return isValid;
  };

  const validationForCommitteeCreation = (
    data: any,
    name: string,
    label: string
  ) => {
    let valid = true;
    const newError: any = {};

    if (data[name] === "" || data[name] === false || data[name] === null) {
      if (notificationValidationCheck(data, name)) {
        newError[name] = `${label} ${t("committee.isRequired")}`;
        valid = false;
      }
    }
    if (data[name] !== "") {
      if (name === "committeeMinMember" || name === "committeeMaxMember") {
        const minMember = parseInt(data["committeeMinMember"], 10);
        const maxMember = parseInt(data["committeeMaxMember"], 10);

        if (!isNaN(minMember) && !isNaN(maxMember)) {
          if (minMember > maxMember) {
            newError["committeeMaxMember"] = t(
              "committee.maximumGreaterMinimum"
            );
            valid = false;
          }
        }
      }
      if (
        name === "notifyStaffExpirationofMembershipDays_First" &&
        parseInt(data["notifyStaffExpirationofMembershipDays_First"]) <=
          parseInt(data["notifyStaffExpirationofMembershipDays_Second"])
      ) {
        newError[name] = t("committee.firstNotificationGreaterSecond");
        valid = false;
      }
      if (
        name === "notifyStaffExpirationofMembershipDays_Second" &&
        parseInt(data["notifyStaffExpirationofMembershipDays_Second"]) <=
          parseInt(data["notifyStaffExpirationofMembershipDays_Third"])
      ) {
        newError[name] = t("committee.secondNotificationGreaterThird");
        valid = false;
      }
      if (
        name === "notifyMemberExpirationofMembershipDays_First" &&
        parseInt(data["notifyMemberExpirationofMembershipDays_First"]) <=
          parseInt(data["notifyMemberExpirationofMembershipDays_Second"])
      ) {
        newError[name] = t("committee.firstNotificationGreaterSecond");
        valid = false;
      }
      // // Ensure the first notification is mandatory if any notifications are selected
      // if (
      //   data["notifyMemberExpirationofMembership"] &&
      //   (!data["notifyMemberExpirationofMembershipDays_First"] ||
      //     data["notifyMemberExpirationofMembershipDays_First"] === "")
      // ) {
      //   newError["notifyMemberExpirationofMembershipDays_First"] =
      //     "First Notification is mandatory";
      //   valid = false;
      // }

      // // Ensure the second notification is filled before the third notification
      // if (
      //   data["notifyMemberExpirationofMembershipDays_Third"] &&
      //   (!data["notifyMemberExpirationofMembershipDays_Second"] ||
      //     data["notifyMemberExpirationofMembershipDays_Second"] === "")
      // ) {
      //   newError["notifyMemberExpirationofMembershipDays_Second"] =
      //     "Second Notification must be filled before the Third Notification";
      //   valid = false;
      // }

      if (
        name === "notifyMemberExpirationofMembershipDays_Second" &&
        parseInt(data["notifyMemberExpirationofMembershipDays_Second"]) <=
          parseInt(data["notifyMemberExpirationofMembershipDays_Third"])
      ) {
        newError[name] = t("committee.secondNotificationGreaterThird");
        valid = false;
      }
    }

    setError((prev) => ({ ...prev, ...newError }));
    return valid;
  };

  const validationForCatogoryOfInterest = (
    data: any,
    name: string,
    label: string
  ) => {
    let valid = true;
    const newError: any = {};
    let dataObject = data[0];
    if (committeeConfigurationObject.IsBalanceBasedOnCategoryofInterests) {
      if (dataObject[name] !== "") {
        if (parseInt(dataObject["min"]) > parseInt(dataObject["max"])) {
          newError["max"] = t("committee.maximumGreaterMinimum");
          valid = false;
        }
      }
      if (
        dataObject[name] === "" ||
        dataObject[name] === false ||
        !dataObject[name] ||
        (Object.keys(dataObject[name]).includes("name") &&
          dataObject[name].name === undefined)
      ) {
        newError[name] = `${label} ${t("committee.isRequired")}`;
        valid = false;
      }
    }
    setCategoryOfInterestError((prev) => ({ ...prev, ...newError }));
    return valid;
  };

  useEffect(() => {
    handleConfigurationDropdownOptions();
  }, []);

  const handleMultiSelectCOI = (e: any) => {
    setMultiselectCOIError(null);
    setSelectedCategory(e.value);
  };

  const handleRefershCommitteMember = async (Id?: string) => {
    const [
      createCommitteeData,
      configureCommitteeData,
      committeeMemberData,
      categoryOfInterestData,
    ] = await getCommitteeData();
    dispatch(createCommitteeRequest(createCommitteeData));
    dispatch(configureCommitteeRequest(configureCommitteeData));
    dispatch(addMembersInCommitteeRequest(committeeMemberData));
    dispatch(categoryOfInteresetRequest(categoryOfInterestData));
    if (!adminConfigFlag) {
      setcommitteeConfigurationObject(configureCommitteeData);
    }
    setCategoryOfInterest(separateCOIDataFromResponse(categoryOfInterestData));
    if (categoryOfInterestData[0].categoryOfInterestValue) {
      setSelectedCategory(
        categoryOfInterestData.map((item: any) => {
          return {
            ...item.categoryOfInterestValue,
            CategoryOfInterestId: item.CategoryOfInterestId,
          };
        })
      );
    }
  };

  useEffect(() => {
    let committeeId = sessionStorage.getItem("committeeId");
    if (
      Object.keys(createCommitteeData.createCommittee).length < 1 &&
      committeeId
    ) {
      // handleDefaultCommitte(committeeId);

      handleRefershCommitteMember(committeeId);
      setDefaultParentCheck(false);
    } else if (
      committeeConfigurationObject.defaultParent &&
      defaultParentCheck &&
      Object.keys(createCommitteeData.configureCommittee).length < 1
    ) {
      handleDefaultCommitte(
        createCommitteeData.createCommittee.parentCommittee.id
      );
    } else if (!committeeConfigurationObject.defaultParent) {
      setcommitteeConfigurationObject(initialConfiguration);
      setCategoryOfInterest([initialCategoryOfInterest]);
      setSelectedCategory([]);
    }
  }, [committeeConfigurationObject.defaultParent]);

  const handleAdminComitteeConfigurations = async () => {
    const getAdminConfigurationData = await fetchData(
      "CommitteeConfiguration/GetAll"
    );
    if (getAdminConfigurationData.Collection.length > 0) {
      let formatedAdminConfiguration = ConfigureCommitteeResponseFormter(
        getAdminConfigurationData?.Collection[0]
      );
      setcommitteeConfigurationObject(formatedAdminConfiguration);
      setAdminConfigFlag(true);
      setDefaultConfigurationFromParentCommitteeCheckbox(
        getAdminConfigurationData?.Collection[0]
          .IsCheckBoxCheckedForDefaultParent
      );
      setNatureOfCommitteeCheckbox(
        getAdminConfigurationData?.Collection[0].IsCheckBoxCheckedIsRestricted
      );
      setAcceptApplicationsWithoutOpenPositionCheckbox(
        getAdminConfigurationData?.Collection[0]
          .AcceptApplicationWithoutOpenPositions
      );
      setDefaultApplicationConfigurationFromParentCommitteeCheckbox(
        getAdminConfigurationData?.Collection[0]
          .IsCheckBoxCheckedForAllowMemberOtherCommitteeAccess
      );

      const CommitteeConfigId = getAdminConfigurationData?.Collection[0].Id;
      if (CommitteeConfigId && formatedAdminConfiguration.committeeMinMember) {
        const responseDataforCOI = await getListByParentId(
          "CategoryofInterestConfiguration",
          CommitteeConfigId
        );
        let formatAdminCategoryOfInterest = setCategoryOfInterestData(
          responseDataforCOI.Collection,
          true
        );

        setCategoryOfInterest(formatAdminCategoryOfInterest);
      }
      setCheckedBalanceBasedOnOverallThreshold(
        formatedAdminConfiguration.IsBalanceBasedOnOverAllThreshold
      );
      setCheckedBalanceRepresentationByOrg(
        formatedAdminConfiguration.IsBalanceBasedOnRepresentationByOrg
      );
      setCheckedBalanceBasedOnIndCoi(
        formatedAdminConfiguration.IsBalanceBasedOnCategoryofInterests
      );
    }
  };

  useEffect(() => {
    if (!createCommitteeData.configureCommittee.committeeMaxMember) {
      handleAdminComitteeConfigurations();
    }
  }, [createCommitteeData.configureCommittee.committeeMaxMember]);

  useEffect(() => {
    if (Object.keys(createCommitteeData.configureCommittee).length > 0) {
      let draftedData: any = createCommitteeData.configureCommittee;
      let draftedCOIData: any = Object.values(
        createCommitteeData.categoryOfIntereset
      ).map((val) => val);
      setcommitteeConfigurationObject(draftedData);
      setCategoryOfInterest(separateCOIDataFromResponse(draftedCOIData));
      if (draftedCOIData[0].categoryOfInterestValue) {
        setSelectedCategory(
          draftedCOIData.map((item: any) => {
            return {
              ...item.categoryOfInterestValue,
              CategoryOfInterestId: item.CategoryOfInterestId,
            };
          })
        );
      }
    }
    let editMode = sessionStorage.getItem("editConfigCommittee");
    if (editMode === "On") {
      setEditModeForConfigCommitte(true);
    }
  }, []);

  const handleCancel = () => {
    if (editModeForConfigCommitte) {
      sessionStorage.setItem("redirectionPath", "config");
    }
    let committeeId = sessionStorage.getItem("committeeId");
    navigate(`/committee/details/${committeeId}`);
  };

  const [memberCommitteeAccessParent, setmemberCommitteeAccessParent] =
    useState<string>("");
  const [committeeApplicationAccess, setCommitteeApplicationAccess] =
    useState<string>("");

  const applicationAccessChange = (value: any) => {
    setcommitteeConfigurationObject({
      ...committeeConfigurationObject,
      acceptApplicationWithoutOpenPositions: value === "Yes",
    });
  };
  const [restrictedCommittee, setRestrictedCommittee] = useState<string>("");

  return (
    <>
      <div className="">
        {!editModeForConfigCommitte && (
          <CustomBreadcrumb
            model={breadcumbItems}
            className="bg-transparent mb-5 px-0 py-2 border-none"
          />
        )}

        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">
            {editModeForConfigCommitte
              ? t("committee.editCommittee")
              : t("committee.createNewCommittee")}
          </h1>
          {/* <p className="text-base font-normal m-0">
            Any supporting text for request listing page can go here
          </p> */}
        </div>

        {!editModeForConfigCommitte && <CommitteeSteps activeStep={1} />}

        {(editModeForConfigCommitte ||
          defaultConfigurationFromParentCommitteeCheckbox ||
          natureOfCommitteeCheckbox ||
          defaultApplicationConfigurationFromParentCommitteeCheckbox ||
          acceptApplicationsWithoutOpenPositionCheckbox) && (
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
              <h2 className="text-title text-lg font-bold text-capitalize m-0">
                {t("committee.basicCommitteeConfiguration")}
              </h2>
            </div>

            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                <div
                  className={`flex align-items-center ${editModeForConfigCommitte ? "p-disabled" : ""}`}
                >
                  {(editModeForConfigCommitte ||
                    defaultConfigurationFromParentCommitteeCheckbox) && (
                    <fieldset className="border-none p-0">
                      <legend className="block font-bold text-input-label p-0 mb-3">
                        {t("committee.defaultFromParentCommittee")}
                      </legend>

                      <div className="flex flex-wrap gap-3">
                        <div className="flex align-items-center">
                          <RadioButton
                            inputId="defaultFromParentCommittee1"
                            name="defaultParent"
                            value={true}
                            onChange={handleRadioButton}
                            checked={committeeConfigurationObject.defaultParent}
                          />
                          <label
                            htmlFor="defaultFromParentCommittee1"
                            className="ml-2"
                          >
                            {t("committee.yes")}
                          </label>
                        </div>
                        <div className="flex align-items-center">
                          <RadioButton
                            inputId="defaultFromParentCommittee2"
                            name="defaultParent"
                            value={false}
                            onChange={handleRadioButton}
                            checked={
                              !committeeConfigurationObject.defaultParent
                            }
                          />
                          <label
                            htmlFor="defaultFromParentCommittee2"
                            className="ml-2"
                          >
                            {t("committee.no")}
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  )}
                </div>

                {/* <div className="flex flex-column gap-3">
                <div className="flex gap-2">
                  <h3
                    id="committeeCharter"
                    className="block text-base font-bold text-capitalize text-input-label m-0"
                  >
                    {t("committee.natureOfTheCommittee")}
                  </h3>
                  <TooltipButton
                    icon="pi pi-info-circle"
                    className="text-body"
                    ariaLabel="Tooltip"
                    tooltip="Mark a committee as secret or restricted, thereby ensuring its ballots, records, and requests are not visible to members and staff of other committees."
                  />
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="restrictedCommittee"
                    name="isNatureofCommitteeRestricted"
                    onChange={handleCheckboxChange}
                    checked={
                      committeeConfigurationObject.isNatureofCommitteeRestricted
                    }
                    className="mr-2"
                    aria-labelledby="committeeCharter"
                  />
                  <label
                    htmlFor="restrictedCommittee"
                    className="text-base font-normal"
                  >
                    {t("committee.restrictedCommittee")}
                  </label>
                </div>
              </div> */}
                {(editModeForConfigCommitte || natureOfCommitteeCheckbox) && (
                  <fieldset className="border-none p-0">
                    <div className="flex gap-2">
                      <legend className="block font-bold text-input-label p-0 mb-3">
                        {t("committee.restrictedCommittee")}?
                      </legend>
                      {/* <label className="block font-bold text-input-label">
                    {t("committee.natureOfTheCommittee")}</label> */}
                      <TooltipButton
                        icon="pi pi-info-circle"
                        className="text-body"
                        ariaLabel="Tooltip"
                        tooltip={t("committee.markSecretRestricted")}
                      />
                    </div>
                    <div className="flex flex-wrap gap-3">
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="restrictedCommittee1"
                          name="isNatureofCommitteeRestricted"
                          value={true}
                          onChange={handleRadioButton}
                          checked={
                            committeeConfigurationObject.isNatureofCommitteeRestricted
                          }
                        />
                        <label htmlFor="restrictedCommittee1" className="ml-2">
                          {t("committee.yes")}
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="restrictedCommittee2"
                          name="isNatureofCommitteeRestricted"
                          value={false}
                          onChange={handleRadioButton}
                          checked={
                            !committeeConfigurationObject.isNatureofCommitteeRestricted
                          }
                        />
                        <label htmlFor="restrictedCommittee2" className="ml-2">
                          {t("committee.no")}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                )}

                {(editModeForConfigCommitte ||
                  defaultApplicationConfigurationFromParentCommitteeCheckbox) && (
                  <fieldset className="border-none p-0">
                    <legend className="block font-bold text-input-label p-0 mb-3">
                      {t("committee.memberCommitteeAccessParent")}
                    </legend>
                    <div className="flex flex-wrap gap-3">
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="memberCommitteeAccessParent1"
                          name="isAllowMemberOtherCommitteAccess"
                          value={true}
                          onChange={handleRadioButton}
                          checked={
                            committeeConfigurationObject.isAllowMemberOtherCommitteAccess
                          }
                        />
                        <label
                          htmlFor="memberCommitteeAccessParent1"
                          className="ml-2"
                        >
                          {t("committee.yes")}
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="memberCommitteeAccessParent2"
                          name="isAllowMemberOtherCommitteAccess"
                          value={false}
                          onChange={handleRadioButton}
                          checked={
                            !committeeConfigurationObject.isAllowMemberOtherCommitteAccess
                          }
                        />
                        <label
                          htmlFor="memberCommitteeAccessParent2"
                          className="ml-2"
                        >
                          {t("committee.no")}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                )}
                {(editModeForConfigCommitte ||
                  acceptApplicationsWithoutOpenPositionCheckbox) && (
                  <fieldset className="border-none p-0">
                    <legend className="block font-bold text-input-label p-0 mb-3">
                      {t("committee.allowApplications")}
                    </legend>
                    <div className="flex flex-wrap gap-3">
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="acceptApplicationWithoutOpenPositions1"
                          name="acceptApplicationWithoutOpenPositions"
                          value="Yes"
                          onChange={(e) => applicationAccessChange(e.value)}
                          checked={
                            committeeConfigurationObject.acceptApplicationWithoutOpenPositions ===
                            true
                          }
                        />
                        <label
                          htmlFor="acceptApplicationWithoutOpenPositions1"
                          className="ml-2"
                        >
                          {t("committee.yes")}
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="acceptApplicationWithoutOpenPositions2"
                          name="acceptApplicationWithoutOpenPositions"
                          value="No"
                          onChange={(e) => applicationAccessChange(e.value)}
                          checked={
                            committeeConfigurationObject.acceptApplicationWithoutOpenPositions ===
                            false
                          }
                        />
                        <label
                          htmlFor="acceptApplicationWithoutOpenPositions2"
                          className="ml-2"
                        >
                          {t("committee.no")}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.categoryOfInterest")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="grid grid-xl">
              <div className="flex flex-column gap-2 xl:col-4 lg:col-5 md:col-6 col-12">
                <MultiSelect
                  value={selectedCategory}
                  name="selectedCategory"
                  onChange={(e: MultiSelectChangeEvent) =>
                    handleMultiSelectCOI(e)
                  }
                  options={categoryOfInterestOption}
                  optionLabel="name"
                  filter
                  placeholder={t("committee.selectAnOptions")}
                  maxSelectedLabels={3}
                  className={`w-full ${multiselectCOIError ? "p-invalid" : ""}`}
                  aria-label={t("committee.categoryOfInterest")}
                />
                <span
                  id="minOverallThresholdError"
                  className={`p-error font-bold text-capitalize ${multiselectCOIError} ? "" : "error-hidden"}`}
                >
                  {multiselectCOIError}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.committeeBalance")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-6 w-full">
              {committeeConfigurationObject.IsBalanceBasedOnOverAllThreshold ||
              checkedBalanceBasedOnOverallThreshold ? (
                <div className="flex flex-column gap-4">
                  <div className="flex align-items-center">
                    <Checkbox
                      inputId="IsBalanceBasedOnOverAllThreshold"
                      name="IsBalanceBasedOnOverAllThreshold"
                      onChange={handleCheckboxChange}
                      checked={
                        committeeConfigurationObject.IsBalanceBasedOnOverAllThreshold
                      }
                      className="mr-2"
                    />
                    <label
                      htmlFor="IsBalanceBasedOnOverAllThreshold"
                      className="text-base font-normal"
                    >
                      {t("committee.balanceBasedOnOverallThreshold")}
                    </label>
                  </div>
                  {(committeeConfigurationObject.IsBalanceBasedOnOverAllThreshold ||
                    checkedBalanceBasedOnOverallThreshold) && (
                    <div className="grid grid-xl align-items-end pl-4">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="minOverallThreshold"
                          className={`block font-bold text-capitalize ${
                            errorBalanceBasedOnOverallThreshold.minOverallThreshold
                              ? " p-error"
                              : "text-input-label"
                          }`}
                        >
                          {t("committee.min")}
                        </label>
                        <InputText
                          id="minOverallThreshold"
                          name="minOverallThreshold"
                          value={
                            committeeConfigurationObject.minOverallThreshold
                          }
                          onChange={handleInputChange}
                          type="number"
                          min="1"
                          max="9999"
                          placeholder={t("committee.min")}
                          className={`w-full ${
                            errorBalanceBasedOnOverallThreshold.minOverallThreshold
                              ? "p-invalid"
                              : ""
                          }`}
                        />
                        <span
                          id="minOverallThresholdError"
                          className={`p-error font-bold text-capitalize ${errorBalanceBasedOnOverallThreshold.minOverallThreshold ? "" : "error-hidden"}`}
                        >
                          {
                            errorBalanceBasedOnOverallThreshold.minOverallThreshold
                          }
                        </span>
                      </div>
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="maxOverallThreshold"
                          className={`block font-bold text-capitalize ${
                            errorBalanceBasedOnOverallThreshold.maxOverallThreshold
                              ? " p-error"
                              : "text-input-label"
                          }`}
                        >
                          {t("committee.max")}
                        </label>
                        <InputText
                          id="maxOverallThreshold"
                          name="maxOverallThreshold"
                          value={
                            committeeConfigurationObject.maxOverallThreshold
                          }
                          onChange={handleInputChange}
                          type="number"
                          min="1"
                          max="9999"
                          placeholder={t("committee.max")}
                          className={`w-full ${
                            errorBalanceBasedOnOverallThreshold.maxOverallThreshold
                              ? "p-invalid"
                              : ""
                          }`}
                        />
                        <span
                          id="maxOverallThresholdError"
                          className={`p-error font-bold text-capitalize ${errorBalanceBasedOnOverallThreshold.maxOverallThreshold ? "" : "error-hidden"}`}
                        >
                          {
                            errorBalanceBasedOnOverallThreshold.maxOverallThreshold
                          }
                        </span>
                      </div>
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="thresholdPercentage"
                          className={`block font-bold text-capitalize ${
                            errorBalanceBasedOnOverallThreshold.thresholdPercentage
                              ? " p-error"
                              : "text-input-label"
                          }`}
                        >
                          {t("committee.threshold")}*
                        </label>
                        <InputText
                          id="thresholdPercentage"
                          name="thresholdPercentage"
                          value={
                            committeeConfigurationObject.thresholdPercentage
                          }
                          onChange={handleInputChange}
                          type="number"
                          min="1"
                          max="100"
                          placeholder={t("committee.threshold")}
                          className={`w-full ${
                            errorBalanceBasedOnOverallThreshold.thresholdPercentage
                              ? "p-invalid"
                              : ""
                          }`}
                        />
                        <span
                          id="thresholdPercentageError"
                          className={`p-error font-bold text-capitalize ${errorBalanceBasedOnOverallThreshold.thresholdPercentage ? "" : "error-hidden"}`}
                        >
                          {
                            errorBalanceBasedOnOverallThreshold.thresholdPercentage
                          }
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ) : null}

              {(committeeConfigurationObject.IsBalanceBasedOnCategoryofInterests ||
                checkedBalanceBasedOnIndCoi) && (
                <div className="flex flex-column gap-4">
                  <div className="flex align-items-center">
                    <Checkbox
                      inputId="IsBalanceBasedOnCategoryofInterests"
                      name="IsBalanceBasedOnCategoryofInterests"
                      onChange={handleCheckboxChange}
                      checked={
                        committeeConfigurationObject.IsBalanceBasedOnCategoryofInterests
                      }
                      className="mr-2"
                    />
                    <label
                      htmlFor="IsBalanceBasedOnCategoryofInterests"
                      className="text-base font-normal"
                    >
                      {t("committee.balanceBasedOnIndCoi")}
                    </label>
                  </div>

                  {(committeeConfigurationObject.IsBalanceBasedOnCategoryofInterests ||
                    checkedBalanceBasedOnIndCoi) && (
                    <div className="flex flex-column gap-2 w-full pl-4">
                      <div className="flex flex-column gap-2">
                        <div className="grid grid-xl mt-0 xl:flex-nowrap grid-resp grid-resp-lables configuration-grid">
                          <span
                            className={`flex xl:col-3 lg:col-4 md:col-6 col-12 py-1 font-bold text-capitalize ${
                              categoryOfInterestError.categoryOfInterestValue
                                ? " p-error"
                                : ""
                            }`}
                            id="categoryInterest"
                          >
                            {t("committee.categoryOfInterest")}*
                          </span>
                          <div className="xl:col-3 lg:col-4 md:col-6 col-12 py-0 mt-0">
                            <div className="grid grid-xl mt-0">
                              <span
                                className={`col-6 py-1 font-bold text-capitalize ${
                                  categoryOfInterestError.min ? " p-error" : ""
                                }`}
                                id="min"
                              >
                                {t("committee.min")}
                              </span>
                              <span
                                className={`col-6 py-1 font-bold text-capitalize ${
                                  categoryOfInterestError.max ? " p-error" : ""
                                }`}
                                id="max"
                              >
                                {t("committee.max")}
                              </span>
                            </div>
                          </div>
                          <span
                            className={`flex xl:col-3 lg:col-3 md:col-6 col-12 py-1 font-bold text-capitalize ${
                              categoryOfInterestError.threshold
                                ? " p-error"
                                : ""
                            }`}
                            id="threshold"
                          >
                            {t("committee.threshold")} %
                          </span>
                        </div>
                        <div className="flex flex-column row-gap-5 lg:row-gap-0 w-full">
                          {categoryOfInterest.map((val, idx) => (
                            <div
                              className={`grid grid-xl lg:flex-nowrap grid-resp configuration-grid idx-${idx}`}
                              key={idx}
                            >
                              <div
                                className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12 data-label"
                                data-label={t("committee.categoryOfInterest")}
                              >
                                <Dropdown
                                  inputId={`categoryInterest-${idx}`}
                                  aria-describedby={`categoryInterestError-${idx}`}
                                  value={
                                    categoryOfInterest[idx]
                                      ?.categoryOfInterestValue?.id ||
                                    categoryOfInterest[idx]
                                      ?.categoryOfInterestValue
                                  }
                                  optionValue="id"
                                  onChange={(e) =>
                                    handleCategoryOfInterest(e, idx)
                                  }
                                  options={selectedCategory?.filter(
                                    (option) => {
                                      return categoryOfInterest.every(
                                        (item, index) => {
                                          return (
                                            index === idx ||
                                            item.categoryOfInterestValue?.id !==
                                              option?.id
                                          );
                                        }
                                      );
                                    }
                                  )}
                                  name="categoryOfInterestValue"
                                  optionLabel="name"
                                  placeholder={t(
                                    "committee.categoryOfInterest"
                                  )}
                                  className={`w-full ${
                                    categoryOfInterestError.categoryOfInterestValue
                                      ? "p-invalid"
                                      : ""
                                  }`}
                                  aria-labelledby="categoryInterest"
                                />
                                <span
                                  id={`categoryInterestError-${idx}`}
                                  className={`p-error font-bold text-capitalize ${categoryOfInterestError.categoryOfInterestValue ? "" : "error-hidden"}`}
                                >
                                  {
                                    categoryOfInterestError.categoryOfInterestValue
                                  }
                                </span>
                              </div>
                              <div className="xl:col-3 lg:col-4 md:col-6 col-12 pb-0">
                                <div className="grid grid-xl">
                                  <div
                                    className="flex flex-column gap-2 col-6 data-label"
                                    data-label={t("committee.min")}
                                  >
                                    <InputText
                                      id={`min-${idx}`}
                                      aria-describedby={`minError-${idx}`}
                                      name="min"
                                      onChange={(e) =>
                                        handleCategoryOfInterest(e, idx)
                                      }
                                      value={categoryOfInterest[idx].min}
                                      type="number"
                                      min="0"
                                      max="9999"
                                      placeholder={t("committee.min")}
                                      className={`w-full ${
                                        categoryOfInterestError.max
                                          ? "p-invalid"
                                          : ""
                                      }`}
                                      aria-labelledby="min"
                                    />
                                    <span
                                      id={`minError-${idx}`}
                                      className={`p-error font-bold text-capitalize ${categoryOfInterestError.min ? "" : "error-hidden"}`}
                                    >
                                      {categoryOfInterestError.min}
                                    </span>
                                  </div>
                                  <div
                                    className="flex flex-column gap-2 col-6 data-label"
                                    data-label={t("committee.max")}
                                  >
                                    <InputText
                                      id={`max-${idx}`}
                                      aria-describedby={`maxError-${idx}`}
                                      name="max"
                                      onChange={(e) =>
                                        handleCategoryOfInterest(e, idx)
                                      }
                                      value={categoryOfInterest[idx].max}
                                      type="number"
                                      min="0"
                                      max="9999"
                                      placeholder={t("committee.max")}
                                      className={`w-full ${
                                        categoryOfInterestError.min
                                          ? "p-invalid"
                                          : ""
                                      }`}
                                      aria-labelledby="max"
                                    />
                                    <span
                                      id={`maxError-${idx}`}
                                      className={`p-error font-bold text-capitalize ${categoryOfInterestError.max ? "" : "error-hidden"}`}
                                    >
                                      {categoryOfInterestError.max}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="flex flex-column gap-2 xl:col-3 lg:col-3 md:col-6 col-12 data-label"
                                data-label={t("committee.threshold")}
                              >
                                <InputText
                                  id={`threshold-${idx}`}
                                  aria-describedby={`thresholdError-${idx}`}
                                  name="threshold"
                                  onChange={(e) =>
                                    handleCategoryOfInterest(e, idx)
                                  }
                                  value={categoryOfInterest[idx].threshold}
                                  type="number"
                                  min="0"
                                  max="100"
                                  placeholder={t("committee.threshold") + " %"}
                                  className={`w-full ${
                                    categoryOfInterestError.threshold
                                      ? "p-invalid"
                                      : ""
                                  }`}
                                  aria-labelledby="threshold"
                                />
                                <span
                                  id={`thresholdError-${idx}`}
                                  className={`p-error font-bold text-capitalize ${categoryOfInterestError.threshold ? "" : "error-hidden"}`}
                                >
                                  {categoryOfInterestError.threshold}
                                </span>
                              </div>
                              {categoryOfInterest.length > 1 && (
                                <div className="flex flex-column gap-2 xl:col-1 lg:col-1 md:col-2 col-12 w-auto">
                                  <Button
                                    text
                                    className="p-button-plain p-2 text-delete xl:ml-0 lg:ml-auto"
                                    aria-label={t("committee.delete")}
                                  >
                                    <FeatherIcon
                                      name="trash-2"
                                      size={20}
                                      onClick={() =>
                                        handleDeleteCategoryOfInterest(idx)
                                      }
                                    />
                                  </Button>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div>
                        <Button
                          text
                          className="p-button-plain gap-1 pl-0 underline"
                          onClick={handleAddCategoryOfInterest}
                        >
                          <FeatherIcon name="plus" size={20} />
                          <span className="font-bold text-capitalize">
                            {t("committee.addCategoryOfInterest")}
                          </span>
                        </Button>
                      </div>

                      {/* <div className="flex flex-column gap-4">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="theBalanceOfInterest"
                        onChange={handleCheckboxChange}
                        name="isBalanceofInterestRequiredtoMaintained"
                        checked={
                          committeeConfigurationObject.isBalanceofInterestRequiredtoMaintained
                        }
                        className="mr-2"
                      />
                      <label
                        htmlFor="theBalanceOfInterest"
                        className="text-base font-normal"
                      >
                        {t("committee.theBalanceOfInterest")}
                      </label>
                    </div>
                    <div className="grid grid-xl align-items-end pl-5">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="criteriaOfBalanceOfInterest"
                          className={`block font-bold text-capitalize ${error.balanceofInterest
                            ? " p-error"
                            : "text-input-label"
                            }`}
                        >
                          {t("committee.criteriaOfBalanceOfInterest")} (%)
                        </label>
                        <InputText
                          disabled={
                            !committeeConfigurationObject.isBalanceofInterestRequiredtoMaintained
                          }
                          id="criteriaOfBalanceOfInterest"
                          aria-describedby="criteriaOfBalanceOfInterestError"
                          name="balanceofInterest"
                          type="number"
                          min="0"
                          max="100"
                          placeholder={
                            t("committee.criteriaOfBalanceOfInterest") + " %"
                          }
                          onChange={handleInputChange}
                          className={`w-full ${error.balanceofInterest ? "p-invalid" : ""
                            }`}
                          value={committeeConfigurationObject.balanceofInterest}
                        />
                        <span
                          id="criteriaOfBalanceOfInterestError"
                          className={`p-error font-bold text-capitalize ${error.balanceofInterest ? "" : "error-hidden"}`}
                        >
                          {error.balanceofInterest}
                        </span>
                      </div>
                    </div>
                  </div> */}
                    </div>
                  )}

                  {/* <div className="grid grid-xl align-items-end pl-4">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="categoryOfInterest"
                      className="block font-bold text-input-label text-capitalize"
                    >
                      {t("committee.categoryOfInterest")}*
                    </label>
                    <Dropdown
                      inputId="categoryOfInterest"
                      name="categoryOfInterest"
                      value={selectedOption}
                      onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                      options={options}
                      optionLabel="name"
                      placeholder={t("committee.selectAnOption")}
                    />
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="balanceBasedOnIndCoiMin"
                      className="block font-bold text-input-label text-capitalize"
                    >
                      {t("committee.min")}*
                    </label>
                    <InputText
                      id="balanceBasedOnIndCoiMin"
                      name="balanceBasedOnIndCoiMin"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      type="number"
                      placeholder={t("committee.min")}
                      className="w-full"
                    />
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="balanceBasedOnIndCoiMax"
                      className="block font-bold text-input-label text-capitalize"
                    >
                      {t("committee.max")}*
                    </label>
                    <InputText
                      id="balanceBasedOnIndCoiMax"
                      name="balanceBasedOnIndCoiMax"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      type="number"
                      placeholder={t("committee.max")}
                      className="w-full"
                    />
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="balanceBasedOnIndCoiTrehshold"
                      className="block font-bold text-input-label text-capitalize"
                    >
                      {t("committee.threshold")}*
                    </label>
                    <InputText
                      id="balanceBasedOnIndCoiTrehshold"
                      name="balanceBasedOnIndCoiTrehshold"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      type="number"
                      placeholder={t("committee.threshold")}
                      className="w-full"
                    />
                  </div>
                </div> */}
                </div>
              )}

              {committeeConfigurationObject.IsBalanceBasedOnRepresentationByOrg ||
              checkedBalanceRepresentationByOrg ? (
                <div className="flex flex-column gap-4">
                  <div className="flex align-items-center">
                    <Checkbox
                      inputId="IsBalanceBasedOnRepresentationByOrg"
                      name="IsBalanceBasedOnRepresentationByOrg"
                      onChange={handleCheckboxChange}
                      checked={
                        committeeConfigurationObject.IsBalanceBasedOnRepresentationByOrg
                      }
                      className="mr-2"
                    />
                    <label
                      htmlFor="IsBalanceBasedOnRepresentationByOrg"
                      className="text-base font-normal"
                    >
                      {t("committee.balanceRepresentationByOrg")}
                    </label>
                  </div>
                  {(committeeConfigurationObject.IsBalanceBasedOnRepresentationByOrg ||
                    checkedBalanceRepresentationByOrg) && (
                    <div className="grid grid-xl align-items-end pl-4">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="organisationMinMember"
                          className={`block font-bold text-capitalize ${
                            errorBalanceRepresentationByOrg.organisationMinMember
                              ? " p-error"
                              : "text-input-label"
                          }`}
                        >
                          {t("committee.minimum")}
                        </label>
                        <InputText
                          id="organisationMinMember"
                          aria-describedby="organisationMinimumError"
                          name="organisationMinMember"
                          onChange={handleInputChange}
                          value={
                            committeeConfigurationObject.organisationMinMember
                          }
                          type="number"
                          min="1"
                          max="9999"
                          placeholder={t("committee.minimum")}
                          className={`w-full ${
                            errorBalanceRepresentationByOrg.organisationMinMember
                              ? "p-invalid"
                              : ""
                          }`}
                        />
                        <span
                          id="organisationMinimumError"
                          className={`p-error font-bold text-capitalize ${errorBalanceRepresentationByOrg.organisationMinMember ? "" : "error-hidden"}`}
                        >
                          {
                            errorBalanceRepresentationByOrg.organisationMinMember
                          }
                        </span>
                      </div>
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="organisationMaximum"
                          className={`block font-bold text-capitalize ${
                            errorBalanceRepresentationByOrg.organisationMaxMember
                              ? " p-error"
                              : "text-input-label"
                          }`}
                        >
                          {t("committee.maximum")}
                        </label>
                        <InputText
                          id="organisationMaximum"
                          aria-describedby="organisationMaximumError"
                          name="organisationMaxMember"
                          onChange={handleInputChange}
                          value={
                            committeeConfigurationObject.organisationMaxMember
                          }
                          type="number"
                          min="1"
                          max="9999"
                          placeholder={t("committee.maximum")}
                          className={`w-full ${
                            errorBalanceRepresentationByOrg.organisationMaxMember
                              ? "p-invalid"
                              : ""
                          }`}
                        />
                        <span
                          id="organisationMaximumError"
                          className={`p-error font-bold text-capitalize ${errorBalanceRepresentationByOrg.organisationMaxMember ? "" : "error-hidden"}`}
                        >
                          {
                            errorBalanceRepresentationByOrg.organisationMaxMember
                          }
                        </span>
                      </div>
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="organizationThreshold"
                          className={`block font-bold text-capitalize ${
                            errorBalanceRepresentationByOrg.organisationMemberThreshold
                              ? " p-error"
                              : "text-input-label"
                          }`}
                        >
                          {t("committee.threshold")} %
                        </label>
                        <InputText
                          id="organizationThreshold"
                          aria-describedby="organizationThresholdError"
                          name="organisationMemberThreshold"
                          onChange={handleInputChange}
                          value={
                            committeeConfigurationObject.organisationMemberThreshold
                          }
                          type="number"
                          min="0"
                          max="100"
                          placeholder={t("committee.threshold") + " %"}
                          className={`w-full ${
                            errorBalanceRepresentationByOrg.organisationMemberThreshold
                              ? "p-invalid"
                              : ""
                          }`}
                        />
                        <span
                          id="organizationThresholdError"
                          className={`p-error font-bold text-capitalize ${errorBalanceRepresentationByOrg.organisationMemberThreshold ? "" : "error-hidden"}`}
                        >
                          {
                            errorBalanceRepresentationByOrg.organisationMemberThreshold
                          }
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.committeeMemberConfiguration")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-6 w-full">
              <div className="flex flex-column gap-4">
                <h3 className="m-0 font-normal text-lg">
                  {t("committee.noMemberInCommittee")}
                </h3>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="minimum"
                      className={`block font-bold text-capitalize ${
                        error.committeeMinMember
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.minimum")}
                    </label>
                    <InputText
                      id="minimum"
                      aria-describedby="minimumError"
                      name="committeeMinMember"
                      onChange={handleInputChange}
                      value={committeeConfigurationObject.committeeMinMember}
                      type="number"
                      min="1"
                      max="9999"
                      placeholder={t("committee.minimum")}
                      className={`w-full ${
                        error.committeeMinMember ? "p-invalid" : ""
                      }`}
                    />
                    <span
                      id="minimumError"
                      className={`p-error font-bold text-capitalize ${error.committeeMinMember ? "" : "error-hidden"}`}
                    >
                      {error.committeeMinMember}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="maximum"
                      className={`block font-bold text-capitalize ${
                        error.committeeMaxMember
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.maximum")}
                    </label>
                    <InputText
                      id="maximum"
                      aria-describedby="maximumError"
                      name="committeeMaxMember"
                      onChange={handleInputChange}
                      value={committeeConfigurationObject.committeeMaxMember}
                      type="number"
                      min="1"
                      max="9999"
                      placeholder={t("committee.maximum")}
                      className={`w-full ${
                        error.committeeMaxMember ? "p-invalid" : ""
                      }`}
                    />
                    <span
                      id="maximumError"
                      className={`p-error font-bold text-capitalize ${error.committeeMaxMember ? "" : "error-hidden"}`}
                    >
                      {error.committeeMaxMember}
                    </span>
                  </div>
                </div>
              </div>

              {/* <div className="flex flex-column gap-4">
                <h3 className="m-0 font-normal text-lg">
                  {t("committee.numberOfMemberOrganisation")}
                </h3>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="organisationMaximum"
                      className={`block font-bold text-capitalize ${
                        error.organisationMaxMember
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.maximum")}
                    </label>
                    <InputText
                      id="organisationMaximum"
                      aria-describedby="organisationMaximumError"
                      name="organisationMaxMember"
                      onChange={handleInputChange}
                      value={committeeConfigurationObject.organisationMaxMember}
                      type="number"
                      min="1"
                      max="9999"
                      placeholder={t("committee.maximum")}
                      className={`w-full ${
                        error.organisationMaxMember ? "p-invalid" : ""
                      }`}
                    />
                    <span
                      id="organisationMaximumError"
                      className={`p-error font-bold text-capitalize ${error.organisationMaxMember ? "" : "error-hidden"}`}
                    >
                      {error.organisationMaxMember}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="organizationThreshold"
                      className={`block font-bold text-capitalize ${
                        error.organisationMemberThreshold
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.threshold")} %
                    </label>
                    <InputText
                      id="organizationThreshold"
                      aria-describedby="organizationThresholdError"
                      name="organisationMemberThreshold"
                      onChange={handleInputChange}
                      value={
                        committeeConfigurationObject.organisationMemberThreshold
                      }
                      type="number"
                      min="0"
                      max="100"
                      placeholder={t("committee.threshold") + " %"}
                      className={`w-full ${
                        error.organisationMemberThreshold ? "p-invalid" : ""
                      }`}
                    />
                    <span
                      id="organizationThresholdError"
                      className={`p-error font-bold text-capitalize ${error.organisationMemberThreshold ? "" : "error-hidden"}`}
                    >
                      {error.organisationMemberThreshold}
                    </span>
                  </div>
                </div>
              </div> */}

              <div className="flex flex-column gap-4">
                <div className="flex flex-row gap-2">
                  <h3 className="m-0 font-normal text-lg">
                    {t("committee.criteriaMembershipEligibility")}
                  </h3>

                  <TooltipButton
                    icon="pi pi-info-circle"
                    className="text-body"
                    ariaLabel="Tooltip"
                    tooltip={t("committee.notificationMessage")}
                  />
                </div>
                <div className="grid grid-xl align-items-end">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="subtierCommittee"
                      className={`block font-bold text-capitalize ${
                        error.msElig_SubTierCommitte_Months
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.subTierCommittee")}
                    </label>
                    <InputText
                      id="subtierCommittee"
                      aria-describedby="subtierCommitteeError"
                      name="msElig_SubTierCommitte_Months"
                      onChange={handleInputChange}
                      value={
                        committeeConfigurationObject.msElig_SubTierCommitte_Months
                      }
                      type="number"
                      min="0"
                      max="99"
                      placeholder={t("committee.subTierCommittee")}
                      className={`w-full ${
                        error.msElig_SubTierCommitte_Months ? "p-invalid" : ""
                      }`}
                    />
                    <span
                      id="subtierCommitteeError"
                      className={`p-error font-bold text-capitalize ${error.msElig_SubTierCommitte_Months ? "" : "error-hidden"}`}
                    >
                      {error.msElig_SubTierCommitte_Months}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="sameCommittee"
                      className={`block font-bold text-capitalize ${
                        error.msElig_SameCommitte_Officer_Months
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.subTierCommitteePosition")}
                    </label>
                    <InputText
                      id="sameCommittee"
                      aria-describedby="sameCommitteeError"
                      name="msElig_SameCommitte_Officer_Months"
                      onChange={handleInputChange}
                      value={
                        committeeConfigurationObject.msElig_SameCommitte_Officer_Months
                      }
                      type="number"
                      min="0"
                      max="99"
                      placeholder={t("committee.subTierCommitteePosition")}
                      className={`w-full ${
                        error.msElig_SameCommitte_Officer_Months
                          ? "p-invalid"
                          : ""
                      }`}
                    />
                    <span
                      id="sameCommitteeError"
                      className={`p-error font-bold text-capitalize ${error.msElig_SameCommitte_Officer_Months ? "" : "error-hidden"}`}
                    >
                      {error.msElig_SameCommitte_Officer_Months}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-column gap-4">
                <h3 className="m-0 font-normal text-lg">
                  {t("committee.MembershipRules")}
                </h3>
                <div className="grid grid-xl align-items-end">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="tenureOfOfficerPosition"
                      className={`block font-bold text-capitalize ${
                        error.msExpire_Officer_Tenure_Years
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.tenureOfOfficerPosition")}*
                    </label>
                    <InputText
                      id="tenureOfOfficerPosition"
                      aria-describedby="tenureOfOfficerPositionError"
                      name="msExpire_Officer_Tenure_Years"
                      onChange={handleInputChange}
                      value={
                        committeeConfigurationObject.msExpire_Officer_Tenure_Years
                      }
                      type="number"
                      min="0"
                      max="99"
                      placeholder={t("committee.tenureOfOfficerPosition")}
                      className={`w-full ${
                        error.msExpire_Officer_Tenure_Years ? "p-invalid" : ""
                      }`}
                    />
                    <span
                      id="tenureOfOfficerPositionError"
                      className={`p-error font-bold text-capitalize ${error.msExpire_Officer_Tenure_Years ? "" : "error-hidden"}`}
                    >
                      {error.msExpire_Officer_Tenure_Years}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="tenureMembers"
                      className={`block font-bold text-capitalize ${
                        error.msExpire_Member_Tenure_Years
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.tenureMembers")}*
                    </label>
                    <InputText
                      id="tenureMembers"
                      aria-describedby="tenureMembersError"
                      name="msExpire_Member_Tenure_Years"
                      onChange={handleInputChange}
                      value={
                        committeeConfigurationObject.msExpire_Member_Tenure_Years
                      }
                      type="number"
                      min="0"
                      max="99"
                      placeholder={t("committee.tenureMembers")}
                      className={`w-full ${
                        error.msExpire_Member_Tenure_Years ? "p-invalid" : ""
                      }`}
                    />
                    <span
                      id="tenureMembersError"
                      className={`p-error font-bold text-capitalize ${error.msExpire_Member_Tenure_Years ? "" : "error-hidden"}`}
                    >
                      {error.msExpire_Member_Tenure_Years}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-column gap-4">
                <h3 className="m-0 font-normal text-lg">
                  {t("committee.allMembershipsExpiresOn")}
                </h3>
                <div className="grid grid-xl align-items-end">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="membershipExpiresOn_All"
                      className={`block font-bold text-capitalize ${
                        error.membershipExpiresOn_All
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.allMembershipsExpiresOn")}
                    </label>
                    <Calendar
                      appendTo="self"
                      inputId="membershipExpiresOn_All"
                      aria-describedby="membershipExpiresOn_AllError"
                      name="membershipExpiresOn_All"
                      value={
                        committeeConfigurationObject.membershipExpiresOn_All
                          ? new Date(
                              committeeConfigurationObject.membershipExpiresOn_All
                            )
                          : null
                      }
                      placeholder={t("committee.allMembershipsExpiresOn")}
                      onChange={handleInputChange}
                      // onSelect={() => focusOnField("membershipExpiresOn_All")}
                      dateFormat="mm/dd"
                      className={`custom-datepicker ${
                        error.membershipExpiresOn_All ? "p-invalid" : ""
                      }`}
                    />
                    <span
                      id="membershipExpiresOn_AllError"
                      className={`p-error font-bold text-capitalize ${error.membershipExpiresOn_All ? "" : "error-hidden"}`}
                    >
                      {error.membershipExpiresOn_All}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.notificationAlertMembership")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-6 w-full">
              {/* <div className="flex align-items-center">
                <Checkbox
                  inputId="notificationAlertExpiration"
                  name="sendNotificationforMembershipExpiration"
                  onChange={handleCheckboxChange}
                  checked={
                    committeeConfigurationObject.sendNotificationforMembershipExpiration
                  }
                  className="mr-2"
                />
                <label
                  htmlFor="notificationAlertExpiration"
                  className="text-base font-normal"
                >
                  {t("committee.notificationAlertExpiration")}
                </label>
              </div> */}

              <div className="flex flex-column gap-4">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="notificationToStaff"
                    name="notifyStaffExpirationofMembership"
                    onChange={handleCheckboxChange}
                    checked={
                      committeeConfigurationObject.notifyStaffExpirationofMembership
                    }
                    className="mr-2"
                  />
                  <label
                    htmlFor="notificationToStaff"
                    className="text-base font-normal"
                  >
                    {t("committee.notificationToStaff")}
                  </label>
                </div>
                <div className="grid pl-4">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="firstNotificationStaff"
                      className={`block font-bold text-capitalize ${
                        error.notifyStaffExpirationofMembershipDays_First
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.firstNotification")}*
                    </label>
                    <InputText
                      disabled={
                        !committeeConfigurationObject.notifyStaffExpirationofMembership
                      }
                      id="firstNotificationStaff"
                      aria-describedby="firstNotificationStaffError"
                      type="number"
                      min={`${committeeConfigurationObject.notifyStaffExpirationofMembershipDays_Second ? parseInt(committeeConfigurationObject.notifyStaffExpirationofMembershipDays_Second) + 1 : "0"}`}
                      max="99"
                      placeholder={t("committee.firstNotification")}
                      className={`w-full ${
                        error.notifyStaffExpirationofMembershipDays_First
                          ? "p-invalid"
                          : ""
                      }`}
                      name="notifyStaffExpirationofMembershipDays_First"
                      onChange={handleInputChange}
                      value={
                        committeeConfigurationObject.notifyStaffExpirationofMembershipDays_First
                      }
                    />
                    <span
                      id="firstNotificationStaffError"
                      className={`p-error font-bold text-capitalize ${error.notifyStaffExpirationofMembershipDays_First ? "" : "error-hidden"}`}
                    >
                      {error.notifyStaffExpirationofMembershipDays_First}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="secondNotificationStaff"
                      className={`block font-bold text-capitalize ${
                        error.notifyStaffExpirationofMembershipDays_Second
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.secondNotification")}*
                    </label>
                    <InputText
                      disabled={
                        !committeeConfigurationObject.notifyStaffExpirationofMembership
                      }
                      id="secondNotificationStaff"
                      aria-describedby="secondNotificationStaffError"
                      type="number"
                      min="0"
                      max="99"
                      placeholder={t("committee.secondNotification")}
                      className={`w-full ${
                        error.notifyStaffExpirationofMembershipDays_Second
                          ? "p-invalid"
                          : ""
                      }`}
                      name="notifyStaffExpirationofMembershipDays_Second"
                      onChange={handleInputChange}
                      value={
                        committeeConfigurationObject.notifyStaffExpirationofMembershipDays_Second
                      }
                    />
                    <span
                      id="secondNotificationStaffError"
                      className={`p-error font-bold text-capitalize ${error.notifyStaffExpirationofMembershipDays_Second ? "" : "error-hidden"}`}
                    >
                      {error.notifyStaffExpirationofMembershipDays_Second}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="thirdNotificationStaff"
                      className={`block font-bold text-capitalize ${
                        error.notifyStaffExpirationofMembershipDays_Third
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.thirdNotification")}*
                    </label>
                    <InputText
                      disabled={
                        !committeeConfigurationObject.notifyStaffExpirationofMembership
                      }
                      id="thirdNotificationStaff"
                      aria-describedby="thirdNotificationStaffError"
                      type="number"
                      min="0"
                      max="99"
                      placeholder={t("committee.thirdNotification")}
                      className={`w-full ${
                        error.notifyStaffExpirationofMembershipDays_Third
                          ? "p-invalid"
                          : ""
                      }`}
                      name="notifyStaffExpirationofMembershipDays_Third"
                      onChange={handleInputChange}
                      value={
                        committeeConfigurationObject.notifyStaffExpirationofMembershipDays_Third
                      }
                    />
                    <span
                      id="thirdNotificationStaffError"
                      className={`p-error font-bold text-capitalize ${error.notifyStaffExpirationofMembershipDays_Second ? "" : "error-hidden"}`}
                    >
                      {error.notifyStaffExpirationofMembershipDays_Third}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-column gap-4">
                <div className="flex align-items-center gap-2">
                  <Checkbox
                    inputId="notificationToMember"
                    onChange={handleCheckboxChange}
                    name="notifyMemberExpirationofMembership"
                    checked={
                      committeeConfigurationObject.notifyMemberExpirationofMembership
                    }
                    className=""
                  />
                  <label
                    htmlFor="notificationToMember"
                    className="text-base font-normal"
                  >
                    {t("committee.notificationToMember")}
                  </label>

                  <TooltipButton
                    icon="pi pi-info-circle"
                    className="text-body"
                    ariaLabel="Tooltip"
                    tooltip={t("committee.minimumOfficerServed")}
                  />
                </div>
                <div className="grid pl-4">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="firstNotificationMember"
                      className={`block font-bold text-capitalize ${
                        error.notifyMemberExpirationofMembershipDays_First
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.firstNotification")}*
                    </label>
                    <InputText
                      disabled={
                        !committeeConfigurationObject.notifyMemberExpirationofMembership
                      }
                      id="firstNotificationMember"
                      aria-describedby="firstNotificationMemberError"
                      type="number"
                      min="0"
                      max="99"
                      placeholder={t("committee.firstNotification")}
                      className={`w-full ${
                        error.notifyMemberExpirationofMembershipDays_First
                          ? "p-invalid"
                          : ""
                      }`}
                      name="notifyMemberExpirationofMembershipDays_First"
                      onChange={handleInputChange}
                      value={
                        committeeConfigurationObject.notifyMemberExpirationofMembershipDays_First
                      }
                    />
                    <span
                      id="firstNotificationMemberError"
                      className={`p-error font-bold text-capitalize ${error.notifyMemberExpirationofMembershipDays_First ? "" : "error-hidden"}`}
                    >
                      {error.notifyMemberExpirationofMembershipDays_First}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="secondNotificationMember"
                      className={`block font-bold text-capitalize ${
                        error.notifyMemberExpirationofMembershipDays_Second
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.secondNotification")}*
                    </label>
                    <InputText
                      disabled={
                        !committeeConfigurationObject.notifyMemberExpirationofMembership
                      }
                      id="secondNotificationMember"
                      aria-describedby="secondNotificationMemberError"
                      type="number"
                      min="0"
                      max="99"
                      placeholder={t("committee.secondNotification")}
                      className={`w-full ${
                        error.notifyMemberExpirationofMembershipDays_Second
                          ? "p-invalid"
                          : ""
                      }`}
                      name="notifyMemberExpirationofMembershipDays_Second"
                      onChange={handleInputChange}
                      value={
                        committeeConfigurationObject.notifyMemberExpirationofMembershipDays_Second
                      }
                    />
                    <span
                      id="secondNotificationMemberError"
                      className={`p-error font-bold text-capitalize ${error.notifyMemberExpirationofMembershipDays_Second ? "" : "error-hidden"}`}
                    >
                      {error.notifyMemberExpirationofMembershipDays_Second}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="thirdNotificationMember"
                      className={`block font-bold text-capitalize ${
                        error.notifyMemberExpirationofMembershipDays_Third
                          ? "p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.thirdNotification")}*
                    </label>
                    <InputText
                      disabled={
                        !committeeConfigurationObject.notifyMemberExpirationofMembership
                      }
                      id="thirdNotificationMember"
                      aria-describedby="thirdNotificationMemberError"
                      type="number"
                      min="0"
                      max="99"
                      placeholder={t("committee.thirdNotification")}
                      className={`w-full ${
                        error.notifyMemberExpirationofMembershipDays_Third
                          ? "p-invalid"
                          : ""
                      }`}
                      name="notifyMemberExpirationofMembershipDays_Third"
                      onChange={handleInputChange}
                      value={
                        committeeConfigurationObject.notifyMemberExpirationofMembershipDays_Third
                      }
                    />
                    <span
                      id="thirdNotificationMemberError"
                      className={`p-error font-bold text-capitalize ${error.notifyMemberExpirationofMembershipDays_Third ? "" : "error-hidden"}`}
                    >
                      {error.notifyMemberExpirationofMembershipDays_Third}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-column gap-2">
                <div className="grid grid-xl align-items-end">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="maximumConsecutiveTerm"
                      className={`block font-bold text-capitalize ${
                        error.officerMaximumTermYears
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.maximumConsecutiveTerm")}
                    </label>
                    <InputText
                      id="maximumConsecutiveTerm"
                      type="number"
                      min="0"
                      max="99"
                      placeholder={t("committee.maximumConsecutiveTerm")}
                      className={`w-full ${
                        error.officerMaximumTermYears ? "p-invalid" : ""
                      }`}
                      name="officerMaximumTermYears"
                      onChange={handleInputChange}
                      value={
                        committeeConfigurationObject.officerMaximumTermYears
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-xl align-items-end">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="durationOfReconfirm"
                      className={`block font-bold text-capitalize ${
                        error.applicationAutoWithdrawalMonths
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.durationOfReconfirm")}
                    </label>
                    <InputText
                      id="durationOfReconfirm"
                      aria-describedby="durationOfReconfirmError"
                      type="number"
                      min="0"
                      max="99"
                      placeholder={t("committee.durationOfReconfirm")}
                      className={`w-full ${
                        error.applicationAutoWithdrawalMonths ? "p-invalid" : ""
                      }`}
                      name="applicationAutoWithdrawalMonths"
                      onChange={handleInputChange}
                      value={
                        committeeConfigurationObject.applicationAutoWithdrawalMonths
                      }
                    />
                    <span
                      id="durationOfReconfirmError"
                      className={`p-error font-bold text-capitalize ${error.applicationAutoWithdrawalMonths ? "" : "error-hidden"}`}
                    >
                      {error.applicationAutoWithdrawalMonths}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.notificationAlertsToOfficers")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5 w-full">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="sendNotificationAlertOnMember"
                  onChange={handleCheckboxChange}
                  name="notifyMemberLackofParticipation"
                  checked={
                    committeeConfigurationObject.notifyMemberLackofParticipation
                  }
                  className="mr-2"
                />
                <label
                  htmlFor="sendNotificationAlertOnMember"
                  className="text-base font-normal"
                >
                  {t("committee.sendNotificationAlertOnMember")}
                </label>
              </div>

              <div className="flex flex-column gap-4">
                <div className="flex flex-column gap-4 pl-5">
                  <div className="flex flex-column gap-3">
                    <div className="flex flex-column gap-2 pl-4">
                      <div className="grid grid-xl align-items-end">
                        <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                          <label
                            htmlFor="numberMeetingsMissed"
                            className={`block font-bold text-capitalize ${
                              error.numberofMeetingMissed
                                ? " p-error"
                                : "text-input-label"
                            }`}
                          >
                            {t("committee.numberMeetingsMissed")}
                          </label>
                          <InputText
                            disabled={
                              !committeeConfigurationObject.notifyMemberLackofParticipation
                            }
                            id="numberMeetingsMissed"
                            aria-describedby="numberMeetingsMissedError"
                            type="number"
                            min="0"
                            max="99"
                            placeholder={t("committee.numberMeetingsMissed")}
                            className={`w-full ${
                              error.numberofMeetingMissed ? "p-invalid" : ""
                            }`}
                            name="numberofMeetingMissed"
                            onChange={handleInputChange}
                            value={
                              committeeConfigurationObject.numberofMeetingMissed
                            }
                          />
                          <span
                            id="numberMeetingsMissedError"
                            className={`p-error font-bold text-capitalize ${error.numberofMeetingMissed ? "" : "error-hidden"}`}
                          >
                            {error.numberofMeetingMissed}
                          </span>
                        </div>
                        <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                          <label
                            htmlFor="numberBallotsMissed"
                            className={`block font-bold text-capitalize ${
                              error.numberofBallotsMissed
                                ? " p-error"
                                : "text-input-label"
                            }`}
                          >
                            {t("committee.numberBallotsMissed")}
                          </label>
                          <InputText
                            disabled={
                              !committeeConfigurationObject.notifyMemberLackofParticipation
                            }
                            id="numberBallotsMissed"
                            aria-describedby="numberBallotsMissedError"
                            type="number"
                            min="0"
                            max="99"
                            placeholder={t("committee.numberBallotsMissed")}
                            className={`w-full ${
                              error.numberofBallotsMissed ? "p-invalid" : ""
                            }`}
                            name="numberofBallotsMissed"
                            onChange={handleInputChange}
                            value={
                              committeeConfigurationObject.numberofBallotsMissed
                            }
                          />
                          <span
                            id="numberBallotsMissedError"
                            className={`p-error font-bold text-capitalize ${error.numberofBallotsMissed ? "" : "error-hidden"}`}
                          >
                            {error.numberofBallotsMissed}
                          </span>
                        </div>
                      </div>
                      <div className="grid grid-xl align-items-end">
                        <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                          <label
                            htmlFor="totalMeetingMissed"
                            className={`block font-bold text-capitalize ${
                              error.totalMeetingMisssedPercentage
                                ? " p-error"
                                : "text-input-label"
                            }`}
                          >
                            {t("committee.totalMeetingMissed")} (%)
                          </label>
                          <InputText
                            disabled={
                              !committeeConfigurationObject.notifyMemberLackofParticipation
                            }
                            id="totalMeetingMissed"
                            aria-describedby="totalMeetingMissedError"
                            type="number"
                            min="0"
                            max="99"
                            placeholder={
                              t("committee.totalMeetingMissed") + " %"
                            }
                            className={`w-full ${
                              error.totalMeetingMisssedPercentage
                                ? "p-invalid"
                                : ""
                            }`}
                            name="totalMeetingMisssedPercentage"
                            onChange={handleInputChange}
                            value={
                              committeeConfigurationObject.totalMeetingMisssedPercentage
                            }
                          />
                          <span
                            id="totalMeetingMissedError"
                            className={`p-error font-bold text-capitalize ${error.totalMeetingMisssedPercentage ? "" : "error-hidden"}`}
                          >
                            {error.totalMeetingMisssedPercentage}
                          </span>
                        </div>
                        <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                          <label
                            htmlFor="totalBallotMissed"
                            className={`block font-bold text-capitalize ${
                              error.totalBallotMisssedPercentage
                                ? " p-error"
                                : "text-input-label"
                            }`}
                          >
                            {t("committee.totalBallotMissed")} (%)
                          </label>
                          <InputText
                            disabled={
                              !committeeConfigurationObject.notifyMemberLackofParticipation
                            }
                            id="totalBallotMissed"
                            aria-describedby="totalBallotMissedError"
                            type="number"
                            min="0"
                            max="99"
                            placeholder={
                              t("committee.totalBallotMissed") + " %"
                            }
                            className={`w-full ${
                              error.totalBallotMisssedPercentage
                                ? "p-invalid"
                                : ""
                            }`}
                            name="totalBallotMisssedPercentage"
                            onChange={handleInputChange}
                            value={
                              committeeConfigurationObject.totalBallotMisssedPercentage
                            }
                          />
                          <span
                            id="totalBallotMissedError"
                            className={`p-error font-bold text-capitalize ${error.totalBallotMisssedPercentage ? "" : "error-hidden"}`}
                          >
                            {error.totalBallotMisssedPercentage}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {editModeForConfigCommitte ? (
          <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
            <Button
              label={t("committee.cancel")}
              className="button-md"
              severity="secondary"
              onClick={() => handleCancel()}
            />
            <Button
              className="button-md gap-1"
              onClick={(e) => handleSaveAndContinue(e)}
            >
              <span className="font-bold text-capitalize">
                {t("committee.submit")}
              </span>
              <FeatherIcon name="chevron-right" size={20} color="inherit" />
            </Button>
          </div>
        ) : (
          <>
            <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
              <Button
                label={t("committee.cancel")}
                className="button-md"
                severity="secondary"
                onClick={() => navigate("/committee/")}
              />
              <div className="flex md:flex-row gap-4">
                <Button
                  text
                  onClick={(e) => handleSaveAndContinue(e, "draft")}
                  className="p-button-plain underline"
                  label={t("committee.saveAsDraft")}
                ></Button>
                <Button
                  className="button-md gap-1"
                  severity="secondary"
                  onClick={() => navigate("/committee/create")}
                >
                  <FeatherIcon name="chevron-left" size={20} color="inherit" />
                  <span className="font-bold text-capitalize">
                    {t("committee.back")}
                  </span>
                </Button>
                <Button
                  className="button-md gap-1"
                  onClick={(e) => handleSaveAndContinue(e)}
                >
                  <span className="font-bold text-capitalize">
                    {t("committee.saveContinue")}
                  </span>
                  <FeatherIcon name="chevron-right" size={20} color="inherit" />
                </Button>
              </div>
            </div>
            {/* Mobile responsive buttions */}
            <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
              <Button
                className="button-md gap-1 w-full justify-content-center"
                onClick={(e) => handleSaveAndContinue(e)}
              >
                <span className="font-bold text-capitalize">
                  {t("committee.saveContinue")}
                </span>
                <FeatherIcon name="chevron-right" size={20} color="inherit" />
              </Button>
              <div className="flex align-items-center gap-3 w-full">
                <Button
                  className="button-md gap-1"
                  severity="secondary"
                  onClick={() => navigate("/committee/create")}
                  aria-label={t("committee.back")}
                >
                  <FeatherIcon name="chevron-left" size={20} color="inherit" />
                </Button>
                <Button
                  label={t("committee.cancel")}
                  className="button-md w-full"
                  severity="secondary"
                  onClick={() => navigate("/committee/details")}
                />
              </div>
              <Button
                text
                onClick={(e) => handleSaveAndContinue(e, "draft")}
                className="p-button-plain underline w-full"
                label={t("committee.saveAsDraft")}
              ></Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CommitteeConfiguration;

import React, { useState, useEffect } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import Input from "../../utils/Input";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchData,
  getAllCountries,
  getListByParentId,
  postData,
  putData,
} from "../../services/apiService";
import { InputText } from "primereact/inputtext";
import { focusOnErrorField } from "../../utils/focusError";
import DocumentUpload from "../common/DocumentUpload";
import { focusOnField } from "../../utils/focusField";
interface Address {
  AddressType: string;
  userAddressId: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  CountryId: string;
  StateId: string;
  City: string;
  pincode: string;
  stateOther: string;
  IsDefaultAddress: any;
  TenantId?: string;
  IsDirty?: boolean;
  IsNew?: boolean;
  UserProfileId: any;
}

const AddAddress = () => {
  const { id } = useParams<any>();
  const { selectedLanguage } = useLanguageContext();
  const [t, i18n] = useTranslation("profile");
  const [startFileUpload, setStartFileUpload] = useState(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [checkedStates, setCheckedStates] = useState<boolean[]>([false]); //new
  const userProfileID = localStorage.getItem("userProfileID") || "";
  const [anyDefaultAddress, setAnyDefaultAddress] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<
    Array<Partial<Address>>
  >([]);

  const [addresses, setAddresses] = useState<Address[]>([
    {
      AddressType: "",
      userAddressId: userProfileID,
      AddressLine1: "",
      AddressLine2: "",
      AddressLine3: "",
      CountryId: "",
      StateId: "",
      City: "",
      pincode: "",
      stateOther: "",
      IsDefaultAddress: false,
      UserProfileId: userProfileID,
      IsDirty: true,
      IsNew: true,
      //TenantId: TENANT_ID,
    },
  ]);

  const createProfileLabelForValidation = [
    { name: "AddressType", label: t("profile.addressType") },
    { name: "AddressLine1", label: t("profile.address") },
    { name: "CountryId", label: t("profile.country") },
    { name: "StateId", label: t("profile.state") },
    { name: "City", label: t("profile.city") },
    { name: "pincode", label: t("profile.zipcode") },
  ];

  const navigate = useNavigate();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  useEffect(() => {
    getAllCountries()
      .then((data: any) => {
        const dataCollection = data?.Collection;
        const countryOptions = dataCollection.map((country: any) => ({
          value: country.Id,
          label: country.Name,
        }));
        setCountries(countryOptions);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const addressType = [
    { label: "Work", value: "Work" },
    { label: "Home", value: "Home" },
    { label: "Others", value: "Others" },
  ];

  const handleInputChange = (index: number, key: keyof Address, value: any) => {
    const newAddresses = [...addresses];

    if (key === "IsDefaultAddress" && value) {
      newAddresses.forEach((address, i) => {
        address.IsDefaultAddress = i === index;
      });
      setAnyDefaultAddress(true);
    } else if (key === "IsDefaultAddress" && !value) {
      newAddresses[index][key] = value as never;
      setAnyDefaultAddress(
        newAddresses.some((address) => address.IsDefaultAddress)
      );
    } else {
      newAddresses[index][key] = value as never;
    }

    const newValidationErrors = [...validationErrors];
    newValidationErrors[index] = {
      ...newValidationErrors[index],
      [key]: undefined,
    };
    setValidationErrors(newValidationErrors);
    setAddresses(newAddresses);
  };

  // const handleInputChange = (
  //   index: number,
  //   key: keyof Address,
  //   value: string
  // ) => {
  //   const newAddresses = [...addresses];
  //   newAddresses[index][key] = value as never;

  //   const newValidationErrors = [...validationErrors];
  //   newValidationErrors[index] = {
  //     ...newValidationErrors[index],
  //     [key]: undefined,
  //   };
  //   setValidationErrors(newValidationErrors);
  //   setAddresses(newAddresses);
  // };

  const handleSelectChange = (index: number, value: string) => {
    const newAddresses = [...addresses];
    newAddresses[index]["AddressType"] = value;
    setAddresses(newAddresses);

    const newValidationErrors = [...validationErrors];
    newValidationErrors[index] = {
      ...newValidationErrors[index],
      AddressType: undefined,
    };
    setValidationErrors(newValidationErrors);
  };

  const handleCountryChange = (index: number, value: string) => {
    const newAddresses = [...addresses];

    getListByParentId("State", value)
      .then((data: any) => {
        const dataCollection = data?.Collection;
        const stateOptions = dataCollection.map((state: any) => ({
          value: state.Id,
          label: state.Name,
        }));
        setStates(stateOptions);

        // Update the validation error for CountryId field to undefined
        const newValidationErrors = [...validationErrors];
        newValidationErrors[index] = {
          ...newValidationErrors[index],
          CountryId: undefined,
        };
        setValidationErrors(newValidationErrors);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });

    newAddresses[index]["CountryId"] = value;
    setAddresses(newAddresses);
  };

  const fetchDataByUserId = async (userId: string) => {
    try {
      const responseData = await getListByParentId("UserAddress", userId);
      const addressesData = responseData.Collection;

      const hasDefaultAddress = addressesData.some(
        (address: Address) => address.IsDefaultAddress
      );

      setAnyDefaultAddress(hasDefaultAddress);
    } catch (error) {
      console.error(`Failed to fetch addresses:`, error);
    }
  };

  useEffect(() => {
    fetchDataByUserId(userProfileID);
  }, [userProfileID]);

  const handleStateChange = (index: number, value: string) => {
    const newAddresses = [...addresses];
    newAddresses[index]["StateId"] = value;
    setAddresses(newAddresses);
    const newValidationErrors = [...validationErrors];
    newValidationErrors[index] = {
      ...newValidationErrors[index],
      StateId: undefined,
    };
    setValidationErrors(newValidationErrors);
  };

  const handleAddAddress = () => {
    const newAddress: Address = {
      AddressType: "",
      userAddressId: userProfileID,
      AddressLine1: "",
      AddressLine2: "",
      AddressLine3: "",
      CountryId: "",
      StateId: "",
      stateOther: "",
      City: "",
      pincode: "",
      UserProfileId: userProfileID,
      IsDirty: true,
      IsNew: true,
      IsDefaultAddress: false,
    };
    setAddresses([...addresses, newAddress]);
    setCheckedStates((prevStates) => [...prevStates, false]); //new

    // To focus on newly added row first element "addressType"
    setTimeout(() => {
      focusOnField(`addressType-${addresses.length}`);
    }, 0); // Delay of 0 milliseconds
  };

  const handleClick = (index: number) => {
    const newCheckedStates = checkedStates.map((state, idx) => idx === index);
    setCheckedStates(newCheckedStates);

    const newAddresses = addresses.map((address, idx) => ({
      ...address,
      IsDefaultAddress: idx === index ? "true" : "false",
    }));
    setAddresses(newAddresses);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStartFileUpload(true);
    setTimeout(() => {
      focusOnErrorField(".p-invalid");
    }, 100);
    const errors: Array<Partial<Address>> = [];

    const requiredFields = [
      "AddressType",
      "AddressLine1",
      "CountryId",
      "StateId",
      "City",
      "pincode",
    ];
    addresses.forEach((address, index) => {
      const error: Partial<Address> = {};
      requiredFields.forEach((field) => {
        const fieldValue = address[field as keyof Address];
        if (
          !fieldValue ||
          (typeof fieldValue === "string" && fieldValue.trim() === "")
        ) {
          const label =
            createProfileLabelForValidation.find((item) => item.name === field)
              ?.label || field;
          error[field as keyof Address] = `${label} ${t("profile.isRequired")}`;
        }
      });
      errors[index] = error;
    });

    setValidationErrors(errors);

    if (errors.some((error) => Object.keys(error).length !== 0)) {
      return;
    }
    try {
      const postDataResponse = await postData(
        "UserAddress/BulkUpload",
        addresses
      );
      navigate("/profile");
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  const isAddressFilled = (address: Address): boolean => {
    return (
      address.AddressType.trim() !== "" &&
      address.AddressLine1.trim() !== "" &&
      address.CountryId.trim() !== "" &&
      address.StateId.trim() !== "" &&
      address.City.trim() !== "" &&
      address.pincode.trim() !== ""
    );
  };

  const areAllAddressesFilled = (): boolean => {
    return addresses.every(isAddressFilled);
  };

  return (
    <>
      <div className="card bg-white w-full mb-5 shadow-md">
        <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
          <Button
            text
            className="p-button-plain p-0 cursor-pointer"
            onClick={() => navigate("/profile/")}
            aria-label={t("profile.back")}
          >
            <FeatherIcon name="arrow-left" size={32} />
          </Button>
          <div className="flex flex-column gap-1">
            <h1 className="text-title text-lg font-bold m-0">
              {t("profile.addAddressDetail")}
            </h1>
            <p className="text-base font-normal m-0 text-help">
              {t("profile.allFieldsRequired")}
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="p-5 cardBody">
            {addresses.map((Address, index) => (
              <div
                className={`flex flex-column gap-3 mb-3 ${
                  index > 0 ? "border-top-1 border-gray-200 pt-5" : ""
                }`}
                key={index}
              >
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor={`addressType-${index}`}
                      className={`block font-bold ${
                        validationErrors[index]?.AddressType ? "p-error" : ""
                      }`}
                    >
                      {t("profile.addressType")}
                    </label>
                    <Dropdown
                      inputId={`addressType-${index}`}
                      aria-describedby={`addressTypeError-${index}`}
                      value={Address.AddressType}
                      options={addressType}
                      optionLabel="label"
                      onChange={(e) =>
                        handleSelectChange(index, e.target.value)
                      }
                      placeholder={t("profile.selectAnOption")}
                      className={
                        validationErrors[index]?.AddressType ? "p-invalid" : ""
                      }
                    />
                    <span
                      id={`addressTypeError-${index}`}
                      className={`p-error font-bold text-capitalize ${validationErrors[index]?.AddressType ? "" : "error-hidden"}`}
                    >
                      {validationErrors[index]?.AddressType}
                    </span>
                  </div>
                  {!anyDefaultAddress || Address.IsDefaultAddress ? (
                    <div className="flex col align-items-center mt-0 md:mt-5">
                      <Checkbox
                        inputId={`IsDefaultAddress-${index}`}
                        checked={Address.IsDefaultAddress}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "IsDefaultAddress",
                            e.checked
                          )
                        }
                      />
                      <label
                        htmlFor={`IsDefaultAddress-${index}`}
                        className="text-sm ml-2"
                      >
                        {t("profile.checkboxAddress")}
                      </label>
                    </div>
                  ) : null}
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor={`addressLine1-${index}`}
                      className={`block font-bold ${
                        validationErrors[index]?.AddressLine1 ? "p-error" : ""
                      }`}
                    >
                      {t("profile.addressLine1")}
                    </label>
                    <InputText
                      id={`addressLine1-${index}`}
                      aria-describedby={`addressLine1Error-${index}`}
                      value={Address.AddressLine1}
                      name="addressLine1"
                      onChange={(e) =>
                        handleInputChange(index, "AddressLine1", e.target.value)
                      }
                      placeholder={t("profile.placeholderAddress")}
                      className={
                        validationErrors[index]?.AddressType ? "p-invalid" : ""
                      }
                    />
                    <span
                      id={`addressLine1Error-${index}`}
                      className={`p-error font-bold text-capitalize ${validationErrors[index]?.AddressLine1 ? "" : "error-hidden"}`}
                    >
                      {validationErrors[index]?.AddressLine1}
                    </span>
                  </div>
                  <div className="xl:col-3 lg:col-4 md:col-6 col-12">
                    {/* <label
                      htmlFor="gender"
                      className="block font-bold text-input-label capitalize"
                    >
                      {" "}
                      Address Line 2 ({t("profile.optional")}){" "}
                    </label> */}
                    <Input
                      type="text"
                      label={`${t("profile.addressLine2")} (${t("profile.optional")})`}
                      value={Address.AddressLine2}
                      name="addressLine2"
                      onChange={(e) =>
                        handleInputChange(index, "AddressLine2", e)
                      }
                      placeholder={t("profile.placeholderAddress")}
                    />
                  </div>
                  <div className="xl:col-3 lg:col-4 md:col-6 col-12">
                    {/* <label
                      htmlFor="gender"
                      className="block font-bold text-input-label capitalize"
                    >
                      {" "}
                      Address Line 3 ({t("profile.optional")}){" "}
                    </label> */}
                    <Input
                      type="text"
                      label={`${t("profile.addressLine3")} (${t("profile.optional")})`}
                      value={Address.AddressLine3}
                      name="addressLine3"
                      onChange={(e) =>
                        handleInputChange(index, "AddressLine3", e)
                      }
                      placeholder={t("profile.placeholderAddress")}
                    />
                  </div>
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor={`country-${index}`}
                      className={`block font-bold ${
                        validationErrors[index]?.CountryId ? "p-error" : ""
                      }`}
                    >
                      {t("profile.country")}
                    </label>
                    <Dropdown
                      inputId={`country-${index}`}
                      aria-describedby={`countryError-${index}`}
                      value={Address.CountryId}
                      options={countries}
                      optionLabel="label"
                      onChange={(e) =>
                        handleCountryChange(index, e.target.value)
                      }
                      placeholder={t("profile.selectAnOption")}
                      className={
                        validationErrors[index]?.AddressType ? "p-invalid" : ""
                      }
                    />
                    <span
                      id={`countryError-${index}`}
                      className={`p-error font-bold text-capitalize ${validationErrors[index]?.CountryId ? "" : "error-hidden"}`}
                    >
                      {validationErrors[index]?.CountryId}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor={`state-${index}`}
                      className={`block font-bold ${
                        validationErrors[index]?.StateId ? "p-error" : ""
                      }`}
                    >
                      {t("profile.state")}
                    </label>
                    <Dropdown
                      inputId={`state-${index}`}
                      aria-describedby={`stateError-${index}`}
                      value={Address.StateId}
                      options={states}
                      optionLabel="label"
                      onChange={(e) => handleStateChange(index, e.target.value)}
                      placeholder={t("profile.selectAnOption")}
                      className={
                        validationErrors[index]?.StateId ? "p-invalid" : ""
                      }
                    />
                    <span
                      id={`stateError-${index}`}
                      className={`p-error font-bold text-capitalize ${validationErrors[index]?.StateId ? "" : "error-hidden"}`}
                    >
                      {validationErrors[index]?.StateId}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor={`city-${index}`}
                      className={`block font-bold ${
                        validationErrors[index]?.City ? "p-error" : ""
                      }`}
                    >
                      {t("profile.city")}
                    </label>
                    <InputText
                      id={`city-${index}`}
                      aria-describedby={`cityError-${index}`}
                      value={Address.City}
                      name="city"
                      onChange={(e) =>
                        handleInputChange(index, "City", e.target.value)
                      }
                      placeholder={t("profile.city")}
                      className={
                        validationErrors[index]?.City ? "p-invalid" : ""
                      }
                    />
                    <span
                      id={`cityError-${index}`}
                      className={`p-error font-bold text-capitalize ${validationErrors[index]?.City ? "" : "error-hidden"}`}
                    >
                      {validationErrors[index]?.City}
                    </span>
                  </div>
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor={`zipcode-${index}`}
                      className={`block font-bold ${
                        validationErrors[index]?.pincode ? "p-error" : ""
                      }`}
                    >
                      {t("profile.zipcode")}
                    </label>
                    <InputText
                      id={`zipcode-${index}`}
                      aria-describedby={`zipcodeError-${index}`}
                      value={Address.pincode}
                      name="zipcode"
                      placeholder={t("profile.zipcode")}
                      onChange={(e) =>
                        handleInputChange(index, "pincode", e.target.value)
                      }
                      className={
                        validationErrors[index]?.pincode ? "p-invalid" : ""
                      }
                    />
                    <span
                      id={`zipcodeError-${index}`}
                      className={`p-error font-bold text-capitalize ${validationErrors[index]?.pincode ? "" : "error-hidden"}`}
                    >
                      {validationErrors[index]?.pincode}
                    </span>
                  </div>
                </div>
              </div>
            ))}

            <Button
              text
              className="p-button-plain gap-2 pl-0 underline"
              onClick={handleAddAddress}
              disabled={!areAllAddressesFilled()}
            >
              <FeatherIcon name="plus" size={20} />
              <span>{t("profile.addCommunicationAddress")} </span>
            </Button>
          </div>
          {/* <DocumentUpload
            heading="Upload Document"
            startFileUpload={startFileUpload}
            documentType="UserProfile_Resume"
          ></DocumentUpload> */}
          <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-4 justify-content-end cardFooter">
            <div className="flex align-items-center gap-4">
              <Button
                label={t("profile.cancel")}
                className="button-md"
                severity="secondary"
                onClick={() => navigate("/profile")}
              />
              <Button
                label={t("profile.save")}
                type="submit"
                className="button-md"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddAddress;

import React, { useEffect, useState } from "react";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import Table from "../common/Table";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";

import {
  fetchData,
  getListByParentId,
  postData,
  putData,
} from "../../services/apiService";

interface CommitteeDataItem {
  IsDirty: true;
  id: string;
  IsNew: true;
  IsDefaultParent: boolean;
  CreatedDate: string;
  IsNatureofCommitteeRestricted: boolean;
  IsBalanceBasedOnOverAllThreshold: boolean;
  MinOverallThreshold: number;
  MaxOverallThreshold: number;
  ThresholdPercentage: number;
  IsBalanceBasedOnCategoryofInterests: boolean;
  IsBalanceBasedOnRepresentationByOrg: boolean;
  OrganisationMinMember: number;
  OrganisationMaxMember: number;
  OrganisationMemberThreshold: number;
  IsShowCommitteemembers: boolean;
  CommitteeMinMember: number;
  CommitteeMaxMember: number;
  IsCriteriaOfmembershipEligibility: boolean;
  MSElig_SubTierCommitte_Months: number;
  MSElig_SameCommitte_Officer_Months: number;
  IsTenure_ExpirationsRules: boolean;
  MSExpire_Officer_Tenure_Years: number;
  MSExpire_Member_Tenure_Years: number;
  IsShowAllMembershipExpires: boolean;
  SendNotificationforMembershipExpiration: boolean;
  NotifyStaffExpirationofMembership: boolean;
  NotifyStaffExpirationofMembershipDays_First: number;
  NotifyStaffExpirationofMembershipDays_Second: number;
  NotifyStaffExpirationofMembershipDays_Third: number;
  NotifyMemberExpirationofMembership: boolean;
  NotifyMemberExpirationofMembershipDays_First: number;
  NotifyMemberExpirationofMembershipDays_Second: number;
  NotifyMemberExpirationofMembershipDays_Third: number;
  OfficerMaximumTermYears: number;
  ApplicationAutoWithdrawalMonths: number;
  NotifyMemberLackofParticipation: boolean;
  NumberofMeetingMissed: number;
  NumberofBallotsMissed: number;
  TotalMeetingMisssedPercentage: number;
  TotalBallotMisssedPercentage: number;
  AcceptApplicationWithoutOpenPositions: boolean;
}

interface CommitteeCOIItem {
  id: string;
  IsDirty: true;
  IsNew: true;
  MinMember: number;
  MaxMember: number;
  Threshold: number;
}

const MemberConfigurationTab: React.FC = () => {
  const [t, i18n] = useTranslation("configuration");
  const [existingData, setExistingData] = useState<CommitteeDataItem | null>(
    null
  );
  const [existingCOIData, setExistingCOIData] =
    useState<CommitteeCOIItem | null>(null);
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const [value, setValue] = useState("");

  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex align-items-center">
              <h2 className="text-title text-lg font-bold text-capitalize m-0">
                {t("configuration.termsAndConditions")}
              </h2>
            </div>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <div className="card flex justify-content-center">
                <InputTextarea
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  rows={7}
                  cols={30}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex align-items-center">
              <h2 className="text-title text-lg font-bold text-capitalize m-0">
                {t("configuration.confidentialityAgreement")}
              </h2>
            </div>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <div className="card flex justify-content-center">
                <InputTextarea
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  rows={7}
                  cols={30}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("configuration.cancel")}
            className="button-md"
            severity="secondary"
            type="button"
            //onClick={() => navigate("/configuration")}
          />
          <Button
            label={t("configuration.save")}
            className="button-md"
            type="button"
            // onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default MemberConfigurationTab;

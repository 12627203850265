import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from "../common/FeatherIconComponent";
import { RootState } from "../../store/store";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import Input from "../../utils/Input";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import {
  deleteData,
  fetchData,
  getListByParentId,
  putData,
} from "../../services/apiService";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { usePermission } from "../auth/Authorization";
import { focusOnErrorField } from "../../utils/focusError";
import { focusOnField } from "../../utils/focusField";
interface EducationDetail {
  isNew: boolean;
  userProfileId: string;
  isActive: true;
  isDeleted: false;
  IsDirty?: boolean;
  DegreeId: string;
  UniversityId: string;
  FromYear: any;
  ToYear: any;
  CountryId: string;
  City: string;
  StateId: string;
  TenantId?: string;
  userEducationDetailId: string;
}
const EditEducation = () => {
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const createProfileDetails = useSelector(
    (state: RootState) => state.createProfile
  );
  const userProfileID = localStorage.getItem("userProfileID") || "";
  const [educationId, setEducationId] = useState<string>("");
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const [validationError, setValidationError] = useState<string | null>(null);
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [validationErrors, setValidationErrors] = useState<
    Array<Partial<EducationDetail>>
  >([]);

  React.useEffect(() => {
    if (
      createProfileDetails &&
      createProfileDetails.configureEducation &&
      createProfileDetails.configureEducation.educationId
    ) {
      setEducationId(createProfileDetails.configureEducation.educationId);
    } else {
      // Retrieve data from session storage if not available in Redux
      const experienceIdFromSessionStorage =
        sessionStorage.getItem("EducationID");
      setEducationId(experienceIdFromSessionStorage || educationId);
    }
  }, [createProfileDetails]);

  const [educationDetails, setEducationDetails] = useState<EducationDetail[]>([
    {
      isNew: true,
      userProfileId: userProfileID,
      isActive: true,
      isDeleted: false,
      IsDirty: true,
      DegreeId: "",
      UniversityId: "",
      FromYear: null,
      ToYear: null,
      CountryId: "",
      City: "",
      StateId: "",
      //TenantId: TENANT_ID,
      userEducationDetailId: userProfileID,
    },
  ]);
  const [degreeOptions, setDegreeOptions] = useState([]);
  const [universityOptions, setUniversityOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const handleInputChange = (
    index: number,
    key: keyof EducationDetail,
    value: any
  ) => {
    const newEducationDetails = [...educationDetails];
    newEducationDetails[index][key] = value as never;

    const newValidationErrors = [...validationErrors];
    newValidationErrors[index] = {
      ...newValidationErrors[index],
      [key]: undefined,
    };
    setValidationErrors(newValidationErrors);

    setEducationDetails(newEducationDetails);
  };

  const createProfileLabelForValidation = [
    { name: "DegreeId", label: t("profile.degree") },
    { name: "UniversityId", label: t("profile.university") },
    { name: "CountryId", label: t("profile.country") },
    { name: "StateId", label: t("profile.state") },
    { name: "City", label: t("profile.city") },
    { name: "FromYear", label: t("profile.yearFrom") },
    { name: "ToYear", label: t("profile.yearTo") },
  ];

  const onDegreeChange = (e: { value: any }) => {
    setEducationDetails((prevState) => ({
      ...prevState,
      degree: e.value,
    }));
  };

  const onUniversityChange = (e: any) => {
    setEducationDetails((prevState) => ({
      ...prevState,
      university: e.value,
    }));
  };

  const onCountryChange = (e: any) => {
    setEducationDetails((prevState) => ({
      ...prevState,
      university: e.value,
    }));
  };

  const onStateChange = (index: number, newStateId: string) => {
    const updatedEducationDetails = [...educationDetails];
    updatedEducationDetails[index].StateId = newStateId;
    setEducationDetails(updatedEducationDetails);
  };

  // For deleting record
  const handleDeleteEducation = async () => {
    try {
      if (educationId) {
        const postDataResponse = deleteData("UserEducationDetail", educationId);
        navigate("/profile/");
      } else {
        console.error("No id found in parameters.");
      }
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  const confirmDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    confirmDialog({
      header: t("profile.confirmation"),
      message: t("profile.areYouSureYouWantToDelete"),
      icon: "",
      defaultFocus: "accept",
      acceptLabel: t("profile.yes"),
      rejectLabel: t("profile.no"),
      accept: handleDeleteEducation,
      reject: () => console.log("Deletion rejected."),
    });
  };

  useEffect(() => {
    fetchDataByID();
  }, [educationId]);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setTimeout(() => {
      focusOnErrorField(".p-invalid");
    }, 100);
    const errors: Array<Partial<EducationDetail>> = [];

    const requiredFields = [
      "DegreeId",
      "UniversityId",
      "CountryId",
      "StateId",
      "City",
      "FromYear",
      "ToYear",
    ];

    educationDetails.forEach((education, index) => {
      const error: Partial<EducationDetail> = {};

      requiredFields.forEach((field) => {
        const fieldValue = education[field as keyof EducationDetail];
        if (
          !fieldValue ||
          (typeof fieldValue === "string" && fieldValue.trim() === "")
        ) {
          // Find the corresponding label name from createProfileLabelForValidation
          const label =
            createProfileLabelForValidation.find((item) => item.name === field)
              ?.label || field;
          error[field as keyof EducationDetail] =
            `${label} ${t("profile.isRequired")}`;
        }
      });

      errors[index] = error;
    });

    setValidationErrors(errors);
    if (errors.some((error) => Object.keys(error).length !== 0)) {
      return;
    }
    try {
      if (educationId) {
        const postDataResponse = putData(
          "UserEducationDetail",
          educationId,
          educationDetails[0]
        );
        navigate("/profile/");
        fetchDataByID();
      } else {
        console.error("No id found in parameters.");
      }
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  useEffect(() => {
    fetchDataDegrees();
    fetchDataUniversity();
    fetchDataCountry();
  }, []);

  useEffect(() => {
    fetchDataByID();
  }, []);

  const fetchDataDegrees = async () => {
    try {
      const postDataResponse = await fetchData("Degree/getAll");
      const formattedDegreeOptions = postDataResponse.Collection.map(
        (item: { Name: any; Id: any }) => ({
          label: item.Name,
          value: item.Id,
        })
      );
      setDegreeOptions(formattedDegreeOptions);
    } catch (error) {
      console.error("Error fetching degrees:", error);
    }
  };

  // Get all University
  const fetchDataUniversity = async () => {
    try {
      const postDataResponse = await fetchData("University/getAll");
      const formattedUniversityOptions = postDataResponse.Collection.map(
        (item: { Name: any; Id: any }) => ({
          label: item.Name,
          value: item.Id,
        })
      );
      setUniversityOptions(formattedUniversityOptions);
    } catch (error) {
      console.error("Error fetching degrees:", error);
    }
  };

  // Get all Country
  const fetchDataCountry = async () => {
    try {
      const postDataResponse = await fetchData("Country/getAll");
      const formattedCountryOptions = postDataResponse.Collection.map(
        (item: { Name: any; Id: any }) => ({
          label: item.Name,
          value: item.Id,
        })
      );
      setCountryOptions(formattedCountryOptions);
    } catch (error) {
      console.error("Error fetching degrees:", error);
    }
  };

  // For getting all records through parent user id
  const fetchDataByID = async () => {
    try {
      const responseData = await fetchData("UserEducationDetail", educationId);
      setEducationDetails([responseData]);
      const countryId = responseData.CountryId;
      fetchDataState(countryId);
    } catch (error) {
      // Handle error
      console.error("Failed to fetch data:", error);
    }
  };

  const fetchDataState = async (countryId: any) => {
    try {
      const data = await getListByParentId("State", countryId);
      const dataCollection = data?.Collection;
      const stateOptions = dataCollection.map((state: any) => ({
        value: state.Id,
        label: state.Name,
      }));
      setStateOptions(stateOptions);
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  useEffect(() => {
    fetchDataByID();
  }, [id]);

  const fetchCountryByID = async (countryId: any) => {
    try {
      const responseData = await fetchData("Country", countryId);
      const countryName = responseData.Name;

      getListByParentId("State", countryId)
        .then((data: any) => {
          const dataCollection = data?.Collection;
          const stateOptions = dataCollection.map((state: any) => ({
            value: state.Id,
            label: state.Name,
          }));

          setStateOptions(stateOptions);
        })
        .catch((error) => {
          console.error("Error fetching countries:", error);
        });
    } catch (error) {
      console.error("Error fetching data from AnotherAPIEndpoint:", error);
    }
  };

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              aria-label={t("profile.back")}
              onClick={() => navigate("/profile/")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("profile.editEducationalDetails")}
              </h1>
              <p className="text-base font-normal m-0 text-help">
                {t("profile.allFieldsRequired")}
              </p>
            </div>
          </div>
          <ConfirmDialog className="custom-dialog hide-close" ariaCloseIconLabel="Close" />
          <form onSubmit={handleSubmit}>
            <div className="p-5 cardBody">
              {educationDetails.map((education, index) => (
                <div
                  className={`flex flex-column gap-3 mb-3 ${
                    index > 0 ? "border-top-1 border-gray-200 pt-5" : ""
                  }`}
                  key={index}
                >
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="degree"
                        className={`block font-bold ${
                          validationErrors[index]?.DegreeId ? "p-error" : ""
                        }`}
                      >
                        {t("profile.degree")}
                      </label>
                      <Dropdown
                        inputId="degree"
                        aria-describedby="degreeError"
                        value={education.DegreeId}
                        onChange={(e) =>
                          handleInputChange(index, "DegreeId", e.value)
                        }
                        className={
                          validationErrors[index]?.DegreeId ? "p-invalid" : ""
                        }
                        options={degreeOptions}
                        optionLabel="label"
                        placeholder={t("profile.selectAnOption")}
                        aria-labelledby="degree"
                      />
                      <span
                        id="degreeError"
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.DegreeId ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.DegreeId}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="university"
                        className={`block font-bold ${
                          validationErrors[index]?.UniversityId ? "p-error" : ""
                        }`}
                      >
                        {t("profile.university")}
                      </label>

                      <Dropdown
                        inputId="university"
                        aria-describedby="universityError"
                        value={education.UniversityId}
                        onChange={(e) =>
                          handleInputChange(index, "UniversityId", e.value)
                        }
                        className={
                          validationErrors[index]?.UniversityId
                            ? "p-invalid"
                            : ""
                        }
                        options={universityOptions}
                        optionLabel="label"
                        placeholder={t("profile.selectAnOption")}
                        aria-labelledby="university"
                      />
                      <span
                        id="universityError"
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.UniversityId ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.UniversityId}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="yearfrom"
                        className={`block font-bold ${
                          validationErrors[index]?.FromYear ? "p-error" : ""
                        }`}
                      >
                        {t("profile.from")}
                      </label>
                      <Calendar
                        appendTo="self"
                        maxDate={
                          education.ToYear
                            ? new Date(education.ToYear)
                            : undefined
                        }
                        value={
                          education.FromYear
                            ? new Date(education.FromYear)
                            : null
                        }
                        onChange={(e) =>
                          handleInputChange(index, "FromYear", e.target.value)
                        }
                        // onSelect={() => focusOnField(`yearFrom-${index}`)}
                        inputId="yearfrom"
                        aria-describedby="yearfromError"
                        placeholder={t("profile.from")}
                        dateFormat="mm/dd/yy"
                        className={`custom-datepicker ${
                          validationErrors[index]?.FromYear ? "p-invalid" : ""
                        }`}
                      />
                      <span
                        id="yearfromError"
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.FromYear ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.FromYear}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="yearTo"
                        className={`block font-bold ${
                          validationErrors[index]?.ToYear ? "p-error" : ""
                        }`}
                      >
                        {t("profile.to")}
                      </label>
                      <Calendar
                        appendTo="self"
                        minDate={
                          education.FromYear
                            ? new Date(education.FromYear)
                            : undefined
                        }
                        value={
                          education.ToYear ? new Date(education.ToYear) : null
                        }
                        onChange={(e) =>
                          handleInputChange(index, "ToYear", e.target.value)
                        }
                        // onSelect={() => focusOnField(`yearTo-${index}`)}
                        inputId="yearTo"
                        aria-describedby="yearToError"
                        placeholder={t("profile.to")}
                        className={`custom-datepicker ${
                          validationErrors[index]?.ToYear ? "p-invalid" : ""
                        }`}
                      />
                      <span
                        id="yearToError"
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.ToYear ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.ToYear}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`country-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.CountryId ? "p-error" : ""
                        }`}
                      >
                        {t("profile.country")}
                      </label>
                      <Dropdown
                        inputId={`country-${index}`}
                        aria-describedby={`countryError-${index}`}
                        value={education.CountryId}
                        onChange={(e) => {
                          const newValue = e.value;
                          handleInputChange(index, "CountryId", newValue);
                          fetchCountryByID(newValue);
                        }}
                        className={
                          validationErrors[index]?.CountryId ? "p-invalid" : ""
                        }
                        options={countryOptions}
                        optionLabel="label"
                        placeholder={t("profile.selectAnOption")}
                      />
                      <span
                        id={`countryError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.CountryId ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.CountryId}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`state-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.StateId ? "p-error" : ""
                        }`}
                      >
                        {t("profile.state")}
                      </label>
                      <Dropdown
                        inputId={`state-${index}`}
                        aria-describedby={`stateError-${index}`}
                        value={education.StateId}
                        onChange={(e) =>
                          handleInputChange(index, "StateId", e.value)
                        }
                        className={
                          validationErrors[index]?.StateId ? "p-invalid" : ""
                        }
                        options={stateOptions}
                        optionLabel="label"
                        placeholder={t("profile.selectAnOption")}
                      />
                      <span
                        id={`stateError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.StateId ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.StateId}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`city-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.City ? "p-error" : ""
                        }`}
                      >
                        {t("profile.city")}
                      </label>
                      <InputText
                        id={`city-${index}`}
                        aria-describedby={`cityError-${index}`}
                        type="text"
                        value={education.City}
                        name={`city_${index}`}
                        onChange={(e) =>
                          handleInputChange(index, "City", e.target.value)
                        }
                        placeholder={t("profile.city")}
                        className={
                          validationErrors[index]?.City ? "p-invalid" : ""
                        }
                      />
                      <span
                        id={`cityError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.City ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.City}
                      </span>
                    </div>
                  </div>
                </div>
              ))}

              <div
                className={`p-error font-bold text-capitalize ${validationError ? "" : "error-hidden"}`}
              >
                {validationError}
              </div>
            </div>
            <div className="flex flex-wrap align-items-center px-5 py-3 border-top-1 border-gray-200 gap-3 md:gap-4 justify-content-between cardFooter">
              <div>
                <Button
                  text
                  className="p-button-plain gap-2 px-2 md:pr-3 md:pl-0 text-delete underline"
                  onClick={confirmDelete}
                  aria-label={t("profile.deleteEducation")}
                >
                  <FeatherIcon name="trash-2" size={20} />
                  <span className="hidden md:flex">
                    {t("profile.deleteEducation")}
                  </span>
                </Button>
              </div>
              <Button
                label={t("profile.cancel")}
                className="button-md ml-auto w-auto"
                severity="secondary"
                onClick={() => navigate("/profile/")}
              />
              <Button label={t("profile.save")} className="button-md w-auto" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditEducation;

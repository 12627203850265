import React, { useState, useRef } from "react";
import FeatherIcon from "../../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OverlayPanel } from "primereact/overlaypanel"; // Import OverlayPanel
import Table from "../../common/Table";
import { useLanguageContext } from "../../LanguageContext";
import { useTranslation } from "react-i18next";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
interface Option {
  name: string;
  code: string;
}

const StandardTable: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: t("record.dashboard"), url: "/dashboard" },
    { label: t("record.records") },
    { label: t("record.standardRecords") },
  ];

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const navigate = useNavigate();

  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  return (
    <>
      <div className="flex flex-wrap md:flex-nowrap md:justify-content-between align-items-center py-3 gap-3 w-full">
        <IconField iconPosition="left" className="w-full md:w-16rem">
          <InputIcon className="pi pi-search"> </InputIcon>
          <InputText
            id="searchCommittee"
            placeholder={t("record.searchCommittee")}
            className="w-full"
            aria-label={t("record.searchCommittee")}
          />
        </IconField>
        <div className="flex flex-wrap md:flex-nowrap gap-3 w-full md:w-auto align-items-center">
          <Dropdown
            inputId="selectCommittee"
            value={selectedOption}
            onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
            options={options}
            optionLabel="name"
            placeholder={t("record.selectCommittee")}
            className="w-full md:w-16rem"
            aria-label={t("record.selectCommittee")}
          />
          <div className="flex gap-3 w-full md:w-auto align-items-center">
            <div className="relative">
              <Button
                className="button-md gap-1"
                severity="secondary"
                onClick={toggleFilterPanel}
                aria-label={t("record.filter")}
              >
                <FeatherIcon name="filter" size={20} color="inherit" />
                <span className="font-bold text-capitalize hidden md:flex">
                  {t("record.filter")}
                </span>
              </Button>
              {/* OverlayPanel for Filter */}
              <OverlayPanel
                ref={overlayPanelRef}
                onHide={() => setShowFilterPanel(false)}
                appendTo={"self"}
                dismissable
                className="p-overlaypanel-filter left-auto md:right-0 top-100"
                aria-labelledby="filter-heading"
                role="dialog"
              >
                <div className="flex flex-column gap-1" tabIndex={0}>
                  <p id="filter-heading" className="text-lg font-bold m-0 p-3">
                    {t("record.filter")}
                  </p>
                  <div className="filter-wrap">
                    {/* Add filter fields here */}
                  </div>
                  <Button
                    className="button-md w-full justify-content-center"
                  >
                    {t("record.applyFilter")}
                  </Button>
                </div>
              </OverlayPanel>
            </div>
            <Button className="button-md gap-1 justify-content-center w-full md:w-auto" onClick={() => navigate("/record/standard/create")}>
              <FeatherIcon name="plus" size={20} color="inherit" />
              <span className="font-bold text-capitalize">{t("record.newRecord")}</span>
            </Button>
          </div>
        </div>
      </div>

      <Table
        loading={false}
        products={[]}
        columns={[
          { field: "recordId", header: t("record.recordId") },
          { field: "standardId", header: t("record.standardId") },
          { field: "workingCommittee", header: t("record.workingCommittee") },
          { field: "projectTechnicalManager", header: t("record.projectTechnicalManager") },
          { field: "positionApplied", header: t("record.positionApplied") },
          { field: "readyForBalloting", header: t("record.readyForBalloting") },
          { field: "createdOn", header: t("record.createdOn") },
          { field: "status", header: t("record.status") },
          { field: "action", header: t("record.action") },
        ]}
      />
    </>
  );
};

export default StandardTable;

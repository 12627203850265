import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../store/store";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Input from "../../utils/Input";
import { Calendar } from "primereact/calendar";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
  deleteData,
  fetchData,
  getAllCountries,
  getListByParentId,
  putData,
} from "../../services/apiService";
import { InputText } from "primereact/inputtext";
import { usePermission } from "../auth/Authorization";
import { focusOnErrorField } from "../../utils/focusError";
interface LicensedEngineer {
  LicenseNumber: string;
  LicenseYear: number | string;
  LicenseName: string;
  StateId: string;
  CountryId: string;
  City: string;
}

const EditProfessionalLicenses = () => {
  const [t, i18n] = useTranslation("profile");
  const [countries, setCountries] = useState<any[]>([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [licesesId, setLicesesId] = useState<string>("");
  //const licesesId = localStorage.getItem("licesesId") || "";
  const [validationErrors, setValidationErrors] = useState<
    Array<Partial<LicensedEngineer>>
  >([]);
  const createProfileDetails = useSelector(
    (state: RootState) => state.createProfile
  );
  const { selectedLanguage } = useLanguageContext();
  const navigate = useNavigate();
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  React.useEffect(() => {
    if (
      createProfileDetails &&
      createProfileDetails.configureLicenses &&
      createProfileDetails.configureLicenses.licesesId
    ) {
      setLicesesId(createProfileDetails.configureLicenses.licesesId);
    } else {
      // Retrieve data from session storage if not available in Redux
      const experienceIdFromSessionStorage =
        sessionStorage.getItem("licesesId");
      setLicesesId(experienceIdFromSessionStorage || licesesId);
    }
  }, [createProfileDetails]);

  const confirmDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    confirmDialog({
      header: t("profile.confirmation"),
      message: t("profile.areYouSureYouWantToDelete"),
      icon: "",
      acceptLabel: t("profile.yes"),
      rejectLabel: t("profile.no"),
      defaultFocus: "accept",
      accept: () =>
        handleDeleteLicense({} as React.MouseEvent<HTMLButtonElement>),
      reject: () => navigate("/profile/"),
    });
  };

  const [licensedEngineers, setLicensedEngineers] = useState<
    LicensedEngineer[]
  >([
    {
      LicenseNumber: "",
      LicenseYear: 0,
      LicenseName: "",
      StateId: "",
      CountryId: "",
      City: "",
    },
  ]);

  const createProfileLabelForValidation = [
    { name: "LicenseNumber", label: t("profile.licensedNumber") },
    { name: "LicenseName", label: t("profile.licensedName") },
    { name: "LicenseYear", label: t("profile.licensedYear") },
    { name: "CountryId", label: t("profile.country") },
    { name: "StateId", label: t("profile.state") },
  ];

  React.useEffect(() => {
    getAllCountries()
      .then((data: any) => {
        const dataCollection = data?.Collection;
        const countryOptions = dataCollection.map((country: any) => ({
          value: country.Id,
          label: country.Name,
        }));
        setCountries(countryOptions);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const handleCountryChange = (index: number, value: string) => {
    const newProfessionalLicense = [...licensedEngineers];
    newProfessionalLicense[index]["CountryId"] = value;
    // Update the validation error for CountryId field to undefined
    const newValidationErrors = [...validationErrors];
    newValidationErrors[index] = {
      ...newValidationErrors[index],
      CountryId: undefined,
    };
    setValidationErrors(newValidationErrors);
    setLicensedEngineers(newProfessionalLicense);
  };

  const fetchDataState = async (countryId: any) => {
    try {
      const data = await getListByParentId("State", countryId);
      const dataCollection = data?.Collection;
      const stateOptions = dataCollection.map((state: any) => ({
        value: state.Id,
        label: state.Name,
      }));
      setStateOptions(stateOptions);
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const handleStateChange = (index: number, value: string) => {
    const newProfessionalLicense = [...licensedEngineers];
    newProfessionalLicense[index]["StateId"] = value;
    // Update the validation error for State field to undefined
    const newValidationErrors = [...validationErrors];
    newValidationErrors[index] = {
      ...newValidationErrors[index],
      StateId: undefined,
    };
    setValidationErrors(newValidationErrors);
    setLicensedEngineers(newProfessionalLicense);
  };

  const handleDeleteRow = (index: number) => {
    const newLicensedEngineers = [...licensedEngineers];
    newLicensedEngineers.splice(index, 1);
    setLicensedEngineers(newLicensedEngineers);
  };

  const years = [
    { label: "2024", value: 2024 },
    { label: "2023", value: 2023 },
    { label: "2022", value: 2022 },
    { label: "2021", value: 2021 },
    { label: "2020", value: 2020 },
    { label: "2019", value: 2019 },
    { label: "2018", value: 2018 },
    { label: "2017", value: 2017 },
    { label: "2016", value: 2016 },
    { label: "2015", value: 2015 },
    { label: "2014", value: 2014 },
    { label: "2013", value: 2013 },
    { label: "2012", value: 2012 },
    { label: "2011", value: 2011 },
    { label: "2010", value: 2010 },
    { label: "2009", value: 2009 },
    { label: "2008", value: 2008 },
    { label: "2007", value: 2007 },
    { label: "2006", value: 2006 },
    { label: "2005", value: 2005 },
    { label: "2004", value: 2004 },
    { label: "2003", value: 2003 },
    { label: "2002", value: 2002 },
    { label: "2001", value: 2001 },
    { label: "2000", value: 2000 },
    { label: "1999", value: 1999 },
    { label: "1998", value: 1998 },
    { label: "1997", value: 1997 },
    { label: "1996", value: 1996 },
    { label: "1995", value: 1995 },
    { label: "1994", value: 1994 },
    { label: "1993", value: 1993 },
    { label: "1992", value: 1992 },
    { label: "1991", value: 1991 },
    { label: "1990", value: 1990 },
  ];

  const fetchDataByID = async () => {
    try {
      const responseData = await fetchData(
        "UserProfessionalLicense",
        licesesId
      );
      const countryId = responseData.CountryId;
      fetchDataState(countryId);
      setLicensedEngineers([responseData]);
    } catch (error) {
      // Handle error
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    fetchDataByID();
  }, [licesesId]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setTimeout(() => {
      focusOnErrorField(".p-invalid");
    }, 100);
    const errors: Array<Partial<LicensedEngineer>> = [];
    const requiredFields = [
      "LicenseNumber",
      "LicenseName",
      "LicenseYear",
      "CountryId",
      "StateId",
    ];
    licensedEngineers.forEach((license, index) => {
      const error: Partial<LicensedEngineer> = {};
      requiredFields.forEach((field) => {
        const fieldValue = license[field as keyof LicensedEngineer];
        if (
          !fieldValue ||
          (typeof fieldValue === "string" && fieldValue.trim() === "")
        ) {
          const label =
            createProfileLabelForValidation.find((item) => item.name === field)
              ?.label || field;
          error[field as keyof LicensedEngineer] = `${label} ${t("profile.isRequired")}`;
        }
      });

      errors[index] = error;
    });

    setValidationErrors(errors);

    if (errors.some((error) => Object.keys(error).length !== 0)) {
      return;
    }
    try {
      if (licesesId) {
        await putData(
          "UserProfessionalLicense",
          licesesId,
          licensedEngineers[0]
        );
        navigate("/profile/");
        fetchDataByID();
      } else {
        console.error("No id found in parameters.");
      }
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  // For deleting record
  const handleDeleteLicense = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    try {
      if (licesesId) {
        const postDataResponse = deleteData(
          "UserProfessionalLicense",
          licesesId
        );
        navigate("/profile/");
      } else {
        console.error("No id found in parameters.");
      }
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  const handleInputChange = (
    index: number,
    key: keyof LicensedEngineer,
    value: string
  ) => {
    const newLicensedEngineers = [...licensedEngineers];
    newLicensedEngineers[index][key] = value as never;

    const newValidationErrors = [...validationErrors];
    newValidationErrors[index] = {
      ...newValidationErrors[index],
      [key]: undefined,
    };
    setValidationErrors(newValidationErrors);
    setLicensedEngineers(newLicensedEngineers);
  };
  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              aria-label={t("profile.back")}
              onClick={() => navigate("/profile")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("profile.editLicenseDetails")}
              </h1>
              <p className="text-base font-normal m-0 text-help">
                {t("profile.allFieldsRequired")}
              </p>
            </div>
          </div>
          <ConfirmDialog className="custom-dialog hide-close" ariaCloseIconLabel="Close" />
          <form onSubmit={handleSubmit}>
            <div className="p-5 cardBody">
              {licensedEngineers.map((license, index) => (
                <div
                  className={`flex flex-column gap-3 mb-3 ${index > 0 ? "border-top-1 border-gray-200 pt-5" : ""
                    }`}
                  key={index}
                >
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`licenseNumber-${index}`}
                        className={`block font-bold ${validationErrors[index]?.LicenseNumber
                            ? "p-error"
                            : ""
                          }`}
                      >
                        {t("profile.licensedNumber")}
                      </label>
                      <InputText
                        id={`licenseNumber-${index}`}
                        aria-describedby={`licenseNumberError-${index}`}
                        value={license.LicenseNumber}
                        name={`licenseNumber_${index}`}
                        onChange={(e: { target: { value: string } }) =>
                          handleInputChange(
                            index,
                            "LicenseNumber",
                            e.target.value
                          )
                        }
                        className={
                          validationErrors[index]?.LicenseNumber
                            ? "p-invalid"
                            : ""
                        }
                        placeholder="license Number"
                      />
                      <span id={`licenseNumberError-${index}`} className={`p-error font-bold text-capitalize ${validationErrors[index]?.LicenseNumber ? "" : "error-hidden"}`}>
                        {validationErrors[index]?.LicenseNumber}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`licenseYear-${index}`}
                        className={`block font-bold ${validationErrors[index]?.LicenseYear ? "p-error" : ""
                          }`}
                      >
                        {t("License Year")}
                      </label>
                      <Dropdown
                        inputId={`licenseYear-${index}`}
                        aria-describedby={`licenseYearError-${index}`}
                        value={license.LicenseYear ? license.LicenseYear : null}
                        options={years}
                        optionLabel="label"
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "LicenseYear",
                            e.target.value
                          )
                        }
                        placeholder={t("profile.selectAnOption")}
                        className={
                          validationErrors[index]?.LicenseYear
                            ? "p-invalid"
                            : ""
                        }
                      />
                      <span id={`licenseYearError-${index}`} className={`p-error font-bold text-capitalize ${validationErrors[index]?.LicenseYear ? "" : "error-hidden"}`}>
                        {validationErrors[index]?.LicenseYear}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`licenseName-${index}`}
                        className={`block font-bold ${validationErrors[index]?.LicenseName ? "p-error" : ""
                          }`}
                      >
                        {t("profile.licensedName")}
                      </label>
                      <InputText
                        id={`licenseName-${index}`}
                        aria-describedby={`licenseNameError-${index}`}
                        value={license.LicenseName}
                        name={`LicenseName_${index}`}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "LicenseName",
                            e.target.value
                          )
                        }
                        placeholder={t("profile.licensedName")}
                        className={
                          validationErrors[index]?.LicenseName
                            ? "p-invalid"
                            : ""
                        }
                      />
                      <span id={`licenseNameError-${index}`} className={`p-error font-bold text-capitalize ${validationErrors[index]?.LicenseName ? "" : "error-hidden"}`}>
                        {validationErrors[index]?.LicenseName}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`country-${index}`}
                        className={`block font-bold ${validationErrors[index]?.CountryId ? "p-error" : ""
                          }`}
                      >
                        {t("profile.country")}
                      </label>
                      <Dropdown
                        inputId={`country-${index}`}
                        aria-describedby={`countryError-${index}`}
                        value={license.CountryId}
                        options={countries}
                        optionLabel="label"
                        onChange={(e) =>
                          handleCountryChange(index, e.target.value)
                        }
                        placeholder={t("profile.selectAnOption")}
                        className={
                          validationErrors[index]?.CountryId ? "p-invalid" : ""
                        }
                      />
                      <span id={`countryError-${index}`} className={`p-error font-bold text-capitalize ${validationErrors[index]?.CountryId ? "" : "error-hidden"}`}>
                        {validationErrors[index]?.CountryId}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`state-${index}`}
                        className={`block font-bold ${validationErrors[index]?.StateId ? "p-error" : ""
                          }`}
                      >
                        {t("profile.state")}
                      </label>
                      <Dropdown
                        inputId={`state-${index}`}
                        aria-describedby={`stateError-${index}`}
                        value={license.StateId}
                        options={stateOptions}
                        optionLabel="label"
                        onChange={(e) =>
                          handleStateChange(index, e.target.value)
                        }
                        placeholder={t("profile.selectAnOption")}
                        className={`${validationErrors[index]?.StateId ? "p-invalid" : ""
                          }`}
                      />
                      <div id={`stateError-${index}`} className={`p-error font-bold text-capitalize ${validationErrors[index]?.StateId ? "" : "error-hidden"}`}>
                        {validationErrors[index]?.StateId}
                      </div>
                    </div>
                    <div
                      className="xl:col-3 lg:col-4 md:col-6 col-12"
                      style={{ display: "none" }}
                    >
                      <Input
                        type="text"
                        label={t("profile.city")}
                        value={license.City}
                        name={`city_${index}`}
                        onChange={(e) => handleInputChange(index, "City", e)}
                        placeholder="City"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-wrap align-items-center px-5 py-3 border-top-1 border-gray-200 gap-3 md:gap-4 justify-content-between cardFooter">
              <Button
                text
                className="p-button-plain gap-2 px-2 md:pr-3 md:pl-0 text-delete underline"
                onClick={confirmDelete}
                aria-label={t("profile.deleteLicense")}
              >
                <FeatherIcon name="trash-2" size={20} />
                <span className="hidden md:flex">{t("profile.deleteLicense")}</span>
              </Button>
              <Button
                label={t("profile.cancel")}
                className="button-md ml-auto w-auto"
                severity="secondary"
                onClick={() => navigate("/profile")}
              />
              <Button label={t("profile.save")} className="button-md w-auto" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditProfessionalLicenses;

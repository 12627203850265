import React from "react";
import ReactDOM from "react-dom/client";
import {
  PublicClientApplication,
  EventType,
  AccountInfo,
  AuthenticationResult,
} from "@azure/msal-browser";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import global_en from "./translations/en/global.json";
import global_fr from "./translations/fr/global.json";
import global_gr from "./translations/gr/global.json";
import auth_en from "./translations/en/authTranslation.json";
import auth_fr from "./translations/fr/authTranslation.json";
import auth_gr from "./translations/gr/authTranslation.json";
import profile_en from "./translations/en/profileTranslation.json";
import profile_fr from "./translations/fr/profileTranslation.json";
import profile_gr from "./translations/gr/profileTranslation.json";
import committee_en from "./translations/en/committeeTranslation.json";
import committee_fr from "./translations/fr/committeeTranslation.json";
import committee_gr from "./translations/gr/committeeTranslation.json";
import member_en from "./translations/en/memberTranslation.json";
import member_fr from "./translations/fr/memberTranslation.json";
import member_gr from "./translations/gr/memberTranslation.json";
import record_en from "./translations/en/recordTranslation.json";
import record_fr from "./translations/fr/recordTranslation.json";
import record_gr from "./translations/gr/recordTranslation.json";
import ballot_en from "./translations/en/ballotTranslation.json";
import ballot_fr from "./translations/fr/ballotTranslation.json";
import ballot_gr from "./translations/gr/ballotTranslation.json";
import configuration_en from "./translations/en/configurationTranslation.json";
import configuration_fr from "./translations/fr/configurationTranslation.json";
import configuration_gr from "./translations/gr/configurationTranslation.json";
import report_en from "./translations/en/reportTranslation.json";
import report_fr from "./translations/fr/reportTranslation.json";
import report_gr from "./translations/gr/reportTranslation.json";
import request_en from "./translations/en/requestTranslation.json";
import request_fr from "./translations/fr/requestTranslation.json";
import request_gr from "./translations/gr/requestTranslation.json";
import standard_en from "./translations/en/standardTranslation.json";
import standard_fr from "./translations/fr/standardTranslation.json";
import standard_gr from "./translations/gr/standardTranslation.json";

import i18next from "i18next";
import { Provider } from "react-redux";
import store from "./store/store";
import { ThemeProvider } from "./ThemeContext";
import { PrimeReactProvider } from "primereact/api";
import { msalConfig } from "./config";
import { setEnvInfo } from "./utils/storage";

i18next.init({
  interpolation: {
    escapeValue: false,
  },
  lng: "en",
  resources: {
    en: {
      global: global_en,
      auth: auth_en,
      profile: profile_en,
      committee: committee_en,
      member: member_en,
      record: record_en,
      ballot: ballot_en,
      configuration: configuration_en,
      report: report_en,
      request: request_en,
      standard: standard_en,
    },
    fr: {
      global: global_fr,
      auth: auth_fr,
      profile: profile_fr,
      committee: committee_fr,
      member: member_fr,
      record: record_fr,
      ballot: ballot_fr,
      configuration: configuration_fr,
      report: report_fr,
      request: request_fr,
      standard: standard_fr,
    },
    gr: {
      global: global_gr,
      auth: auth_gr,
      profile: profile_gr,
      committee: committee_gr,
      member: member_gr,
      record: record_gr,
      ballot: ballot_gr,
      configuration: configuration_gr,
      report: report_gr,
      request: request_gr,
      standard: standard_gr,
    },
  },
});

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const authResult = event.payload as AuthenticationResult;
    msalInstance.setActiveAccount(authResult.account as AccountInfo);
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Ensure the MSAL instance is properly initialized before rendering the app
const initializeMsal = async () => {
  try {
    await msalInstance.initialize();
    root.render(
      <ThemeProvider>
        <I18nextProvider i18n={i18next}>
          <Provider store={store}>
            <App instance={msalInstance} />
          </Provider>
        </I18nextProvider>
      </ThemeProvider>
    );
  } catch (error) {
    console.error("Failed to initialize MSAL", error);
  }
};

// Call the initialization function
initializeMsal();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { fetchData } from "../../services/apiService";

const fetchRecordRequest = async (memberId: string | null | undefined) => {
  try {
    const responseData = await fetchData("Record", memberId);
    return responseData;
  } catch (error) {
    console.error("Error fetching Category of Interest:", error);
  }
};

const fetchAllRecords = async () => {
  try {
    const responseData = await fetchData("Record/GetAll");
    return responseData;
  } catch (error) {
    console.error("Error fetching Category of Interest:", error);
  }
};

const fetchRecordDetailById = async (id: string) => {
  try {
    const responseData = await fetchData(`Record/${id}`, id);
    return responseData;
  } catch (error) {
    console.error("Error fetching Record", error);
  }
};

export { fetchRecordRequest, fetchAllRecords, fetchRecordDetailById };

import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

interface DonutChartProps {
  data: number[];
  labels: string[];
  backgroundColors: string[];
  options?: any;
}


const DonutChart: React.FC<DonutChartProps> = ({ data, labels, backgroundColors }) => {
    const chartData = {
     // labels,
      datasets: [
        {
          data,
          backgroundColor: backgroundColors,
          hoverBackgroundColor: backgroundColors,
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right' as const,
        },
      },
    };
  
    return <Doughnut data={chartData} options={options} />;
  };

  export default DonutChart;

import React, { useState, useRef, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import StandardTable from "./StandardTable";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { useNavigate } from "react-router-dom";

const StandardRequests: React.FC = () => {
  const [t, i18n] = useTranslation("request");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: t("request.dashboard"), url: "/dashboard" },
    { label: t("request.standardRequests") },
  ];

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const navigate = useNavigate();

  const handleNewRequestClick = () => {
    switch (activeIndex) {
      case 0:
        navigate("/request/standard/new");
        break;
      case 1:
        navigate("/request/standard/technical-revision");
        break;
      case 2:
        navigate("/request/standard/editorial-revision");
        break;
      case 3:
        navigate("/request/standard/errata-revision");
        break;
      case 4:
        navigate("/request/standard/withdrawal");
        break;
      case 5:
        navigate("/request/standard/split");
        break;
      case 6:
        navigate("/request/standard/merge");
        break;
      case 7:
        navigate("/request/standard/rename");
        break;
      case 8:
        navigate("/request/standard/stabilised");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div>
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">
            {t("request.standardRequests")}
          </h1>
          <p className="text-base font-normal m-0">
            Any supporting text for request listing page can go here
          </p>
        </div>
        <div className="tabs-wrap">
          <TabView
            scrollable
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header={t("request.newStandard")}>
              <StandardTable handleNewRequestClick={handleNewRequestClick} />
            </TabPanel>
            <TabPanel header={t("request.technicalRevision")}>
              <StandardTable handleNewRequestClick={handleNewRequestClick} />
            </TabPanel>
            <TabPanel header={t("request.editorialRevision")}>
              <StandardTable handleNewRequestClick={handleNewRequestClick} />
            </TabPanel>
            <TabPanel header={t("request.errataRevision")}>
              <StandardTable handleNewRequestClick={handleNewRequestClick} />
            </TabPanel>
            <TabPanel header={t("request.withdrawal")}>
              <StandardTable handleNewRequestClick={handleNewRequestClick} />
            </TabPanel>
            <TabPanel header={t("request.split")}>
              <StandardTable handleNewRequestClick={handleNewRequestClick} />
            </TabPanel>
            <TabPanel header={t("request.merge")}>
              <StandardTable handleNewRequestClick={handleNewRequestClick} />
            </TabPanel>
            <TabPanel header={t("request.rename")}>
              <StandardTable handleNewRequestClick={handleNewRequestClick} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default StandardRequests;

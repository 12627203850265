import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Badge } from "primereact/badge";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import FeatherIcon from "../common/FeatherIconComponent";
import { useLanguageContext } from "../LanguageContext";
import BallotTabDetails from "./listing/BallotTabDetails";
import BallotTabReport from "./BallotTabReport";
import BallotTabCastVote from "./BallotTabCastVote";
import BallotTabComments from "./comments/BallotTabComments";
import BallotTabVoteSummary from "./BallotTabVoteSummary";
import {
  fetchData as getData,
  putData,
} from "../../services/Ballot/apiservice";
import { BallotStatusResponse, BallotStatus } from "./common/type";
import {
  BALLOT_CANCELLED,
  BALLOT_DRAFT,
  BALLOT_OPEN,
} from "./common/constants";
import useUserData from "../../hooks/useUserData";

const BallotTabs: React.FC = () => {
  const [t, i18n] = useTranslation("ballot");
  const [ballotDetails, setBallotDetails] = useState<any>([]);
  const [ballotStatusData, setBallotStatusData] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { userProfileId, userRole } = useUserData();
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();
  let ballotId = sessionStorage.getItem("ballotTableDetails");
  const menuBallot = useRef<Menu>(null);
  const toggleBallotMenu = (event: any) => {
    if (menuBallot.current) {
      menuBallot.current.toggle(event);
    }
  };

  const ballotPayload = {
    accreditationBodyId: ballotDetails?.AccreditationBody?.Id,
    isDirty: true,
    isNew: false,
    ballotLevelId: ballotDetails?.BallotLevel?.Id,
    ballotTypeId: ballotDetails?.BallotType?.Id,
    ballotSubTypeId: ballotDetails?.BallotSubType?.Id,
    ballotStatusId: ballotStatusData[BALLOT_CANCELLED]?.id,
    recordSubTypeId: ballotDetails?.RecordSubType?.Id,
    recordTypeId: ballotDetails?.RecordType?.Id,
    committeeId: ballotDetails?.Committee?.Id,
    membershipPositionId: ballotDetails?.MembershipPosition?.Id,
    note: ballotDetails?.Note,
    noOfRecords: ballotDetails?.NoOfRecords,
    explaination: ballotDetails?.Explaination,
    description: ballotDetails?.Description,
    daysToIssue: ballotDetails?.DaysToIssue,
    openDate: ballotDetails?.OpenDate,
    closeDate: ballotDetails?.CloseDate,
    ballotNumber: ballotDetails?.BallotNumber,
    isActive: true,
    isDeleted: false,
  };
  const ballotCancelHandler = async () => {
    try {
      const createBallotRecord = await putData(
        `Ballot`,
        `${ballotId}`,
        ballotPayload
      );
    } catch (err) {
      console.log(err);
    }
  };
  const showActionItem: MenuItem[] = [
    {
      label: "Cancel",
      command: ballotCancelHandler,
      visible:
        ballotDetails?.BallotStatus?.Name === BALLOT_DRAFT ||
        ballotDetails?.BallotStatus?.Name === BALLOT_OPEN,
    },
  ];
  const getBallotDetailsHandler = async () => {
    try {
      const ballotDetailsData = await getData(`Ballot/${ballotId}`);
      setBallotDetails(ballotDetailsData);
    } catch (err) {
      console.log(err, "errr");
    }
  };
  const getAllBallotStatus = async () => {
    try {
      const ballotStatusResponse: BallotStatusResponse =
        await getData(`BallotStatus/GetAll`);
      const allBallotStatusFormatted = ballotStatusResponse.Collection.map(
        (ballot: BallotStatus) => {
          return {
            id: ballot.Id,
            name: ballot.Name,
          };
        }
      );
      const transformedObject = allBallotStatusFormatted.reduce(
        (acc: any, status: any) => {
          acc[status.name] = { id: status.id };
          return acc;
        },
        {}
      );
      setBallotStatusData(transformedObject);
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getBallotDetailsHandler();
    getAllBallotStatus();
  }, []);
  return (
    <>
      <div>
        <Button
          text
          className="p-button-plain gap-2 mb-3 pl-0 underline"
          onClick={() => navigate("/ballot/membership")}
        >
          <FeatherIcon name="arrow-left" size={20} />
          <span className="">{t("ballot.back")}</span>
        </Button>
        <div className="mb-5 flex flex-wrap gap-2">
          <div className="flex flex-column gap-2">
            <div className="flex gap-2">
              <h1 className="text-title display-xs font-bold text-capitalize m-0 flex align-items-center gap-2">
                {t("ballot.ballotId")} - {ballotDetails.BallotNumber}
              </h1>
              <Badge value="Active" className="active" />
            </div>
            <p className="text-base font-normal m-0">
              Any supporting text for request listing page can go here
            </p>
          </div>
          <div className="flex flex-row align-items-center gap-4 ml-auto">
            {userRole !== "Member" && (
              <>
                <Button
                  className="button-md"
                  label={t("ballot.closeBallot")}
                ></Button>
                <Button
                  className="button-md gap-1"
                  severity="secondary"
                  onClick={toggleBallotMenu}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      toggleBallotMenu(e);
                    }
                  }}
                >
                  <span className="font-bold">{t("ballot.action")}</span>
                  <FeatherIcon name="chevron-down" size={20} color="inherit" />
                </Button>
              </>
            )}

            <Menu
              model={showActionItem}
              popup
              ref={menuBallot}
              id="profileMenu"
              popupAlignment="right"
              role="listbox"
            />
          </div>
        </div>

        <div className="tabs-wrap">
          <TabView
            scrollable
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header={t("ballot.details")}>
              <BallotTabDetails />
            </TabPanel>
            {userRole === "Member" ? (
              <TabPanel header={t("ballot.castAvote")}>
                <BallotTabCastVote />
                {/* <BallotTabWeightedCastVote /> */}
              </TabPanel>
            ) : (
              <TabPanel header={t("ballot.castEditVoteOnBehalf")}>
                <BallotTabCastVote />
                {/* <BallotTabWeightedCastVote /> */}
              </TabPanel>
            )}

            <TabPanel header={t("ballot.voteSummary")}>
              <BallotTabVoteSummary />
            </TabPanel>
            <TabPanel header={t("ballot.comments")}>
              <BallotTabComments />
            </TabPanel>
            {userRole !== "Member" ? (
              <TabPanel header={t("ballot.ballotReport")}>
                <BallotTabReport setActiveIndex={setActiveIndex} />
                {/* for weighted ballot */}
                {/* <BallotTabReportWeighted /> */}
              </TabPanel>
            ) : null}
          </TabView>
        </div>
      </div>
    </>
  );
};

export default BallotTabs;

import React, { useState, useRef } from "react";
import FeatherIcon from "../../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OverlayPanel } from "primereact/overlaypanel"; // Import OverlayPanel
import Table from "../../common/Table";
import { useLanguageContext } from "../../LanguageContext";
import { useTranslation } from "react-i18next";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import StandardTable from "./StandardTable";
interface Option {
  name: string;
  code: string;
}

const StandardRecordListing: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: t("record.dashboard"), url: "/dashboard" },
    { label: t("record.records") },
    { label: t("record.standardRecords") },
  ];

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const navigate = useNavigate();

  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  return (
    <>
      <div>
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />

        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">{t("record.standardRecords")}</h1>
          <p className="text-base font-normal m-0">
            Any supporting text for request listing page can go here
          </p>
        </div>

        <div className="tabs-wrap">
          <TabView scrollable>
            <TabPanel header={t("record.newStandard")}>
              <StandardTable />
            </TabPanel>
            <TabPanel header={t("record.technicalRevision")}>
              <StandardTable />
            </TabPanel>
            <TabPanel header={t("record.editorialRevision")}>
              <StandardTable />
            </TabPanel>
            <TabPanel header={t("record.errataRevision")}>
              <StandardTable />
            </TabPanel>
            <TabPanel header={t("record.withdrawal")}>
              <StandardTable />
            </TabPanel>
            <TabPanel header={t("record.split")}>
              <StandardTable />
            </TabPanel>
            <TabPanel header={t("record.merge")}>
              <StandardTable />
            </TabPanel>
            <TabPanel header={t("record.rename")}>
              <StandardTable />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default StandardRecordListing;

import React from "react";
import SearchRecordForm from "../sections/SearchRecordForm";
import BallotRecordTable from "../sections/BallotRecordTable";

const SelectRecords: React.FC = () => {
  return (
    <>
      <div>
        <SearchRecordForm />
        <BallotRecordTable />
      </div>
    </>
  );
};

export default SelectRecords;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import MasterData from "./MasterData";
import CommitteeConfigurationTab from "./CommitteeConfigurationTab";
import MemberRole from "./MemberRole";
import BallotConfigurationTab from "./ballotConfiguration/BallotConfigurationTab";
import BallotApprovalCriteriaTab from "./BallotApprovalCriteriaTab";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import MemberRolesTab from "./MemberRolesTab";
import CommitteeConfigurationTabView from "./CommitteeConfigurationTabView";
import CommitteeConfigurationToggle from "./CommitteeConfigurationToggle";
import MemberConfigurationToggle from "./MemberConfigurationToggle";

const Configuration: React.FC = () => {
  const [t, i18n] = useTranslation("configuration");
  const { selectedLanguage } = useLanguageContext();
  const [activeIndex, setActiveIndex] = useState(0);
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: "Dashboard", url: "/dashboard" },
    { label: "Configuration" },
  ];

  const handleEditButtonClick = () => {
    setActiveIndex(2); // Switch to CommitteeConfigurationTab
  };

  return (
    <>
      <div className="">
        {/* <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        /> */}
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("configuration.configuration")}
          </h1>
          {/* <p className="text-base font-normal m-0">
            Any supporting text for request listing page can go here
          </p> */}
        </div>

        <div className="tabs-wrap">
          {/* <TabView scrollable> */}
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
          >
            <TabPanel header={t("configuration.masterData")}>
              <MasterData />
            </TabPanel>
            <TabPanel header={t("configuration.defaultConfiguration")}>
              <CommitteeConfigurationToggle setActiveIndex={setActiveIndex} />
            </TabPanel>
            <TabPanel header={t("configuration.rolePermissions")}>
              <MemberRole />
            </TabPanel>
            {/* <TabPanel header={t("configuration.committeeConfiguration")}>
              <CommitteeConfigurationTab />
            </TabPanel>
            <TabPanel header="View Committee Configuration">
              <CommitteeConfigurationTabView />
            </TabPanel> */}
            <TabPanel header={t("configuration.ballotConfiguration")}>
              <BallotConfigurationTab />
            </TabPanel>
            <TabPanel header={t("configuration.ballotAdditionalConfiguration")}>
              <BallotApprovalCriteriaTab />
            </TabPanel>
            <TabPanel header={t("configuration.memberRoles")}>
              <MemberRolesTab />
            </TabPanel>
            <TabPanel header={t("configuration.memberConfigurations")}>
              <MemberConfigurationToggle setActiveIndex={setActiveIndex} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default Configuration;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { RootState } from "../../store/store";
import FeatherIcon from "../common/FeatherIconComponent";
import MemberRequestSteps from "./MemberRequestSteps";
import { fetchData, getListByParentId } from "../../services/apiService";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { createMemberRequest } from "../../slices/createMemberRequestSlice";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";

const CommitteeDetails: React.FC = () => {
  const [t, i18n] = useTranslation("member");
  const location = useLocation();
  const rowData = location.state?.rowData as any | undefined;
  const newData = rowData;

  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [parentCommitteeId, setparentCommitteeId] = useState("");
  const [previousCommittee, setPreviousCommitee] = useState<any>(null);
  const [breadcumbPageItems, setbreadcumbPageItems] = useState<any>([]);
  const [parentCommitteeName, setparentCommitteeName] = useState("");
  const [subCommittee, setSubCommittee] = useState([]);
  const [parentCommittee, setParentCommittee] = useState<any>(null);

  const createCommitteeData = useSelector(
    (state: RootState) => state.createMemberRequest
  );
  const [selectedCommitteeId, setSelectedCommitteeId] = useState("");
  const [selectedCommitteeIndex, setselectedCommitteeIndex] = useState<
    number | null
  >(null);
  const [committees, setCommitteess] = useState<any>([]);
  const dispatch = useDispatch();

  const breadcumbItems = [
    { label: t("member.dashboard"), url: "/dashboard" },
    { label: t("member.membershipRequest") },
  ];
  const breadcumbPage = {
    label: t("member.allCommittees"),
    url: "/member/create",
  };
  const [checked, setChecked] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
      setSelectedCommitteeId(parentCommittee.Id);
      sessionStorage.setItem("committeeId", parentCommittee.Id);
      dispatch(createMemberRequest({ committeeId: parentCommittee.Id }));
    }
  };

  React.useEffect(() => {
    const committeeIdFromRedux = createCommitteeData.data.committeeId;
    const committeeIdFromSessionStorage = sessionStorage.getItem("committeeId");
    const committeeId = committeeIdFromRedux || committeeIdFromSessionStorage;
    const fetchCommitteeDetailById = async () => {
      try {
        const responseData = await fetchData("Committee", committeeId);
        setParentCommittee(responseData);
        setparentCommitteeName(responseData.Name);
        setparentCommitteeId(responseData.ParentCommitteeId);
        setPreviousCommitee(responseData.ParentCommitteeName);
      } catch (error) {
        console.error("Error fetching committees:", error);
        //setLoading(false);
      }
    };
    fetchCommitteeDetailById();
    if (newData) {
      setParentCommittee(newData);

      setChecked(true);
      setparentCommitteeName(newData.CommitteeName);
      setparentCommitteeId(newData.CommitteeId);
      setPreviousCommitee(newData.CommitteeName);
    }
  }, []);

  React.useEffect(() => {
    const fetchSubCommittee = async () => {
      try {
        const responseData = await getListByParentId(
          "Committee",
          parentCommittee.Id
        );
        setSubCommittee(responseData.Collection);
      } catch (error) {
        console.error("Error fetching Category of Interest:", error);
      }
    };
    fetchSubCommittee();
  }, [parentCommittee]);

  const selectCommittee = (committee: any, index: number) => {
    setSelectedCommitteeId(committee.Id);
    setselectedCommitteeIndex(index);
    sessionStorage.setItem("committeeId", committee.Id);
    dispatch(createMemberRequest({ committeeId: committee.Id }));
  };

  //const  breadcumbPageItems = [{ label: parentCommittee?.Name }];

  React.useEffect(() => {
    if (parentCommittee) {
      setbreadcumbPageItems([
        breadcumbPage,
        {
          label: parentCommittee?.ParentCommitteeName,
          url: "/member/committee",
          onClick: handleBreadcrumbParentClick,
        },
        { label: parentCommittee?.Name, url: "" },
      ]);
    }
  }, [parentCommittee]);

  const handleBreadcrumbParentClick = () => {
    sessionStorage.setItem("committeeId", parentCommittee.ParentCommitteeId);
    dispatch(
      createMemberRequest({ committeeId: parentCommittee.ParentCommitteeId })
    );
  };

  const onhandleContinue = () => {
    if (editMode) {
      dispatch(createMemberRequest({ committeeId: selectedCommitteeId }));
      sessionStorage.setItem(
        "committeeId",
        JSON.stringify({ committeeId: selectedCommitteeId })
      );
      navigate(`/member/details`);
    }
    if (newData) {
      dispatch(createMemberRequest({ committeeId: newData.CommitteeId }));

      navigate(`/member/details`, { state: { newData } });
    }
  };

  return (
    <>
      <div className="">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("member.membershipRequest")}
          </h1>
          <p className="text-base font-normal m-0">
            {t("member.membershipRequestSubText")}
          </p>
        </div>
        <MemberRequestSteps activeStep={0} />
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-7">
              <div className="flex flex-column gap-4">
                <div className="custom-breadcrumb-page">
                  <CustomBreadcrumb
                    model={breadcumbPageItems}
                    className="bg-transparent px-0 py-2 border-none"
                    separator={"/"}
                  />
                </div>
                <div className="flex align-items-center">
                  <div className="px-5 py-4 bg-brand-secondary">
                    <Checkbox
                      inputId="termsconditions"
                      onChange={handleCheckboxChange}
                      checked={checked}
                      className="mr-2"
                    />
                    <label
                      htmlFor="termsconditions"
                      className="text-lg font-bold"
                    >
                      {t("member.checkCommitteeMembership")}
                    </label>
                  </div>
                </div>
                <div className="flex flex-column gap-4">
                  <div className="flex flex-column gap-3">
                    <h2 className="text-title text-lg font-bold m-0">
                      {parentCommittee?.Name} {parentCommittee?.CommitteeName}
                    </h2>
                    {/* <p className="m-0 text-base font-normal">
                      Lorem ipsum dolor sit amet consectetur. Etiam faucibus
                      tristique phasellus hendrerit. Arcu tellus penatibus porta
                      bibendum. Duis dolor suspendisse risus eu. Nibh turpis
                      nunc purus pharetra aliquam nascetur ...
                    </p> */}
                  </div>
                  <div className="flex flex-column md:flex-row gap-5">
                    <div className="flex flex-column gap-1">
                      <h3 className="m-0 font-bold text-base">
                        {t("member.committeeManager")}
                      </h3>
                      <p className="text-base text-title font-normal m-0">
                        {/* Marvin Howard */}
                        {/* {parentCommittee} */}
                        {parentCommittee?.Staff?.Firstname}{" "}
                        {parentCommittee?.Staff?.MiddleName}{" "}
                        {parentCommittee?.Staff?.Lastname}
                        {parentCommittee?.StaffName}
                      </p>
                    </div>
                    <div className="flex flex-column gap-1">
                      <h3 className="m-0 font-bold text-base">
                        {t("member.chairPerson")}
                      </h3>
                      <p className="text-base text-title font-normal m-0">
                        {parentCommittee?.HierarchyLevel?.Name}
                        {parentCommittee?.OpenPositions}
                      </p>
                    </div>
                  </div>
                  {/* <div className="flex flex-column gap-2">
                    <h3 className="m-0 font-bold text-base">
                      Standard Managed by committee
                    </h3>
                    <ul className="flex flex-column gap-3 pl-3 m-0 list-marker">
                      <li className="text-base text-link font-bold m-0 underline">
                        Standard 1
                      </li>
                      <li className="text-base text-link font-bold m-0 underline">
                        Standard 1
                      </li>
                      <li className="text-base text-link font-bold m-0 underline">
                        Standard 1
                      </li>
                      <li className="text-base text-link font-bold m-0 underline">
                        Standard 1
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              {!newData && (
                <div className="flex flex-column gap-3">
                  <h2 className="m-0 text-lg font-bold">
                    {t("member.subCommittees")}
                  </h2>
                  <ul className="list-none p-0 m-0 shadow-md">
                    {subCommittee.map((subCommittee: any, index: number) => (
                      <li
                        key={index}
                        onClick={() => selectCommittee(subCommittee, index)}
                        className={`flex align-items-center justify-content-between pl-4 pr-2 py-3 border-1 border-gray-200 gap-2 md:gap-3 shadow-hover ${selectedCommitteeIndex === index ? "selected" : ""} ${subCommittee.Id === selectedCommitteeId ? "selected" : ""}`}
                      >
                        <div className="flex flex-column gap-2">
                          <p className="m-0 text-base font-bold break-word">
                            {subCommittee.Name}
                          </p>
                          <p className="m-0 text-base font-normal">
                            {subCommittee.CommitteeType.TypeName}
                          </p>
                        </div>
                        <Button
                          text
                          className="p-button-plain gap-1 underline"
                          onClick={() => navigate("/member/sub-committee")}
                        >
                          <span className="font-bold hidden md:flex">
                            {t("member.viewDetails")}
                          </span>
                          <FeatherIcon name="chevron-right" size={20} />
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("member.cancel")}
            className="button-md w-full sm:w-auto"
            severity="secondary"
            onClick={() => {
              navigate("/member");
            }}
          />
          <Button
            label={t("member.confirmCommitteeContinue")}
            className="button-md w-full sm:w-auto"
            onClick={onhandleContinue}
            disabled={!selectedCommitteeId && !newData}
          />
        </div>

        {/* Mobile responsive buttions */}
        <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("member.confirmCommitteeContinue")}
            className="button-md w-full"
            onClick={onhandleContinue}
            disabled={!selectedCommitteeId}
          />
          <Button
            label={t("member.cancel")}
            className="button-md w-full"
            severity="secondary"
            onClick={() => {
              navigate("/member");
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CommitteeDetails;

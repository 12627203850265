import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import Table from "../common/Table";

const PublishedStandardTab: React.FC = () => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="px-5 py-4 border-bottom-1 border-gray-200 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("standard.publishedVersions")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "standardName", header: t("standard.standardName") },
                { field: "shortName", header: t("standard.shortName") },
                { field: "primaryCommitteeResponsible", header: t("standard.primaryCommitteeResponsible") },
                { field: "publishedYear", header: t("standard.publishedYear") },
                { field: "attachments", header: t("standard.attachments") },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PublishedStandardTab;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CreateProfileApiData {
  createProfile: Record<string, any>;
  configureAddress: Record<string, any>;
  configureEducation: Record<string, any>;
  staffConfigureUserProfile: Record<string, any>;
  configureLicenses: Record<string, any>;
  configureExperience: Record<string, any>;
  loading: boolean;
}

const initialState: CreateProfileApiData = {
  createProfile: {},
  configureEducation: {},
  configureAddress: {},
  staffConfigureUserProfile: {},
  configureLicenses: {},
  configureExperience: {},
  loading: false,
};

const createProfileSlice = createSlice({
  name: "Profile",
  initialState,
  reducers: {
    createUserProfileRequest: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.createProfile = { ...state.createProfile, ...action.payload };
      state.loading = false;
    },
    configureAddressRequest: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.configureAddress = { ...state.configureAddress, ...action.payload };
      state.loading = false;
    },
    configureEducationRequest: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.configureEducation = {
        ...state.configureEducation,
        ...action.payload,
      };
      state.loading = false;
    },
    configureExperienceRequest: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.configureExperience = {
        ...state.configureExperience,
        ...action.payload,
      };
      state.loading = false;
    },
    configureLicensesRequest: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.configureLicenses = {
        ...state.configureLicenses,
        ...action.payload,
      };
      state.loading = false;
    },
    staffConfigureProfile: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.staffConfigureUserProfile = {
        ...state.staffConfigureUserProfile,
        ...action.payload,
      };
      state.loading = false;
    },
    resetProfile: () => initialState,
  },
});

export const {
  createUserProfileRequest,
  configureAddressRequest,
  staffConfigureProfile,
  configureLicensesRequest,
  configureExperienceRequest,
  configureEducationRequest,
  resetProfile,
} = createProfileSlice.actions;
export default createProfileSlice.reducer;

import React, { useState, useRef } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OverlayPanel } from "primereact/overlaypanel"; // Import OverlayPanel
import Table from "../common/Table";
import { Calendar } from "primereact/calendar";
import { MenuItem } from "primereact/menuitem";
import { MultiSelect } from "primereact/multiselect";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
interface Option {
  name: string;
  code: string;
}

const CommitteeMeetingTabAttendees: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: t("committee.dashboard"), url: "/dashboard" },
    { label: t("committee.committees") },
  ];

  const [selectedOption, setselectedOption] = useState(null);
  const attendees = [
    { name: "Option 1", code: "option1" },
    { name: "Option 2", code: "option2" },
  ];
  const navigate = useNavigate();

  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  const [checked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };
  const [dateActivation, setDateActivation] = useState(null);
  const actionItems: MenuItem[] = [
    {
      label: "Opt 1",
      // command: () => {
      //   navigate("/member/request-view");
      // },
    },
    // {
    //   label: "Terminate member",
    // },
    // {
    //   label: "Reappoint member",
    // },
  ];
  return (
    <>
      <div className="">
        <div className="flex flex-wrap align-items-center py-3 gap-3 w-full">
          <div className="flex flex-column gap-2 w-full md:w-16rem lg:w-18rem">
            <label
              htmlFor="selectDay"
              className="block font-bold text-capitalize text-input-label"
            >
              {t("committee.selectDay")}
            </label>
            <Dropdown
              inputId="selectDay"
              // aria-describedby="selectDayError"
              name="meetingType"
              value={selectedOption}
              onChange={(e: DropdownChangeEvent) => setselectedOption(e.value)}
              options={attendees}
              optionLabel="name"
              placeholder={t("committee.selectAnOption")}
            />
          </div>
          <div className="flex flex-column gap-2 w-full md:w-16rem lg:w-18rem">
            <label
              htmlFor="selectCommittee"
              className="block font-bold text-capitalize text-input-label"
            >
              {t("committee.selectCommittee")}
            </label>
            <Dropdown
              inputId="selectCommittee"
              // aria-describedby="selectCommitteeError"
              name="meetingType"
              value={selectedOption}
              onChange={(e: DropdownChangeEvent) => setselectedOption(e.value)}
              options={attendees}
              optionLabel="name"
              placeholder={t("committee.selectAnOption")}
            />
          </div>
          <Button
            className="button-md gap-1 ml-auto w-full md:w-auto"
            label={t("committee.markPresent")}
          ></Button>
        </div>

        <Table
          products={[
            {
              attendeeName: "John doe",
              membershipType: "Member",
              presence: "--",
              action: t("committee.action"),
            },
          ]}
          loading={false}
          columns={[
            { field: "attendeeName", header: t("committee.attendeeName") },
            { field: "membershipType", header: t("committee.membershipType") },
            { field: "presence", header: t("committee.presence") },
            { field: "action", header: t("committee.action"), sortable: false },
          ]}
          actionItems={actionItems}
          showCheckbox={true}
        />
      </div>
    </>
  );
};

export default CommitteeMeetingTabAttendees;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { selectRecordsData } from "./ballotSelectRecordType";


const initialState: selectRecordsData = {
    accreditationBodyOptions: [],
    primaryCommitteOptions: [],
    ballotTypeOptions: [],
    ballotSubTypeOptions: [],
    ballotLevelOptions: [],
    recordTypeOptions: [],
    recordSubTypeOptions: [],
    positionAppliedOptions: [],
    selectedRecordValues: {
        accreditationBodySelect: [],
        primaryCommitteSelect: [],
        ballotTypeSelect: [],
        ballotSubTypeSelect: [],
        ballotLevelSelect: [],
        recordTypeSelect: [],
        recordSubTypeSelect: [],
        positionAppliedSelect: [],
    },
    selectedTableRecords: [],
    recordTableData: [],
    postBallotRecord: false,
    stepCount: 0,
    isEdit: false,
}
const selectRecords = createSlice({
    name: 'ballotSelectRecords',
    initialState,
    reducers: {
        initialRecordValues: (state,
            action: PayloadAction<Record<string, any>>) => {
            switch (action.payload.label) {
                case ('accreditationType'):
                    const accreditationFormattedOptions = action.payload.options.Collection.map((accreditation: any) => {
                        return {
                            label: accreditation.Name,
                            value: accreditation.Name,
                            id: accreditation.Id
                        }
                    });
                    state.accreditationBodyOptions = accreditationFormattedOptions;
                    break;
                case ('primarycommittee'):
                    const primaryCommitteeFormattedOptions = action.payload.options.map((committee: any) => {
                        return {
                            label: committee.Name,
                            value: committee.Name,
                            id: committee.Id
                        }
                    });
                    state.primaryCommitteOptions = primaryCommitteeFormattedOptions;
                    break;
                case ('ballottype'):
                    const ballotTypeFormattedOptions = action.payload.options.Collection.map((ballot: any) => {
                        return {
                            label: ballot.Name,
                            value: ballot.Name,
                            id: ballot.Id
                        }
                    });
                    state.ballotTypeOptions = ballotTypeFormattedOptions;
                    break;
                case ('ballotsubtype'):
                    const ballotSubtypeFormattedOptions = action.payload.options.Collection.map((ballot: any) => {
                        return {
                            label: ballot.Name,
                            value: ballot.Name,
                            id: ballot.Id
                        }
                    });
                    state.ballotSubTypeOptions = ballotSubtypeFormattedOptions;
                    break;
                case ('ballotlevel'):
                    const ballotLevelFormattedOptions = action.payload.options.Collection.map((ballot: any) => {
                        return {
                            label: ballot.Name,
                            value: ballot.Name,
                            id: ballot.Id
                        }
                    });
                    state.ballotLevelOptions = ballotLevelFormattedOptions;
                    break;
                case ('recordtype'):
                    const recordTypeFormattedOptions = action.payload.options.Collection.map((committee: any) => {
                        return {
                            label: committee.TypeName,
                            value: committee.TypeName,
                            id: committee.Id
                        }
                    });
                    state.recordTypeOptions = recordTypeFormattedOptions;
                    break;
                case ('recordsubtype'):
                    const recordSubTypeFormattedOptions = action.payload.options.Collection.map((recorsubType: any) => {
                        return {
                            label: recorsubType.Type,
                            value: recorsubType.Type,
                            id: recorsubType.Id
                        }
                    });
                    state.recordSubTypeOptions = recordSubTypeFormattedOptions;
                    break;
                case ('positions'):
                    const positionsFormattedOptions = action.payload.options.Collection.map((committee: any) => {
                        return {
                            label: committee.PositionName,
                            value: committee.PositionName,
                            id: committee.Id
                        }
                    });
                    state.positionAppliedOptions = positionsFormattedOptions;
                    break;
                default:
                    break;
            }
        },
        selectedRecordValues: (state,
            action: PayloadAction<Record<string, any>>) => {
            switch (action.payload.label) {
                case ('accreditationBodyValue'):
                    const accreditationBodyOptions = state.accreditationBodyOptions.find((accreditation: any) => accreditation.value === action.payload.target);
                    state.selectedRecordValues.accreditationBodySelect = accreditationBodyOptions;
                    break;
                case ('primaryCommitteeValue'):
                    const primaryCommitteeOptions = state.primaryCommitteOptions.find((committee: any) => committee.value === action.payload.target);
                    state.selectedRecordValues.primaryCommitteSelect = primaryCommitteeOptions;
                    break;
                case ('ballotTypeValue'):
                    const ballotTypeOptions = state.ballotTypeOptions.find((ballot: any) => ballot.value === action.payload.target);
                    state.selectedRecordValues.ballotTypeSelect = ballotTypeOptions;
                    break;
                case ('ballotSubTypeValue'):
                    const ballotSubTypeOptions = state.ballotSubTypeOptions.find((ballot: any) => ballot.value === action.payload.target);
                    state.selectedRecordValues.ballotSubTypeSelect = ballotSubTypeOptions;
                    break;
                case ('ballotLevelValue'):
                    const ballotLevelOptions = state.ballotLevelOptions.find((ballot: any) => ballot.value === action.payload.target);
                    state.selectedRecordValues.ballotLevelSelect = ballotLevelOptions;
                    break;
                case ('recordTypeValue'):
                    const recordTypeOptions = state.recordTypeOptions.find((committee: any) => committee.value === action.payload.target);
                    state.selectedRecordValues.recordTypeSelect = recordTypeOptions;
                    break;
                case ('recordSubTypeValue'):
                    const recordSubTypeOptions = state.recordSubTypeOptions.find((committee: any) => committee.value === action.payload.target);
                    state.selectedRecordValues.recordSubTypeSelect = recordSubTypeOptions;
                    break;
                case ('positionAppliedValue'):
                    const positionAppliedOptions = state.positionAppliedOptions.find((committee: any) => committee.value === action.payload.target);
                    state.selectedRecordValues.positionAppliedSelect = positionAppliedOptions;
                    break;
                default:
                    break;
            }
        },
        tableRecordSelectionHandler: (state,
            action: PayloadAction<Record<string, any>>) => {
            let index = state.selectedTableRecords.findIndex((obj: any) => obj.id === action.payload.rowData.id);
            if (index === -1) {
                state.selectedTableRecords.push(action.payload.rowData);
            } else {
                state.selectedTableRecords.splice(index, 1);
            }
        },
        addRecordTableData: (state,
            action: PayloadAction<Record<string, any>>) => {
            state.recordTableData = action.payload.rowTableData;
            state.selectedTableRecords = [];
        },
        postBallotRecord: (state,
            action: PayloadAction<Record<string, any>>) => {
            state.postBallotRecord = action.payload.value
        },
        addStepCountHandler: (state,
            action: PayloadAction<Record<string, any>>) => {
            state.stepCount = action.payload.value
        },
        isEditHandler: (state,
            action: PayloadAction<Record<string, any>>) => {
            state.isEdit = action.payload.value
        },
        reset: () => initialState,
    }
});

export const ballotSelectRecords = selectRecords.actions
export default selectRecords.reducer;
import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
// import "./primereact-theme/themes/stanza-red/theme.scss";
import "./App.css";
import ReactGA from "react-ga4";
import { ProtectedRoute } from "./ProtectedRoute";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import Layout from "./components/Layout";
import SignIn from "./components/auth/SignIn";
import SignInOidc from "./components/auth/SignInOidc";
import SignUp from "./components/auth/SignUp";
import EmailSent from "./components/auth/EmailSent";
import EmailVerified from "./components/auth/EmailVerified";
import ResetPassword from "./components/auth/ResetPassword";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPasswordEmailSent from "./components/auth/ResetPasswordEmailSent";
import CookieBanner from "./components/common/CookieBanner";
import { getTenantNameFromDomain, loadTheme } from "./utils/utils";
import { getEnvInfo, setTenantInfo } from "./utils/storage";
import { IDENTITY_URL } from "./config";

function App({ instance }: any) {
  useEffect(() => {
    ReactGA.initialize("G-CSPBFYZ299");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    const updateLogoInHead = async () => {
      const envInfo = getEnvInfo();
      const identityURL = envInfo?.REACT_APP_IDENTITY_URL || IDENTITY_URL;
      const tenantDomain = getTenantNameFromDomain();
      try {
        const getTenantConfig = await fetch(
          `${identityURL}/Tenant/GetTenantbyDomianName/${tenantDomain}`
        );
        const tenantResponseData = await getTenantConfig.json();
        setTenantInfo(tenantResponseData);
        const brandingLogo = tenantResponseData.BrandingLogo;
        if (brandingLogo) {
          const link: HTMLLinkElement | null =
            document.querySelector("link[rel~='icon']");
          if (link) link.href = `/logos/${brandingLogo}`;
        }
      } catch (error) {
        console.error("Failed to fetch tenant config:", error);
      }
    };

    updateLogoInHead();
    loadTheme();
  }, []);

  return (
    <MsalProvider instance={instance}>
      <CookieBanner />
      <Router>
        <div className="App">
          <Routes>
            <Route path="/signin-oidc" element={<SignInOidc />} />
            <Route path="/" element={<SignIn />} />
          </Routes>
        </div>
        <ProtectedRoute />
      </Router>
    </MsalProvider>
  );
}

export default App;

import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { RootState } from "../../store/store";
import FeatherIcon from "../common/FeatherIconComponent";
import { fetchData, getListByParentId } from "../../services/apiService";
import { useLanguageContext } from "../LanguageContext";
import CreateRecordHeader from "./createRecordHeader";
import { reset } from "../../slices/createRecordSlice";
import { clearAllSessionStorage } from "../../utils/storage";
import RecordListDocument from "../common/RecordListDocument";

const RecordPreview: React.FC = () => {
  const toast = useRef<any>(null);
  const [volunteerProfileId, setVolunteerProfileId] = useState<string | null>(
    null
  );
  const [isprofessionalLicenseDetails, setIsprofessionalLicenseDetails] =
    useState<boolean>(true);
  const dispatch = useDispatch();
  const [recordDetails, setRecordDetails] = useState<any>(null);
  const [basicDetails, setBasicDetails] = useState<any>(null);
  const [addressDetails, setAddressDetails] = useState<any>(null);
  const [experienceDetails, setExperienceDetails] = useState<any>(null);
  const [professionalDetails, setProfessionalLicenseDetails] =
    useState<any>(null);
  const [recordStatusId, setRecordStatusId] = useState<string>("");
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const createRecordDetails = useSelector(
    (state: RootState) => state.createRecord
  );
  const [membershipTerminationReason, setMembershipTerminationReason] =
    useState<string>("");
  const [saveAsDraftClicked, setSaveAsDraftClicked] = useState(false);
  const navigate = useNavigate();
  const [membershipRecordId, setMembershipRecordId] = useState<any>(null);

  const handleRecordsStatus = async () => {
    const committeeResponse = await fetchData("RecordStatus/GetAll");
    const draftRecord = committeeResponse.Collection.find(
      (item: any) => item.StatusName === "Draft"
    );
    setRecordStatusId(draftRecord.Id);
  };

  React.useEffect(() => {
    handleRecordsStatus();
    let recordId: string | null | undefined;
    if (
      createRecordDetails.configureRecord &&
      createRecordDetails.configureRecord.recordId
    ) {
      recordId = createRecordDetails.configureRecord.recordId;
      setMembershipRecordId(recordId);
    } else {
      const storedData = sessionStorage.recordId;
      setMembershipRecordId(storedData);
      if (storedData) {
        recordId = storedData;
      }
    }
    if (recordId) {
      const fetchRecordDetail = async () => {
        try {
          const responseData = await fetchData("Record", recordId);
          setRecordDetails(responseData);
          setVolunteerProfileId(responseData.UserProfileId);
        } catch (error) {
          console.error("Error fetching Category of Interest:", error);
        }
      };
      fetchRecordDetail();
    }
  }, [createRecordDetails]);

  const fetchCountryByID = async (id: string) => {
    try {
      const responseData = fetchData("Country", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const fetchStateByID = async (id: string) => {
    try {
      const responseData = fetchData("State", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const fetchUniversityByID = async (id: string) => {
    try {
      const responseData = fetchData("University", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const fetchDegreeByID = async (id: string) => {
    try {
      const responseData = fetchData("Degree", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const getTerminationNameByID = async () => {
    try {
      const responseData = await fetchData(
        `MembershipTerminateReason/${recordDetails.MembershipTerminateReason.Id}`
      );
      setMembershipTerminationReason(responseData.Reason);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (recordDetails?.MembershipTerminateReason?.Id) {
      getTerminationNameByID();
    }
  }, [recordDetails?.MembershipTerminateReason?.Id]);

  React.useEffect(() => {
    if (volunteerProfileId) {
      const fetchDataAsync = async () => {
        try {
          const [
            userProfile,
            phoneData,
            experienceData,
            addressData,
            licenseData,
            educationData,
          ] = await Promise.all([
            fetchData("UserProfile", volunteerProfileId),
            getListByParentId("UserPhone", volunteerProfileId),
            getListByParentId("UserExperience", volunteerProfileId),
            getListByParentId("UserAddress", volunteerProfileId),
            getListByParentId("UserProfessionalLicense", volunteerProfileId),
            getListByParentId("UserEducationDetail", volunteerProfileId),
          ]);
          setBasicDetails(userProfile);
          setExperienceDetails(experienceData?.Collection);
          setAddressDetails(addressData?.Collection);
          setProfessionalLicenseDetails(licenseData?.Collection);
          if (licenseData?.Collection) {
            setIsprofessionalLicenseDetails(true);
          }
          const updatedEducationDetails = await Promise.all(
            educationData?.Collection.map(async (education: any) => {
              try {
                const countryData = await fetchCountryByID(education.CountryId);
                const stateData = await fetchStateByID(education.StateId);
                const universityData = await fetchUniversityByID(
                  education.UniversityId
                );
                const degreeData = await fetchDegreeByID(education.DegreeId);
                return {
                  ...education,
                  // countryName: countryData?.Name,
                  // stateName: stateData?.Name,
                  // universityName: universityData?.Name,
                  // degreeName: degreeData?.Name,
                };
              } catch (error) {
                console.error("Error fetching education details:", error);
                return education;
              }
            })
          );
          // // setEducationDetails(updatedEducationDetails);
          // setLoadingsecond(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchDataAsync();
    }
  }, [volunteerProfileId]);

  const [visible, setVisible] = useState<boolean>(false);
  const footerContent = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("record.gotoAllRecords")}
        onClick={() => {
          setVisible(false);
          navigate("/record");
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("record.goToDashboard")}
        onClick={() => {
          setVisible(false);
          navigate("/dashboard");
        }}
        className="button-md"
        autoFocus
      />
    </div>
  );

  const handlePostData = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      recordDetails.recordStatusId = recordStatusId;
      if (saveAsDraftClicked) {
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: t("record.success"),
            detail: t("record.dataSavedDraft"),
          });
        }
      } else {
        setVisible(true);
        dispatch(reset());
        clearAllSessionStorage();
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="">
        <CreateRecordHeader activeStep={3} />
        <form onSubmit={handlePostData}>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.committeeDetails")}
              </h2>
              <div className="flex text-sm">
                <Button
                  text
                  className="p-button-plain gap-2 underline"
                  onClick={() => {
                    navigate("/record/create");
                  }}
                >
                  <FeatherIcon name="edit-2" size={20} />
                  <span>{t("record.edit")}</span>
                </Button>
              </div>
            </div>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-4">
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("record.committeeName")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {recordDetails?.Committee?.Name}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("record.staffSecretary")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {recordDetails?.Committee?.Staff?.Firstname}{" "}
                    {recordDetails?.Committee?.Staff?.Lastname}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.membershipDetails")}
              </h2>
              <div className="flex text-sm">
                <Button
                  text
                  className="p-button-plain gap-2 underline"
                  onClick={() => {
                    navigate("/record/details");
                  }}
                >
                  <FeatherIcon name="edit-2" size={20} />
                  <span>{t("record.edit")}</span>
                </Button>
              </div>
            </div>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-4">
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("record.committeePosition")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {recordDetails?.MembershipPosition?.PositionName}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("record.categoryOfInterest")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {recordDetails?.CategoryOfInterest?.CategoryName}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("record.remark")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {recordDetails?.Remark}
                  </p>
                </div>

                {membershipTerminationReason && (
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                      {t("record.reasonForTermination")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {membershipTerminationReason}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.basicDetails")}
              </h2>
            </div>
            <div className="cardBody">
              <div>
                {basicDetails ? (
                  <div className="p-5 cardBody">
                    <div className="flex flex-column gap-1">
                      <h3 className="font-bold text-base text-title m-0">
                        {basicDetails.Prefix} <span>.</span>{" "}
                        {basicDetails.Firstname} {basicDetails.MiddleName}{" "}
                        {basicDetails.Lastname}
                      </h3>
                      <p className="m-0 font-normal">{basicDetails.Email}</p>
                    </div>
                  </div>
                ) : (
                  <div className="p-5 cardBody">
                    <div className="text-center">
                      <p>{t("record.noDataAvailable")}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.addressDetails")}
              </h2>
            </div>
            <div className="p-5 cardBody">
              <div>
                {addressDetails && addressDetails.length > 0 ? (
                  <div className="cardBody">
                    <div className="flex flex-column gap-5">
                      {addressDetails.map((address: any, index: number) => (
                        <div className="flex" key={index}>
                          <div className="experienceDetails flex flex-column gap-1">
                            <h3 className="font-bold text-base text-title m-0">
                              {t("record.addressType")} -{address.AddressType}
                            </h3>
                            {address.AddressLine1 && (
                              <p className="m-0 font-normal">
                                {t("record.address")} - {address.AddressLine1}
                              </p>
                            )}
                            {address.AddressLine2 && (
                              <p className="m-0 font-normal">
                                {address.AddressLine2}
                              </p>
                            )}
                            {address.AddressLine3 && (
                              <p className="m-0 font-normal">
                                {address.AddressLine3}
                              </p>
                            )}
                            {address.Pincode && (
                              <p className="m-0 font-normal">
                                {address.Pincode}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <span>{t("record.addressNotAvailable")}</span>
                )}
              </div>
            </div>
          </div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.experience")}
              </h2>
            </div>

            {experienceDetails && experienceDetails.length > 0 ? (
              <div className="p-5 cardBody">
                <div className="flex flex-column gap-5">
                  {experienceDetails.map((experience: any, index: number) => (
                    <div className="flex" key={index}>
                      <div className="experienceDetails flex flex-column gap-1">
                        <h3 className="font-bold text-base text-title m-0">
                          {experience.Designation} ({experience.Department})
                        </h3>
                        <p className="m-0 font-normal">
                          {experience.CompanyName}
                        </p>
                        <p className="m-0 font-normal">
                          {new Date(experience.StartDate).toLocaleDateString(
                            "en-US",
                            { month: "long", year: "numeric" }
                          )}{" "}
                          -{" "}
                          {new Date(experience.EndDate).toLocaleDateString(
                            "en-US",
                            { month: "long", year: "numeric" }
                          )}
                        </p>
                        <p className="m-0 font-normal">
                          {experience.WebsiteURL}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="p-5 font-normal">
                {t("record.experienceDetailsNotAvailable")}
              </div>
            )}
          </div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.professionalDetails")}
              </h2>
            </div>
            {professionalDetails && professionalDetails.length > 0 ? (
              <div className="p-5 cardBody">
                <div className="flex flex-column gap-5">
                  {professionalDetails.map((license: any, index: number) => (
                    <div className="flex" key={index}>
                      <div className="experienceDetails flex flex-column gap-1">
                        <h3 className="font-bold text-base text-title m-0">
                          {t("record.licenseName")} - {license.LicenseName}
                        </h3>
                        <p className="m-0 font-normal">
                          {t("record.licenseNumber")} - {license.LicenseNumber}
                        </p>
                        <p className="m-0 font-normal">
                          {t("record.validUpto")} - {license.LicenseYear}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="p-5 font-normal">
                {t("record.professionalDetailsNotAvailable")}
              </div>
            )}
          </div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.documents")}
              </h2>
            </div>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                <div className="flex flex-column gap-3">
                  <h3 className="m-0 font-bold text-base">
                    {t("record.recordDoumentsLabel")}
                  </h3>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    <RecordListDocument
                      documentType="RecordDocument"
                      membershipRecordID={membershipRecordId}
                      showNoDataMessage={true}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 fixed-footer w-full left-0 shadow">
            <Button
              label={t("record.cancel")}
              className="button-md"
              severity="secondary"
              onClick={() => {
                navigate("/record");
              }}
            />
            <Button
              text
              className="p-button-plain underline gap-1 flex align-self-center ml-auto"
              label={t("record.saveasdraft")}
              onClick={() => {
                setSaveAsDraftClicked(true);
              }}
            ></Button>
            <Button
              onClick={() => {
                navigate("/record/details");
              }}
              className="button-md gap-1"
              severity="secondary"
            >
              <FeatherIcon name="chevron-left" size={20} color="inherit" />
              <span className="font-bold text-capitalize">
                {t("record.back")}
              </span>
            </Button>
            <Button
              onClick={() => {
                setSaveAsDraftClicked(false);
              }}
              label={t("record.submit")}
              className="button-md"
            />
          </div>

          {/* Mobile responsive buttions */}
          <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 fixed-footer w-full left-0 shadow">
            <Button
              onClick={() => {
                setSaveAsDraftClicked(false);
              }}
              label={t("record.submit")}
              className="button-md w-full"
            />

            <div className="flex align-items-center gap-3 w-full">
              <Button
                onClick={() => {
                  navigate("/record/details");
                }}
                className="button-md"
                severity="secondary"
                aria-label={t("record.back")}
              >
                <FeatherIcon name="chevron-left" size={20} color="inherit" />
              </Button>
              <Button
                label={t("record.cancel")}
                className="button-md w-full"
                severity="secondary"
                onClick={() => {
                  navigate("/record");
                }}
              />
            </div>
            <Button
              text
              className="p-button-plain underline gap-1 flex align-self-center w-full"
              label={t("record.saveasdraft")}
              onClick={() => {
                setSaveAsDraftClicked(true);
              }}
            ></Button>
          </div>
        </form>
      </div>
      <Dialog
        header={
          <div className="border-circle bg-green-300 p-2 inline-block w-3rem h-3rem flex align-items-center justify-content-center border-8 border-green-100">
            <FeatherIcon
              name="check-circle"
              size={24}
              color="var(--icon-white)"
            />
          </div>
        }
        visible={visible}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
        className="custom-dialog hide-close"
        ariaCloseIconLabel="Close"
      >
        <div className="flex flex-column gap-3">
          <h2 className="text-lg font-bold m-0">Record Submitted</h2>
          <p className="text-base font-normal m-0">
            {t("record.requestSubmittedSub")}
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default RecordPreview;

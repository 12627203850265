import axios from "axios";
import { IDENTITY_URL } from "../config";

const axoisInstanceIdentity = axios.create({
    baseURL: IDENTITY_URL,
});

axoisInstanceIdentity.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axoisInstanceIdentity.interceptors.response.use(
    (response: any) => {
        return response;
    },
    (error) => {
        if (error.message) {
            console.error("network error", error.message);
        }

        if (error?.response?.status) {
            const statusCode = error.response.status;
            switch (statusCode) {
                case 401:
                    console.log("code 401");
                    break;
                case 404:
                    console.log("code 404");
                    break;
                case 405:
                    console.log("code 405");
                    break;
                case 500:
                    console.log("code 500");
                    break;
                default:
                    console.error("Unhandled API Error:", statusCode);

                    break;
            }
        } else if (error.request) {
            console.error("No response received:", error.request);
        } else {
            console.error("Request error:", error.message);
        }
        return Promise.reject(error);
    },
);

export default axoisInstanceIdentity;

// LanguageContext.tsx
import React, { createContext, useState, useContext } from "react";

interface LanguageContextType {
  selectedLanguage: string;
  changeLanguage: (lang: string) => void;
}

const LanguageContext = createContext<LanguageContextType>({
  selectedLanguage: "en",
  changeLanguage: () => {},
});

export const useLanguageContext = () => useContext(LanguageContext);

interface LanguageProviderProps {
  children: React.ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
  children,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>("EN");

  const changeLanguage = (lang: string) => {
    setSelectedLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ selectedLanguage, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;

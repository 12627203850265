import React, { FC, MouseEvent } from "react";
import * as feather from "feather-icons";

interface FeatherIconProps {
  name: string;
  size?: number;
  color?: string;
  onClick?: (event: MouseEvent<HTMLSpanElement>) => void;
}

const FeatherIcon: FC<FeatherIconProps> = ({
  name,
  size = 24,
  color = "inherit",
  onClick,
}) => {
  const iconMarkup = feather.icons[name].toSvg({
    width: size,
    height: size,
    color,
  });

  return (
    <span
      className="inline-flex align-self-center"
      style={{
        cursor: onClick ? "pointer" : "inherit",
      }}
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: iconMarkup }}
    />
  );
};

export default FeatherIcon;

import React, { useState, useRef } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OverlayPanel } from "primereact/overlaypanel"; // Import OverlayPanel
import Table from "../common/Table";
import { Calendar } from "primereact/calendar";
import { MenuItem } from "primereact/menuitem";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
interface Option {
  name: string;
  code: string;
}

const CommitteeMeetingTabDocuments: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: t("committee.dashboard"), url: "/dashboard" },
    { label: t("committee.committees") },
  ];

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const navigate = useNavigate();

  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  const [checked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };
  const [dateActivation, setDateActivation] = useState(null);
  const actionItems: MenuItem[] = [
    {
      label: "Opt 1",
      // command: () => {
      //   navigate("/member/request-view");
      // },
    },
    // {
    //   label: "Terminate member",
    // },
    // {
    //   label: "Reappoint member",
    // },
  ];
  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.meetingDocuments")}
            </h2>
          </div>

          <div className="p-5 cardBody flex flex-column gap-7">
            <Table
              products={[
                {
                  document: "Document name goes here",
                  documentType: "PDF",
                  sharedBy: "John Doe",
                  action: t("committee.action"),
                },
              ]}
              loading={false}
              columns={[
                { field: "document", header: t("committee.document") },
                { field: "documentType", header: t("committee.documentType") },
                { field: "sharedBy", header: t("committee.sharedBy") },
                {
                  field: "action",
                  header: t("committee.action"),
                  sortable: false,
                },
              ]}
              actionItems={actionItems}
            />
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex flex-wrap align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 justify-content-between cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.additionalDocuments")}
            </h2>
            <Button className="button-md gap-1 justify-content-center w-full sm:w-auto">
              <FeatherIcon name="plus" size={20} color="inherit" />
              <span className="font-bold text-capitalize">
                {t("committee.addDocuments")}
              </span>
            </Button>
          </div>

          <div className="p-5 cardBody flex flex-column gap-7">
            <Table
              products={[
                {
                  document: "Document name goes here",
                  documentType: "PDF",
                  sharedBy: "John Doe",
                  action: t("committee.action"),
                },
              ]}
              loading={false}
              columns={[
                { field: "document", header: t("committee.document") },
                { field: "documentType", header: t("committee.documentType") },
                { field: "sharedBy", header: t("committee.sharedBy") },
                {
                  field: "action",
                  header: t("committee.action"),
                  sortable: false,
                },
              ]}
              actionItems={actionItems}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommitteeMeetingTabDocuments;

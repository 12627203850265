import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import Table from "../../common/Table";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";

const ProjectTeamMembersTab: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  // Action menu
  const menuAction = useRef<Menu>(null);
  const toggleActionMenu = (event: any) => {
    if (menuAction.current) {
      menuAction.current.toggle(event);
    }
  };
  const showActionItem: MenuItem[] = [
    {
      label: "Option 1",
    },
    {
      label: "Option 2",
    },
  ];

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.teamMembers")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
              onClick={toggleActionMenu}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  toggleActionMenu(e);
                }
              }}
            >
              <span className="font-bold">{t("record.action")}</span>
              <FeatherIcon name="chevron-down" size={20} color="inherit" />
            </Button>
            <Menu
              model={showActionItem}
              popup
              ref={menuAction}
              id="actionMenu"
              popupAlignment="right"
              role="listbox"
            />
          </div>
          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "memberId", header: t("record.memberId") },
                { field: "memberName", header: t("record.memberName") },
                { field: "committeePositionBr", header: t("record.committeePositionBr") },
                { field: "joinedCommitteeOn", header: t("record.joinedCommitteeOn") },
                { field: "status", header: t("record.status") },
                { field: "action", header: t("record.action") },
              ]}
            />
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.teamNotes")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
            >
              <FeatherIcon name="plus" size={20} />
              <span>{t("record.addNew")}</span>
            </Button>
          </div>
          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "description", header: t("record.description"), },
                { field: "fileType", header: t("record.fileType") },
                { field: "attachments", header: t("record.attachments") },
              ]}
            />
          </div>
        </div>

      </div>
    </>
  );
};

export default ProjectTeamMembersTab;

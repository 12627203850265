import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { getTenantNameFromDomain } from "./utils/utils";
import { getEnvInfo } from "./utils/storage";
import { IDENTITY_URL } from "./config";
import { putData } from "./services/apiService";
import LoadingOverlay from "./components/common/LoadingOverlay";

interface ThemeContextProps {
  theme: string;
  setTheme: (newTheme: string) => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

const themes = [
  {
    name: "stanza-blue",
    title: "Stanza Blue",
    brandColor: "#1459a3",
    first: "#1459a3",
    bodyBgColor: "#f6f6f6",
  },
  {
    name: "stanza-red",
    title: "Stanza Red",
    brandColor: "#EA1B3D",
    first: "#212128",
    bodyBgColor: "#fff9ed",
  },
  {
    name: "stanza-lia",
    title: "Stanza Gray",
    brandColor: "#EA1B3D",
    first: "#4C4F54",
    bodyBgColor: "#fff9ed",
  },
];

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [defaultTheme, setDefaultTheme] = useState<string>("stanza-blue");
  const [tenantData, setTenantData] = useState<any>({});
  const [theme, setTheme] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getThemeName = async () => {
    try {
      const TenantDomain = getTenantNameFromDomain();
      const getTenantConfig = await fetch(
        `${IDENTITY_URL}/Tenant/GetTenantbyDomianName/${TenantDomain}`
      );
      const tenantResponseData = await getTenantConfig.json();
      setTenantData(tenantResponseData);
      setDefaultTheme(tenantResponseData.ApplicationTheme);
      return tenantResponseData.ApplicationTheme;
    } catch (e) {
      console.log("########e", e);
    }
  };

  const handlesSetTheme = async (themeName: string) => {
    if (themeName !== theme) {
      if (themeName !== tenantData.ApplicationTheme) {
        const tenantRequestData = {
          isDirty: true,
          isNew: false,
          tenantName: tenantData.TenantName,
          expirationDate: tenantData.ExpirationDate,
          applicationTheme: themeName,
          brandingLogo: tenantData.BrandingLogo,
          domainName: tenantData.DomainName,
          orgWebSite: tenantData.OrgWebSite,
        };
        const token = localStorage.getItem("token");
        await fetch(`${IDENTITY_URL}/Tenant/${tenantData.Id}`, {
          method: "PUT",
          body: JSON.stringify(tenantRequestData),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
      }
    }
  };

  const handleChangeTheme = async (newTheme: string) => {
    const themeExists = themes.some((theme) => theme.name === newTheme);
    const themeToSet = themeExists ? newTheme : defaultTheme;
    const newThemeHref = `/primereact-theme/themes/${themeToSet}/theme.css`;

    const newLinkElement = document.createElement("link");
    newLinkElement.rel = "stylesheet";
    newLinkElement.href = newThemeHref;
    newLinkElement.id = "new-theme-link";

    newLinkElement.onload = async () => {
      const oldLinkElement = document.getElementById(
        "theme-link"
      ) as HTMLLinkElement;
      if (oldLinkElement) {
        oldLinkElement.remove();
      }
      newLinkElement.id = "theme-link";
      setTheme(themeToSet);
      await handlesSetTheme(themeToSet);
      localStorage.setItem("appTheme", themeToSet);
    };

    newLinkElement.onerror = () => {
      console.error(`Failed to load theme: ${newThemeHref}`);
      newLinkElement.remove();
    };

    document.head.appendChild(newLinkElement);
  };

  useEffect(() => {
    const initializeTheme = async () => {
      const tenantTheme = await getThemeName();
      const storedTheme = localStorage.getItem("appTheme");
      const initialTheme = storedTheme || tenantTheme || defaultTheme;
      await handleChangeTheme(initialTheme);
      setLoading(false);
    };

    initializeTheme();
  }, []);

  if (loading) {
    return <LoadingOverlay visible={loading} />;
  }

  return (
    <ThemeContext.Provider
      value={{ theme: theme!, setTheme: handleChangeTheme }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export { ThemeProvider, useTheme };

import React, { useState, useEffect, ChangeEvent } from "react";
import { Button } from "primereact/button";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { fetchData } from "../../services/apiService";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import Table from "../common/Table";
import { usePermission } from "../auth/Authorization";
import { useLanguageContext } from "../LanguageContext";

const PandingReappointment: React.FC = () => {
  const [t, i18n] = useTranslation("report");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [searchText, setSearchText] = useState("");
  const [userProfileId, setUserProfileId] = useState<string>("");
  const [committeeList, setCommitteeList] = useState<
    { label: string; value: string }[] | []
  >([]);
  const [committeeValue, setCommitteeValue] = useState<
    string[] | null | string
  >(null);
  const [memberValue, setMembersValue] = useState<string[] | null | string>(
    null
  );
  const [memberList, setMemberList] = useState<
    { label: string; value: string }[] | []
  >([]);
  const [userRole, setUserRole] = useState<string>("");
  const [requestList, setRequestLists] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const breadcumbItems = [
    { label: t("report.dashboard"), url: "/dashboard" },
    { label: t("report.reports") },
  ];
  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const responseData = await fetchData(
          "UserProfile/GetCurrentUserProfile"
        );
        setUserProfileId(responseData.Id);
      } catch (error) {
        console.error("Error fetching current user profile:", error);
      }
    };
    fetchCurrentUser();
  }, []);

  const exportData = () => {
    if (!requestList) {
      return null;
    }
    const sortedData = requestList.map((item: any) => ({
      "Committee Name": item.CommitteeName,
      "Member Name": item.MemberName,
      "Membership expiration date": item.MemberExpirationDate,
      "Staff Contact": item.StaffContact,
      "Member Position": item.MemberPosition,
      "Category Of Interest": item.CategoryOfInterest,
    }));
    const csvData = [...sortedData];
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const filename = `Pending_Reappointment_Report_${day}-${month}-${year}_${hours}${minutes}${seconds}.csv`;
    return (
      <CSVLink data={csvData} filename={filename}>
        <Button className="button-md gap-1 justify-content-center w-full md:w-auto">
          <span className="font-bold text-capitalize">
            {t("report.export")}
          </span>
        </Button>
      </CSVLink>
    );
  };

  const handleChangeCommittee = (e: MultiSelectChangeEvent) => {
    const selectedCommittee = e.value;
    setCommitteeValue(selectedCommittee);
  };

  const handleChangeMembers = (e: MultiSelectChangeEvent) => {
    const selectedMembers = e.value;
    setMembersValue(selectedMembers);
  };

  const buildUrl = (
    selectedDate: any,
    userProfileId: string,
    userRole: string,
    committeeValue: string[] | string | null,
    memberValue: string[] | string | null
  ) => {
    let url = "CommitteeMembership/PendingReappointmentsReports?";
    const filters: {
      Key: string;
      Value: string | number | string[] | number[] | null;
    }[] = [];
    if (selectedDate) {
      const parsedDays = parseInt(selectedDate, 10);
      filters.push({ Key: "noofdaystoexpiration", Value: parsedDays });
    }
    if (committeeValue && committeeValue.length > 0) {
      filters.push({ Key: "CommitteeId", Value: committeeValue });
    }
    if (memberValue && memberValue.length > 0) {
      filters.push({ Key: "UserProfileId", Value: memberValue });
    }
    if (userProfileId && userRole !== "Admin") {
      filters.push({ Key: "StaffId", Value: userProfileId });
    }
    url += filters
      .map(
        (filter: {
          Key: string;
          Value: string | number | string[] | null | number[];
        }) =>
          `Filters[${filters.indexOf(filter)}].Key=${filter.Key}&Filters[${filters.indexOf(filter)}].Value=${filter.Value}`
      )
      .join("&");
    url += "&PageIndex=-1";
    return url;
  };

  const formatDateForResponse = (dateString: string) => {
    const date = new Date(dateString);
    const options: any = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const fetchDataBasedOnFilters = async (
    url: string,
    setRequestLists: React.Dispatch<React.SetStateAction<any[]>>,
    setMemberList: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    try {
      const responseData = await fetchData(url);
      const sortedData = responseData.Collection.map((item: any) => ({
        ...item,
        MemberExpirationDate: formatDateForResponse(item.MemberExpirationDate),
      }));
      setRequestLists(sortedData);
      if (!committeeValue && !memberValue) {
        const uniqueUserIds = new Set();
        const memberListData = responseData.Collection.reduce(
          (accumulator: { label: string; value: string }[], item: any) => {
            if (!uniqueUserIds.has(item.UserProfileId)) {
              accumulator.push({
                label: item.MemberName,
                value: item.UserProfileId,
              });
              uniqueUserIds.add(item.UserProfileId);
            }
            return accumulator;
          },
          []
        );
        const CommitteeListData = responseData.Collection.reduce(
          (accumulator: { label: string; value: string }[], item: any) => {
            if (!uniqueUserIds.has(item.CommitteeId)) {
              accumulator.push({
                label: item.CommitteeName,
                value: item.CommitteeId,
              });
              uniqueUserIds.add(item.CommitteeId);
            }
            return accumulator;
          },
          []
        );
        setMemberList(memberListData);
        setCommitteeList(CommitteeListData);
      }
    } catch (error) {
      console.error("Error fetching current user profile:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userProfileId) return;
    const url = buildUrl(
      selectedDate,
      userProfileId,
      userRole,
      committeeValue,
      memberValue
    );
    if (url) {
      fetchDataBasedOnFilters(url, setRequestLists, setMemberList);
    }
  }, [selectedDate, userProfileId, userRole, committeeValue, memberValue]);

  const handleInputChange = (value: any) => {
    setSelectedDate(value.target.value);
  };
  return (
    <>
      <div>
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-wrap md:flex-nowrap md:align-items-center gap-3 w-full justify-content-between">
          <h1 className="text-title display-xs font-bold m-0">
            {t("report.pendingReappointments")}
          </h1>
          <InputText
            type="number"
            placeholder={t("report.numberOfDaysToExpiration")}
            aria-label={t("report.numberOfDaysToExpiration")}
            value={selectedDate}
            className="w-full md:w-18rem lg:w:20rem"
            onChange={(value) => handleInputChange(value)}
            min="1"
            onKeyDown={(e) => e.key === "-" && e.preventDefault()}
          />
        </div>
        <div>
          <div className="flex flex-wrap md:align-items-center py-3 gap-3 w-full justify-content-between">
            <IconField iconPosition="left" className="w-full md:w-16rem">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                id="searchCommittee"
                className="w-full"
                placeholder={t("report.search")}
                aria-label={t("report.search")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </IconField>
            <div className="flex flex-wrap gap-3 w-full md:w-auto align-items-center">
              <MultiSelect
                value={committeeValue}
                onChange={handleChangeCommittee}
                options={committeeList}
                optionLabel="label"
                display="chip"
                placeholder={t("report.selectCommittees")}
                aria-label={t("report.selectCommittees")}
                maxSelectedLabels={3}
                className="w-full md:w-18rem lg:w:20rem"
              />
              <MultiSelect
                value={memberValue}
                onChange={handleChangeMembers}
                options={memberList}
                optionLabel="label"
                display="chip"
                placeholder={t("report.selectMembers")}
                aria-label={t("report.selectMembers")}
                maxSelectedLabels={3}
                className="w-full md:w-18rem lg:w:20rem"
              />
              {exportData()}
            </div>
          </div>
          <Table
            loading={loading}
            products={requestList?.filter((item: any) => {
              const memberName = "" || item.MemberName;
              const committeeName = "" || item.CommitteeName;
              return (
                searchText === "" ||
                committeeName
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                memberName.toLowerCase().includes(searchText.toLowerCase())
              );
            })}
            columns={[
              { field: "CommitteeName", header: t("report.committeeName") },
              { field: "MemberName", header: t("report.memberName") },
              {
                field: "MemberExpirationDate",
                header: t("report.membershipExpirationDate"),
              },
              { field: "StaffContact", header: t("report.staffContact") },
              { field: "MemberPosition", header: t("report.memberPosition") },
              {
                field: "CategoryOfInterest",
                header: t("report.categoryOfInterest"),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default PandingReappointment;

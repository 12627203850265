import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import CustomStepWizard from "../common/CustomStepWizard";
import BasicDetails from "./BasicDetails";
import ContributingCommittee from "./ContributingCommittee";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import Preview from "./Preview";
import OtherConfiguration from "./OtherConfiguration";

const CreateNewStandard: React.FC = () => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  const stepItems = [
    {
      icon: "cached",
      label: t("standard.basicDetails"),
    },
    {
      icon: "cached",
      label: t("standard.otherConfiguration"),
    },
    {
      icon: "cached",
      label: t("standard.contributingCommittee"),
    },
    {
      icon: "cached",
      label: t("standard.preview"),
    },
  ];

  const [activeStep, setActiveStep] = useState(0);

  return (
    <>
      {/* Header  */}
      <div className="mb-5">
        <Button
          text
          className="p-button-plain gap-2 mb-3 pl-0 underline"
          onClick={() => navigate("/standard")}
        >
          <FeatherIcon name="arrow-left" size={20} />
          <span>{t("standard.back")}</span>
        </Button>
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("standard.createNewStandard")}
          </h1>
          <p className="text-base font-normal m-0">
            One line supporting text will appear here
          </p>
        </div>
        <CustomStepWizard stepItems={stepItems} activeIndex={activeStep} />
      </div>

      {/* Body - steps  */}
      {activeStep === 0 && <BasicDetails />}
      {activeStep === 1 && <OtherConfiguration />}
      {activeStep === 2 && <ContributingCommittee />}
      {activeStep === 3 && <Preview />}


      {/* Footer buttons */}
      <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 fixed-footer w-full left-0 shadow">
        <Button
          label={t("standard.cancel")}
          className="button-md"
          severity="secondary"
          type="button"
          onClick={() => navigate("/standard")}
        />
        <Button
          className="button-md gap-1 ml-auto"
          severity="secondary"
          onClick={() => {
            if (activeStep === 0) {
              navigate("/standard");
            } else if (activeStep > 0) {
              setActiveStep(activeStep - 1);
            }
          }}
        >
          <FeatherIcon
            name="chevron-left"
            size={20}
            color="inherit"
          />
          <span className="font-bold text-capitalize">
            {t("standard.back")}
          </span>
        </Button>
        {activeStep == 3 ? (
          <Button className="button-md gap-1">
            <span className="font-bold">
              {t("standard.createStandard")}
            </span>
          </Button>
        ) : (
          <Button
            className="button-md gap-1"
            type="button"
            onClick={() => {
              if (activeStep < 3)
                setActiveStep(activeStep + 1)
            }}
          >
            <span className="font-bold text-capitalize">
              {t("standard.next")}
            </span>
            <FeatherIcon
              name="chevron-right"
              size={20}
              color="inherit"
            />
          </Button>
        )}
      </div>
      {/* Mobile responsive buttions */}
      <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
        {activeStep == 3 ? (
          <Button className="button-md gap-1 w-full justify-content-center">
            <span className="font-bold">
              {t("standard.createStandard")}
            </span>
          </Button>
        ) : (
          <Button
            className="button-md gap-1 w-full justify-content-center"
            type="button"
            onClick={() => {
              if (activeStep < 3)
                setActiveStep(activeStep + 1)
            }}
          >
            <span className="font-bold text-capitalize">
              {t("standard.next")}
            </span>
            <FeatherIcon
              name="chevron-right"
              size={20}
              color="inherit"
            />
          </Button>
        )}
        <div className="flex align-items-center gap-3 w-full">
          <Button
            className="button-md"
            severity="secondary"
            aria-label={t("ballot.back")}
            onClick={() => {
              if (activeStep === 0) {
                navigate("/standard");
              } else if (activeStep > 0) {
                setActiveStep(activeStep - 1);
              }
            }}
          >
            <FeatherIcon
              name="chevron-left"
              size={20}
              color="inherit"
            />
          </Button>
          <Button
            label={t("standard.cancel")}
            className="button-md w-full"
            severity="secondary"
            type="button"
            onClick={() => navigate("/standard")}
          />

        </div>
      </div>
    </>
  );
};

export default CreateNewStandard;

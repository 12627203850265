import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "../common/FeatherIconComponent";

const SplitStandard: React.FC = () => {
  const [t, i18n] = useTranslation("request");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  return (
    <>
      <div>
        <Button
          text
          className="p-button-plain gap-2 mb-3 pl-0 underline"
          onClick={() => navigate("/request/standard")}
        >
          <FeatherIcon name="arrow-left" size={20} />
          <span>{t("request.back")}</span>
        </Button>
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">
            {t("request.split")}
          </h1>
          <p className="text-base font-normal m-0">
            One line supporting text will appear here
          </p>
        </div>
      </div>
    </>
  );
};

export default SplitStandard;

import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import ToastMessage from "../common/ToastMessage";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Header from "../Header";
import Sidebar from "../SidebarApp";
import Layout from "../Layout";

const ErrorPage404: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="card bg-white shadow-md h-full p-5 flex justify-content-center">
        <div className="flex flex-column gap-3 text-center justify-content-center align-items-center">
          <h1 className="text-title display-xs font-bold m-0">Sorry...</h1>
          <p className="text-base font-normal m-0">
            There is some Network Issue... Please try after sometime.
          </p>
          <Button
            label="Go to Dashboard"
            className="button-md mt-6"
            onClick={() => navigate("/dashboard")}
          />
        </div>
      </div>
    </>
  );
};

export default ErrorPage404;

import React, {
  Children,
  useState,
  createContext,
  useContext,
  useEffect,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Outlet } from "react-router-dom";
import "./Layout.scss";
import Header from "./Header";
import Sidebar from "./SidebarApp";
import { LanguageProvider } from "./LanguageContext";
import SkipLink from "./SkipLink";
import LoadingOverlay from "./common/LoadingOverlay";
import { PermissionProvider } from "./auth/Authorization";
import FooterCopyright from "./ballot/FooterCopyright";

interface LayoutProps {
  children: React.ReactNode;
}

const LoadingContext = createContext<{
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  loading: false,
  setLoading: () => {},
});

const Layout = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const handleLanguageChange = (lang: any) => {
    setSelectedLanguage(lang);
    // You can perform any other actions related to language change here
  };

  const [loading, setLoading] = useState(false); // Add loading state
  useEffect(() => {
    // Add or remove class to body based on loading state
    if (loading) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }
  }, [loading]);

  const [hasFixedFooter, setHasFixedFooter] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const bodyHasFixedFooter = !!document.querySelector(".fixed-footer");
      if (bodyHasFixedFooter) {
        document.body.classList.add("body-footer-fixed");
      } else {
        document.body.classList.remove("body-footer-fixed");
      }
      setHasFixedFooter(bodyHasFixedFooter);
    });

    observer.observe(document.body, { subtree: true, childList: true });

    return () => observer.disconnect();
  }, []);

  return (
    <PermissionProvider>
      <LoadingContext.Provider value={{ loading, setLoading }}>
        <>
          {/* Add LoadingOverlay component */}
          <LoadingOverlay visible={loading} />

          {/* <Header /> */}
          <LanguageProvider>
            <SkipLink />
            <section className={`flex`}>
              {/* <section className={`flex ${hasFixedFooter ? 'fixed-footer-padding' : ''}`}> */}
              <Sidebar />
              <div className="flex flex-column w-full md:pl-7">
                <Header onLanguageChange={handleLanguageChange} />
                <main className="w-full p-4 content relative" id="main-content">
                  <Outlet />
                </main>
                <FooterCopyright />
              </div>
            </section>
          </LanguageProvider>
        </>
      </LoadingContext.Provider>
    </PermissionProvider>
  );
};
// Create a custom hook to consume loading context
export const useLoadingContext = () => useContext(LoadingContext);
export default Layout;

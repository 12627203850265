import React, { useEffect, useState, useRef } from "react";
import "./steps.scss";

interface StepItem {
  icon: string;
  label: string;
}

interface StepWizardProps {
  activeIndex: number;
  stepItems: StepItem[];
}

const CustomStepWizard: React.FC<StepWizardProps> = ({
  activeIndex,
  stepItems,
}) => {
  const [stepItemsList, setStepItemsList] = useState<StepItem[]>([]);
  const [stepItemsLoaded, setStepItemsLoaded] = useState<boolean>(false);
  const stepContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (stepItems && stepItems.length) {
      setStepItemsList(stepItems);
      setStepItemsLoaded(true);
    }
  }, [stepItems]);

  useEffect(() => {
    // Scroll to active step when component updates or window resizes
    const handleResizeAndScroll = () => {
      if (stepContainerRef.current && stepItemsLoaded) {
        const stepItemsElements = stepContainerRef.current.querySelectorAll('.p-steps-item');
        if (stepItemsElements.length > activeIndex && activeIndex >= 0) {
          const activeStep = stepItemsElements[activeIndex] as HTMLElement;
          const containerRect = stepContainerRef.current.getBoundingClientRect();
          const stepRect = activeStep.getBoundingClientRect();
          const scrollLeft = stepRect.left - containerRect.left;
          
          // Check if active step is fully visible within the container
          if (scrollLeft < 0 || scrollLeft + stepRect.width > containerRect.width) {
            // Scroll the container to make the active step fully visible
            stepContainerRef.current.scrollLeft = stepContainerRef.current.scrollLeft + scrollLeft;
          }
        }
      }
    };
  
    handleResizeAndScroll(); // Initial scroll on component mount
    window.addEventListener("resize", handleResizeAndScroll); // Event listener for window resize
  
    return () => {
      window.removeEventListener("resize", handleResizeAndScroll); // Cleanup event listener
    };
  }, [activeIndex, stepItemsLoaded]);
  
  
  
  const handleStepClick = (index: number) => {
    // Handle step click event if needed
  };

  return (
    <div className="step-wizard steps-wrap p-steps">
      <div className="step-scroll-container" ref={stepContainerRef}>
        <ol className="step-list">
          {stepItemsList.map((item, index) => (
            <li
              key={index}
              className={`p-steps-item flex align-items-start flex-column ${
                index === activeIndex ? "p-steps-current" : ""
              }`}
              onClick={() => handleStepClick(index)}
              tabIndex={0}
              aria-label={`Step ${index + 1}`}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleStepClick(index);
                }
              }}
            >
              <span className="material-symbols-outlined text-4xl"></span>
              <div
                id={`Step ${index + 1}`}
                className="p-1 text-step test-base font-bold mt-1"
              >
                <p className="md:hidden p-0 m-0">{index + 1} / {stepItemsList.length}</p>
                {item.label}
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default CustomStepWizard;

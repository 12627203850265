import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Input from "../../utils/Input";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchData,
  getAllCountries,
  getListByParentId,
  postData,
  putData,
} from "../../services/apiService";
import { useLanguageContext } from "../LanguageContext";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { focusOnErrorField } from "../../utils/focusError";
import { focusOnField } from "../../utils/focusField";
interface LicensedEngineer {
  isNew: boolean;
  TenantId?: string;
  IsDirty?: boolean;
  userProfileId: string;
  licenseNumber: string;
  licenseName: string;
  licenseYear: any;
  city: string;
  stateId: string;
  countryId: string;
  otherstate: string;
  userProfessionalLicenseId: string;
  isActive: boolean;
  isDeleted: boolean;
}

const AddProfessionalLicenses = () => {
  const { id } = useParams<any>();
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const [countries, setCountries] = useState<any[]>([]);
  const userProfileID = localStorage.getItem("userProfileID") || "";
  const [validationErrors, setValidationErrors] = useState<
    Array<Partial<LicensedEngineer>>
  >([]);
  const [states, setStates] = useState<any[]>([]);
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState(null);
  const years = [
    { label: "2024", value: 2024 },
    { label: "2023", value: 2023 },
    { label: "2022", value: 2022 },
    { label: "2021", value: 2021 },
    { label: "2020", value: 2020 },
    { label: "2019", value: 2019 },
    { label: "2018", value: 2018 },
    { label: "2017", value: 2017 },
    { label: "2016", value: 2016 },
    { label: "2015", value: 2015 },
    { label: "2014", value: 2014 },
    { label: "2013", value: 2013 },
    { label: "2012", value: 2012 },
    { label: "2011", value: 2011 },
    { label: "2010", value: 2010 },
    { label: "2009", value: 2009 },
    { label: "2008", value: 2008 },
    { label: "2007", value: 2007 },
    { label: "2006", value: 2006 },
    { label: "2005", value: 2005 },
    { label: "2004", value: 2004 },
    { label: "2003", value: 2003 },
    { label: "2002", value: 2002 },
    { label: "2001", value: 2001 },
    { label: "2000", value: 2000 },
    { label: "1999", value: 1999 },
    { label: "1998", value: 1998 },
    { label: "1997", value: 1997 },
    { label: "1996", value: 1996 },
    { label: "1995", value: 1995 },
    { label: "1994", value: 1994 },
    { label: "1993", value: 1993 },
    { label: "1992", value: 1992 },
    { label: "1991", value: 1991 },
    { label: "1990", value: 1990 },
  ];

  const [licensedEngineers, setLicensedEngineers] = useState<
    LicensedEngineer[]
  >([
    {
      isNew: true,
      //TenantId: TENANT_ID,
      IsDirty: true,
      userProfileId: userProfileID,
      licenseNumber: "",
      licenseName: "",
      licenseYear: 0,
      city: "",
      stateId: "",
      countryId: "",
      otherstate: "",
      userProfessionalLicenseId: userProfileID,
      isActive: true,
      isDeleted: false,
    },
  ]);

  const createProfileLabelForValidation = [
    { name: "licenseNumber", label: t("profile.licensedNumber") },
    { name: "licenseName", label: t("profile.licensedName") },
    { name: "licenseYear", label: t("profile.licensedYear") },
    { name: "countryId", label: t("profile.country") },
    { name: "stateId", label: t("profile.state") },
  ];

  const handleDateChange = (
    index: number,
    key: keyof LicensedEngineer,
    value: Date
  ) => {
    const newLicensedEngineers = [...licensedEngineers];
    newLicensedEngineers[index] = { ...newLicensedEngineers[index] };
    setLicensedEngineers(newLicensedEngineers);
  };

  React.useEffect(() => {
    getAllCountries()
      .then((data: any) => {
        const dataCollection = data?.Collection;
        const countryOptions = dataCollection.map((country: any) => ({
          value: country.Id,
          label: country.Name,
        }));
        setCountries(countryOptions);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const [validationError, setValidationError] = useState<string | null>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const newLicensedEngineers = [...licensedEngineers];
    newLicensedEngineers[index] = {
      ...newLicensedEngineers[index],
      [name]: value,
    };
    setLicensedEngineers(newLicensedEngineers);
  };

  const handleDeleteRow = (index: number) => {
    const newLicensedEngineers = [...licensedEngineers];
    newLicensedEngineers.splice(index, 1);
    setLicensedEngineers(newLicensedEngineers);
  };

  const handleInputChange = (
    index: number,
    key: keyof LicensedEngineer,
    value: string
  ) => {
    const newLicensedEngineers = [...licensedEngineers];
    newLicensedEngineers[index][key] = value as never;

    const newValidationErrors = [...validationErrors];
    newValidationErrors[index] = {
      ...newValidationErrors[index],
      [key]: undefined,
    };
    setValidationErrors(newValidationErrors);
    setLicensedEngineers(newLicensedEngineers);
  };
  const handleAddLicense = () => {
    const newProfessionalLicense: LicensedEngineer = {
      isNew: true,
      //TenantId: TENANT_ID,
      IsDirty: true,
      userProfileId: userProfileID,
      licenseNumber: "",
      licenseName: "",
      licenseYear: 0,
      city: "",
      stateId: "",
      countryId: "",
      otherstate: "",
      userProfessionalLicenseId: userProfileID,
      isActive: true,
      isDeleted: false,
    };
    setLicensedEngineers([...licensedEngineers, newProfessionalLicense]);

    // To focus on newly added row first element "licensedNumber"
    setTimeout(() => {
      focusOnField(`licensedNumber-${licensedEngineers.length}`);
    }, 0); // Delay of 0 milliseconds
  };

  const handleCountryChange = async (index: number, value: string) => {
    const newProfessionalLicense = [...licensedEngineers];

    try {
      const data = await getListByParentId("State", value);
      const dataCollection = data?.Collection;
      const stateOptions = dataCollection.map((state: any) => ({
        value: state.Id,
        label: state.Name,
      }));
      setStates(stateOptions);

      newProfessionalLicense[index]["countryId"] = value;

      // Update the validation error for CountryId field to undefined
      const newValidationErrors = [...validationErrors];
      newValidationErrors[index] = {
        ...newValidationErrors[index],
        countryId: undefined,
      };
      setValidationErrors(newValidationErrors);
    } catch (error) {
      console.error("Error fetching states:", error);
    }

    setLicensedEngineers(newProfessionalLicense);
  };

  const handleStateChange = (index: number, value: string) => {
    const newProfessionalLicense = [...licensedEngineers];
    newProfessionalLicense[index]["stateId"] = value;

    // Update the validation error for State field to undefined
    const newValidationErrors = [...validationErrors];
    newValidationErrors[index] = {
      ...newValidationErrors[index],
      stateId: undefined,
    };
    setValidationErrors(newValidationErrors);

    setLicensedEngineers(newProfessionalLicense);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setTimeout(() => {
      focusOnErrorField(".p-invalid");
    }, 100);
    const errors: Array<Partial<LicensedEngineer>> = [];
    const requiredFields = [
      "licenseNumber",
      "licenseName",
      "licenseYear",
      "countryId",
      "stateId",
    ];
    licensedEngineers.forEach((license, index) => {
      const error: Partial<LicensedEngineer> = {};
      requiredFields.forEach((field) => {
        const fieldValue = license[field as keyof LicensedEngineer];
        if (
          !fieldValue ||
          (typeof fieldValue === "string" && fieldValue.trim() === "")
        ) {
          const label =
            createProfileLabelForValidation.find((item) => item.name === field)
              ?.label || field;
          error[field as keyof LicensedEngineer] =
            `${label} ${t("profile.isRequired")}`;
        }
      });

      errors[index] = error;
    });

    setValidationErrors(errors);

    if (errors.some((error) => Object.keys(error).length !== 0)) {
      return;
    }
    try {
      const postDataResponse = await postData(
        "UserProfessionalLicense/BulkUpload",
        licensedEngineers
      );
      navigate("/profile/");
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  const isLicenseFilled = (license: LicensedEngineer): boolean => {
    return (
      license.licenseNumber.trim() !== "" &&
      license.licenseYear !== "" &&
      license.licenseName.trim() !== "" &&
      license.countryId.trim() !== "" &&
      license.stateId.trim() !== ""
    );
  };

  const areAllLicenseFilled = (): boolean => {
    return licensedEngineers.every(isLicenseFilled);
  };

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              onClick={() => navigate("/profile/")}
              aria-label={t("profile.back")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("profile.addLicensedDetails")}
              </h1>
              <p className="text-base font-normal m-0 text-help">
                {t("profile.allFieldsRequired")}
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="p-5 cardBody">
              {licensedEngineers.map((LicensedEngineer, index) => (
                <div
                  className={`flex flex-column gap-3 mb-3 ${
                    index > 0 ? "border-top-1 border-gray-200 pt-5" : ""
                  }`}
                  key={index}
                >
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`licensedNumber-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.licenseNumber
                            ? "p-error"
                            : ""
                        }`}
                      >
                        {t("profile.licensedNumber")}
                      </label>
                      <InputText
                        value={LicensedEngineer.licenseNumber}
                        id={`licensedNumber-${index}`}
                        aria-describedby={`licensedNumberError-${index}`}
                        name={`licensedNumber-${index}`}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "licenseNumber",
                            e.target.value
                          )
                        }
                        className={
                          validationErrors[index]?.licenseNumber
                            ? "p-invalid"
                            : ""
                        }
                        placeholder={t("profile.licensedNumber")}
                      />
                      <span
                        id={`licensedNumberError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.licenseNumber ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.licenseNumber}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`licensedYear-${index}`}
                        className={`block font-bold mb-0 ${
                          validationErrors[index]?.licenseYear ? "p-error" : ""
                        }`}
                      >
                        {t("profile.licensedYear")}
                      </label>
                      <Dropdown
                        inputId={`licensedYear-${index}`}
                        aria-describedby={`licensedYearError-${index}`}
                        value={LicensedEngineer.licenseYear}
                        options={years}
                        optionLabel="label"
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "licenseYear",
                            e.target.value
                          )
                        }
                        placeholder={t("profile.selectAnOption")}
                        className={
                          validationErrors[index]?.licenseYear
                            ? "p-invalid"
                            : ""
                        }
                      />
                      <span
                        id={`licensedYearError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.licenseYear ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.licenseYear}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`licensedName-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.licenseName ? "p-error" : ""
                        }`}
                      >
                        {t("profile.licensedName")}
                      </label>
                      <InputText
                        value={LicensedEngineer.licenseName}
                        id={`licensedName-${index}`}
                        aria-describedby={`licensedNameError-${index}`}
                        name={`licensedName-${index}`}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "licenseName",
                            e.target.value
                          )
                        }
                        className={
                          validationErrors[index]?.licenseName
                            ? "p-invalid"
                            : ""
                        }
                        placeholder={t("profile.licensedName")}
                      />
                      <span
                        id={`licensedNameError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.licenseName ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.licenseName}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`country-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.countryId ? "p-error" : ""
                        }`}
                      >
                        {t("profile.country")}
                      </label>
                      <Dropdown
                        inputId={`country-${index}`}
                        aria-describedby={`countryError-${index}`}
                        value={LicensedEngineer.countryId}
                        options={countries}
                        optionLabel="label"
                        onChange={(e) =>
                          handleCountryChange(index, e.target.value)
                        }
                        placeholder={t("profile.selectAnOption")}
                        className={
                          validationErrors[index]?.countryId ? "p-invalid" : ""
                        }
                      />
                      <span
                        id={`countryError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.countryId ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.countryId}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`state-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.stateId ? "p-error" : ""
                        }`}
                      >
                        {t("profile.state")}
                      </label>
                      <Dropdown
                        inputId={`state-${index}`}
                        aria-describedby={`stateError-${index}`}
                        value={LicensedEngineer.stateId}
                        options={states}
                        optionLabel="label"
                        onChange={(e) =>
                          handleStateChange(index, e.target.value)
                        }
                        placeholder="Select an  Option"
                        className={`${
                          validationErrors[index]?.stateId ? "p-invalid" : ""
                        }`}
                      />
                      <span
                        id={`stateError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.stateId ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.stateId}
                      </span>
                    </div>
                    <div
                      className="xl:col-3 lg:col-4 md:col-6 col-12"
                      style={{ display: "none" }}
                    >
                      <Input
                        type="text"
                        label={t("profile.city")}
                        value={LicensedEngineer.city}
                        name={`city-${index}`}
                        onChange={(e) => handleInputChange(index, "city", e)}
                        placeholder={t("profile.city")}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div className="block w-full">
                <Button
                  text
                  className="p-button-plain gap-2 pl-0 underline"
                  onClick={handleAddLicense}
                  disabled={!areAllLicenseFilled()}
                >
                  <FeatherIcon name="plus" size={20} />
                  <span>{t("profile.addLicense")}</span>
                </Button>
              </div>
            </div>

            <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-4 justify-content-end cardFooter">
              <div className="flex align-items-center gap-4">
                <Button
                  label={t("profile.cancel")}
                  className="button-md"
                  severity="secondary"
                  onClick={() => navigate("/profile")}
                />
                <Button label={t("profile.save")} className="button-md" />
              </div>
            </div>
            <div
              className={`p-error font-bold text-capitalize ${validationError ? "" : "error-hidden"}`}
            >
              {validationError}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddProfessionalLicenses;

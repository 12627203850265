import React, { useState, useEffect, useRef } from "react";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import {
  fetchData,
  postData,
  putMeberData,
} from "../../services/Ballot/apiservice";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

const MemberRolesTab: React.FC = () => {
  const [t, i18n] = useTranslation("configuration");
  const { selectedLanguage } = useLanguageContext();

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  interface MemberPosition {
    id: string;
    memberPositionId: string;
    memberType: string;
    canVote: boolean;
    canComment: boolean;
    canManageBallot: boolean;
    isDirty?: boolean;
  }

  const [memberPosition, setMemberPosition] = useState<MemberPosition[]>([]);
  const [payload, setPayload] = useState<{ [key: string]: any }>({});
  const toast = useRef<Toast>(null);

  useEffect(() => {
    const fetchMemberPositionData = async () => {
      try {
        const data = await fetchData("MemberPositionVotingRight/GetAll");
        const transformedData: MemberPosition[] = data.Collection.map(
          (item: any) => ({
            id: item.Id,
            memberPositionId: item.MemberPositionID,
            memberType: item.PositionName,
            canVote: item.CanVote,
            canComment: item.CanComment,
            canManageBallot: item.CanManageBallot,
          })
        );

        setMemberPosition(transformedData);
      } catch (error) {
        console.error("Error fetching ballot level data:", error);
      }
    };
    fetchMemberPositionData();
  }, []);

  const handleCheckboxChange = (
    rowData: MemberPosition,
    field: string,
    value: boolean
  ) => {
    const updatedMemberPositions = memberPosition.map((item) =>
      item.id === rowData.id ? { ...item, [field]: value, isDirty: true } : item
    );
    setMemberPosition(updatedMemberPositions);

    setPayload((prevPayload) => ({
      ...prevPayload,
      [rowData.id]: {
        ...prevPayload[rowData.id],
        [field]: value,
        isDirty: true,
        isNew: false,
        memberPositionId: rowData.memberPositionId,
        canVote:
          field === "canVote"
            ? value
            : prevPayload[rowData.id]?.canVote ?? rowData.canVote,
        canComment:
          field === "canComment"
            ? value
            : prevPayload[rowData.id]?.canComment ?? rowData.canComment,
        canManageBallot:
          field === "canManageBallot"
            ? value
            : prevPayload[rowData.id]?.canManageBallot ??
              rowData.canManageBallot,
      },
    }));
  };

  const handleSave = async () => {
    const dirtyRows = Object.entries(payload)
      .filter(([id, row]) => row.isDirty)
      .map(([id, row]) => ({
        id,
        memberPositionId: row.memberPositionId,
        canVote: row.canVote,
        canComment: row.canComment,
        canManageBallot: row.canManageBallot,
      }));

    try {
      await Promise.all(
        dirtyRows.map(async (row) => {
          const dataToSend = {
            canVote: row.canVote,
            canComment: row.canComment,
            canManageBallot: row.canManageBallot,
            membershipPositionId: row.memberPositionId,
          };

          if (row.id === "00000000-0000-0000-0000-000000000000") {
            await postData("MemberPositionVotingRight", dataToSend);
          } else {
            await putMeberData("MemberPositionVotingRight", row.id, dataToSend);
          }
        })
      );
      if (toast.current) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Data updated successfully",
          life: 3000,
        });
      }

      setMemberPosition((prevState) =>
        prevState.map((item) => ({ ...item, isDirty: false }))
      );
      setPayload({});
    } catch (error) {
      console.error("Error updating data:", error);
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error updating data",
          life: 3000,
        });
      }
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("configuration.MemberRoles")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <DataTable value={memberPosition}>
              <Column
                field="memberType"
                header={t("configuration.memberType")}
                sortable={true}
              />
              <Column
                field="canVote"
                header={t("configuration.canVote")}
                body={(rowData: MemberPosition) => (
                  <Checkbox
                    checked={rowData.canVote}
                    onChange={(e) =>
                      handleCheckboxChange(
                        rowData,
                        "canVote",
                        e.checked ?? false
                      )
                    }
                    aria-label={`Checkbox-canVote-${rowData.id}`}
                  />
                )}
              />
              <Column
                field="canComment"
                header={t("configuration.canComment")}
                body={(rowData: MemberPosition) => (
                  <Checkbox
                    checked={rowData.canComment}
                    onChange={(e) =>
                      handleCheckboxChange(
                        rowData,
                        "canComment",
                        e.checked ?? false
                      )
                    }
                    aria-label={`Checkbox-canComment-${rowData.id}`}
                  />
                )}
              />
              <Column
                field="canManageBallot"
                header={t("configuration.canManageBallot")}
                body={(rowData: MemberPosition) => (
                  <Checkbox
                    checked={rowData.canManageBallot}
                    onChange={(e) =>
                      handleCheckboxChange(
                        rowData,
                        "canManageBallot",
                        e.checked ?? false
                      )
                    }
                    aria-label={`Checkbox-canManageBallot-${rowData.id}`}
                  />
                )}
              />
            </DataTable>
          </div>

          <div className="flex align-items-center justify-content-end p-5 border-top-1 border-gray-200">
            <Button label="Save" onClick={handleSave} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberRolesTab;

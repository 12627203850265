// set the Tenant info from the local storage
export const setTenantInfo = (tenantInfo: any) => {
  tenantInfo = stringifyData(tenantInfo);
  localStorage.setItem("tenantInfo", tenantInfo);
};

// get Tenant info 
export const getTenantInfo = () => {
  const tenantInfo = parseIntoJSONData(localStorage.getItem("tenantInfo"));
  if (tenantInfo && tenantInfo !== 'undefined') return tenantInfo;
  else return null;
};

// remove the Tenant info from the local storage
export const removeTenantInfo = () => {
  localStorage.removeItem("tenantInfo");
};

// get Tenant info 
export const getIsADB2CLoginStatus = () => {
  // return true;
  const tenantInfo = parseIntoJSONData(localStorage.getItem("tenantInfo"));
  if (tenantInfo && tenantInfo !== 'undefined') {
    return tenantInfo?.IsADB2CLogin || false;
  } else {
    return  false;
  }
};

export const setTokenLS = (token: any) => {
  localStorage.setItem("token", token);
};

// get Tenant info 
export const getTokenLS = () => {
  const token = localStorage.getItem("token");
  if (token && token !== 'undefined') return token;
  else return null;
};

// remove the Tenant info from the local storage
export const removeTokenLS = () => {
  localStorage.removeItem("token");
};

// set the Tenant info from the local storage
export const setB2CAuthenticateSSOInfo = (b2cAuthSSOInfo: any) => {
  b2cAuthSSOInfo = stringifyData(b2cAuthSSOInfo);
  localStorage.setItem("b2cAuthSSOInfo", b2cAuthSSOInfo);
};

// get Tenant info 
export const getB2CAuthenticateSSOInfo = () => {
  const b2cAuthSSOInfo = parseIntoJSONData(localStorage.getItem("b2cAuthSSOInfo"));
  if (b2cAuthSSOInfo && b2cAuthSSOInfo !== 'undefined') return b2cAuthSSOInfo;
  else return null;
};

// remove the Tenant info from the local storage
export const removeB2CAuthenticateSSOInfo = () => {
  localStorage.removeItem("b2cAuthSSOInfo");
};

// set the Tenant info from the local storage
export const setEnvInfo = (envData: any) => {
  envData = stringifyData(envData);
  sessionStorage.setItem("envData", envData);
};

// get Tenant info 
export const getEnvInfo = () => {
  const envData = parseIntoJSONData(sessionStorage.getItem("envData"));
  if (envData && envData !== 'undefined') return envData;
  else return null;
};

// remove the Tenant info from the local storage
export const removeEnvInfo = () => {
  sessionStorage.removeItem("envData");
};

// remove the Tenant info from the local storage
export const clearAllSessionStorage = () => {
  const envInfoObj = getEnvInfo();
  sessionStorage.clear();
  setEnvInfo(envInfoObj);
};

export async function generateKey() {
  const key = await window.crypto.subtle.generateKey(
      {
          name: "AES-GCM",
          length: 256,
      },
      true,
      ["encrypt", "decrypt"]
  );
  return key;
}

export async function encryptObject(key: any, obj: any) {
  const iv = window.crypto.getRandomValues(new Uint8Array(12));
  const encoder = new TextEncoder();
  const data = encoder.encode(JSON.stringify(obj));
  const encrypted = await window.crypto.subtle.encrypt(
      {
          name: "AES-GCM",
          iv: iv
      },
      key,
      data
  );
  return { iv: iv, encryptedData: encrypted };
}

export async function decryptObject(key: any, encryptedObj: any) {
  const { iv, encryptedData } = encryptedObj;
  const decrypted = await window.crypto.subtle.decrypt(
      {
          name: "AES-GCM",
          iv: iv
      },
      key,
      encryptedData
  );
  const decoder = new TextDecoder();
  const decryptedText = decoder.decode(decrypted);
  return JSON.parse(decryptedText);
}

export const stringifyData = (data: any) => {
  if(data && data !== null && data !== undefined) {
    return JSON.stringify(data);
  }
  return data;
};

export const parseIntoJSONData = (data: any) => {
  if(data && data !== null && data !== undefined && data !== "undefined") {
    return JSON.parse(data);
  }

  return data;
};
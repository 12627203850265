import React, { useEffect, useState } from "react";
import CommitteeConfigurationTab from "./CommitteeConfigurationTab";
import CommitteeConfigurationTabView from "./CommitteeConfigurationTabView";
import { fetchData } from "../../services/apiService";

interface CommitteeConfigurationToggleProps {
  setActiveIndex: (index: number) => void; // Define setActiveIndex prop
}

const CommitteeConfigurationToggle: React.FC<
  CommitteeConfigurationToggleProps
> = ({ setActiveIndex }) => {
  const [committeeData, setCommitteeData] = useState<any[]>([]);
  const [editClicked, setEditClicked] = useState<boolean>(false);

  const fetchCommitteeConfig = async () => {
    try {
      const responseData = await fetchData("CommitteeConfiguration/GetAll");
      const CommitteeConfigData = responseData?.Collection;
      setCommitteeData(CommitteeConfigData);
    } catch (error) {
      console.error("Error fetching committee configuration", error);
    }
  };

  useEffect(() => {
    fetchCommitteeConfig();
  }, []);

  const handleEditButtonClick = () => {
    setActiveIndex(1);
    setEditClicked(true);
  };

  const handleViewTab = () => {
    setEditClicked(false);
  };

  return (
    <div>
      {committeeData.length === 0 || editClicked ? (
        <CommitteeConfigurationTab onSubmitSuccess={handleViewTab} />
      ) : (
        <CommitteeConfigurationTabView
          setActiveIndex={setActiveIndex}
          onEditButtonClick={handleEditButtonClick}
        />
      )}
    </div>
  );
};

export default CommitteeConfigurationToggle;

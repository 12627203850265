import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import Table from "../../common/Table";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";

const RecordAuditTab: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  // Action menu
  const menuAction = useRef<Menu>(null);
  const toggleActionMenu = (event: any) => {
    if (menuAction.current) {
      menuAction.current.toggle(event);
    }
  };
  const showActionItem: MenuItem[] = [
    {
      label: "Option 1",
    },
    {
      label: "Option 2",
    },
  ];

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.recordAuditHistory")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
              onClick={toggleActionMenu}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  toggleActionMenu(e);
                }
              }}
            >
              <span className="font-bold">{t("record.action")}</span>
              <FeatherIcon name="chevron-down" size={20} color="inherit" />
            </Button>
            <Menu
              model={showActionItem}
              popup
              ref={menuAction}
              id="actionMenu"
              popupAlignment="right"
              role="listbox"
            />
          </div>
          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "sNo", header: t("record.sNo") },
                { field: "date", header: t("record.date") },
                { field: "field", header: t("record.field") },
                { field: "user", header: t("record.user") },
                { field: "originalValue", header: t("record.originalValue") },
                { field: "newValue", header: t("record.newValue") },
              ]}
            />
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("record.relatedRecords")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
              onClick={toggleActionMenu}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  toggleActionMenu(e);
                }
              }}
            >
              <span className="font-bold">{t("record.action")}</span>
              <FeatherIcon name="chevron-down" size={20} color="inherit" />
            </Button>
            <Menu
              model={showActionItem}
              popup
              ref={menuAction}
              id="actionMenu"
              popupAlignment="right"
              role="listbox"
            />
          </div>
          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "sNo", header: t("record.sNo"), },
                { field: "date", header: t("record.date") },
              ]}
            />
          </div>
        </div>

      </div>
    </>
  );
};

export default RecordAuditTab;

import React, { useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { InputComponent } from "../common/inputComponent";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import Input from "../../utils/Input";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { AutoComplete } from "primereact/autocomplete";

interface EducationDetail {
  educationLevel: string;
  degree: string;
  fieldOfStudy: string;
  school: string;
  yearCompleted: number;
}

const AddMembershipDetails = () => {
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const navigate = useNavigate();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [educationDetail, setEducationDetail] = useState<EducationDetail>({
    educationLevel: "",
    degree: "",
    fieldOfStudy: "",
    school: "",
    yearCompleted: 0,
  });
  const [degree, setDegree] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const degreeOptions = [
    { label: "Bachelor of Science", value: "BSc" },
    { label: "Master of Science", value: "MSc" },
    // Add more degree options as needed
  ];

  const universityOptions = [
    { label: "Harvard University", value: "Harvard" },
    { label: "MIT", value: "MIT" },
    // Add more university options as needed
  ];

  const citySuggestions = [
    "New York",
    "Los Angeles",
    "Chicago",
    "Houston",
    "Phoenix",
  ];
  const stateSuggestions = ["New York", "California", "Texas", "Arizona"];
  const countrySuggestions = [
    "United States",
    "Canada",
    "United Kingdom",
    "Australia",
  ];
  const completeCity = (e: any) => {
    setCity(e.value);
  };

  const completeState = (e: any) => {
    setState(e.value);
  };

  const completeCountry = (e: any) => {
    setCountry(e.value);
  };

  const onDegreeChange = (e: any) => {
    setDegree(e.value);
  };

  //   const onUniversityChange = (e: any) => {
  //     setUniversity(e.value);
  //   };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setEducationDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleExpiryDateChange = (value: string) => {
    setExpiryDate(value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEducationDetail({
      educationLevel: "",
      degree: "",
      fieldOfStudy: "",
      school: "",
      yearCompleted: 0,
    });
  };

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              aria-label={t("profile.back")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("profile.addmembershipdetail")}
              </h1>
              <p className="text-base font-normal m-0 text-help">
                {t("profile.allFieldsRequired")}
              </p>
            </div>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="membertype"
                    className="block font-bold text-input-label capitalize"
                  >
                    {t("profile.membertype")}
                  </label>
                  <Dropdown
                    inputId="membertype"
                    value={degree}
                    onChange={onDegreeChange}
                    options={degreeOptions}
                    optionLabel="label"
                    placeholder={t("profile.selectAnOption")}
                    className="w-full"
                    aria-labelledby="membertype"
                  />
                </div>
              </div>
              <div className="grid grid-xl">
                <div className="xl:col-3 lg:col-4 md:col-6 col-12">
                  <Input
                    type="text"
                    label={t("profile.expirydate")}
                    value={expiryDate}
                    name="expiryDate"
                    onChange={handleExpiryDateChange}
                    placeholder={t("profile.expirydate")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-4 justify-content-end cardFooter">
            <div className="flex align-items-center gap-4">
              <Button
                label={t("profile.cancel")}
                className="button-md"
                severity="secondary"
              />
              <Button label={t("profile.save")} className="button-md" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMembershipDetails;

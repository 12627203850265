import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IDENTITY_URL } from "../../config";
import { getTenantNameFromDomain } from "../../utils/utils";
const ResetPasswordEmailSent = () => {
  const location = useLocation();
  const { email } = location.state;
  const [resetSent, setResetSent] = useState(false);
  const [countdown, setCountdown] = useState(30);

  const handleResetClick = async () => {
    setResetSent(true);
    let requestBody: any = {
      email: email,
      TenantDomain: getTenantNameFromDomain(),
    };
    let emailVerifier = await fetch(
      `${IDENTITY_URL}/Account/ForgetPasswordEmailVerify`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resetSent && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (resetSent && countdown === 0) {
      setResetSent(false);
      setCountdown(30);
    }
    return () => clearTimeout(timer);
  }, [resetSent, countdown]);
  return (
    <>
      <div className="flex align-items-center justify-content-center align-self-center h-full p-3">
        <div className="surface-card p-fluid sm:px-4 md:px-8 shadow-2 border-round w-full loginCards">
          <div className="flex flex-column gap-4 md:p-8 p-5">
            <div className="flex flex-column gap-2">
              <h1 className="text-title display-sm font-bold m-0 mb-3">
                Email sent
              </h1>
              <p className="m-0">
                We have sent a password reset link to <strong> {email} </strong>
              </p>
              <p className="m-0">
                Follow the instructions on the email to reset your password.
              </p>
            </div>
            <div>
              <span>Haven’t received an email?</span>
              {resetSent ? (
                <span>
                  Resend in
                  <span className="font-bold text-link">
                    {countdown} seconds
                  </span>
                </span>
              ) : (
                <>
                  <span
                    className="font-bold underline ml-2 text-link cursor-pointer"
                    onClick={() => handleResetClick()}
                  >
                    Send the Reset Link again
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordEmailSent;

import React from 'react';
import { RadioButton } from 'primereact/radiobutton';

interface VotingOptionsProps {
    votingOptionsList: { label: string; value: string }[];  
    selectedVoteOptionId: string;
    userRole: string;
    selectedCommittee: string | null;
    selectedMember: string | null;
    index: number;
    handleVotingOptionChange: (index: number, value: string) => void;
}

const VotingOptions: React.FC<VotingOptionsProps> = ({
    votingOptionsList,
    selectedVoteOptionId,
    userRole,
    selectedCommittee,
    selectedMember,
    index,
    handleVotingOptionChange,
}) => {
    return (
        <div>
            <div className="flex flex-wrap column-gap-7 gap-3">
                {votingOptionsList.map((option: {label: string, value: string}, optindex: number) => (
                    <div key={optindex} className="flex align-items-center" style={{ cursor: (userRole !== "Member" && (!selectedCommittee || !selectedMember)) ? 'not-allowed' : 'pointer' }}>
                    <RadioButton
                        inputId={`votingOption${optindex + 1}`}
                        name={`votingOption${index}`}
                        value={option.value}
                        onChange={(e) => handleVotingOptionChange(index, e.value)}
                        checked={selectedVoteOptionId === option.value}
                        aria-labelledby={`votingOption${optindex + 1}Label`}
                        disabled={userRole !== "Member" && (!selectedCommittee || !selectedMember)}
                    />
                    <label
                        htmlFor={`votingOption${optindex + 1}`}
                        id={`votingOption${optindex + 1}Label`}
                        className="ml-2"
                    >
                        {option.label}
                    </label>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default VotingOptions;
import { Avatar } from 'primereact/avatar'
import { formatDate } from '../../../utils/utils'
import { CommentSectionProps } from './type'

const CommentSection = ({ comment }: CommentSectionProps) => {
    return (
        <>
            <div className="flex flex-wrap align-items-center justify-content-between py-3 gap-3">
            <div className="flex flex-row gap-2 align-items-center">
                <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" size="large" shape="circle" className="flex-shrink-0" />
                <div className="flex flex-column gap-1">
                    <h3 className="text-title text-base font-bold text-capitalize m-0">
                        {comment?.CreatedByName}
                        </h3>
                        <p className="text-base font-normal text-capitalize m-0">
                            {comment?.CreatedByUserPosition}
                        </p>
                </div>
            </div>
            <div className="flex align-items-center gap-3">
                <span>{formatDate(comment?.CreatedDate)}</span>
            </div>
        </div>
            <div>
                <p className="p-0">
                    {comment?.ResponseText}
                </p>
            </div>
        </>
    )
}

export default CommentSection
import React from "react";

const FooterCopyright: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="flex align-items-center justify-content-center px-5 py-2 gap-4 w-full left-0 text-center footer-copyright bg-footer">
        <p className="p-0 m-0 text-sm">
          &copy; Copyright {currentYear} InfoBeans
        </p>
      </footer>
    </>
  );
};

export default FooterCopyright;

import { Committee } from "../ballotCommitte";
import { fetchData as getData } from "../../../services/apiService";
import { fetchData } from "../../../services/Ballot/apiservice";
let ballotId = "";
let allBallotCommittee: any = [];
let node: any = [];
let tableParentCommitteesData: any = [];
let allCommitteeMembership: any = [];
export const fetchCommittees = async (
  ballotUid: string,
  ballotCommittees: any,
  disableColumn: boolean,
  ballotLevelSelect: any,
  ballotRecordStatusId: string
) => {
  try {
    ballotId = ballotUid;
    allBallotCommittee = ballotCommittees;
    const selectCommitteeData = await getData(`Committee/Search?PageIndex=-1`);
    const getAllCommitteeMembership = await getData(
      `CommitteeMembership/GetAll`
    );
    allCommitteeMembership = getAllCommitteeMembership.Collection;
    const parentCommittees: Committee[] = selectCommitteeData.Collection.filter(
      (committee: any) =>
        committee.HierarchyLevel.Name === ballotLevelSelect.value
    );
    tableParentCommitteesData = parentCommittees;
    const formatedParentCommittee = parentCommittees.map(
      async (committee: any) => {
        const memberCount = allCommitteeMembership.filter(
          (committeMember: any) => committee.Id === committeMember.CommitteeId
        );
        let matchedCommittee = allBallotCommittee.find(
          (prev: any) => prev.CommitteeName === committee.Name
        );
        let isRevivewCommentCommittee = matchedCommittee
          ? matchedCommittee.forReviewAndComment
          : null;
        return {
          key: `${committee.Id}-${committee.ParentCommitteeId}`,
          Id: committee.ParentCommitteeId,
          data: {
            Id: committee.Id,
            Abbreviation: committee.Abbreviation,
            ParentCommitteeId: committee.ParentCommitteeId,
            CreatedDate: committee.CreatedDate,
            ModifiedDate: committee.ModifiedDate,
            Name: committee.Name,
            Count: `${memberCount.length} Members`,
            balloting:
              matchedCommittee && !disableColumn
                ? !matchedCommittee.ForReviewAndComment
                : null,
            review: matchedCommittee
              ? matchedCommittee.ForReviewAndComment
              : null,
            isDirty: false,
            isNew: true,
            ballotId: ballotId,
            committeeId: committee.Id,
            ballotRecordStatusId: ballotRecordStatusId,
            isRevivewCommentCommittee: isRevivewCommentCommittee,
            ballotCommitteeId: "00000000-0000-0000-0000-000000000000",
            isActive: true,
            isDeleted: false,
          },
          children: [],
        };
      }
    );

    const parentCommitteesData = await Promise.all(formatedParentCommittee);
    node = parentCommitteesData;
    fetchSubCommittee(ballotRecordStatusId);
    return node;
  } catch (err) {
    console.log(err, "error fetching committees");
  }
};
const fetchSubCommittee = async (ballotRecordStatusId: string) => {
  try {
    node.forEach(async (parentData: any, index: any) => {
      const subcommitteesData = tableParentCommitteesData.find(
        (data: any) => parentData.data.Id === data.ParentCommitteeId
      );
      if (subcommitteesData) {
        const memberCount = allCommitteeMembership.filter(
          (committeMember: any) =>
            subcommitteesData.Id === committeMember.CommitteeId
        );

        const childSubCommitteesData = {
          key: `${subcommitteesData.Id}-${subcommitteesData.Id}`,
          Id: subcommitteesData.Id,
          parentId: subcommitteesData.Id,
          data: {
            Id: subcommitteesData.Id,
            Abbreviation: subcommitteesData.Abbreviation,
            ParentCommitteeId: subcommitteesData.ParentCommitteeId,
            CreatedDate: subcommitteesData.CreatedDate,
            ModifiedDate: subcommitteesData.ModifiedDate,
            Name: subcommitteesData.Name,
            Count: `${memberCount.length} Members`,
            balloting: false,
            review: false,
            isDirty: false,
            isNew: true,
            ballotId: ballotId,
            committeeId: subcommitteesData.Id,
            ballotRecordStatusId: ballotRecordStatusId,
            isRevivewCommentCommittee: false,
            ballotCommitteeId: "00000000-0000-0000-0000-000000000000",
            isActive: true,
            isDeleted: false,
          },
          children: [],
        };
        const formattedChildData = {
          key: subcommitteesData.Id,
          Id: subcommitteesData.ParentCommitteeId,
          data: {
            Id: subcommitteesData.Id,
            Abbreviation: subcommitteesData.Abbreviation,
            ParentCommitteeId: subcommitteesData.ParentCommitteeId,
            CreatedDate: subcommitteesData.CreatedDate,
            ModifiedDate: subcommitteesData.ModifiedDate,
            Name: subcommitteesData.Name,
            Count: `${memberCount.length} Members`,
            balloting: false,
            review: false,
            isDirty: false,
            isNew: true,
            ballotId: ballotId,
            committeeId: subcommitteesData.Id,
            ballotRecordStatusId: ballotRecordStatusId,
            isRevivewCommentCommittee: false,
            ballotCommitteeId: "00000000-0000-0000-0000-000000000000",
            isActive: true,
            isDeleted: false,
          },
          children: [childSubCommitteesData],
        };
        node[index].children.push(formattedChildData);
      }
    });
    return node;
  } catch (err) {
    console.log(err, `error fetching subcommittees for `);
  }
};
const fetchSubCommitteeData = async () => {
  try {
    node.forEach((parentData: any, index: any) => {
      const childObj = node.find(
        (data: any) => parentData.data.Id === data.data.ParentCommitteeId
      );
      if (childObj) {
      }
    });
    return node;
  } catch (err) {
    console.log(err, `error fetching subcommittees for `);
  }
};

const updateNode = (nodes: any, parentId: any, subCommittees: any) => {
  return nodes.map((node: any) => {
    if (node.Id === parentId) {
      return {
        ...node,
        children: subCommittees,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateNode(node.children, parentId, subCommittees),
      };
    }
    return node;
  });
};

export const BallotType = async () => {
  try {
    const response: any = await fetch(`ballotType/GetAll`);
    const data = response.Collection;
    return data;
  } catch (error) {
    console.error("Error fetching ballot types:", error);
    throw error;
  }
};

import React, { useState, useRef } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel"; // Import OverlayPanel
import Table from "../common/Table";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
interface Option {
  name: string;
  code: string;
}

const StandardManagementListing: React.FC = () => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);


  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const navigate = useNavigate();

  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  return (
    <>
      <div>

        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">{t("standard.standardsManagement")}</h1>
          <p className="text-base font-normal m-0">
            One line supporting text will appear here
          </p>
        </div>
        <div>
          <div className="flex flex-wrap md:flex-nowrap md:justify-content-between align-items-center py-3 gap-3 w-full">
            <IconField iconPosition="left" className="w-full md:w-16rem">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                id="searchMemberOrPosition"
                placeholder={t("standard.searchMemberOrPosition")}
                className="w-full"
                aria-label={t("standard.searchMemberOrPosition")}
              />
            </IconField>
            <div className="flex flex-wrap md:flex-nowrap gap-3 w-full md:w-auto align-items-center">
              <div className="flex gap-3 w-full md:w-auto align-items-center">
                <div className="relative">
                  <Button
                    className="button-md gap-1"
                    severity="secondary"
                    onClick={toggleFilterPanel}
                    aria-label={t("standard.filter")}
                  >
                    <FeatherIcon name="filter" size={20} color="inherit" />
                    <span className="font-bold text-capitalize hidden md:flex">
                      {t("standard.filter")}
                    </span>
                  </Button>
                  {/* OverlayPanel for Filter */}
                  <OverlayPanel
                    ref={overlayPanelRef}
                    onHide={() => setShowFilterPanel(false)}
                    appendTo={"self"}
                    dismissable
                    className="p-overlaypanel-filter left-auto md:right-0 top-100"
                    aria-labelledby="filter-heading"
                    role="dialog"
                  >
                    <div className="flex flex-column gap-1" tabIndex={0}>
                      <p id="filter-heading" className="text-lg font-bold m-0 p-3">
                        {t("standard.filter")}
                      </p>
                      <div className="filter-wrap">
                        {/* Add filter fields here */}
                      </div>
                      <Button
                        className="button-md w-full justify-content-center"
                      >
                        {t("standard.applyFilter")}
                      </Button>
                    </div>
                  </OverlayPanel>
                </div>
                <Button className="button-md gap-1 justify-content-center w-full md:w-auto" onClick={() => navigate("/standard/create")}>
                  <FeatherIcon name="plus" size={20} color="inherit" />
                  <span className="font-bold text-capitalize">{t("standard.newStandard")}</span>
                </Button>
              </div>
            </div>
          </div>

          <Table
            loading={false}
            products={[]}
            columns={[
              { field: "standardName", header: t("standard.standardName") },
              { field: "shortName", header: t("standard.shortName") },
              { field: "primaryCommitteeResponsible", header: t("standard.primaryCommitteeResponsible") },
              { field: "maintenanceCycle", header: t("standard.maintenanceCycle") },
              { field: "publishedYear", header: t("standard.publishedYear") },
              { field: "accreditation", header: t("standard.accreditation") },
              { field: "action", header: t("standard.action") },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default StandardManagementListing;

import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import CustomBreadcrumb from "../common/CustomBreadcrumb";

const AdministrativeRecord: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: t("record.dashboard"), url: "/dashboard" },
    { label: t("record.administrativeRecord") },
  ];

  return (
    <>
      <div className="">
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">
            {t("record.administrativeRecord")}
          </h1>
          <p className="text-base font-normal m-0">
            Any supporting text for request listing page can go here
          </p>
        </div>
      </div>
    </>
  );
};

export default AdministrativeRecord;

import React from "react";
import { useTranslation } from "react-i18next";
import { TabView, TabPanel } from "primereact/tabview";
import { useLanguageContext } from "../../LanguageContext";
import BallotTable from "./BallotTable";

const NonStandardDocuments: React.FC = () => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  return (
    <>
      <div>
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">
            {t("ballot.nonStandardDocumentsBallots")}
          </h1>
          <p className="text-base font-normal m-0">
            {t("ballot.myBallotHelp")}
          </p>
        </div>
        <div className="tabs-wrap">
          <TabView scrollable>
            <TabPanel header={t("ballot.newDocument")}>
              <BallotTable />
            </TabPanel>
            <TabPanel header={t("ballot.revision")}>
              <BallotTable />
            </TabPanel>
            <TabPanel header={t("ballot.withdraw")}>
              <BallotTable />
            </TabPanel>
            <TabPanel header={t("ballot.all")}>
              <BallotTable />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default NonStandardDocuments;

import React, { useState, useRef, useEffect } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OverlayPanel } from "primereact/overlaypanel"; // Import OverlayPanel
import Table from "../common/Table";
import { Calendar } from "primereact/calendar";
import { MenuItem } from "primereact/menuitem";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { fetchData } from "../../services/apiService";
import { MultiSelect } from "primereact/multiselect";
import { BASE_URL } from "../../config";
import axiosInstance from "../../services/axiosInstance";
import { debug } from "console";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { sortByStartDate } from "../../utils/utils";
import { focusOnField } from "../../utils/focusField";
import { useLoadingContext } from "../Layout";
interface Option {
  name: string;
  code: string;
}

const CommitteeTabRequests: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const [memberPosition, setMemberPosition] = useState([]);
  const [coiData, setCoiData] = useState([]);
  const [selectedCOIs, setSelectedCOIs] = useState<any[]>([]);
  const [coiCheckboxChecked, setCoiCheckboxChecked] = useState<boolean>(false);
  const [membershipStatusData, setMembershipStatusData] = useState([]);
  const [memberStatusCheckboxChecked, setMemberStatusCheckboxChecked] =
    useState<boolean>(false);
  const [selectedPositions, setSelectedPositions] = useState<any[]>([]);
  const [positionCheckboxChecked, setPositionCheckboxChecked] =
    useState<boolean>(false);
  const [dateCheckboxChecked, setDateCheckboxChecked] =
    useState<boolean>(false);
  const [dateActivationFrom, setDateActivationFrom] = useState<Date | null>(
    null
  );
  const [dateActivationTo, setDateActivationTo] = useState<Date | null>(null);
  const [selectedMemberStatuses, setSelectedMemberStatuses] = useState<any[]>(
    []
  );
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const [search, setSearch] = useState("");
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: t("committee.dashboard"), url: "/dashboard" },
    { label: t("committee.committees") },
  ];

  const fetchCategoryOfInterest = async () => {
    try {
      const response = await fetchData("CategoryOfInterest/GetAll");
      const categoryOfInterestData = response.Collection;
      setCoiData(categoryOfInterestData);
    } catch (error) {
      console.error("Error fetching COI:", error);
    }
  };

  const handleDateSelect = (date: Date | null, field: string) => {
    focusOnField(field);
    if (field === "dateActivationFrom") {
      setDateActivationFrom(date);
    } else if (field === "dateActivationTo") {
      setDateActivationTo(date);
    }
  };

  const fetchMembershipStatus = async () => {
    try {
      const response = await fetchData("MembershipStatus/GetAll");
      const memberstatus = response.Collection;
      const filteredStatus = memberstatus.filter((status: any) => {
        const removedStatusNames = [
          "Retired",
          "Current",
          "Terminate",
          "Resign",
        ];
        return !removedStatusNames.includes(status.StatusName);
      });
      const modifiedStatus = filteredStatus.map((status: any) => {
        if (status.StatusName === "Convert to Record") {
          return { ...status, StatusName: "Converted to Record" };
        }
        return status;
      });
      setMembershipStatusData(modifiedStatus);
    } catch (error) {
      console.error("Error fetching membership status:", error);
    }
  };

  const fetchMembershipPosition = async () => {
    try {
      const response = await fetchData("MembershipPosition/GetAll");
      const membershipPositionData = response.Collection;
      setMemberPosition(membershipPositionData);
    } catch (error) {
      console.error("Error fetching Membership Position:", error);
    }
  };

  useEffect(() => {
    fetchCategoryOfInterest();
    fetchMembershipStatus();
    fetchMembershipPosition();
  }, []);

  const handleCheckboxChange =
    (checkbox: string) => (e: CheckboxChangeEvent) => {
      const isChecked = e.checked !== undefined ? e.checked : false;
      switch (checkbox) {
        case "coi":
          setCoiCheckboxChecked(isChecked);
          break;
        case "status":
          setMemberStatusCheckboxChecked(isChecked);
          break;
        case "position":
          setPositionCheckboxChecked(isChecked);
          break;
        case "date":
          setDateCheckboxChecked(isChecked);
          break;
        default:
          break;
      }
    };

  const fetchFilteredData = async (event: any) => {
    const queryParams: { [key: string]: any } = {};
    const filters: { Key: string; Value: string }[] = [];

    if (coiCheckboxChecked) {
      const coiIds = selectedCOIs.map((coi) => coi.Id);
      filters.push({ Key: "CategoryOfInterestId", Value: coiIds.join(",") });
    }
    if (memberStatusCheckboxChecked) {
      const memberStatusIds = selectedMemberStatuses.map((status) => status.Id);
      filters.push({
        Key: "MembershipStatusId",
        Value: memberStatusIds.join(","),
      });
    }
    if (positionCheckboxChecked) {
      const positionIds = selectedPositions.map((position) => position.Id);
      filters.push({
        Key: "MembershipPositionId",
        Value: positionIds.join(","),
      });
    }
    if (dateCheckboxChecked) {
      const fromDateStr = formatDate(dateActivationFrom);
      const toDateStr = formatDate(dateActivationTo);
      filters.push({ Key: "DateFilter", Value: `${fromDateStr}#${toDateStr}` });
    }
    filters.forEach((filter, index) => {
      queryParams[`Filters[${index}].Key`] = filter.Key;
      queryParams[`Filters[${index}].Value`] = filter.Value;
    });
    queryParams["PageIndex"] = -1;
    //queryParams["PageSize"] = 10;
    queryParams["SearchText"] = "";
    queryParams["SortBy"] = "";
    queryParams["SortDir"] = "asc";
    const apiUrl = `${BASE_URL}/MembershipRequest/Search?${new URLSearchParams(queryParams).toString()}`;
    try {
      const response = await axiosInstance.get(apiUrl);

      if (response.status === 200) {
        const filteredData = response.data;
        const filterIdArray = filteredData.Collection.map(
          (data: any) => data.Id
        );
        const filterMembershipRequestData: any =
          memebershipRequestTableData?.filter(
            (data: any) =>
              filterIdArray.includes(data.Id) &&
              data.statusName.toLowerCase() !== "draft"
          );
        let sortedFilteredData = sortByStartDate(
          filterMembershipRequestData,
          "requestDate"
        );
        setFilteredRecords(sortedFilteredData);
        setIsFilterApplied(true);
      } else {
        console.error("Failed to fetch filtered data");
      }
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
    overlayPanelRef.current?.toggle(event);
    setCoiCheckboxChecked(false);
    setMemberStatusCheckboxChecked(false);
    setPositionCheckboxChecked(false);
    setDateCheckboxChecked(false);
    setSelectedCOIs([]);
    setSelectedMemberStatuses([]);
    setSelectedPositions([]);
    setDateActivationFrom(null);
    setDateActivationTo(null);
  };

  const formatDate = (date: Date | null) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };
  const handleViewRequest = () => {
    sessionStorage.setItem("redirectionPath", "request");
    navigate("/member/request-view");
  };

  const actionItems: MenuItem[] = [
    {
      label: t("committee.viewRequest"),
      command: handleViewRequest,
    },
  ];

  const formatMembershipRequest = (data: any) => {
    return data.map((details: any) => {
      return {
        requestNumber: details.MembershipRequestNumber,
        memberName:
          details?.UserProfile.Firstname + " " + details?.UserProfile?.Lastname,
        committeeApplied: details?.Committee?.Name,
        positionApplied: details?.MembershipPosition?.PositionName,
        categoryOfInterest: details?.CategoryOfInterest?.CategoryName,
        requestDate: details?.CreatedDate
          ? new Date(details?.CreatedDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : "",
        statusName: details?.MembershipStatus?.StatusName,
        status: details?.Active
          ? t("committee.active")
          : t("committee.inactive"),
        action: t("committee.action"),
        sessionKey: "memberShipId",
        Id: details?.Id,
      };
    });
  };
  const [memebershipRequestTableData, setMemebershipRequestTableData] =
    useState<any>();
  useEffect(() => {
    const handleMembershipResquestListing = async () => {
      try {
        const committeeId = sessionStorage.getItem("committeeId");
        if (committeeId) {
          const membershipRequest = await fetchData(
            `MembershipRequest/Search?Filters[0].Key=CommitteeId&Filters[0].Value=${committeeId}&PageIndex=-1`
          );
          let formatedData = formatMembershipRequest(
            membershipRequest.Collection
          ).filter((data: any) => data.statusName.toLowerCase() !== "draft");
          let sortedMembershipRequestData = sortByStartDate(
            formatedData,
            "requestDate"
          );
          setMemebershipRequestTableData(sortedMembershipRequestData);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    handleMembershipResquestListing();
  }, []);

  return (
    <>
      <div className="">
        <div className="flex flex-wrap md:flex-nowrap md:align-items-center py-3 gap-3 w-full">
          <IconField iconPosition="left" className="w-full md:w-16rem">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              id="recordId"
              placeholder={t("committee.searchRequest")}
              className="w-full"
              aria-label={t("committee.searchRequest")}
              onChange={(e) => setSearch(e.target.value)}
            />
          </IconField>
          <div className="flex gap-3 w-full md:w-auto align-items-center ml-auto">
            <div className="relative">
              <Button
                className="button-md gap-1"
                severity="secondary"
                onClick={toggleFilterPanel}
                aria-label={t("committee.filter")}
              >
                <FeatherIcon name="filter" size={20} color="inherit" />
                <span className="font-bold text-capitalize hidden md:flex">
                  {t("committee.filter")}
                </span>
              </Button>
              {/* OverlayPanel for Filter */}
              <OverlayPanel
                ref={overlayPanelRef}
                onHide={() => setShowFilterPanel(false)}
                appendTo={"self"}
                dismissable
                className="p-overlaypanel-filter left-auto md:right-0 top-100"
                aria-labelledby="filter-heading"
                role="dialog"
              >
                <div className="flex flex-column gap-1" tabIndex={0}>
                  <p id="filter-heading" className="text-lg font-bold m-0 p-3">
                    {t("committee.filter")}
                  </p>
                  <div className="filter-wrap">
                    <div className="">
                      <label
                        htmlFor="categoryOfInterestFilter"
                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                      >
                        <Checkbox
                          inputId="categoryOfInterestFilter"
                          onChange={handleCheckboxChange("coi")}
                          checked={coiCheckboxChecked}
                          className="mr-2"
                          tabIndex={0}
                        />
                        {t("committee.categoryOfInterest")}
                      </label>
                      <div className="pr-3 py-2 pl-6 w-full relative">
                        <MultiSelect
                          // appendTo={"self"}
                          value={selectedCOIs}
                          onChange={(e) => setSelectedCOIs(e.value)}
                          options={coiData}
                          optionLabel="CategoryName"
                          filter
                          placeholder={t("committee.categoryOfInterest")}
                          maxSelectedLabels={3}
                          className="w-full"
                          aria-label={t("committee.categoryOfInterest")}
                        />
                      </div>
                    </div>
                    <div className="p-0">
                      <label
                        htmlFor="requestStatusFilter"
                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                      >
                        <Checkbox
                          inputId="requestStatusFilter"
                          onChange={handleCheckboxChange("status")}
                          checked={memberStatusCheckboxChecked}
                          className="mr-2"
                          tabIndex={0}
                        />
                        {t("committee.requestStatus")}
                      </label>
                      <div className="pr-3 py-2 pl-6 w-full relative">
                        <MultiSelect
                          // appendTo={"self"}
                          value={selectedMemberStatuses}
                          onChange={(e) => setSelectedMemberStatuses(e.value)}
                          options={membershipStatusData}
                          optionLabel="StatusName"
                          filter
                          placeholder={t("committee.requestStatus")}
                          maxSelectedLabels={3}
                          className="w-full"
                          aria-label={t("committee.requestStatus")}
                        />
                      </div>
                    </div>
                    <div className="p-0">
                      <label
                        htmlFor="membershipPositionIdFilter"
                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                      >
                        <Checkbox
                          inputId="membershipPositionIdFilter"
                          onChange={handleCheckboxChange("position")}
                          checked={positionCheckboxChecked}
                          className="mr-2"
                          tabIndex={0}
                        />
                        {t("committee.membershipPositionId")}
                      </label>
                      <div className="pr-3 py-2 pl-6 w-full relative">
                        <MultiSelect
                          // appendTo={"self"}
                          value={selectedPositions}
                          onChange={(e) => setSelectedPositions(e.value)}
                          options={memberPosition}
                          optionLabel="PositionName"
                          filter
                          placeholder={t("committee.membershipPositionId")}
                          maxSelectedLabels={3}
                          className="w-full"
                          aria-label={t("committee.membershipPositionId")}
                        />
                      </div>
                    </div>
                    <div className="p-0">
                      <label
                        htmlFor="requestedDateFilter"
                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                      >
                        <Checkbox
                          inputId="requestedDateFilter"
                          onChange={handleCheckboxChange("date")}
                          checked={dateCheckboxChecked}
                          className="mr-2"
                          tabIndex={0}
                        />
                        {t("committee.requestDate")}
                      </label>
                      <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                        <Calendar
                          className="custom-datepicker"
                          inputId="dateActivationFrom"
                          onChange={(e) =>
                            handleDateSelect(
                              e.value as Date,
                              "dateActivationFrom"
                            )
                          }
                          value={dateActivationFrom}
                          dateFormat="mm/dd/yy"
                          tabIndex={0}
                          ariaLabel={t("committee.fromDate")}
                          placeholder={t("committee.fromDate")}
                        />
                        <Calendar
                          className="custom-datepicker"
                          inputId="dateActivationTo"
                          onChange={(e) =>
                            handleDateSelect(
                              e.value as Date,
                              "dateActivationTo"
                            )
                          }
                          value={dateActivationTo}
                          dateFormat="mm/dd/yy"
                          tabIndex={0}
                          ariaLabel={t("committee.toDate")}
                          placeholder={t("committee.toDate")}
                        />
                      </div>
                    </div>
                  </div>
                  <Button
                    className="button-md w-full justify-content-center"
                    onClick={(e: any) => fetchFilteredData(e)}
                  >
                    {t("committee.applyFilter")}
                  </Button>
                </div>
              </OverlayPanel>
            </div>
            {/* <Button
              onClick={() => navigate("/member/create")}
              className="button-md gap-1 justify-content-center w-full md:w-auto"
            >
              <FeatherIcon name="plus" size={20} color="inherit" />
              <span className="font-bold text-capitalize">{"New Request"}</span>
            </Button> */}
          </div>
        </div>

        <Table
          loading={loading}
          // products={memebershipRequestTableData}
          products={
            isFilterApplied
              ? filteredRecords?.filter((item: any) => {
                  const CommitteeApplied =
                    (item && item.committeeApplied) || "";
                  const MemberName = (item && item.memberName) || "";
                  const requestNumber = (item && item.requestNumber) || "";
                  return (
                    search === "" ||
                    MemberName.toLowerCase().includes(search.toLowerCase()) ||
                    CommitteeApplied.toLowerCase().includes(
                      search.toLowerCase()
                    ) ||
                    requestNumber.toLowerCase().includes(search.toLowerCase())
                  );
                })
              : memebershipRequestTableData?.filter((item: any) => {
                  const CommitteeApplied =
                    (item && item.committeeApplied) || "";
                  const MemberName = (item && item.memberName) || "";
                  const requestNumber = (item && item.requestNumber) || "";
                  return (
                    search === "" ||
                    MemberName.toLowerCase().includes(search.toLowerCase()) ||
                    CommitteeApplied.toLowerCase().includes(
                      search.toLowerCase()
                    ) ||
                    requestNumber.toLowerCase().includes(search.toLowerCase())
                  );
                })
          }
          columns={[
            {
              field: "requestNumber",
              header: t("committee.requestId"),
              link: "/member/request-view",
            },
            { field: "memberName", header: t("committee.memberName") },
            {
              field: "committeeApplied",
              header: t("committee.committeeApplied"),
            },
            {
              field: "positionApplied",
              header: t("committee.positionApplied"),
            },
            {
              field: "categoryOfInterest",
              header: t("committee.categoryOfInterest"),
            },
            { field: "requestDate", header: t("committee.requestDate") },
            { field: "statusName", header: t("committee.status") },
            { field: "action", header: t("committee.action"), sortable: false },
          ]}
          actionItems={actionItems}
        />
      </div>
    </>
  );
};

export default CommitteeTabRequests;

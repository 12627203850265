import React, { useState } from "react";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import Table from "../common/Table";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "../common/FeatherIconComponent";

interface Option {
  name: string;
  code: string;
}

const BallotConfigurationTab: React.FC = () => {

  const [t, i18n] = useTranslation("configuration");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  const [allowEditVoteAfterClosed, setallowEditVoteAfterClosed] = useState<string>('');
  const [allowEditVoteBeforeClosed, setallowEditVoteBeforeClosed] = useState<string>('');
  const [allowVotingPostBallotEndDate, setallowVotingPostBallotEndDate] = useState<string>('');
  const [adjustRankingUnderCastAVote, setadjustRankingUnderCastAVote] = useState<string>('');
  const [newRemoveExistingRecordsRecirculatingBallots, setnewRemoveExistingRecordsRecirculatingBallots] = useState<string>('');
  const [newRemoveExistingCommitteeRecirculatingBallots, setnewRemoveExistingCommitteeRecirculatingBallots] = useState<string>('');



  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const [value, setValue] = useState<string>('');

  const [isBallotConfVisible, setIsBallotConfVisible] = useState(false);
  const handleBallotConfHideShow = () => {
    setIsBallotConfVisible(!isBallotConfVisible);
  };

  return (
    <>
      <div className="">
        {!isBallotConfVisible && (
          <div>
            <div className="flex justify-content-end py-3 gap-3 w-full">
              <Button
                className="button-md gap-1 justify-content-center w-full md:w-auto"
                onClick={handleBallotConfHideShow}
              >
                <FeatherIcon name="plus" size={20} color="inherit" />
                <span className="font-bold text-capitalize">
                  {t("configuration.addNewConfiguration")}
                </span>
              </Button>
            </div>
            <Table
              loading={false}
              products={[]}
              columns={[
                {
                  field: "ballotLevel",
                  header: t("configuration.ballotLevel"),
                },
                {
                  field: "ballotType",
                  header: t("configuration.ballotType"),
                },
                {
                  field: "ballotSubType",
                  header: t("configuration.ballotSubType"),
                },
                {
                  field: "recordType",
                  header: t("configuration.recordType"),
                },
                {
                  field: "recordSubType",
                  header: t("configuration.recordSubType"),
                },
                {
                  field: "recordSubSubType",
                  header: t("configuration.recordSubSubType"),
                },
                {
                  field: "editVoteAfterClosed",
                  header: t("configuration.editVoteAfterClosed"),
                },
                {
                  field: "editVoteBeforeClosed",
                  header: t("configuration.editVoteBeforeClosed"),
                },
                {
                  field: "firstNotification",
                  header: t("configuration.firstNotification"),
                },
                {
                  field: "secondNotification",
                  header: t("configuration.secondNotification"),
                },
                {
                  field: "thirdNotification",
                  header: t("configuration.thirdNotification"),
                },
                { field: "action", header: t("configuration.action") },
              ]}
            />
          </div>
        )}

        {isBallotConfVisible && (
          <>
            <div className="card bg-white w-full mb-5 shadow-md">
              <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
                <Button
                  text
                  className="p-button-plain p-0"
                  onClick={handleBallotConfHideShow}
                  aria-label={t("configuration.back")}>
                  <FeatherIcon name="arrow-left" size={32} />
                </Button>
                <h2 className="text-title text-lg font-bold m-0">
                  {t("configuration.ballotConfiguration")}
                </h2>
              </div>

              <div className="p-5 cardBody">
                <div className="flex flex-column gap-3">
                <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="applicableAccreditationBody"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.applicableAccreditationBody")}
                      </label>
                      <Dropdown
                        inputId="applicableAccreditationBody"
                        name="applicableAccreditationBody"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="ballotLevel"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.ballotLevel")}
                      </label>
                      <Dropdown
                        inputId="ballotLevel"
                        name="ballotLevel"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="ballotType"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.ballotType")}
                      </label>
                      <Dropdown
                        inputId="ballotType"
                        name="ballotType"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="ballotSubType"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.ballotSubType")}
                      </label>
                      <Dropdown
                        inputId="ballotSubType"
                        name="ballotSubType"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="recordType"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.recordType")}
                      </label>
                      <Dropdown
                        inputId="recordType"
                        name="recordType"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="recordSubType"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.recordSubType")}
                      </label>
                      <Dropdown
                        inputId="recordSubType"
                        name="recordSubType"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="recordSubSubType"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.recordSubSubType")}
                      </label>
                      <Dropdown
                        inputId="recordSubSubType"
                        name="recordSubSubType"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-3">
                      <fieldset className="border-none p-0">
                        <legend className="block font-bold text-input-label p-0 mb-3">
                          {t("configuration.allowEditVoteAfterClosed")}
                        </legend>
                        <div className="flex flex-wrap gap-3">
                          <div className="flex align-items-center">
                            <RadioButton inputId="allowEditVoteAfterClosed1" name="allowEditVoteAfterClosed" value="Yes" onChange={(e: RadioButtonChangeEvent) => setallowEditVoteAfterClosed(e.value)} checked={allowEditVoteAfterClosed === 'Yes'} />
                            <label htmlFor="allowEditVoteAfterClosed1" className="ml-2">Yes</label>
                          </div>
                          <div className="flex align-items-center">
                            <RadioButton inputId="allowEditVoteAfterClosed2" name="allowEditVoteAfterClosed" value="No" onChange={(e: RadioButtonChangeEvent) => setallowEditVoteAfterClosed(e.value)} checked={allowEditVoteAfterClosed === 'No'} />
                            <label htmlFor="allowEditVoteAfterClosed2" className="ml-2">No</label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-3">
                      <fieldset className="border-none p-0">
                        <legend className="block font-bold text-input-label p-0 mb-3">
                          {t("configuration.allowEditVoteBeforeClosed")}
                        </legend>
                        <div className="flex flex-wrap gap-3">
                          <div className="flex align-items-center">
                            <RadioButton inputId="allowEditVoteBeforeClosed1" name="allowEditVoteBeforeClosed" value="Yes" onChange={(e: RadioButtonChangeEvent) => setallowEditVoteBeforeClosed(e.value)} checked={allowEditVoteBeforeClosed === 'Yes'} />
                            <label htmlFor="allowEditVoteBeforeClosed1" className="ml-2">Yes</label>
                          </div>
                          <div className="flex align-items-center">
                            <RadioButton inputId="allowEditVoteBeforeClosed2" name="allowEditVoteBeforeClosed" value="No" onChange={(e: RadioButtonChangeEvent) => setallowEditVoteBeforeClosed(e.value)} checked={allowEditVoteBeforeClosed === 'No'} />
                            <label htmlFor="allowEditVoteBeforeClosed2" className="ml-2">No</label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-3">
                      <fieldset className="border-none p-0">
                        <legend className="block font-bold text-input-label p-0 mb-3">
                          {t("configuration.allowVotingPostBallotEndDate")}
                        </legend>
                        <div className="flex flex-wrap gap-3">
                          <div className="flex align-items-center">
                            <RadioButton inputId="allowVotingPostBallotEndDate1" name="allowVotingPostBallotEndDate" value="Yes" onChange={(e: RadioButtonChangeEvent) => setallowVotingPostBallotEndDate(e.value)} checked={allowVotingPostBallotEndDate === 'Yes'} />
                            <label htmlFor="allowVotingPostBallotEndDate1" className="ml-2">Yes</label>
                          </div>
                          <div className="flex align-items-center">
                            <RadioButton inputId="allowVotingPostBallotEndDate2" name="allowVotingPostBallotEndDate" value="No" onChange={(e: RadioButtonChangeEvent) => setallowVotingPostBallotEndDate(e.value)} checked={allowVotingPostBallotEndDate === 'No'} />
                            <label htmlFor="allowVotingPostBallotEndDate2" className="ml-2">No</label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <div className="grid grid-xl align-items-end">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="firstVotingNotificationDays"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.firstVotingNotificationDays")}
                      </label>
                      <InputText
                        id="firstVotingNotificationDays"
                        name="firstVotingNotificationDays"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        type="number"
                        placeholder={t("configuration.firstVotingNotificationDays")}
                        className="w-full"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="secondVotingNotificationDays"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.secondVotingNotificationDays")}
                      </label>
                      <InputText
                        id="secondVotingNotificationDays"
                        name="secondVotingNotificationDays"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        type="number"
                        placeholder={t("configuration.secondVotingNotificationDays")}
                        className="w-full"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="thirdVotingNotificationDays"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.thirdVotingNotificationDays")}
                      </label>
                      <InputText
                        id="thirdVotingNotificationDays"
                        name="thirdVotingNotificationDays"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        type="number"
                        placeholder={t("configuration.thirdVotingNotificationDays")}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/* <h3 className="font-bold text-lg m-0">
                    {t("configuration.ballotApprovalCriteriaNotDefined")}
                  </h3>
                  <div className="ballot-approval-criteria-grid mb-3">
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="operator1"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.operator1")}*
                      </label>
                      <Dropdown
                        inputId="operator1"
                        className="w-full"
                        name="operator1"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="percent1"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        %*
                      </label>
                      <InputText
                        id="percent1"
                        name="percent1"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        type="number"
                        placeholder="%"
                        className="w-full"
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="votingOptions"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.votingOptions")}*
                      </label>
                      <Dropdown
                        className="w-full"
                        inputId="votingOptions"
                        name="votingOptions"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="operator2"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.operator2")}
                      </label>
                      <Dropdown
                        className="w-full"
                        inputId="operator2"
                        name="operator2"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="operator3"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.operator3")}
                      </label>
                      <Dropdown
                        className="w-full"
                        inputId="operator3"
                        name="operator3"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="percent2"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        %
                      </label>
                      <InputText
                        id="percent2"
                        name="percent2"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        type="number"
                        placeholder="%"
                        className="w-full"
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="votingOption"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.votingOption")}
                      </label>
                      <Dropdown
                        className="w-full"
                        inputId="votingOption"
                        name="votingOption"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div> */}

                  <h3 className="font-bold text-lg m-0">
                    {t("configuration.rankingPoints")}
                  </h3>
                  <div className="flex flex-column gap-2 w-full">
                    <div className="flex flex-column gap-2">
                      <div className="grid grid-xl mt-0">
                        <span
                          className={`flex xl:col-3 lg:col-4 md:col-5 col-5 py-1 font-bold text-capitalize`}
                          id="rank"
                        >
                          {t("configuration.rank")}
                        </span>
                        <span
                          className={`flex xl:col-3 lg:col-3 md:col-5 col-5 py-1 font-bold text-capitalize`}
                          id="points"
                        >
                          {t("configuration.points")}
                        </span>
                      </div>
                      <div className="flex flex-column w-full">
                        <div className={`grid grid-xl align-items-center idx`}>
                          <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                            <Dropdown
                              inputId="rank-0"
                              name="rank-0"
                              value={selectedOption}
                              onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                              options={options}
                              optionLabel="name"
                              placeholder={t("configuration.selectAnOption")}
                              aria-labelledby="rank"
                            />
                          </div>
                          <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                            <InputText
                              id="points-0"
                              name="points-0"
                              value={value}
                              onChange={(e) => setValue(e.target.value)}
                              type="number"
                              placeholder=""
                              className="w-full"
                            />
                          </div>
                          <div className="w-auto p-0">
                            <Button
                              text
                              className="p-button-plain p-2 text-delete"
                              aria-label={t("configuration.delete")}
                            >
                              <FeatherIcon
                                name="trash-2"
                                size={20}
                              />
                            </Button>
                          </div>
                        </div>
                        <div className={`grid grid-xl align-items-center idx`}>
                          <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                            <Dropdown
                              inputId="rank-0"
                              name="rank-0"
                              value={selectedOption}
                              onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                              options={options}
                              optionLabel="name"
                              placeholder={t("configuration.selectAnOption")}
                              aria-labelledby="rank"
                            />
                          </div>
                          <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                            <InputText
                              id="points-0"
                              name="points-0"
                              value={value}
                              onChange={(e) => setValue(e.target.value)}
                              type="number"
                              placeholder=""
                              className="w-full"
                            />
                          </div>
                          <div className="w-auto p-0">
                            <Button
                              text
                              className="p-button-plain p-2 text-delete"
                              aria-label={t("configuration.delete")}
                            >
                              <FeatherIcon
                                name="trash-2"
                                size={20}
                              />
                            </Button>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div>
                      <Button
                        text
                        className="p-button-plain gap-1 pl-0 underline"
                      >
                        <FeatherIcon name="plus" size={20} />
                        <span className="font-bold text-capitalize">
                          {t("configuration.addRank")}
                        </span>
                      </Button>
                    </div>
                  </div>

                  {/* <div className="grid grid-xl">
                    <div className="flex flex-column gap-3">
                      <fieldset className="border-none p-0">
                        <legend className="block font-bold text-input-label p-0 mb-3">
                          {t("configuration.adjustRankingUnderCastAVote")}
                        </legend>
                        <div className="flex flex-wrap gap-3">
                          <div className="flex align-items-center">
                            <RadioButton inputId="adjustRankingUnderCastAVote1" name="adjustRankingUnderCastAVote" value="Yes" onChange={(e: RadioButtonChangeEvent) => setadjustRankingUnderCastAVote(e.value)} checked={adjustRankingUnderCastAVote === 'Yes'} />
                            <label htmlFor="adjustRankingUnderCastAVote1" className="ml-2">Yes</label>
                          </div>
                          <div className="flex align-items-center">
                            <RadioButton inputId="adjustRankingUnderCastAVote2" name="adjustRankingUnderCastAVote" value="No" onChange={(e: RadioButtonChangeEvent) => setadjustRankingUnderCastAVote(e.value)} checked={adjustRankingUnderCastAVote === 'No'} />
                            <label htmlFor="adjustRankingUnderCastAVote2" className="ml-2">No</label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div> */}

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-3 xl:col-3 lg:col-4 md:col-6 col-12">
                      <fieldset className="border-none p-0">
                        <legend className="block font-bold text-input-label p-0 mb-3">
                          {t("configuration.newRemoveExistingRecordsRecirculatingBallots")}
                        </legend>
                        <div className="flex flex-wrap gap-3">
                          <div className="flex align-items-center">
                            <RadioButton inputId="newRemoveExistingRecordsRecirculatingBallots1" name="newRemoveExistingRecordsRecirculatingBallots" value="Yes" onChange={(e: RadioButtonChangeEvent) => setnewRemoveExistingRecordsRecirculatingBallots(e.value)} checked={newRemoveExistingRecordsRecirculatingBallots === 'Yes'} />
                            <label htmlFor="newRemoveExistingRecordsRecirculatingBallots1" className="ml-2">Yes</label>
                          </div>
                          <div className="flex align-items-center">
                            <RadioButton inputId="newRemoveExistingRecordsRecirculatingBallots2" name="newRemoveExistingRecordsRecirculatingBallots" value="No" onChange={(e: RadioButtonChangeEvent) => setnewRemoveExistingRecordsRecirculatingBallots(e.value)} checked={newRemoveExistingRecordsRecirculatingBallots === 'No'} />
                            <label htmlFor="newRemoveExistingRecordsRecirculatingBallots2" className="ml-2">No</label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-3 xl:col-3 lg:col-4 md:col-6 col-12">
                      <fieldset className="border-none p-0">
                        <legend className="block font-bold text-input-label p-0 mb-3">
                          {t("configuration.newRemoveExistingCommitteeRecirculatingBallots")}
                        </legend>
                        <div className="flex flex-wrap gap-3">
                          <div className="flex align-items-center">
                            <RadioButton inputId="newRemoveExistingCommitteeRecirculatingBallots1" name="newRemoveExistingCommitteeRecirculatingBallots" value="Yes" onChange={(e: RadioButtonChangeEvent) => setnewRemoveExistingCommitteeRecirculatingBallots(e.value)} checked={newRemoveExistingCommitteeRecirculatingBallots === 'Yes'} />
                            <label htmlFor="newRemoveExistingCommitteeRecirculatingBallots1" className="ml-2">Yes</label>
                          </div>
                          <div className="flex align-items-center">
                            <RadioButton inputId="newRemoveExistingCommitteeRecirculatingBallots2" name="newRemoveExistingCommitteeRecirculatingBallots" value="No" onChange={(e: RadioButtonChangeEvent) => setnewRemoveExistingCommitteeRecirculatingBallots(e.value)} checked={newRemoveExistingCommitteeRecirculatingBallots === 'No'} />
                            <label htmlFor="newRemoveExistingCommitteeRecirculatingBallots2" className="ml-2">No</label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
              <Button
                label={t("configuration.cancel")}
                className="button-md"
                severity="secondary"
                type="button"
                onClick={() => navigate("/configuration/")}
              />
              <Button
                label={t("configuration.save")}
                className="button-md"
                type="button"
                onClick={() => navigate("/configuration/")}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BallotConfigurationTab;

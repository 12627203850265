import { useRef, useState } from "react";
import { Menu, Menu as PrimeMenu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export interface CustomMenuProps {
  actionItems: MenuItem[];
  rowData: any;
  disabled?: boolean;
}

const CustomMenu: React.FC<CustomMenuProps> = ({
  actionItems,
  rowData,
  disabled,
}) => {
  const menu = useRef<PrimeMenu>(null);
  const location = useLocation();
  const [t, i18n] = useTranslation("committee");
  const toggleMenu = (event: any, rowData?: any) => {
    if (
      rowData.isMembershipExpiring == true ||
      rowData.IsMembershipExpiring == true ||
      rowData.sessionKey == "committeeMembershipId"
    ) {
      sessionStorage.setItem("reappointment", "true");
    }
    const Reappointment = sessionStorage.getItem("reappointment");
    if (menu.current) {
      menu.current.toggle(event);
    }
    if (Reappointment) {
      sessionStorage.setItem("committeeId", rowData.committeeID);
      sessionStorage.setItem(
        "reappointmentUserProfileId",
        rowData.userProfileID
      );
    } else {
      sessionStorage.setItem("committeeId", rowData.Id);
    }
  };

  return (
    <>
      <Menu model={actionItems} popup ref={menu} />
      <Button
        text
        className="text-body gap-1"
        aria-haspopup="true"
        aria-label={t("committee.action")}
        onClick={(e) => toggleMenu(e, rowData)}
        disabled={disabled}
      >
        <FeatherIcon name="settings" size={20} />
        <span className="font-normal">{t("committee.action")}</span>
      </Button>
    </>
  );
};

export default CustomMenu;

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Table from "../common/Table";
import { useLanguageContext } from "../LanguageContext";
import PreviewItem from "../common/PreviewItem";
import { fetchData } from "../../services/Ballot/apiservice";
import { BallotCommitteeReports, BallotReport } from "./types/BallotReport";
import withLoader from "../common/LoaderIntercepter";

// Define props for BallotTabReport
interface BallotTabReportProps {
  setActiveIndex: (index: number) => void; // Function to switch tabs
}

const BallotTabReport: React.FC<BallotTabReportProps> = ({
  setActiveIndex,
}) => {
  const { id } = useParams();
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const [ballotReport, setBallotReport] = useState<BallotReport | null>(null);
  const [recordLists, setRecordList] = useState<any | any>([]);
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const getBallotDetailsHandler = async () => {
    try {
      const ballotDetailsData = await fetchData(
        `BallotRecordVote/BallotReports/${id}`
      );
      setBallotReport(ballotDetailsData);
    } catch (err) {
      console.error("Error fetching ballot details", err);
    }
  };

  useEffect(() => {
    const fetchBallotRecord = async () => {
      try {
        const responseData = await fetchData(
          `BallotRecord/GetListByParentId?parentId=${id}`
        );
        const sortedData = responseData.collection.map((item: any) => ({
          recordNumber: item.record.recordNumber,
          committeeName: item.record.committee.name,
          statusName: item.record.recordStatus?.statusName,
        }));
        setRecordList(sortedData);
      } catch (error) {
        console.error("Error fetching Category of Interest:", error);
      }
    };
    if (id) {
      fetchBallotRecord();
    }
  }, [id]);

  useEffect(() => {
    getBallotDetailsHandler();
  }, [id]);
  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-7">
              <div className="flex flex-column gap-5">
                <h2 className="text-title text-lg font-bold text-capitalize m-0">
                  {t("ballot.votingCriteria")}
                </h2>
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("ballot.approvedRecord")}
                    value={`${t("ballot.moreThan")} 60%`}
                  />
                  <span>Or</span>
                  <PreviewItem
                    label={t("ballot.disapporvedRecord")}
                    value={`${t("ballot.lessThan")} 10%`}
                  />
                </div>
              </div>
              <div className="flex flex-column gap-5">
                <h2 className="text-title text-lg font-bold text-capitalize m-0">
                  {t("ballot.currentVoteCountByCommitteeName")}
                </h2>
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("ballot.totalMemberInvited")}
                    value={`${ballotReport?.TotalMemberInvited}`}
                  />
                  <PreviewItem
                    label={t("ballot.votesRecevied")}
                    value={`${ballotReport?.VoteRecived}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.recordStatus")}
            </h2>
          </div>
          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={recordLists}
              columns={[
                {
                  field: "recordNumber",
                  header: t("ballot.recordId"),
                  link: "/ballot/",
                },
                { field: "committeeName", header: t("ballot.committeeName") },
                { field: "statusName", header: t("ballot.recordStatus") },
              ]}
            />
          </div>
        </div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.committeeForBalloting")}
            </h2>
          </div>
          {ballotReport?.ballotCommitteeReports &&
            ballotReport.ballotCommitteeReports
              .filter(
                (ballotreportData: BallotCommitteeReports) =>
                  !ballotreportData.ForReviewAndComment
              )
              .map(
                (ballotreportData: BallotCommitteeReports, index: number) => {
                  return (
                    <div
                      className="p-5 cardBody flex flex-column gap-7"
                      key={index}
                    >
                      <div className="">
                        <div className="flex flex-wrap align-items-center justify-content-between gap-4  mb-4">
                          <h3 className="text-title text-lg text-capitalize m-0">
                            <span className="font-bold mr-2">
                              {" "}
                              {ballotreportData.CommitteeName}
                            </span>
                            <span className="font-normal">
                              ({t("ballot.primaryResponsibleCommittee")})
                            </span>
                          </h3>
                          <h4 className="text-lg font-bold m-0">
                            {ballotreportData.VoteRecivedByCommittee}/
                            {ballotreportData.TotalMemberInvitedByCommittee}{" "}
                            {t("ballot.memberVoted")}
                          </h4>
                        </div>
                        <Table
                          products={
                            ballotreportData?.BallotCommitteeDetailReportList
                          }
                          loading={false}
                          columns={[
                            {
                              field: "RecordNumber",
                              header: t("ballot.recordId"),
                              link: "/ballot/",
                            },
                            {
                              field: "ApplicantName",
                              header: t("ballot.applicantName"),
                            },
                            {
                              field: "Cirteriameet",
                              header: t("ballot.criteriaMet"),
                              body: (rowData: any) =>
                                rowData.Cirteriameet ?? "-",
                            },
                            {
                              field: "CommitteeName",
                              header: t("ballot.committeeAppliedFor"),
                            },
                            {
                              field: "AppliedPosition",
                              header: t("ballot.positionAppliedFor"),
                            },
                            {
                              field: "Term",
                              header: t("ballot.termYear"),
                              body: (rowData: any) => rowData.Term ?? "0",
                            },
                            {
                              field: "Approved",
                              header: t("ballot.approved"),
                              body: (rowData: any) => rowData.Approved ?? "-",
                            },
                            {
                              field: "DisApproved",
                              header: t("ballot.disapproved"),
                              body: (rowData: any) =>
                                rowData.DisApproved ?? "-",
                            },
                            {
                              field: "Abstained",
                              header: t("ballot.abstained"),
                              body: (rowData: any) => rowData.Abstained ?? "-",
                            },
                            {
                              field: "NotReturn",
                              header: t("ballot.notReturn"),
                              body: (rowData: any) => rowData.NotReturn ?? "-",
                            },
                            {
                              field: "No_Comment",
                              header: t("ballot.commentsResponded"),
                              body: (rowData: any) => rowData.No_Comment ?? "-",
                            },
                          ]}
                        />
                        <Button
                          text
                          className="p-button-plain underline"
                          onClick={() => setActiveIndex(3)}
                          label={t("ballot.viewComments")}
                        ></Button>
                      </div>
                    </div>
                  );
                }
              )}
        </div>
        {ballotReport?.ballotCommitteeReports
          .filter(
            (ballotreportData: BallotCommitteeReports) =>
              ballotreportData.ForReviewAndComment
          )
          .map((ballotreportData: BallotCommitteeReports, index: number) => {
            return (
              <div className="card bg-white w-full mb-5 shadow-md" key={index}>
                <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
                  <h2 className="text-title text-lg font-bold text-capitalize m-0">
                    {t("ballot.committeeForReviewAndComment")}
                  </h2>
                </div>
                <div className="p-5 cardBody flex flex-column gap-7">
                  <div className="">
                    <div className="flex flex-wrap align-items-center justify-content-between gap-4  mb-4">
                      <h3 className="text-title text-lg text-capitalize m-0">
                        <span className="font-bold mr-2">
                          {t("ballot.committeeName")}
                        </span>
                        <span className="font-normal">
                          ({t("ballot.primaryResponsibleCommittee")})
                        </span>
                      </h3>
                      <h4 className="text-lg font-bold m-0">
                        16/18 {t("ballot.memberVoted")}
                      </h4>
                    </div>
                    <Table
                      products={
                        ballotreportData?.BallotCommitteeDetailReportList
                      }
                      loading={false}
                      columns={[
                        {
                          field: "recordId",
                          header: t("ballot.recordId"),
                          link: "/ballot/",
                        },
                        {
                          field: "applicantName",
                          header: t("ballot.applicantName"),
                        },
                        {
                          field: "criteriaMet",
                          header: t("ballot.criteriaMet"),
                        },
                        {
                          field: "committeeAppliedFor",
                          header: t("ballot.committeeAppliedFor"),
                        },
                        {
                          field: "positionAppliedFor",
                          header: t("ballot.positionAppliedFor"),
                        },
                        { field: "termYear", header: t("ballot.termYear") },
                        { field: "approved", header: t("ballot.approved") },
                        {
                          field: "disapproved",
                          header: t("ballot.disapproved"),
                        },
                        { field: "abstained", header: t("ballot.abstained") },
                        { field: "notReturn", header: t("ballot.notReturn") },
                        {
                          field: "commentsResponded",
                          header: t("ballot.commentsResponded"),
                        },
                      ]}
                    />
                    <Button
                      text
                      className="p-button-plain underline"
                      onClick={() => setActiveIndex(3)}
                      label={t("ballot.viewComments")}
                    ></Button>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default withLoader(BallotTabReport);

import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IDENTITY_URL } from "../../config";
import { getTenantNameFromDomain } from "../../utils/utils";

const EmailVerified = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("t");
  const handleGoToLogin = () => {
    navigate("/");
  };

  useEffect(() => {
    let modifiedToken = token?.replaceAll(" ", "+");
    if (modifiedToken) {
      let verifyToken = {
        token: modifiedToken,
        TenantDomain: getTenantNameFromDomain(),
      };

      const EmailVerifyRequest = fetch(
        `${IDENTITY_URL}/User/UserRegistrationTokenVerify`,
        {
          body: JSON.stringify(verifyToken),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
  }, []);

  return (
    <>
      <div className="flex align-items-center justify-content-center align-self-center h-full">
        <div className="surface-card p-fluid px-8 shadow-2 border-round w-full loginCards">
          <div className="p-8">
            <div className="mb-5">
              <h1 className="text-title display-sm font-bold m-0 mb-3">
                Email Verified!
              </h1>
              <p className="m-0 mb-2">
                Congratulations your email has been verified successfully. In
                order to complete your Membership request you have to complete
                your Profile.
              </p>
            </div>
            <div className="button-container">
              <button
                className="p-button p-component p-button-primary mr-3"
                onClick={handleGoToLogin}
              >
                Please Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerified;

import React, { useContext, useEffect, useRef, useState } from "react";
import FeatherIcon from "./common/FeatherIconComponent";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import { usePermission } from "./auth/Authorization";
import { Sidebar } from "primereact/sidebar";
import { useTheme } from "../ThemeContext";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "./LanguageContext";

const SidebarApp = () => {
  const [t, i18n] = useTranslation("global");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();
  const [sideBarItems, setSideBarItems] = useState<any>([]);
  const [isAdminSidebar, setIsAdminSidebar] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");

  const [expandedKeys, setExpandedKeys] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [focusedItem, setFocusedItem] = useState<string | null>(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (isSidebarOpen) {
      setExpandedKeys({});
    }
  };

  // Listen for resize events to determine if it's mobile view
  window.addEventListener("resize", () => {
    setIsMobileView(window.innerWidth < 768);
  });

  const [visibleLeft, setVisibleLeft] = useState<boolean>(false);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
      const memberItems = [
        {
          label: t("global.dashboard"),
          icon: "pi pi-desktop",
          url: "/dashboard",
        },
        {
          label: t("global.myMemberships"),
          icon: "pi pi-id-card",
          url: "/all-membership",
        },
        {
          label: t("global.myCommittees"),
          icon: "pi pi-users",
          url: "/committee",
        },
        {
          label: t("global.myRequests"),
          icon: "pi pi-history",
          items: [
            {
              label: t("global.membershipRequest"),
              url: "/member",
            },
            {
              label: t("global.standardRequest"),
              url: "/request/standard",
            },
            {
              label: t("global.administrativeRequest"),
              url: "/request/administrative",
            },
          ],
        },
        {
          label: t("global.myBallots"),
          icon: "pi pi-list",
          items: [
            {
              label: t("global.membershipBallots"),
              url: "/ballot/membership",
            },
            {
              label: t("global.standardBallots"),
              url: "/ballot/standard",
            },
            {
              label: t("global.administrativeBallots"),
              url: "/ballot/administrative",
            },
            {
              label: t("global.nonStandardDocumentsBallots"),
              url: "/ballot/non-standard-documents",
            },
          ],
        },
        {
          label: t("global.openPositions"),
          icon: "pi pi-user-plus",
          url: "/positions",
        },
      ];

      const staffItems = [
        {
          label: t("global.dashboard"),
          icon: "pi pi-desktop",
          url: "/dashboard",
        },
        {
          label: t("global.memberships"),
          icon: "pi pi-id-card",
          url: "/all-membership",
        },
        {
          label: t("global.requests"),
          icon: "pi pi-history",
          items: [
            {
              label: t("global.membershipRequest"),
              url: "/member",
            },
            {
              label: t("global.standardRequest"),
              url: "/request/standard",
            },
            {
              label: t("global.administrativeRequest"),
              url: "/request/administrative",
            },
          ],
        },
        {
          label: t("global.records"),
          icon: "pi pi-database",
          items: [
            {
              label: t("global.membershipRecord"),
              url: "/record",
            },
            {
              label: t("global.standardRecord"),
              url: "/record/standard",
            },
            {
              label: t("global.administrativeRecord"),
              url: "/record/administrative",
            },
          ],
        },
        {
          label: t("global.committees"),
          icon: "pi pi-users",
          url: "/committee",
        },
        {
          label: t("global.standards"),
          icon: "pi pi-check-circle",
          url: "/standard",
        },
        {
          label: t("global.openPositions"),
          icon: "pi pi-user-plus",
          url: "/positions",
        },
        {
          label: t("global.volunteers"),
          icon: "pi pi-id-card",
          url: "/volunteers",
        },
        {
          label: t("global.ballots"),
          icon: "pi pi-list",
          items: [
            {
              label: t("global.membershipBallots"),
              url: "/ballot/membership",
            },
            {
              label: t("global.standardBallots"),
              url: "/ballot/standard",
            },
            {
              label: t("global.administrativeBallots"),
              url: "/ballot/administrative",
            },
            {
              label: t("global.nonStandardDocumentsBallots"),
              url: "/ballot/non-standard-documents",
            },
          ],
        },
        {
          label: t("global.reports"),
          icon: "pi pi-book",
          items: [
            {
              label: t("global.biReport"),
              url: "/report/bi-report",
            },
            {
              label: t("global.membershipRequest"),
              url: "/report/request-report",
            },
            {
              label: t("global.pendingReappointments"),
              url: "/report/pending-reappointments",
            },
            {
              label: t("global.membershipExpiration"),
              url: "/report/member-expiration",
            },
            {
              label: t("global.committeeReport"),
              url: "/report/committee-report",
            },
            {
              label: t("global.recordStatus"),
              url: "/report/record-status",
            },
            {
              label: t("global.balanceOfInterest"),
              url: "/report/balance-interest",
            },
          ],
        },
      ];

      const configItems = [
        {
          label: t("global.configuration"),
          icon: "pi pi-cog",
          url: "/configuration",
        },
        {
          label: t("global.settings"),
          icon: "pi pi-cog",
          func: () => setVisibleLeft(true),
        },
      ];

      const adminItems = [...staffItems, ...configItems];

      const getSidebarItems = (roleName: string) => {
        switch (roleName.toLowerCase()) {
          case "member":
            return memberItems;
          case "staff":
            return staffItems;
          default:
            return adminItems;
        }
      };

      setSideBarItems(getSidebarItems(roleName));

      setIsAdminSidebar(roleName.toLowerCase() === "admin");
    }
  }, [userPermissions, isSidebarOpen, expandedKeys]);

  const CustomMenu = ({ items }: { items: any[] }) => {
    const navigate = useNavigate();

    const menuRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

    useEffect(() => {
      if (focusedItem && menuRefs.current[focusedItem]) {
        menuRefs.current[focusedItem]?.focus();
      }
    }, [expandedKeys, focusedItem]);

    const handleToggle = (index: string) => {
      setIsSidebarOpen(true);
      setExpandedKeys((prevKeys: any) => ({
        ...prevKeys,
        [index]: !prevKeys[index],
      }));
    };

    const handleFocus = (index: string) => {
      setFocusedItem(index);
    };

    const handleItemAction = (
      item: any,
      currentIndex: string,
      e: React.KeyboardEvent | React.MouseEvent
    ) => {
      e.preventDefault();
      if (item.url) navigate(item.url);
      if (item.func) item.func(); // Call the function if it exists
      if (item.items && item.items.length > 0) {
        handleToggle(currentIndex);
        handleFocus(currentIndex);
      } else {
        setIsSidebarOpen(false);
        setExpandedKeys({});
        handleFocus(currentIndex);
      }
    };

    const renderItems = (items: any[], parentIndex = "") => {
      return items.map((item, idx) => {
        const currentIndex =
          parentIndex === "" ? `${idx}` : `${parentIndex}_${idx}`;
        const isExpanded = !!expandedKeys[currentIndex];
        const hasChildren = item.items && item.items.length > 0;

        // Function to normalize paths
        const normalizePath = (path: string) =>
          path ? path.replace(/^\/|\/$/g, "") : "";

        const currentPath = normalizePath(window.location.pathname);

        // Recursive function to determine if any child item is active
        const hasActiveDescendant = (item: { url: string; items: any[] }) => {
          if (item.url && normalizePath(item.url) === currentPath) {
            return true;
          }
          if (
            item.items &&
            item.items.some((child) => hasActiveDescendant(child))
          ) {
            return true;
          }
          return false;
        };

        const isActive = hasActiveDescendant(item);

        return (
          <div key={currentIndex}>
            <div
              ref={(el) => (menuRefs.current[currentIndex] = el)}
              role="button"
              tabIndex={0}
              aria-expanded={isExpanded}
              aria-haspopup={hasChildren ? "true" : "false"}
              aria-label={item.label}
              className={`flex align-items-center menu-item depth-${currentIndex.split("_").length - 1} ${isExpanded ? "expanded" : ""} ${isActive ? "active" : ""}`}
              title={item.label}
              onClick={(e) => handleItemAction(item, currentIndex, e)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleItemAction(item, currentIndex, e);
                }
              }}
            >
              <span className={`${item.icon} menu-icon`} />
              <span className="menu-label">{item.label}</span>
              {hasChildren && (
                <i
                  className={`ml-auto menu-arrow pi ${isExpanded ? "pi-chevron-down" : "pi-chevron-right"}`}
                ></i>
              )}
            </div>
            {hasChildren && isExpanded && (
              <div className="menu-subitems">
                {renderItems(item.items, currentIndex)}
              </div>
            )}
          </div>
        );
      });
    };

    return <nav>{renderItems(items)}</nav>;
  };

  // Set theme
  const { theme, setTheme } = useTheme();

  // Ensure the default theme name matches in both index.html and ThemeContext.tsx.
  // This ensures consistency and prevents issues if the theme in local storage
  // does not match any theme in the provided themes array or if no theme is found in local storage.
  const themes = [
    {
      name: "stanza-blue",
      title: "Stanza Blue",
      brandColor: "#1459a3",
      first: "#1459a3",
      bodyBgColor: "#f6f6f6",
    },
    {
      name: "stanza-red",
      title: "Stanza Red",
      brandColor: "#EA1B3D",
      first: "#212128",
      bodyBgColor: "#fff9ed",
    },
    {
      name: "stanza-lia",
      title: "Stanza Gray",
      brandColor: "#EA1B3D",
      first: "#4C4F54",
      bodyBgColor: "#fff9ed",
    },
  ];

  return (
    <>
      <aside
        className={`sidebar bg-sidebar 0 flex-shrink-0 h-full z-3 md:fixed 
          ${isSidebarOpen ? "w-17rem sidebarOpened" : "w-4rem sidebarClosed"} 
          ${isMobileView ? "max-w-0" : ""}
          ${isAdminSidebar ? "settings" : ""}
          ${isMobileView && isSidebarOpen ? "max-w-17rem fixed" : "w-4rem"}`}
      >
        <div>
          <div className="text-right">
            <Button
              text
              className={`side-icon-btn justify-content-center ${
                isMobileView
                  ? "hamburger-ion-color mobileMenuToggle p-3 ml-3"
                  : "text-gray-300 p-2 py-3"
              }
              ${isSidebarOpen ? "w-auto" : "w-full"}
              ${isMobileView && isSidebarOpen ? "text-gray-300" : ""}`}
              onClick={toggleSidebar}
              aria-label={isSidebarOpen ? "Close Menu" : "Open Menu"}
              aria-expanded={isSidebarOpen ? "true" : "false"}
            >
              <FeatherIcon
                name={isSidebarOpen ? "x" : "menu"}
                size={20}
                color="inherit"
              />
            </Button>
          </div>

          <div
            className={`sidebar-menu
            ${isMobileView ? "hidden" : ""}
            ${isMobileView && isSidebarOpen ? "block" : ""}`}
          >
            <CustomMenu items={sideBarItems} />
          </div>
        </div>
      </aside>

      <Sidebar
        visible={visibleLeft}
        position="left"
        onHide={() => setVisibleLeft(false)}
      >
        <h2 className="text-title text-lg font-bold ">
          {t("global.changeTheme")}
        </h2>
        <ul className="list-none p-0 m-0 flex flex-column gap-3">
          {themes.map((themeOption) => (
            <li
              key={themeOption.name}
              className="flex align-items-center gap-3"
            >
              <Button
                text
                onClick={() => setTheme(themeOption.name)}
                className="w-full"
              >
                <div
                  className="flex flex-row w-6rem"
                  style={{
                    border: "1px solid #ddd",
                    borderColor: themeOption.brandColor,
                  }}
                >
                  <span
                    style={{
                      backgroundColor: themeOption.brandColor,
                      width: "32px",
                      height: "32px",
                    }}
                  ></span>
                  <span
                    style={{
                      backgroundColor: themeOption.first,
                      width: "32px",
                      height: "32px",
                    }}
                  ></span>
                  <span
                    style={{
                      backgroundColor: themeOption.bodyBgColor,
                      width: "32px",
                      height: "32px",
                    }}
                  ></span>
                </div>
                <span style={{ marginLeft: "10px", color: "#252525" }}>
                  {themeOption.title}
                </span>
                {theme === themeOption.name && (
                  <i
                    className="pi pi-check ml-auto"
                    style={{ color: "#252525", fontSize: "20px" }}
                  ></i>
                )}
              </Button>
            </li>
          ))}
        </ul>
      </Sidebar>
    </>
  );
};

export default SidebarApp;

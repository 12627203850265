import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel"; // Import OverlayPanel
import { useTranslation } from "react-i18next";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { useLanguageContext } from "../LanguageContext";
import Table from "../common/Table";
import FeatherIcon from "../common/FeatherIconComponent";
interface Option {
  name: string;
  code: string;
}

interface StandardTableProps {
  handleNewRequestClick: () => void;
}

const StandardTable: React.FC<StandardTableProps> = ({ handleNewRequestClick }) => {
  const [t, i18n] = useTranslation("request");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const navigate = useNavigate();

  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  return (
    <>
      <div className="flex flex-wrap md:flex-nowrap md:justify-content-between align-items-center py-3 gap-3 w-full">
        <IconField iconPosition="left" className="w-full md:w-16rem">
          <InputIcon className="pi pi-search"> </InputIcon>
          <InputText
            id="searchMemberOrPosition"
            placeholder={t("request.searchMemberOrPosition")}
            className="w-full"
            aria-label={t("request.searchMemberOrPosition")}
          />
        </IconField>
        <div className="flex flex-wrap md:flex-nowrap gap-3 w-full md:w-auto align-items-center">
          <div className="flex gap-3 w-full md:w-auto align-items-center">
            <div className="relative">
              <Button
                className="button-md gap-1"
                severity="secondary"
                onClick={toggleFilterPanel}
                aria-label={t("request.filter")}
              >
                <FeatherIcon name="filter" size={20} color="inherit" />
                <span className="font-bold text-capitalize hidden md:flex">
                  {t("request.filter")}
                </span>
              </Button>
              {/* OverlayPanel for Filter */}
              <OverlayPanel
                ref={overlayPanelRef}
                onHide={() => setShowFilterPanel(false)}
                appendTo={"self"}
                dismissable
                className="p-overlaypanel-filter left-auto md:right-0 top-100"
                aria-labelledby="filter-heading"
                role="dialog"
              >
                <div className="flex flex-column gap-1" tabIndex={0}>
                  <p id="filter-heading" className="text-lg font-bold m-0 p-3">
                    {t("request.filter")}
                  </p>
                  <div className="filter-wrap">
                    {/* Add filter fields here */}
                  </div>
                  <Button
                    className="button-md w-full justify-content-center"
                  >
                    {t("request.applyFilter")}
                  </Button>
                </div>
              </OverlayPanel>
            </div>
            <Button
              className="button-md gap-1 justify-content-center w-full md:w-auto"
              onClick={handleNewRequestClick}
            >
              <FeatherIcon name="plus" size={20} color="inherit" />
              <span className="font-bold text-capitalize">
                {t("request.newRequest")}
              </span>
            </Button>
          </div>
        </div>
      </div>
      <Table
        loading={false}
        products={[]}
        columns={[
          { field: "request", header: t("request.request") },
          { field: "memberName", header: t("request.memberName") },
          { field: "committeeApplied", header: t("request.committeeApplied") },
          { field: "positionApplied", header: t("request.positionApplied") },
          {
            field: "categoryOfInterest",
            header: t("request.categoryOfInterest"),
          },
          { field: "requestDate", header: t("request.requestDate") },
          { field: "status", header: t("request.status") },
          { field: "action", header: t("request.action"), sortable: false },
        ]}
        // actionItems={actionItems}
      />
    </>
  );
};

export default StandardTable;

import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import { Badge } from "primereact/badge";
import DetailTab from "./DetailTab";
import RecordAuditTab from "./RecordAuditTab";
import ProjectTeamMembersTab from "./ProjectTeamMembersTab";
import ContributingCommitteesTab from "./ContributingCommitteesTab";
import RelatedRecordsTab from "./RelatedRecordsTab";
import BallotHistoryTab from "./BallotHistoryTab";
import CommentHistoryTab from "./CommentHistoryTab";

const StandardRecordDetailTabs: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  return (
    <>
      <div>
        <Button
          text
          className="p-button-plain gap-2 mb-3 pl-0 underline"
          onClick={() => navigate("/record/standard")}
        >
          <FeatherIcon name="arrow-left" size={20} />
          <span>{t("record.back")}</span>
        </Button>
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title flex-wrap display-xs font-bold text-capitalize m-0 flex align-items-center gap-2">
            {t("record.recordId")}
            <span>- C22-78357</span>
            <Badge
              value="Active"
              className="active"
            />
          </h1>
          <p className="text-base font-normal m-0">
            Any supporting text for request listing page can go here
          </p>
        </div>

        <div className="tabs-wrap">
          <TabView
            scrollable
          >
            <TabPanel header={t("record.details")}>
              <DetailTab />
            </TabPanel>
            <TabPanel header={t("record.recordAudit")}>
              <RecordAuditTab />
            </TabPanel>
            <TabPanel header={t("record.projectTeamMembers")}>
              <ProjectTeamMembersTab />
            </TabPanel>
            <TabPanel header={t("record.contributingCommittees")}>
              <ContributingCommitteesTab />
            </TabPanel>
            <TabPanel header={t("record.relatedRecords")}>
              <RelatedRecordsTab />
            </TabPanel>
            <TabPanel header={t("record.ballotHistory")}>
              <BallotHistoryTab />
            </TabPanel>
            <TabPanel header={t("record.commentHistory")}>
              <CommentHistoryTab />
            </TabPanel>
          </TabView>
        </div>

      </div >
    </>
  );
};

export default StandardRecordDetailTabs;

import React, { useEffect, useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import {
  putData,
  fetchData,
  getListByParentId,
} from "../../services/apiService";
import { Navigate, useNavigate } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
//import { useHistory } from "react-router-dom";

interface MemberConfigurationTabViewProps {
  setActiveIndex: (index: number) => void;
  onEditButtonClick: () => void;
}

const MemberConfigurationTabView: React.FC<MemberConfigurationTabViewProps> = ({
  setActiveIndex,
  onEditButtonClick,
}) => {
  const [t, i18n] = useTranslation("configuration");
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [confidentialChecked, setConfidentialChecked] = useState(true);
  const [committeeData, setCommitteeData] = useState<CommitteeDataItem[]>([]);
  const [committeeCOIData, setCommitteeCOIData] = useState<CommitteeCOIItem[]>(
    []
  );
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  interface CommitteeDataItem {
    Id: string;
    //id: string;
    IsDirty: true;
    IsNew: true;
    IsDefaultParent: boolean;
    IsNatureofCommitteeRestricted: boolean;
    CreatedDate: string;
    IsBalanceBasedOnOverAllThreshold: boolean;
    MinOverallThreshold: number;
    MaxOverallThreshold: number;
    ThresholdPercentage: number;
    IsBalanceBasedOnCategoryofInterests: boolean;
    IsBalanceBasedOnRepresentationByOrg: boolean;
    OrganisationMinMember: number;
    OrganisationMaxMember: number;
    OrganisationMemberThreshold: number;
    IsShowCommitteemembers: boolean;
    CommitteeMinMember: number;
    CommitteeMaxMember: number;
    IsCriteriaOfmembershipEligibility: boolean;
    MSElig_SubTierCommitte_Months: number;
    MSElig_SameCommitte_Officer_Months: number;
    IsTenure_ExpirationsRules: boolean;
    MSExpire_Officer_Tenure_Years: number;
    MSExpire_Member_Tenure_Years: number;
    IsShowAllMembershipExpires: boolean;
    SendNotificationforMembershipExpiration: boolean;
    NotifyStaffExpirationofMembership: boolean;
    NotifyStaffExpirationofMembershipDays_First: number;
    NotifyStaffExpirationofMembershipDays_Second: number;
    NotifyStaffExpirationofMembershipDays_Third: number;
    NotifyMemberExpirationofMembership: boolean;
    NotifyMemberExpirationofMembershipDays_First: number;
    NotifyMemberExpirationofMembershipDays_Second: number;
    NotifyMemberExpirationofMembershipDays_Third: number;
    OfficerMaximumTermYears: number;
    ApplicationAutoWithdrawalMonths: number;
    NotifyMemberLackofParticipation: boolean;
    NumberofMeetingMissed: number;
    NumberofBallotsMissed: number;
    TotalMeetingMisssedPercentage: number;
    TotalBallotMisssedPercentage: number;
    AcceptApplicationWithoutOpenPositions: boolean;
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  interface CommitteeCOIItem {
    id: string;
    IsDirty: true;
    IsNew: true;
    MinMember: number;
    MaxMember: number;
    Threshold: number;
  }

  const fetchCommitteeConfig = async () => {
    try {
      const responseData = await fetchData("CommitteeConfiguration/GetAll");
      const committeeCollection = responseData?.Collection || [];
      setCommitteeData(committeeCollection);

      if (committeeCollection.length > 0) {
        const CommitteeConfigId = committeeCollection[0].Id;
        const responseCOIData = await getListByParentId(
          "CategoryofInterestConfiguration",
          CommitteeConfigId
        );
        setCommitteeCOIData(responseCOIData?.Collection || []);
      } else {
        console.log("No committee data found.");
      }
    } catch (error) {
      console.error("Error fetching committee configuration:", error);
    }
  };

  useEffect(() => {
    fetchCommitteeConfig();
  }, []);

  const handleCheckboxChange = (e: any) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };

  const handleConfidentialCheckboxChange = (e: any) => {
    if (e.confidentialChecked !== undefined) {
      setConfidentialChecked(e.confidentialChecked);
    }
  };

  const handleButtonClick = () => {
    setActiveIndex(2);
  };

  const handleEditButtonClick = () => {
    onEditButtonClick();
  };

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex align-items-center">
              <Checkbox
                inputId="termsconditions"
                onChange={handleCheckboxChange}
                checked={checked}
                className="mr-2"
                disabled={false}
              />
              <label
                htmlFor="termsconditions"
                className="text-base font-normal"
              >
                <h2 className="text-title text-lg font-bold text-capitalize m-0">
                  {t("configuration.termsAndConditions")}
                </h2>
              </label>
            </div>
            {checked && (
              <div className="ml-auto">
                <Button
                  text
                  className="p-button-plain gap-2 underline"
                  onClick={handleEditButtonClick}
                  //onClick={onEditButtonClick}
                >
                  <FeatherIcon name="edit-2" size={20} />
                  <span>{t("configuration.edit")}</span>
                </Button>
              </div>
            )}
          </div>
          {checked && (
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                <p className="m-0 font-normal text-base">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat. Duis aute irure dolor in reprehenderit
                  in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                  in culpa qui officia deserunt mollit anim id est laborum.
                </p>
                <p className="m-0 font-normal text-base">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat. Duis aute irure dolor in reprehenderit
                  in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt{" "}
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex align-items-center">
              <Checkbox
                inputId="confidentialityAgreement"
                onChange={handleConfidentialCheckboxChange}
                checked={confidentialChecked}
                className="mr-2"
                disabled={false}
              />
              <label
                htmlFor="confidentialityAgreement"
                className="text-base font-normal"
              >
                <h2 className="text-title text-lg font-bold text-capitalize m-0">
                  {t("configuration.confidentialityAgreement")}
                </h2>
              </label>
            </div>
            {confidentialChecked && (
              <div className="ml-auto">
                <Button
                  text
                  className="p-button-plain gap-2 underline"
                  onClick={handleEditButtonClick}
                  //onClick={onEditButtonClick}
                >
                  <FeatherIcon name="edit-2" size={20} />
                  <span>{t("configuration.edit")}</span>
                </Button>
              </div>
            )}
          </div>
          {confidentialChecked && (
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                <p className="m-0 font-normal text-base">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat. Duis aute irure dolor in reprehenderit
                  in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                  in culpa qui officia deserunt mollit anim id est laborum.
                </p>
                <p className="m-0 font-normal text-base">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat. Duis aute irure dolor in reprehenderit
                  in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt{" "}
                </p>
              </div>
            </div>
          )}
        </div>
        {/* <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("configuration.committeeConfiguration")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
              onClick={handleEditButtonClick}
              //onClick={onEditButtonClick}
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("configuration.edit")}</span>
            </Button>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                  {t("configuration.defaultConfigurationFromParentCommittee")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {committeeData[0]?.IsDefaultParent ? "Yes" : "No"}
                </p>
              </div>
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                  {t("configuration.natureOfCommittee")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {committeeData[0]?.IsNatureofCommitteeRestricted
                    ? "Yes"
                    : "No"}
                </p>
              </div>
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                  {t(
                    "configuration.defaultApplicationConfigurationFromParentCommittee"
                  )}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {committeeData[0]?.AcceptApplicationWithoutOpenPositions
                    ? "Yes"
                    : "No"}
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default MemberConfigurationTabView;
function setActiveIndex(arg0: number) {
  throw new Error("Function not implemented.");
}

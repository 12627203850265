import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import Table from "../../common/Table";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";

const ContributingCommitteesTab: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  // Action menu
  const menuAction = useRef<Menu>(null);
  const toggleActionMenu = (event: any) => {
    if (menuAction.current) {
      menuAction.current.toggle(event);
    }
  };
  const showActionItem: MenuItem[] = [
    {
      label: "Option 1",
    },
    {
      label: "Option 2",
    },
  ];

  return (
    <>
      <div className="mb-5">
        <div className="flex mb-4 ml-auto">
          <Button
            className="button-md gap-1 ml-auto"
            severity="secondary"
            onClick={toggleActionMenu}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                toggleActionMenu(e);
              }
            }}
          >
            <span className="font-bold">{t("record.action")}</span>
            <FeatherIcon name="chevron-down" size={20} color="inherit" />
          </Button>
          <Menu
            model={showActionItem}
            popup
            ref={menuAction}
            id="profileMenu"
            popupAlignment="right"
            role="listbox"
          />
        </div>
        <Table
          loading={false}
          products={[]}
          columns={[
            { field: "committeeId", header: t("record.committeeId") },
            { field: "committeeName", header: t("record.committeeName") },
            { field: "createdDate", header: t("record.createdDate") },
          ]}
        />
      </div>

    </>
  );
};

export default ContributingCommitteesTab;

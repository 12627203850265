import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import './LoadingOverlay.scss'; // Example CSS file for styling the loading overlay

interface LoadingOverlayProps {
  visible: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ visible }) => {
  return (<>
    {visible && <div className={`loading-overlay visible`}>
      <div className="overlay-content">
        <ProgressSpinner />
      </div>
    </div>}
  </>
  );
};

export default LoadingOverlay;

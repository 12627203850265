import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    allFormPermissions: [],
    allRoleFormPermissions: []
};

const addUserRolePermissionsSlice = createSlice({
    name: "UserRolePermissions",
    initialState,
    reducers: {
        addAllFormPermissions: (
            state,
            action: PayloadAction<any>,
        ) => {
            state.allFormPermissions = action.payload;
        },
        addRoleFormPermissions: (
            state,
            action: PayloadAction<any>,
        ) => {
            state.allRoleFormPermissions = action.payload;
        },
    },
});

export const {
    addAllFormPermissions,
    addRoleFormPermissions,
} = addUserRolePermissionsSlice.actions;
export default addUserRolePermissionsSlice.reducer;
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FeatherIcon from "../common/FeatherIconComponent";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { RootState } from "../../store/store";
import {
  ConfirmDialog,
  ConfirmDialogProps,
  confirmDialog,
} from "primereact/confirmdialog";
import Input from "../../utils/Input";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import {
  deleteData,
  fetchData,
  getListByParentId,
  postData,
  putData,
} from "../../services/apiService";
import "./Profile.scss";
import { InputText } from "primereact/inputtext";
import { createUserProfileRequest } from "../../slices/createProfileSlice";
import { focusOnErrorField } from "../../utils/focusError";
import { focusOnField } from "../../utils/focusField";
interface PhoneNumber {
  isNew: boolean;
  isActive: true;
  isDeleted: false;
  isDirty?: boolean;
  type: string;
  dialingCode: string;
  phoneNumber: string;
  isPreferredContact: boolean;
  userProfileId: string;
  userPhoneId: string;
}

interface FormData {
  gender: string;
  prefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  isDirty?: boolean;
  isNew?: boolean;
  userId?: string;
  tenantId?: string;
  remark: string;
  userName: string;
  preferredDisplayName: string;
  preferredPublishingName: string;
}

interface DropdownOption {
  label: string;
  value: string;
}

interface RowData {
  id: number;
  selectedType: string | null;
  selectedExtension: string | null;
  PhoneNumber: string;
  preferredContact: boolean;
}

const AddBasicDetails = () => {
  const { id } = useParams();
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const [data, setData] = useState(null);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const navigate = useNavigate();
  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [userProfileId, setUserProfileId] = useState("");
  const [userId, setUserId] = useState("");
  const [readOnlyEmail, setReadOnlyEmail] = useState(false);
  const createProfileDetails = useSelector(
    (state: RootState) => state.createProfile
  );

  const profileID = localStorage.getItem("userProfileID") || "";

  const genderOptions: DropdownOption[] = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ];

  const prefixOptions: DropdownOption[] = [
    { label: "Mr.", value: "Mr" },
    { label: "Mrs.", value: "Mrs" },
    { label: "Ms.", value: "Ms" },
  ];
  const typeOptions: DropdownOption[] = [
    { label: "Home", value: "home" },
    { label: "Work", value: "work" },
  ];
  const extensionOptions: DropdownOption[] = [
    { label: "+1", value: "+1" },
    { label: "+91", value: "+91" },
  ];
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [checked, setChecked] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber[]>([
    {
      isNew: true,
      isActive: true,
      isDeleted: false,
      isDirty: true,
      type: "",
      dialingCode: "",
      phoneNumber: "",
      isPreferredContact: true,
      userProfileId: profileID,
      userPhoneId: profileID,
    },
  ]);
  const [formData, setFormData] = useState<FormData>({
    gender: "",
    prefix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    remark: "",
    userName: "",
    preferredDisplayName: "",
    preferredPublishingName: "",
  });
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const handleSaveButtonClick = () => {
    setSaveButtonClicked(true);
  };

  useEffect(() => {
    if (
      createProfileDetails &&
      createProfileDetails.createProfile &&
      createProfileDetails.createProfile.UserProfileId
    ) {
      setUserProfileId(createProfileDetails.createProfile.UserProfileId);
    } else {
      // Retrieve data from session storage if not available in Redux
      const experienceIdFromSessionStorage =
        sessionStorage.getItem("detailsID");
      setUserProfileId(experienceIdFromSessionStorage || userProfileId);
    }
  }, [createProfileDetails]);

  useEffect(() => {
    const detailsID = sessionStorage.getItem("detailsID");
    if (detailsID) {
      setReadOnlyEmail(true);
    }
  }, []);

  const hideDialog = () => {
    setVisible(false);
  };

  const onConfirm = () => {
    // Handle confirmation logic here

    // Hide the dialog regardless of success or failure
    hideDialog();
  };

  const onCancel = () => {
    hideDialog();
  };

  const confirmDialogProps: ConfirmDialogProps = {
    header: t("profile.confirmation"),
    message: t("profile.areYouSureYouWantToProceed"),
    visible: visible,
    onHide: hideDialog,
    accept: onConfirm,
    reject: onCancel,
  };

  const addNewRow = () => {
    setPhoneNumber([
      ...phoneNumber,
      {
        isNew: true,
        isActive: true,
        isDeleted: false,
        isDirty: true,
        type: "",
        dialingCode: "",
        phoneNumber: "",
        isPreferredContact: false,
        userProfileId: profileID,
        userPhoneId: profileID,
      },
    ]);

    // To focus on newly added row first element "phoneType"
    setTimeout(() => {
      focusOnField(`phoneType-${phoneNumber.length}`);
    }, 0); // Delay of 0 milliseconds
  };

  const handleInputChange = (name: string, value: string) => {
    // Update form data state
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);

    // Update form errors state
    const updatedFormErrors = { ...formErrors };
    if (!value) {
      updatedFormErrors[name] = `${name} ${t("profile.isRequired")}`;
    } else {
      delete updatedFormErrors[name];
    }
    setFormErrors(updatedFormErrors);
  };

  const handlePhoneTypeChange = (index: number, value: string) => {
    const updatedPhoneNumbers = [...phoneNumber];
    updatedPhoneNumbers[index].type = value;

    // Remove the validation error for phone type
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors[`Type-${index}`];

    setPhoneNumber(updatedPhoneNumbers);
    setFormErrors(updatedFormErrors); // Update formErrors state
  };

  const handlePhoneInputChange = (index: number, value: string) => {
    const updatedPhoneNumbers = [...phoneNumber];
    updatedPhoneNumbers[index].phoneNumber = value;

    // Remove the validation error for phone number
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors[`phoneNumber-${index}`];

    setPhoneNumber(updatedPhoneNumbers);
    setFormErrors(updatedFormErrors); // Update formErrors state
  };

  const handlePhoneExtensionChange = (index: number, value: string) => {
    const updatedPhoneNumbers = [...phoneNumber];
    updatedPhoneNumbers[index].dialingCode = value;

    // Remove the validation error for phone type
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors[`dialingCode-${index}`];

    setPhoneNumber(updatedPhoneNumbers);
    setFormErrors(updatedFormErrors);
  };

  const handleGenderChange = (value: string) => {
    setFormData({ ...formData, gender: value });
  };

  const handlePrefixChange = (value: string) => {
    // Update form data state
    setFormData({ ...formData, prefix: value });

    // Update form errors state
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors["prefix"];
    setFormErrors(updatedFormErrors);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTimeout(() => {
      focusOnErrorField(".p-invalid");
    }, 100);
    if (!deleteButtonClicked && saveButtonClicked) {
      const errors: Record<string, string> = {};
      if (!formData.gender) {
        errors.gender = t("profile.gender") + " " + t("profile.isRequired");
      }
      if (!formData.prefix) {
        errors.prefix = t("profile.prefix") + " " + t("profile.isRequired");
      }
      if (!formData.firstName) {
        errors.firstName =
          t("profile.firstname") + " " + t("profile.isRequired");
      }
      if (!formData.lastName) {
        errors.lastName = t("profile.lastname") + " " + t("profile.isRequired");
      }

      if (!formData.preferredDisplayName) {
        errors.preferredDisplayName =
          t("profile.preferredDisplayName") + " " + t("profile.isRequired");
      }

      if (!formData.preferredPublishingName) {
        errors.preferredPublishingName =
          t("profile.preferredPublishingName") + " " + t("profile.isRequired");
      }

      if (!formData.email) {
        errors.email = t("profile.email") + " " + t("profile.isRequired");
      } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
          errors.email = t("profile.invalidEmailFormat");
        }
      }

      phoneNumber.forEach((phone, index) => {
        if (!phone.type) {
          errors[`Type-${index}`] =
            t("profile.type") + " " + t("profile.isRequired");
        }
        if (!phone.dialingCode) {
          errors[`dialingCode-${index}`] =
            t("profile.dialingCode") + " " + t("profile.isRequired");
        }
        const phoneNumber = phone.phoneNumber.trim();
        const phoneNumberRegex = /^[0-9]+$/;

        if (!phoneNumber) {
          errors[`phoneNumber-${index}`] =
            t("profile.phonenumber") + " " + t("profile.isRequired");
        } else if (!phoneNumberRegex.test(phoneNumber)) {
          errors[`phoneNumber-${index}`] = t(
            "profile.invalidFormatForPhoneNumber"
          );
        }
      });

      if (Object.keys(errors).length === 0) {
        handlePostData(formData, phoneNumber);
      } else {
        setFormErrors(errors);
      }
    }
  };

  const handleCheckboxChange = (index: number, value: boolean) => {
    const updatedPhoneNumbers = phoneNumber.map((phone, idx) => ({
      ...phone,
      isPreferredContact: idx === index ? value : false,
    }));

    setPhoneNumber(updatedPhoneNumbers);

    // setFormData({
    //   ...formData,
    //   userPhones: updatedPhoneNumbers,
    // });
  };

  const handlePhoneNumber = async (formDetails: FormData) => {
    try {
      const postDataResponse = postData("UserPhone/bulkUpload", phoneNumber);
      return postDataResponse;
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  const handlePostData = async (
    formDetails: FormData,
    phoneNumbers: PhoneNumber[]
  ) => {
    try {
      formDetails.userId = userId;
      //formDetails.tenantId = TENANT_ID;
      formDetails.isDirty = true;
      formDetails.isNew = true;
      formDetails.userName = formDetails.email;

      // Handle user profile data
      let userProfileResponse;
      let userPhoneResponse;

      // Make both API calls concurrently
      [userProfileResponse, userPhoneResponse] = await Promise.all([
        postData("UserProfile", formDetails),
        postData("UserPhone/bulkUpload", phoneNumbers),
      ]);

      // Handle responses here if needed
    } catch (error) {
      console.error(`Error posting data:`, error);
      // navigate("/profile/");
    }
  };

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              onClick={() => navigate("/profile/")}
              aria-label={t("profile.back")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("profile.addbasicdetails")}
              </h1>
              <p className="text-base font-normal m-0 text-help">
                {t("profile.allFieldsRequired")}
              </p>
            </div>
          </div>
          <ConfirmDialog className="custom-dialog hide-close" ariaCloseIconLabel="Close" />
          <form onSubmit={handleSubmit}>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-3">
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="gender"
                      className="block font-bold text-input-label capitalize"
                    >
                      {t("profile.gender")} ({t("profile.optional")})
                    </label>
                    <Dropdown
                      inputId="gender"
                      name="gender"
                      value={formData.gender}
                      onChange={(e) => handleGenderChange(e.target.value)}
                      options={genderOptions}
                      optionLabel="label"
                      placeholder={t("profile.selectAnOption")}
                    />
                  </div>
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="prefix"
                      className={`block font-bold text-input-label capitalize ${
                        formErrors?.prefix ? "p-error" : ""
                      }`}
                    >
                      {t("profile.prefix")}
                    </label>
                    <Dropdown
                      inputId="prefix"
                      aria-describedby="prefixError"
                      name="prefix"
                      value={formData.prefix}
                      onChange={(e) => handlePrefixChange(e.target.value)}
                      options={prefixOptions}
                      optionLabel="label"
                      placeholder={t("profile.selectAnOption")}
                      className={formErrors?.prefix ? "p-invalid" : ""}
                      aria-labelledby="prefix"
                    />
                    <span
                      id="prefixError"
                      className={`p-error font-bold text-capitalize ${formErrors.prefix ? "" : "error-hidden"}`}
                    >
                      {formErrors.prefix}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="firstName"
                      className={`block font-bold text-input-label capitalize ${
                        formErrors?.firstName ? "p-error" : ""
                      }`}
                    >
                      {t("profile.firstname")}
                    </label>
                    <InputText
                      id="firstName"
                      aria-describedby="firstNameError"
                      value={formData.firstName}
                      name="firstname"
                      className={`${formErrors?.firstName ? "p-invalid" : ""}`}
                      onChange={(e) =>
                        handleInputChange("firstName", e.target.value)
                      }
                      placeholder={t("profile.firstname")}
                    />
                    <span
                      id="firstNameError"
                      className={`p-error font-bold text-capitalize ${formErrors.firstName ? "" : "error-hidden"}`}
                    >
                      {formErrors.firstName}
                    </span>
                  </div>
                  <div className="xl:col-3 lg:col-4 md:col-6 col-12">
                    <Input
                      type="text"
                      label={`${t("profile.middlename")} (${t(
                        "profile.optional"
                      )})`}
                      value={formData.middleName}
                      name="MiddleName"
                      onChange={(value) =>
                        handleInputChange("middleName", value)
                      }
                      placeholder={t("profile.middlename")}
                    />
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="lastName"
                      className={`block font-bold text-input-label capitalize ${
                        formErrors?.lastName ? "p-error" : ""
                      }`}
                    >
                      {t("profile.lastname")}
                    </label>
                    <InputText
                      id="lastName"
                      aria-describedby="lastNameError"
                      value={formData.lastName}
                      name="LastName"
                      onChange={(e) =>
                        handleInputChange("lastName", e.target.value)
                      }
                      placeholder={t("profile.lastname")}
                      className={`${formErrors?.lastName ? "p-invalid" : ""}`}
                    />
                    <span
                      id="lastNameError"
                      className={`p-error font-bold text-capitalize ${formErrors.lastName ? "" : "error-hidden"}`}
                    >
                      {formErrors.lastName}
                    </span>
                  </div>
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="email"
                      className={`block font-bold text-input-label capitalize ${
                        formErrors?.email ? "p-error" : ""
                      }`}
                    >
                      {t("profile.email")}
                    </label>
                    <InputText
                      id="email"
                      aria-describedby="emailError"
                      value={formData.email}
                      name="email"
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                      placeholder={t("profile.email")}
                      className={`${formErrors?.email ? "p-invalid" : ""}`}
                    />
                    <span
                      id="emailError"
                      className={`p-error font-bold text-capitalize ${formErrors.email ? "" : "error-hidden"}`}
                    >
                      {formErrors.email}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="displayName"
                      className={`block font-bold text-input-label capitalize ${
                        formErrors?.preferredDisplayName ? "p-error" : ""
                      }`}
                    >
                      {t("profile.preferredDisplayName")}
                    </label>
                    <InputText
                      id="displayName"
                      aria-describedby="displayNameError"
                      value={formData.preferredDisplayName}
                      name="displayName"
                      onChange={(e) =>
                        handleInputChange("preferredName", e.target.value)
                      }
                      placeholder={t("profile.preferredDisplayName")}
                      className={`${formErrors?.preferredDisplayName ? "p-invalid" : ""}`}
                    />
                    <span
                      id="displayNameError"
                      className={`p-error font-bold text-capitalize ${formErrors.preferredDisplayName ? "" : "error-hidden"}`}
                    >
                      {formErrors.preferredDisplayName}
                    </span>
                  </div>

                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="publishingName"
                      className={`block font-bold text-input-label capitalize ${
                        formErrors?.preferredPublishingName ? "p-error" : ""
                      }`}
                    >
                      {t("profile.preferredPublishingName")}
                    </label>
                    <InputText
                      id="publishingName"
                      aria-describedby="displayNameError"
                      value={formData.preferredPublishingName}
                      name="publishingName"
                      onChange={(e) =>
                        handleInputChange("publishingName", e.target.value)
                      }
                      placeholder={t("profile.preferredPublishingName")}
                      className={`${formErrors?.preferredPublishingName ? "p-invalid" : ""}`}
                    />
                    <span
                      id="displayNameError"
                      className={`p-error font-bold text-capitalize ${formErrors.preferredPublishingName ? "" : "error-hidden"}`}
                    >
                      {formErrors.preferredPublishingName}
                    </span>
                  </div>
                </div>

                <div className="flex flex-column gap-5">
                  <div className="flex flex-column gap-2">
                    <div className="grid m-0 w-full gap-3 add-details-grid add-details-grid-lables">
                      <span className="font-bold" id="phoneType">
                        {t("profile.type")}*
                      </span>
                      <span className="font-bold" id="dialingCode">
                        {t("profile.dialingCode")}
                      </span>
                      <span className="font-bold" id="phonenumber">
                        {t("profile.phonenumber")}
                      </span>
                    </div>
                    <div className="flex flex-column gap-3 w-full">
                      {phoneNumber.length > 0 &&
                        phoneNumber.map((phone, index) => (
                          <div
                            className={`grid m-0 w-full gap-3 add-details-grid idx-0 align-items-baseline idx-${index}`}
                            key={index}
                          >
                            <div
                              className="flex flex-column gap-2 data-label"
                              data-label={t("profile.type")}
                            >
                              {/* <label 
                             htmlFor={`phoneType-${index}`}
                             className={`block font-bold text-input-label capitalize ${formErrors[`Type-${index}`] ? "p-error" : ""
                               }`}
                            >
                              {t("profile.type")}
                            </label> */}
                              <Dropdown
                                inputId={`phoneType-${index}`}
                                aria-describedby={`phoneTypeError-${index}`}
                                value={phone.type}
                                options={typeOptions}
                                optionLabel="label"
                                onChange={(e) =>
                                  handlePhoneTypeChange(index, e.target.value)
                                }
                                placeholder={t("profile.type")}
                                className={`w-full ${formErrors[`Type-${index}`] ? "p-invalid" : ""}`}
                                aria-labelledby="phoneType"
                              />
                              <span
                                id={`phoneTypeError-${index}`}
                                className={`p-error font-bold text-capitalize ${formErrors[`Type-${index}`] ? "" : "error-hidden"}`}
                              >
                                {formErrors[`Type-${index}`]}
                              </span>
                            </div>
                            <div
                              className="flex flex-column gap-2 data-label"
                              data-label={t("profile.dialingCode")}
                            >
                              {/* <label 
                             htmlFor={`dialingCode-${index}`}
                             className={`block font-bold text-input-label capitalize ${formErrors[`dialingCode-${index}`] ? "p-error" : ""
                               }`}
                            >
                              {t("profile.dialingCode")}
                            </label> */}
                              <Dropdown
                                inputId={`dialingCode-${index}`}
                                aria-describedby={`dialingCodeError-${index}`}
                                value={phone.dialingCode}
                                options={extensionOptions}
                                optionLabel="label"
                                onChange={(e) =>
                                  handlePhoneExtensionChange(
                                    index,
                                    e.target.value
                                  )
                                }
                                placeholder={t("profile.selectAnOption")}
                                aria-labelledby="dialingCode"
                                className={`w-full ${formErrors[`dialingCode-${index}`] ? "p-invalid" : ""}`}
                              />
                              <span
                                id={`dialingCodeError-${index}`}
                                className={`p-error font-bold text-capitalize ${formErrors[`dialingCode-${index}`] ? "" : "error-hidden"}`}
                              >
                                {formErrors[`dialingCode-${index}`]}
                              </span>
                            </div>
                            <div
                              className="flex flex-column gap-2 data-label"
                              data-label={t("profile.phonenumber")}
                            >
                              {/* <label 
                             htmlFor={`phoneNumber-${index}`}
                             className={`block font-bold text-input-label capitalize ${formErrors[`phoneNumber-${index}`] ? "p-error" : ""
                               }`}
                            >
                              {t("profile.phonenumber")}
                            </label> */}
                              <InputText
                                id={`phonenumber-${index}`}
                                aria-describedby={`phonenumberError-${index}`}
                                type="text"
                                value={phone.phoneNumber}
                                name={`phonenumber-${index}`}
                                onChange={(e) =>
                                  handlePhoneInputChange(index, e.target.value)
                                }
                                placeholder={t("profile.phonenumber")}
                                aria-labelledby="phonenumber"
                                className={`${formErrors[`phoneNumber-${index}`] ? "p-invalid" : ""}`}
                              />
                              <span
                                id={`phonenumberError-${index}`}
                                className={`p-error font-bold text-capitalize ${formErrors[`phoneNumber-${index}`] ? "" : "error-hidden"}`}
                              >
                                {formErrors[`phoneNumber-${index}`]}
                              </span>
                            </div>
                            <div className="flex align-items-center">
                              <Checkbox
                                inputId={`preferredContact-${index}`}
                                checked={phone.isPreferredContact}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    index,
                                    (e.target as HTMLInputElement).checked
                                  )
                                }
                                className="mr-2"
                              />
                              <label
                                htmlFor={`preferredContact-${index}`}
                                className="text-base"
                              >
                                {t("profile.checkboxPhoneNumber")}
                              </label>
                            </div>
                            {/* <div className="flex justify-content-end">
                            <Button
                              text
                              className="p-button-plain gap-2 text-delete p-0 underline"
                              //onClick={() => handleDeleteButtonClick(phone.Id)}
                              disabled={phoneNumber.length === 1}
                            >
                              <FeatherIcon name="trash-2" size={20} />
                              <span>
                                {t("profile.delete")}
                              </span>
                            </Button>
                          </div> */}
                          </div>
                        ))}
                    </div>
                  </div>
                  {!id && (
                    <div className="block w-full">
                      <Button
                        text
                        className="p-button-plain gap-2 pl-0 underline"
                        onClick={addNewRow}
                      >
                        <FeatherIcon name="plus" size={20} />
                        <span>{t("profile.addAnotherPhone")}</span>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-4 justify-content-end cardFooter">
              <Button
                label={t("profile.cancel")}
                className="button-md"
                severity="secondary"
                onClick={() => navigate("/profile/")}
              />
              <Button
                label={t("profile.save")}
                className="button-md"
                onClick={handleSaveButtonClick}
              />
            </div>
          </form>
        </div>
      </div>

      <ConfirmDialog {...confirmDialogProps} />
    </>
  );
};

export default AddBasicDetails;

import { Menu } from "primereact/menu";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { JSX } from "react/jsx-runtime";

interface BreadcrumbItem {
  label: string;
  url?: string;
  className?: string;
  onClick?: () => void;
}

interface BreadcrumbProps {
  model: BreadcrumbItem[];
  className?: string;
  separator?: any; // Accept JSX Element as separator
  handleBreadcrumbParentClick?: () => void;
}

const CustomBreadcrumb: React.FC<BreadcrumbProps> = ({ model, className, separator = <i className="pi pi-chevron-right text-xs"></i> }) => {

  const handleItemClick = (item: BreadcrumbItem) => {
    if (item.onClick) {
      item.onClick(); // Call the onClick function if provided
    }
  };

  const renderItems = () => {
    const items: JSX.Element[] = [];

    model.forEach((item, index) => {
      if (index !== 0) {
        items.push(
          <li key={`separator-${index}`} className="p-menuitem-separator">
            {separator}
          </li>,
        );
      }
      items.push(
        <li key={index} className="p-breadcrumb-item" onClick={() => handleItemClick(item)}>
          {item.url ? (
            <Link
            to={item.url}
            className="text-link font-bold"
          >
            {item.label}
          </Link>
          ) : (
            <span className={`text-body font-normal`}>
              {item.label}
            </span>
          )}
        </li>,
      );
    });
    return items;
  };

  // mobile
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  const menuBreadcumb = useRef<Menu>(null);
  const toggleBreadcumb = (event: any) => {
    if (menuBreadcumb.current) {
      menuBreadcumb.current.toggle(event);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderItemsMobile = () => {
    const items: JSX.Element[] = [];

    model.forEach((item, index) => {
      if (index < model.length - 1) {
        items.push(
          <li key={index} className="p-breadcrumb-item" onClick={() => handleItemClick(item)}>
            {item.url ? (
            
              <Link
              to={item.url}
              className="text-link font-bold"
            >
              {item.label}
            </Link>
            ) : (
              <span className={`text-body font-normal`}>
                {item.label}
              </span>
            )}
          </li>,
        );
      }
    });

    const lastItem = model[model.length - 1];

    return (
      <>
        <li className="p-breadcrumb-item cursor-pointer"
          onClick={toggleBreadcumb}
          aria-controls="profileMenu"
          aria-haspopup="true"
          aria-expanded="false"
          tabIndex={0}
          aria-label="Open Profile Menu"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              toggleBreadcumb(e);
            }
          }}
          role="button"
        >
          ...
        </li>

        <Menu model={model.slice(0, model.length - 1)} popup
          ref={menuBreadcumb}
          id="profileMenu"
          role="listbox" />

        <li className="p-menuitem-separator">
          {separator}
        </li>
        <li className="p-breadcrumb-item">
          <span className={`text-body font-normal`}>
            {lastItem?.label}
          </span>
        </li>

      </>
    );
  };

  return (
    <nav
      aria-label="Breadcrumb"
      className={`p-breadcrumb p-component ${className}`}
    >
      <ol className="p-breadcrumb-list list-none flex flex-nowrap m-0 p-0">
        {isMobileView ? renderItemsMobile() : renderItems()}
      </ol>
    </nav>
  );
};

export default CustomBreadcrumb;
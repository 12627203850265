import { ChangeEvent, FC, useState } from "react";
import { InputText } from "primereact/inputtext";
interface InputProps {
  label: string;
  type: "text" | "password" | "email";
  value: string;
  name: string;
  onChange: (value: string) => void;
  placeholder?: string;
  validationRules?: ValidationRules;
  errorMessage?: string;
}

interface ValidationRules {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp;
  errorMessage?: string;
}

const Input: FC<InputProps> = ({
  label,
  type,
  value,
  onChange,
  name,
  placeholder,
  validationRules,
  errorMessage,
}) => {
  const [error, setError] = useState<string | null>(null);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setError(null);
    onChange(value);
  };

  const validateInput = (value: string) => {
    if (validationRules) {
      const { required, minLength, maxLength, pattern, errorMessage } =
        validationRules;

      if (required && value.trim() === "") {
        setError(errorMessage || `${label} is required.`);
        return false;
      }

      if (minLength && value.length < minLength) {
        setError(
          errorMessage || `${label} must be at least ${minLength} characters.`,
        );
        return false;
      }

      if (maxLength && value.length > maxLength) {
        setError(
          errorMessage || `${label} must be at most ${maxLength} characters.`,
        );
        return false;
      }

      if (pattern && !pattern.test(value)) {
        setError(errorMessage || `Invalid ${label}.`);
        return false;
      }
    }

    setError(null); // Clear error if validation passes
    return true;
  };
  return (
    <>
      <div className="flex flex-column gap-2 input-wrapper w-full">
        <label htmlFor={name} className="block font-bold text-input-label capitalize">
          {label}
        </label>
        <InputText
          type={type}
          id={name}
          value={value}
          name={name}
          placeholder={placeholder}
          aria-describedby={`${name}Error`}
          onChange={handleChange}
          className="w-full"
        />
        <span
          id={`${name}Error`}
          className={`p-error font-bold text-capitalize ${errorMessage ? "" : "error-hidden"}`}
        >
          {errorMessage}
        </span>
      </div>
    </>
  );
};

export default Input;

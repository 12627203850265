import React from 'react';
import { Button } from 'primereact/button';

const TooltipButton: React.FC<any> = ({ icon, className, rounded= 'rounded', text= 'text', ariaLabel, tooltip, position = 'bottom' }) => {
  return (
    <Button
      icon={icon}
      className={`p-0 inline tooltip-btn ${className}`}
      aria-label={ariaLabel}
      tooltip={tooltip}
      rounded={rounded}
      text={text}
      tooltipOptions={{ position, event: 'both' }}
    />
  );
};

export default TooltipButton;

import React from "react";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import pdfIcon from "../../assets/icons/pdf.svg";
import CreateRecordHeader from "./createRecordHeader";

const CreateRecordPreview: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  return (
    <>
      <div className="">
        <CreateRecordHeader activeStep={3} />
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.membershipDetails")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                  {t("record.staffSecretary")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  Value here
                </p>
              </div>
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                  {t("record.committeePosition")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  Value here
                </p>
              </div>
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                  {t("record.categoryOfInterest")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  Value here
                </p>
              </div>
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                  {t("record.remark")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  Value here
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.basicDetails")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-2">
              <h3 className="font-bold text-base text-title m-0">
                Mr. Ryan Vetrovs
              </h3>
              <p className="m-0 font-normal">Ryan.vetrovs@gmail.com</p>
              <p className="m-0 font-normal">
                {t("record.work")} - +91 012-345-6789
              </p>
              <p className="m-0 font-normal">
                {t("record.home")} - +91 012-345-6789
              </p>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.address")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-2">
              <h3 className="font-bold text-base text-title m-0">
                5201 S Louise Ave
              </h3>
              <p className="m-0 font-normal">Sioux Falls, SD</p>
              <p className="m-0 font-normal">USA</p>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.experience")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-2">
              <h3 className="font-bold text-base text-title m-0">
                Product Manager (Engineering )
              </h3>
              <p className="m-0 font-normal">O'Reilly and Sons</p>
              <p className="m-0 font-normal">
                Feb 2019 - {t("record.present")}
              </p>
              <p className="m-0 font-normal">www.O'Reilly&Sons.com</p>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.professionalDetails")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <h3 className="font-normal text-base m-0">
                {t("record.licenseDetails")}
              </h3>
              <div className="flex flex-column gap-2">
                <h4 className="font-bold text-base text-title m-0">
                  NHCP672287, 2019
                </h4>
                <p className="m-0 font-normal">Ryan.vetrovs12@gmail.com</p>
                <p className="m-0 font-normal">South Dakota, USA</p>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.documents")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <div className="flex flex-column gap-3">
                <h3 className="m-0 font-bold text-lg">{t("record.resume")}</h3>
                <a className="m-0 flex align-items-center gap-2 underline">
                  <img src={pdfIcon} alt="PDF" />
                  <span className="font-bold text-link">
                    {"Ryan vetros resume"}
                  </span>
                </a>
              </div>
              <div className="flex flex-column gap-3">
                <h3 className="m-0 font-bold text-lg">
                  {t("record.otherDocument")}
                </h3>
                <a className="m-0 flex align-items-center gap-2 underline">
                  <img src={pdfIcon} alt="PDF" />
                  <span className="font-bold text-link">
                    {"Random document B"}
                  </span>
                </a>
                <a className="m-0 flex align-items-center gap-2 underline">
                  <img src={pdfIcon} alt="PDF" />
                  <span className="font-bold text-link">
                    {"Random document B"}
                  </span>
                </a>
              </div>
              <div className="flex flex-column gap-3">
                <h3 className="m-0 font-bold text-lg">
                  {t("record.supportingDocuments")}
                </h3>
                <a className="m-0 flex align-items-center gap-2 underline">
                  <img src={pdfIcon} alt="PDF" />
                  <span className="font-bold text-link">
                    {"Random document B"}
                  </span>
                </a>
                <a className="m-0 flex align-items-center gap-2 underline">
                  <img src={pdfIcon} alt="PDF" />
                  <span className="font-bold text-link">
                    {"Random document B"}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.otherMembershipDetails")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-2">
              <h3 className="font-bold text-base m-0">Membership ID</h3>
              <p className="m-0 font-normal">Membership type</p>
              <p className="m-0 font-normal">{t("record.exp")} - June 2025</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateRecordPreview;

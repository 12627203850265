import React, { useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Input from "../../utils/Input";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  deleteData,
  fetchData,
  getAllCountries,
  getListByParentId,
  postData,
  putData,
} from "../../services/apiService";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { InputText } from "primereact/inputtext";
import { usePermission } from "../auth/Authorization";
import { focusOnErrorField } from "../../utils/focusError";
interface Address {
  AddressType: any;
  IsDefaultAddress: boolean;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  CountryId: string;
  StateId: string;
  City: string;
  Pincode: string;
  stateOther: string;
}

const EditAddress = ({ addressData }: { addressData: any[] }) => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [t, i18n] = useTranslation("profile");
  const [countries, setCountries] = useState<any[]>([]);
  const [stateOptions, setStateOptions] = useState([]);
  const { selectedLanguage } = useLanguageContext();
  const [validationError, setValidationError] = useState<string | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<
    Array<Partial<Address>>
  >([]); //new

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const [addresses, setAddresses] = useState<Address[]>([
    {
      AddressType: "",
      IsDefaultAddress: false,
      AddressLine1: "",
      AddressLine2: "",
      AddressLine3: "",
      CountryId: "",
      StateId: "",
      City: "",
      Pincode: "",
      stateOther: "",
    },
  ]);
  
  const createProfileLabelForValidation = [
    { name: "AddressType", label: t("profile.addressType") },
    { name: "AddressLine1", label: t("profile.address") },
    { name: "CountryId", label: t("profile.country") },
    { name: "StateId", label: t("profile.state") },
    { name: "City", label: t("profile.city") },
    { name: "Pincode", label: t("profile.zipcode") },
  ];
  const createProfileDetails = useSelector(
    (state: RootState) => state.createProfile
  );
  const [addressId, setAddressId] = useState<string>("");
  const [anyDefaultAddress, setAnyDefaultAddress] = useState<boolean>(false);
  const userProfileID = localStorage.getItem("userProfileID") || "";
  //  const addressID = localStorage.getItem("AddressID") || "";

  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  useEffect(() => {
    if (addressData.length === 1) {
      setIsButtonDisabled(true);
    }
  }, [addressData]);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  const confirmDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    confirmDialog({
      header: t("profile.confirmation"),
      message: t("profile.areYouSureYouWantToDelete"),
      icon: "",
      defaultFocus: "accept",
      acceptLabel: t("profile.yes"),
      rejectLabel: t("profile.no"),
      accept: () => handleDeleteAddress(event),
      reject: () => navigate("/profile/"),
    });
  };

  const addressType = [
    { label: "Work", value: "Work" },
    { label: "Home", value: "Home" },
    { label: "Others", value: "Others" },
  ];
  React.useEffect(() => {
    if (
      createProfileDetails &&
      createProfileDetails.configureAddress &&
      createProfileDetails.configureAddress.addressId
    ) {
      setAddressId(createProfileDetails.configureAddress.addressId);
    } else {
      // Retrieve data from session storage if not available in Redux
      const experienceIdFromSessionStorage =
        sessionStorage.getItem("AddressId");
      setAddressId(experienceIdFromSessionStorage || addressId);
    }
  }, [createProfileDetails]);
  React.useEffect(() => {
    getAllCountries()
      .then((data: any) => {
        const dataCollection = data?.Collection;
        const countryOptions = dataCollection.map((country: any) => ({
          value: country.Id,
          label: country.Name,
        }));
        setCountries(countryOptions);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const handleCountryChange = (index: number, value: string) => {
    const newAddresses = [...addresses];

    newAddresses[index]["CountryId"] = value;
    setAddresses(newAddresses);
  };

  const fetchDataByUserId = async (userId: string) => {
    try {
      const responseData = await getListByParentId("UserAddress", userId);
      const addressesData = responseData.Collection;

      // Check if there's any default address
      const hasDefaultAddress = addressesData.some(
        (address: Address) => address.IsDefaultAddress
      );

      // Only set the `anyDefaultAddress` state
      setAnyDefaultAddress(hasDefaultAddress);

      // Optionally update addresses if needed elsewhere
      // setAddresses(addressesData);
    } catch (error) {
      console.error("Failed to fetch addresses:", error);
    }
  };

  useEffect(() => {
    fetchDataByUserId(userProfileID);
  }, [userProfileID]);

  const fetchDataState = async (countryId: any) => {
    try {
      const data = await getListByParentId("State", countryId);
      const dataCollection = data?.Collection;
      const stateOptions = dataCollection.map((state: any) => ({
        value: state.Id,
        label: state.Name,
      }));
      setStateOptions(stateOptions);
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const handleStateChange = (index: number, value: string) => {
    const newAddresses = [...addresses];
    newAddresses[index]["StateId"] = value;
    setAddresses(newAddresses);
  };

  const handleClick = () => {
    setChecked(!checked);
  };

  const handleInputChange = (index: number, key: keyof Address, value: any) => {
    const newAddresses = [...addresses];

    if (key === "IsDefaultAddress" && value) {
      newAddresses.forEach((address, i) => {
        address.IsDefaultAddress = i === index;
      });
      setAnyDefaultAddress(true);
    } else if (key === "IsDefaultAddress" && !value) {
      newAddresses[index][key] = value as never;
      setAnyDefaultAddress(
        newAddresses.some((address) => address.IsDefaultAddress)
      );
    } else {
      newAddresses[index][key] = value as never;
    }

    const newValidationErrors = [...validationErrors];
    newValidationErrors[index] = {
      ...newValidationErrors[index],
      [key]: undefined,
    };
    setValidationErrors(newValidationErrors);
    setAddresses(newAddresses);
  };

  const fetchDataByID = async () => {
    try {
      const responseData = await fetchData("UserAddress", addressId);
      const countryId = responseData.CountryId;
      fetchDataState(countryId);
      setAddresses([responseData]);
    } catch (error) {
      // Handle error
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    fetchDataByID();
  }, [addressId]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setTimeout(() => {
      focusOnErrorField(".p-invalid");
    }, 100);
    const errors: Array<Partial<Address>> = [];

    const requiredFields = [
      "AddressType",
      "AddressLine1",
      "CountryId",
      "StateId",
      "City",
      "Pincode",
    ];
    addresses.forEach((address, index) => {
      const error: Partial<Address> = {};
      requiredFields.forEach((field) => {
        const fieldValue = address[field as keyof Address];
        if (
          !fieldValue ||
          (typeof fieldValue === "string" && fieldValue.trim() === "")
        ) {
          const label =
            createProfileLabelForValidation.find((item) => item.name === field)
              ?.label || field;
          error[field as keyof Address] = `${label} ${t("profile.isRequired")}`;
        }
      });
      errors[index] = error;
    });

    setValidationErrors(errors);

    if (errors.some((error) => Object.keys(error).length !== 0)) {
      return;
    }
    try {
      if (addressId) {
        await putData("UserAddress", addressId, addresses[0]);
        navigate("/profile/");
        fetchDataByID();
      } else {
        console.error(t("profile.noIdFoundParameters"));
      }
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  const handleDeleteAddress = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    try {
      if (addressId) {
        const postDataResponse = deleteData("UserAddress", addressId);
        navigate("/profile/");
      } else {
        console.error(t("profile.noIdFoundParameters"));
      }
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              aria-label={t("profile.back")}
              onClick={() => navigate("/profile")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("profile.editAddressDetails")}
              </h1>
              <p className="text-base font-normal m-0 text-help">
                {t("profile.allFieldsRequired")}
              </p>
            </div>
          </div>
          <ConfirmDialog className="custom-dialog hide-close" ariaCloseIconLabel="Close" />
          <form onSubmit={handleSubmit}>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-3">
                {addresses.map((Address, index) => (
                  <div
                    className={`flex flex-column gap-3 mb-3 ${index > 0 ? "border-top-1 border-gray-200 pt-5" : ""
                      }`}
                    key={index}
                  >
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor={`addressType-${index}`}
                          className={`block font-bold ${validationErrors[index]?.AddressType
                              ? "p-error"
                              : ""
                            }`}
                        >
                          {t("profile.addressType")}
                        </label>
                        <Dropdown
                          inputId={`addressType-${index}`}
                          aria-describedby={`addressTypeError-${index}`}
                          value={Address.AddressType}
                          options={addressType}
                          optionLabel="label"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "AddressType",
                              e.target.value
                            )
                          }
                          placeholder={t("profile.selectAnOption")}
                          // className="w-full"
                          className={
                            validationErrors[index]?.AddressType
                              ? "p-invalid"
                              : ""
                          }
                        />
                        <span
                          id={`addressTypeError-${index}`}
                          className={`p-error font-bold text-capitalize ${validationErrors[index]?.AddressType ? "" : "error-hidden"}`}
                        >
                          {validationErrors[index]?.AddressType}
                        </span>
                      </div>
                      {!anyDefaultAddress || Address.IsDefaultAddress ? (
                        <div className="flex col align-items-center mt-0 md:mt-5">
                          <Checkbox
                            inputId={`IsDefaultAddress-${index}`}
                            checked={Address.IsDefaultAddress}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "IsDefaultAddress",
                                e.checked
                              )
                            }
                          />
                          <label
                            htmlFor={`IsDefaultAddress-${index}`}
                            className="text-sm ml-2"
                          >
                            {t("profile.checkboxAddress")}
                          </label>
                        </div>
                      ) : null}
                    </div>

                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor={`addressLine1-${index}`}
                          className={`block font-bold ${validationErrors[index]?.AddressLine1
                              ? "p-error"
                              : ""
                            }`}
                        >
                          {t("profile.addressLine1")}
                        </label>
                        <InputText
                          id={`addressLine1-${index}`}
                          aria-describedby={`addressLine1Error-${index}`}
                          value={Address.AddressLine1}
                          name="addressLine1"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "AddressLine1",
                              e.target.value
                            )
                          }
                          placeholder={t("profile.placeholderAddress")}
                          className={
                            validationErrors[index]?.AddressType
                              ? "p-invalid"
                              : ""
                          }
                        />
                        <span
                          id={`addressLine1Error-${index}`}
                          className={`p-error font-bold text-capitalize ${validationErrors[index]?.AddressLine1 ? "" : "error-hidden"}`}
                        >
                          {validationErrors[index]?.AddressLine1}
                        </span>
                      </div>
                      <div className="xl:col-3 lg:col-4 md:col-6 col-12">
                        <Input
                          type="text"
                          label={`${t("profile.addressLine2")} (${t(
                            "profile.optional"
                          )})`}
                          value={Address.AddressLine2}
                          name="addressLine2"
                          onChange={(e) =>
                            handleInputChange(index, "AddressLine2", e)
                          }
                          placeholder={t("profile.placeholderAddress")}
                        />
                      </div>
                      <div className="xl:col-3 lg:col-4 md:col-6 col-12">
                        <Input
                          type="text"
                          label={`${t("profile.addressLine3")} (${t(
                            "profile.optional"
                          )})`}
                          value={Address.AddressLine3}
                          name="addressLine3"
                          onChange={(e) =>
                            handleInputChange(index, "AddressLine3", e)
                          }
                          placeholder={t("profile.placeholderAddress")}
                        />
                      </div>
                    </div>
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor={`country-${index}`}
                          className={`block font-bold ${validationErrors[index]?.CountryId ? "p-error" : ""
                            }`}
                        >
                          {t("profile.country")}
                        </label>

                        <Dropdown
                          inputId={`country-${index}`}
                          aria-describedby={`countryError-${index}`}
                          value={Address.CountryId}
                          options={countries}
                          optionLabel="label"
                          onChange={(e) =>
                            handleCountryChange(index, e.target.value)
                          }
                          placeholder={t("profile.selectAnOption")}
                          className={
                            validationErrors[index]?.AddressType
                              ? "p-invalid"
                              : ""
                          }
                        />
                        <span
                          id={`countryError-${index}`}
                          className={`p-error font-bold text-capitalize ${validationErrors[index]?.CountryId ? "" : "error-hidden"}`}
                        >
                          {validationErrors[index]?.CountryId}
                        </span>
                      </div>
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor={`state-${index}`}
                          className={`block font-bold ${validationErrors[index]?.StateId ? "p-error" : ""
                            }`}
                        >
                          {t("profile.state")}
                        </label>

                        <Dropdown
                          inputId={`state-${index}`}
                          aria-describedby={`stateError-${index}`}
                          value={Address.StateId}
                          options={stateOptions}
                          optionLabel="label"
                          onChange={(e) =>
                            handleStateChange(index, e.target.value)
                          }
                          placeholder={t("profile.selectAnOption")}
                          className={
                            validationErrors[index]?.StateId ? "p-invalid" : ""
                          }
                        />
                        <span
                          id={`stateError-${index}`}
                          className={`p-error font-bold text-capitalize ${validationErrors[index]?.StateId ? "" : "error-hidden"}`}
                        >
                          {validationErrors[index]?.StateId}
                        </span>
                      </div>
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor={`city-${index}`}
                          className={`block font-bold ${validationErrors[index]?.City ? "p-error" : ""
                            }`}
                        >
                          {t("profile.city")}
                        </label>
                        <InputText
                          id={`city-${index}`}
                          aria-describedby={`cityError-${index}`}
                          value={Address.City}
                          name="city"
                          onChange={(e) =>
                            handleInputChange(index, "City", e.target.value)
                          }
                          placeholder="Please enter your City"
                          className={
                            validationErrors[index]?.City ? "p-invalid" : ""
                          }
                        />
                        <span
                          id={`cityError-${index}`}
                          className={`p-error font-bold text-capitalize ${validationErrors[index]?.City ? "" : "error-hidden"}`}
                        >
                          {validationErrors[index]?.City}
                        </span>
                      </div>
                    </div>
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor={`zipcode-${index}`}
                          className={`block font-bold ${validationErrors[index]?.Pincode ? "p-error" : ""
                            }`}
                        >
                          {t("profile.zipcode")}
                        </label>
                        <InputText
                          type="text"
                          id={`zipcode-${index}`}
                          aria-describedby={`zipcodeError-${index}`}
                          value={Address.Pincode}
                          name="pincode"
                          onChange={(e) =>
                            handleInputChange(index, "Pincode", e.target.value)
                          }
                          placeholder={`Please enter your ${t("profile.zipcode")}`}
                          className={
                            validationErrors[index]?.Pincode ? "p-invalid" : ""
                          }
                        />
                        <span
                          id={`zipcodeError-${index}`}
                          className={`p-error font-bold text-capitalize ${validationErrors[index]?.Pincode ? "" : "error-hidden"}`}
                        >
                          {validationErrors[index]?.Pincode}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-wrap align-items-center px-5 py-3 border-top-1 border-gray-200 gap-3 md:gap-4 justify-content-between cardFooter">
              <Button
                text
                className="p-button-plain gap-2 px-2 md:pr-3 md:pl-0 text-delete underline"
                onClick={confirmDelete}
                disabled={addressData.length === 1}
                aria-label={t("profile.deleteAddress")}
              >
                <FeatherIcon name="trash-2" size={20} />
                <span className="hidden md:flex">{t("profile.deleteAddress")}</span>
              </Button>
              <Button
                label={t("profile.cancel")}
                className="button-md ml-auto w-auto"
                severity="secondary"
                onClick={() => navigate("/profile")}
              />
              <Button label={t("profile.save")} className="button-md w-auto" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default EditAddress;

import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import Table from "../common/Table";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";

interface ContributingCommitteeTabProps {
  showEditButton?: boolean;
}

const ContributingCommitteeTab: React.FC<ContributingCommitteeTabProps> = ({ showEditButton }) => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("standard.contributingCommittee")}
            </h2>
            {showEditButton && (
              <Button
                text
                className="p-button-plain gap-2 underline"
              >
                <FeatherIcon name="edit-2" size={20} />
                <span>{t("standard.edit")}</span>
              </Button>
            )}
          </div>

          <div className="p-5 cardBody">
            <Table
              loading={false}
              products={[]}
              columns={[
                { field: "committeeName", header: t("standard.committeeName") },
                { field: "primaryStaff", header: t("standard.primaryStaff") },
                { field: "committeeLevel", header: t("standard.committeeLevel") },
                { field: "canVote", header: t("standard.canVote") },
                { field: "mustVote", header: t("standard.mustVote") },
                { field: "canComment", header: t("standard.canComment") },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContributingCommitteeTab;

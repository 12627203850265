import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { fetchData } from "../../services/apiService";
interface PermissionContextType {
  userPermissions: string;
  fetchUserPermissions: () => Promise<void>;
  hasPermission: () => string;
}

// Create context
const PermissionContext = createContext<PermissionContextType>({
  userPermissions: "",
  fetchUserPermissions: async () => {},
  hasPermission: () => "",
});

// Permission provider component
export const PermissionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [userPermissions, setUserPermissions] = useState<string>("");
  useEffect(() => {
    fetchUserPermissions();
  }, []);

  const fetchUserPermissions = async () => {
    try {
      const data = await fetchData("UserProfile/GetCurrentUserProfile");
      // debugger;
      setUserPermissions(data.UserProfileUserType[0].UserType.TypeName);
    } catch (error) {
      console.error("Error fetching user permissions:", error);
    }
  };

  const hasPermission = (): string => {
    return userPermissions ? userPermissions : "";
  };

  return (
    <PermissionContext.Provider
      value={{ userPermissions, fetchUserPermissions, hasPermission }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

// Custom hook to access permission context
export const usePermission = () => {
  return useContext(PermissionContext);
};

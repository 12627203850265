import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../LanguageContext";

const CommentHistoryTab: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  return (
    <>
      <div className="mb-5">

      </div>

    </>
  );
};

export default CommentHistoryTab;

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../LanguageContext";

const BasicBallotDetails = () => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const {
    primaryCommitteSelect,
    ballotTypeSelect,
    ballotLevelSelect,
    recordTypeSelect,
    recordSubTypeSelect,
    positionAppliedSelect,
  } = useSelector(
    (state: any) => state.ballotSelectRecords.selectedRecordValues
  );
  return (
    <>
      <div className="card bg-white w-full mb-5 shadow-md">
        <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
          <div className="flex flex-column gap-1">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.basicBallotDetails")}
            </h2>
          </div>
        </div>

        <div className="p-5 cardBody">
          <div className="flex flex-column gap-4">
            <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
              <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                {t("ballot.ballotType")}
              </span>
              <p className="m-0 font-normal text-base text-title m-0 w-full">
                {ballotTypeSelect?.value}
              </p>
            </div>
            <div className="flex flex-column md:flex-row gap-5">
              <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                {t("ballot.primaryCommitteeResponsible")}
              </span>
              <p className="m-0 font-normal text-base text-title m-0 w-full">
                {primaryCommitteSelect?.value}
              </p>
            </div>
            <div className="flex flex-column md:flex-row gap-5">
              <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                {t("ballot.ballotLevel")}
              </span>
              <p className="m-0 font-normal text-base text-title m-0 w-full">
                {ballotLevelSelect?.value}
              </p>
            </div>
            <div className="flex flex-column md:flex-row gap-5">
              <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                {t("ballot.recordType")}
              </span>
              <p className="m-0 font-normal text-base text-title m-0 w-full">
                {recordTypeSelect?.value}
              </p>
            </div>
            <div className="flex flex-column md:flex-row gap-5">
              <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                {t("ballot.recordSubType")}
              </span>
              <p className="m-0 font-normal text-base text-title m-0 w-full">
                {recordSubTypeSelect?.value}
              </p>
            </div>
            <div className="flex flex-column md:flex-row gap-5">
              <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                {t("ballot.committeePosition")}
              </span>
              <p className="m-0 font-normal text-base text-title m-0 w-full">
                {positionAppliedSelect?.value}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicBallotDetails;

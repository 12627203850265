import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { fetchData, putData } from "../../services/apiService";
import { IDENTITY_URL } from "../../config";
import FeatherIcon from "../common/FeatherIconComponent";
import withLoader from "../common/LoaderIntercepter";
import { getTenantNameFromDomain } from "../../utils/utils";
import { loginRequest } from "../../config";
import { getIsADB2CLoginStatus, getTenantInfo } from "../../utils/storage";
import LoadingOverlay from "../common/LoadingOverlay";

const SignIn: React.FC = () => {
  const [t, i18n] = useTranslation("auth");
  const [loading, setLoading] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [loginComplete, setLoginComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isLog, setisLog] = useState(false);
  const toast = useRef<Toast>(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    TenantDomain: getTenantNameFromDomain(),
  });

  const tenantData = getTenantInfo();
  const isAuthenticated = useIsAuthenticated();
  const isADB2CLoginStatus = getIsADB2CLoginStatus();

  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    if (isADB2CLoginStatus && isAuthenticated && accounts?.length > 0) {
      setLoading(true);
    }
  }, [accounts, inProgress, isAuthenticated, isADB2CLoginStatus]);

  useEffect(() => {
    const storedEmail = localStorage.getItem("rememberedEmail");
    const storedPassword = localStorage.getItem("rememberedPassword");
    const storedRememberMe = localStorage.getItem("rememberMe");
    if (storedEmail) {
      setFormData((prevData) => ({
        ...prevData,
        email: storedEmail,
        password: storedPassword || "",
      }));
    }
    if (storedRememberMe) {
      setChecked(storedRememberMe === "true");
    }
  }, []);

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFormData({
      email: "",
      password: "",
      TenantDomain: getTenantNameFromDomain(),
    });
    setFormErrors({});
  };

  const userProfileIDs = async () => {
    const languages = [
      {
        label: "English",
        value: "en",
      },
      { label: "French", value: "fr" },
    ];
    try {
      const responseData = await fetchData("UserProfile/GetCurrentUserProfile");
      const userProfileID = responseData.Id;
      const lastLoginDate = responseData.LastLoginDate;
      if (
        responseData.LanguageId !== "00000000-0000-0000-0000-000000000000" &&
        responseData.LanguageId
      ) {
        const languageResponse = await fetchData(
          `Language/${responseData.LanguageId}`
        );
        localStorage.setItem(
          "selectedLanguage",
          languageResponse.Symbol.toLowerCase()
        );
      }
      localStorage.setItem("userProfileID", userProfileID);
      localStorage.setItem("lastLoginDate", lastLoginDate);
    } catch (error) {
      console.error(error);
    }
  };
  const [uName, setuName] = useState(formData.email);
  const [uPass, setuPass] = useState(formData.password);
  useEffect(() => {
    const userStore = localStorage.getItem("rememberedEmail");
    const passStore = localStorage.getItem("rememberedPassword");
    const rmeStore = localStorage.getItem("rememberMe");

    if (rmeStore === "true") {
      setisLog(true);
    }
    if (userStore) {
      setuName(userStore);
    }
    if (passStore) {
      setuPass(passStore);
    }
  }, []);

  const handleLogin = async () => {
    const { email, password, TenantDomain } = formData;
    const lognCred = {
      emailAddress: formData.email,
      password: formData.password,
      TenantDomain: formData.TenantDomain,
    };
    if (!email.trim() || !password.trim()) {
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(
        `${IDENTITY_URL}/Account/Authenticate`,
        lognCred
      );
      const token = response.data.token;
      localStorage.setItem("token", token);
      await userProfileIDs();
      const UserId: any = localStorage.getItem("userProfileID");
      const responseData = await fetchData("UserProfile/GetCurrentUserProfile");
      const userProfileID = responseData.Id;

      await putData("UserProfile/UpdateUserLastLoginDate", undefined, {
        "Content-Type": "application/json",
      });

      setLoginComplete(true);
      if (rememberMe) {
        localStorage.setItem("rememberedEmail", uName);
        localStorage.setItem("rememberedPassword", uPass);
        localStorage.setItem("rememberMe", "true");
      } else {
        localStorage.removeItem("rememberedEmail");
        localStorage.removeItem("rememberedPassword");
        localStorage.removeItem("rememberMe");
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data;
        if (
          errorMessage ===
          "Invalid User name and Password. Please check supplied credentials."
        ) {
          setErrorMessage(t("auth.doesntMatch"));
        } else {
          setErrorMessage(`${errorMessage}`);
        }
      } else if (error.response && error.response.status === 400) {
        let message = error.response.data.detail;
        errorMessageToast(message);
      } else {
        console.error("Login Failed", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("rememberedEmail");
    const storedPassword = localStorage.getItem("rememberedPassword");
    const storedRememberMe = localStorage.getItem("rememberMe");
    if (storedEmail) {
      setFormData((prevData) => ({
        ...prevData,
        email: storedEmail,
        password: storedPassword || "",
      }));
    }
    if (storedRememberMe) {
      setRememberMe(storedRememberMe === "true");
    }
  }, []);

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      email: value,
    }));
    if (formErrors.email) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };

  const handlePasswordChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      password: value,
    }));
    if (formErrors.password) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }
  };
  const errorMessageToast = (msg: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors: Record<string, string> = {};
    if (!formData.email || !formData.email.trim()) {
      errors.email = t("auth.email") + " " + t("auth.isRequired");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = t("auth.invalidEmailFormat");
    }

    if (!formData.password || !formData.password.trim()) {
      errors.password = t("auth.password") + " " + t("auth.isRequired");
    }

    if (Object.keys(errors).length === 0) {
    } else {
      setFormErrors(errors);
    }
  };

  const handleCheckboxChange = () => {
    const newState = !rememberMe;
    setRememberMe(newState);
    if (newState) {
      setuName(formData.email);
      setuPass(formData.password);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const lastloginDate = localStorage.getItem("lastLoginDate");
    if (token) {
      let fromPath = sessionStorage.getItem("fromPath");
      if (fromPath) {
        navigate(fromPath);
      } else if (lastloginDate === "undefined" || lastloginDate === "null") {
        navigate("/profile");
      } else if (lastloginDate != "null") {
        navigate("/dashboard");
      } else {
        navigate("/dashboard");
      }
    }
  }, [loginComplete, navigate]);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleB2CLogin = async () => {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      {loading && <LoadingOverlay visible={loading} />}
      {!isADB2CLoginStatus ? (
        <div className="flex align-items-center justify-content-center align-self-center h-full p-3">
          <div className="surface-card p-fluid sm:px-4 md:px-8 shadow-2 border-round w-full loginCards">
            <div
              className={`flex flex-column ${errorMessage ? "gap-2" : "gap-6"} md:p-8 p-5`}
            >
              <div className="flex flex-column gap-1">
                <h1 className="text-title display-sm font-bold m-0">
                  {t("auth.welcomeHeading")}
                </h1>
                <p className="text-help text-base font-normal m-0">
                  {t("auth.sss")}
                </p>
              </div>
              <form onSubmit={handleSubmit} className="flex flex-column">
                {errorMessage && (
                  <p className="error-message p-error px-3 py-2 my-3 text-center">
                    {errorMessage}
                  </p>
                )}
                <div className="flex flex-column gap-6">
                  <div className="flex flex-column gap-4">
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="email"
                        className={`block font-bold ${
                          formErrors.email ? "p-error" : ""
                        }`}
                      >
                        {`${t("auth.email")} *`}
                      </label>
                      <InputText
                        id="email"
                        value={formData.email}
                        name="email"
                        onChange={handleEmailChange}
                        placeholder="Please enter your email"
                        className={formErrors.email ? "p-invalid" : ""}
                      />
                      {formErrors.email && (
                        <span
                          className={`p-error font-bold text-capitalize ${formErrors.email ? "" : "error-hidden"}`}
                        >
                          {formErrors.email}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="password"
                        className={`block font-bold ${
                          formErrors.password ? "p-error" : ""
                        }`}
                      >
                        {`${t("auth.password")} *`}
                      </label>
                      <div className="flex align-content-center relative">
                        <InputText
                          id="password"
                          type={showPassword ? "text" : "password"}
                          value={formData.password}
                          name="password"
                          onChange={handlePasswordChange}
                          placeholder="Enter your password"
                          className={formErrors.password ? "p-invalid" : ""}
                        />
                        <Button
                          text
                          className="p-2 absolute right-0 w-auto h-full"
                          aria-label="Toggle Password Visibility"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <FeatherIcon
                              name="eye-off"
                              size={20}
                              color="var(--gray-600)"
                            />
                          ) : (
                            <FeatherIcon
                              name="eye"
                              size={20}
                              color="var(--gray-600)"
                            />
                          )}
                        </Button>
                      </div>
                      {formErrors.password && (
                        <span className="p-error font-bold block">
                          {formErrors.password}
                        </span>
                      )}
                    </div>
                    <div className="flex align-items-center justify-content-between">
                      <div className="flex align-items-center">
                        <Checkbox
                          inputId="rememberme"
                          onChange={handleCheckboxChange}
                          checked={rememberMe}
                          className="mr-2"
                        />
                        <label htmlFor="rememberme">Remember me</label>
                      </div>
                      <Link
                        to="/forgot-password"
                        className="underline text-sm text-link font-bold"
                      >
                        {t("auth.forgotPassword")}
                      </Link>
                    </div>
                  </div>
                  <div className="flex flex-column gap-4">
                    <div className="flex gap-5">
                      <Button
                        label={`${t("auth.cancelText")}`}
                        className="w-full button-md"
                        severity="secondary"
                        onClick={handleCancel}
                      />
                      <Button
                        type="submit"
                        onClick={() => {
                          setLoading(true);
                          handleLogin();
                        }}
                        label={`${t("auth.logInText")}`}
                        className="w-full button-md"
                      />
                    </div>
                    <div>
                      <span className="font-normal line-height-3">
                        Don't have an account?
                      </span>
                      <Link to="/sign-up" className="ml-2 font-bold text-link">
                        Create an account
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex align-items-center justify-content-center align-self-center h-full p-3">
          <div className="surface-card p-fluid sm:px-4 md:px-8 shadow-2 border-round w-full loginCards">
            <div
              className={`flex flex-column ${errorMessage ? "gap-2" : "gap-6"} md:p-8 p-5`}
            >
              <div className="flex flex-column gap-1">
                <h1 className="text-title display-sm font-bold m-0">
                  {t("auth.welcomeHeading")}
                </h1>
                <p className="text-help text-base font-normal m-0">
                  {t("auth.azureLoginADB2C")}
                </p>
              </div>
              <form onSubmit={handleSubmit} className="flex flex-column">
                {errorMessage && (
                  <p className="error-message p-error px-3 py-2 my-3 text-center">
                    {errorMessage}
                  </p>
                )}
                <div className="flex flex-column gap-6">
                  <div className="flex flex-column gap-4">
                    <div className="flex gap-5">
                      <Button
                        type="submit"
                        onClick={() => {
                          setLoading(true);
                          handleB2CLogin();
                        }}
                        label={`Login Via Azure AD B2C`}
                        className="w-full button-md"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withLoader(SignIn);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import { Calendar } from "primereact/calendar";
import Input from "../../utils/Input";
import { useLanguageContext } from "../LanguageContext";
import {
  deleteData,
  fetchData,
  postData,
  putData,
} from "../../services/apiService";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { usePermission } from "../auth/Authorization";
import { focusOnErrorField } from "../../utils/focusError";
import { focusOnField } from "../../utils/focusField";
interface Experience {
  Designation: string;
  CompanyName: string;
  Department: string;
  StartDate: any;
  EndDate: any;
  WebsiteURL: any;
  jobPosition: string;
}

const EditExperience = () => {
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const navigate = useNavigate();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const [experienceId, setExperienceId] = useState<string>("");
  const userProfileID = sessionStorage.getItem("userProfileID") || "";
  const experienceID = localStorage.getItem("experienceID") || "";
  const [validationError, setValidationError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<
    Array<Partial<Experience>>
  >([]);
  const { id } = useParams<{ id: string }>();
  const createProfileDetails = useSelector(
    (state: RootState) => state.createProfile
  );
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const [readOnly, setReadOnly] = useState(true);
  //const { id } = useParams<{ id: string }>();
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  React.useEffect(() => {
    if (
      createProfileDetails &&
      createProfileDetails.configureExperience &&
      createProfileDetails.configureExperience.experienceId
    ) {
      setExperienceId(createProfileDetails.configureExperience.experienceId);
    } else {
      // Retrieve data from session storage if not available in Redux
      const experienceIdFromSessionStorage =
        sessionStorage.getItem("ExperienceID");
      setExperienceId(experienceIdFromSessionStorage || experienceID);
    }
  }, [createProfileDetails]);

  const [experiences, setExperiences] = useState<Experience[]>([
    {
      Designation: "",
      CompanyName: "",
      Department: "",
      StartDate: null,
      EndDate: null,
      jobPosition: "",
      WebsiteURL: "",
    },
  ]);

  const createProfileLabelForValidation = [
    { name: "Designation", label: t("profile.designation") },
    { name: "CompanyName", label: t("profile.companyName") },
    { name: "Department", label: t("profile.department") },
    { name: "WebsiteURL", label: t("profile.companyWebsite") },
    { name: "StartDate", label: t("profile.startDate") },
    { name: "EndDate", label: t("profile.endDate") },
  ];

  const handleInputChange = (
    index: number,
    key: keyof Experience,
    value: string
  ) => {
    const newExperiences = [...experiences];
    newExperiences[index][key] = value as never;

    const newValidationErrors = [...validationErrors];
    newValidationErrors[index] = {
      ...newValidationErrors[index],
      [key]: undefined,
    };
    setValidationErrors(newValidationErrors);
    setExperiences(newExperiences);
  };

  // For getting all records through parent user id
  const fetchDataByID = async () => {
    try {
      const responseData = await fetchData("UserExperience", experienceId);
      setExperiences([responseData]);
    } catch (error) {
      // Handle error
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    fetchDataByID();
  }, [experienceId]);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  // For updating record

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setTimeout(() => {
      focusOnErrorField(".p-invalid");
    }, 100);
    const errors: Array<Partial<Experience>> = [];
    const requiredFields = ["StartDate", "EndDate", "Department", "WebsiteURL"];

    experiences.forEach((experience, index) => {
      const error: Partial<Experience> = {};

      requiredFields.forEach((field) => {
        const fieldValue = experience[field as keyof Experience];
        if (
          !fieldValue ||
          (typeof fieldValue === "string" && fieldValue.trim() === "")
        ) {
          // Find the corresponding label name from createProfileLabelForValidation
          const label =
            createProfileLabelForValidation.find((item) => item.name === field)
              ?.label || field;
          error[field as keyof Experience] =
            `${label} ${t("profile.isRequired")}`;
        }
      });

      errors[index] = error;
    });

    setValidationErrors(errors);

    if (errors.some((error) => Object.keys(error).length !== 0)) {
      return;
    }

    try {
      if (experienceId) {
        const postDataResponse = putData(
          "UserExperience",
          experienceId,
          experiences[0]
        );
        navigate("/profile/");
        fetchDataByID();
      } else {
        console.error("No id found in parameters.");
      }
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  const confirmDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    confirmDialog({
      header: t("profile.confirmation"),
      message: t("profile.areYouSureYouWantToDelete"),
      icon: "",
      acceptLabel: t("profile.yes"),
      rejectLabel: t("profile.no"),
      defaultFocus: "accept",
      accept: handleDeleteExperience,
      reject: () => console.log("Deletion rejected."),
    });
  };

  // For deleting record
  const handleDeleteExperience = async () => {
    try {
      if (experienceId) {
        const postDataResponse = deleteData("UserExperience", experienceId);
        navigate("/profile/");
      } else {
        console.error("No id found in parameters.");
      }
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  const handleDateChange = (
    index: number,
    key: keyof Experience,
    value: Date
  ) => {
    const updatedExperiences = [...experiences];
    updatedExperiences[index][key] = value as never;

    const newValidationErrors = [...validationErrors];
    newValidationErrors[index] = {
      ...newValidationErrors[index],
      [key]: undefined,
    };
    setValidationErrors(newValidationErrors);

    setExperiences(updatedExperiences);
  };

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              aria-label={t("profile.back")}
              onClick={() => navigate("/profile/")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("profile.editexperiencedetail")}
              </h1>
              <p className="text-base font-normal m-0 text-help">
                {t("profile.allFieldsRequired")}
              </p>
            </div>
          </div>
          <ConfirmDialog className="custom-dialog hide-close" ariaCloseIconLabel="Close" />
          <form onSubmit={handleSubmit}>
            <div className="p-5 cardBody">
              {experiences.map((experience, index) => (
                <div
                  className={`flex flex-column gap-3 mb-3 ${
                    index > 0 ? "border-top-1 border-gray-200 pt-5" : ""
                  }`}
                  key={index}
                >
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label htmlFor="designation" className="block font-bold">
                        {t("profile.jobTitle")}
                      </label>

                      <InputText
                        id="designation"
                        value={experience.Designation}
                        name={`jobTitle_${index}`}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "Designation",
                            e.target.value
                          )
                        }
                        className={readOnly ? "read-only" : ""}
                        placeholder={t("profile.jobTitle")}
                        readOnly={readOnly}
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="website"
                        className={`block font-bold ${
                          validationErrors[index]?.WebsiteURL ? "p-error" : ""
                        }`}
                      >
                        {t("profile.website")}
                      </label>
                      <InputText
                        id="website"
                        aria-describedby="websiteError"
                        value={experience.WebsiteURL}
                        name={`website_${index}`}
                        onChange={(e) =>
                          handleInputChange(index, "WebsiteURL", e.target.value)
                        }
                        placeholder={t("profile.website")}
                        className={
                          validationErrors[index]?.WebsiteURL ? "p-invalid" : ""
                        }
                      />
                      <span
                        id="websiteError"
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.WebsiteURL ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.WebsiteURL}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label htmlFor="CompanyName" className="block font-bold">
                        {t("profile.company")}
                      </label>

                      <InputText
                        id="CompanyName"
                        value={experience.CompanyName}
                        name={`organizationName_${index}`}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "CompanyName",
                            e.target.value
                          )
                        }
                        className={readOnly ? "read-only" : ""}
                        placeholder={t("profile.company")}
                        readOnly={readOnly}
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="department"
                        className={`block font-bold ${
                          validationErrors[index]?.Department ? "p-error" : ""
                        }`}
                      >
                        {t("profile.department")}
                      </label>

                      <InputText
                        id="department"
                        aria-describedby="departmentError"
                        value={experience.Department}
                        name={`department_${index}`}
                        onChange={(e) =>
                          handleInputChange(index, "Department", e.target.value)
                        }
                        placeholder={t("profile.department")}
                        className={
                          validationErrors[index]?.Department ? "p-invalid" : ""
                        }
                      />
                      <span
                        id="departmentError"
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.Department ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.Department}
                      </span>

                      {/* <Input
                        type="text"
                        label={t("profile.department")}
                        value={experience.Department}
                        name={`department_${index}`}
                        onChange={(e) =>
                          handleInputChange(index, "Department", e)
                        }
                        placeholder={t("profile.department")}
                        errorMessage={validationErrors[index]?.Department}
                      /> */}

                      {/* <div className={`p-error font-bold text-capitalize ${validationErrors[index]?.Department ? "" : "error-hidden"}`}>{validationErrors[index]?.Department}</div> */}
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`yearfrom-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.StartDate ? "p-error" : ""
                        }`}
                      >
                        {t("profile.from")}
                      </label>

                      <Calendar
                        appendTo="self"
                        maxDate={
                          experience.EndDate
                            ? new Date(experience.EndDate)
                            : undefined
                        }
                        placeholder={t("profile.startDate")}
                        value={
                          experience.StartDate
                            ? new Date(experience.StartDate)
                            : null
                        }
                        onChange={(e) =>
                          handleDateChange(index, "StartDate", e.value as Date)
                        }
                        // onSelect={() => focusOnField(`yearfrom-${index}`)}
                        inputId={`yearfrom-${index}`}
                        aria-describedby={`yearfromError-${index}`}
                        className={`custom-datepicker ${
                          validationErrors[index]?.StartDate ? "p-invalid" : ""
                        }`}
                      />
                      <span
                        id={`yearfromError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.StartDate ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.StartDate}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`yearTo-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.EndDate ? "p-error" : ""
                        }`}
                      >
                        {" "}
                        {t("profile.to")}
                      </label>

                      <Calendar
                        appendTo="self"
                        minDate={
                          experience.StartDate
                            ? new Date(experience.StartDate)
                            : undefined
                        }
                        placeholder={t("profile.endDate")}
                        value={
                          experience.EndDate
                            ? new Date(experience.EndDate)
                            : null
                        }
                        onChange={(e) =>
                          handleDateChange(index, "EndDate", e.value as Date)
                        }
                        // onSelect={() => focusOnField(`yearTo-${index}`)}
                        inputId={`yearTo-${index}`}
                        aria-describedby={`yearToError-${index}`}
                        className={`custom-datepicker ${
                          validationErrors[index]?.EndDate ? "p-invalid" : ""
                        }`}
                      />
                      <span
                        id={`yearToError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.EndDate ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.EndDate}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex flex-wrap align-items-center px-5 py-3 border-top-1 border-gray-200 gap-3 md:gap-4 justify-content-between cardFooter">
              <Button
                text
                className="p-button-plain gap-2 px-2 md:pr-3 md:pl-0 text-delete underline"
                onClick={confirmDelete}
                aria-label={t("profile.deleteExperience")}
              >
                <FeatherIcon name="trash-2" size={20} />
                <span className="hidden md:flex">
                  {t("profile.deleteExperience")}
                </span>
              </Button>
              <Button
                label={t("profile.cancel")}
                className="button-md ml-auto w-auto"
                severity="secondary"
                onClick={() => navigate("/profile/")}
              />
              <Button label={t("profile.save")} className="button-md w-auto" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditExperience;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../LanguageContext";
import CustomStepWizard from "../../common/CustomStepWizard";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import BasicDetails from "./BasicDetails";
import ContributingCommittee from "./ContributingCommittee";
import FeatherIcon from "../../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import AssignProjectTeam from "./AssignProjectTeam";
import SelectRelatedRecords from "./SelectRelatedRecords";
import Preview from "./Preview";

const CreateStandardRecord: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  const breadcumbItems = [
    { label: t("record.dashboard"), url: "/dashboard" },
    { label: t("record.standardRecords"), url: "/record/standard" },
    { label: t("record.createNewStandardRecords") },
  ];

  const stepItems = [
    {
      icon: "cached",
      label: t("record.basicDetails"),
    },
    {
      icon: "cached",
      label: t("record.selectCommittee"),
    },
    {
      icon: "cached",
      label: t("record.assignProjectTeam"),
    },
    {
      icon: "cached",
      label: t("record.selectRelatedRecords"),
    },
    {
      icon: "cached",
      label: t("record.preview"),
    },
  ];

  const [activeStep, setActiveStep] = useState(0);

  return (
    <>
      {/* Header  */}
      <div className="mb-5">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("record.createNewStandardRecords")}
          </h1>
          <p className="text-base font-normal m-0">
            Any supporting text for request listing page can go here
          </p>
        </div>
        <CustomStepWizard stepItems={stepItems} activeIndex={activeStep} />
      </div>

      {/* Body - steps  */}
      {activeStep === 0 && <BasicDetails />}
      {activeStep === 1 && <ContributingCommittee />}
      {activeStep === 2 && <AssignProjectTeam />}
      {activeStep === 3 && <SelectRelatedRecords />}
      {activeStep === 4 && <Preview />}


      {/* Footer buttons */}
      <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 fixed-footer w-full left-0 shadow">
        <Button
          label={t("record.cancel")}
          className="button-md"
          severity="secondary"
          type="button"
          onClick={() => navigate("/record/standard")}
        />
        <Button
          className="button-md gap-1 ml-auto"
          severity="secondary"
          onClick={() => {
            if (activeStep === 0) {
              navigate("/record/standard");
            } else if (activeStep > 0) {
              setActiveStep(activeStep - 1);
            }
          }}
        >
          <FeatherIcon
            name="chevron-left"
            size={20}
            color="inherit"
          />
          <span className="font-bold text-capitalize">
            {t("record.back")}
          </span>
        </Button>
        {activeStep == 4 ? (
          <Button className="button-md gap-1">
            <span className="font-bold">
              {t("record.createStandardRecord")}
            </span>
          </Button>
        ) : (
          <Button
            className="button-md gap-1"
            type="button"
            onClick={() => {
              if (activeStep < 4)
                setActiveStep(activeStep + 1)
            }}
          >
            <span className="font-bold text-capitalize">
              {t("record.next")}
            </span>
            <FeatherIcon
              name="chevron-right"
              size={20}
              color="inherit"
            />
          </Button>
        )}
      </div>
      {/* Mobile responsive buttions */}
      <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
        {activeStep == 4 ? (
          <Button className="button-md gap-1 w-full justify-content-center">
            <span className="font-bold">
              {t("record.createStandardRecord")}
            </span>
          </Button>
        ) : (
          <Button
            className="button-md gap-1 w-full justify-content-center"
            type="button"
            onClick={() => {
              if (activeStep < 4)
                setActiveStep(activeStep + 1)
            }}
          >
            <span className="font-bold text-capitalize">
              {t("record.next")}
            </span>
            <FeatherIcon
              name="chevron-right"
              size={20}
              color="inherit"
            />
          </Button>
        )}
        <div className="flex align-items-center gap-3 w-full">
          <Button
            className="button-md"
            severity="secondary"
            aria-label={t("ballot.back")}
            onClick={() => {
              if (activeStep === 0) {
                navigate("/record/standard");
              } else if (activeStep > 0) {
                setActiveStep(activeStep - 1);
              }
            }}
          >
            <FeatherIcon
              name="chevron-left"
              size={20}
              color="inherit"
            />
          </Button>
          <Button
            label={t("record.cancel")}
            className="button-md w-full"
            severity="secondary"
            type="button"
            onClick={() => navigate("/record/standard")}
          />

        </div>
      </div>

    </>
  );
};

export default CreateStandardRecord;

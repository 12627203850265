import React, { useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import MemberRequestSteps from "./MemberRequestSteps";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
interface Option {
  name: string;
  code: string;
}
interface OutletWrapperProps {
  selectedLanguage: string;
}
const MemberRequestCommitteeSelection: React.FC = () => {
  const [t, i18n] = useTranslation("member");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  

  const breadcumbItems = [
    { label: t("member.dashboard"), url: "/dashboard" }, { label: t("member.membershipRequest") }];

  const [checked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };

  const navigate = useNavigate();
  return (
    <>
      <div className="">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />

        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("member.membershipRequest")}
          </h1>
          <p className="text-base font-normal m-0">
           {t("member.membershipRequestSubText")}
          </p>
        </div>
        {/* 
        <MemberRequestSteps /> */}

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <p className="text-link text-base font-bold m-0">
                {t("member.thankYouMsgConfirm")}
              </p>
              <div className="flex flex-column gap-4">
                <div className="flex flex-column gap-3">
                  <h2 className="text-title text-lg font-bold m-0">
                    Committee 2
                  </h2>
                  <p className="m-0 text-base font-normal">
                    Lorem ipsum dolor sit amet consectetur. Etiam faucibus
                    tristique phasellus hendrerit. Arcu tellus penatibus porta
                    bibendum. Duis dolor suspendisse risus eu. Nibh turpis nunc
                    purus pharetra aliquam nascetur ...
                  </p>
                </div>
                <div className="flex flex-column md:flex-row gap-5">
                  <div className="flex flex-column gap-1">
                    <h3 className="m-0 font-bold text-base">
                      {t("member.committeeManager")}
                    </h3>
                    <p className="text-base text-title font-normal m-0">
                      Ross Geller
                    </p>
                  </div>
                  <div className="flex flex-column gap-1">
                    <h3 className="m-0 font-bold text-base">
                      {t("member.chairPerson")}
                    </h3>
                    <p className="text-base text-title font-normal m-0">
                      Ross Geller
                    </p>
                  </div>
                </div>
                <div className="flex flex-column gap-2">
                  <h3 className="m-0 font-bold text-base">
                    {t("member.standardManaged")}
                  </h3>
                  <ul className="flex flex-column gap-3 pl-3 m-0 list-marker">
                    <li className="text-base text-link font-bold m-0">
                      <a className="underline">
                      Standard 1
                      </a>
                    </li>
                    <li className="text-base text-link font-bold m-0">
                      <a className="underline">
                      Standard 1
                      </a>
                    </li>
                    <li className="text-base text-link font-bold m-0">
                      <a className="underline">
                      Standard 1
                      </a>
                    </li>
                    <li className="text-base text-link font-bold m-0">
                      <a className="underline">
                      Standard 1
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("member.cancel")}
            className="button-md w-full sm:w-auto"
            severity="secondary"
          />
          <Button
            label={t("member.confirmCommitteeContinue")}
            className="button-md w-full sm:w-auto"
            onClick={() => navigate("/member/details")}
          />
        </div>

        {/* Mobile responsive buttions */}
        <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("member.confirmCommitteeContinue")}
            className="button-md w-full"
            onClick={() => navigate("/member/details")}
          />
          <Button
            label={t("member.cancel")}
            className="button-md w-full"
            severity="secondary"
          />
          
        </div>

      </div>
    </>
  );
};

export default MemberRequestCommitteeSelection;

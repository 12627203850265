import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { CSVLink } from "react-csv";
import FeatherIcon from "../common/FeatherIconComponent";
import { fetchData } from "../../services/apiService";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import Table from "../common/Table";
import { usePermission } from "../auth/Authorization";
import { useLanguageContext } from "../LanguageContext";
import {
  RecordStatusList,
  RecordStatusDetail,
  RecordTypeDetails,
} from "./types/RecordStatus";

const RecordStatus: React.FC = () => {
  const [t, i18n] = useTranslation("report");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [dates, setDates] = useState<Date[]>([]);
  const [userProfileId, setUserProfileId] = useState<string>("");
  const [statusId, setStatusId] = useState<string>("");
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [recordTypeList, setRecordTypeList] = useState<
    { label: string; value: string }[] | []
  >([]);
  const [committeeValue, setCommitteeValue] = useState<string[] | null>(null);
  const [recordValue, setRecordValue] = useState<string[] | null>(null);
  const [recordList, setRecordList] = useState<
    { label: string; value: string }[] | []
  >([]);
  const [userRole, setUserRole] = useState<string>("");
  const [requestList, setRequestLists] = useState<RecordStatusList[] | []>([]);
  const [search, setSearch] = useState("");
  const breadcumbItems = [
    { label: t("report.dashboard"), url: "/dashboard" },
    { label: t("report.reports") },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const responseData = await fetchData(
          "UserProfile/GetCurrentUserProfile"
        );
        setUserProfileId(responseData.Id);
      } catch (error) {
        console.error("Error fetching current user profile:", error);
      }
    };
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const responseData = await fetchData(`RecordStatus/GetAll`);
        const labelValueArray = responseData.Collection.map(
          (category: RecordStatusDetail) => ({
            label: category.StatusName,
            value: category.Id,
          })
        );
        setRecordList(labelValueArray);
        const filteredData = responseData.Collection.filter(
          (item: RecordStatusDetail) => {
            if (item.StatusName !== "Open") {
              return false;
            }
            return true;
          }
        );
        setStatusId(filteredData[0].Id);
      } catch (error) {
        console.error("Error fetching current user profile:", error);
      }
    };
    fetchStatus();
    const fetchRecordType = async () => {
      try {
        const responseData = await fetchData(`RecordType/GetAll`);
        const labelValueArray = responseData.Collection.map(
          (category: RecordTypeDetails) => ({
            label: category.TypeName,
            value: category.Id,
          })
        );
        setRecordTypeList(labelValueArray);
      } catch (error) {
        console.error("Error fetching current user profile:", error);
      }
    };
    fetchRecordType();
    fetchStatus();
  }, []);

  const exportData = () => {
    if (!requestList) {
      return null;
    }
    const sortedData = requestList.map((item: RecordStatusList) => ({
      "Record#": item.recordNumber,
      "Record Type": item.recordType,
      "Committee Applied for": item.committeeAppliedFor
        ? item.committeeAppliedFor
        : null,
      "Position Applied for": item?.positionAppliedFor,
      "Created on": item.createdDate
        ? new Date(item.createdDate).toLocaleDateString()
        : null,
      Status: item?.status,
    }));
    const csvData = [...sortedData];
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const filename = `Record_Status_Report_${day}-${month}-${year}_${hours}${minutes}${seconds}.csv`;
    return (
      <CSVLink data={csvData} filename={filename}>
        <Button className="button-md gap-1 justify-content-center w-full md:w-auto">
          <span className="font-bold text-capitalize">
            {t("report.export")}
          </span>
        </Button>
      </CSVLink>
    );
  };

  const handleChangeCommittee = (e: MultiSelectChangeEvent) => {
    const selectedCommittee = e.value;
    setCommitteeValue(selectedCommittee);
  };

  const handleChangeMembers = (e: MultiSelectChangeEvent) => {
    const selectedMembers = e.value;
    setRecordValue(selectedMembers);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const buildUrl = (
    dates: any,
    memberValue: string[] | null | string,
    committeeValue: string[] | null | string,
    userProfileId: string
  ) => {
    let url = "Record/RecordStatusReport?";
    const filters: {
      Key: string;
      Value: string | number | string[] | number[] | null;
    }[] = [];
    if (dates && dates.length > 0) {
      const formattedStartDate = formatDate(dates[0]);
      const formattedEndDate = formatDate(dates[dates.length - 1]);
      filters.push({ Key: "StartDate", Value: formattedStartDate });
      filters.push({ Key: "EndDate", Value: formattedEndDate });
    }
    if (committeeValue && committeeValue.length > 0) {
      filters.push({ Key: "RecordTypeId", Value: committeeValue });
    }
    if (recordValue && recordValue.length > 0) {
      filters.push({ Key: "StatusId", Value: memberValue });
    }
    if (userProfileId && userRole !== "Admin") {
      filters.push({ Key: "StaffId", Value: userProfileId });
    }
    url += filters
      .map(
        (filter: {
          Key: string;
          Value: string | number | string[] | number[] | null;
        }) =>
          `Filters[${filters.indexOf(filter)}].Key=${filter.Key}&Filters[${filters.indexOf(filter)}].Value=${filter.Value}`
      )
      .join("&");
    url += `&PageIndex=-1`;
    return url;
  };

  const fetchDataBasedOnFilters = async (
    url: string,
    setRequestLists: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    try {
      const responseData = await fetchData(url);
      const sortedData = responseData.collection.map((item: any) => ({
        ...item,
        CreatedDate: item.createdDate
          ? new Date(item.createdDate).toLocaleDateString()
          : null,
        statusName: item.status,
      }));
      setRequestLists(sortedData);
    } catch (error) {
      console.error("Error fetching current user profile:", error);
    } finally {
    }
  };

  useEffect(() => {
    if (!userProfileId) return;
    const url = buildUrl(dates, recordValue, committeeValue, userProfileId);
    if (url) {
      fetchDataBasedOnFilters(url, setRequestLists);
    }
  }, [dates, recordValue, committeeValue, userProfileId, statusId]);

  return (
    <>
      <div>
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-wrap md:flex-nowrap md:align-items-center gap-3 w-full justify-content-between">
          <h1 className="text-title display-xs font-bold m-0">
            {t("report.recordReport")}
          </h1>
          <Calendar
            appendTo="self"
            value={dates}
            placeholder={t("report.selectDateRange")}
            ariaLabel={t("report.selectDateRange")}
            onChange={(e: any) => setDates(e.value)}
            selectionMode="range"
            readOnlyInput
            hideOnRangeSelection
            className="custom-datepicker w-full md:w-18rem lg:w:20rem"
          />
        </div>
        <div className="">
          <div className="flex flex-wrap md:align-items-center py-3 gap-3 w-full justify-content-between">
            <IconField iconPosition="left" className="w-full md:w-16rem">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                id="searchCommittee"
                className="w-full"
                placeholder={t("report.search")}
                aria-label={t("report.search")}
                onChange={(e) => setSearch(e.target.value)}
              />
            </IconField>
            <div className="flex flex-wrap gap-3 w-full md:w-auto align-items-center">
              <MultiSelect
                value={committeeValue}
                onChange={handleChangeCommittee}
                options={recordTypeList}
                optionLabel="label"
                display="chip"
                filter
                placeholder={t("report.selectRecordType")}
                aria-label={t("report.selectRecordType")}
                maxSelectedLabels={3}
                className="w-full md:w-18rem lg:w:20rem"
              />
              <MultiSelect
                value={recordValue}
                onChange={handleChangeMembers}
                options={recordList}
                optionLabel="label"
                display="chip"
                filter
                placeholder={t("report.selectRecordStatus")}
                aria-label={t("report.selectRecordStatus")}
                maxSelectedLabels={3}
                className="w-full md:w-18rem lg:w:20rem"
              />
              {exportData()}
              {userRole === "Member" && (
                <Button
                  className="button-md gap-1 justify-content-center w-full md:w-auto"
                  onClick={() => navigate("/member/create")}
                >
                  <FeatherIcon name="plus" size={20} color="inherit" />
                  <span className="font-bold">{t("report.export")}</span>
                </Button>
              )}
            </div>
          </div>
          <Table
            loading={false}
            products={requestList?.filter((item: RecordStatusList) => {
              const RecordNumber = "" || item.recordNumber;
              const RecordType = "" || item.recordType;
              const CommitteeAppliedFor = "" || item.committeeAppliedFor;
              return (
                search === "" ||
                CommitteeAppliedFor.toLowerCase().includes(
                  search.toLowerCase()
                ) ||
                RecordNumber.toLowerCase().includes(search.toLowerCase()) ||
                RecordType.toLowerCase().includes(search.toLowerCase())
              );
            })}
            columns={[
              { field: "recordNumber", header: t("report.recordNo") },
              { field: "recordType", header: t("report.recordType") },
              {
                field: "committeeAppliedFor",
                header: t("report.committeeAppliedFor"),
              },
              {
                field: "positionAppliedFor",
                header: t("report.positionAppliedFor"),
              },
              { field: "createdDate", header: t("report.createdOn") },
              { field: "statusName", header: t("report.status") },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default RecordStatus;

import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import Input from "../../utils/Input";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import "./Configuration.scss";
import {
  fetchData,
  postData,
  putData,
  deleteData,
  getListByParentId,
} from "../../services/apiService";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";

const MasterData: React.FC = () => {
  const [t, i18n] = useTranslation("configuration");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState(null);
  const [gridData, setGridData] = useState([]);
  const [gridHeaders, setGridHeaders] = useState<string[]>([]);
  const [row, setRow] = useState([
    {
      TypeName: "",
      Description: "",
      checkbox: false,
      CategoryName: "",
      Active: false,
      id: "",
      isNew: true,
    },
  ]);

  useEffect(() => {
    if (selectedValue) {
      fetchGridData(selectedValue);
    }
  }, [selectedValue]);

  const fetchGridData = async (selectedValue: any) => {
    try {
      const data = await fetchData(`${selectedValue}/GetAll`);
      const dataArray = data.Collection || [];
      setRow(dataArray.map((item: { Id: any }) => ({ ...item, id: item.Id })));
      const firstObjectKeys = Object.keys(dataArray[0] || {});
      const headers = [...firstObjectKeys.slice(0, 2), "Active"];
      setGridHeaders(headers);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDropdownChange = (selectedItem: any) => {
    setSelectedValue(selectedItem.value);
  };

  const handleAdd = () => {
    setRow([
      ...row,
      {
        TypeName: "",
        Description: "",
        CategoryName: "",
        checkbox: false,
        Active: true,
        id: "",
        isNew: true,
      },
    ]);
  };

  const handleCancel = () => {
    setSelectedValue(null);
    setRow([]);
    setGridHeaders([]);
  };

  const confirmDelete = (rowId: string) => {
    confirmDialog({
      header: t("configuration.confirmation"),
      message: t("configuration.areYouSureYouWantToDelete"),
      icon: "",
      acceptLabel: t("configuration.yes"),
      rejectLabel: t("configuration.no"),
      defaultFocus: "accept",
      accept: () => handleDeleteRecord(selectedValue, rowId),
      reject: () => navigate("/configuration"),
    });
  };

  const handleDeleteRecord = async (selectedValue: any, rowId: string) => {
    try {
      await deleteData(selectedValue, rowId);
      const updatedRows = row.filter((row) => row.id !== rowId);
      setRow(updatedRows);
      navigate("/configuration");
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleUpdate = async (selectedValue: any) => {
    try {
      const updatedRowData = row.filter((item) => item.id);
      await Promise.all(
        updatedRowData.map(async (rowData) => {
          await putData(selectedValue, rowData.id, rowData);
        })
      );
      fetchGridData(selectedValue);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleAddIconClick = async (index: number) => {
    try {
      const selectedEndpoint = `${selectedValue}`;
      const newRow = row[index];
      const requiredFields = ["typeName", "description"].filter(
        (key) => newRow[key as keyof typeof newRow] === ""
      );
      if (requiredFields.length > 0) {
        console.error("Please fill all required fields.");
        return;
      }
      const dataToSend = {
        isDirty: true,
        isNew: true,
        ...(selectedValue === "CategoryOfInterest"
          ? {
              categoryName: newRow.CategoryName,
              description: newRow.Description,
            }
          : {
              typeName: newRow.TypeName,
              description: newRow.Description,
            }),
      };
      await postData(selectedEndpoint, dataToSend);
      const updatedRow = [...row];
      updatedRow[index].isNew = false;
      setRow(updatedRow);
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };

  const HandleMasterData = [
    { name: "Committee Type", value: "CommitteeType" },
    { name: "Membership Position", value: "MembershipPosition" },
    { name: "Record Type", value: "RecordType" },
    { name: "Record Status", value: "RecordStatus" },
    { name: "Category Of Interest", value: "CategoryOfInterest" },
  ];

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex flex-column gap-1">
              <h2 className="text-title text-lg font-bold m-0">
                {t("configuration.masterData")}
              </h2>
            </div>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label htmlFor="MasterType" className="font-bold">
                    {t("configuration.selectType")}:
                  </label>
                  <Dropdown
                    inputId="MasterType"
                    value={selectedValue}
                    options={HandleMasterData}
                    optionLabel="name"
                    onChange={handleDropdownChange}
                    placeholder={t("configuration.selectAnOption")}
                    className="w-full"
                  />
                </div>
              </div>

              <div className="flex flex-column gap-2 lg:w-9">
                <div className="grid m-0 w-full gap-3 grid-resp grid-resp-lables masterdata-grid">
                  {gridHeaders.map((key, index) => (
                    <span key={index} className={`font-bold`} id={key}>
                      {key}
                    </span>
                  ))}
                </div>
                <div className="flex flex-column gap-3 w-full"></div>
                {row.map((rowData, rowIndex) => (
                  <div
                    className={`grid m-0 mb-3 md:mb-0 w-full gap-3 grid-resp masterdata-grid`}
                    key={rowIndex}
                  >
                    {gridHeaders.map((header, headerIndex) => (
                      <div
                        className="data-label"
                        key={headerIndex}
                        data-label={header}
                      >
                        {header === "Active" ? (
                          <div className="flex align-content-center h-full">
                            <Checkbox
                              className="align-self-center"
                              checked={rowData.Active}
                              onChange={(e) => {
                                const updatedRow = [...row];
                                updatedRow[rowIndex].Active =
                                  e.checked ?? false;
                                setRow(updatedRow);
                              }}
                              aria-label={`Checkbox-${rowData.Active}`}
                            />
                          </div>
                        ) : (
                          <InputText
                            placeholder={header}
                            className="w-full"
                            aria-labelledby={header}
                            value={(rowData as any)[header] || ""}
                            onChange={(e) => {
                              const updatedRow = [...row];
                              (updatedRow[rowIndex] as any)[header] =
                                e.target.value;
                              setRow(updatedRow);
                            }}
                          />
                        )}
                      </div>
                    ))}
                    {rowData.isNew ? (
                      <Button
                        text
                        className="p-button-plain p-2"
                        aria-label={t("configuration.add")}
                        onClick={() => handleAddIconClick(rowIndex)}
                        style={{
                          display: selectedValue ? "flex" : "none",
                        }}
                      >
                        <FeatherIcon name="plus" size={20} color="green" />
                      </Button>
                    ) : (
                      <Button
                        text
                        className="p-button-plain p-2 text-delete"
                        aria-label={t("configuration.delete")}
                        onClick={() => confirmDelete(rowData.id)}
                        style={{
                          display: selectedValue ? "flex" : "none",
                        }}
                      >
                        <FeatherIcon name="trash-2" size={20} />
                      </Button>
                    )}
                  </div>
                ))}

                <div className="mt-3">
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0"
                    onClick={handleAdd}
                    style={{ display: selectedValue ? "inline-flex" : "none" }}
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold underline">
                      {t("configuration.addRow")}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-4 justify-content-end cardFooter">
            <Button
              className="button-md"
              severity="secondary"
              onClick={handleCancel}
            >
              <span className="font-bold">{t("configuration.cancel")}</span>
            </Button>
            <Button
              className="button-md"
              onClick={() => selectedValue && handleUpdate(selectedValue)}
            >
              <span className="font-bold">{t("configuration.submit")}</span>
            </Button>

            <ConfirmDialog className="custom-dialog hide-close" ariaCloseIconLabel="Close" />
          </div>
        </div>
      </div>
    </>
  );
};

export default MasterData;

import React, { useState } from "react";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import CustomStepWizard from "../common/CustomStepWizard";

interface CommitteeStepsProps {
  activeStep: number;
}

const Committeesteps: React.FC<CommitteeStepsProps> = ({
  activeStep,
}) => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);


  const stepItems = [
    {
      icon: "cached",
      label: t("committee.committee"),
    },
    {
      icon: "cached",
      label: t("committee.configuration"),
    },
    {
      icon: "cached",
      label: t("committee.member"),
    },
    {
      icon: "cached",
      label: t("committee.preview"),
    },
  ];

  return (
    <>
      <div className="mb-5">
        <CustomStepWizard stepItems={stepItems} activeIndex={activeStep} />
      </div>
    </>
  );
};

export default Committeesteps;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postData } from "../services/apiService";

interface CreateMemberApiData {
  openPositionId: any;
  data: any;
  loading: boolean;
}

const initialState: CreateMemberApiData = {
  data: {},
  loading: false,
  openPositionId: undefined || null,
};

interface MembershipRequest {
  tenantId: string;
  isDirty: boolean;
  isNew: boolean;
  committeeId?: string | undefined | null;
  membershipStatusId: string;
  membershipPositionId: string;
  categoryOfInterestId: string;
  remark: string;
  statusId: string;
  openPositionId: string | null;
}

const createMemberReqestSlice = createSlice({
  name: "createMemberRequest",
  initialState,
  reducers: {
    createMemberRequest: (state, action: PayloadAction<any>) => {
      state.data = { ...state.data, ...action.payload };
      state.loading = true;
    },
    createMemberSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
    },
    createMemberFailure: (state) => {
      state.loading = false;
    },
    resetMemberRequest: () => initialState,
  },
});

export const {
  createMemberRequest,
  createMemberSuccess,
  createMemberFailure,
  resetMemberRequest,
} = createMemberReqestSlice.actions;
export default createMemberReqestSlice.reducer;

import React, { useEffect, useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import Input from "../../utils/Input";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import {
  fetchData,
  getListByParentId,
  postData,
} from "../../services/apiService";
import { useDispatch } from "react-redux";
import { InputText } from "primereact/inputtext";
import { focusOnErrorField } from "../../utils/focusError";
import { focusOnField } from "../../utils/focusField";
interface EducationDetail {
  isNew: boolean;
  userProfileId: string;
  isActive: true;
  isDeleted: false;
  IsDirty?: boolean;
  degreeId: string;
  universityId: string;
  fromYear: any;
  toYear: any;
  countryId: string;
  city: string;
  stateId: string;
  TenantId?: string;
  userEducationDetailId: string;
}

const AddEducation = () => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const userProfileID = localStorage.getItem("userProfileID") || "";
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [educationDetails, setEducationDetails] = useState<EducationDetail[]>([
    {
      isNew: true,
      userProfileId: userProfileID,
      isActive: true,
      isDeleted: false,
      IsDirty: true,
      degreeId: "",
      universityId: "",
      fromYear: null,
      toYear: null,
      countryId: "",
      city: "",
      stateId: "",
      // TenantId: TENANT_ID,
      userEducationDetailId: userProfileID,
    },
  ]);
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState<string | null>(null);
  const [degreeOptions, setDegreeOptions] = useState([]);
  const [universityOptions, setUniversityOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [validationErrors, setValidationErrors] = useState<
    Array<Partial<EducationDetail>>
  >([]);

  const handleAddEducationDetail = () => {
    setEducationDetails([
      ...educationDetails,
      {
        isNew: true,
        userProfileId: userProfileID,
        isActive: true,
        isDeleted: false,
        IsDirty: true,
        degreeId: "",
        universityId: "",
        fromYear: null,
        toYear: null,
        countryId: "",
        city: "",
        stateId: "",
        //TenantId: TENANT_ID,
        userEducationDetailId: userProfileID,
      },
    ]);

    // To focus on newly added row first element "degree"
    setTimeout(() => {
      focusOnField(`degree-${educationDetails.length}`);
    }, 0); // Delay of 0 milliseconds
  };
  const createProfileLabelForValidation = [
    { name: "degreeId", label: t("profile.degree") },
    { name: "universityId", label: t("profile.university") },
    { name: "countryId", label: t("profile.country") },
    { name: "stateId", label: t("profile.state") },
    { name: "city", label: t("profile.city") },
    { name: "fromYear", label: t("profile.yearFrom") },
    { name: "toYear", label: t("profile.yearTo") },
  ];

  const handleInputChange = (
    index: number,
    key: keyof EducationDetail,
    value: any
  ) => {
    const newEducationDetails = [...educationDetails];
    newEducationDetails[index][key] = value as never;

    const newValidationErrors = [...validationErrors];
    newValidationErrors[index] = {
      ...newValidationErrors[index],
      [key]: undefined,
    };
    setValidationErrors(newValidationErrors);

    setEducationDetails(newEducationDetails);
  };

  const fetchCountryByID = async (countryId: any) => {
    try {
      const responseData = await fetchData("Country", countryId);
      const countryName = responseData.Name;

      getListByParentId("State", countryId)
        .then((data: any) => {
          const dataCollection = data?.Collection;
          const stateOptions = dataCollection.map((state: any) => ({
            value: state.Id,
            label: state.Name,
          }));

          setStateOptions(stateOptions);
        })
        .catch((error) => {
          console.error("Error fetching countries:", error);
        });
    } catch (error) {
      console.error("Error fetching data from AnotherAPIEndpoint:", error);
    }
  };
  useEffect(() => {
    const fetchDataDegrees = async () => {
      try {
        const postDataResponse = await fetchData("Degree/getAll");
        const formattedDegreeOptions = postDataResponse.Collection.map(
          (item: { Name: any; Id: any }) => ({
            label: item.Name,
            value: item.Id,
          })
        );
        setDegreeOptions(formattedDegreeOptions);
      } catch (error) {
        console.error("Error fetching degrees:", error);
      }
    };

    // Get all University
    const fetchDataUniversity = async () => {
      try {
        const postDataResponse = await fetchData("University/getAll");
        const formattedUniversityOptions = postDataResponse.Collection.map(
          (item: { Name: any; Id: any }) => ({
            label: item.Name,
            value: item.Id,
          })
        );
        setUniversityOptions(formattedUniversityOptions);
      } catch (error) {
        console.error("Error fetching degrees:", error);
      }
    };

    // Get all Country
    const fetchDataCountry = async () => {
      try {
        const postDataResponse = await fetchData("Country/getAll");
        const formattedCountryOptions = postDataResponse.Collection.map(
          (item: { Name: any; Id: any }) => ({
            label: item.Name,
            value: item.Id,
          })
        );
        setCountryOptions(formattedCountryOptions);
      } catch (error) {
        console.error("Error fetching degrees:", error);
      }
    };

    fetchDataDegrees();
    fetchDataUniversity();
    fetchDataCountry();
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setTimeout(() => {
      focusOnErrorField(".p-invalid");
    }, 100);
    const errors: Array<Partial<EducationDetail>> = [];

    const requiredFields = [
      "degreeId",
      "universityId",
      "countryId",
      "stateId",
      "city",
      "fromYear",
      "toYear",
    ];

    educationDetails.forEach((education, index) => {
      const error: Partial<EducationDetail> = {};

      requiredFields.forEach((field) => {
        const fieldValue = education[field as keyof EducationDetail];
        if (
          !fieldValue ||
          (typeof fieldValue === "string" && fieldValue.trim() === "")
        ) {
          // Find the corresponding label name from createProfileLabelForValidation
          const label =
            createProfileLabelForValidation.find((item) => item.name === field)
              ?.label || field;
          error[field as keyof EducationDetail] =
            `${label} ${t("profile.isRequired")}`;
        }
      });

      errors[index] = error;
    });

    setValidationErrors(errors);
    if (errors.some((error) => Object.keys(error).length !== 0)) {
      return;
    }
    if (educationDetails) {
    }
    try {
      const postDataResponse = await postData(
        "UserEducationDetail/BulkUpload",
        educationDetails
      );
      navigate("/profile/");
      return postDataResponse;
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  const isExperienceFilled = (education: EducationDetail): boolean => {
    return (
      education.degreeId.trim() !== "" &&
      education.universityId.trim() !== "" &&
      education.countryId.trim() !== "" &&
      education.stateId.trim() !== "" &&
      education.city.trim() !== "" &&
      education.fromYear &&
      education.toYear
    );
  };

  const areAllExperiencesFilled = (): boolean => {
    return educationDetails.every(isExperienceFilled);
  };

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              onClick={() => navigate("/profile/")}
              aria-label={t("profile.back")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("profile.addeducationaldetail")}
              </h1>
              <p className="text-base font-normal m-0 text-help">
                {t("profile.allFieldsRequired")}
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="p-5 cardBody">
              {educationDetails.map((education, index) => (
                <div
                  className={`flex flex-column gap-3 mb-3 ${
                    index > 0 ? "border-top-1 border-gray-200 pt-5" : ""
                  }`}
                  key={index}
                >
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`degree-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.degreeId ? "p-error" : ""
                        }`}
                      >
                        {t("profile.degree")}
                      </label>
                      <Dropdown
                        inputId={`degree-${index}`}
                        aria-describedby={`degreeError-${index}`}
                        value={education.degreeId}
                        onChange={(e) =>
                          handleInputChange(index, "degreeId", e.value)
                        }
                        className={
                          validationErrors[index]?.degreeId ? "p-invalid" : ""
                        }
                        options={degreeOptions}
                        optionLabel="label"
                        placeholder={t("profile.selectAnOption")}
                      />
                      <span
                        id={`degreeError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.degreeId ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.degreeId}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`university-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.universityId ? "p-error" : ""
                        }`}
                      >
                        {t("profile.university")}
                      </label>
                      <Dropdown
                        inputId={`university-${index}`}
                        aria-describedby={`universityError-${index}`}
                        value={education.universityId}
                        onChange={(e) =>
                          handleInputChange(index, "universityId", e.value)
                        }
                        className={
                          validationErrors[index]?.universityId
                            ? "p-invalid"
                            : ""
                        }
                        options={universityOptions}
                        optionLabel="label"
                        placeholder={t("profile.selectAnOption")}
                        // className="w-full"
                      />
                      <span
                        id={`universityError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.universityId ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.universityId}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`yearFrom-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.fromYear ? "p-error" : ""
                        }`}
                      >
                        {t("profile.from")}
                      </label>
                      <Calendar
                        appendTo="self"
                        maxDate={education.toYear}
                        inputId={`yearFrom-${index}`}
                        aria-describedby={`yearFromError-${index}`}
                        onChange={(e) =>
                          handleInputChange(index, "fromYear", e.target.value)
                        }
                        // onSelect={() => focusOnField(`yearFrom-${index}`)}
                        value={education.fromYear}
                        dateFormat="mm/dd/yy"
                        placeholder={t("profile.yearFrom")}
                        className={`custom-datepicker ${
                          validationErrors[index]?.fromYear ? "p-invalid" : ""
                        }`}
                      />
                      <span
                        id={`yearFromError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.fromYear ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.fromYear}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`yearTo-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.toYear ? "p-error" : ""
                        }`}
                      >
                        {t("profile.to")}
                      </label>
                      <Calendar
                        appendTo="self"
                        inputId={`yearTo-${index}`}
                        aria-describedby={`yearToError-${index}`}
                        value={education.toYear}
                        minDate={education.fromYear}
                        onChange={(e) =>
                          handleInputChange(index, "toYear", e.target.value)
                        }
                        // onSelect={() => focusOnField(`yearTo-${index}`)}
                        dateFormat="mm/dd/yy"
                        placeholder={t("profile.yearTo")}
                        className={`custom-datepicker ${
                          validationErrors[index]?.toYear ? "p-invalid" : ""
                        }`}
                      />
                      <span
                        id={`yearToError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.toYear ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.toYear}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`country-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.countryId ? "p-error" : ""
                        }`}
                      >
                        {t("profile.country")}
                      </label>
                      <Dropdown
                        inputId={`country-${index}`}
                        aria-describedby={`countryError-${index}`}
                        value={education.countryId}
                        onChange={(e) => {
                          const newValue = e.value;
                          handleInputChange(index, "countryId", newValue);
                          fetchCountryByID(newValue);
                        }}
                        className={
                          validationErrors[index]?.countryId ? "p-invalid" : ""
                        }
                        options={countryOptions}
                        optionLabel="label"
                        placeholder={t("profile.selectAnOption")}
                        // className="w-full"
                      />
                      <span
                        id={`countryError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.countryId ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.countryId}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`state-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.stateId ? "p-error" : ""
                        }`}
                      >
                        {t("profile.state")}
                      </label>
                      <Dropdown
                        inputId={`state-${index}`}
                        aria-describedby={`stateError-${index}`}
                        value={education.stateId}
                        onChange={(e) =>
                          handleInputChange(index, "stateId", e.value)
                        }
                        className={
                          validationErrors[index]?.stateId ? "p-invalid" : ""
                        }
                        options={stateOptions}
                        optionLabel="label"
                        placeholder={t("profile.selectAnOption")}
                      />
                      <span
                        id={`stateError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.stateId ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.stateId}
                      </span>
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor={`city-${index}`}
                        className={`block font-bold ${
                          validationErrors[index]?.city ? "p-error" : ""
                        }`}
                      >
                        {t("profile.city")}
                      </label>
                      <InputText
                        id={`city-${index}`}
                        aria-describedby={`cityError-${index}`}
                        value={education.city}
                        name={`city-${index}`}
                        onChange={(e) =>
                          handleInputChange(index, "city", e.target.value)
                        }
                        placeholder={t("profile.city")}
                        className={
                          validationErrors[index]?.city ? "p-invalid" : ""
                        }
                      />
                      <span
                        id={`cityError-${index}`}
                        className={`p-error font-bold text-capitalize ${validationErrors[index]?.city ? "" : "error-hidden"}`}
                      >
                        {validationErrors[index]?.city}
                      </span>
                    </div>
                  </div>
                </div>
              ))}

              <div
                className={`p-error font-bold text-capitalize ${validationError ? "" : "error-hidden"}`}
              >
                {validationError}
              </div>
            </div>
            <div className="flex flex-wrap align-items-center px-5 py-3 border-top-1 border-gray-200 gap-3 md:gap-4 justify-content-between cardFooter">
              <Button
                text
                className="p-button-plain gap-2 px-2 md:pr-3 md:pl-0 text-link underline"
                onClick={handleAddEducationDetail}
                disabled={!areAllExperiencesFilled()}
                aria-label={t("profile.addAnotherEducation")}
              >
                <FeatherIcon name="plus" size={20} />
                <span className="hidden md:flex">
                  {t("profile.addAnotherEducation")}
                </span>
              </Button>
              <Button
                label={t("profile.cancel")}
                className="button-md ml-auto w-auto"
                severity="secondary"
                onClick={() => navigate("/profile/")}
              />
              <Button label={t("profile.save")} className="button-md w-auto" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddEducation;

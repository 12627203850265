import React, { useRef, ReactNode } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

interface ToastMessageProps {
  severity: any;
  summary: string;
  detail: string;
  life?: number;
  children: ReactNode;
}

const ToastMessage: React.FC<ToastMessageProps> = ({
  severity,
  summary,
  detail,
  life = 3000,
  children,
}) => {
  const toast = useRef<Toast>(null);

  const showMessage = () => {
    toast.current?.show({ severity, summary, detail, life });
  };

  return (
    <>
      <Toast ref={toast} />
      <div onClick={showMessage}>{children}</div>
    </>
  );
};

export default ToastMessage;

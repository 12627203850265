import React, { useState } from "react";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import CustomStepWizard from "../common/CustomStepWizard";

interface CreateRecordHeaderProps {
  activeStep: number;
}

const CreateRecordHeader: React.FC<CreateRecordHeaderProps> = ({
  activeStep,
}) => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: t("record.dashboard"), url: "/dashboard" },
    { label: t("record.record"), url: "/record" },
    { label: t("record.createNewRecord") },
  ];

  const stepItems = [
    {
      icon: "cached",
      label: t("record.applicant"),
    },
    {
      icon: "cached",
      label: t("record.selectCommittee"),
    },
    {
      icon: "cached",
      label: t("record.selectPosition"),
    },
    {
      icon: "cached",
      label: t("record.preview"),
    },
  ];

  return (
    <>
      <div className="mb-5">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("record.createNewRecords")}
          </h1>
          {/* <p className="text-base font-normal m-0">
            Any supporting text for request listing page can go here
          </p> */}
        </div>
        <CustomStepWizard stepItems={stepItems} activeIndex={activeStep} />
      </div>
    </>
  );
};

export default CreateRecordHeader;

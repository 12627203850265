import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { useLanguageContext } from "../LanguageContext";
import Table from "../common/Table";
import PreviewItem from "../common/PreviewItem";
import FeatherIcon from "../common/FeatherIconComponent";

const CloseBallot: React.FC = () => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(true); // State to manage collapse/expand

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <div>
        <Button
          text
          className="p-button-plain gap-2 mb-3 pl-0 underline"
          onClick={() => navigate("/ballot")}
        >
          <FeatherIcon name="arrow-left" size={20} />
          <span className="">{t("ballot.back")}</span>
        </Button>
        <div className="mb-5 flex flex-wrap gap-2">
          <div className="flex flex-column gap-2">
            <div className="flex gap-2">
              <h1 className="text-title display-xs font-bold text-capitalize m-0 flex align-items-center gap-2">
                {t("ballot.ballotClosure")} - 773574
              </h1>
            </div>
            <p className="text-base font-normal m-0">
              Any supporting text for request listing page can go here
            </p>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center pl-5 pr-2 py-3 border-bottom-1 border-gray-200 gap-3 justify-content-between cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.votingCriteria")}
            </h2>
            <Button
              text
              className="p-button-plain underline gap-1"
              onClick={toggleCollapse}
            >
              <FeatherIcon
                name={collapsed ? "chevron-down" : "chevron-up"}
                size={20}
                color="inherit"
              />
            </Button>
          </div>
          {!collapsed && (
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-7">
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("ballot.approvedRecord")}
                    value={`${t("ballot.moreThan")} 60%`}
                  />
                  <span>Or</span>
                  <PreviewItem
                    label={t("ballot.disapporvedRecord")}
                    value={`${t("ballot.lessThan")} 10%`}
                  />
                </div>

                <div className="flex flex-column gap-5">
                  <h2 className="text-title text-lg font-bold text-capitalize m-0">
                    {t("ballot.currentVoteCountByCommitteeName")}
                  </h2>
                  <div className="flex flex-column gap-4">
                    <PreviewItem
                      label={t("ballot.totalMemberInvited")}
                      value="38"
                    />
                    <PreviewItem label={t("ballot.votesRecevied")} value="32" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.recordStatus")}
            </h2>
          </div>
          <div className="p-5 cardBody">
            <Table
              products={[]}
              loading={false}
              columns={[
                {
                  field: "recordId",
                  header: t("ballot.recordId"),
                  link: "/ballot/",
                },
                { field: "committeeName", header: t("ballot.committeeName") },
                {
                  field: "oldRecordStatus",
                  header: t("ballot.oldRecordStatus"),
                },
                {
                  field: "newRecordStatus",
                  header: t("ballot.newRecordStatus"),
                },
              ]}
            />
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.committeeForBalloting")}
            </h2>
          </div>

          <div className="p-5 cardBody flex flex-column gap-7">
            <div className="">
              <div className="flex flex-wrap align-items-center justify-content-between gap-4  mb-4">
                <h3 className="text-title text-lg text-capitalize m-0">
                  <span className="font-bold mr-2">
                    {t("ballot.committeeName")}
                  </span>
                  <span className="font-normal">
                    ({t("ballot.primaryResponsibleCommittee")})
                  </span>
                </h3>
                <h4 className="text-lg font-bold m-0">
                  16/18 {t("ballot.memberVoted")}
                </h4>
              </div>
              <Table
                products={[]}
                loading={false}
                columns={[
                  {
                    field: "approvedYesNo",
                    header: `${t("ballot.approved")}?`,
                  },
                  {
                    field: "recordId",
                    header: t("ballot.recordId"),
                    link: "/ballot/",
                  },
                  { field: "applicantName", header: t("ballot.applicantName") },
                  { field: "criteriaMet", header: t("ballot.criteriaMet") },
                  {
                    field: "committeeAppliedFor",
                    header: t("ballot.committeeAppliedFor"),
                  },
                  {
                    field: "positionAppliedFor",
                    header: t("ballot.positionAppliedFor"),
                  },
                  { field: "termYear", header: t("ballot.termYear") },
                  { field: "approved", header: t("ballot.approved") },
                  { field: "disapproved", header: t("ballot.disapproved") },
                  { field: "abstained", header: t("ballot.abstained") },
                  { field: "notReturn", header: t("ballot.notReturn") },
                  {
                    field: "commentsResponded",
                    header: t("ballot.commentsResponded"),
                  },
                ]}
              />
              <Button
                text
                className="p-button-plain underline"
                label={t("ballot.viewComments")}
              ></Button>
            </div>
            <div className="">
              <div className="flex flex-wrap align-items-center justify-content-between gap-4  mb-4">
                <h3 className="text-title text-lg text-capitalize m-0">
                  <span className="font-bold mr-2">
                    {t("ballot.committeeName")}
                  </span>
                </h3>
                <h4 className="text-lg font-bold m-0">
                  16/18 {t("ballot.memberVoted")}
                </h4>
              </div>
              <Table
                products={[]}
                loading={false}
                columns={[
                  {
                    field: "approvedYesNo",
                    header: `${t("ballot.approved")}?`,
                  },
                  {
                    field: "recordId",
                    header: t("ballot.recordId"),
                    link: "/ballot/",
                  },
                  { field: "applicantName", header: t("ballot.applicantName") },
                  { field: "criteriaMet", header: t("ballot.criteriaMet") },
                  {
                    field: "committeeAppliedFor",
                    header: t("ballot.committeeAppliedFor"),
                  },
                  {
                    field: "positionAppliedFor",
                    header: t("ballot.positionAppliedFor"),
                  },
                  { field: "termYear", header: t("ballot.termYear") },
                  { field: "approved", header: t("ballot.approved") },
                  { field: "disapproved", header: t("ballot.disapproved") },
                  { field: "abstained", header: t("ballot.abstained") },
                  { field: "notReturn", header: t("ballot.notReturn") },
                  {
                    field: "commentsResponded",
                    header: t("ballot.commentsResponded"),
                  },
                ]}
              />
              <Button
                text
                className="p-button-plain underline"
                label={t("ballot.viewComments")}
              ></Button>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.committeeForReviewAndComment")}
            </h2>
          </div>

          <div className="p-5 cardBody flex flex-column gap-7">
            <div className="">
              <div className="flex flex-wrap align-items-center justify-content-between gap-4  mb-4">
                <h3 className="text-title text-lg text-capitalize m-0">
                  <span className="font-bold mr-2">
                    {t("ballot.committeeName")}
                  </span>
                  <span className="font-normal">
                    ({t("ballot.primaryResponsibleCommittee")})
                  </span>
                </h3>
                <h4 className="text-lg font-bold m-0">
                  16/18 {t("ballot.memberVoted")}
                </h4>
              </div>
              <Table
                products={[]}
                loading={false}
                columns={[
                  {
                    field: "approvedYesNo",
                    header: `${t("ballot.approved")}?`,
                  },
                  {
                    field: "recordId",
                    header: t("ballot.recordId"),
                    link: "/ballot/",
                  },
                  { field: "applicantName", header: t("ballot.applicantName") },
                  { field: "criteriaMet", header: t("ballot.criteriaMet") },
                  {
                    field: "committeeAppliedFor",
                    header: t("ballot.committeeAppliedFor"),
                  },
                  {
                    field: "positionAppliedFor",
                    header: t("ballot.positionAppliedFor"),
                  },
                  { field: "termYear", header: t("ballot.termYear") },
                  { field: "approved", header: t("ballot.approved") },
                  { field: "disapproved", header: t("ballot.disapproved") },
                  { field: "abstained", header: t("ballot.abstained") },
                  { field: "notReturn", header: t("ballot.notReturn") },
                  {
                    field: "commentsResponded",
                    header: t("ballot.commentsResponded"),
                  },
                ]}
              />
              <Button
                text
                className="p-button-plain underline"
                label={t("ballot.viewComments")}
              ></Button>
            </div>
          </div>
        </div>

        <div className="bg-white flex flex-wrap align-items-center px-5 py-3 gap-4 fixed-footer w-full left-0 shadow">
          <Button className="button-md gap-1" severity="secondary">
            <span className="font-bold text-capitalize">
              {t("ballot.cancel")}
            </span>
          </Button>

          <Button className="button-md gap-1 ml-auto">
            <span className="font-bold text-capitalize">
              {t("ballot.closeBallot")}
            </span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default CloseBallot;

import { Button } from 'primereact/button';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useLanguageContext } from '../../LanguageContext';
import { BallotRecordheaderInfo } from './type';


const BallotCommentRecordHeader = (props: BallotRecordheaderInfo) => {
    const { BallotRecordheaderInfo } = props;
    const [t, i18n] = useTranslation("ballot");
    const { selectedLanguage } = useLanguageContext();
    const navigate = useNavigate();
    const performAction = () => {
        i18n.changeLanguage(selectedLanguage);
    };
    React.useEffect(() => {
        performAction();
    }, [selectedLanguage]);
    return (
        <div className="bg-white flex flex-wrap align-items-center justify-content-between px-4 py-3 gap-3">
            <div className="flex flex-column gap-1">
                <p className="text-base font-normal text-capitalize m-0">{t("ballot.applicantName")}</p>
                <h3 className="text-title text-base font-bold text-capitalize m-0">
                    {BallotRecordheaderInfo?.record?.userProfile.displayName}
                </h3>
            </div>
            <Button type='button'
                label={t("ballot.viewRecord")}
                className="button-md"
                severity="secondary"
                onClick={() => {
                    sessionStorage.setItem("recordId", BallotRecordheaderInfo?.record?.id);
                    navigate('/record/record-preview');
                }}
            />
        </div>
    )
}

export default BallotCommentRecordHeader
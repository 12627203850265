import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CreateCommitteeApiData {
  createCommittee: Record<string, any>;
  configureCommittee: Record<string, any>;
  addMembersInCommittee: Record<string, any>;
  categoryOfIntereset: Record<string, any>;
  loading: boolean;
}

const initialState: CreateCommitteeApiData = {
  createCommittee: {},
  configureCommittee: {},
  addMembersInCommittee: {},
  categoryOfIntereset: {},
  loading: false,
};

const createCommitteeSlice = createSlice({
  name: "Committee",
  initialState,
  reducers: {
    createCommitteeRequest: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.createCommittee = { ...state.createCommittee, ...action.payload };
      state.loading = false;
    },
    configureCommitteeRequest: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.configureCommittee = {
        ...state.configureCommittee,
        ...action.payload,
      };
      state.loading = false;
    },
    addMembersInCommitteeRequest: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.addMembersInCommittee = {
        ...state.addMembersInCommittee,
        ...action.payload,
      };
      state.loading = false;
    },
    categoryOfInteresetRequest: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.categoryOfIntereset = {
        ...state.categoryOfIntereset,
        ...action.payload,
      };
      state.loading = false;
    },
    categoryOfInteresetReset: (state) => {
      state.categoryOfIntereset = {};
      state.loading = false;
    },
    addMembersInCommitteeReset: (state) => {
      state.addMembersInCommittee = {};
      state.loading = false;
    },
    reset: () => initialState,
  },
});

export const {
  createCommitteeRequest,
  configureCommitteeRequest,
  addMembersInCommitteeRequest,
  categoryOfInteresetRequest,
  reset,
  categoryOfInteresetReset,
  addMembersInCommitteeReset,
} = createCommitteeSlice.actions;
export default createCommitteeSlice.reducer;

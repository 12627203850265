import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import { useLanguageContext } from "../../LanguageContext";
import { fetchData } from "../../../services/Ballot/apiservice";
import { fetchData as getData } from "../../../services/apiService";
import FeatherIcon from "../../common/FeatherIconComponent";
import { allBallotListings } from "../../../slices/ballotListing";
import {
  CommitteeResponse,
  Committee as CommitteResponse,
} from "../common/type";
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";
interface Committee {
  Id: number;
  Name: string;
}
interface BallotType {
  Id: number;
  Name: string;
}
interface BallotStatus {
  Id: number;
  Name: string;
}
interface Ballot {
  Committee: Committee;
  BallotType: BallotType;
  BallotStatus: BallotStatus;
}
interface FilterData {
  Id: number;
  label: string;
  value: string;
}
const getIdByName = (
  items: { Id: number; value: string }[],
  selectedNames: string[]
): number[] => {
  return selectedNames
    .map((name) => {
      const item = items.find((item) => item.value === name);
      return item ? item.Id : -1;
    })
    .filter((id) => id !== -1);
};
const getIdByNameforCommittee = (
  items: { id: number; value: string }[],
  selectedNames: string[]
): number[] => {
  return selectedNames
    .map((name) => {
      const item = items.find((item) => item.value === name);
      return item ? item.id : -1;
    })
    .filter((id) => id !== -1);
};
const formatDate = (date: Date | null) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return "";
  }
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
const BallotFilter = (props: any) => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const dispatch = useDispatch();
  const { ballotDetails } = props;
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
  const [allBallotStatus, setAllBallotStatus] = useState<any>();
  const [allBallotType, setAllBallotType] = useState<any>();
  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };
  const getAllBallotStatus = async () => {
    try {
      const ballotStatus = await fetchData(`BallotStatus/GetAll`);
      const allBallotStatusFormatted = ballotStatus.Collection.map(
        (ballot: any) => {
          return {
            Id: ballot.Id,
            name: ballot.Name,
            value: ballot.Name,
          };
        }
      );
      setAllBallotStatus(allBallotStatusFormatted);
    } catch (err) {
      console.log(err, "err");
    }
  };
  const getAllBallotTypes = async () => {
    try {
      const ballotTypeData = await fetchData("BallotType/GetAll");
      const allBallotTypeFormatted = ballotTypeData.Collection.map(
        (ballot: any) => {
          return {
            Id: ballot.Id,
            name: ballot.Name,
            value: ballot.Name,
          };
        }
      );
      setAllBallotType(allBallotTypeFormatted);
    } catch (err) {
      console.log(err, "err");
    }
  };
  const fetchParentCommittee = async () => {
    try {
      const primaryCommitteeResponse: CommitteeResponse =
        await getData(`Committee/GetAll`);
      const primaryCommittee = primaryCommitteeResponse.Collection.filter(
        (committee: CommitteResponse) => committee.Status.Name === "Created"
      );
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: primaryCommittee,
          label: "primarycommittee",
        })
      );
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getAllBallotStatus();
    getAllBallotTypes();
    fetchParentCommittee();
  }, []);

  const { primaryCommitteOptions } = useSelector(
    (state: any) => state.ballotSelectRecords
  );
  const { control, handleSubmit } = useForm();
  const onSubmit = (data: any) => {
    const primaryCommitteIds = getIdByNameforCommittee(
      primaryCommitteOptions,
      data.primaryCommitte
    );
    const ballotTypeIds = getIdByName(allBallotType, data.ballotType);
    const ballotStatusIds = getIdByName(allBallotStatus, data.ballotStatus);
    const formData = {
      ...data,
      primaryCommitte: primaryCommitteIds,
      ballotType: ballotTypeIds,
      ballotStatus: ballotStatusIds,
    };
    const fromDateStr = formatDate(formData.dateActivationFrom);
    const toDateStr = formatDate(formData.dateActivationTo);
    const filters = [];
    if (data.primaryCommitteFilter && data.primaryCommitte.length >= 1)
      filters.push({ Key: "committeeid", Value: formData.primaryCommitte });
    if (data.ballotTypeFilter && data.ballotType.length >= 1)
      filters.push({ Key: "ballottypeid", Value: formData.ballotType });
    if (data.ballotStatusFilter && data.ballotStatus.length >= 1)
      filters.push({ Key: "ballotstatusid", Value: formData.ballotStatus });
    if (
      data.requestDateFilter &&
      data.dateActivationFrom &&
      data.dateActivationTo
    )
      filters.push({ Key: "DateFilter", Value: `${fromDateStr}#${toDateStr}` });

    const queryString = filters
      .map((filter, index) => {
        const value = Array.isArray(filter.Value)
          ? filter.Value.map((val: string) => val).join(",")
          : encodeURIComponent(filter.Value);
        return `Filters[${index}].Key=${filter.Key}&Filters[${index}].Value=${value}`;
      })
      .join("&");

    searchHandler(queryString);
    if (overlayPanelRef.current && filters.length > 0) {
      overlayPanelRef.current.hide();
    }
  };

  const searchHandler = async (querstr: string) => {
    try {
      const searchResultData = await fetchData(
        `Ballot/Search?${querstr}&PageIndex=-1&SortDir=desc`
      );
      console.log(searchResultData, "searchResultData");
      const formattedBallottableData = searchResultData.Collection.map(
        (ballot: any) => {
          let OpenDate = formatDate(new Date(ballot.OpenDate));
          let CloseDate = formatDate(new Date(ballot.CloseDate));
          return {
            Id: ballot.Id,
            ballotId: ballot.BallotNumber,
            numberOfRecords: `${ballot.NoOfRecords} Records`,
            primaryCommitteeResponsible: ballot.Committee.Name,
            ballotType: ballot.BallotType.Name,
            ballotLevel: ballot.BallotLevel.Name,
            statusName: ballot.BallotStatus.Name,
            openDate: OpenDate,
            endDate: CloseDate,
            action: t("ballot.action"),
            sessionKey: "ballotTableDetails",
          };
        }
      );
      dispatch(
        allBallotListings.addAllBallotDetails({
          value: formattedBallottableData,
        })
      );
    } catch (err) {
      console.log(err, "err");
    }
  };
  return (
    <>
      <Button
        className="button-md gap-1"
        severity="secondary"
        onClick={toggleFilterPanel}
        aria-label={t("ballot.filter")}
      >
        <FeatherIcon name="filter" size={20} color="inherit" />
        <span className="font-bold text-capitalize hidden md:flex">
          {t("ballot.filter")}
        </span>
      </Button>
      <OverlayPanel
        ref={overlayPanelRef}
        onHide={() => setShowFilterPanel(false)}
        appendTo={"self"}
        dismissable
        className="p-overlaypanel-filter left-auto md:right-0 top-100"
        aria-labelledby="filter-heading"
        role="dialog"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-column gap-1" tabIndex={0}>
            <h3 id="filter-heading" className="text-lg font-normal m-0 p-3">
              {t("ballot.filter")}
            </h3>
            <div className="filter-wrap">
              <div className="">
                <Controller
                  name="primaryCommitteFilter"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <label
                      htmlFor="primaryCommitteFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="primaryCommitteFilter"
                        {...field}
                        checked={field.value}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("ballot.PrimaryCommittee")}
                    </label>
                  )}
                />
                <div className="pr-3 py-2 pl-6 w-full relative">
                  <Controller
                    name="primaryCommitte"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <MultiSelect
                        appendTo="self"
                        {...field}
                        options={primaryCommitteOptions}
                        optionLabel="value"
                        filter
                        placeholder={t("ballot.PrimaryCommittee")}
                        className="w-full"
                        aria-label={t("ballot.PrimaryCommittee")}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="p-0">
                <Controller
                  name="ballotTypeFilter"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <label
                      htmlFor="ballotTypeFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="ballotTypeFilter"
                        {...field}
                        checked={field.value}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("ballot.ballotType")}
                    </label>
                  )}
                />
                <div className="pr-3 py-2 pl-6 w-full relative">
                  <Controller
                    name="ballotType"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <MultiSelect
                        appendTo="self"
                        {...field}
                        options={allBallotType}
                        optionLabel="value"
                        filter
                        placeholder={t("ballot.ballotType")}
                        maxSelectedLabels={3}
                        className="w-full"
                        aria-label={t("ballot.ballotType")}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="p-0">
                <Controller
                  name="ballotStatusFilter"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <label
                      htmlFor="ballotStatus"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="ballotStatus"
                        {...field}
                        checked={field.value}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("ballot.ballotStatus")}
                    </label>
                  )}
                />
                <div className="pr-3 py-2 pl-6 w-full relative">
                  <Controller
                    name="ballotStatus"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <MultiSelect
                        appendTo="self"
                        {...field}
                        options={allBallotStatus}
                        optionLabel="value"
                        filter
                        placeholder={t("ballot.ballotStatus")}
                        maxSelectedLabels={3}
                        className="w-full"
                        aria-label={t("ballot.ballotStatus")}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="p-0">
                <Controller
                  name="requestDateFilter"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <label
                      htmlFor="requestDateFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="requestDateFilter"
                        {...field}
                        checked={field.value}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("ballot.requestDate")}
                    </label>
                  )}
                />
                <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                  <Controller
                    name="dateActivationFrom"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <Calendar
                        className="custom-datepicker"
                        inputId="dateActivationFrom"
                        {...field}
                        value={field.value}
                        dateFormat="mm/dd/yy"
                        tabIndex={0}
                        ariaLabel={t("ballot.fromDate")}
                        placeholder={t("ballot.fromDate")}
                      />
                    )}
                  />
                  <Controller
                    name="dateActivationTo"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <Calendar
                        className="custom-datepicker"
                        inputId="dateActivationTo"
                        {...field}
                        value={field.value}
                        dateFormat="mm/dd/yy"
                        tabIndex={0}
                        ariaLabel={t("ballot.toDate")}
                        placeholder={t("ballot.toDate")}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <Button
              type="submit"
              className="button-md w-full justify-content-center"
            >
              {t("ballot.applyFilter")}
            </Button>
          </div>
        </form>
      </OverlayPanel>
    </>
  );
};

export default BallotFilter;

import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import Table from "../common/Table";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";
import { OverlayPanel } from "primereact/overlaypanel";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { useNavigate } from "react-router-dom";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";

interface Option {
  name: string;
  code: string;
}

const RecordsTab: React.FC = () => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const navigate = useNavigate();

  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  return (
    <>
      <div>
        <div className="flex flex-wrap md:flex-nowrap md:justify-content-between align-items-center py-3 gap-3 w-full">
          <IconField iconPosition="left" className="w-full md:w-16rem">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              id="searchCommittee"
              placeholder={t("standard.searchCommittee")}
              className="w-full"
              aria-label={t("standard.searchCommittee")}
            />
          </IconField>

          <div className="flex gap-3 w-full md:w-auto align-items-center">
            <Dropdown
              inputId="selectCommittee"
              value={selectedOption}
              onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
              options={options}
              optionLabel="name"
              placeholder={t("standard.selectCommittee")}
              className="w-full md:w-16rem"
              aria-label={t("standard.selectCommittee")}
            />
            <div className="relative">
              <Button
                className="button-md gap-1"
                severity="secondary"
                onClick={toggleFilterPanel}
                aria-label={t("standard.filter")}
              >
                <FeatherIcon name="filter" size={20} color="inherit" />
                <span className="font-bold text-capitalize hidden md:flex">
                  {t("standard.filter")}
                </span>
              </Button>
              {/* OverlayPanel for Filter */}
              <OverlayPanel
                ref={overlayPanelRef}
                onHide={() => setShowFilterPanel(false)}
                appendTo={"self"}
                dismissable
                className="p-overlaypanel-filter left-auto right-0 top-100"
                aria-labelledby="filter-heading"
                role="dialog"
              >
                <div className="flex flex-column gap-1" tabIndex={0}>
                  <p id="filter-heading" className="text-lg font-bold m-0 p-3">
                    {t("standard.filter")}
                  </p>
                  <div className="filter-wrap">
                    {/* Add filter fields here */}
                  </div>
                  <Button
                    className="button-md w-full justify-content-center"
                  >
                    {t("standard.applyFilter")}
                  </Button>
                </div>
              </OverlayPanel>
            </div>
          </div>
        </div>

        <Table
          loading={false}
          products={[]}
          columns={[
            { field: "recordId", header: t("standard.recordId") },
            { field: "projectTechnicalManager", header: t("standard.projectTechnicalManager") },
            { field: "positionApplied", header: t("standard.positionApplied") },
            { field: "readyForBalloting", header: t("standard.readyForBalloting") },
            { field: "createdOn", header: t("standard.createdOn") },
            { field: "comment", header: t("standard.comment") },
            { field: "status", header: t("standard.status") },
          ]}
        />
      </div>
    </>
  );
};

export default RecordsTab;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import FeatherIcon from "feather-icons-react";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import Table from "../common/Table";
import { MenuItem } from "primereact/menuitem";
import { fetchData } from "../../services/apiService";
import { usePermission } from "../auth/Authorization";

const ResignMembers: React.FC = () => {
  const [t, i18n] = useTranslation("member");
  const [committees, setRequestsList] = useState([]);
  const [search, setSearch] = useState("");
  const [userRole, setUserRole] = useState<string>("");
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();

  const { selectedLanguage } = useLanguageContext();

  const [loading, setLoading] = useState<boolean>(true);
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const handleViewMember = () => {
    return () => {
      if (sessionStorage.getItem("statusName") === "Draft") {
        navigate("/member/create");
      } else {
        navigate("/member/request-view");
      }
    };
  };

  const actionItems: MenuItem[] = [
    {
      label: t("member.viewEditRequest"),
      command: handleViewMember(),
    },
  ];

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  const breadcumbItems = [
    { label: t("member.dashboard"), url: "/dashboard" },
    { label: t("member.membershipRequests") },
  ];
  const navigate = useNavigate();

  const formatResponseData = (responseData: any) => {
    let modifiedData = responseData.Collection.sort((a: any, b: any) => {
      const dateA: any = new Date(a.CreatedDate as any);
      const dateB: any = new Date(b.CreatedDate as any);
      return dateB - dateA;
    });

    // Filter out 'Draft' records for 'Staff' and 'Admin' roles
    if (userRole === "Staff" || userRole === "Admin") {
      modifiedData = modifiedData.filter(
        (item: any) => item.MembershipStatus?.StatusName !== "Draft"
      );
    }

    const sortedData = modifiedData.map((item: any) => ({
      ...item,
      MemberPositionName: item.MembershipPosition
        ? item.MembershipPosition.PositionName
        : null,
      CommitteeName: item.Committee ? item.Committee.Name : null,
      CategoryName: item.CategoryOfInterest
        ? item.CategoryOfInterest.CategoryName
        : null,
      CreatedDate: item.CreatedDate
        ? new Date(item.CreatedDate).toLocaleDateString()
        : null,
      RequestStatus: item.MembershipStatus
        ? item.MembershipStatus.StatusName
        : null,
      sessionKey: "memberShipId",
      statusName: item.MembershipStatus
        ? item.MembershipStatus.StatusName
        : null,
      sessionKeyStatus: "statusName",
      MemberName:
        item?.UserProfile?.Firstname + " " + item?.UserProfile?.Lastname,
    }));

    return sortedData;
  };

  const resignDataFetch = async () => {
    const responseData = await fetchData(
      `MembershipRequest/Search?&PageIndex=-1`
    );
    const filteredData =
      responseData?.Collection?.filter(
        (item: {
          IsResignRequest: boolean;
          MembershipStatus: { StatusName: string };
        }) =>
          item.MembershipStatus.StatusName === "Submitted" &&
          item.IsResignRequest === true
      ) || [];
    const sortedData = formatResponseData({ Collection: filteredData });
    setRequestsList(sortedData);

    setLoading(false);
  };

  useEffect(() => {
    resignDataFetch();
    setLoading(false);
  }, []);

  return (
    <>
      <IconField iconPosition="left" className="w-full md:w-16rem mb-4">
        <InputIcon className="pi pi-search"> </InputIcon>
        <InputText
          id="searchCommittee"
          className="w-full"
          placeholder={"Search"}
          aria-label={t("record.searchCommittee")}
          onChange={(e) => setSearch(e.target.value)}
        />
      </IconField>
      <Table
        loading={loading}
        products={committees?.filter((item: any) => {
          const memberName = "" || item.MemberName;
          const committeeName = "" || item.CommitteeName;
          const MembershipRequestNumber = "" || item.MembershipRequestNumber;
          return (
            search === "" ||
            committeeName.toLowerCase().includes(search.toLowerCase()) ||
            memberName.toLowerCase().includes(search.toLowerCase()) ||
            MembershipRequestNumber.toLowerCase().includes(search.toLowerCase())
          );
        })}
        columns={[
          {
            field: "MembershipRequestNumber",
            header: t("member.request") + "#",
            link: "/member/request-view",
          },
          { field: "MemberName", header: t("member.memberName") },
          {
            field: "CommitteeName",
            header: t("member.committeeApplied"),
          },
          {
            field: "MemberPositionName",
            header: t("member.positionApplied"),
          },
          {
            field: "CategoryName",
            header: t("member.categoryofIntrest"),
          },
          {
            field: "CreatedDate",
            header: t("member.requestDate"),
            sortable: true,
          },
          { field: "statusName", header: t("member.status") },
          {
            field: "action",
            header: t("member.action"),
            sortable: false,
          },
        ]}
        actionItems={actionItems}
      />
    </>
  );
};

export default ResignMembers;

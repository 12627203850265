import { fetchData, postData, putData } from "./apiService";

const fetchDataForMemberShipPosition = async () => {
  try {
    const responseData = await fetchData("MembershipPosition/GetAll");
    const labelValueArray = responseData.Collection.map((category: any) => ({
      label: category.PositionName,
      value: category.Id,
    }));
    return labelValueArray;
  } catch (error) {
    console.error("Error fetching Membership Position:", error);
    //setLoading(false);
  }
};

const fetchDataForCategoryOfInterest = async () => {
  try {
    const responseData = await fetchData("CategoryOfInterest/GetAll");
    const labelValueArray = responseData.Collection.map((category: any) => ({
      label: category.CategoryName,
      value: category.Id,
    }));
    return labelValueArray;
  } catch (error) {
    console.error("Error fetching Category of Interest:", error);
    // setError(error);
  }
};

export { fetchDataForMemberShipPosition, fetchDataForCategoryOfInterest };
